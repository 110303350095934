import classNames from 'classnames';
import PropTypes from 'prop-types';
import mylistLogo from 'assets/images/mylist-logo-icon.svg'
import Button from 'common/components/elements/buttons/primary';


const SwitchToMylist = ({
   isSubLink,
   accountType,
   onClick,
}) => {

   return (
      <div
         className={ classNames({
            'h-11 flex items-center justify-between gap-4 hover:bg-hover cursor-pointer pr-4': true,
            'pl-14 desktop:pl-4': isSubLink,
            'px-4 desktop:px-0': !isSubLink,
         }) }
         role='presentation'
         onClick={ onClick }
      >
         <div
            className='flex items-center gap-4'
         >
            <div
               className='w-5 h-5 flex items-center'
            >
               <img
                  src={ mylistLogo }
                  alt='mylist.site logo'
               />
            </div>
            <span
               className={ classNames({
                  'font-medium leading-5 text-major': true,
               }) }
               dangerouslySetInnerHTML={ {
                  __html: `${ accountType === 'all' ? 'Go to' : 'Create' } my<span class="font-bold">list.</span>site`,
               } }
            />
         </div>
         <Button
            iconName='help-outline'
            classNames='!p-0 !w-fit'
            primaryColor={ false }
            backgroundColor='transparent'
            fontIconColor='secondary'
            onClick={ (e) => {
               e.preventDefault()
               e.stopPropagation()
            } }
         />
      </div>
   )
}

SwitchToMylist.propTypes = {
   isSubLink: PropTypes.bool,
   accountType: PropTypes.string,
   onClick: PropTypes.func,
}

export default SwitchToMylist;
