import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ImgGallery from 'common/components/modules/img-gallery/index.jsx'
import '../style.scss'

const Photoset = ({
   data,
   galleryProps,
}) => {
   const haveData = Array.isArray(data) && data.length > 0;
   const images = data.map(item => {
      return { original: item }
   });
   return (
      <div className='h-full w-full'>
         {
            haveData && (
               <ImgGallery
                  images={ images }
                  {
                     ...galleryProps
                  }
               />
            )
         }
      </div>
   );
};

Photoset.propTypes = {
   data: PropTypes.array,
   galleryProps: PropTypes.object,
};

export default memo(Photoset);
