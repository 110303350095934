import React, { useState } from 'react'
import PropTypes from 'prop-types';
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label';
import Button from 'common/components/elements/buttons/primary';
import DeleteModal from 'admin/views/manage-resource/delete-modal';
import Modal from 'common/components/modules/modal';
import { getCountState } from 'common/utils/utils'
import './style.scss'

const BulkEditButton = ({
   isBulkEdit,
   onBulkEdit,
   setIsBulkEdit,
   onSelectAll,
   isSelectAll,
   selectedLength,
   destroyButtonDisabled,
   destroyMedais,
   type = 'video',
   setSelectedCount,
   totalCount,
   onCancel,
   isReorderMode,
   isDemoMode,
}) => {

   const [openDeleteModal, setOpenDeleteModal] = useState(false);
   return (
      <div className={ `flex justify-between bulk-edit-buttons gap-3 ${ isBulkEdit ? ' w-full sm:flex-row flex-col ' : '' }` }>
         {
            !isBulkEdit &&
            <Button
               text='Bulk edit'
               textColor='major'
               fontIconColor='secondary'
               iconName='pencil'
               iconPosition='left'
               padding='none'
               classNames='h-11 !w-fit px-5 py-2.5 whitespace-nowrap border border-divider'
               textMarginX='0'
               onClick={ () => setIsBulkEdit(true) }
               borderRadius='large'
               backgroundColor='panel'
               disabled={ isReorderMode }
               textClassName={ `${ isReorderMode ? '!text-disabled' : '' } ml-2` }
               iconClassName={ `${ isReorderMode ? '!text-disabled' : '' }` }
               textSize='base'
            />
         }
         {
            isBulkEdit && (
               <>
                  <div className='flex items-center gap-3'>
                     <Button
                        text={  `Edit ${ selectedLength > 0 ? `${ selectedLength } selected` : '' }` }
                        iconName='pencil'
                        iconPosition='left'
                        padding='none'
                        classNames='h-fit !w-fit px-5 py-2.5'
                        primaryColor
                        onClick={ onBulkEdit }
                        borderRadius='large'
                        disabled={ selectedLength === 0 }
                        textSize='base'
                     />
                     <Button
                        text='Cancel'
                        textSize='base'
                        textColor='secondary'
                        padding='none'
                        classNames='h-fit !w-fit px-5 py-2.5'
                        onClick={ () => {
                           setIsBulkEdit(false);
                           if(onCancel){
                              onCancel();
                           }
                        } }
                        borderRadius='large'
                        backgroundColor='transparent'
                        textMarginX='0'
                     />
                  </div>
                  <div className='flex items-center gap-3'>
                     <CheckboxLabel
                        label={ `Select ${ totalCount !== 1 ? 'all' : '' } ${ getCountState(totalCount, type) } ` }
                        onChange={ () => onSelectAll(!isSelectAll) }
                        checked={ isSelectAll }
                        name='select_all'
                        textColor='secondary'
                        padding='none'
                        labelClassName='!h-fit !w-fit'
                        fontWeight='medium'
                        labelTextClassName='whitespace-nowrap'
                     />
                     <Button
                        text='Delete'
                        textSize='base'
                        textColor='error'
                        padding='none'
                        classNames={ `h-fit !w-fit ${ selectedLength === 0 ? 'opacity-50 cursor-not-allowed' : '' }` }
                        onClick={ selectedLength === 0 ? null : () => setOpenDeleteModal(true) }
                        borderRadius='large'
                        backgroundColor='transparent'
                        disabled={ selectedLength === 0 || isDemoMode }
                        dataToolTipContent={ !!isDemoMode ? 'Not available in demo' : '' }
                        dataToolTipId='ams-top-tooltip-start'
                     />
                  </div>
               </>
            )
         }
         {
            openDeleteModal && (
               <Modal
                  contentPosition={ 'center' }
                  onClose={ () => setOpenDeleteModal(false) }
                  isCloseAction={ false }
               >
                  <DeleteModal
                     title={ `Type "delete ${ getCountState(selectedLength, type) }" to confirm` }
                     type='video'
                     isBulkEdit={ true }
                     count={ getCountState(selectedLength, type) }
                     checkTrue={ `delete ${ getCountState(selectedLength, type) }` }
                     isDisabled={ destroyButtonDisabled }
                     deleteCollection={ () => {
                        destroyMedais(() => {
                           setOpenDeleteModal(false)
                           setIsBulkEdit(false)
                           setSelectedCount(0)
                        })
                     } }
                     onClickCancel={ () => setOpenDeleteModal(false) } />
               </Modal>
            )
         }
      </div>
   )
}

BulkEditButton.propTypes = {
   isBulkEdit: PropTypes.bool,
   onBulkEdit: PropTypes.func,
   setIsBulkEdit: PropTypes.func,
   onSelectAll: PropTypes.func,
   isSelectAll: PropTypes.bool,
   selectedLength: PropTypes.number,
   totalCount: PropTypes.number,
   destroyMedais: PropTypes.func,
   onCancel: PropTypes.func,
   destroyButtonDisabled: PropTypes.bool,
   setSelectedCount: PropTypes.any,
   type: PropTypes.string,
   isReorderMode: PropTypes.bool,
   isDemoMode: PropTypes.bool,
}
export default BulkEditButton
