export const FETCH_DETAILS_START = "collections/FETCH_DETAILS_START";
export const FETCH_DETAILS_COMPLETED = "collections/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "collections/FETCH_DETAILS_FAILED";
export const DELETE_COLLECTIONS_START = "collections/DELETE_COLLECTIONS_START";
export const DELETE_COLLECTIONS_COMPLETED = "collections/DELETE_COLLECTIONS_COMPLETED";
export const DELETE_COLLECTIONS_FAILED = "collections/DELETE_COLLECTIONS_FAILED";
export const UPDATE_COLLECTIONS_START = "collections/UPDATE_COLLECTIONS_START";
export const UPDATE_COLLECTIONS_COMPLETED = "collections/UPDATE_COLLECTIONS_COMPLETED";
export const UPDATE_COLLECTIONS_FAILED = "collections/UPDATE_COLLECTIONS_FAILED";
export const CREATE_NEW_COLLECTIONS_START = "collections/CREATE_NEW_COLLECTIONS_START";
export const CREATE_NEW_COLLECTIONS_COMPLETED = "collections/CREATE_NEW_COLLECTIONS_COMPLETED";
export const CREATE_NEW_COLLECTIONS_FAILED = "collections/CREATE_NEW_COLLECTIONS_FAILED";
export const COLLECTIONS_REORDER_START = "collections/COLLECTIONS_REORDER_START";
export const COLLECTIONS_REORDER_COMPLETED = "collections/COLLECTIONS_REORDER_COMPLETED";
export const COLLECTIONS_REORDER_FAILED = "collections/COLLECTIONS_REORDER_FAILED";
export const SET_MODAL_SETTINGS = "collections/SET_MODAL_SETTINGS";
