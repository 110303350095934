import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';
import SyncPageScoll from 'common/utils/pageScrollSync';

const reducersMap = {
   [types.OPEN_HEADER_POPUP]: (state, action) => {
      return {
         ...state,
         isOpenPopup: action.payload,
      }
   },
   [types.OPEN_REPLY_COMMENT]: (state, action) => {
      const { payload }  = action;
      let comments = [...state.replyComments];
      if(!payload) {
         comments = [];
      }
      return {
         ...state,
         replyComments: [...comments],
         replyModal: {
            ...state.replyModal,
            isOpen: payload,
         },
         isOpenReplyComment: payload,
      }
   },


   [types.UPDATE_NOTIFICATIONS_DATA]: (state, action) => {
      const { data }  = action.payload;
      let unseenCount = state.unseenCount;
      if(!state.list?.data) {
         return {
            ...state,
            unseenCount: state.unseenCount + 1,
         }
      }
      let newData = [...state.list?.data];
      if(!!newData.filter(item => item.id === data.id).length) {
         newData = newData.map(item => {
            if(item.id === data.id) {
               return {
                  ...item,
                  ...data,
               }
            }
            return item;
         })
      } else {
         unseenCount++;
         newData = [
            data,
            ...newData,
         ];
      }
      return {
         ...state,
         list: {
            ...state.list,
            data: newData,
         },
         unseenCount,
         isEmptyNotifications: newData?.length === 0,
      };
   },

   [types.MAKE_ALL_AS_SEEN_NOTIFICATIONS]: (state, action) => {
      return {
         ...state,
         unseenCount: 0,
      };
   },

   [types.MAKE_ALL_AS_READ_NOTIFICATIONS]: (state, action) => {
      const { id } = action.payload;
      let data = [...state.list.data];
      let unreadCount = !!id ? state.unreadCount - 1  : 0;
      data = data.map(item => {
         if(!id || (!!id && id === item.id)) {
            return {
               ...item,
               is_read: true,
            };
         }
         return item;
      })
      return {
         ...state,
         list: {
            ...state.list,
            data: [...data],
         },
         unreadCount: unreadCount,
      };
   },


   [types.FETCH_NOTIFICATIONS_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingNotifications: true,
         isEmptyByFilterNotifications: false,
         isFetchingNewNotifications: false,
         isFetchingByFilterNotifications: false,
         isEmptyNotifications: false,
      };
   },
   [types.FETCH_NOTIFICATIONS_COMPLETED]: (state, action) => {
      const { data } = action.payload;
      return {
         ...state,
         list: data?.notifications,
         unseenCount: data?.badge_count,
         unreadCount: data?.unread_count,
         isFetchingNotifications: false,
         isEmptyNotifications: data?.notifications?.data?.length === 0,
      };
   },
   [types.FETCH_NOTIFICATIONS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingNotifications: false,
      };
   },

   [types.FETCH_NEW_NOTIFICATIONS_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingNewNotifications: true,
      };
   },
   [types.FETCH_NEW_NOTIFICATIONS_COMPLETED]: (state, action) => {
      const data  = action.payload;
      let list = {
         ...state.list,
         ...data?.notifications,
         data: [
            ...state.list.data,
            ...data?.notifications?.data,
         ],
      };
      return {
         ...state,
         list: list,
         unseenCount: data.badge_count,
         isFetchingNewNotifications: false,
      };
   },
   [types.FETCH_NEW_NOTIFICATIONS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingNewNotifications: false,
      };
   },

   [types.FETCH_NOTIFICATIONS_BY_FILTER_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingByFilterNotifications: true,
      };
   },
   [types.FETCH_NOTIFICATIONS_BY_FILTER_COMPLETED]: (state, action) => {
      const data = action.payload;
      return {
         ...state,
         list: data?.notifications,
         unseenCount: data?.badge_count,
         isFetchingByFilterNotifications: false,
         isEmptyByFilterNotifications: data?.notifications?.data?.length === 0,
      };
   },
   [types.FETCH_NOTIFICATIONS_BY_FILTER_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingByFilterNotifications: false,
      };
   },


   [types.FETCH_REPLY_COMMENT_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingReplyComment: true,
         replyModal: {
            type: 'comment',
            isOpen: true,
         },
         isOpenReplyComment: true,
      };
   },
   [types.FETCH_REPLY_COMMENT_COMPLETED]: (state, action) => {
      const data  = action.payload;
      return {
         ...state,
         replyComments: { ...data },
         isFetchingReplyComment: false,
      };
   },
   [types.FETCH_REPLY_COMMENT_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingReplyComment: false,
         isOpenReplyComment: false,
         replyModal: {
            type: 'comment',
            isOpen: false,
         },
      };
   },


   [types.FETCH_REPLY_CHAT_MESSAGE_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingReplyComment: true,
         replyModal: {
            type: 'chat',
            isOpen: true,
         },
      };
   },
   [types.FETCH_REPLY_CHAT_MESSAGE_COMPLETED]: (state, action) => {
      const data  = action.payload;
      return {
         ...state,
         replyChatMessage: { ...data },
         isFetchingReplyComment: false,
      };
   },
   [types.FETCH_REPLY_CHAT_MESSAGE_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingReplyComment: false,
         replyModal: {
            type: 'chat',
            isOpen: false,
         },
      };
   },


   [types.UPDATE_REPLY_COMMENTS]: (state, action) => {
      const { data, type, initial }  = action.payload;
      let comments = [...state.replyComments];
      if(!!initial){
         comments = [...initial];
      }
      if(!!Object.keys(data).length) {
         if(type && type === 'add') {
            comments = [
               ...comments,
               data,
            ];
         } else {
            comments = comments.map(item => {
               if(item.id === data.id) {
                  return data;
               }
               return item;
            });
         }
      }
      return {
         ...state,
         replyComments: [
            ...comments,
         ],
      }
   },

   [types.SEND_COMMENT_REQUEST]: (state) => {
      return {
         ...state,
      }
   },

   [types.SEND_COMMENT_COMPLETED]: (state, action) => {
      const { data } = action.payload;
      let notifications = [...state.list.data];
      if(!!data.parent_id) {
         notifications = notifications.map(item => {
            if(item?.payload?.id === data.parent_id) {
               return {
                  ...item,
                  payload: {
                     ...item.payload,
                     admin_reply: { ...data },
                  },
               }
            }
            return item;
         })
      }
      return {
         ...state,
         list: {
            ...state.list,
            data: [...notifications],
         },
      }
   },
   [types.SEND_COMMENT_FAILED]: (state) => {
      return {
         ...state,
      }
   },


   [types.REPLY_COMMENTS_TOGGLE_LIKE_REQUEST]: (state) => {
      return {
         ...state,
      }
   },

   [types.REPLY_COMMENTS_TOGGLE_LIKE_COMPLETED]: (state, action) => {
      const { commentId, parentId } = action.payload;
      let replyComments = [...state.replyComments];
      let data = [...state.list.data];

      if(!!replyComments.length) {
         let replyComment = replyComments[0];
         if(!!parentId) {
            let childs = replyComment.childs.map(item => {
               if(item.id === commentId) {
                  return {
                     ...item,
                     liked: !!item.liked ? false : true,
                     likes_count: !!item.liked ? --item.likes_count : ++item.likes_count,
                  };
               }
               return item;
            })
            replyComment = {
               ...replyComment,
               childs: [...childs],
            }
         } else {
            replyComment = {
               ...replyComment,
               liked: !!replyComment.liked ? false : true,
               likes_count: !!replyComment.liked ? --replyComment.likes_count : ++replyComment.likes_count,
            }
         }
         replyComments = [replyComment];
      }
      data = data.map(item => {
         if(item?.payload?.id === commentId) {
            return {
               ...item,
               payload: {
                  ...item.payload,
                  liked: !item.payload.liked,
               },
            }
         }
         return item;
      })

      return {
         ...state,
         replyComments: [...replyComments],
         list: {
            ...state.list,
            data: [...data],
         },
      }

   },
   [types.REPLY_COMMENTS_TOGGLE_LIKE_FAILED]: (state) => {
      return {
         ...state,
      }
   },

   [types.REPLY_COMMENTS_DELETE_COMPLETED]: (state, action) => {
      const { payload } = action
      if(!!state.replyComments.length) {
         let isOpenReplyComment = state.isOpenReplyComment;
         const commentId = +payload.commentId
         const parentId = payload.parentId
         let replyComments = [...state.replyComments];
         if(!!parentId) {
            let childs = replyComments[0].childs.filter(item => item.id !== commentId);
            replyComments = [
               {
                  ...replyComments[0],
                  childs: [...childs],
               },
            ]
         } else {
            replyComments = [];
            isOpenReplyComment = false;
         }
         return {
            ...state,
            replyComments: [...replyComments],
            isOpenReplyComment,
         }
      } else {
         return { ...state }
      }
   },

   [types.SET_UNREAD_COUNT]: (state, action) => {
      const unreadCount  = action.payload
      return {
         ...state,
         unreadCount,
      }
   },
   [types.SET_UNSEEN_COUNT]: (state, action) => {
      const unseenCount  = action.payload
      return {
         ...state,
         unseenCount,
      }
   },


   [types.SET_NOTIFICATIONS_PANEL]: (state, action) => {
      const { payload } = action;
      let style = {}
      if(payload.isMobile) {
         setTimeout(() => {
            SyncPageScoll.syncScroll(payload.isOpen)
         }, 100);
      }
      if(payload.animationClass) {
         style = { ...payload.animationClass }
      } else {
         style = {
            animation: payload.isOpen ? 'slide-in-right-chat 0.6s forwards' : 'slide-out-right-chat 0.6s forwards',
         }

      }
      return {
         ...state,
         notificationsPanel: {
            ...state.notificationsPanel,
            ...payload,
            animationClass: style,
         },
      }
   },
   [types.UPDATE_NOTIFICATIONS_BY_MEMBER_ID]: (state, action) => {
      const { member_nickname, username } = action.payload;

      let notifications = state.list.data

      let updatedNotifications = notifications?.map((notif, ind) => {
         if(notif?.user?.username === username) {
            return {
               ...notif,
               user: {
                  ...notif.user,
                  nickname: member_nickname,
               },
            }

         } else {
            return notif
         }
      })

      return {
         ...state,
         list: {
            ...notifications,
            data: updatedNotifications,
         },
      }
   },
};

export default createReducer(initialState)(reducersMap);
