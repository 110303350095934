import React from 'react';
import PropTypes from 'prop-types';
import NotificationContent from 'common/components/modules/notifications/content';
import './style.scss'

const Notifications = ({
   filters,
   notifications,
   isMobile,
   onCloseModal,
   onScroll,
   isFetchingNew,
   goToNotificationPreferences,
   onClickCard,
   onClickLike,
   onClickReply,
   replyNotification,
   avatars,
   onFilterChange,
   markAllAsRead,
   isFetching,
   isEmpty,
   isEmptyByFilter,
   isFetchingByFilter,
   sendMessage,
   isDisabledMarkAllAsReadButton,
   userAvatar,
   goToResourceOrMemberPage,
   screenWidth,
   goToOrderDetails,
   siteInfo,
}) => {
   return (
      <NotificationContent
         isFetching={ isFetching }
         isEmpty={ isEmpty }
         isEmptyByFilter={ isEmptyByFilter }
         isFetchingByFilter={ isFetchingByFilter }
         filters={ filters }
         isMobile={ isMobile }
         notifications={ notifications }
         isAdmin={ true }
         onCloseModal={ onCloseModal }
         onScroll={ onScroll }
         isFetchingNew={ isFetchingNew }
         goToNotificationPreferences={ goToNotificationPreferences }
         onClickCard={ onClickCard }
         onClickLike={ onClickLike }
         onClickReply={ onClickReply }
         replyNotification={ replyNotification }
         avatars={ avatars }
         onFilterChange={ onFilterChange }
         markAllAsRead={ markAllAsRead }
         sendMessage={ sendMessage }
         isDisabledMarkAllAsReadButton={ isDisabledMarkAllAsReadButton }
         userAvatar={ userAvatar }
         goToResourceOrMemberPage={ goToResourceOrMemberPage }
         screenWidth={ screenWidth }
         goToOrderDetails={ goToOrderDetails }
         siteInfo={ siteInfo }
      />
   );
};

Notifications.propTypes = {
   notifications: PropTypes.array,
   onCloseModal: PropTypes.func,
   isMobile: PropTypes.bool,
   filters: PropTypes.object,
   onFilterChange: PropTypes.func,
   isFetching: PropTypes.bool,
   isEmpty: PropTypes.bool,
   isEmptyByFilter: PropTypes.bool,
   isFetchingByFilter: PropTypes.bool,
   goToNotificationPreferences: PropTypes.func,
   onScroll: PropTypes.func,
   isFetchingNew: PropTypes.bool,
   onClickCard: PropTypes.func,
   onClickLike: PropTypes.func,
   markAllAsRead: PropTypes.func,
   sendMessage: PropTypes.func,
   avatars: PropTypes.array,
   isDisabledMarkAllAsReadButton: PropTypes.bool,
   userAvatar: PropTypes.string,
   onClickReply: PropTypes.func,
   replyNotification: PropTypes.object,
   goToResourceOrMemberPage: PropTypes.func,
   screenWidth: PropTypes.number,
   goToOrderDetails: PropTypes.func,
   siteInfo: PropTypes.object,
};

export default React.memo(Notifications);
