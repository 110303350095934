export const FETCH_DETAILS = "store/FETCH_DETAILS";
export const FETCH_DETAILS_COMPLETED = "store/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "store/FETCH_DETAILS_FAILED";

// next page

export const FETCH_NEW_STORE = "store/FETCH_NEW_STORE";
export const FETCH_NEW_STORE_COMPLETED = "store/FETCH_NEW_STORE_COMPLETED";
export const FETCH_NEW_STORE_FAILED = "store/FETCH_NEW_STORE_FAILED";

// by filter
export const FETCH_FILTER_STORE = "store/FETCH_FILTER_STORE";
export const FETCH_FILTER_STORE_COMPLETED = "store/FETCH_FILTER_STORE_COMPLETED";
export const FETCH_FILTER_STORE_FAILED = "store/FETCH_FILTER_STORE_FAILED";
export const FETCH_DATA_BY_FILTERS = "store/FETCH_DATA_BY_FILTERS"
export const AFTER_FILTER_COMPLETED = "store/AFTHER_FILTER_COMPLETED"

// get by id
export const FETCH_STORE_BY_ID = "store/FETCH_STORE_BY_ID";
export const FETCH_STORE_BY_ID_COMPLETED = "store/FETCH_STORE_BY_ID_COMPLETED";
export const FETCH_STORE_BY_ID_FAILED = "store/FETCH_STORE_BY_ID_FAILED";

// update
export const UPDATE_STORE_LIST = "store/UPDATE_STORE_LIST";
export const UPDATE_FAILED = "store/UPDATE_FAILED";

// create new store item
export const CREATE_NEW_STORE = "store/CREATE_NEW_STORE";
export const CREATE_NEW_STORE_COMPLETED = "store/CREATE_NEW_STORE_COMPLETED";
export const CREATE_NEW_STORE_FAILED = "store/CREATE_NEW_STORE_FAILED";
export const SAVE_NEW_STORE_DATA = "store/SAVE_NEW_STORE_DATA";

export const FETCH_RESOURCES = "store/FETCH_RESOURCES";
export const FETCH_RESOURCES_COMPLETED = "store/FETCH_RESOURCES_COMPLETED";
export const FETCH_RESOURCES_FAILED = "store/FETCH_RESOURCES_FAILED";

export const CHANGE_LOADING_STATE = "store/CHANGE_LOADING_STATE"

// collection by id
export const FETCH_COLLECTION_BY_ID = "store/FETCH_COLLECTION_BY_ID";
export const FETCH_COLLECTION_BY_ID_COMPLETED = "store/FETCH_COLLECTION_BY_ID_COMPLETED";
export const FETCH_COLLECTION_BY_ID_FAILED = "store/FETCH_COLLECTION_BY_ID_FAILED";

// update category
export const UPDATE_CATEGORY = "store/UPDATE_CATEGORY";
export const UPDATE_CATEGORY_COMPLETED = "store/UPDATE_CATEGORY_COMPLETED";
export const UPDATE_CATEGORY_FAILED = "store/UPDATE_CATEGORY_FAILED";

// create category
export const CREATE_CATEGORY = "store/CREATE_CATEGORY";
export const CREATE_CATEGORY_COMPLETED = "store/CREATE_CATEGORY_COMPLETED";
export const CREATE_CATEGORY_FAILED = "store/CREATE_CATEGORY_FAILED";

// delete category
export const DELETE_CATEGORY = "store/DELETE_CATEGORY";
export const DELETE_CATEGORY_COMPLETED = "store/DELETE_CATEGORY_COMPLETED";
export const DELETE_CATEGORY_FAILED = "store/DELETE_CATEGORY_FAILED";

//init categories
export const FETCH_STORE_CATEGORIES = "store/FETCH_STORE_CATEGORIES"


export const SET_STORE_IMG = "store/SET_STORE_IMG";
export const SET_DELETE_MODAL = "store/SET_DELETE_MODAL";
export const DELETE_STORE_COMPLETED = "store/DELETE_STORE_COMPLETED";
export const SET_NEW_BUTTON_DISABLED = "store/SET_NEW_BUTTON_DISABLED";

// shipping zone
export const OPEN_SHIPPING_ZONE_MODAL = "store/OPEN_SHIPPING_ZONE_MODAL"
export const CHANGE_CHECKED_SHIPPING_ZONES = "store/CHANGE_CHECKED_SHIPPING_ZONES"
export const CHANGE_STORE_DETAILS_DATA = "store/CHANGE_STORE_DETAILS_DATA"
export const ADD_OR_REMOVE_IMAGE = "store/ADD_OR_REMOVE_IMAGE"
export const CHANGE_STORE_DETAILS_ERRORS = "store/CHANGE_STORE_DETAILS_ERRORS"
export const GET_STORE_VALIDATION_NUMBERS = "store/GET_STORE_VALIDATION_NUMBERS"
export const SAVE_STORE_SCROLL_POSITION_AND_FILTERS = "store/SAVE_STORE_SCROLL_POSITION_AND_FILTERS"


//order requests
export const FETCH_ORDER_REQUESTS_START = "store/FETCH_ORDER_REQUESTS_START"
export const FETCH_ORDER_REQUESTS_COMPLETED = "store/FETCH_ORDER_REQUESTS_COMPLETED"
export const FETCH_ORDER_REQUESTS_BY_FILTER_START = "store/FETCH_ORDER_REQUESTS_BY_FILTER_START"
export const FETCH_ORDER_REQUESTS_BY_FILTER_COMPLETED = "store/FETCH_ORDER_REQUESTS_BY_FILTER_COMPLETED"
export const FETCH_ORDER_REQUESTS_BY_FILTER_FAIL = "store/FETCH_ORDER_REQUESTS_BY_FILTER_FAIL"
export const FETCH_NEW_ORDER_REQUESTS_START = "store/FETCH_NEW_ORDER_REQUESTS_START"
export const FETCH_NEW_ORDER_REQUESTS_COMPLETED = "store/FETCH_NEW_ORDER_REQUESTS_COMPLETED"
export const FETCH_NEW_ORDER_REQUESTS_FAIL = "store/FETCH_NEW_ORDER_REQUESTS_FAIL"
export const FETCH_ORDER_REQUEST_FORMS = "store/FETCH_ORDER_REQUEST_FORMS"
export const FETCH_NEW_ORDER_REQUEST_FORMS_START = "store/FETCH_NEW_ORDER_REQUEST_FORMS_START"
export const FETCH_NEW_ORDER_REQUEST_FORMS_COMPLETED = "store/FETCH_NEW_ORDER_REQUEST_FORMS_COMPLETED"
export const FETCH_NEW_ORDER_REQUEST_FORMS_FAIL = "store/FETCH_NEW_ORDER_REQUEST_FORMS_FAIL"
export const DELETE_ORDER_REQUEST_FORM = "store/DELETE_ORDER_REQUEST_FORM"


export const FETCH_ORDER_REQUEST_BY_ID_START = "store/FETCH_ORDER_REQUEST_BY_ID_START"
export const FETCH_ORDER_REQUEST_BY_ID_COMPLETED = "store/FETCH_ORDER_REQUEST_BY_ID_COMPLETED"
export const FETCH_ORDER_REQUEST_BY_ID_FAIL = "store/FETCH_ORDER_REQUEST_BY_ID_FAIL"
export const UPDATE_ACTIVE_ORDER_REQUEST = "store/UPDATE_ACTIVE_ORDER_REQUEST"
export const UPDATE_ORDER_REQUESTS_DATA = "store/UPDATE_ORDER_REQUESTS_DATA"
export const UPDATE_ORDER_REQUESTS_LIST_DATA = "store/UPDATE_ORDER_REQUESTS_LIST_DATA"

// content bundels

export const CHANGE_NEW_BUNDLE_DATA = "store/CHANGE_NEW_BUNDLE_DATA"

export const FETCH_RESOURCES_FOR_BUNDLE_COMPLETED = "store/FETCH_RESOURCES_FOR_BUNDLE_COMPLETED"


export const FETCH_RESOURCES_FOR_NEW_BUNDLE = "store/FETCH_RESOURCES_FOR_NEW_BUNDLE"

export const CHANGE_NEW_BUNDLE_ERRORS = "store/CHANGE_NEW_BUNDLE_ERRORS"

export const CLEAR_NEW_BUNDLE_DATA = "store/CLEAR_NEW_BUNDLE_DATA"

export const CHANGE_ACCESS_DATA = "store/CHANGE_ACCESS_DATA"

export const SET_IMAGE_LOADER = "store/SET_IMAGE_LOADER"


export const SET_ACTIVE_BUNDLE_ERRORS = "store/SET_ACTIVE_BUNDLE_ERRORS"

export const CLEAR_ACTIVE_BUNDLE_DATA = "store/CLEAR_ACTIVE_BUNDLE_DATA"

export const CHANGE_ACTIVE_BUNDLE_ACCESSES = "store/CHANGE_ACTIVE_BUNDLE_ACCESSES"

export const CHANGE_ACTIVE_BUNDLE_DATA = "store/CHANGE_ACTIVE_BUNDLE_DATA"

export const PIN_OR_UNPIN_BUNDLE_ITEMS = "store/PIN_OR_UNPIN_BUNDLE_ITEMS"

export const DELETE_STORE_BUNDLE_ITEM = "store/DELETE_STORE_BUNDLE_ITEM"

//reorder

export const REORDER_STORE = 'store/REORDER_STORE'
export const CHANGE_INITED_STATE = 'store/CHANGE_INITED_STATE'
