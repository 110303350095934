import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetching: true,
         video: {},
         initImgEvent: null,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const res = action.payload;
      if(res.fetchByFilter) {
         return {
            ...state,
            isFetching: false,
            storiesData: {
               ...res.storiesData,
               data: res.storiesData.data,
            },
            isEmptyByFilter: !res.storiesData.data.length,
            isEmpty: false,
         }
      }
      return {
         ...state,
         isFetching: false,
         storiesData: {
            ...res.storiesData,
            data: res.storiesData.data,
         },
         isEmpty: res.storiesData.data.length === 0,

      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   // stories filter
   [types.FILTER_STORIES_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingFilter: true,
         isEmptyByFilter: false,
      }
   },
   [types.FILTER_STORIES_COMPLETED]: (state, action) => {
      const res = action.payload;
      return {
         ...state,
         isFetchingFilter: false,
         isFetching: false,
         storiesData: {
            ...res.storiesData,
            data: res.storiesData.data,
         },
         isEmptyByFilter: res.storiesData.data.length === 0,

      }
   },
   [types.FILTER_STORIES_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingFilter: false,
      }
   },

   // next page
   [types.NEW_FETCH_REQUEST]: (state, action) => {
      return {
         ...state,
         isNewFetching: true,
      }
   },
   [types.NEW_FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const res = payload.storiesData;
      return {
         ...state,
         isNewFetching: false,
         storiesData: {
            ...res,
            data: [...state.storiesData.data, ...res.data],
         },

      }
   },
   [types.FETCH_STATISTICS_FAILED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isNewFetching: false,
         statistics: payload,
      }
   },

   // STATISTICS //
   [types.FETCH_STATISTICS_REQUEST]: (state, action) => {
      return {
         ...state,
         isStatisticsFetching: true,
      }
   },
   [types.FETCH_STATISTICS_COMPLETED]: (state, action) => {

      return {
         ...state,
         statistics: action.payload,
         isStatisticsFetching: false,


      }
   },
   [types.NEW_FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isStatisticsFetching: false,
      }
   },

   // share

   [types.FETCH_UPLOAD_IMG_COMPLETED]: (state, action) => {
      return {
         ...state,
         initImgEvent: action.payload,
      }
   },

   [types.SHANRE_NEW_POST_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetching: true,
         shareErrors: {},
         isPublished: false,
      }
   },
   [types.SHANRE_NEW_POST_COMPLETED]: (state, action) => {
      const res = action.payload;
      return {
         ...state,
         storiesData: {
            ...state.storiesData,
            data: [...state.storiesData.data, res],
         },
         isFetching: false,
         isPublished: true,
      }
   },
   [types.SHANRE_NEW_POST_FAILED]: (state, action) => {
      const { error } = action.payload
      return {
         ...state,
         isFetching: false,
         shareErrors: error.response.data,
      }
   },

   [types.UPDATE_POST_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.UPDATE_POST_COMPLETED]: (state, action) => {
      const res = action.payload;
      return {
         ...state,
         shareErrors: null,
         video: {
            id: res.id,
            full_thumbnail: res.fullSrc,
            video_src: res.video_src,
         },
      }
   },
   [types.UPDATE_VIDEO_COMPLETED]: (state, action) => {

   },
   [types.UPDATE_POST_FAILED]: (state, action) => {
      const { error } = action.payload
      return {
         ...state,
         shareErrors: error.response.data,
      }
   },

   // delete story

   [types.DELETE_STORY_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingDelete: true,
      }
   },
   [types.DELETE_STORY_COMPLETED]: (state, action) => {
      const id = action.payload
      const newData = [...state.storiesData.data].filter(i => i.id !== id)
      return {
         ...state,
         storiesData: {
            ...state.storiesData,
            data: [...newData],
         },
         isFetchingDelete: false,
      }
   },
   [types.DELETE_STORY_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingDelete: false,
      }
   },
   // delete img
   [types.DELETE_IMG_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingDelete: true,
      }
   },
   [types.DELETE_IMG_COMPLETED]: (state, action) => {
      const index = action.payload.index;
      const id = action.payload.storyId;
      state.storiesData.data.map(i => {
         if(i.id === id){
            i.full_src.splice(index, 1)
            return i
         }
         return i
      })
      state.activeStory.full_src.splice(index, 1)
      return {
         ...state,
         isFetchingDelete: false,
      }
   },
   [types.DELETE_IMG_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingDelete: false,
      }
   },

   // get STORY BY ID
   [types.GET_BY_ID_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingStory: true,
      }
   },
   [types.GET_BY_ID_COMPLETED]: (state, action) => {
      return {
         ...state,
         activeStory: action.payload,
         isFetchingStory: false,


      }
   },
   [types.GET_BY_ID_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingStory: false,
      }
   },

   [types.UPLOAD_POSTER_START]: (state, action) => {
      return {
         ...state,
         customPosterImage: {
            ...state.customPosterImage,
            loading: true,
            warning: false,
         },
      }
   },
   [types.UPLOAD_POSTER_COMPLETED]: (state, action) => {
      return {
         ...state,
         customPosterImage: {
            ...state.customPosterImage,
            loading: false,
            warning: false,
            ...action.payload,
         },
      }
   },
   [types.UPLOAD_POSTER_FAILED]: (state, action) => {
      return {
         ...state,
         customPosterImage: {
            ...state.customPosterImage,
            loading: false,
            warning: true,
            src: '',
         },
      }
   },

   ///////////////////////////////////
   [types.FETCH_EDITED_STORY_REQUEST]: state => ({
      ...state,
      isEditedStoriesFetching: true,
   }),
   [types.FETCH_EDITED_STORY_COMPLETED]: (state, action) => {
      const { payload: { editedStory } } = action;
      return {
         ...state,
         isEditedStoriesFetching: false,
         editedStory,
      }
   },
   [types.SET_EDITED_STORY_INPUT]: (state, action) => {
      const { payload: { key, value } } = action;
      return {
         ...state,
         editedStories: {
            ...state.editedVideo,
            [key]: value,
         },
      }
   },
   [types.PIN_STORY_COMPLETED]: (state, action) => {
      const { payload: { id, bool } } = action;
      let data  = [...state.storiesData.data].sort(function(item1, item2){
         if(item1.id === id)  item1.is_pinned = !bool
         if(item2.id === id)  item2.is_pinned = !bool
         if(item1.id === id) {
            if(bool){
               return item2.is_pinned ? -1 : new Date(item1.created_at) - new Date(item2.created_at)
            }
            return -1
         } else if(item2.id === id) {
            if(bool){
               return item1.is_pinned ? -1 : new Date(item2.created_at) - new Date(item1.created_at)
            }
            return 1
         } else if(item1.id !== id && item2.id !== id){
            return  item1.is_pinned && !item2.is_pinned ? -1 ? item2.is_pinned && !item1.is_pinned  : 1 : 0
         } else {
            return 0
         }
      });
      if(bool) {
         data  = [...data].sort(function(item1, item2){
            return item1.is_pinned ? -1 : item2.is_pinned ? 1 : 0
         })

      }
      return {
         ...state,
         storiesData: {
            ...state.storiesData,
            data: data,
         },
         activeStory: {
            ...state.activeStory,
            is_pinned: !state.activeStory?.is_pinned,
         },
      }
   },
   //reorder
   [types.REORDER_STORIES]: (state, action) => {
      return {
         ...state,
         storiesData: {
            ...state.storiesData,
            data: action.payload,
         },
      }
   },
};

export default createReducer(initialState)(reducersMap);
