//API to handle audio recording
const LOCAL_STORAGE_PREFIX = 'chat_voice';
const LOCAL_STORAGE_SUFIX = 'device';

export const computeAudioDuration = (startTime) => {
   let endTime = new Date();
   let timeDiff = endTime - startTime;
   return timeDiff / 1000;
}

export const getDuration = (totalSeconds, bool) => {
   function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
   }
   const minutes = Math.floor(totalSeconds / 60);
   let seconds = totalSeconds % 60;
   if(bool) {
      seconds = Math.floor(seconds)
      const result = `${ padTo2Digits(minutes) }:${ padTo2Digits(seconds) }`;
      return result
   }
   seconds = seconds.toFixed(1)
   const result = `${ padTo2Digits(minutes) }:${ padTo2Digits(seconds) }`;
   return result
}

export const saveDevice = (deviceId) => {
   const localStorageKey = `${ LOCAL_STORAGE_PREFIX }_audio_${ LOCAL_STORAGE_SUFIX }`
   localStorage.setItem(localStorageKey, deviceId)
}

export const getDevice = () => {
   const localStorageKey = `${ LOCAL_STORAGE_PREFIX }_audio_${ LOCAL_STORAGE_SUFIX }`
   return localStorage.getItem(localStorageKey)
}

export const removeDevice = () => {
   const localStorageKey = `${ LOCAL_STORAGE_PREFIX }_audio_${ LOCAL_STORAGE_SUFIX }`
   localStorage.removeItem(localStorageKey)
}


export const requestPermissionAndGetDevices = async () => {
   let devices = [];
   let error = null;
   try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const allDevicesData = await navigator.mediaDevices.enumerateDevices();
      devices = allDevicesData.filter((d) => d.kind === 'audioinput');

      const tracks = await stream.getTracks();
      await  tracks.forEach(track => track.stop());
   } catch (err) {
      error = err;
   }
   return {
      devices,
      error,
   }
}

export const audioRecorder = {
   /** Stores the recorded audio as Blob objects of audio data as the recording continues*/
   audioBlobs: [], /*of type Blob[]*/
   /** Stores the reference of the MediaRecorder instance that handles the MediaStream when recording starts*/
   mediaRecorder: null, /*of type MediaRecorder*/
   /** Stores the reference to the stream currently capturing the audio*/
   streamBeingCaptured: null, /*of type MediaStream*/
   /** Start recording the audio
    * @returns {Promise} - returns a promise that resolves if audio recording successfully started
    */
   start: function(deviceId) {
      //Feature Detection
      if(!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
         //Feature is not supported in browser
         //return a custom error
         return Promise.reject(new Error('mediaDevices API or getUserMedia method is not supported in this browser.'));
      }

      else {
         //Feature is supported in browser

         //create an audio stream
         let constraints = { audio: true };
         if(deviceId){
            constraints = { audio: { deviceId: deviceId } };
         }
         return navigator.mediaDevices.getUserMedia(constraints/*of type MediaStreamConstraints*/)
         //returns a promise that resolves to the audio stream
            .then(stream /*of type MediaStream*/ => {
               //save the reference of the stream to be able to stop it when necessary
               audioRecorder.streamBeingCaptured = stream;

               //create a media recorder instance by passing that stream into the MediaRecorder constructor
               audioRecorder.mediaRecorder = new MediaRecorder(stream); /*the MediaRecorder interface of the MediaStream Recording
                   API provides functionality to easily record media*/

               //clear previously saved audio Blobs, if any
               audioRecorder.audioBlobs = [];

               //add a dataavailable event listener in order to store the audio data Blobs when recording
               audioRecorder.mediaRecorder.addEventListener("dataavailable", event => {
                  //store audio Blob object
                  audioRecorder.audioBlobs.push(event.data);
               });

               //start the recording by calling the start method on the media recorder
               audioRecorder.mediaRecorder.start();
               window.audioRecorder =  audioRecorder
            });

         /* errors are not handled in the API because if its handled and the promise is chained, the .then after the catch will be executed*/
      }
   },
   /** Stop the started audio recording
    * @returns {Promise} - returns a promise that resolves to the audio as a blob file
    */
   stop: function() {
      //return a promise that would return the blob or URL of the recording
      return new Promise(resolve => {
         //save audio type to pass to set the Blob type
         let mimeType = audioRecorder.mediaRecorder?.mimeType || 'audio/webm;codecs=opus';
         //listen to the stop event in order to create & return a single Blob object
         audioRecorder.mediaRecorder.addEventListener("stop", () => {
            //create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
            let audioBlob = new Blob(audioRecorder.audioBlobs, { type: mimeType });

            //resolve promise with the single audio blob representing the recorded audio
            resolve(audioBlob);
         });
         audioRecorder.cancel();
      });
   },
   /** Cancel audio recording*/
   cancel: function() {
      //stop the recording feature
      audioRecorder.mediaRecorder.stop();

      //stop all the tracks on the active stream in order to stop the stream
      audioRecorder.stopStream();

      //reset API properties for next recording
      audioRecorder.resetRecordingProperties();
   },
   /** Stop all the tracks on the active stream in order to stop the stream and remove
    * the red flashing dot showing in the tab
    */
   stopStream: function() {
      //stopping the capturing request by stopping all the tracks on the active stream
      audioRecorder?.streamBeingCaptured?.getTracks() //get all tracks from the stream
         .forEach(track /*of type MediaStreamTrack*/ => track.stop()); //stop each one
   },
   /** Reset all the recording properties including the media recorder and stream being captured*/
   resetRecordingProperties: function() {
      audioRecorder.mediaRecorder = null;
      audioRecorder.streamBeingCaptured = null;

      /*No need to remove event listeners attached to mediaRecorder as
       If a DOM element which is removed is reference-free (no references pointing to it), the element itself is picked
       up by the garbage collector as well as any event handlers/listeners associated with it.
       getEventListeners(audioRecorder.mediaRecorder) will return an empty array of events.*/
   },
}
