import React from 'react';
import { createRoot } from 'react-dom/client';
import store, { history } from "admin/state/store";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Routes from 'admin/routes';
import bootsrap from 'admin/bootsrap';

// import modules style
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'assets/css/index.scss';

// Styles start
import 'assets/fonts/icons/style.css';
import 'assets/css/video-style.scss';
import { screenResize } from 'admin/state/modules/common/actions';
// Styles end

import { isLocalhost } from 'common/utils/helper';
import { isStaging } from 'sites/utils/Helpers';
import { PostHogProvider } from 'posthog-js/react'

bootsrap();

window.addEventListener('resize', () => {
   store.dispatch(screenResize(window.innerWidth));
});

const app = document.getElementById('root');
const root = createRoot(app);

const isProduction = !isLocalhost() && !isStaging();

const posthogOptions = {
   api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

root.render(
   <Provider store={ store }>
      <ConnectedRouter history={ history }>
         {
            isProduction ? (
               <PostHogProvider
                  apiKey={ process.env.REACT_APP_PUBLIC_POSTHOG_KEY }
                  options={ posthogOptions }
               >
                  <Routes />
               </PostHogProvider>
            ) : (
               <Routes />
            )
         }
      </ConnectedRouter>
   </Provider>);
