import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from 'common/components/modules/modal-wrapper';


const MassMessageLimitModal = ({
   isMobile,
   onCloseModal,
}) => {
   return (
      <ModalWrapper
         type='confirm'
         icon='attention-outline'
         iconColor='warning'
         onCloseModal={ onCloseModal }
         action={ onCloseModal }
         title='Warning'
         description='You have reached the limit for sending mass messages at the moment. Your message cannot be sent via email however, it will still be delivered through the chat.'
         buttonText='I want higher limits'
         cancelText='Ok'
         contentWidth='375px'
         isMobile={ isMobile }
         iconSize='2xl'
         buttonClassName='mt-8'
         primaryButtonClassName='intercom-i-want-higher-limits'
      />
   );
};

MassMessageLimitModal.propTypes = {
   isMobile: PropTypes.bool,
   onCloseModal: PropTypes.func,
};

export default memo(MassMessageLimitModal);
