import { getSiteUrl } from 'sites/utils/Helpers';
import React from 'react';
import PropTypes from 'react';

const GDRPPolicy = ({
   legalPages,
}) => {
   let currentInfo
   if(!!legalPages && !!legalPages?.length) {
      currentInfo = legalPages?.find(it => it.name === 'gdrp_policy')
   }
   return (
      currentInfo?.html_content ?
         <>
            <div dangerouslySetInnerHTML={ {
               __html: currentInfo?.html_content,
            } } className='text-major dark:text-major-dark policy-list-dist'
            />
         </>
         :
         <>

            <p className='dark:text-secondary-dark '>
               We are committed to protecting the personal data of our users in accordance with the General Data Protection
               Regulation (GDPR). This policy explains how we collect, use, store, and protect your personal data and
               outlines your rights regarding your data.
               <br /><br />
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>1. Data Collection and Use</p>
            <p className='dark:text-secondary-dark '>
               We collect and process your personal data only for legitimate purposes, including but not limited to:
               <br /><br />
            </p>
            <ul style={ { paddingLeft: '30px', listStyle: 'disc' } }>
               <li style={ { margin: '4px 0' } }>
                  Providing our services.
               </li>
               <li style={ { margin: '4px 0' } }>
                  Enhancing user experience.
               </li>
               <li style={ { margin: '4px 0' } }>
                  Communicating with you about updates, services, or support.
               </li>
               <li style={ { margin: '4px 0' } }>
                  Ensuring compliance with legal obligations.
               </li>
            </ul>
            <br /><br />
            <p className='dark:text-secondary-dark font-medium'>
               Categories of Data Collected:
               <br /><br />
            </p>
            <ul style={ { paddingLeft: '30px', listStyle: 'disc' } }>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Personal Identifiable Information (PII):</span><br />
                  Email address.
               </li>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Usage Data:</span><br />
                  Browser type, IP address, pages visited, etc
               </li>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Payment Information:</span><br />
                  Billing address, transaction details (processed via secure third-party systems).
               </li>
            </ul>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>2. Legal Basis for Processing</p>
            <p className='dark:text-secondary-dark font-medium'>
               We process your personal data based on one or more of the following legal grounds:
               <br /><br />
            </p>
            <ul style={ { paddingLeft: '30px', listStyle: 'disc' } }>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Consent:</span><br />
                  When you have given clear consent for us to process your data.
               </li>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Legitimate Interests:</span><br />
                  When processing is necessary for our legitimate business interests and does not
                  override your rights
               </li>
            </ul>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>3. Data Sharing and Transfers</p>
            <p className='dark:text-secondary-dark '>
               We do not sell your personal data. However, we may share your data with:
               <br /><br />
            </p>

            <ul style={ { paddingLeft: '30px', listStyle: 'disc' } }>
               <li style={ { margin: '4px 0' } }>
                  Our support desk platform (Intercom) ONLY in the event that you interact with customer support. For
                  more information, please refer to <a href='https://www.intercom.com/legal/privacy'>Intercom's Privacy Policy</a>.
               </li>
               <li style={ { margin: '4px 0' } }>
                  Legal authorities, if required to comply with legal obligations.
               </li>
               <li style={ { margin: '4px 0' } }>
                  Payment processors, ONLY when a purchase is made.
               </li>
               <li style={ { margin: '4px 0' } }>
                  Google Analytics but ONLY non Personal Identifiable Information (PII) like IP addresses and browser
                  types.
               </li>
            </ul>

            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>4. Data Retention</p>
            <p className='dark:text-secondary-dark '>
               We retain personal data only as long as necessary to fulfill the purposes for which it was collected, comply with
               legal obligations, resolve disputes, and enforce agreements.
               <br />
               When data is no longer needed, we ensure it is securely deleted or anonymized.
               <br /><br />
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>5. Your Rights</p>
            <p className='dark:text-secondary-dark '>
               Under the GDPR, you have the following rights regarding your personal data:
            </p>
            <ul style={ { paddingLeft: '30px', listStyle: 'disc' } }>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Personal Identifiable Information (PII):</span><br />
                  Email address.
               </li>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Access:</span><br />
                  Request a copy of the personal data we hold about you.
               </li>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Rectification:</span><br />
                  Request correction of inaccurate or incomplete data.
               </li>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Erasure:</span><br />
                  Request deletion of your data ("Right to be Forgotten").
               </li>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Restriction:</span><br />
                  Request restriction of data processing.
               </li>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Portability:</span><br />
                  Request transfer of your data to another service.
               </li>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Objection:</span><br />
                  Object to data processing based on legitimate interests.
               </li>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Withdraw Consent:</span><br />
                  Withdraw your consent for data processing at any time.
               </li>
            </ul>
            <p className='dark:text-secondary-dark '>
               To exercise your rights, contact us at support@mymember.site or visit {getSiteUrl()}/member-support
               <br /><br />
            </p>

            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }> 6. Data Security</p>
            <p className='dark:text-secondary-dark '>
               We implement technical and organizational measures to protect your data, including:
            </p>
            <ul style={ { paddingLeft: '30px', listStyle: 'disc' } }>
               <li style={ { margin: '4px 0' } }>
                  Encryption of sensitive data.
               </li>
               <li style={ { margin: '4px 0' } }>
                  Regular security audits and vulnerability testing.
               </li>
               <li style={ { margin: '4px 0' } }>
                  Controlled access to personal data.
               </li>

            </ul>

            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }> 7. Cookies and Tracking</p>
            <p className='dark:text-secondary-dark '>
               We use cookies and similar technologies to enhance functionality and analyze usage. You can manage your
               cookie preferences through your browser settings.
               <br /><br />
               For more details, refer to our Cookie Policy.
               <br /><br />
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>  8. Updates to this Policy</p>
            <p className='dark:text-secondary-dark '>
               We may update this GDPR Policy periodically. Changes will be communicated via our platform or other
               appropriate channels.
               <br /><br />
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>  Contact Information</p>
            <p className='dark:text-secondary-dark '>
               If you have any questions or concerns about this policy or your data, please contact us at
               support@mymember.site or visit {getSiteUrl()}/member-support
               <br /><br />
            </p>
         </>
   )
};

GDRPPolicy.propTypes = {
   legalPages: PropTypes.array,
}


export default GDRPPolicy;
