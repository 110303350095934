const initialState = {
   isFetching: true,
   isNewFetching: false,
   isFilterFetching: false,
   isFetchingAddFreeMember: false,
   isFetchingAddNewPerson: true,
   membersData: {
      data: [],
   },
   statistics: {},
   member: {},
   memberships: [],
   isFetchingMemberships: true,
   freeMemberToAddId: null,
   freeMemberToAddMembership: null,
   errors: {},
};

export default initialState;
