import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChatAvatarsBar from 'common/components/modules/chat/chat-right-side-avatars-bar'
import ModalDropDown from  'common/components/modules/chat/chat-modal-drop-down'
import AvatarWithName from 'admin/views/chat/modules/avatar-with-name'
import { createPortal } from 'react-dom';
import './style.scss'
import cx from 'classnames';
import ChatCoversationForStream from '../coversiation-stream';
import { useSelector } from 'react-redux';
function MiniChatContent({
   userList,
   openDesctopDialogue,
   dialogue,
   activeConverstaion,
   onSetMemberOption,
   openDialogue,
   currentConverstionId,
   handleClickOutside,
   handleClearTimeout,

   isOnlineStream,
   handleCloseChat,
   // isOnlineStreamPage,
   openEditUsernameModal,
   giveFreeMembership,
   openCustomTagModal,
   isDisabledNotifications,
   onOpenEditNickname,
   goToMemberProfile,
}) {
   const isOnlineStreamPage = true
   let defIndex = null
   if(activeConverstaion && activeConverstaion.chat_room_id) {
      defIndex = !activeConverstaion.user_uuid ? 0 : 1
   }
   const { site_status } = useSelector(state => state.common.siteInfo)
   const [activeIndex, setActiveIndex] = useState(defIndex);
   const [showDialogue, setShowDialogue] = useState(1 === defIndex ? !!activeConverstaion && !!activeConverstaion.chat_room_id : true);
   useEffect(() => {
      if(!openDesctopDialogue && !isOnlineStreamPage) {
         setActiveIndex(defIndex)
         setShowDialogue(1 === defIndex ? !!activeConverstaion && !!activeConverstaion.chat_room_id : true)
      }
      return () => {
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isOnlineStream, activeConverstaion, openDesctopDialogue]);

   function showMiniChat(item) {
      let isOpen = true
      if(isOnlineStream) {
         setShowDialogue(true)
         setActiveIndex(item.user_uuid ? 1 : 0)
         openDialogue({ isOpen, ...item })
      } else {
         isOpen = (Number(item.chat_room_id) !== Number(currentConverstionId)) || !openDesctopDialogue
         openDialogue({ isOpen, ...item })
      }
   }

   let dynamicHeight = site_status !== 'live' ? 107 : 58;
   return (
      createPortal(
         <div
            style={ {
               pointerEvents: 'none',
               zIndex: 23,
               height: isOnlineStream ? `calc(100vh - ${ dynamicHeight }px)` : '',
            } }
            id='mini-chat'
            className={ cx({
               'fixed bottom-0 right-0': true,
               'h-full flex justify-end min-chat-into-stream bg-panel': isOnlineStream,
               'mr-4 mb-3': !isOnlineStream,
               'w-20': isOnlineStream && !openDesctopDialogue,
            }) }
         >

            <div
               className={ cx({
                  'flex items-end': true,
                  'w-full justify-end': isOnlineStream,
                  'rounded-xlg': !isOnlineStream,
               }) }
            >
               {
                  openDesctopDialogue && isOnlineStream && (
                     <ChatCoversationForStream
                        dialogue={ dialogue }
                        closeChat={ handleCloseChat }
                        userList={ userList  }
                        activeConverstaion={ activeConverstaion }
                        onClickPrivateChat={ (item) => openDialogue({ isOpen: true, ...item }) }
                        updateContentState={ (index, showDialogue) => {
                           setShowDialogue(showDialogue)
                           setActiveIndex(index)
                        } }
                        activeIndex={ activeIndex }
                        showDialogue={ showDialogue }
                     />
                  )
               }
               {
                  !isOnlineStream && (
                     <MiniChatDialogue
                        openDesctopDialogue={ openDesctopDialogue }
                        dialogue={ dialogue }
                        activeConverstaion={ activeConverstaion }
                        onSetMemberOption={ onSetMemberOption }
                        handleClickOutside={ handleClickOutside }
                        handleClearTimeout={ handleClearTimeout }
                        openEditUsernameModal={ openEditUsernameModal }
                        openCustomTagModal={ openCustomTagModal }
                        giveFreeMembership={ giveFreeMembership }
                        isDisabledNotifications={ isDisabledNotifications }
                        onOpenEditNickname={ onOpenEditNickname }
                        goToMemberProfile={ goToMemberProfile }
                     />
                  )
               }
               <ChatAvatarsBar
                  showMiniChat={ showMiniChat }
                  list={ userList }
                  isOnlineStream={ isOnlineStream }
               />
            </div>
         </div>,
         document.querySelector(isOnlineStream ? '.layout-content' : '#root')
      )

   );
}
function MiniChatDialogue({
   openDesctopDialogue,
   dialogue,
   activeConverstaion,
   onSetMemberOption,
   handleClickOutside,
   handleClearTimeout,
   openEditUsernameModal,
   giveFreeMembership,
   openCustomTagModal,
   isDisabledNotifications,
   onOpenEditNickname,
   goToMemberProfile,
}) {
   useEffect(() => {
      if(openDesctopDialogue) {
         document.addEventListener('mousedown', handleClickOutside);
      } else {
         document.removeEventListener('mousedown', handleClickOutside);
         handleClearTimeout()
      }
      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
         handleClearTimeout()

      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [openDesctopDialogue]);

   const header = document.querySelector('.page__header');
   let diff = 0
   if(header) {
      diff = header.offsetHeight + 20
   }

   return (
      <div
         className={ `mr-5 shadow dark:shadow-dark rounded-xlg mini-chat-content ${ openDesctopDialogue ? 'dialogue-show' : 'dialogue-hidden' }` }
         style={ {
            height: `calc(100vh - ${ diff }px)`,
            width: '372px',
            pointerEvents: openDesctopDialogue ? 'all' : 'none',
         } }
      >
         <ModalDropDown
            isDropDown={ false }
            isMobile={ false }
            contentPosition='full-screen'
            bottom={ 110 }
            width={ 346 }
            height={ 497 }
            right={ 90 }
         >
            <div className='bg-panel w-full flex flex-col h-full rounded-xlg' >
               {
                  activeConverstaion && (
                     <AvatarWithName
                        name={ activeConverstaion.conversation_name !== 'Group chat' ? (activeConverstaion?.member_nickname || activeConverstaion?.member?.nickname || activeConverstaion.username || activeConverstaion.conversation_name) : 'Group chat' }
                        avatarSrc={ activeConverstaion.user_avatar  || activeConverstaion.avatars }
                        memberStatus={ activeConverstaion?.member?.member_status || null }
                        leftOptionsIcon
                        onClickAvatar={ () => goToMemberProfile(activeConverstaion?.member?.id) }
                        goToBack={ () => {} }
                        isOnline={ activeConverstaion.is_online }
                        muteTime={ activeConverstaion.muted_period_in_seconds }
                        onSetMemberOption={ onSetMemberOption }
                        isBlocked={ !!activeConverstaion.member && !!activeConverstaion.member.banned }
                        openEditUsernameModal={ openEditUsernameModal }
                        setMemberOptionModal={ onSetMemberOption }
                        giveFreeMembership={ giveFreeMembership }
                        openCustomTagModal={ openCustomTagModal }
                        isDisabledNotifications={ isDisabledNotifications }
                        member={ activeConverstaion?.member }
                        onOpenEditNickname={ onOpenEditNickname }
                        memberData={ activeConverstaion?.member }
                     />
                  )
               }
               {dialogue && dialogue}
            </div>
         </ModalDropDown>
      </div>

   );
}

MiniChatContent.propTypes = {
   userList: PropTypes.any,
   openDesctopDialogue: PropTypes.bool,
   openDialogue: PropTypes.func,
   currentConverstionId: PropTypes.any,
   dialogue: PropTypes.any,
   activeConverstaion: PropTypes.object,
   onSetMemberOption: PropTypes.func,
   handleClickOutside: PropTypes.func,
   handleClearTimeout: PropTypes.func,
   openEditUsernameModal: PropTypes.func,
   giveFreeMembership: PropTypes.func,
   openCustomTagModal: PropTypes.func,
   isDisabledNotifications: PropTypes.bool,
   onOpenEditNickname: PropTypes.func,
}
MiniChatDialogue.propTypes = {
   openDesctopDialogue: PropTypes.bool,
   dialogue: PropTypes.any,
   activeConverstaion: PropTypes.object,
   onSetMemberOption: PropTypes.func,
   handleClickOutside: PropTypes.func,
   handleClearTimeout: PropTypes.func,
   openEditUsernameModal: PropTypes.func,
   giveFreeMembership: PropTypes.func,
   openCustomTagModal: PropTypes.func,
   isDisabledNotifications: PropTypes.bool,
   onOpenEditNickname: PropTypes.func,
   goToMemberProfile: PropTypes.func,
}


export default MiniChatContent;
