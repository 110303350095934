import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Modal from 'common/components/modules/modal';
import IconLabel from 'common/components/elements/icon-label';

function StoryDelete({
   onCloseModal,
   deleteVault,
   isMobile,
   isCloseAction,
   type,
}) {
   return (
      <Modal
         contentPosition={ isMobile ? 'bottom' : 'center' }
         onClose={ () => onCloseModal() }
         roundedModal='xlg'
         marginX='5'
         isCloseAction={ isCloseAction }
         otherClassName={ isMobile ? 'w-full' : '!w-[375px]' }
      >
         <div className='w-full p-6 flex flex-col text-center select-none'>
            <div className='flex justify-center mb-3'>
               <IconLabel
                  name='delete'
                  color='error'
               />
            </div>
            <span className='text-major text-xl font-bold'>Delete this {type}?</span>
            <span className='text-secondary leading-tight mt-4'>This {type} will be permanently deleted</span>
            <div className={ `mt-8 h-9 mx-auto` }>
               <Button
                  classNames='h-full flex leading-tight px-3 !min-w-[168px]'
                  text={ `Yes, delete ${ type }` }
                  textColor='error'
                  backgroundColor='transparent'
                  fontWeight='medium'
                  padding='none'
                  textSize='small'
                  onClick={ () => { deleteVault() } }
                  loaderColor='red'
               />
            </div>
            <div className='mt-3 h-9 mx-auto'>
               <Button
                  classNames='h-full flex leading-tight px-3 !w-[164px]'
                  text='Cancel'
                  textColor='secondary'
                  backgroundColor='transparent'
                  fontWeight='medium'
                  padding='none'
                  textSize='small'
                  onClick={ onCloseModal }
               />
            </div>
         </div>
      </Modal>
   );
}

StoryDelete.propTypes = {
   onCloseModal: PropTypes.func,
   deleteVault: PropTypes.func,
   isMobile: PropTypes.bool,
   isCloseAction: PropTypes.bool,
   type: PropTypes.string,
};

export default React.memo(StoryDelete);
