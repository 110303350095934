import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from 'common/components/elements/buttons/primary';
import ImageUploader from 'admin/views/promotions/modules/image-uploader';
import Loader from 'common/components/elements/loader/circle';
import Icon from 'common/components/elements/icons';
import MediaChip from 'common/components/modules/media-chip';
import ConfirmModal from 'common/components/modules/modal-wrapper';

import './style.scss'
import toast, { positionToasterContainer } from 'common/utils/toast';
import { getVariablesList } from '../../data';
import { AutoNotificationEdit } from '../modules';
import { getAllowedVideoExtentions } from 'common/utils/utils';
// import UploadMediaButton from 'common/components/modules/media-upload';

const AutoNotification = ({
   isMobile,
   isTweet,
   data,
   // siteLinkVarable,
   // expandTextAreaOnTweet,
   attachFile,
   screenWidth,
   onChangeData,
   onClickRevert,
   initialData,
   uploadFile,
   uploadImages,
   onClickRemoveMedia,
   uploadVideo,
   individualVariables,
   isEqualData,
   siteInfo,
   onClickDeleteTweetAttachment,
   slug,
   errors,
   bodyRef,
   isInsideModal,
   attachmentDisabled,
   disabledAttachmentTooltipText,
}) => {
   // const [showEmojis, setShowEmojis] = useState(false);
   const [openRefertConfirmModal, setOpenRefertConfirmModal] = useState(null);

   let inputRef = React.useRef(null)
   let textAreaRef = React.useRef(null)

   let editorRef = createRef(null)
   if(bodyRef){
      editorRef = bodyRef
   }

   const getAllVariables = () => {
      let variables = getVariablesList(siteInfo);
      if(individualVariables && Array.isArray(individualVariables)){
         variables = variables.filter(variable => individualVariables.includes(variable.text) || variable.base)
      }
      return variables;
   }

   const getLinkVariableName = () => {
      if(['membership_new_join_with_public_promotion', 'new_promotion', 'promotion_24_hours_left'].includes(slug)) return '<promotion-link>';
      if(['livestream_started', 'livestream_scheduled', 'tip_during_livestream'].includes(slug)) return '<livestream-link>';
      if(['membership_new_join'].includes(slug)) return '<memberships-link>';
      return attachFile ? '<site-link>' : '<content-link>';
   }

   const getTweetAttachment = () => {
      return (
         <div className='flex flex-col mt-4'>
            <span className='text-major font-medium leading-[24px]'>Tweet attachment</span>
            <span className='text-secondary my-2 text-sm leading-[20px] font-medium'>Attach an image to your tweet</span>
            {/* <span className='text-secondary my-2 text-sm leading-[20px] font-medium'>Attach an image or video to your tweet</span> */}
            <ImageUploader
               screenWidth={ screenWidth }
               isMobile={ isMobile }
               handleChangeImage={ (e) => uploadFile(e, 'photo', true) }
               source={ data.cover ? data?.cover_full_src : null }
               loading={ data?.isLoadingUpload }
               errorMessage={ data?.uploadError }
               handleDeleteImage={ onClickDeleteTweetAttachment }
               uploadImageText='Upload image'
               disabled={ attachmentDisabled }
               tooltipText={ attachmentDisabled ? disabledAttachmentTooltipText : undefined }
            />
         </div>

      )
   }

   const getMessageAttachment = () => {
      return (
         <>
            {
               data.resources_data &&  data.resources_data?.length > 0 && (
                  <div className='flex flex-wrap gap-2'>
                     {
                        data.resources_data.map((item, index) => {
                           return (
                              <PhotoVideoContent
                                 key={ index.toString() }
                                 src={ item.full_src }
                                 duration={ data.duration }
                                 type={ data.resources_type }
                                 onClickRemove={ () => onClickRemoveMedia(item) }
                                 status={ item?.status }
                                 percentage={ item?.percentage  || item?.videoUpload?.percentage }
                              />
                           )
                        })
                     }
                  </div>
               )
            }

            <div className='flex flex-wrap gap-2'>

               <UploadMediaButton
                  uploadFile={ (e) => uploadImages(e) }
                  isMobile={ isMobile }
                  text='Attach image'
                  type='photo'
                  iconName='add-photos'
                  disabled={ attachmentDisabled }
                  disabledTooltipText={ disabledAttachmentTooltipText }
               />
               {/* <UploadMediaButton
                  uploadFile={ (e) => uploadVideo(e, 'video')  }
                  isMobile={ isMobile }
                  text='Attach video'
                  type='video'
                  iconName='video-camera'
               /> */}
            </div>
         </>
      )
   }

   return (
      <>
         {
            openRefertConfirmModal && (
               <ConfirmModal
                  iconColor='action'
                  icon='revert'
                  type='confirm'
                  title='Revert to default?'
                  description='All of your edits will be deleted'
                  cancelText='Keep edits'
                  buttonText='Revert to default'
                  action={ () => {
                     if(editorRef.current?.editor?.clipboard) {
                        editorRef.current.editor.clipboard.quill.clipboard.dangerouslyPasteHTML(initialData.body, 'user');
                     }
                     onClickRevert(() => {
                        setOpenRefertConfirmModal(false)
                     });
                  } }
                  onCloseModal={ _ => setOpenRefertConfirmModal(false) }
                  buttonClassName='mt-8'
               />
            )
         }
         <AutoNotificationEdit
            isTweet={ isTweet }
            data={ data }
            isMobile={ isMobile }
            textAreaRef={ textAreaRef }
            inputRef={ inputRef }
            editorRef={ editorRef }
            onChangeTextArea={ (name, value) => {
               onChangeData(name, value);
            } }
            onChangeInput={ (name, value) => {
               onChangeData(name, value);
            } }
            onChangeEditor={ (value) => {
               if(!openRefertConfirmModal){
                  onChangeData('body', value);
               }
            } }
            variableList={ getAllVariables() }
            tweetAttachment={ attachFile && isTweet ?  getTweetAttachment() : null }
            messageAttachment={ attachFile && !isTweet ?  getMessageAttachment() : null }
            subjectEditorTop='80px'
            linkVariableName={ getLinkVariableName() }
            slug={ slug }
            bodyLimit={ ['livestream_started', 'livestream_scheduled', 'livestream'].includes(slug) ? 4000 : 3000 }
            errors={ errors }
            isInsideModal={ isInsideModal }
         />

         <div
            className={ cx({
               'h-9 inline-block': true,
               'mt-3': isTweet || (!isTweet && attachFile),
            }) }
         >
            <Button
               text='Revert to default'
               onClick={ () => setOpenRefertConfirmModal(true) }
               iconName='revert'
               textColor='secondary'
               backgroundColor='transparent'
               textSize='base'
               iconPosition='left'
               fontIconColor='secondary'
               fontIconSize='large'
               padding='none'
               disabled={ !(data.edited || !isEqualData) }
               classNames={ `flex h-full ${ !(data.edited || !isEqualData)   ? 'opacity-50 disabled-transparent' : '' }` }
            />
         </div>
      </>
   );
}


const PhotoVideoContent = ({
   status,
   type,
   duration,
   percentage,
   src,
   onClickRemove,
}) => {
   return (
      <div
         className='relative rounded-lg flex items-center !h-[100px] w-[100px] justify-center overflow-hidden bg-secondary'
      >
         {
            src && 'completed' === status  && (
               <>
                  <div
                     className=' absolute z-1 blur-[8px] h-full w-full bg-no-repeat bg-cover bg-center'
                     style={ {
                        backgroundImage: `url(${ src })`,
                     } }
                  />
                  <img src={ src } className='object-contain object-center w-auto h-auto max-h-full z-2' alt='src' />

               </>

            )
         }
         {
            'error' === status && (
               <div className='flex justify-center'>
                  <div className='w-4 h-4 flex items-cente'>
                     <Icon
                        name='attention'
                        size='sm'
                        color='error'
                     />
                  </div>
                  <span className='font-medium text-sm block text-error'>Error</span>
               </div>
            )
         }
         {
            ['loading', 'uploading', 'optimizing']?.includes(status) && (
               <div className='flex flex-col items-center'>
                  {
                     'optimizing' === status && (
                        <span className='text-major-dark text-xs'></span>
                     )
                  }
                  <Loader size={ 8 } color='white' />
                  {
                     'loading' !== status && (
                        <span className='text-major-dark text-xs text-center'>
                           {'optimizing' === status ? 'Optimizing ' : 'Uploading '}
                           ({percentage || 0 }%)
                        </span>
                     )
                  }

               </div>
            )
         }
         {
            status === 'pending' && (
               <span className='text-major-dark text-sm'>Pending</span>
            )
         }
         {
            status === 'in_optimization_queue' && (
               <span className='text-major-dark text-sm text-center'>Optimization will start soon</span>
            )
         }

         {
            'video' === type && (
               <MediaChip
                  countOrDuration={ duration }
                  type={ type }
                  className={ cx({
                     'pl-1 pr-2 py-1 absolute  chip-bg': true,
                     'bottom-0 m-2': 'photoset' === type,
                     'top-0 left-0 m-1': 'video' ===  type,
                  }) }
               />
            )
         }

         <div
            className={ cx({
               'media-item-remove absolute w-5 h-5 top-0 right-0 flex p-4 rounded-lg items-center justify-center cursor-pointer z-[3]': true,
            }) }
            role='presentation'
            onClick={ onClickRemove }
            data-tooltip-content={ 'Remove' }
            data-tooltip-id={ 'ams-top-tooltip' }
         >
            <Icon
               name='cancel'
               size='xl'
               color='major-dark'
            />
         </div>
      </div>
   ) }

const UploadMediaButton = ({
   type,
   isMobile,
   uploadFile,
   text,
   iconName,
   disabled,
   disabledTooltipText,
}) => {
   const [value, setValue] = useState('');
   let fileUploader = createRef();
   const allowedExtentions = getAllowedVideoExtentions();
   let accept = null;
   if('video' === type) {

      accept = allowedExtentions.join(',')
   } else {
      accept = 'image/png, image/jpeg, image/jpg, image'
   }
   return (
      <div
         className={ cx({
            'flex flex-col h-9': true,
         }) }
      >
         <input
            value={ value }
            name={ `${ type }_upload` }
            id={ `${ type }_upload` }
            hidden
            type='file'
            ref={ fileUploader }
            onChange={ (e) => {
               setValue('')
               if(type === 'video'){
                  const file = e.target.files[0] || {}
                  if(!allowedExtentions.includes(file.type)){
                     toast.error('You may upload only video files', isMobile ? { onOpen: () => {
                        positionToasterContainer('60px')

                     } } : {})
                     return
                  }
                  uploadFile(e)
               } else {
                  uploadFile(e)

               }
            }  }
            multiple={ 'photo' === type }
            accept={ 'photo' === type ? accept : `.mov,${ accept }` }
         />
         <div className='h-10 w-full'>

            <Button
               padding='none'
               text={ text }
               textSize='small'
               fontIconSize='extraLarge'
               iconPosition='left'
               textClassName='leading-5'
               classNames='h-full w-full pl-[14px] pr-[6px]'
               iconName={ iconName }
               backgroundColor='transparent'
               borderRadius='large'
               primaryColor
               onClick={ () => {
                  fileUploader.current.click()
               } }
               disabled={ disabled }
               dataToolTipContent={ disabled ? disabledTooltipText : undefined }
            />
         </div>
      </div>
   )
}


AutoNotification.defaultProps = {
   isInsideModal: false,
}
UploadMediaButton.propTypes = {
   type: PropTypes.string,
   text: PropTypes.string,
   iconName: PropTypes.string,
   isMobile: PropTypes.bool,
   uploadFile: PropTypes.func,
   disabled: PropTypes.bool,
   disabledTooltipText: PropTypes.string,
}
PhotoVideoContent.propTypes = {
   type: PropTypes.string,
   status: PropTypes.string,
   duration: PropTypes.number,
   percentage: PropTypes.number,
   src: PropTypes.string,
   onClickRemove: PropTypes.func,
}
AutoNotification.propTypes = {
   isMobile: PropTypes.bool,
   isTweet: PropTypes.bool,
   data: PropTypes.object,
   // siteLinkVarable: PropTypes.string,
   isEqualData: PropTypes.bool,
   // expandTextAreaOnTweet: PropTypes.bool,
   attachFile: PropTypes.bool,
   screenWidth: PropTypes.number,
   onChangeData: PropTypes.func,
   onClickRevert: PropTypes.func,
   initialData: PropTypes.object,
   uploadFile: PropTypes.func,
   uploadImages: PropTypes.func,
   uploadVideo: PropTypes.func,
   onClickRemoveMedia: PropTypes.func,
   onClickDeleteTweetAttachment: PropTypes.func,
   individualVariables: PropTypes.array,
   siteInfo: PropTypes.object,
   slug: PropTypes.string,
   errors: PropTypes.object,
   bodyRef: PropTypes.any,
   isInsideModal: PropTypes.bool,
   attachmentDisabled: PropTypes.bool,
   disabledAttachmentTooltipText: PropTypes.string,
};

export default  React.memo(AutoNotification)
