
import { createSelector } from 'reselect'

const innerStateSelector = state => state.stories;

// data selector
const storiesDataSelector = createSelector(
   innerStateSelector,
   state => state.storiesData
);

const isStoriesDataFetching = createSelector(
   innerStateSelector,
   state => state.isFetching
);

const emptyDataSelector = createSelector(
   innerStateSelector,
   state => state.isEmpty
);

// next page data selector
const isNewFetching = createSelector(
   innerStateSelector,
   state => state.isNewFetching
);

const newStoryDataSelector = createSelector(
   innerStateSelector,
   state => state.newStory
);

// filter selector
const emptyDataByFilterSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyByFilter
);
const isFetchingFilterSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingFilter
);
const isFetchingDeleteSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingDelete
);

// statistics selector
const statisticsDataSelector = createSelector(
   innerStateSelector,
   state => state.statistics
);

const isStatisticsFetching = createSelector(
   innerStateSelector,
   state => state.isStatisticsFetching
);

//shared selector
const isSharedStoryFetching = createSelector(
   innerStateSelector,
   state => state.isSharedStoryFetching
);

const sharedStorySelector = createSelector(
   innerStateSelector,
   state => state.editedStory
);
const imageStorySelector = createSelector(
   innerStateSelector,
   state => state.initImgEvent
);
const shareErrorsData = createSelector(
   innerStateSelector,
   state => state.shareErrors
);
const videoInitStorySelector = createSelector(
   innerStateSelector,
   state => state.video
);

// get story by id
const activeStorySelector = createSelector(
   innerStateSelector,
   state => state.activeStory
);
const isFetchingStorySelector = createSelector(
   innerStateSelector,
   state => state.isFetchingStory
);

const customPosterImageSelector = createSelector(
   innerStateSelector,
   state => state.customPosterImage
);
const isPublishedSelector = createSelector(
   innerStateSelector,
   state => state.isPublished
);

export {
   storiesDataSelector,
   isStoriesDataFetching,

   newStoryDataSelector,

   isSharedStoryFetching,
   sharedStorySelector,
   statisticsDataSelector,

   emptyDataSelector,
   isNewFetching,
   isStatisticsFetching,

   emptyDataByFilterSelector,
   isFetchingFilterSelector,

   imageStorySelector,
   shareErrorsData,
   videoInitStorySelector,

   activeStorySelector,
   isFetchingStorySelector,
   customPosterImageSelector,
   isPublishedSelector,
   isFetchingDeleteSelector,
}
