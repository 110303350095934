import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';

const reducersMap = {
   [types.FETCH_IS_TEMPORARY_PAGE_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      };
   },
   [types.FETCH_IS_TEMPORARY_PAGE_COMPLETED]: (state, action) => {
      return {
         ...state,
         settingsData: {
            ...state.settingsData,
            ...action.payload,
         },
         isFetching: false,
      };
   },
   [types.FETCH_IS_TEMPORARY_PAGE_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      };
   },
};

export default createReducer(initialState)(reducersMap);
