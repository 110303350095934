// FETCH transactions
export const FETCH_TRANSACTIONS_REQUEST = 'transactions/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_COMPLETED = 'transactions/FETCH_TRANSACTIONS_COMPLETED';
export const FETCH_TRANSACTIONS_FAILED = 'transactions/FETCH_TRANSACTIONS_FAILED';

export const FETCH_TRANSACTIONS_BY_FILTER_START = 'transactions/FETCH_TRANSACTIONS_BY_FILTER_START';
export const FETCH_TRANSACTIONS_BY_FILTER_COMPLETED = 'transactions/FETCH_TRANSACTIONS_BY_FILTER_COMPLETED';
export const FETCH_TRANSACTIONS_BY_FILTER_FAILED = 'transactions/FETCH_TRANSACTIONS_BY_FILTER_FAILED';

export const FETCH_DETAILS_REQUEST = 'transactions/FETCH_DETAILS_REQUEST';
export const FETCH_DETAILS_COMPLETED = 'transactions/FETCH_DETAILS_COMPLETED';
export const FETCH_DETAILS_FAILED = 'transactions/FETCH_DETAILS_FAILED';

export const FETCH_NEW_DATA_START = 'transactions/FETCH_NEW_DATA_START';
export const FETCH_NEW_DATA_COMPLETED = 'transactions/FETCH_NEW_DATA_COMPLETED';
export const FETCH_NEW_DATA_FAILED = 'transactions/FETCH_NEW_DATA_FAILED';

export const EXPORT_CSV_COMPLETED = 'transactions/EXPORT_CSV_COMPLETED';
export const EXPORT_CSV_START = 'transactions/EXPORT_CSV_START';

export const UPDATE_TRANSACTIONS_BY_MEMBER = 'transactions/UPDATE_TRANSACTIONS_BY_MEMBER';


// FETCH transactions wallet activity
export const FETCH_TRANSACTIONS_WALLET_ACTIVITY_REQUEST = 'transactions/FETCH_TRANSACTIONS_WALLET_ACTIVITY_REQUEST';
export const FETCH_TRANSACTIONS_WALLET_ACTIVITY_COMPLETED = 'transactions/FETCH_TRANSACTIONS_WALLET_ACTIVITY_COMPLETED';
export const FETCH_TRANSACTIONS_WALLET_ACTIVITY_FAILED = 'transactions/FETCH_TRANSACTIONS_WALLET_ACTIVITY_FAILED';

export const FETCH_TRANSACTIONS_WALLET_ACTIVITY_BY_FILTER_START = 'transactions/FETCH_TRANSACTIONS_WALLET_ACTIVITY_BY_FILTER_START';
export const FETCH_TRANSACTIONS_WALLET_ACTIVITY_BY_FILTER_COMPLETED = 'transactions/FETCH_TRANSACTIONS_WALLET_ACTIVITY_BY_FILTER_COMPLETED';
export const FETCH_TRANSACTIONS_WALLET_ACTIVITY_BY_FILTER_FAILED = 'transactions/FETCH_TRANSACTIONS_WALLET_ACTIVITY_BY_FILTER_FAILED';

export const FETCH_NEW_WALLET_ACTIVITY_REQUEST = 'transactions/FETCH_NEW_WALLET_ACTIVITY_REQUEST';
export const FETCH_NEW_WALLET_ACTIVITY_COMPLETED = 'transactions/FETCH_NEW_WALLET_ACTIVITY_COMPLETED';
export const FETCH_NEW_WALLET_ACTIVITY_FAILED = 'transactions/FETCH_NEW_WALLET_ACTIVITY_FAILED';

export const FETCH_WALLET_ACTIVITY_DETAILS_REQUEST = 'transactions/FETCH_WALLET_ACTIVITY_DETAILS_REQUEST';
export const FETCH_WALLET_ACTIVITY_DETAILS_COMPLETED = 'transactions/FETCH_WALLET_ACTIVITY_DETAILS_COMPLETED';
export const FETCH_WALLET_ACTIVITY_DETAILS_FAILED = 'transactions/FETCH_WALLET_ACTIVITY_DETAILS_FAILED';

export const EXPORT_WALLET_ACTIVITY_CSV_COMPLETED = 'transactions/EXPORT_WALLET_ACTIVITY_CSV_COMPLETED';
export const EXPORT_WALLET_ACTIVITY_CSV_START = 'transactions/EXPORT_WALLET_ACTIVITY_CSV_START';
export const EXPORT_WALLET_ACTIVITY_CSV_FAILED = 'transactions/EXPORT_WALLET_ACTIVITY_CSV_FAILED';
