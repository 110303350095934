export const FETCH_WITHDRAWALS_REQUEST = 'accountPayout/FETCH_WITHDRAWALS_REQUEST';
export const FETCH_WITHDRAWALS_COMPLETED = 'accountPayout/FETCH_WITHDRAWALS_COMPLETED';
export const FETCH_WITHDRAWAL_INFO_REQUEST = 'accountPayout/FETCH_WITHDRAWAL_INFO_REQUEST';
export const FETCH_WITHDRAWAL_INFO_COMPLETED = 'accountPayout/FETCH_WITHDRAWAL_INFO_COMPLETED';
export const WITHDRAWAL_NOW_REQUEST = 'accountPayout/WITHDRAWAL_NOW_REQUEST';
export const WITHDRAWAL_NOW_COMPLETED = 'accountPayout/WITHDRAWAL_NOW_COMPLETED';
export const COMMIT_WITHDRAWAL_REQUEST = 'accountPayout/COMMIT_WITHDRAWAL_REQUEST';
export const COMMIT_WITHDRAWAL_COMPLETED = 'accountPayout/COMMIT_WITHDRAWAL_COMPLETED';
export const FETCH_ADJUSTMENTS_REQUEST = 'accountPayout/FETCH_ADJUSTMENTS_REQUEST';
export const FETCH_ADJUSTMENTS_COMPLETED = 'accountPayout/FETCH_ADJUSTMENTS_COMPLETED';
export const FETCH_COLLAPSE_ADJUSTMENT_REQUEST = 'accountPayout/FETCH_COLLAPSE_ADJUSTMENT_REQUEST';
export const FETCH_COLLAPSE_ADJUSTMENT_COMPLETED = 'accountPayout/FETCH_COLLAPSE_ADJUSTMENT_COMPLETED';
export const FETCH_HOLDS_REQUEST = 'accountPayout/FETCH_HOLDS_REQUEST';
export const FETCH_HOLDS_COMPLETED = 'accountPayout/FETCH_HOLDS_COMPLETED';
export const FETCH_HISTORY_REQUEST = 'accountPayout/FETCH_HISTORY_REQUEST';
export const FETCH_HISTORY_COMPLETED = 'accountPayout/FETCH_HISTORY_COMPLETED';
export const FETCH_INIT_REQUEST = 'accountPayout/FETCH_INIT_REQUEST';
export const FETCH_INIT_COMPLETED = 'accountPayout/FETCH_INIT_COMPLETED';
export const UPDATE_AUTOMATIC_WITHDRAWAL_COMPLETED = 'accountPayout/UPDATE_AUTOMATIC_WITHDRAWAL_COMPLETED';
export const UPDATE_AUTOMATIC_WITHDRAWAL_FAILED = 'accountPayout/UPDATE_AUTOMATIC_WITHDRAWAL_FAILED';
export const UPDATE_AUTOMATIC_WITHDRAWAL_REQUEST = 'accountPayout/UPDATE_AUTOMATIC_WITHDRAWAL_REQUEST';
export const FETCH_PAYOUT_SRV_COUNTRY_REQUEST = 'accountPayout/FETCH_PAYOUT_SRV_COUNTRY_REQUEST';
export const FETCH_PAYOUT_SRV_COUNTRY_COMPLETED = 'accountPayout/FETCH_PAYOUT_SRV_COUNTRY_COMPLETED';
export const GET_PAYOUT_SRV_SERVICES_FOR_COUNTRY_REQUEST = 'accountPayout/GET_PAYOUT_SRV_SERVICES_FOR_COUNTRY_REQUEST';
export const GET_PAYOUT_SRV_SERVICES_FOR_COUNTRY_COMPLETED = 'FETCH_WITHDRAWAL_INFO_COMPLETED/GET_PAYOUT_SRV_SERVICES_FOR_COUNTRY_COMPLETED';
export const UPDATE_PAYOUT_INIT = 'accountPayout/UPDATE_PAYOUT_INIT';
export const GET_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_REQUEST = 'accountPayout/GET_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_REQUEST';
export const GET_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_COMPLETED = 'accountPayout/GET_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_COMPLETED';

export const CREATE_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_REQUEST = 'accountPayout/CREATE_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_REQUEST';
export const CREATE_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_COMPLETED = 'accountPayout/CREATE_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_COMPLETED';
export const CREATE_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_FAILED = 'accountPayout/CREATE_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_FAILED';

export const SET_WALLET_PAYOUT_METHOD_REQUEST = 'accountPayout/SET_WALLET_PAYOUT_METHOD_REQUEST';
export const SET_WALLET_PAYOUT_METHOD_COMPLETED = 'accountPayout/SET_WALLET_PAYOUT_METHOD_COMPLETED';
export const SET_WALLET_PAYOUT_METHOD_FAILED = 'accountPayout/SET_WALLET_PAYOUT_METHOD_FAILED';
export const DELETE_CURRENT_PAYOUT_METHOD_ACTION = 'accountPayout/DELETE_CURRENT_PAYOUT_METHOD_ACTION';
export const UPDATE_2FA_MODAL_STATE_ACTION = 'accountPayout/UPDATE_2FA_MODAL_STATE_ACTION';
export const UPDATE_NOW_REQUEST = 'accountPayout/UPDATE_NOW_REQUEST';
export const SET_PAYOUT_TERMS_MODAL = 'accountPayout/SET_PAYOUT_TERMS_MODAL';
export const OPEN_INCOMPLETE_LEGAL_INFO_MODAL = 'accountPayout/OPEN_INCOMPLETE_LEGAL_INFO_MODAL';
