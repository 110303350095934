import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import RadioLabel from 'common/components/elements/inputs/radio-label';
import IconLabel from 'common/components/elements/icon-label';
import './style.scss';
import { useSelector } from 'react-redux';
import { siteInfoSelector } from 'admin/state/modules/common/selectors';

const ChooseMembershipPage = ({
   onclose,
   memberships,
   selectedMembership,
   setSelectedMembership,
   addFreeMember,
   isButtonLoading,
   warningMessage,
}) => {
   const siteInfo = useSelector(siteInfoSelector)
   const isDemoMode = siteInfo?.site_status === 'demo_mode';
   return (
      <div className='choose-membership-page w-full flex flex-col items-center'>
         <div className='choose-membership-heeder flex items-center justify-start w-full h-14 z-10'>
            <div
               role='presentation'
               onClick={ onclose }
               className='h-10 w-10 flex items-center justify-center cursor-pointer mx-2'
            >
               <Icon
                  name='close'
                  size='2xl'
                  className='font-medium'
               />
            </div>
         </div>
         <IconLabel
            className='w-10 h-10'
            name='membership'
            size='2xl'
            color='action'
         />
         <span className='leading-8 mt-3 mb-6 flex items-center justify-center text-major text-xl font-semibold select-none' style={ {
            lineHeight: '30px',
         } }>Invite complimentary members</span>
         <p className='text-secondary leading-6'>Choose membership plan</p>
         <div className='memberships-container w-full p-6 '>
            <div className='bg-panel w-full plans hover:rounded-lg overflow-hidden'>
               {
                  memberships.map((membership, index) => {
                     return (
                        <div
                           key={ index.toString() }
                           className={ `` }
                        >
                           <RadioLabel
                              id={ membership  }
                              checked={ selectedMembership === membership }
                              onChange={ () => { setSelectedMembership(membership) } }
                              // paddingY='2'
                              className='py-[10px] hover:bg-hover text-major text-sm leading-5'
                           >
                              <span>
                                 {membership}-day Complimentary Membership
                              </span>
                           </RadioLabel>
                        </div>
                     );
                  })
               }
            </div>
            {warningMessage !== null && (
               <span className='text-sm text-error block leading-none select-none mt-2'>
                  You’ve reached the maximum number of complimentary memberships
               </span>
            )}
         </div>
         <div className='w-full px-6 pt-2 pb-6'>
            <Button
               borderRadius='large'
               text='Done'
               onClick={ addFreeMember }
               primaryColor
               disabled={ !selectedMembership || warningMessage !== null || isDemoMode  }
               isLoading={ isButtonLoading }
               classNames='h-9'
               textSize='small'
               dataToolTipContent={ isDemoMode ? 'Not available in demo' : undefined }
            />
         </div>
      </div>
   );
};

ChooseMembershipPage.propTypes = {
   memberships: PropTypes.array,
   selectedMembership: PropTypes.number,
   setSelectedMembership: PropTypes.func,
   addFreeMember: PropTypes.func,
   onclose: PropTypes.func,
   isButtonLoading: PropTypes.bool,
   warningMessage: PropTypes.string,
};

ChooseMembershipPage.defaultProps = {
   memberships: [],
   addFreeMember: () => {},
};

export default React.memo(ChooseMembershipPage);
