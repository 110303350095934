import React from 'react'
import PropTypes from 'prop-types'
import Button from "common/components/elements/buttons/primary";
import Icon from 'common/components/elements/icons';
import intercomMoreInfo, { MORE_INFO_ON_MUTING, MORE_INFO_ON_BLOCKING } from 'admin/utils/IntercomMoreInfo';
import DateFormatter from 'common/utils/DateFormatter';

import './style.scss'

const MuteMemberNotification = ({
   unmuteMember,
   info,
   type = 'mute',
   unblockMember,
}) => {
   const {
      muted_since,
      muted_period_in_seconds,
   } = info
   let state = {
      mute: {
         iconName: 'chat-mute',
         iconBg: 'warning',
         buttonText: 'Unmute',
         moreInfoText: 'More info on muting',
         moreInfoAction: () =>  intercomMoreInfo(MORE_INFO_ON_MUTING),
         onClickAction: unmuteMember,
         moreInfoClass: 'intercom-more-info-on-muting',
      },
      block: {
         iconName: 'block',
         iconBg: 'error',
         buttonText: 'Unblock',
         moreInfoText: 'More info on blocking',
         moreInfoAction: () =>  intercomMoreInfo(MORE_INFO_ON_BLOCKING),
         onClickAction: unblockMember,
         moreInfoClass: 'intercom-more-info-on-blocking',
      },
   }
   const item = state[type] ?  state[type] : {}
   const res = muted_period_in_seconds ?  DateFormatter.addDateWhitTz(muted_since, muted_period_in_seconds) : null
   return (
      <div className='flex flex-col px-4 pt-4 pb-2 w-full bg-panel mute-member-notification'>
         <div className='flex'>
            <div className='flex flex-1'>
               <div
                  style={ { minWidth: '28px' } }
                  className={ `flex w-7 h-7 bg-${ item.iconBg } items-center justify-center mr-3 rounded-full` }
               >
                  <Icon name={ item.iconName } color='major-dark' size='base' />
               </div>
               {
                  'mute' === type && (
                     <>
                        {
                           res && (
                              <span className='leading-tight text-major'>This member is muted and cannot send messages until { res }</span>
                           )
                        }
                        {
                           !muted_period_in_seconds && (
                              <span className='leading-18'>This member is muted and cannot send messages</span>
                           )
                        }
                     </>
                  )
               }
               {
                  'block' === type && (
                     <span className='leading-18'>This member is blocked and does not have access to your site.</span>
                  )
               }
            </div>
            <div
               className='h-9 flex mx-2'>
               <Button
                  primaryColor
                  text={ item.buttonText  }
                  onClick={ item.onClickAction }
                  classNames='w-full h-full px-2'
                  padding='none'
                  textSize='base'
                  borderRadius='large'
               />
            </div>
         </div>
         <div
            style={ { minHeight: '36px' } }
            // className='w-full mt-2 ml-7'
            className='mt-2 ml-7 inline-flex w-[fit-content]'
         >
            <Button
               primaryColor
               textPosition='left'
               text={ item.moreInfoText }
               backgroundColor='transparent'
               onClick={ item.moreInfoAction }
               classNames={ `h-full px-2 py-1 ${ item.moreInfoClass } ` }
               padding='none'
               textSize='base'
               iconName='attention-outline'
               iconPosition='left'
               fontIconColor='action'
               alignment='end'
               fontIconSize='base'
            />
         </div>
      </div>
   )
}

MuteMemberNotification.propTypes = {
   unmuteMember: PropTypes.func,
   unblockMember: PropTypes.func,
   info: PropTypes.object,
   type: PropTypes.string,
}
MuteMemberNotification.defaultProps = {
   unmuteMember: () => {},
   unblockMember: () => {},
}

export default MuteMemberNotification

