import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SecondModal from 'common/components/modules/information-and-policies/modal';
import Modal from 'common/components/modules/modal';
import PrimaryButton from 'common/components/elements/buttons/primary';
import { types } from '../admin/data';
import { getCapitalizeSiteName, getSiteLink } from 'common/utils/helper';

import "../style.scss";

const moment = require('moment');

const NavbarInformationAndPolicies = ({
   isOpen,
   onClose,
   modalCenter,
   isDemoMode,
}) => {
   const [secondModal, setSecondModal] = useState('');
   const [animate, setAnimate] = useState('up');
   let timer = null


   useEffect(() => {
      isOpen ? onOpenModal() : onCloseModal();
      return () => clearTimeout(timer);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isOpen, timer]);

   function onCloseModal()  {
      setAnimate('down')
   }
   const onOpenModal = () => {
      setAnimate('up')
   }

   const MobileContent = () => {

      const onClickButton = (type) => {
         if(!window?.Intercom) return
         switch (type) {
            case 'Content Policy':
               // () => window.open('https://help.mymember.site/en/articles/3512988-content-policy', '_blank')
               window?.Intercom('showArticle', '3512988')
               break;
            case 'Privacy Policy':
               window?.Intercom('showArticle', '9005081')
               break;
            case 'Payouts and Fees':
               window?.Intercom('showArticle', '1007426')
               break;
            case 'GDRP Policy':
               window?.Intercom('showArticle', '9005081') // need to ann
               break;
            case 'Cookie Policy':
               window?.Intercom('showArticle', '9005081') // need to ann
               break;

            default:
               setSecondModal(type)
               break;
         }
      }

      return (
         <div>

            <div className='flex justify-between items-center px-4 py-3'>
               <p className='cursor-default'>Information & Policies</p>
               <div className='inline-flex'>
                  <PrimaryButton
                     iconName='close'
                     onClick={ () => {
                        if(modalCenter){
                           onClose()
                        } else {
                           setAnimate('down')
                           timer = setTimeout(() => {
                              onClose()
                           }, 800)
                        }
                     } }
                     backgroundColor='transparent'
                     fontIconColor='major'
                     padding='none'
                  />
               </div>
            </div>

            {
               types.map((type, index) => {
                  if(isDemoMode && type === 'Site Administrator Agreement') return null

                  return (
                     <div className='border-t border-solid dark:border-divider-dark border-divider px-4 py-3' key={ index.toString() }>
                        <PrimaryButton
                           text={ type }
                           textPosition='left'
                           textSize='base'
                           iconName='arrow-right'
                           padding='none'
                           alignment='between'
                           backgroundColor='transparent'
                           textColor='action'
                           fontIconColor='major'
                           onClick={ () =>  onClickButton(type) }
                           fontWeight='normal'
                        />
                     </div>
                  )
               }
               )
            }
            <p className='text-secondary dark:text-secondary-dark px-4 py-3 border-t border-solid dark:border-divider-dark border-divider'>
               Copyright notice { moment().year() } <a className='text-action dark:text-action-dark font-normal' target='_blank' rel='noopener noreferrer' href={ getSiteLink() }><span dangerouslySetInnerHTML={ { __html: getCapitalizeSiteName() } } /></a>
            </p>
         </div>
      )
   }

   return (
      <>
         <Modal
            blurColor='modal'
            contentPosition={ modalCenter ? 'center' : 'bottom' }
            onClose={ () => {
               if(modalCenter){
                  onClose()
               } else {
                  setAnimate('down')
                  timer = setTimeout(() => {
                     onClose()
                  }, 800)
               }
            } }
            isCloseAction={ false }
            animate={ modalCenter ? null : animate }
         >
            <MobileContent
            />
         </Modal>
         {
            !!secondModal && (
               <SecondModal
                  title={ secondModal }
                  onClose={ () => {
                     setSecondModal('')
                  }
                  }
                  type='admin'
                  isCloseAction={ false }
               />
            )
         }

      </>
   );
};

NavbarInformationAndPolicies.defaultProps = {
   isOpen: false,
   modalCenter: false,
   isDemoMode: false,
   onClose: () => {},
};

NavbarInformationAndPolicies.propTypes = {
   isOpen: PropTypes.bool,
   onClose: PropTypes.func,
   modalCenter: PropTypes.bool,
   isDemoMode: PropTypes.bool,
};

export default NavbarInformationAndPolicies;
