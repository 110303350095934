import React from 'react';
import Icon from 'common/components/elements/icons';
import PropTypes from 'prop-types';
import './index.scss';
import cx from 'classnames';

const UploadBar = ({
   status,
   inProgressOptions,
   onClickBar,
}) => {
   const title = 'in_progress' === status ? 'Uploads in progress' :  'Uploads completed!';
   const {
      showArrow,
      count,
   } = inProgressOptions;
   return (
      <div
         className='w-full upload-bar'
         onClick={ onClickBar }
         role='presentation'
      >
         <div
            className='flex w-full items-center relative content'
            onClick={ () => {
            } }
            role='presentation'
         >
            <div
               className='background'
               style={ {
                  background: '#FFFFFF',
               } }
            />
            <span
               className='title'
            >
               { title }
            </span>
            <div
               className='flex h-full items-center'
            >
               {
                  'in_progress' === status && (
                     <span>
                        { count } {count > 1 ? 'videos' : 'video'}
                     </span>
                  )
               }
               {
                  showArrow && (
                     <Icon name='arrow-right'
                        size='2xl'
                        className='arrow'
                     />
                  )
               }
            </div>


         </div>
         <div className='progress-not-filled' />
         <div
            className={ cx({
               'progress-filled w-full': true,
               'bg-success': 'in_progress' !== status,
            }) }
            style={ 'in_progress' === status ? { background: 'linear-gradient(90deg, #B7DBFF 10.37%, #231FFF 42.67%, #D5D6DC 100%)' } : {} }
         />
      </div>
   );
};

UploadBar.propTypes = {
   status: PropTypes.oneOf([
      'in_progress',
      'completed',
   ]),
   inProgressOptions: PropTypes.shape({
      showArrow: PropTypes.bool.isRequired,
      count: PropTypes.number,
   }),
   onClickBar: PropTypes.func.isRequired,
};
UploadBar.defaultProps = {
   status: 'in_progress',
   onClickBar: () => { console.log('onClickBar') },
   inProgressOptions: {
      showArrow: true,
      count: 5,
   },
};

export default React.memo(UploadBar);
