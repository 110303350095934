import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/modules/modal';
import Button from 'common/components/elements/buttons/primary';

function VideoUploadLimit({
   onIncreaseMylimit,
   onCancel,
   isIncreaseMyLimitClicked,
}) {

   return (
      <Modal
         onClose={ onCancel }
         roundedModal='lg'
         contentPosition='center'
         isCloseAction={ false }
      >
         <div className='flex flex-col items-center px-3 py-5'>
            <span className='text-major dark:text-major-dark text-center text-xl font-bold leading-normal block mt-2'>Video upload limit reached </span>
            <span className='text-center text-secondary dark:text-secondary-dark leading-tight block mt-4'>You have reached the maximum number of video uploads. Deleting your existing videos will not increase your limit. Contact us to have this increased.</span>

            <div className='flex flex-col mt-8 w-full'>
               <div className='h-9'>
                  {
                     !isIncreaseMyLimitClicked ? (
                        <Button
                           text='Increase my limit'
                           padding='none'
                           classNames='h-full px-3 video_upload_limit_reached'
                           backgroundColor='transparent'
                           primaryColor
                           textSize='base'
                           fontWeight='medium'
                           onClick={ onIncreaseMylimit }
                           waveActive={ false }
                        />
                     ) : (
                        <div className='outline-none focus:outline-none focus flex items-center w-full relative select-none justify-center rounded-none p-none h-full px-3'>
                           <span className='undefined mx-2 font-medium text-base text-center text-secondary dark:text-secondary-dark'>Support team is reviewing your account.</span>
                        </div>
                     )
                  }
               </div>
               <div className='h-9 mt-3'>
                  <Button
                     text={ isIncreaseMyLimitClicked ? 'Close' : 'Cancel' }
                     padding='none'
                     classNames='h-full px-3'
                     backgroundColor='transparent'
                     textColor='secondary dark:text-secondary-dark'
                     textSize='base'
                     fontWeight='medium'
                     onClick={ onCancel }
                     waveColor='rgba(106, 106, 106, 0.8)'
                  />
               </div>
            </div>
         </div>
      </Modal>
   );
}

VideoUploadLimit.propTypes = {
   onIncreaseMylimit: PropTypes.func,
   onCancel: PropTypes.func,
   isIncreaseMyLimitClicked: PropTypes.bool,
};
VideoUploadLimit.defaultProps = {
   onIncreaseMylimit: () => {},
   onCancel: () => {},
   isIncreaseMyLimitClicked: false,
};

export default React.memo(VideoUploadLimit);
