import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';

const LastMessage = ({
   children,
   tooltipId,
   tooltipText,
}) => {
   const [showTooltip, setShowTooltip] = useState(false)
   const wrapperRef = useRef(null)
   const textRef = useRef(null)

   useEffect(() => {
      window.addEventListener('resize', updateShowTooltipState)
      return () => {
         window.removeEventListener('resize', updateShowTooltipState)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      updateShowTooltipState()
   }, [children])

   const updateShowTooltipState = () => {
      if(!wrapperRef || !textRef) return;
      if(textRef.current.scrollWidth > wrapperRef.current.offsetWidth) {
         setShowTooltip(true)
      }
   }

   return (
      <>
         <div
            className='grid max-w-full'
            data-tooltip-id={ showTooltip  ? `ams-html-tooltip-${ tooltipId }` : null }
            ref={ wrapperRef }
         >
            <span
               className='truncate panel-description text-secondary placeholder dark:text-placeholder-dark'
               ref={ textRef }
            >
               {children}
            </span>
         </div>
         {
            createPortal(
               <Tooltip
                  place='top'
                  className='ams-black-tooltips'
                  id={ showTooltip  ? `ams-html-tooltip-${ tooltipId }` : null }
                  clickable
               >
                  <span
                     onClick={ e => {
                        e.preventDefault()
                        e.stopPropagation()
                     } }
                     role='presentation'
                  >
                     {tooltipText || children}
                  </span>
               </Tooltip>
               ,
               document.getElementById('tooltips')
            )
         }
      </>
   )
}

LastMessage.propTypes = {
   children: PropTypes.any,
   tooltipId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
   tooltipText: PropTypes.string,
}

export default LastMessage;
