const initialState = {
   isFetching: true,
   isPayoutFetching: true,
   fetchingChangePassword: false,
   fetchingChangeEmail: false,
   data: {},
   payoutData: {
      data: {},
   },
   activePayout: {},
   errors: {},
   updatePasswordError: {},
   updateEmailError: {},
   detailsValue: {

   },
   payoutErrors: {
      ach: {},
      sepa: {},
      wire: {},
      paypal: {},
      cosmopayment: {},
      paxum: {},
   },
   activeDetailsType: null,
   isFetchingNotificationPreferences: true,
   notificationPreferences: {},

   isFetchUpdate: false,
   validPasswordInputs: {},
   securityTabModal: {
      isOpen: false,
      type: null,
   },
   twoFaModal: {
      isOpen: false,
      type: null,
      secretKey: null,
      token: null,
      code: null,
      codes: [],
      actionLoading: false,
      errors: null,
   },
   fetchingSetPassword: false,
   addressEditMode: 'auto',
};

export default initialState;
