import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from "common/components/elements/buttons/primary";
import Icon from 'common/components/elements/icons';
import DateFormatter from 'common/utils/DateFormatter';

import './style.scss'

const MuteMember = ({
   onClickButton,
   mutedInfo,
}) => {
   const {
      muted_period_in_seconds,
      muted_since,
   }  = mutedInfo
   let [res, setRes] = useState(muted_period_in_seconds ? DateFormatter.getDateLeftBySeconds(muted_since, muted_period_in_seconds) : null)
   useEffect(() => {
      let interval = null
      if(muted_period_in_seconds) {
         let time = 30000
         interval = setInterval(() => {
            setRes(DateFormatter.getDateLeftBySeconds(muted_since, muted_period_in_seconds))
         }, time);
      }
      return () => clearInterval(interval);
   }, [muted_period_in_seconds, muted_since, res]);

   return (
      <div className='border-2 pt-4 pb-2 w-full border-warning dark:border-warning-dark bg-panel dark:bg-panel-dark mute-member-notification rounded-lg flex flex-col'>
         <div className='flex items-center px-4'>
            <div className='flex w-6 h-6 itrms-center justify-center mr-1'>
               <Icon name='details' color='warning' darkColor='warning-dark' size='xl' />
            </div>
            <span className='leading-tight text-major dark:text-major-dark'>Your messages are muted</span>
         </div>
         <div className='flex flex-col text-secondary text-sm px-4 mt-4 mb-3 dark:text-secondary-dark '>
            {
               muted_period_in_seconds && (
                  <span className='leading-18'>Messaging capabilities will be re-enabled in {res}</span>
               )
            }
            {
               !muted_period_in_seconds && (
                  <span className='leading-18'>Messaging muted</span>
               )
            }
         </div>
         <div className='w-full h-9 px-2'>
            <Button
               primaryColor
               textPosition='left'
               text='Ok'
               backgroundColor='transparent'
               onClick={ onClickButton }
               classNames='w-full h-full'
               padding='none'
               secondIconName='1'
            />
         </div>
      </div>
   )
}

MuteMember.propTypes = {
   onClickButton: PropTypes.func,
   mutedInfo: PropTypes.object,
}

export default MuteMember
