import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import NotificationMedia from 'common/components/modules/notifications/modules/media';
import cx from 'classnames';
import DateFormatter from 'common/utils/DateFormatter';
import Icon from 'common/components/elements/icons';
import NotificationChip from 'common/components/modules/notifications/modules/chip';
import Button from 'common/components/elements/buttons/primary';


const NotificationItemV2 = ({
   notification,
   onClickCard,
   hasTopBorder,
   generateContentLink,
   goToOrderDetails,
   adminScreenName,
}) => {
   const contentLink = generateContentLink ? generateContentLink(notification) : null;

   const getState = () => {
      let state = {
         text: '',
         contentLink: null,
         icon: '',
         mainTitle: '',
         paymentDeclined: false,
      };
      switch (notification?.key) {
         case 'comment_like':
            state.text = 'liked your comment:';
            state.contentLink = contentLink;
            state.icon = 'heart-filled'
            state.iconColor = window.site_dark_bg ? '#ED6BA1' : '#FF2480'
            break;
         case 'comment_reply':
            state.text = 'replied to your comment:';
            state.contentLink = contentLink;
            state.icon = 'comment'
            state.iconColor = window.site_dark_bg ? '#6F6DE6' : '#231FFF'
            break;
         case 'order_request':
            state = {
               ...state,
               icon: 'store',
               iconColor: window.site_dark_bg ? '#6F6DE6' : '#231FFF',
               isOrderRequest: true,
               messageFromAdmin: notification?.payload?.order_request_status_note,
               chipColor: window.site_dark_bg ? '#6F6DE6' : '#231FFF',
               amount: notification?.payload?.amount,
               goToButtonText: 'Review my order',
               goToLink(){
                  goToOrderDetails(notification?.payload?.order_request_id)
               },
               isBalance: false,
               balance: notification?.payload?.balance,
            }

            if(notification?.payload?.order_request_status_name === 'waiting') {
               state.mainTitle = 'Payment declined'
               state.paymentDeclined = true
               state.iconColor = '#D92D20'
               state.text =
                     `${ !!adminScreenName ? adminScreenName : 'Item-owner' } tried to accept your ${ notification?.payload?.resource_name } order but your payment was declined:
                        <p class='my-2'>
                        ${ notification?.payload?.order_request_decline_reason || '' }
                        </p>
                        Please try the payment again when the issue has been resolved.
                        `
            } else if(notification?.payload?.order_request_status_name === 'accepted') {
               state.iconColor = '#4CCE1B'
               state.mainTitle = 'Payment successful'
               state.text = `${ notification?.payload?.admin_name } accepted your ${ notification?.payload?.resource_name } order${ !!state?.messageFromAdmin ? ':' : '' }`
            } else {
               state.iconColor = '#D92D20'
               state.mainTitle = `${ notification?.payload?.resource_name } update`
               state.text = `Your request for ${ notification?.payload?.resource_name } has been ${ notification?.payload?.order_request_status_name }`
            }
            break;

         default:
            break;
      }
      return state;
   }

   const state = getState();
   const isRead = Boolean(notification?.is_read);
   return (
      <div
         role='presentation'
         onClick={ _ => onClickCard(notification) }
         style={ {
            transition: 'all 0.5s ease',
         } }
         className={ cx({
            'flex p-4 cursor-pointer hover:bg-hover dark:hover:bg-hover-dark': true,
            'border-t dark:border-divider-dark border-divider': hasTopBorder,
            'dark:hover:bg-hover-dark hover:bg-hover': !isRead,
         }) }
      >

         <div
            data-tooltip-content={ isRead ? 'Mark as unread' : 'Mark as read' }
            data-tooltip-id='ams-left-tooltip'
            style={ {
               transition: 'all 0.5s ease',
            } }
            className={ cx({
               'shadow dark:shadow-dark rounded-full border border-disabled dark:border-disabled-dark w-4 min-w-[16px] h-4 opacity-1 flex': true,
               'dark:bg-action bg-action': !isRead,
               'skin-primary-background': !isRead && !window.site_dark_bg,
               'bg-panel dark:bg-panel-dark': isRead,
            }) }
         />

         <div
            style={ {
               backgroundImage: `url(${ notification?.user?.avatar_compressed_src })`,
            } }
            className='h-10 w-10 min-w-[40px] flex items-center bg-no-repeat bg-contain bg-center relative ml-2' >
            <div
               className='shadow dark:shadow-dark absolute w-[18.46px] h-[18.46px] left-[21.54px] top-[21.54px] bg-panel dark:bg-panel-dark rounded-full flex items-center justify-center'>
               <Icon
                  primaryColor={ state.iconColor }
                  name={ state.icon }
                  style={ {
                     fontSize: '10px',
                  } } />
            </div>
         </div>
         <div className='flex flex-col flex-1 ml-3'>
            <div className='flex mb-4 w-full'>
               <div className='flex flex-col pr-4'>
                  <span className='text-major dark:text-major-dark'>
                     {
                        !!state?.isOrderRequest ?
                           <div className='flex flex-col gap-1'>
                              <p className='font-semibold'>{state?.mainTitle}</p>
                              <p
                                 className=''
                                 dangerouslySetInnerHTML={ { __html: state?.text } }
                              />
                              {
                                 state?.messageFromAdmin &&
                                 <p className='mt-2'>{state?.messageFromAdmin}</p>
                              }
                           </div>
                           :
                           <>
                              <span className='font-medium '>{notification?.user?.username}&nbsp;</span>
                              {state?.text}&nbsp;
                              <LinkWraper
                                 link={ state.contentLink }
                                 className='text-secondary dark:text-secondary-dark  hover:font-medium hover:underline'
                              >
                                 {notification?.payload?.text}
                              </LinkWraper>
                           </>
                     }
                  </span>
                  {
                     !!state?.amount &&
                     <NotificationChip
                        color={ state?.chipColor }
                        amount={ state?.amount }
                        className={ `!my-3` }
                        balance={ state?.balance }
                        isBalance={ state?.isBalance }
                     />
                  }
                  {
                     !!state?.goToButtonText &&
                     <Button
                        text={ state?.goToButtonText }
                        textSize='base'
                        backgroundColor='transparent'
                        classNames='h-full !w-fit'
                        padding='none'
                        textMarginX='0'
                        primaryColor
                        onClick={ state?.goToLink }
                     />
                  }
               </div>
               <div className='flex ml-auto'>
                  {
                     !!notification?.payload?.resource_id && !!notification?.payload?.resource_src && (
                        <LinkWraper link={ state.contentLink }>
                           <NotificationMedia
                              src={ notification?.payload?.resource_src }
                              type={ notification?.payload?.type }
                              isResourceDeleted={ notification?.payload?.is_resource_deleted }
                              onClick={ () => {} }
                           />
                        </LinkWraper>
                     )
                  }

               </div>

            </div>
            <span className='text-xs leading-[18px] text-secondary dark:text-secondary-dark text-start'>{DateFormatter.getDateByTimzeonFormat(notification?.created_at)}</span>
         </div>

      </div>
   );
};

const LinkWraper = ({ link, children, className }) => {
   if(link){
      return (
         <Link
            to={ link }
            className={ className }
         >
            { children && children }
         </Link>
      )
   }

   return (
      <>{children && children} </>
   )
}

NotificationItemV2.defaultProps = {
   notification: {},
   onClickCard: () => {},
   goToOrderDetails: () => {},
};
LinkWraper.defaultProps = {
   className: '',
};

NotificationItemV2.propTypes = {
   notification: PropTypes.object,
   onClickCard: PropTypes.func,
   hasTopBorder: PropTypes.bool,
   generateContentLink: PropTypes.func,
   goToOrderDetails: PropTypes.func,
   adminScreenName: PropTypes.any,
};
LinkWraper.propTypes = {
   link: PropTypes.string,
   className: PropTypes.string,
   children: PropTypes.any,
};

export default NotificationItemV2;
