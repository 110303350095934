import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Filter from 'common/components/responsive/filter';
import Search from 'common/components/elements/inputs/search';
import Button from 'common/components/elements/buttons/primary';
import MobileFilter from '../../mobile-filter';

const NotificationsFilters = ({
   isMobile,
   onFilterChange,
   allFilters,
   setSearchValue,
   markAllAsRead,
   isDisabledMarkAllAsReadButton,
   isMember,
   isShowFilters,
   setShowFilters,
}) => {

   const genearteFilterData = () => {
      let categories = [
         { name: 'Chat messages & likes', value: 'chat_messages' },
         { name: 'Comments', value: 'comments' },
      ];

      let statuses = [
         { name: 'Unseen', value: 'unseen' },
         { name: 'Unreplied ', value: 'unreplied' },
      ];
      if(isMember){
         categories = [
            { name: 'Comment replies', value: 'comment_replies' },
            { name: 'Comment likes', value: 'comment_likes' },
         ];

         statuses = [
            { name: 'All ', value: 'all' },
            { name: 'Unseen', value: 'unseen' },
         ];
      }
      let sortData = [
         { name: 'Newest', value: 'date_added:desc' },
         { name: 'Oldest ', value: 'date_added:asc'  },
      ];
      return { categories, statuses, sortData }
   }
   const getDefaultCategories = () => {
      let res = {};
      const categories = allFilters?.categories || '';
      if(categories){
         const value = categories.split(',');
         const name = `${ value.length } Selected`;
         res = {
            value,
            name,
         }
      }
      return res
   }
   const getDefaultValue = (type) => {
      let res = {};
      let dataKey = '';
      switch (type) {
         case 'status':
            res = isMember ? { name: 'All ', value: 'all' } : {};
            dataKey = 'statuses';
            break;

         case 'sortBy':
            res =  {
               name: 'Newest',
               value: 'date_added:desc',
            };
            dataKey = 'sortData'
            break;

         default:
            break;
      }
      const data = genearteFilterData()?.[dataKey] || [];
      const value = allFilters?.[type] || '';
      if(value){
         res = data.find(e => e.value === value);
      }
      return res
   }

   const [filters, setFilters] = useState({
      categories: getDefaultCategories(),
      status: getDefaultValue('status'),
      sortBy: getDefaultValue('sortBy'),
   })


   function onMultiplFilter(value, name, type, bool){
      let data = []
      if(filters[type] && filters[type].value && Array.isArray(filters[type].value)) {
         data = filters[type].value
      }
      if(data && Array.isArray(data) && data.includes(value)) {
         data = data.filter(i => i !== value)
      } else {
         data = [...data, value]
      }
      setFilters({
         ...filters,
         [type]: {
            name: data.length === 0 ? null : `${ data.length } Selected`,
            value: data,
         },
      })
      if(bool) {
         onFilterChange(data, type)
      }
   }

   function onSimpleFilter(value, name, type) {
      setFilters({
         ...filters,
         [type]: {
            name,
            value,
         },
      })
   }

   function onClearFilter(isSimple, type, defaultValue) {
      let emptyValue = isSimple ? {} : [];
      if(defaultValue) {
         emptyValue = defaultValue
      }
      setFilters({
         ...filters,
         [type]: emptyValue,
      })
      onFilterChange(isSimple ? '' : [], type)
   }

   // function onCloseContent(type) {
   //    let data = {};
   //    let filterName = filters[type].name;
   //    let newFilters = [];
   //    newFilters = filters[type].value ? filters[type].value : [];
   //    if(type === 'categories') {
   //       newFilters = !!allFilters[type] ? allFilters[type].split(',') : [];
   //       filterName =  `${ newFilters.length > 0 ? newFilters.length + ' Selected' : '' }`;
   //    } else if(type === 'sortBy') {
   //       filterName = !!allFilters[type] ? allFilters[type] === 'date_added:desc' ? 'Newest' : 'Oldest' : '';
   //       newFilters = !!allFilters[type] ? allFilters[type] : [];
   //    } else if(type === 'status') {
   //       filterName = !!allFilters[type] ? allFilters[type].charAt(0).toUpperCase() + allFilters[type].slice(1) : '';
   //       newFilters = !!allFilters[type] ? allFilters[type] : [];
   //    } else  {
   //       newFilters = !!allFilters[type] ? allFilters[type] : [];
   //    }
   //    if(!!newFilters.length){
   //       data = {
   //          name: filterName,
   //          value: newFilters,
   //       }
   //    }
   //    setFilters({
   //       ...filters,
   //       [type]: { ...data },
   //    });
   // }

   const calculateFiltersCount = () => {
      let count = 0

      Object.keys(filters).forEach(key => {
         if(Array.isArray(filters[key]?.value)) {
            count += filters[key].value.length
         } else if(typeof filters[key]?.value === 'string') {
            if(key === 'status' && filters[key].value !== 'unseen') {
               count += 1
            }
            if(key === 'sortBy' && filters[key].value !== 'date_added:desc') {
               count += 1
            }
         }
      })

      return count
   }

   return (
      <div className='px-4 mt-4'>
         <div className='w-full flex gap-3' >
            <Search
               onChange={ (value) => {
                  setSearchValue(value);
                  onFilterChange(value, 'search')
               } }
               placeholder='Search notifications'
               value={ allFilters?.search || '' }
               isDebounce
            />
            {
               (isMember || (!isMobile && !isMember)) ? (
                  <div
                     className={ `h-10 header-icon-min-w flex items-center justify-center cursor-pointer relative  header_icon_color_major` }
                     onClick={ setShowFilters }
                     role='presentation'
                     data-tooltip-content={ !isMobile ? 'Filter & sort' : '' }
                     data-tooltip-id='ams-bottom-tooltip'
                  >
                     <Button
                        classNames={ `h-full w-full px-2` }
                        backgroundColor={ isShowFilters ? 'hover dark:bg-hover-dark' : 'transparent' }
                        padding='none'
                        iconName='filter-new'
                        fontIconColor='major'
                        darkIconcolor='major-dark'
                        textColor={ `major dark:text-major-dark` }
                        fontIconSize='doubleLarge'
                        textSize='base'
                        iconPosition='left'
                        borderRadius='large'
                     />
                  </div>
               ) : (
                  <MobileFilter
                     sortOptions={ genearteFilterData().sortData }
                     selectedSortValue={ filters.sortBy.value }
                     sortName='sortBy'
                     onFilterChange={ (value, name, filterName, isMultiple) => {
                        if(!isMultiple) {
                           onSimpleFilter(value, name, filterName);
                           onFilterChange([value], filterName)
                        } else {
                           onMultiplFilter(value, name, filterName, true)
                        }
                     } }
                     filtersCount={ calculateFiltersCount() }
                     filtersList={ [
                        {
                           title: !!Object.keys(filters.categories).length ? 'Notifications' : 'All notifications',
                           isMultiple: true,
                           filterName: 'categories',
                           optionNameKey: 'name',
                           optionValueKey: 'value',
                           options: genearteFilterData().categories,
                           selectedFilter: filters.categories,
                        },
                        {
                           title: 'Statuses',
                           isMultiple: true,
                           filterName: 'status',
                           optionNameKey: 'name',
                           optionValueKey: 'value',
                           options: genearteFilterData().statuses,
                           selectedFilter: filters.status || {},
                        },
                     ] }
                  />
               )
            }
         </div>
         {
            ((isMember && isShowFilters) || (!isMobile && !isMember && isShowFilters)) && (
               <div
                  className={ cx({
                     'notification-filter w-full flex flex-wrap gap-2 text-base mt-3': true,
                     'flex-nowrap overflow-x-auto pr-3': isMobile,
                  }) }
               >
                  <div className='notification-filter-item'>
                     <Filter
                        isMultiplSelect={ true }
                        isSearchable={ false }
                        activeSelectedValue={ filters.categories }
                        name={ !!Object.keys(filters.categories).length ? 'Notifications' : 'All notifications' }
                        type='filter'
                        data={ genearteFilterData().categories }
                        desktopType={ isMobile ? "mobile" : 'descktop' }
                        className='filter-item-v2'
                        height='10'
                        onFilterChange={ (value, name, bool) => onMultiplFilter(value, name, 'categories', bool) }
                        onClickShowResult={ () => onFilterChange(filters.categories.value, 'categories') }
                        onClickClear={ () => onClearFilter(false, 'categories') }
                        isAutoSave
                        showNewStyles
                        version='v2'
                     />
                  </div>
                  <div className='notification-filter-item'>
                     <Filter
                        name={ filters.sortBy?.value ? filters.sortBy?.name  : 'Sort' }
                        type='filter'
                        filterNameKey='name'
                        filterValueKey='value'
                        data={ genearteFilterData().sortData }
                        isMultiplSelect={ false }
                        activeSelectedValue={ { value: filters.sortBy.value, name: '' } }
                        desktopType={ isMobile ? 'mobile' : 'descktop' }
                        className='filter-item-v2'
                        height='10'
                        contentStyles={ { width: '200px' } }
                        onFilterChange={ (value, name) => {
                           onSimpleFilter(value, name, 'sortBy');
                           onFilterChange([value], 'sortBy')
                        } }
                        showNewStyles
                        version='v2'
                     />
                  </div>
                  <div className='notification-filter-item'>
                     <Filter
                        name={ 'Statuses' }
                        type='filter'
                        filterNameKey='name'
                        filterValueKey='value'
                        data={  genearteFilterData().statuses }
                        activeSelectedValue={ filters.status }
                        isMultiplSelect={ true }
                        desktopType={ isMobile ? 'mobile' : 'descktop' }
                        className='filter-item-v2'
                        height='10'
                        onFilterChange={ (value, name, bool) => onMultiplFilter(value, name, 'status', bool) }
                        onClickShowResult={ () => onFilterChange(filters.status.value, 'status') }
                        onClickClear={ () => onClearFilter(false, 'status') }
                        showNewStyles
                        version='v2'
                        isAutoSave
                     />
                  </div>
               </div>
            )
         }
         {
            !isDisabledMarkAllAsReadButton && (
               <div className='flex items-center justify-end mx-2 mt-2'>
                  <div className='w-30 h-9'>
                     <Button
                        text='Mark all as read'
                        backgroundColor='transparent'
                        textSize='small'
                        primaryColor={ !isMember }
                        textColor='secondary dark:text-secondary-dark '
                        fontIconSize='large'
                        fontIconColor='secondary dark:text-secondary-dark '
                        iconPosition='left'
                        iconName={ isMember ? 'done' : '' }
                        padding='none'
                        textMarginX={ !isMember ? '0' : ' ml-2' }
                        classNames='justify-center items-center h-full'
                        onClick={ markAllAsRead }
                        disabled={ isDisabledMarkAllAsReadButton }
                     />
                  </div>
               </div>
            )
         }
      </div>
   );
};

NotificationsFilters.propTypes = {
   isMobile: PropTypes.bool,
   onFilterChange: PropTypes.func,
   allFilters: PropTypes.object,
   setSearchValue: PropTypes.func,
   markAllAsRead: PropTypes.func,
   isMember: PropTypes.bool,
   isDisabledMarkAllAsReadButton: PropTypes.bool,
   isShowFilters: PropTypes.bool,
   setShowFilters: PropTypes.func,
};
NotificationsFilters.defaultProps = {
   onFilterChange: () => {},
}

export default React.memo(NotificationsFilters);
