
const uploadBarStatusSelector = (state) => {
   const { videos } = state.uploads;
   let status = 'completed';
   const inProgressStatuses = [
      'uploading',
      'pending',
      'paused',
   ];
   videos.forEach((video) => {
      if(inProgressStatuses.includes(video.uploadingStatus)) {
         status = 'in_progress';
      }
   });
   return status;
};

const uploadingVideosCountSelector = (state) => {
   const { videos } = state.uploads;
   const inProgressStatuses = [
      'uploading',
      'pending',
      'paused',
   ];
   let count = 0;
   videos.forEach((video) => {
      if(inProgressStatuses.includes(video.uploadingStatus)) {
         count++;
      }
   });
   return count;
};

const complitedUploadsCountSelector = (state) => {
   const { videos, photosets } = state.uploads;
   let count = videos.length + photosets.length;
   return count;
};

const videoUploadsSelector = state => state.uploads.videos;
const isVideoUploadLimitReachedSelector = state => state.uploads.isVideoUploadLimitReached;
const isIncreaseMyLimitClickedSelector = state => state.uploads.isIncreaseMyLimitClicked;
const photosetUploadsSelector = state => state.uploads.photosets;
const isDistoryFetchingSelector = state => state.uploads.isDistoryFetching;

export {
   uploadBarStatusSelector,
   videoUploadsSelector,
   isVideoUploadLimitReachedSelector,
   uploadingVideosCountSelector,
   isIncreaseMyLimitClickedSelector,

   photosetUploadsSelector,
   complitedUploadsCountSelector,
   isDistoryFetchingSelector,
}
