import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import cx from 'classnames';
import NotificationsFilters from '../filters';
import CircleLoader from 'common/components/elements/loader/circle';
import NotificationItems from 'common/components/modules/notifications/items';
import NotificationItemV2 from 'common/components/modules/notifications/items-v2';
import EmptyState from 'common/components/modules/empty-states';
import Button from 'common/components/elements/buttons/primary';

const NotificationsContent = ({
   isMobile,
   notifications,
   isAdmin,
   onCloseModal,
   onScroll,
   isFetchingNew,
   goToNotificationPreferences,
   onClickCard,
   onClickLike,
   onClickReply,
   replyNotification,
   avatars,
   filters,
   onFilterChange,
   markAllAsRead,
   isFetching,
   isEmpty,
   isEmptyByFilter,
   isFetchingByFilter,
   sendMessage,
   isDisabledMarkAllAsReadButton,
   userAvatar,
   goToResourceOrMemberPage,
   isMember,
   generateContentLink,
   screenWidth,
   goToOrderDetails,
   siteInfo,
}) => {
   const [searchValue, setSearchValue] = useState(filters.search || '');
   const [isShowFilters, setShowFilters] = useState(true);

   return (
      <div
         className={ cx({
            'notification-content bg-panel dark:bg-panel-dark w-full flex flex-col text-base': true,
            '': isMobile,
            'h-full': !isMobile,
            'h-screen': isMobile,
         }) }
      >
         <NotificationHeader
            onCloseModal={ onCloseModal }
            goToNotificationPreferences={ goToNotificationPreferences }
            isMobile={ isMobile }
            isMember={ isMember }
         />

         {
            isFetching ? (
               <div
                  className='flex w-full py-6 h-full justify-center items-center'>
                  <CircleLoader />
               </div>
            ) : (
               isEmpty ? (
                  <div className='flex items-center w-full m-auto'>
                     <EmptyState
                        noContentText='You don’t have any notifications'
                        noContentTextColor='secondary dark:text-secondary-dark '
                     />
                  </div>
               ) : (
                  <>
                     <div className='w-full'>
                        <NotificationsFilters
                           allFilters={ filters }
                           isMobile={ screenWidth <= 1024 }
                           onFilterChange={ onFilterChange }
                           markAllAsRead={ markAllAsRead }
                           setSearchValue={ setSearchValue }
                           isDisabledMarkAllAsReadButton={ isDisabledMarkAllAsReadButton }
                           isMember={ isMember }
                           setShowFilters={ () => setShowFilters(!isShowFilters) }
                           isShowFilters={ isShowFilters }
                        />
                     </div>
                     {
                        isFetchingByFilter ? (
                           <div className='flex w-full py-6 h-full justify-center items-center'>
                              <CircleLoader />
                           </div>
                        ) : (
                           isEmptyByFilter ? (
                              <div
                                 className={ cx({
                                    'flex items-center w-full m-auto': true,
                                    'pt-[108px]': !isShowFilters,
                                 }) }
                              >
                                 <EmptyState
                                    type={ !!searchValue ? 'search' : 'filter' }
                                    result={ searchValue }
                                    noResultsClassName='dark:text-secondary-dark '
                                 />
                              </div>
                           ) : (
                              <div
                                 onScroll={ onScroll }
                                 className={ cx({
                                    'notification-content-list flex flex-1 flex-col overflow-y-auto h-full': true,
                                    'px-2 pb-2': !isMember,
                                    'pt-4': isShowFilters,
                                    'ams-custom-scrool': isMember,
                                 }) }
                              >
                                 {
                                    notifications && notifications.map((notification, index) => {
                                       // console.log(notification);
                                       if(isMember){
                                          return (
                                             <NotificationItemV2
                                                key={ notification.id }
                                                notification={ notification }
                                                onClickCard={ onClickCard }
                                                hasTopBorder={ index !== 0 }
                                                generateContentLink={ generateContentLink }
                                                goToOrderDetails={ goToOrderDetails }
                                                adminScreenName={ siteInfo?.admin_screen_name }
                                             />
                                          )
                                       }
                                       return (
                                          <NotificationItems
                                             key={ notification.id }
                                             notification={ notification }
                                             isAdmin={ isAdmin }
                                             onClickCard={ onClickCard }
                                             onClickLike={ onClickLike }
                                             onClickReply={ () => onClickReply(notification) }
                                             avatars={ avatars }
                                             isMobile={ isMobile }
                                             replyNotification={ replyNotification }
                                             sendMessage={ (...arg) => {
                                                sendMessage(...arg);
                                                onClickReply(null)
                                             } }
                                             cancelReplyMessage={ () => onClickReply(null) }
                                             userAvatar={ userAvatar }
                                             goToResourceOrMemberPage={ goToResourceOrMemberPage }
                                             goToOrderDetails={ goToOrderDetails }
                                          />
                                       )
                                    })
                                 }
                                 {
                                    isFetchingNew && (
                                       <div
                                          className='flex w-full py-6 h-24 justify-center items-center'>
                                          <CircleLoader />
                                       </div>
                                    )
                                 }
                              </div>
                           )
                        )
                     }
                  </>
               )
            )
         }
      </div>
   );
};

const NotificationHeader = ({
   onCloseModal,
   goToNotificationPreferences,
   isMobile,
   isMember,
}) => {
   return (
      <div className='flex notification-header h-14 items-center justify-between z-20'>
         <div className={ `px-2 h-14 w-full bg-panel dark:bg-panel-dark flex items-center justify-between z-15 border-b dark:border-divider-dark border-divider` }>
            <div className='flex'>
               <div
                  className={ `h-10 header-icon-min-w flex items-center justify-center cursor-pointer relative  header_icon_color_major` }
                  onClick={ () => onCloseModal(true) }
                  role='presentation'
               >
                  <Button
                     classNames={ `h-full w-full px-2` }
                     backgroundColor='transparent'
                     padding='none'
                     iconName='close'
                     fontIconColor='major'
                     darkIconcolor='major-dark'
                     textColor={ `major dark:text-major-dark` }
                     fontIconSize='doubleLarge'
                     textSize='base'
                     iconPosition='left'
                  />
               </div>
               <div className='flex items-center justify-center pl-2 truncate'>
                  <span className='text-xl select-none  font-bold max-h-full dark:text-major-dark text-major'> Notifications </span>
               </div>
            </div>
            <div className='flex'>

               {
                  !isMember && (
                     <div
                        className={ `h-10 header-icon-min-w flex items-center justify-center cursor-pointer relative  header_icon_color_major` }
                        onClick={ () => {
                           goToNotificationPreferences();
                           onCloseModal(true);
                        } }
                        role='presentation'
                        data-tooltip-content={ !isMobile ? 'Notification preferences' : '' }
                        data-tooltip-id='ams-bottom-tooltip'
                     >
                        <Button
                           classNames={ `h-full w-full px-2` }
                           backgroundColor='transparent'
                           padding='none'
                           iconName='settings'
                           fontIconColor='major'
                           darkIconcolor='major-dark'
                           textColor={ `major dark:text-major-dark` }
                           fontIconSize='doubleLarge'
                           textSize='base'
                           iconPosition='left'
                        />
                     </div>

                  )
               }
            </div>
         </div>
      </div>
   )
}

NotificationHeader.propTypes = {
   onCloseModal: PropTypes.func,
   goToNotificationPreferences: PropTypes.func,
   isMobile: PropTypes.bool,
   isMember: PropTypes.bool,
};

NotificationsContent.propTypes = {
   isMobile: PropTypes.bool,
   isAdmin: PropTypes.bool,
   notifications: PropTypes.array,
   onCloseModal: PropTypes.func,
   goToNotificationPreferences: PropTypes.func,
   onScroll: PropTypes.func,
   isFetchingNew: PropTypes.bool,
   onClickCard: PropTypes.func,
   onClickLike: PropTypes.func,
   sendMessage: PropTypes.func,
   avatars: PropTypes.array,
   filters: PropTypes.object,
   onFilterChange: PropTypes.func,
   markAllAsRead: PropTypes.func,
   isFetching: PropTypes.bool,
   isEmpty: PropTypes.bool,
   isEmptyByFilter: PropTypes.bool,
   isFetchingByFilter: PropTypes.bool,
   isDisabledMarkAllAsReadButton: PropTypes.bool,
   userAvatar: PropTypes.string,
   onClickReply: PropTypes.func,
   replyNotification: PropTypes.object,
   goToResourceOrMemberPage: PropTypes.func,
   isMember: PropTypes.bool,
   generateContentLink: PropTypes.func,
   screenWidth: PropTypes.number,
   goToOrderDetails: PropTypes.func,
   siteInfo: PropTypes.object,
};

NotificationsContent.defaultProps = {
   onCloseModal: () => {},
   markAllAsRead: () => {},
   goToOrderDetails: () => {},
}

export default React.memo(NotificationsContent);
