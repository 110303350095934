import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UploadPhotos from 'admin/containers/pages/photosets/new-photosets/photos-add'

import {
} from 'admin/state/modules/photosets/operations';
import {
   clearDataAction,
} from 'admin/state/modules/photosets/actions';

import {
   uploadPhotosetsImage,
   photosMultipleActionsDelete,
   createMultiplePhotos,
} from 'admin/api/AuthApi'

import { screenWidthSelector } from 'admin/state/modules/common/selectors';
import { createNewOperation } from 'admin/state/modules/photosets/operations';
import toast from 'common/utils/toast';
import Modal from 'common/components/modules/modal';
import ConfirmDialog from 'common/components/modules/confirm-dialog';
class MediaCreateContainer extends Component {
   static propTypes = {
      createNewPhotosets: PropTypes.func,
      onCloseModal: PropTypes.func,
      photosEvent: PropTypes.object,
   }

   constructor(){
      super()
      this.state = {
         isUploadedPhotos: true,
         isEdit: true,
         photos: [],
         cover: {},
         isButtonLoading: false,
         discardLoading: false,
         lockedImageIsBlur: true,
      }
      this.newPhotosetsData = {
         photos: [],
         cover: null,
      }
   }
   index = 0;
   isContinue = true;
   targetIndex = 0;
   isStop = false;
   targetFiles = {};
   uploadingPhotos = [];

   componentDidMount() {
      const { photosEvent } = this.props
      if(!!photosEvent) {
         this.onFileChange(photosEvent)
      }
   }


   /////////          start photos uploading

   onFileChange = async e => {
      this.isStop = false;
      this.targetFiles = {
         ...e.target.files,
      }
      const targetFiles =  Object.values(this.targetFiles)
      await this.addFakeData(targetFiles);
      await this.recFunction();
      let uploadingPhotos = [...this.uploadingPhotos].filter(el => el.status === 'loading' && !el.path);
      if(!uploadingPhotos.length) {
         await this.savePhotos();
      }
   }

   addFakeData = (targetFiles) => {
      for(let i = 0; i < targetFiles.length; i++) {
         let file = targetFiles[targetFiles.length - i - 1];
         this.index = this.uploadingPhotos.length + i
         const type = file.name.split('.');
         const validFormat = ['jpeg', 'jpg', 'png'];
         const isValid = validFormat.indexOf(type[type.length - 1].toLowerCase()) !== -1 && type.length > 1;
         if(isValid) {
            this.onChangeFakePhotos(null, this.index, 'loading', file);
         } else {
            toast.error('You may upload only photo files')
         }
      }
   }

   recFunction = async () => {
      let uploadingPhotos = [...this.uploadingPhotos].filter(el => el.status === 'loading' && !el.path);
      if(!uploadingPhotos.length || this.isStop) {
         this.targetIndex = 0;
         return
      }
      console.log(this.isContinue);

      if(this.isContinue) {
         this.isContinue = false;
         let photo = uploadingPhotos[0];
         let file = photo.file;
         this.targetIndex = photo.index;
         const data = new FormData();
         data.append('file', file);
         try {
            const { data: { path } } =  await uploadPhotosetsImage(data);
            await this.onChangeFakePhotos(path, this.targetIndex, 'done', file)
         } catch (error) {
            toast.error('Something went wrong')
            await this.onChangeFakePhotos({}, this.targetIndex, 'error')
         }
         this.isContinue = true;
         await this.recFunction();
      }
   }

   onChangeFakePhotos = (data, index, status, file) => {
      const { photos } = this.state
      this.uploadingPhotos = [...photos];
      switch (status) {
         case 'loading':
            let item = {
               cf_path: null,
               status: 'loading',
               index: index,
               file: file,
            }
            this.uploadingPhotos.push(item)
            this.setState((oldState) => {
               const newState = { photos: [{ ...item }, ...oldState.photos] }
               return {
                  ...oldState,
                  ...newState,
               }
            })
            break;
         case 'done':
            let newPhotos = [...this.state.photos].map(elm => {
               const { ...item } = elm
               if(item.index === index) {
                  let newItem = {
                     ...item,
                     path: data,
                     status: 'loading',
                  };
                  return newItem;
               }
               return item;
            })
            this.uploadingPhotos = [...newPhotos];
            this.setState((oldState) => {
               return { photos: newPhotos }
            })
            break;
         case 'error':
            let newData = [...photos].filter(item => item.index !== index)
            this.uploadingPhotos = [...newData];
            this.setState((oldState) => {
               return { photos: newData }
            })
            break;
         default:
            break;
      }
   }

   savePhotos = async () => {
      let newPhotos = [...this.uploadingPhotos].filter(item => !item.id && item.path);
      newPhotos.sort(function(a, b) {
         return a.index - b.index;
      })
      let newData = newPhotos.map((item, index) => {
         return {
            file_name: item.path,
            photo_collection_id: null,
            order: index,
            index,
         }
      })
      if(!!newData.length) {
         await createMultiplePhotos({ data: newData })
            .then(res => {
               let data = res.data;
               let photos = [...this.uploadingPhotos];
               for(let item in data) {
                  photos = [...photos].map(photo => {
                     if(photo.path === item.toString()) {
                        return { ...data[item] };
                     }
                     return photo;
                  })
               }
               this.uploadingPhotos = [...photos]
               this.setState((oldState) => {
                  let cover = oldState.cover;
                  if(!oldState.cover || (oldState.cover && !oldState.cover.id)) {
                     cover = photos[0]
                  }
                  return {
                     cover,
                     photos: [...photos],
                  }
               })
            })
            .catch(err => {
               console.log(err);
            })
      }
   }
   /////////          end photos uploading


   handleDeleteImage = (photo) => {
      const newPhotos = [...this.uploadingPhotos].filter(i => i.id !== photo.id)
      this.uploadingPhotos = [...newPhotos];
      this.setState((old) => {
         let newState = {}
         if(photo.id === old.cover.id){
            newState = {
               cover: newPhotos[0],
            }
         }
         return {
            ...newState,
            photos: newPhotos,
         }
      }, () => {
         toast.remove('FileS has been deleted');
      })
   }

   onModalClose = async () => {
      const {
         photos,
      } = this.state;
      try {
         this.isStop = true;
         this.setState({ discardLoading: true })
         let ids = [];
         for(let i = 0; i < photos.length; i++) {
            const photo = photos[i]
            if(photo.id){
               ids.push(photo.id);
            }
         }
         if(!!ids.length){
            await photosMultipleActionsDelete({ data: [...ids] });
         }
         this.uploadingPhotos = [];
         this.setState({ photos: [], discardLoading: false })
      } catch (error) {

      }
      const { onCloseModal } = this.props;
      onCloseModal()
   }

   goToContinue = async () => {
      const {
         cover,
         photos,
         lockedImageIsBlur,
      } = this.state
      let brockImgs = photos.filter(i => i.status === 'error')

      if(photos.length < 1 || brockImgs.length > 0) {
         return  toast.error('Upload at least 1 photo to continue')
      } else if(!cover || !photos.find(elm => elm.id === cover.id)) {
         return toast.error('Select cover image')
      } else {
         this.setState({ isButtonLoading: true });
         // eslint-disable-next-line array-callback-return
         const photosData = photos.reduce((acc, curent) => {
            if(curent) {
               let curentOrder = {
                  id: curent.id,
                  order: curent.order,
               }
               acc.orederData.push(curentOrder)
               acc.ids.push(curent.id)
               return acc
            }
         }, { orederData: [], ids: [] })
         this.newPhotosetsData = {
            ...this.newPhotosetsData,
            photos: photosData.ids,
            cover: cover.id,
            order: photosData.orederData,
            locked_image_is_blur: lockedImageIsBlur,
         }
         const { createNewPhotosets } = this.props;

         await createNewPhotosets(this.newPhotosetsData, photos)

      }
   }
   onSortEnd = (data) => {
      const orderedData = data.map((item, index) => {
         return {
            ...item,
            id: item.id,
            order: data.length - index,
         };
      });
      this.uploadingPhotos = [...orderedData];
      this.setState({
         photos: orderedData,
      }, () => {
         toast.remove('Files have been reordered');
      })
   }


   selectCoverPhoto = (cover) => {
      this.setState({
         cover,
      }, () => {
         toast.remove('Thumbnail has been changed');
      })
   }


   showConfirmModal = () => {
      const { photos } = this.state
      if(photos.length > 0) {
         this.openCloseConfirmModal()
      } else {
         this.onModalClose()
      }
   }
   openCloseConfirmModal = () => {
      this.setState((oldStat) => {
         return {
            isOpenConfirModal: !oldStat.isOpenConfirModal,
         }
      })
   }

   updateData = (data) => {
      this.setState({
         ...data,
      })
   }

   render() {
      const {
         isUploadedPhotos,
         isEdit,
         photos,
         cover,
         isButtonLoading,
         isOpenConfirModal,
         discardLoading,
         lockedImageIsBlur,
         screenWidth,
      } = this.state;
      return (
         <>
            <UploadPhotos
               height={ window.innerHeight }
               onFileChange={ this.onFileChange }
               onDeleteImage={ this.handleDeleteImage }
               onCloseModal={ this.showConfirmModal }
               onContinue={ this.goToContinue }
               onCloseRedirect={ this.showConfirmModal }
               photos={ photos }
               photosLength={ photos.length }
               cover={ cover }
               photpsModalButton={ isUploadedPhotos && isEdit }
               selectCoverPhoto={ this.selectCoverPhoto }
               onSortEnd={ this.onSortEnd }
               isEditebl={ false }
               isButtonLoading={ isButtonLoading }
               lockedImageIsBlur={ lockedImageIsBlur }
               onClickUpdate={ (name, value) => this.updateData({ lockedImageIsBlur: value }) }
               screenWidth={ screenWidth }
            />
            {
               isOpenConfirModal && (
                  <Modal contentPosition='center' onClose={ this.showConfirmModal }>
                     <ConfirmDialog
                        modalInfo={ {
                           description: 'Do you want to leave? Your changes will be discarded',
                           actionText: 'Leave and discard changes',
                           action: () =>  this.onModalClose(),
                        } }
                        closeConfirmDialog={ this.openCloseConfirmModal }
                        cancelText='Keep editing'
                        isLoading={ discardLoading }
                        iconName='retry'
                     />
                  </Modal>
               )
            }
         </>
      )
   }
}

const mapStateToProps = state => ({
   screenWidth: screenWidthSelector(state),
});

const mapDispatchToProps = dispatch => ({
   createNewPhotosets: (data, photos) => dispatch(createNewOperation(data, photos)),
   clearData: data => dispatch(clearDataAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaCreateContainer);
