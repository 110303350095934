export const FETCH_AFFILIATE_PROGRAM_DETAILS_START = "affiliate-program/FETCH_AFFILIATE_PROGRAM_DETAILS_START";
export const FETCH_AFFILIATE_PROGRAM_DETAILS_COMPLETED = "affiliate-program/FETCH_AFFILIATE_PROGRAM_DETAILS_COMPLETED";
export const FETCH_AFFILIATE_PROGRAM_DETAILS_FAILED = "affiliate-program/FETCH_AFFILIATE_PROGRAM_DETAILS_FAILED";
export const UPDATE_AFFILIATE_PROGRAM_START = "affiliate-program/UPDATE_AFFILIATE_PROGRAM_START";
export const UPDATE_AFFILIATE_PROGRAM_COMPLETED = "affiliate-program/UPDATE_AFFILIATE_PROGRAM_COMPLETED";
export const UPDATE_AFFILIATE_PROGRAM_FAILED = "affiliate-program/UPDATE_AFFILIATE_PROGRAM_FAILED";
export const SET_AFFILIATE_PROGRAM_ERRORS = "affiliate-program/SET_AFFILIATE_PROGRAM_ERRORS";

export const FETCH_AFFILIATES_LIST_DATA_START = 'affiliate-program/FETCH_AFFILIATES_LIST_DATA_START'
export const FETCH_AFFILIATES_LIST_DATA_COMPLETED = 'affiliate-program/FETCH_AFFILIATES_LIST_DATA_COMPLETED'
export const FETCH_AFFILIATES_LIST_DATA_FAILED = 'affiliate-program/FETCH_AFFILIATES_LIST_DATA_FAILED'
