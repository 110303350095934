export const SELECT_CARD = "mass-message/SELECT_CARD";
export const SELECT_MEMBERSHIP = "mass-message/SELECT_MEMBERSHIP";
export const SET_SEND_DATA = "mass-message/SET_SEND_DATA";

export const SEND_MESSAGE_START = "mass-message/SEND_MESSAGE_START";
export const SEND_MESSAGE_COMPLETED = "mass-message/SEND_MESSAGE_COMPLETED";
export const SEND_MESSAGE_FAILED = "mass-message/SEND_MESSAGE_FAILED";

export const GET_RECEIVERS_STATISTICS_START = "mass-message/GET_RECEIVERS_STATISTICS_START";
export const GET_RECEIVERS_STATISTICS_COMPLETED = "mass-message/GET_RECEIVERS_STATISTICS_COMPLETED";
export const GET_RECEIVERS_STATISTICS_FAILED = "mass-message/GET_RECEIVERS_STATISTICS_FAILED";

export const GET_ACTIVE_MEMBERSHIPS_START = "mass-message/GET_ACTIVE_MEMBERSHIPS_START";
export const GET_ACTIVE_MEMBERSHIPS_COMPLETED = "mass-message/GET_ACTIVE_MEMBERSHIPS_COMPLETED";
export const GET_ACTIVE_MEMBERSHIPS_FAILED = "mass-message/GET_ACTIVE_MEMBERSHIPS_FAILED";

export const SET_ACTIVE_MEMBERSHIPS = "mass-message/SET_ACTIVE_MEMBERSHIPS";
export const GET_INITIAL_DATA = "mass-message/GET_INITIAL_DATA";
export const SET_ACTIVE_TAB = "mass-message/SET_ACTIVE_TAB";

export const TAGS_TO_BE_SENT = "mass-message/TAGS_TO_BE_SENT"

/// mailing list

export const GET_MAILING_LIST_MEMBERS_START = "mass-message/GET_MAILING_LIST_MEMBERS_START";
export const GET_MAILING_LIST_MEMBERS_COMPLETED = "mass-message/GET_MAILING_LIST_MEMBERS_COMPLETED";
export const GET_MAILING_LIST_MEMBERS_FAILED = "mass-message/GET_MAILING_LIST_MEMBERS_FAILED";

export const GET_NEW_MAILING_LIST_MEMBERS_START = "mass-message/GET_NEW_MAILING_LIST_MEMBERS_START";
export const GET_NEW_MAILING_LIST_MEMBERS_COMPLETED = "mass-message/GET_NEW_MAILING_LIST_MEMBERS_COMPLETED";
export const GET_NEW_MAILING_LIST_MEMBERS_FAILED = "mass-message/GET_NEW_MAILING_LIST_MEMBERS_FAILED";

export const GET_FILTER_MEMBERS_MAILING_LIST_START = "mass-message/GET_FILTER_MEMBERS_MAILING_LIST_START";
export const GET_FILTER_MEMBERS_MAILING_LIST_COMPLETED = "mass-message/GET_FILTER_MEMBERS_MAILING_LIST_COMPLETED";
export const GET_FILTER_MEMBERS_MAILING_LIST_FAILED = "mass-message/GET_FILTER_MEMBERS_MAILING_LIST_FAILED";

export const GET_MAILING_LIST_NON_MEMBERS_START = "mass-message/GET_MAILING_LIST_NON_MEMBERS_START";
export const GET_MAILING_LIST_NON_MEMBERS_COMPLETED = "mass-message/GET_MAILING_LIST_NON_MEMBERS_COMPLETED";
export const GET_MAILING_LIST_NON_MEMBERS_FAILED = "mass-message/GET_MAILING_LIST_NON_MEMBERS_FAILED";

export const GET_NEW_MAILING_LIST_NON_MEMBERS_START = "mass-message/GET_NEW_MAILING_LIST_NON_MEMBERS_START";
export const GET_NEW_MAILING_LIST_NON_MEMBERS_COMPLETED = "mass-message/GET_NEW_MAILING_LIST_NON_MEMBERS_COMPLETED";
export const GET_NEW_MAILING_LIST_NON_MEMBERS_FAILED = "mass-message/GET_NEW_MAILING_LIST_NON_MEMBERS_FAILED";

export const GET_FILTER_NON_MEMBERS_MAILING_LIST_START = "mass-message/GET_FILTER_NON_MEMBERS_MAILING_LIST_START";
export const GET_FILTER_NON_MEMBERS_MAILING_LIST_COMPLETED = "mass-message/GET_FILTER_NON_MEMBERS_MAILING_LIST_COMPLETED";
export const GET_FILTER_NON_MEMBERS_MAILING_LIST_FAILED = "mass-message/GET_FILTER_NON_MEMBERS_MAILING_LIST_FAILED";

// mass message

export const INIT_SENT_MESSAGES_START = "mass-message/INIT_SENT_MESSAGES_START";
export const INIT_SENT_MESSAGES_COMPLETED = "mass-message/INIT_SENT_MESSAGES_COMPLETED";
export const INIT_SENT_MESSAGES_FAILED = "mass-message/INIT_SENT_MESSAGES_FAILED";
export const UPDATE_RECIPIENT_USERNAME = "mass-message/UPDATE_RECIPIENT_USERNAME";
export const DELETE_PRIVATE_MESSAGE = "mass-message/DELETE_PRIVATE_MESSAGE";

export const FETCH_MESSAGES_NEXT_PAGE_START = "mass-message/FETCH_MESSAGES_NEXT_PAGE_START";
export const FETCH_MESSAGES_NEXT_PAGE_COMPLETED = "mass-message/FETCH_MESSAGES_NEXT_PAGE_COMPLETED";
export const FETCH_MESSAGES_NEXT_PAGE_FAILED = "mass-message/FETCH_MESSAGES_NEXT_PAGE_FAILED";
export const CLEAR_MESSAGES_DATA = "mass-message/FETCH_MESSAGES_NEXT_PAGE_FAILED";

// filter

export const FETCH_MESSAGES_BY_FILTER_START = "mass-message/FETCH_MESSAGES_BY_FILTER_START";
export const FETCH_MESSAGES_BY_FILTER_COMPLETED = "mass-message/FETCH_MESSAGES_BY_FILTER_COMPLETED";
export const FETCH_MESSAGES_BY_FILTER_FAILED = "mass-message/FETCH_MESSAGES_BY_FILTER_FAILED";

export const UNSEDN_MESSAGE_ACTION = "mass-message/UNSEDN_MESSAGE_ACTION";
export const UNSEDN_MESSAGE_COMPLETED = "mass-message/UNSEDN_MESSAGE_COMPLETED";
export const UPDATE_MASS_MESSAGE_ACTION = "mass-message/UPDATE_MASS_MESSAGE_ACTION";
