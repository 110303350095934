import React from 'react';
import PropTypes from 'prop-types';
import GenerateColor from 'common/utils/generateTextColor.js'
import NumberFormatter from 'common/utils/NumberFormatter';

const numberFormatter = new NumberFormatter();

const NotificationChip = ({
   color,
   amount,
   balance,
   isBalance,
   className,
}) => {

   return (
      <>
         <div
            style={ {
               width: 'max-content',
               minWidth: '90px',
               padding: '3px 8px',
               backgroundColor: GenerateColor.generateRGBColor(color, 0.12),
            } }
            className={ `rounded-full mt-2 mb-1 flex items-center justify-center ${ className }` }
         >
            <span
               style={ {
                  color: GenerateColor.generateRGBColor(color, 1),
               } }
               className='text-major dark:text-major-dark font-normal leading-tight text-center text-sm'>
               { amount === 'free' ? 'FREE' : numberFormatter.withDots(amount) + ' USD'}</span>
         </div>
         {
            (!!balance || balance === 0) &&
               <span className='text-sm text-secondary dark:text-secondary-dark block leading-tight mb-3'>WALLET BALNACE:   { numberFormatter.withDots(balance) } USD</span>
         }
         {
            !!isBalance &&
               <span className='text-sm text-secondary dark:text-secondary-dark block leading-tight mb-3'>TOTAL SPENT:   { numberFormatter.withDots(balance) } USD</span>
         }
      </>
   );
};

NotificationChip.propTypes = {
   color: PropTypes.string,
   amount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
   balance: PropTypes.number,
   isBalance: PropTypes.bool,
   className: PropTypes.string,
};
NotificationChip.defaultProps = {
   color: window.skinPrimaryColor,
   isBalance: false,
   className: '',
};

export default React.memo(NotificationChip);
