import React from 'react'
import PropTypes from 'prop-types'
import LiveChatList from 'common/components/modules/chat/notifications-list'
import ModalDropDown from  'common/components/modules/chat/chat-modal-drop-down'
import ChatItemLoading from 'common/components/modules/chat/list-loading';
import ChatItem from 'common/components/modules/chat/list-item';
import cx from 'classnames';

const LiveChatListContainer = ({
   isMobile,
   onClickChat,
   list,
   gotToChatList,
   groupConversation,
   isFetchConversation,
   isFetchNewConversation,
   onScrollList,
   isSocketConnected,
   onCloseModal,
   authUser,
   openChatDropDown,
   onlyPmList,
   openDkChatNotification,
}) => {
   if(onlyPmList) {
      return (
         <div
            className={ cx({
               'flex-1 overflow-y-auto': true,
               'ams-custom-scrool': !isMobile,
            }) }
            onScroll={ onScrollList }
         >
            {
               !isFetchConversation && (
                  <>
                     {
                        !!list && list.length > 0 && list.map(item => {
                           if(!item) return null
                           return (
                              <ChatItem
                                 key={ item && item.chat_room_id }
                                 chat={ item }
                                 isGroup={ !!item.isGroup }
                                 onClick={ () => onClickChat(item) }
                                 authUserUuid={ authUser.uuid }
                                 showMembersCount={ false }
                                 hiddeBorder={ list.length === 1 }
                                 isFromConversations={ true }
                                 isAdmin
                              />
                           )
                        })
                     }
                     {
                        !!isFetchNewConversation && (
                           <ChatItemLoading count={ 3 } />
                        )
                     }
                     {/* {
                        list.length === 0 && (
                           <div className='flex flex-col items-center my-auto justify-center'>
                              <span className='leading-tight text-placeholder mt-4 text-center dark:text-secondary-dark '
                              >
                                 Please start your stream to use Livestream chat</span>
                           </div>
                        )
                     } */}
                  </>
               )
            }
            {
               !!isFetchConversation && (
                  <ChatItemLoading count={ 6 } />
               )
            }
         </div >
      )
   }
   if(isMobile) {
      return null
   }
   return (
      <ModalDropDown
         isDropDown
         isMobile={ isMobile }
         contentPosition='bottom'
         onCloseModal={ onCloseModal }
         tooltipId={ 'admin-chat-notification' }
         openChatDropDown={ openChatDropDown }
         isOpen={ openDkChatNotification }

      >
         <LiveChatList
            list={ [{ ...groupConversation, isGroup: true }, ...list] }
            onClickChat={ (data) => {
               onClickChat(data)
            } }
            onClickButton={ gotToChatList }
            isMobile={ isMobile }
            isFetchConversation={ isFetchConversation }
            isFetchNewConversation={ isFetchNewConversation }
            onScrollList={ onScrollList }
            isSocketConnected={ isSocketConnected }
            authUser={ authUser }
            isAdmin={ true }
         />
      </ModalDropDown>
   )
}

LiveChatListContainer.propTypes = {
   isMobile: PropTypes.bool,
   onClickChat: PropTypes.func,
   gotToChatList: PropTypes.func,
   list: PropTypes.array,
   isFetchConversation: PropTypes.bool,
   isFetchNewConversation: PropTypes.bool,
   isSocketConnected: PropTypes.bool,
   groupConversation: PropTypes.object,
   onScrollList: PropTypes.func,
   onCloseModal: PropTypes.func,
   authUser: PropTypes.object,
   openChatDropDown: PropTypes.func,
   onlyPmList: PropTypes.bool,
   openDkChatNotification: PropTypes.bool,
}

export default LiveChatListContainer




