import React from 'react'
import ChatToolsItem from '../item';
import PropTypes from 'prop-types'

const ChatMessageAction = ({
   onClickAction,
   showDownloadOption,
   isUnlockedContent,
   showReplyOption,
   showCopyOption,
   showEditOption,
   showUnsendOption,
   deleteButtonText,
}) => {
   return (
      <div
         className='rounded-lg border dark:border-divider-dark border-divider overflow-hidden py-1 bg-panel dark:bg-panel-dark'>
         {
            showReplyOption &&
            <ChatToolsItem
               onActionClick={ () => onClickAction('reply') }
            />
         }
         {
            showEditOption &&
            <ChatToolsItem
               iconName='pencil'
               actionText='Edit'
               onActionClick={ () => onClickAction('edit') }
            />
         }
         {
            showCopyOption &&
            <ChatToolsItem
               iconName='copy'
               actionText='Copy'
               onActionClick={ () => onClickAction('copy') }

            />
         }
         {
            showUnsendOption &&
               <ChatToolsItem
                  iconName='delete'
                  actionText={ deleteButtonText }
                  color='error'
                  iconColor='error'
                  onActionClick={ () => onClickAction('delete') }
                  disabled={ isUnlockedContent }
                  tooltipContent={ isUnlockedContent ? 'Unable to unsend message with unlocked content' : null }
               />
         }
         {
            showDownloadOption &&
               <ChatToolsItem
                  iconName='download'
                  actionText='Download'
                  onActionClick={ () => onClickAction('download') }

               />
         }
      </div>
   )
}
ChatMessageAction.propTypes = {
   onClickAction: PropTypes.func,
   showDownloadOption: PropTypes.bool,
   isUnlockedContent: PropTypes.bool,
   showReplyOption: PropTypes.bool,
   showCopyOption: PropTypes.bool,
   showEditOption: PropTypes.bool,
   showUnsendOption: PropTypes.bool,
   deleteButtonText: PropTypes.string,
}

ChatMessageAction.defaultProps = {
   deleteButtonText: 'Unsend',
}

export default ChatMessageAction

