import * as types from './types';

const openStepsModalCompleted = () => ({
   type: types.OPEN_STEPS_MODAL_COMPLETED,
});

const siteStatusChangeCompleted  = data => ({
   type: types.SITES_STATUS_CHANGE,
   payload: data,
});

const setDialogCompleted  = data => ({
   type: types.SET_DIALOG_COMPLETED,
   payload: data,
});

const toggleIsFetching = data => ({
   type: types.TOGGLE_IS_FETCHING,
   payload: data,
});

const setAddContentModal = bool => ({
   type: types.SET_ADD_CONTENT_MODAL,
   payload: bool,
});

export {
   openStepsModalCompleted,
   siteStatusChangeCompleted,
   setDialogCompleted,
   toggleIsFetching,
   setAddContentModal,
};
