const initialState = {
   ipListData: {},
   isEmptyIpList: false,
   isFetchingIpList: true,
   ipModal: {
      isOpen: false,
      type: null,
      buttonDisabled: false,
      item: {},
   },
   ipErrorMesage: {},
   fetchNewIpList: false,
   regions: {
      canadian_states: [],
      countries: [],
      us_states: [],
   },
   initialRegionsData: {
      canadian_states: [],
      countries: [],
      us_states: [],
   },
   blockRegions: {
      canadian_states: [],
      countries: [],
      us_states: [],
   },
   isFetchingRegions: true,
   isEmptyBlockMembers: false,
   isFetchingBlockMembers: false,
   blockMembersList: [],
   updateMember: {
      openModal: false,
      buttonDisabled: false,
      data: {},
   },
   vpnBlocked: false,
   isFetchingUpdateVpnBlocking: false,
};

export default initialState;
