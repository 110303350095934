// FETCH TIPS
export const FETCH_COLORS_REQUEST = 'mySite/FETCH_COLORS_REQUEST';
export const FETCH_COLORS_COMPLETED = 'mySite/FETCH_COLORS_COMPLETED';
export const FETCH_COLORS_FAILED = 'mySite/FETCH_COLORS_FAILED';
export const CHANGE_COLORS_COMPLETED = 'mySite/CHANGE_COLORS_COMPLETED';
export const UPDATE_CUSTOM_PALETTE_COMPLETED = 'mySite/UPDATE_CUSTOM_PALETTE_COMPLETED';
export const SELECT_COLORS_REQUEST = 'mySite/SELECT_COLORS_REQUEST';
export const SELECT_COLORS_FAILED = 'mySite/SELECT_COLORS_FAILED';
export const DELETE_MY_SITE_FETCHING = 'mySite/DELETE_MY_SITE_FETCHING';

//logo
export const FETCH_LOGO_DATA_COMPLETED = 'mySite/FETCH_LOGO_DATA_COMPLETED';
export const FETCH_LOGO_DATA_FETCHING = 'mySite/FETCH_LOGO_DATA_FETCHING';
export const SET_UPLOAD_LOGO = 'mySite/SET_UPLOAD_LOGO';
export const UPDATE_LOGO_DATA = 'mySite/UPDATE_LOGO_DATA';
