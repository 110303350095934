import { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import Button from 'common/components/elements/buttons/primary';
import Modal from 'common/components/modules/modal';
import cx from 'classnames'

const ImageUploaderOptions = ({
   onClickDelete,
   isMobile,
   onCloseModal,
   onClickCapture,
   onClickUpload,
   hasSource,
   screenWidth,
   uploadImageText,
}) => {
   const [animate, setAnimate] = useState('up');
   let timeout = null;

   function closeOnClickOutside(e) {
      if(!isMobile && !e.target.closest(`.promotions-uploader-options`)) {
         onCloseModal()
      }
   }

   function modalClose(e){
      setAnimate('down')
      timeout = setTimeout(() => {
         onCloseModal()
      }, 800)
   }

   useEffect(() => {
      return () => {
         if(timeout){
            clearTimeout(timeout)
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [timeout]);
   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };   // eslint-disable-next-line
    }, [onCloseModal, isMobile]);


   const view = <Content
      isMobile={ isMobile }
      onClickDelete={ () => {
         onCloseModal()
         onClickDelete()
      } }
      onClickCapture={ onClickCapture }
      onClickUpload={ () => {
         onCloseModal()
         onClickUpload()
      } }
      hasSource={ hasSource }
      screenWidth={ screenWidth }
      uploadImageText={ uploadImageText }
   />

   if(isMobile) {
      return (
         <Modal
            onClose={ modalClose }
            animate={ isMobile ? animate : null }
         >
            {view}
         </Modal>
      )
   }
   return (
      <>
         { view }
      </>
   )
}

const Content = ({
   isMobile,
   onClickDelete,
   onClickCapture,
   onClickUpload,
   hasSource,
   screenWidth,
   uploadImageText,
}) => {
   return (
      <div
         className={ cx({
            'promotions-uploader-options py-1 overflow-hidden': true,
            'w-full': isMobile,
            [`w-[240px] absolute ${ hasSource ? 'top-[52px]' : 'top-11' } right-0 z-15 bg-panel shadow dark:shadow-dark rounded-lg`]: !isMobile,
         }) }
         role='presentation'
         onClick={ (e) => {
            e.stopPropagation()
            e.preventDefault()
         } }
      >
         {
            screenWidth > 1024 && (
               <>
                  <div
                     className='w-full h-10 hover:bg-hover'
                  >
                     <Button
                        classNames='h-full w-full gap-3 pl-4'
                        text='Capture'
                        backgroundColor='transparent'
                        iconPosition='left'
                        iconName='photo-camera'
                        fontIconColor='secondary'
                        textColor='major'
                        fontWeight='normal'
                        textSize='small'
                        textMarginX='0'
                        textPosition='left'
                        padding='none'
                        alignment='end'
                        fontIconSize='extraLarge'
                        onClick={ onClickCapture }
                        waveActive={ false }
                     />
                  </div>
                  <div
                     className='w-full h-10 hover:bg-hover'
                  >
                     <Button
                        classNames='h-full w-full gap-3 pl-4'
                        text={ uploadImageText }
                        backgroundColor='transparent'
                        iconPosition='left'
                        iconName='upload'
                        fontIconColor='secondary'
                        textColor='major'
                        fontWeight='normal'
                        textSize='small'
                        textMarginX='0'
                        textPosition='left'
                        padding='none'
                        alignment='end'
                        fontIconSize='extraLarge'
                        onClick={ (e) => {
                           e.stopPropagation()
                           onClickUpload()
                        }  }
                        waveActive={ false }
                     />
                  </div>
               </>
            )
         }
         {
            hasSource && (
               <div
                  className='w-full h-10 hover:bg-hover'
               >
                  <Button
                     classNames='h-full w-full gap-3 pl-4'
                     text='Delete'
                     backgroundColor='transparent'
                     iconPosition='left'
                     iconName='delete'
                     fontIconColor='error'
                     textColor='error'
                     fontWeight='normal'
                     textSize='small'
                     textMarginX='0'
                     textPosition='left'
                     padding='none'
                     alignment='end'
                     fontIconSize='extraLarge'
                     onClick={ (e) => {
                        e.stopPropagation()
                        onClickDelete()
                     }  }
                     waveActive={ false }
                  />
               </div>
            )
         }
      </div>
   )
}

Content.defaultProps = {
   uploadImageText: 'Upload photo',
}
Content.propTypes = {
   isMobile: PropTypes.bool,
   onClickDelete: PropTypes.func,
   onClickCapture: PropTypes.func,
   onClickUpload: PropTypes.func,
   hasSource: PropTypes.bool,
   screenWidth: PropTypes.number,
   uploadImageText: PropTypes.string,
}

ImageUploaderOptions.propTypes = {
   onClickDelete: PropTypes.func,
   isMobile: PropTypes.bool,
   onCloseModal: PropTypes.func,
   onClickUpload: PropTypes.func,
   onClickCapture: PropTypes.func,
   hasSource: PropTypes.bool,
   screenWidth: PropTypes.number,
   uploadImageText: PropTypes.string,
}

export default ImageUploaderOptions
