import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import './style.scss'

const NotificationActions = ({
   isLiked,
   onClickReply,
   onClickLike,
}) => {
   return (
      <div className='flex'>
         <div className='flex justify-center'>
            <Button
               text='Reply'
               textColor='major dark:text-major-dark'
               textSize='base'
               iconName='comment'
               iconPosition='left'
               fontIconColor='major'
               darkIconcolor='major-dark'
               fontIconSize='extraLarge'
               backgroundColor='transparent'
               textMarginX='1'
               padding='none'
               classNames='h-9'
               onClick={ onClickReply }
               textClassName='whitespace-nowrap'
            />
         </div>
         <div className='flex justify-center ml-4'>
            <Button
               textColor='major dark:text-major-dark'
               textSize='base'
               iconName={ isLiked ? 'heart-filled' : 'heart-outline' }
               iconPosition='left'
               fontIconColor={ isLiked ? 'likes' : 'major dark:text-major-dark' }
               darkIconColor={ isLiked ? 'likes-dark' : 'major-dark' }
               fontIconSize='doubleLarge'
               backgroundColor='transparent'
               textMarginX='1'
               padding='none'
               classNames=' notification-like-comment h-9'
               onClick={ onClickLike }
            />
         </div>
      </div>
   );
};

NotificationActions.defaultProps = {
   onClickReply: () => {},
   onClickLike: () => {},
};

NotificationActions.propTypes = {
   onClickReply: PropTypes.func,
   onClickLike: PropTypes.func,
   isLiked: PropTypes.bool,
};

export default React.memo(NotificationActions);
