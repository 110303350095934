// FETCH TIPS
export const FETCH_STORY_COMMENTS_REQUEST = 'comments/FETCH_STORY_COMMENTS_REQUEST';
export const FETCH_STORY_COMMENTS_COMPLETED = 'comments/FETCH_STORY_COMMENTS_COMPLETED';
export const FETCH_STORY_COMMENTS_FAILED = 'comments/FETCH_STORY_COMMENTS_FAILED';

export const FETCH_COMMENTS_BY_FILTER_REQUEST = 'comments/FETCH_COMMENTS_BY_FILTER_REQUEST';
export const FETCH_COMMENTS_BY_FILTER_COMPLETED = 'comments/FETCH_COMMENTS_BY_FILTER_COMPLETED';
export const FETCH_COMMENTS_BY_FILTER_FAILED = 'comments/FETCH_COMMENTS_BY_FILTER_FAILED';

export const FETCH_NEW_COMMENTS_REQUEST = 'comments/FETCH_NEW_COMMENTS_REQUEST';
export const FETCH_NEW_COMMENTS_COMPLETED = 'comments/FETCH_NEW_COMMENTS_COMPLETED';
export const FETCH_NEW_COMMENTS_FAILED = 'comments/FETCH_NEW_COMMENTS_FAILED';

export const COMMENT_LIKE_REQUEST = 'comments/COMMENT_LIKE_REQUEST';
export const COMMENT_LIKE_COMPLETED = 'comments/COMMENT_LIKE_COMPLETED';
export const COMMENT_LIKE_FAILED = 'comments/COMMENT_LIKE_FAILED';

export const COMMENT_DELETE_REQUEST = 'comments/COMMENT_DELETE_REQUEST';
export const COMMENT_DELETE_COMPLETED = 'comments/COMMENT_DELETE_COMPLETED';
export const COMMENT_DELETE_FAILED = 'comments/COMMENT_DELETE_FAILED';

export const SEND_COMMENT_REQUEST = 'comments/SEND_COMMENT_REQUEST';
export const SEND_COMMENT_COMPLETED = 'comments/SEND_COMMENT_COMPLETED';
export const SEND_COMMENT_FAILED = 'comments/SEND_COMMENT_FAILED';

export const COMMENT_UPDATE_COMPLETED = 'comments/COMMENT_UPDATE_COMPLETED';
export const COMMENT_MEMBER_UPDATE_COMPLETED = 'comments/COMMENT_MEMBER_UPDATE_COMPLETED';
export const UPDATE_COMMENT_MEMBER_BY_UUID = 'comments/UPDATE_COMMENT_MEMBER_BY_UUID';
export const UPDATE_COMMENT_MEMBERS_FAILED = 'comments/UPDATE_COMMENT_MEMBERS_FAILED';
