import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_OUTGOING_NOTIFICATIONS_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },

   [types.FETCH_OUTGOING_NOTIFICATIONS_COMPLETED]: (state, action) => {
      const { payload: { data } } = action;
      return {
         ...state,
         isFetching: false,
         settings: data,
      }
   },

   [types.FETCH_OUTGOING_NOTIFICATIONS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.UPDATE_OUTGOING_NOTIFICATIONS_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingUpdate: true,
         updateError: null,
      }
   },

   [types.UPDATE_OUTGOING_NOTIFICATIONS_COMPLETED]: (state, action) => {
      const { payload: { slug, data } } = action;
      let newState = {}
      const oldSettingBySlug = state.settingBySlug;
      if(oldSettingBySlug && oldSettingBySlug?.slug === slug)
         newState = {
            settingBySlug: {
               ...oldSettingBySlug,
               ...data,
            },
         }
      return {
         ...state,
         isFetchingUpdate: false,
         settings: {
            ...state.settings,
            [slug]: {
               ...state.settings[slug],
               ...data,
            },
         },
         ...newState,
      }
   },

   [types.UPDATE_OUTGOING_NOTIFICATIONS_FAILED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetchingUpdate: false,
         updateError: payload,
      }
   },
   [types.CLEAR_UPDATE_ERROR_STATE]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         updateError: { ...state.updateError, ...payload },
      }
   },

   [types.FETCH_OUTGOING_NOTIFICATION_BY_SLUG_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingBySlug: true,
         updateError: null,
      }
   },

   [types.FETCH_OUTGOING_NOTIFICATION_BY_SLUG_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetchingBySlug: false,
         settingBySlug: payload,
      }
   },

   [types.FETCH_OUTGOING_NOTIFICATION_BY_SLUG_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingBySlug: false,
      }
   },

   [types.SET_CUSTOM_NOTIFICATION_MODAL]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         customNotificationModal: {
            ...state.customNotificationModal,
            ...payload,
         },
      }
   },

   [types.ADD_CUSTOM_NOTIFICATIONS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         settings: {
            ...state.settings,
            ...payload,
         },
         customNotificationModal: {
            resourceType: null,
            isOpen: false,
            action: null,
            buttonLoading: false,
            slug: null,
            resources: [],
            isFetchingNextPage: false,
            isFetching: true,
            currentPage: 0,
            pages: 0,
         },
      }
   },

   [types.DELETE_CUSTOM_NOTIFICATION_COMPLETED]: (state, action) => {
      const { payload } = action;
      let data = { ...state.settings };
      if(payload in data){
         delete data[payload]
      }
      return {
         ...state,
         settings: data,
         customNotificationModal: {
            resourceType: null,
            isOpen: false,
            action: null,
            buttonLoading: false,
            id: null,
         },
      }
   },
};

export default createReducer(initialState)(reducersMap);
