import {
   fetchOutgoingNotificationsSettings,
   getOutgoingNotificationSettings,
   updateOutgoingNotificationSettings,
   createCustomOutgoingNotificationSettings,
   deleteOutgoingNotificationSetting,
   getStoreCollections,
   // revertOutgoingNotificationSettings,
}  from 'admin/api/AuthApi';

import {
   fetchSettingsRequest,
   fetchSettingsCompleted,
   fetchSettingsFailed,
   updateSettingsRequest,
   updateSettingsCompleted,
   updateSettingsFailed,
   fetchSettingBySlugRequest,
   fetchSettingBySlugCompleted,
   fetchSettingBySlugFailed,
   setCustomNotificationModal,
   addCustomNotifcationsCompleted,
   deleteCustomNotifcationCompleted,
} from './actions';
import toster from 'common/utils/toast';
import Router from 'admin/routes/router';
import { push } from "connected-react-router";
import QueryParams from 'admin/utils/QueryParams';
import toast from "common/utils/toast";
import { converDesriptionVarables } from "common/utils/utils";
import { getVariablesList } from 'admin/views/my-site/settings/outgoing-notification-and-auto-tweet/data';


export function fetchSettingsOperation(callBack) {
   return async (dispatch) => {
      dispatch(fetchSettingsRequest());
      try {
         const { data } = await fetchOutgoingNotificationsSettings();
         await dispatch(fetchSettingsCompleted({ data }));
         if(callBack){
            callBack()
         }
         if(window.outgoingNotificationsAndAutoTweetScrollTop){
            window.scroll({
               top: window.outgoingNotificationsAndAutoTweetScrollTop,
               left: 0,
            });
         } else {
            const customize = QueryParams.getParam('customize');

            if(customize){
               switch (customize) {
                  case 'customCategories':
                     const content = document.querySelector('#store_outgoing_notification');
                     if(content){
                        window.scroll({
                           top: content.offsetTop - 50,
                           left: 0,
                        });
                     }
                     break;

                  default:
                     break;
               }
            }
         }
      } catch (error) {
         console.log(error)
         dispatch(fetchSettingsFailed());
      }
   };
}
export function updateSettingOperation(slug, data, callBack) {
   return async (dispatch) => {
      dispatch(updateSettingsRequest());
      try {
         await updateOutgoingNotificationSettings(slug, data);
         await dispatch(updateSettingsCompleted(slug, data));

         toster.info('Your changes have been saved');
         if(callBack){
            callBack();
         }
      } catch (error) {
         dispatch(updateSettingsFailed(error?.response?.data));
      }
   };
}
export function getSettingsBySlugOperation(slug, callback, isInsideModal, insideModalDefaultData) {
   return async (dispatch) => {
      dispatch(fetchSettingBySlugRequest());
      try {
         const { data } = await getOutgoingNotificationSettings(slug);

         let modifiedData = { ...data, mass_private_message_is_on: isInsideModal ? false : data?.mass_private_message_is_on }

         if(isInsideModal) {
            if(!!insideModalDefaultData?.notify_members_on_publish) {
               modifiedData = {
                  ...modifiedData,
                  mass_private_message_payload: insideModalDefaultData?.notify_members_on_publish,
               }
            }

            if(!!insideModalDefaultData?.send_tweet_on_publish) {
               modifiedData = {
                  ...modifiedData,
                  auto_tweet_payload: insideModalDefaultData?.send_tweet_on_publish,
               }
            }
         }


         const variablesList = getVariablesList();

         if(modifiedData?.mass_private_message_payload?.body){
            modifiedData.mass_private_message_payload.body = converDesriptionVarables(modifiedData.mass_private_message_payload.body, variablesList);
         }
         if(modifiedData?.individual_private_message_payload?.body){
            modifiedData.individual_private_message_payload.body = converDesriptionVarables(modifiedData.individual_private_message_payload.body, variablesList);
         }

         if(modifiedData?.default_config?.individual_private_message_payload?.body){
            modifiedData.default_config.individual_private_message_payload.body = converDesriptionVarables(modifiedData.default_config.individual_private_message_payload.body, variablesList);
         }
         if(modifiedData?.default_config?.mass_private_message_payload?.body){
            modifiedData.default_config.mass_private_message_payload.body = converDesriptionVarables(modifiedData.default_config.mass_private_message_payload.body, variablesList);
         }

         dispatch(fetchSettingBySlugCompleted(modifiedData));

         if(callback){
            callback(modifiedData);
         }
      } catch (error) {
         dispatch(push(Router.route('MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS').getCompiledPath()))
         dispatch(fetchSettingBySlugFailed());
      }
   };
}

export function createCustomOutgoingNotificationSettingsOperation(inputs) {
   return async (dispatch) => {
      dispatch(setCustomNotificationModal({ buttonLoading: true }));
      try {
         const { data } = await createCustomOutgoingNotificationSettings(inputs);
         dispatch(addCustomNotifcationsCompleted(data));
         const slugs = Object.keys(data);
         const slug = slugs[0];
         const newNotification = data[slug];
         dispatch(push(Router.route('MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS_EDIT').getCompiledPath({ slug })));
         toast.success(`${ newNotification.title } has been created`)

      } catch (error) {
         dispatch(setCustomNotificationModal({ buttonLoading: false }));
      }
   };
}

export function deleteCustomOutgoingNotificatioSettingsOperation(slug, title) {
   return async (dispatch) => {
      dispatch(setCustomNotificationModal({ buttonLoading: true }));
      try {
         await deleteOutgoingNotificationSetting(slug);
         dispatch(deleteCustomNotifcationCompleted(slug));
         toast.remove(`${ title } has been deleted`)

      } catch (error) {
         dispatch(setCustomNotificationModal({ buttonLoading: false }));
      }
   };
}
export function fetchResourcesOperation(state = {}, type = 'init', query = [], oldData = []) {
   return async (dispatch) => {
      let fetchingKey = '';
      switch (type) {
         case 'init':
            fetchingKey = 'isFetching';
            break;
         case 'pagination':
            fetchingKey = 'isFetchingNextPage';
            break;

         default:
            break;
      }
      dispatch(setCustomNotificationModal({
         ...state,
         [fetchingKey]: true,
      }));
      try {
         const { data } = await getStoreCollections(query);
         const { current_page: currentPage, last_page: pages } = data;
         let newState = {
            resources: data?.data || [],
         }
         if(type === 'pagination'){
            let newData = data?.data || [];
            newData = [...oldData, ...newData];
            newState = {
               ...newState,
               resources: newData,
               currentPage,
               pages,
            }
         }
         if(type === 'init'){
            newState = {
               ...newState,
               currentPage,
               pages,
               isEmpty: newState?.resources?.length === 0,
            }
         }
         dispatch(setCustomNotificationModal({
            ...newState,
            [fetchingKey]: false,
            currentPage,
            pages,

         }))
      } catch (error) {
         dispatch(setCustomNotificationModal({
            ...state,
            [fetchingKey]: false,
         }));
      }
   };
}
