// FETCH TIPS
export const FETCH_DATA_START = 'payouts/FETCH_DATA_START';
export const FETCH_DATA_COMPLETED = 'payouts/FETCH_DATA_COMPLETED';
export const EXPORT_CSV_START = 'payouts/EXPORT_CSV_START';
export const EXPORT_CSV_COMPLETED = 'payouts/EXPORT_CSV_COMPLETED';
export const FETCH_DATA_BY_ID_START = 'payouts/FETCH_DATA_BY_ID_START';
export const FETCH_DATA_BY_ID_COMPLETED = 'payouts/FETCH_DATA_BY_ID_COMPLETED';
export const FETCH_STATISTIC_START = 'payouts/FETCH_STATISTIC_START';
export const FETCH_STATISTIC_COMPLETED = 'payouts/FETCH_STATISTIC_COMPLETED';
export const FETCH_NEW_DATA_START = 'payouts/FETCH_NEW_DATA_START';
export const FETCH_NEW_DATA_COMPLETED = 'payouts/FETCH_NEW_DATA_COMPLETED';
