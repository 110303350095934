import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   [types.FETCH_DETAILS_START]: (state) => {
      console.log('FETCH_DETAILS_START')
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const {
         total_revenue,
         active_users,
         content_views,
      } = action.payload;
      console.log('FETCH_DETAILS_COMPLETED')
      return {
         ...state,

         // FOR SUPPORT OLD HOMEPAGE TESTING
         storiesData: action.payload.stories,
         userEngagementsStatistic: action.payload.engagement,
         tipsStatistic: action.payload.tips,
         membersStatistic: action.payload.members,
         // FOR SUPPORT OLD HOMEPAGE TESTING -- END

         total_revenue,
         active_users,
         content_views,
         isFetching: false,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state) => {
      console.log('FETCH_DETAILS_FAILED')
      return {
         ...state,
         isFetching: false,
      }
   },
   [types.FETCH_MOST_CONTENT_LIST_START]: (state, action) => {
      const { type, isNextPage } = action.payload
      return {
         ...state,
         content_lists: {
            ...state.content_lists,
            [type]: {
               ...state.content_lists[type],
               isFetchingNextPage: isNextPage,
               isLoading: !isNextPage,
            },
         },
      }
   },
   [types.FETCH_MOST_CONTENT_LIST_COMPLETED]: (state, action) => {
      const { type, isNextPage, result: { data, last_page } } = action.payload

      let newData

      if(isNextPage) {
         newData = [...state.content_lists[type].data, ...data]
      } else {
         newData = data
      }

      return {
         ...state,
         content_lists: {
            ...state.content_lists,
            [type]: {
               ...state.content_lists[type],
               last_page,
               data: newData,
               isFetchingNextPage: false,
               isLoading: false,
            },
         },
      }
   },
   [types.FETCH_MOST_CONTENT_LIST_FAILED]: (state, action) => {
      const { type, isNextPage, isCancelled } = action.payload

      return {
         ...state,
         content_lists: {
            ...state.content_lists,
            [type]: {
               ...state.content_lists[type],
               isFetchingNextPage: isNextPage && isCancelled,
               isLoading: false,
            },
         },
      }
   },
   [types.INIT_CONTENT_LIST_START]: (state) => {
      return {
         ...state,
         content_lists: {
            ...state.content_lists,
            isFetchingInit: true,
         },
      }
   },
   [types.INIT_CONTENT_LIST_COMPLETED]: (state, action) => {
      return {
         ...state,
         content_lists: {
            ...state.content_lists,
            ...action.payload,
            isFetchingInit: false,
         },
      }
   },
   [types.INIT_CONTENT_LIST_FAILED]: (state) => {
      return {
         ...state,
         content_lists: {
            ...state.content_lists,
            isFetchingInit: false,
         },
      }
   },
}

export default createReducer(initialState)(reducersMap);
