import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_DATA_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DATA_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
         payouts: action.payload,
         isEmpty: action.payload.data.length === 0,
      }
   },
   [types.EXPORT_CSV_START]: (state) => {
      return {
         ...state,
         isFetchingExportCSV: true,
      }
   },
   [types.EXPORT_CSV_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingExportCSV: false,
      }
   },

   [types.FETCH_DATA_BY_ID_START]: (state) => {
      return {
         ...state,
         isFetchingById: true,
      }
   },
   [types.FETCH_DATA_BY_ID_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingById: false,
         payoutDetails: action.payload,
      }
   },
   [types.FETCH_STATISTIC_START]: (state) => {
      return {
         ...state,
         isFetchingStats: true,
      }
   },
   [types.FETCH_STATISTIC_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingStats: false,
         isEmptyStatistic: !action.payload,
         statistic: action.payload,
      }
   },
   [types.FETCH_NEW_DATA_START]: (state) => {
      return {
         ...state,
         isNewFetching: true,
      }
   },
   [types.FETCH_NEW_DATA_COMPLETED]: (state, action) => {
      const res = action.payload;
      return {
         ...state,
         isNewFetching: false,
         payouts: {
            ...res,
            data: [...state.payouts.data, ...res.data],
         },
         statistic: action.payload,
      }
   },
};

export default createReducer(initialState)(reducersMap);
