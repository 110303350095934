import * as types from "./types";

const fetchResourcesRequest = () => ({
   type: types.FETCH_RESOURCES_REQUEST,
});

const fetchResourcesCompleted = (data) => ({
   type: types.FETCH_RESOURCES_COMPLETED,
   payload: data,
})

const fetchResourcesFailed = (err) => ({
   type: types.FETCH_RESOURCES_FAILED,
   payload: {
      err,
   },
})
const defaultAccessesRequest = () => ({
   type: types.DEFAULT_ACCESSES_REQUEST,
});

const defaultAccessesCompleted = (data) => ({
   type: types.DEFAULT_ACCESSES_COMPLETED,
   payload: data,
})

const defaultAccessesFailed = (err) => ({
   type: types.DEFAULT_ACCESSES_FAILED,
   payload: {
      err,
   },
})

const setDownloadButtonLoading = bool => ({
   type: types.SET_DOWNLOAD_BUTTON_LOADING,
   payload: bool,
})
const setWrongDownloadButtonModal = data => ({
   type: types.SET_WRONG_DOWNLOAD_MODAL,
   payload: data,
})
const setCountsStates = (data, type) => ({
   type: types.SET_COUNTS_STATE,
   payload: { data, type },
})

const createNewResourceTag = (data) => ({
   type: types.CREATE_NEW_RESOURCE_TAG,
   payload: data,
})

export {
   fetchResourcesRequest,
   fetchResourcesCompleted,
   fetchResourcesFailed,
   defaultAccessesRequest,
   defaultAccessesCompleted,
   defaultAccessesFailed,
   setDownloadButtonLoading,
   setWrongDownloadButtonModal,
   setCountsStates,
   createNewResourceTag,

};
