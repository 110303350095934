import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';
import { isArray } from 'lodash'

let reducersMap = {
   [types.FETCH_REQUEST]: (state, action) => {
      const key = action.payload || ''

      return {
         ...state,
         key,
         [`isFetching${ key }`]: true,
         createErrorsData: {},
         openEditTitelMod: false,
         openStatusMod: false,
         updateStatusFetching: false,
         [`isFetchingByFilter${ key }`]: false,
         [`isEmpty${ key }`]: false,
         [`isEmptyByFilter${ key }`]: false,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload: { videosData, emptyFilters, key: currentKey } } = action;
      const key = currentKey || ''
      let newState = {
         [`isEmpty${ key }`]: videosData.data.length === 0,
         [`isEmptyByFilter${ key }`]: false,
      }
      if(!key && !emptyFilters) {
         newState = {
            [`isEmptyByFilter${ key }`]: videosData.data.length === 0,
         }
      }
      return {
         ...state,
         ...newState,
         [`isFetching${ key }`]: false,
         [`videosData${ key }`]: videosData,
         isInited: 'InsideModal' !== state.key,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      const key = state.key || ''
      return {
         ...state,
         [`isFetching${ key }`]: false,
      }
   },
   [types.FETCH_BY_FILTER_REQUEST]: (state, action) => {
      const { key: currentKey } = action.payload
      const key = currentKey || state.key || ''
      return {
         ...state,
         [`isFetchingByFilter${ key }`]: true,
         [`isNewFetching${ key }`]: false,
      }
   },
   [types.FETCH_BY_FILTER_DETAILS_COMPLETED]: (state, action) => {
      const  { payload: { videosData, key: currentKey } } = action;
      const key = currentKey || state.key || ''
      return {
         ...state,
         [`isEmptyByFilter${ key }`]: false,
         [`videosData${ key }`]: videosData,
         [`isEmptyByFilter${ key }`]: videosData.data.length === 0,
         [`isFetchingByFilter${ key }`]: false,
         [`isNewFetching${ key }`]: false,
      }
   },

   [types.FETCH_BY_FILTER_DETAILS_FAILED]: (state, action) => {
      const key = state.key || ''
      return {
         ...state,
         [`isEmptyByFilter${ key }`]: false,
         [`isNewFetching${ key }`]: false,
      }
   },

   [types.FETCH_NOT_COMPLETED_REQUEST]: state => {
      return {
         ...state,
         isNotCompletedFetching: true,
      }
   },
   [types.FETCH_NOT_COMPLETED_COMPLETED]: (state, action) => {
      const { payload: { uncompletedList } } = action;
      return {
         ...state,
         notCompletedList: uncompletedList,
         isNotCompletedFetching: false,
      }
   },

   [types.NEXT_PAGE_REQUEST]: (state, action) => {
      const  { payload: { key: currentKey } } = action;
      const key = currentKey || state.key || ''
      return {
         ...state,
         [`isNewFetching${ key }`]: !state?.[`isFetchingByFilter${ key }`],
      }
   },
   [types.NEXT_PAGE_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const res = payload.videosData;
      const  { payload: { key: currentKey } } = action;
      const key = currentKey || state.key || ''
      return {
         ...state,
         [`isNewFetching${ key }`]: false,
         [`isFetchingByFilter${ key }`]: false,
         [`videosData${ key }`]: {
            ...res,
            data: [...state[`videosData${ key }`].data, ...res.data],
         },

      }
   },
   [types.NEXT_PAGE_FAILED]: (state, action) => {
      const { payload } = action;
      const key = state.key || ''
      return {
         ...state,
         [`isNewFetching${ key }`]: false,
         errors: payload,
      }
   },
   [types.FETCH_VIDEO_BY_ID_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingById: true,
         errors: {},
         createErrorsData: {},
      }
   },
   [types.FETCH_VIDEO_BY_ID_COMPLETED]: (state, action) => {
      const { payload } = action;
      let newState = {};
      if(payload?.replacing_id){
         const {
            id,
            v2_optimization_status,
            replacing_id,
         } = payload;
         const errorStatuses = [
            'mediainfo_error',
            'optimization_error',
            'other_error',
         ];
         let status = null;
         if(errorStatuses.includes(v2_optimization_status)) {
            status = 'error'
         } else if('in_optimization_queue' === v2_optimization_status) {
            status = 'in_optimization_queue';
         } else if('optimizing' === v2_optimization_status) {
            status = 'optimizing';
         }
         const info = {
            uploadingStatus: status,
            id: replacing_id,
         };
         newState = {
            replaceState: {
               ...state.replaceState,
               [id]: info,
            },
         }
      } else if(state?.replaceState?.[payload.id]){
         let newReplace = { ...state.replaceState };
         delete newReplace[payload.id]
         newState = {
            ...newState,
            replaceState: newReplace,
         }
      }
      return {
         ...state,
         ...newState,
         isFetchingById: false,
         fetchVideoById: payload,

      }
   },
   [types.FETCH_VIDEO_BY_ID_FAILED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetchingById: false,
         errors: payload,
      }
   },
   [types.UPDATE_VIDEO_REQUEST]: (state, action) => {
      return {
         ...state,
         updateButtonDisabled: true,
         openEditTitelMod: !!action.payload,
         createErrorsData: {},
         updateStatusFetching: true,
      }
   },
   [types.CLEAR_ERROR_REQUEST]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         createErrorsData: {
            ...state.createErrorsData,
            ...payload,
         },
      }
   },
   [types.UPDATE_VIDEO_COMPLETED]: (state, action) => {
      const { payload: {
         video,
         step,
         allVideos,
      } } = action;
      let { key } = state
      let data = { ...video }
      if(video.is_published !== undefined && typeof video.is_published === 'boolean') {
         data.is_future_publish_enabled = false
      }
      let completedSteps = state.fetchVideoById.completed_steps;
      if(step) {
         completedSteps = [...completedSteps, step]
      }
      let newState = {}
      if(allVideos){
         if(isArray(allVideos)){
            newState = { [`videosData${ key }`]: {
               ...state[`videosData${ key }`],
               data: allVideos,
            } }
         } else if(state.fetchVideoById.id) {

            if(step) {
               newState = { [`videosData${ key }`]: {
                  ...state[`videosData${ key }`],
                  data: [...state[`videosData${ key }`].data, ...video],
               },
               }
            } else {
               newState = { [`videosData${ key }`]: {
                  ...state[`videosData${ key }`],
                  data: [...state[`videosData${ key }`].data].map(item => {
                     let { ...elm } = item
                     if(elm.id === state.fetchVideoById.id){
                        elm = { ...elm, ...video }
                     }
                     return elm
                  }),
               } }

            }
         }
      }
      let updateData = { ...data }
      const { multiple_access_members, multiple_access_others } = state.fetchVideoById
      if('access_members_different' in video) {
         updateData = {
            ...updateData,
            multiple_access_members: multiple_access_members.map(elm => {
               let i = { ...elm }
               let key = i.id
               if(!key) key = i.type
               if([key] in video.access_members_different){
                  i = { ...i, access: { ...video.access_members_different[key] } }
               }
               return i
            }),
         }
      }
      if('access_others_different' in video) {
         updateData = {
            ...updateData,
            multiple_access_others: multiple_access_others.map(elm => {
               let i = { ...elm }
               let key = i.id
               if(!key) key = i.type
               if([key] in video.access_others_different){
                  i = { ...i, access: { ...video.access_others_different[key] } }
               }
               return i
            }),

         }
      }

      let newVideos = {};
      const videos = { ...state[`videosData${ key }`] }
      if(Object.keys(videos).length > 0){
         let newVideosData = videos.data && videos.data.map(item => {
            if(state.fetchVideoById.id === item.id){
               return {
                  ...item,
                  ...video,
               }
            }
            return item;
         })

         newVideos = {
            ...videos,
            data: [
               ...newVideosData,
            ],
         }
      }
      return {
         ...state,
         fetchVideoById: {
            ...state.fetchVideoById,
            ...updateData,
            completed_steps: completedSteps,
         },
         isErrors: false,
         updateButtonDisabled: false,
         createErrorsData: {},
         openEditTitelMod: false,
         openStatusMod: false,
         updateStatusFetching: false,
         [`videosData${ key }`]: { ...newVideos },
         ...newState,
      }
   },
   [types.UPDATE_PUBLISH_DATA]: (state, action) => {
      let newData = action.payload;
      return {
         ...state,
         fetchVideoById: {
            ...state.fetchVideoById,
            ...newData,
         },
      }
   },
   [types.UPDATE_VIDEO_FAILED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         createErrorsData: payload,
         updateButtonDisabled: false,
         isErrors: true,
         openEditTitelMod: !!(payload.title || payload.description),
         updateStatusFetching: false,
         openStatusMod: !!payload.publish_date,
      }
   },
   [types.DELETE_VIDEO_REQUEST]: (state, action) => {
      return {
         ...state,
         openConfirmDialog: false,
         deleteButtonDisabled: true,
      }
   },
   [types.DELETE_VIDEO_COMPLETED]: (state, action) => {
      const { payload: { id, emptyFilterParams } } = action;
      let newState = {};
      if(state?.videosData?.data){
         const data = [...state.videosData.data].filter(i => i.id !== +id)
         newState = {
            videosData: {
               ...state.videosData,
               data,
            },
         }
         if(emptyFilterParams){
            newState = {
               ...newState,
               isEmpty: data.length === 0,
            }
         } else {
            newState = {
               ...newState,
               isEmptyByFilter: data.length === 0,
            }
         }
      }
      return {
         ...state,
         ...newState,
         openConfirmDialog: true,
         deleteButtonDisabled: false,
      }
   },
   [types.DELETE_VIDEO_FAILED]: (state, action) => {
      return {
         ...state,
         openConfirmDialog: false,
         deleteButtonDisabled: false,
      }
   },

   [types.SET_BUTTON_DISABLED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         updateButtonDisabled: payload,
      }
   },
   [types.SET_STATUS_MODAL]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         openStatusMod: payload,
      }
   },
   [types.SET_EDIT_TITLE_MODAL]: (state, action) => {
      const { payload } = action;
      let newErrorsState = {};
      let oldErrors = { ...state.createErrorsData };
      if(payload === false && (oldErrors?.title || oldErrors.description)){
         newErrorsState = {
            createErrorsData: {
               ...oldErrors,
               title: '',
               description: '',
            },
         }
      }
      return {
         ...state,
         openEditTitelMod: payload,
         ...newErrorsState,
      }
   },
   [types.CLEAR_STATE]: (state, action) => {
      let newState = {}
      return {
         ...state,
         // key: '',
         ...newState,
         ...action.payload,
      }
   },
   [types.SET_FILTERS]: (state, action) => {
      const { payload } =  action

      return {
         ...state,
         filters: {
            ...payload,
         },
      }
   },
   [types.DISTORY_VIDEOS_REQUEST]: (state, action) => {
      const { payload } =  action

      return {
         ...state,
         isDistoryFetching: payload,
      }
   },
   [types.DISTORY_VIDEOS_COMPLETED]: (state, action) => {
      const { payload: { ids, hasFilter, excludedIds } } =  action
      let data = []
      if('all' !== ids) {
         data = [...state.videosData.data].filter(i => !ids.includes(i.id))
      } else {
         data = [...state.videosData.data].filter(i => excludedIds.includes(i.id))
      }
      let newState = {}
      if(!hasFilter) {
         newState = {
            isEmpty: data.length === 0,
         }
      } else {
         newState = {
            isEmptyByFilter: data.length === 0,
         }
      }
      return {
         ...state,
         isDistoryFetching: false,
         videosData: {
            ...state.videosData,
            total: data.length,
            data,

         },
         ...newState,
      }
   },
   [types.BULK_VIDEOS_REQUEST]: (state, action) => {
      const { payload } =  action
      return {
         ...state,
         isFetchingByBulkVideos: payload,
         bulkErrors: {},
      }
   },
   [types.BULK_VIDEOS_COMPLETED]: (state, action) => {
      const { payload } =  action
      return {
         ...state,
         isFetchingByBulkVideos: false,
         bulkVideos: payload,
      }
   },
   [types.SET_BULK_IDS]: (state, action) => {
      const { payload } =  action
      return {
         ...state,
         bulkEditIds: {
            ...payload,
         },
         bulkErrors: {},
      }
   },
   [types.MULTIPLE_UPDATE]: (state, action) => {
      const { payload: { bool, errors } } =  action
      return {
         ...state,
         bulkErrors: errors,
         bulkUpdateFetching: bool,
         isInited: false,
      }
   },
   [types.UPDATE_OR_ADD_PREVIEW_VIDEO]: (state, action) => {
      const { payload } = action;
      const videoData = { ...state.fetchVideoById };
      let newVideos = null;
      if(videoData) {
         newVideos = {
            ...videoData,
            ...payload,
         }
      } else {
         newVideos = {
            ...videoData,
         }
      }
      return {
         ...state,
         fetchVideoById: newVideos,
      }
   },
   [types.CLEAR_BULK_ERROR]: (state, action) => {
      const { payload } =  action

      return {
         ...state,
         bulkUpdateErrors: {
            ...state.bulkUpdateErrors,
            ...payload,
         },
      }
   },

   [types.UPDATE_VIDEO_ON_VIDEOS_LIST]: (state, action) => {
      const { payload: { id, data } } =  action;
      const list = { ...state.videosData }
      let newList = list.data || [];
      newList = [...newList].map(video => {
         let { ...element } = video;
         if(element.id === id){
            element = {
               ...element,
               ...data,
            }
         }
         return element;
      })
      return {
         ...state,
         videosData: {
            ...state.videosData,
            data: [...newList],
         },
      }
   },

   [types.SET_REPLACE_STATE_ACTION]: (state, action) => {
      const { payload: { id, data, updateVideo } } =  action;
      const replaceState = state.replaceState?.[id] || {};
      let newReplaceStateState = { ...state.replaceState }
      let newState = {};
      if(data){
         newReplaceStateState = {
            ...newReplaceStateState,
            [id]: {
               ...replaceState,
               ...data,

            },
         }
      } else if(state.replaceState?.[id]){
         delete newReplaceStateState?.[id]
      }
      newState = {
         replaceState: newReplaceStateState,
      }
      if(updateVideo && state.fetchVideoById?.id === id){
         newState = {
            ...newState,
            fetchVideoById: {
               ...state.fetchVideoById,
               ...updateVideo,
            },
         }
      }
      return {
         ...state,
         ...newState,
      }
   },

   //reorder
   [types.REORDER_VIDEOS]: (state, action) => {
      let { payload } = action

      return {
         ...state,
         videosData: {
            ...state.videosData,
            data: payload,
         },
      }
   },
   [types.CHANGE_INITED_STATE]: (state) => {
      return {
         ...state,
         isInited: false,
      }
   },
   [types.SET_WARNING_MODAL]: (state, action) => {
      return {
         ...state,
         warningModal: {
            ...state.warningModal,
            ...action.payload,
         },
      }
   },
   [types.UPDATE_BUNDLE_STATE_IN_DATA]: (state, action) => {
      let { data: { id, attached_bundle_name, attached_bundle_id }, isDetailsPage } = action.payload

      if(isDetailsPage) {
         return {
            ...state,
            fetchVideoById: {
               ...state.fetchVideoById,
               attached_bundle_name, attached_bundle_id,
            },
         }
      }

      let updatedList = state.videosData.data.map(item => {
         if(item.id === id) {
            return {
               ...item,
               attached_bundle_name, attached_bundle_id,
            }
         }
         return item
      })

      return {
         ...state,
         videosData: {
            ...state.videosData,
            data: updatedList,
         },
      }
   },
   [types.PIN_VIDEO_COMPLETED]: (state, action) => {
      const { payload: { id, bool } } = action;
      const key = state.key || ''

      let data  = [...state?.[`videosData${ key }`].data].sort(function(item1, item2){
         if(item1.id === id)  item1.is_pinned = !bool
         if(item2.id === id)  item2.is_pinned = !bool
         if(item1.id === id) {
            if(bool){
               return item2.is_pinned ? -1 : new Date(item1.publish_date) - new Date(item2.publish_date)
            }
            return -1
         } else if(item2.id === id) {
            if(bool){
               return item1.is_pinned ? -1 : new Date(item2.publish_date) - new Date(item1.publish_date)
            }
            return 1
         } else if(item1.id !== id && item2.id !== id){
            return  item1.is_pinned && !item2.is_pinned ? -1 ? item2.is_pinned && !item1.is_pinned  : 1 : 0
         } else {
            return 0
         }
      });

      if(bool) {
         data  = [...data].sort(function(item1, item2){
            return item1.is_pinned ? -1 : item2.is_pinned ? 1 : 0
         })

      }
      return {
         ...state,
         [`videosData${ key }`]: {
            ...state?.[`videosData${ key }`],
            data: data,
         },
      }
   },
   [types.UPDATE_MESSAGE_ACTION]: (state, action) => {
      const { ids, data, messageId, isFilteredByUnsent, removedItemId } = action.payload;
      const key = state.key || '';
      let videosData = state?.[`videosData${ key }`]?.data ? [...state[`videosData${ key }`].data] : [];

      let updatedData = videosData

      updatedData = updatedData.reduce((prev, curr) => {
         let current = curr

         if(!!current?.messages?.length){

            current.messages = current.messages.map(message => {
               if(message?.id === messageId && message.unlock_details && message.unlock_details.resource_type === 'photoset') {
                  current.messages = current.messages.filter(it => it.id !== messageId)
               }
               if(message?.id === messageId && message.unlock_details && message.unlock_details.resource_type === 'multiple_attachment') {
                  if(message.unlock_details.attachments && message.unlock_details.attachments.length > 1) {
                     return {
                        ...message,
                        unlock_details: {
                           ...message.unlock_details,
                           attachments: message.unlock_details.attachments.filter(ct => ct.resource_id !== ids[0]),
                        },
                     }

                  } else {
                     current.messages = current.messages.filter(it => it.id !== messageId)
                  }

               }
               return message
            })
         }

         prev.push(current)
         return prev
      }, [])

      videosData = updatedData.map((el) => {
         let { ...item } = el;
         if(ids.includes(item.id)){
            let messages = null;
            if(item.messages && !action?.payload?.isDeleteAll){
               if(data){
                  messages = [...item.messages].map((message) => {
                     let { ...m } = message;
                     if(m.id === messageId){
                        m = {
                           ...m,
                           ...data,
                        }
                     }
                     return m;
                  })
               } else {
                  messages = [...item.messages].filter(m => m.id !== messageId);
               }
            }
            item.messages = messages;
            if(!messages?.length){
               item.messages = null;
            }
         }
         return item
      }).filter(el => {
         if(!el.messages && !isFilteredByUnsent && el.id === removedItemId) {
            return null
         }

         return el
      })

      return {
         ...state,
         [`videosData${ key }`]: {
            ...state?.[`videosData${ key }`],
            data: videosData,
         },
         isEmptyByFilterInsideModal: !isFilteredByUnsent && videosData?.length === 0 ? true : state?.isEmptyByFilterInsideModal,
      }
   },
   [types.UPDATE_VIDEOS_LIST]: (state, action) => {
      const  { payload: { date, id } } = action

      const newData = state[`videosData${ state?.key }`]?.data?.map(it => {
         if(id === it?.id){
            return {
               ...it,
               last_repost_date: date,
            }
         }
         return it
      })

      return {
         ...state,
         [`videosData${ state?.key }`]: {
            ...state?.[`videosData${ state?.key }`],
            data: newData,
         },
      }
   },
};

export default createReducer(initialState)(reducersMap);
