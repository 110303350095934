const initialState = {
   isFetching: true,
   storiesData: [],
   membersStatistic: {},
   userEngagementsStatistic: {},
   tipsStatistic: {},
   total_revenue: {},
   active_users: {},
   content_views: {},
   content_lists: {
      isFetchingInit: true,
      most_liked: {
         data: [],
         isViewAll: false,
         last_page: 1,
         isFetchingNextPage: false,
         isLoading: true,
      },
      most_commented: {
         data: [],
         isViewAll: false,
         last_page: 1,
         isFetchingNextPage: false,
         isLoading: true,
      },
      most_viewed: {
         data: [],
         isViewAll: false,
         last_page: 1,
         isFetchingNextPage: false,
         isLoading: true,
      },
   },
   top_spenders: [],
};

export default initialState;
