import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   // FETCH TIPS /
   [types.FETCH_TIPS_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },

   [types.FETCH_TIPS_COMPLETED]: (state, action) => {
      const res = action.payload;
      return {
         ...state,
         isFetching: false,
         tipsData: {
            ...res.tipsData,
            data: res.tipsData.data,
         },
      }
   },

   [types.FETCH_TIPS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   // FETCH NEW PAGE TIPS /
   [types.FETCH_NEW_TIPS_REQUEST]: (state) => {
      return {
         ...state,
         isNewFetching: true,
      }
   },

   [types.FETCH_NEW_TIPS_COMPLETED]: (state, action) => {
      const res = action.payload.tipsData;
      return {
         ...state,
         isNewFetching: false,
         tipsData: {
            ...res,
            data: [...state.tipsData.data, ...res.data],
         },
      }
   },

   [types.FETCH_NEW_TIPS_FAILED]: (state, action) => {
      return {
         ...state,
         isNewFetching: false,
      }
   },

   // FETCH TIPS SETTINGS /
   [types.FETCH_TIPS_SETTINGS_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },

   [types.FETCH_TIPS_SETTINGS_COMPLETED]: (state, action) => {
      const { payload: { tipsSettings } } = action;
      return {
         ...state,
         isFetching: false,
         tipsSettings,
      }
   },

   [types.FETCH_TIPS_SETTINGS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   // FETCH TIPS STATISTICS /
   [types.FETCH_TIPS_STATISTICS_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },

   [types.FETCH_TIPS_STATISTICS_COMPLETED]: (state, action) => {
      const { payload: { statistics } } = action;
      return {
         ...state,
         isFetching: false,
         statistics,
      }
   },

   [types.FETCH_TIPS_STATISTICS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },
};

export default createReducer(initialState)(reducersMap);
