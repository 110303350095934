import * as types from "./types";

//FETCH TIPS /
const fetchStoryCommentsRequest = () => ({
   type: types.FETCH_STORY_COMMENTS_REQUEST,
});
const fetchStoryCommentsCompleted = (data, countComments) => ({
   type: types.FETCH_STORY_COMMENTS_COMPLETED,
   payload: {
      data,
      countComments,
   },
});
const fetchStoryCommentsFailed = () => ({
   type: types.FETCH_STORY_COMMENTS_FAILED,
});

const fetchCommentsByFilterRequest = () => ({
   type: types.FETCH_COMMENTS_BY_FILTER_REQUEST,
});
const fetchCommentsByFilterCompleted = (data) => ({
   type: types.FETCH_COMMENTS_BY_FILTER_COMPLETED,
   payload: data,
});
const fetchCommentsByFilterFailed = () => ({
   type: types.FETCH_COMMENTS_BY_FILTER_FAILED,
});

const fetchNewCommentsRequest = () => ({
   type: types.FETCH_NEW_COMMENTS_REQUEST,
});
const fetchNewCommentsCompleted = (data) => ({
   type: types.FETCH_NEW_COMMENTS_COMPLETED,
   payload: data,
});
const fetchNewCommentsFailed = () => ({
   type: types.FETCH_NEW_COMMENTS_FAILED,
});


const commentLikeRequest = () => ({
   type: types.COMMENT_LIKE_REQUEST,
});

const commentLikeCompleted = (data) => ({
   type: types.COMMENT_LIKE_COMPLETED,
   payload: data,
});

const commentUpdateCompleted = (data) => ({
   type: types.COMMENT_UPDATE_COMPLETED,
   payload: data,
});

const commentLikeFailed = () => ({
   type: types.COMMENT_LIKE_FAILED,
});

const commentDeleteRequest = () => ({
   type: types.COMMENT_DELETE_REQUEST,
});
const commentDeleteCompleted = (data) => ({
   type: types.COMMENT_DELETE_COMPLETED,
   payload: data,
});
const commentDeleteFailed = () => ({
   type: types.COMMENT_DELETE_FAILED,
});

const sendCommentRequest = () => ({
   type: types.SEND_COMMENT_REQUEST,
});
const sendCommentCompleted = (data) => ({
   type: types.SEND_COMMENT_COMPLETED,
   payload: data,
});
const sendCommentFailed = () => ({
   type: types.SEND_COMMENT_FAILED,
});

const commentMemberUpdateCompleted = (data) => ({
   type: types.COMMENT_MEMBER_UPDATE_COMPLETED,
   payload: data,
});

const commentMemberUpdateByUuid = (uuid, data) => ({
   type: types.UPDATE_COMMENT_MEMBER_BY_UUID,
   payload: { uuid, data },
})

const commentMemberUpdateFailed = error => ({
   type: types.UPDATE_COMMENT_MEMBERS_FAILED,
   payload: error,
})

export {
   fetchStoryCommentsRequest,
   fetchStoryCommentsCompleted,
   fetchStoryCommentsFailed,

   fetchCommentsByFilterRequest,
   fetchCommentsByFilterCompleted,
   fetchCommentsByFilterFailed,

   fetchNewCommentsRequest,
   fetchNewCommentsCompleted,
   fetchNewCommentsFailed,

   commentLikeRequest,
   commentLikeCompleted,
   commentLikeFailed,

   commentDeleteRequest,
   commentDeleteCompleted,
   commentDeleteFailed,

   sendCommentRequest,
   sendCommentCompleted,
   sendCommentFailed,

   commentUpdateCompleted,
   commentMemberUpdateCompleted,
   commentMemberUpdateByUuid,
   commentMemberUpdateFailed,
};
