import * as types from './types';

const openHeaderPopup = bool => ({
   type: types.OPEN_HEADER_POPUP,
   payload: bool,
});
const openReplyComment = data => ({
   type: types.OPEN_REPLY_COMMENT,
   payload: data,
});

const updateReplyComments = (data, type, initial) => ({
   type: types.UPDATE_REPLY_COMMENTS,
   payload: { data, type, initial },
});

const updateNotifications = (data) => ({
   type: types.UPDATE_NOTIFICATIONS_DATA,
   payload: { data },
});
const markAllAsSeenNotificationsCompleted = () => ({
   type: types.MAKE_ALL_AS_SEEN_NOTIFICATIONS,
});

const markAllAsReadNotificationsCompleted = (id) => ({
   type: types.MAKE_ALL_AS_READ_NOTIFICATIONS,
   payload: { id },
});

const fetchNotificationsRequest = () => ({
   type: types.FETCH_NOTIFICATIONS_REQUEST,
});
const fetchNotificationsCompleted = (data) => ({
   type: types.FETCH_NOTIFICATIONS_COMPLETED,
   payload: data,
});
const fetchNotificationsFailed = (error) => ({
   type: types.FETCH_NOTIFICATIONS_FAILED,
   payload: error,
});

const fetchNewNotificationsRequest = () => ({
   type: types.FETCH_NEW_NOTIFICATIONS_REQUEST,
});
const fetchNewNotificationsCompleted = (data) => ({
   type: types.FETCH_NEW_NOTIFICATIONS_COMPLETED,
   payload: data,
});
const fetchNewNotificationsFailed = (error) => ({
   type: types.FETCH_NEW_NOTIFICATIONS_FAILED,
   payload: error,
});

const fetchNotificationsByFilterRequest = () => ({
   type: types.FETCH_NOTIFICATIONS_BY_FILTER_REQUEST,
});
const fetchNotificationsByFilterCompleted = (data) => ({
   type: types.FETCH_NOTIFICATIONS_BY_FILTER_COMPLETED,
   payload: data,
});
const fetchNotificationsByFilterFailed = (error) => ({
   type: types.FETCH_NOTIFICATIONS_BY_FILTER_FAILED,
   payload: error,
});

const fetchReplyCommentRequest = () => ({
   type: types.FETCH_REPLY_COMMENT_REQUEST,
});
const fetchReplyCommentCompleted = (data) => ({
   type: types.FETCH_REPLY_COMMENT_COMPLETED,
   payload: data,
});
const fetchReplyCommentFailed = (error) => ({
   type: types.FETCH_REPLY_COMMENT_FAILED,
   payload: error,
});

const fetchReplyChatMessageRequest = () => ({
   type: types.FETCH_REPLY_CHAT_MESSAGE_REQUEST,
});
const fetchReplyChatMessageCompleted = (data) => ({
   type: types.FETCH_REPLY_CHAT_MESSAGE_COMPLETED,
   payload: data,
});
const fetchReplyChatMessageFailed = (error) => ({
   type: types.FETCH_REPLY_CHAT_MESSAGE_FAILED,
   payload: error,
});

const sendCommentRequest = () => ({
   type: types.SEND_COMMENT_REQUEST,
});
const sendCommentCompleted = (data, replyMessage, type) => ({
   type: types.SEND_COMMENT_COMPLETED,
   payload: { data, replyMessage, type },
});
const sendCommentFailed = () => ({
   type: types.SEND_COMMENT_FAILED,
});

const replyCommentsToggleLikeRequest = () => ({
   type: types.REPLY_COMMENTS_TOGGLE_LIKE_REQUEST,
});
const replyCommentsToggleLikeCompleted = (data) => ({
   type: types.REPLY_COMMENTS_TOGGLE_LIKE_COMPLETED,
   payload: data,
});
const replyCommentsToggleLikeFailed = () => ({
   type: types.REPLY_COMMENTS_TOGGLE_LIKE_FAILED,
});

const replyCommentsDeleteCompleted = (data) => ({
   type: types.REPLY_COMMENTS_DELETE_COMPLETED,
   payload: data,
});


const setNotificationsPanelAction = (data) => ({
   type: types.SET_NOTIFICATIONS_PANEL,
   payload: data,
});

const setUnreadCountAction = (unreadCount) => ({
   type: types.SET_UNREAD_COUNT,
   payload: unreadCount,
});

const setUnseenCountAction = (unseenCount) => ({
   type: types.SET_UNSEEN_COUNT,
   payload: unseenCount,
});

const updateNotificationsByMemberIdAction = (payload) => ({
   type: types.UPDATE_NOTIFICATIONS_BY_MEMBER_ID,
   payload,
});

export {
   openHeaderPopup,
   openReplyComment,
   updateReplyComments,

   updateNotifications,
   markAllAsSeenNotificationsCompleted,
   markAllAsReadNotificationsCompleted,

   fetchNotificationsRequest,
   fetchNotificationsCompleted,
   fetchNotificationsFailed,

   fetchNewNotificationsRequest,
   fetchNewNotificationsCompleted,
   fetchNewNotificationsFailed,

   fetchNotificationsByFilterRequest,
   fetchNotificationsByFilterCompleted,
   fetchNotificationsByFilterFailed,

   fetchReplyCommentRequest,
   fetchReplyCommentCompleted,
   fetchReplyCommentFailed,

   fetchReplyChatMessageRequest,
   fetchReplyChatMessageCompleted,
   fetchReplyChatMessageFailed,

   sendCommentRequest,
   sendCommentCompleted,
   sendCommentFailed,

   replyCommentsToggleLikeRequest,
   replyCommentsToggleLikeCompleted,
   replyCommentsToggleLikeFailed,

   replyCommentsDeleteCompleted,
   setNotificationsPanelAction,
   setUnreadCountAction,
   setUnseenCountAction,

   updateNotificationsByMemberIdAction,
};
