export const FETCH_OUTGOING_NOTIFICATIONS_REQUEST = 'resources/FETCH_OUTGOING_NOTIFICATIONS_REQUEST';
export const FETCH_OUTGOING_NOTIFICATIONS_COMPLETED = 'resources/FETCH_OUTGOING_NOTIFICATIONS_COMPLETED';
export const FETCH_OUTGOING_NOTIFICATIONS_FAILED = 'resources/FETCH_OUTGOING_NOTIFICATIONS_FAILED';

export const UPDATE_OUTGOING_NOTIFICATIONS_REQUEST = 'resources/UPDATE_OUTGOING_NOTIFICATIONS_REQUEST';
export const UPDATE_OUTGOING_NOTIFICATIONS_COMPLETED = 'resources/UPDATE_OUTGOING_NOTIFICATIONS_COMPLETED';
export const UPDATE_OUTGOING_NOTIFICATIONS_FAILED = 'resources/UPDATE_OUTGOING_NOTIFICATIONS_FAILED';

export const FETCH_OUTGOING_NOTIFICATION_BY_SLUG_REQUEST = 'resources/FETCH_OUTGOING_NOTIFICATION_BY_SLUG_REQUEST';
export const FETCH_OUTGOING_NOTIFICATION_BY_SLUG_COMPLETED = 'resources/FETCH_OUTGOING_NOTIFICATION_BY_SLUG_COMPLETED';
export const FETCH_OUTGOING_NOTIFICATION_BY_SLUG_FAILED = 'resources/FETCH_OUTGOING_NOTIFICATION_BY_SLUG_FAILED';

export const SET_CUSTOM_NOTIFICATION_MODAL = 'resources/SET_CUSTOM_NOTIFICATION_MODAL';

export const ADD_CUSTOM_NOTIFICATIONS_COMPLETED = 'resources/ADD_CUSTOM_NOTIFICATIONS_COMPLETED';

export const DELETE_CUSTOM_NOTIFICATION_COMPLETED = 'resources/DELETE_CUSTOM_NOTIFICATION_COMPLETED';

export const CLEAR_UPDATE_ERROR_STATE = 'resources/CLEAR_UPDATE_ERROR_STATE';

