import * as types from "./types";

// fetch memberships for filter
export const fetchMembershipsStart = () => ({
   type: types.FETCH_MEMBERSHIPS_START,
})
export const fetchMembershipsCompleted = (data) => ({
   type: types.FETCH_MEMBERSHIPS_COMPLETED,
   payload: data,
})
export const fetchMembershipsFailed = () => ({
   type: types.FETCH_MEMBERSHIPS_FAILED,
})


// REVENUE

export const initRevenueStart = (active_tab, isFetchByFilter) => ({
   type: types.INIT_REVENUE_START,
   payload: { active_tab, isFetchByFilter },
})
export const initRevenueCompleted = (active_tab, result) => ({
   type: types.INIT_REVENUE_COMPLETED,
   payload: { active_tab, result },
})
// chart by filter
export const initRevenueChartByFilterStart = (active_tab) => ({
   type: types.INIT_REVENUE_CHART_BY_FILTER_START,
   payload: active_tab,
})
export const initRevenueChartByFilterCompleted = (active_tab, chartData) => ({
   type: types.INIT_REVENUE_CHART_BY_FILTER_COMPLETED,
   payload: { active_tab, chartData },
})
export const initRevenueChartByFilterFailed = (active_tab) => ({
   type: types.INIT_REVENUE_CHART_BY_FILTER_FAILED,
   payload: active_tab,
})


// TOP SPENDING MEMBERS
export const initTopSpendingMembersStart = (isNextpage) => ({
   type: types.INIT_TOP_SPENDING_MEMBERS_START,
   payload: isNextpage,
})
export const initTopSpendingMembersCompleted = (data) => ({
   type: types.INIT_TOP_SPENDING_MEMBERS_COMPLETED,
   payload: data,
})
export const initTopSpendingMembersFailed = (data) => ({
   type: types.INIT_TOP_SPENDING_MEMBERS_FAILED,
   payload: data,
})
export const updateTopSpendingMemberUsername = (data) => ({
   type: types.UPDATE_TOP_SPENDING_MEMBER_USERNAME,
   payload: data,
})
export const updateTopSpendingMember = (data) => ({
   type: types.UPDATE_TOP_SPENDING_MEMBER,
   payload: data,
})

// ENGAGEMENET
export const initEngagementStart = (active_tab) => ({
   type: types.INIT_ENGAGEMENT_START,
   payload: active_tab,
})
export const initEngagementCompleted = (active_tab, result) => ({
   type: types.INIT_ENGAGEMENT_COMPLETED,
   payload: { active_tab, result },
})
export const initEngagementChartByFilterStart = (active_tab) => ({
   type: types.INIT_ENGAGEMENT_CHART_BY_FILTER_START,
   payload: active_tab,
})
export const initEngagementChartByFilterCompleted = (active_tab, chartData) => ({
   type: types.INIT_ENGAGEMENT_CHART_BY_FILTER_COMPLETED,
   payload: { active_tab, chartData },
})
export const initEngagementChartByFilterFailed = (active_tab) => ({
   type: types.INIT_ENGAGEMENT_CHART_BY_FILTER_FAILED,
   payload: active_tab,
})


// CONTENT LIST
export const initContentListStart = (active_tab, isNextPage) => ({
   type: types.INIT_CONTENT_LIST_START,
   payload: { active_tab, isNextPage },
})
export const initContentListCompleted = (active_tab, result, isNextPage) => ({
   type: types.INIT_CONTENT_LIST_COMPLETED,
   payload: { active_tab, result, isNextPage },
})
export const initContentListFailed = (active_tab, isNextPage, isCancelled) => ({
   type: types.INIT_CONTENT_LIST_FAILED,
   payload: { active_tab, isNextPage, isCancelled },
})

// ACQUISITION
export const initAcquisitionStart = (active_tab, isFetchByFilter) => ({
   type: types.INIT_ACQUISITION_START,
   payload: { active_tab, isFetchByFilter },
})
export const initAcquisitionCompleted = (active_tab, result) => ({
   type: types.INIT_ACQUISITION_COMPLETED,
   payload: { active_tab, result },
})