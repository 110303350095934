/* eslint-disable react/prop-types */
import React from 'react';
import cx from 'classnames';
import Loader from 'common/components/elements/loader/circle';
import Icon from 'common/components/elements/icons';
import Button from 'common/components/elements/buttons/primary';
import './style.scss';
import { sortableHandle } from 'react-sortable-hoc';
import PropTypes from 'prop-types'

const DragHandle = sortableHandle(() => (
   <div className='flex gap-1 items-center drag-cursor' >
      <Icon name='drag' color='major-dark' size='xl' />
      <p className='text-major-dark text-sm font-medium'>Drag to reorder</p>
   </div>
));

function AddNewPhotosetCard({
   photo,
   className,
   removePhoto,
   makeCover,
   cover,
}) {
   let status = !photo?.status && photo?.id ? 'complete' : photo?.status

   switch (status) {
      case 'error':
         return (
            <div
               className={ `relative h-auto border border-error rounded-label flex items-center justify-center ${ className }` }>
               <div className='aspect-ratio-padding' />
               <div
                  onClick={ (e) => e.stopPropagation() }
                  role='presentation'
                  className='absolute top-0 right-0 m-2 flex items-center'
               >
                  <Button
                     textColor='major-dark'
                     backgroundColor='transparent'
                     iconName='cancel'
                     fontIconColor='secondary'
                     padding='none'
                     onClick={ () => removePhoto({ index: photo?.index })  }
                     borderRadius='large'
                     textSize='base'
                     dataToolTipContent='Delete'
                  />
               </div>
               <div className='flex flex-col items-center'>
                  <Icon name='warning' color='secondary' size='5xl' />
                  <p className='mt-5 text-error font-medium'>Error</p>
                  <p className='text-major text-sm'>Try again or contact support</p>
               </div>
            </div>
         );
      case 'loading':
         return (
            <div
               className={ `relative bg-placeholder rounded-label flex items-center justify-center ${ className }` }>
               <div className='aspect-ratio-padding' />
               <Loader color='white' />
            </div>
         );
      case 'complete':
         let thumbnailText = cover === 0 ? 'Default thumbnail' : `Thumbnail ${ cover + 1 }`
         const isSelected = cover >= 0
         return (
            <div
               style={ { background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0) 19.7%), linear-gradient(180deg, rgba(0, 0, 0, 0) 62.09%, rgba(0, 0, 0, 0.65) 100%)' } }
               role='presentation' className={ `relative overflow-hidden h-auto rounded-lg flex items-center justify-center ${ className }` }>
               <div
                  onClick={ (e) => e.stopPropagation() }
                  role='presentation'
                  className='absolute w-full top-0 left-0 p-2.5 flex items-center justify-between z-10'
                  style={ {
                     background: 'linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
                  } }
               >
                  <DragHandle />
                  <Button
                     textColor='major-dark'
                     backgroundColor='transparent'
                     iconName='cancel'
                     fontIconColor='major-dark'
                     padding='none'
                     onClick={ () => removePhoto(photo) }
                     borderRadius='large'
                     textSize='base'
                     classNames='!w-fit'
                     dataToolTipContent='Delete'
                  />
               </div>
               <div className='relative h-full w-full'>
                  <div className='aspect-ratio-padding' />
                  <div
                     style={ {
                        backgroundImage: `url(${ photo?.cf_thumb_v2_path  })`,
                        filter: 'blur(20px)',
                        zIndex: '-1',
                     } }
                     className='absolute inset-0 h-full w-full bg-cover bg-center'
                  />
                  <img className='w-full h-full absolute  top-0 right-0 object-contain object-center' src={ photo?.cf_thumb_v2_path } alt='' />
               </div>
               <div
                  className='w-full p-2 absolute bottom-0 left-0'
                  style={ {
                     background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
                  } }
               >
                  <div
                     className={ cx({
                        'flex items-center select-none w-fit cursor-pointer': true,
                     }) }
                     onClick={ (e) => {
                        e.stopPropagation()
                        makeCover(photo.id)
                     } }
                     role='presentation'
                  >
                     <Icon name={ `box-${ !!isSelected ? 'checked' : 'unchecked' }` } color='major-dark' size='xl' />
                     <span className='text-major-dark ml-2 leading-4 font-medium  text-sm'>{!!isSelected ? thumbnailText : 'Select thumbnail'}</span>
                  </div>
               </div>
            </div>
         );
      case 'uploading-zip':
         return (
            <div
               className={ `relative bg-placeholder rounded-label flex items-center justify-center ${ className }` }>
               <div className='aspect-ratio-padding' />
               <div
                  onClick={ (e) => e.stopPropagation() }
                  role='presentation'
                  className='absolute top-2 right-2 flex items-center'
               >
                  <Button
                     textColor='major-dark'
                     backgroundColor='transparent'
                     iconName='cancel'
                     fontIconColor='major-dark'
                     padding='none'
                     onClick={ () => removePhoto({ index: photo?.index })  }
                     borderRadius='large'
                     textSize='base'
                     dataToolTipContent='Delete'
                  />
               </div>
               <div className='flex flex-col gap-2 items-center justify-center'>
                  <Icon
                     name='zip'
                     color='major-dark'
                     size='[32px]'
                  />
                  <p className='text-major-dark text-sm leading-5'>Unzipping...</p>
               </div>
            </div>
         );
      default:
         break;
   }
}
AddNewPhotosetCard.propTypes = {
   photo: PropTypes.object,
   className: PropTypes.string,
   removePhoto: PropTypes.func,
   makeCover: PropTypes.func,
   cover: PropTypes.bool,
}

export default React.memo(AddNewPhotosetCard);
