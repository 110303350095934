import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Modal from 'common/components/modules/modal'
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label'
import LocalStorage from 'sites/utils/LocalStorage';
import Icon from 'common/components/elements/icons';
// import IntercomMoreInfo, { MORE_INFO_ON_WALLET } from 'admin/utils/IntercomMoreInfo.js';
import cx from 'classnames'

function WarningModal({
   action,
   actionText,
   closeModal,
   info,
   isLoading,
   cancelText,
   isCheckbox,
   isMobile,
   dontShowName,
   disabled,
   showNewStyles,
   newStyleForStore,
   hiddeButtons,
   showContactSuportBtn,
   customCss,
   hideCloseButton,
   iconName,
   iconColor,
   modalTitle,
   mainClassName,
   checkBoxTitle,
   onCustomAction,
   children,
   overrideContactSupportButtonProps,
   disableCancel,
}) {

   const [dontShow, setDonwShow] = useState(false)
   const [animate, setAnimate] = useState('up')
   let timeout = null
   useEffect(() => {
      return () => {
         clearTimeout(timeout)
      }
   }, [timeout])

   const onCloseModal = () => {
      if(isMobile) {
         setAnimate('down')
         timeout = setTimeout(() => {
            closeModal()
         }, 800)
      } else {
         closeModal()
      }
   }

   return (
      <Modal
         onClose={ onCloseModal }
         contentPosition={ isMobile ? 'bottom' : 'center' }
         animate={ isMobile ? animate : null }
         otherClassName={ `${ isMobile ?  '' : '!w-[375px]' }` }
         className={ `${ mainClassName } h-full` }
      >
         {
            showNewStyles && !hideCloseButton ?
               <div className='p-4 h-14'>
                  <Icon onClick={ onCloseModal } color='secondary' darkColor='secondary-dark' size='2xl' name='close' className='cursor-pointer' />
               </div>
               : null
         }
         <div className={
            cx({
               [`flex flex-col bg-panel dark:bg-panel-dark p-5 items-center justify-center`]: true,
               'px-6 pb-6 pt-0': showNewStyles,
               'pt-6': hideCloseButton,
            })
         }>
            {
               showNewStyles
                  && <>
                     <div className={ `h-10 w-10 mx-auto flex items-center justify-center mb-3 rounded-full bg-${ iconColor }-12 dark:bg-${ iconColor }-dark-12` }>
                        <Icon
                           name={ iconName }
                           size='2xl'
                           color={ iconColor }
                           darkColor={ `${ iconColor }-dark` }
                        />
                     </div>
                     <p className='text-xl leading-5 text-major dark:text-major-dark font-semibold '>{modalTitle}</p>
                  </>
            }
            {
               info && (
                  <span className={ showNewStyles ? 'text-center mt-4' : '' }>
                     <span
                        dangerouslySetInnerHTML={ { __html: info } }
                        className={ `${ showNewStyles ? '' : 'leading-tight mt-3' } text-secondary dark:text-secondary-dark  text-base` }>
                     </span>
                     {/* {
                        isCheckbox && !showNewStyles && <span
                           role='presentation'
                           onClick={ () => IntercomMoreInfo(MORE_INFO_ON_WALLET)
                           }
                           className='cursor-pointer ml-px inline-flex items-center justify-center w-5 h-5' >
                           <Icon name='help-outline' color='secondary' darkColor='secondary-dark'
                              style={ {
                                 height: '16.67px',
                              } }
                           />
                        </span>
                     } */}
                  </span>
               )
            }
            {
               isCheckbox && (
                  <div className='inline-block mt-4' >
                     <CheckboxLabel
                        label={ checkBoxTitle }
                        name='dont_show'
                        checked={ dontShow }
                        onChange={ () => setDonwShow(old =>  !old) }
                        textColor='secondary dark:text-secondary-dark'
                        padding='0'
                     />
                  </div>

               )
            }
            {children && children}
            {
               !hiddeButtons && <>
                  <div className={ `h-9 ${ showNewStyles ? 'mt-8' : 'mt-6' } ${ newStyleForStore ? '!mt-6' : '' }` }>
                     <Button
                        text={ actionText }
                        borderRadius='large'
                        backgroundColor='transparent'
                        textSize='small'
                        onClick={ () => {
                           if(isCheckbox) {
                              LocalStorage.setItem(dontShowName, +dontShow)
                           }
                           if(action) {
                              action()
                           }
                        }  }
                        padding='none'
                        isLoading={ isLoading }
                        disabled={ disabled }
                        classNames={ `h-full ${ showNewStyles ? 'w-[118px]' : 'w-full' }  ${ disabled ? ' opacity-50' : '' }` }
                        primaryColor
                     />
                  </div>
                  <div className={ `h-9 ${ showNewStyles ? 'mt-3' : 'mt-2' }` }>
                     <Button
                        text={ cancelText }
                        borderRadius='large'
                        backgroundColor='transparent'
                        textColor='secondary dark:text-secondary-dark'
                        textSize='small'
                        onClick={ () => {
                           onCloseModal()
                           if(!!onCustomAction) onCustomAction(+dontShow)
                        } }
                        padding='none'
                        classNames={ `h-full ${ showNewStyles ? 'w-[75px]' : 'w-full' } ` }
                        disabled={ disableCancel }
                     />
                  </div>
               </>
            }
            {
               showContactSuportBtn &&
               <div className='mx-auto mt-8 h-9'>
                  <Button
                     text='Contact support'
                     textSize='base'
                     classNames={ `h-full w-full intercom-purchase-limit ${ customCss }` }
                     iconName='attention-outline'
                     iconPosition='left'
                     alignment='end'
                     backgroundColor='transparent'
                     textColor='action dark:text-action-dark'
                     fontIconColor='action'
                     darkIconColor='action-dark'
                     fontIconSize='large'
                     textPosition='left'
                     { ...overrideContactSupportButtonProps }
                  />
               </div>
            }
         </div>
      </Modal>
   )
}

WarningModal.propTypes = {
   action: PropTypes.func,
   actionText: PropTypes.string,
   info: PropTypes.string,
   closeModal: PropTypes.func,
   isLoading: PropTypes.bool,
   cancelText: PropTypes.string,
   isCheckbox: PropTypes.bool,
   isMobile: PropTypes.bool,
   dontShowName: PropTypes.string,
   disabled: PropTypes.bool,
   showNewStyles: PropTypes.bool,
   newStyleForStore: PropTypes.bool,
   hiddeButtons: PropTypes.bool,
   showContactSuportBtn: PropTypes.bool,
   customCss: PropTypes.string,
   hideCloseButton: PropTypes.bool,
   iconName: PropTypes.string,
   iconColor: PropTypes.string,
   modalTitle: PropTypes.string,
   mainClassName: PropTypes.string,
   checkBoxTitle: PropTypes.string,
   onCustomAction: PropTypes.func,
   children: PropTypes.any,
   overrideContactSupportButtonProps: PropTypes.object,
   disableCancel: PropTypes.bool,
};

WarningModal.defaultProps = {
   cancelText: 'Cancel',
   dontShowName: 'dont_show_chat_coast_warning_modal',
   showNewStyles: false,
   newStyleForStore: false,
   hiddeButtons: false,
   showContactSuportBtn: false,
   customCss: 'intercom-purchase-limit',
   hideCloseButton: false,
   iconName: 'attention-outline',
   iconColor: 'warning',
   modalTitle: 'Warning',
   mainClassName: '',
   checkBoxTitle: 'Don’t show again',
   onCustomAction: () => {},
   overrideContactSupportButtonProps: {},
};

export default WarningModal;
