export const OPEN_HEADER_POPUP = 'notifications/OPEN_HEADER_POPUP';
export const OPEN_REPLY_COMMENT = 'notifications/OPEN_REPLY_COMMENT';
export const UPDATE_REPLY_COMMENTS = 'notifications/UPDATE_REPLY_COMMENTS';



export const UPDATE_CONVERSATION_DATA = 'notifications/UPDATE_CONVERSATION_DATA';
export const UPDATE_NOTIFICATIONS_DATA = 'notifications/UPDATE_NOTIFICATIONS_DATA';
export const MAKE_ALL_AS_SEEN_NOTIFICATIONS = 'notifications/MAKE_ALL_AS_SEEN_NOTIFICATIONS';
export const MAKE_ALL_AS_READ_NOTIFICATIONS = 'notifications/MAKE_ALL_AS_READ_NOTIFICATIONS';

export const FETCH_NOTIFICATIONS_REQUEST = 'notifications/FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_COMPLETED = 'notifications/FETCH_NOTIFICATIONS_COMPLETED';
export const FETCH_NOTIFICATIONS_FAILED = 'notifications/FETCH_NOTIFICATIONS_FAILED';

export const FETCH_NEW_NOTIFICATIONS_REQUEST = 'notifications/FETCH_NEW_NOTIFICATIONS_REQUEST';
export const FETCH_NEW_NOTIFICATIONS_COMPLETED = 'notifications/FETCH_NEW_NOTIFICATIONS_COMPLETED';
export const FETCH_NEW_NOTIFICATIONS_FAILED = 'notifications/FETCH_NEW_NOTIFICATIONS_FAILED';

export const FETCH_NOTIFICATIONS_BY_FILTER_REQUEST = 'notifications/FETCH_NOTIFICATIONS_BY_FILTER_REQUEST';
export const FETCH_NOTIFICATIONS_BY_FILTER_COMPLETED = 'notifications/FETCH_NOTIFICATIONS_BY_FILTER_COMPLETED';
export const FETCH_NOTIFICATIONS_BY_FILTER_FAILED = 'notifications/FETCH_NOTIFICATIONS_BY_FILTER_FAILED';


export const FETCH_REPLY_COMMENT_REQUEST = 'notifications/FETCH_REPLY_COMMENT_REQUEST';
export const FETCH_REPLY_COMMENT_COMPLETED = 'notifications/FETCH_REPLY_COMMENT_COMPLETED';
export const FETCH_REPLY_COMMENT_FAILED = 'notifications/FETCH_REPLY_COMMENT_FAILED';

export const FETCH_REPLY_CHAT_MESSAGE_REQUEST = 'notifications/FETCH_REPLY_CHAT_MESSAGE_REQUEST';
export const FETCH_REPLY_CHAT_MESSAGE_COMPLETED = 'notifications/FETCH_REPLY_CHAT_MESSAGE_COMPLETED';
export const FETCH_REPLY_CHAT_MESSAGE_FAILED = 'notifications/FETCH_REPLY_CHAT_MESSAGE_FAILED';


export const SEND_COMMENT_REQUEST = 'notifications/SEND_COMMENT_REQUEST';
export const SEND_COMMENT_COMPLETED = 'notifications/SEND_COMMENT_COMPLETED';
export const SEND_COMMENT_FAILED = 'notifications/SEND_COMMENT_FAILED';



export const REPLY_COMMENTS_TOGGLE_LIKE_REQUEST = 'notifications/REPLY_COMMENTS_TOGGLE_LIKE_REQUEST';
export const REPLY_COMMENTS_TOGGLE_LIKE_COMPLETED = 'notifications/REPLY_COMMENTS_TOGGLE_LIKE_COMPLETED';
export const REPLY_COMMENTS_TOGGLE_LIKE_FAILED = 'notifications/REPLY_COMMENTS_TOGGLE_LIKE_FAILED';

export const REPLY_COMMENTS_DELETE_COMPLETED = 'notifications/REPLY_COMMENTS_DELETE_COMPLETED';
export const SET_NOTIFICATIONS_PANEL = 'notifications/SET_NOTIFICATIONS_PANEL';
export const SET_UNREAD_COUNT = 'notifications/SET_UNREAD_COUNT';
export const SET_UNSEEN_COUNT = 'notifications/SET_UNSEEN_COUNT';

export const UPDATE_NOTIFICATIONS_BY_MEMBER_ID = 'notifications/UPDATE_NOTIFICATIONS_BY_MEMBER_ID';
