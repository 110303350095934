import React, { useEffect, useRef, useState }  from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Input from 'common/components/elements/inputs/inputs'
import CheckBox from 'common/components/elements/inputs/checkbox-label';
import './style.scss';
import cx from 'classnames';
import ConfirmModal from 'common/components/modules/modal-wrapper';


const EditPriceButtons = ({
   onClick,
   price = 0,
   isOpenEditContent = true,
}) => {
   const hasPrice = Boolean(price);

   return (
      <div
         className={ cx({
            'flex pt-3': true,
            'justify-center': !isOpenEditContent && !hasPrice,
            'justify-between': isOpenEditContent || hasPrice,
         }) }
      >
         {
            !isOpenEditContent &&
               <>
                  {
                     hasPrice &&
                        <div className='flex-1 flex items-center mr-3'>
                           <span className='text-placeholder dark:text-placeholder-dark leading-tighti ml-2px'> Price:<span className='text-major dark:text-major-dark ml-1'>{price} USD</span></span>
                        </div>
                  }
                  <div className='flex h-9'>
                     <Button
                        backgroundColor='transparent'
                        primaryColor
                        textMarginX='0'
                        textSize='small'
                        textClassName={ !hasPrice ? 'ml-2' : 'mr-2' }
                        iconPosition={ !hasPrice ? 'left' : 'right' }
                        fontIconSize='base'
                        text={ !hasPrice ? 'Set price to unlock' : 'Edit' }
                        iconName={ !hasPrice ? 'lock' : 'pencil' }
                        onClick={ () => onClick('set') }
                        classNames='px-3.5 py-2.5'
                     />
                  </div>
               </>
         }
      </div>

   )
}
const EditPriceContent = ({
   maxPrice = 100,
   error,
   handleSave,
   price,
   setPriceDetails,
   setError,
   makeDefaultPrice,
   isMobile,
   onClick,
   hideCheckbox,
}) => {
   const inputRef = useRef()
   const [initialState, setInitialState] = useState({ price, makeDefaultPrice })

   useEffect(() => {
      inputRef.current.focus();
   }, [])

   return (
      <ConfirmModal
         title='Set price to unlock'
         type='confirm'
         icon='lock'
         iconColor='action'
         buttonClassName='mt-8'
         descriptionColorClass='font-normal'
         contentWidth='375px'
         buttonText='Save'
         action={ () => {
            onClick('save')
         } }
         cancelButtonAction={ () => {
            onClick('closeModal', initialState)
            setInitialState({ price, makeDefaultPrice })
         } }
         isPreventDeafault={ false }
         isMobile={ isMobile }
         secondButtonTitle={ !initialState?.price ? '' : 'Remove price' }
         secondButtonAction={ () => {
            onClick('cancel')
         } }
      >
         <div className=''>
            <Input
               inputRef={ inputRef }
               rightText='USD'
               name='price'
               onChange={ (name, value) => {
                  setPriceDetails(name, value)
               } }
               value={ price || '' }
               // onInputBlur={ () => {
               //    let val = price;
               //    let invalidPrice = val < 0.25 || val > maxPrice || val === ''
               //    if(invalidPrice) {
               //       setError(`Price must be between 0.25 and ${ maxPrice } USD`)
               //    }
               // } }
               onInputFocus={ () => {
                  setError(null)
               } }
               onKeyDown={ (e) => {
                  if(e.keyCode === 13 && !e.shiftKey) {
                     e.preventDefault()
                     handleSave()
                  }
               } }
               className={ 'my-4 !flex !items-center !justify-center' }
               errorMessage={ error }
               isPriceInput
            />
            {
               !hideCheckbox &&
                  <CheckBox
                     label='Make this the default price for voice messages'
                     name='make_default_price_for_voice_messages'
                     checked={ makeDefaultPrice }
                     onChange={ setPriceDetails }
                     textColor='secondary dark:text-secondary-dark'
                     padding='none'
                     disabled={ false }
                     // className='flex !justify-center'
                     className='mx-auto !w-[fit-content]'
                     labelClassName='mx-auto !w-[fit-content]'
                     labelTextClassName='!w-[fit-content]'
                  />
            }
         </div>
      </ConfirmModal >
   )
}

EditPriceContent.propTypes = {
   handleSave: PropTypes.func,
   setError: PropTypes.func,
   setPriceDetails: PropTypes.func,
   maxPrice: PropTypes.number,
   price: PropTypes.number,
   error: PropTypes.string,
   makeDefaultPrice: PropTypes.bool,
   isMobile: PropTypes.number,
   onClick: PropTypes.func,
   hideCheckbox: PropTypes.bool,
};
EditPriceButtons.propTypes = {
   onClick: PropTypes.func,
   price: PropTypes.number,
   isOpenEditContent: PropTypes.bool,
};

export { EditPriceButtons, EditPriceContent };
