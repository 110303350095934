const initialState = {
   data: [],
   isFetching: true,
   modalSettings: {
      activeId: null,
      isOpen: false,
      type: 'option',
   },
   isEmpty: false,
   errors: {
      success: true,
      name: '',
   },
};

export default initialState;
