import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'common/components/elements/icons';

// import { ReactComponent as OpenEyeIcon } from 'assets/images/eye-visible.svg';
// import { ReactComponent as CloseEyeIcon } from 'assets/images/eye-visible-off.svg';
import cx from 'classnames'
import './style.scss';

const Input = ({
   onChange,
   value,
   placeholder,
   type,
   disabled,
   className,
   name,
   label,
   inputRef,
   width,
   errorMessage,
   onInputFocus,
   onInputBlur,
   description,
   descriptionFontWeight,
   descriptionFontColor,
   noneBorder,
   iconName,
   isConfirmed,
   isConfirmedIcon,
   rightText,
   hideError,
   descriptionPosition,
   showPasswordIcon,
   hasBorder,
   borderStyle,
   errorMessageStyle,
   hidePlaceholderOnFocus,
   descriptionFontSize,
   descriptionType,
   labelFontSize,
   labelFontWeight,
   topDescription,
   topDescriptionFontWeight,
   topDescriptionFontColor,
   topDescriptionFontSize,
   topDescriptionType,
   showErrorIcon,
   iconColor,
   height,
   descriptionMarginBottom,
   hideDescription,
   hideDescriptionWhenError,
   onSetError,
   showNewStyles,
   inputIconClassname,
   inputIconDisplaying,
   inputBorder,
   labelClasses,
   priceLabel,
   inputClassInStore,
   rightTextQuantity,
   errorMessageClassName,
   hideErrorMessage,
   inputClassInStoreDetails,
   rightTextClassName,
   hasEmojiPicker,
   showErrorOnFocus,
   descriptionClassName,
   inputClassName,
   ignoreErrorIcon,
   isBlackDisabledLabel,
   hideEmptyDescrition,
   topDescriptionMargin,
   righitIconClass,
   isPriceInput,
   disabledOnlyInputField,
   maxPrice,
   iconClassName,
   tooltipState,
   showErrorOnBlur,
   rightTextHelperText,
   inputWrapperClassName,
   ...rest
}) => {
   const [focusState, setFocusState] = useState(false);
   const [placeholderToShow, setPlaceholderToShow] = useState(placeholder);
   const [showError, setShowError] = useState(false);
   const [showPassword, setShowPassword] = useState(false);
   useEffect(() => {
      if(errorMessage) {
         setShowError(true);
         if(onSetError){
            onSetError(true);
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [errorMessage]);
   useEffect(() => {
      if(placeholder) {
         setPlaceholderToShow(placeholder);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [placeholder]);

   function handleOnChange(e) {
      let value = e.target.value
      setShowError(false);
      if(onSetError){
         onSetError(false);
      }
      if(onChange) {
         if(isPriceInput && !!e.target.value) {
            const regexToFixed = /^\d*\.?\d{0,2}$/
            const regexForZero = /^(?!0\d)|^0{1}$/
            if(!regexToFixed.test(value) || !regexForZero.test(value) || value > maxPrice || (value >= maxPrice && value[value.length - 1] === '.')){
               e.preventDefault()
               return
            }
         }
         onChange(e.target.name, value);
      }
   }

   function handleInputFocus(e) {
      if(hidePlaceholderOnFocus) {
         setPlaceholderToShow('');
      }
      setShowError(false);
      if(onSetError){
         onSetError(false);
      }
      setFocusState(true);
      if(onInputFocus) {
         onInputFocus(e);
      }
   }

   function handleInputBlur(e) {

      setPlaceholderToShow(placeholder)
      setFocusState(false);
      if(onInputBlur) {
         onInputBlur(e);
      }
      if(showErrorOnBlur) {
         setShowError(true);
      }
   }

   const isShowDescription = () => {
      if(hideEmptyDescrition){
         return description || (errorMessage && showError && !hideError)
      }
      return true;
   }

   return (
      <div
         className={ `inline-flex flex-col w-full ${ className } ${ isPriceInput ? 'price-input-styles' : '' }` }
      >
         {
            label && (
               <span
                  className={ `text-${ labelFontSize } font-${ labelFontWeight }  ${ classNames({
                     'leading-tight  mb-2 admin_inputLabel select-none dark:text-major-dark': true,
                     'text-placeholder dark:text-placeholder-dark': disabled && !isBlackDisabledLabel,
                     'text-major dark:text-major-dark': !disabled || (disabled && isBlackDisabledLabel),
                     [`${ labelClasses }`]: !!labelClasses,
                  }) } ` }
               >
                  {label}
               </span>
            )
         }
         {
            name && name === 'zoneName' && (
               <span className='mb-2 text-base font-medium text-major'>Name</span>
            )
         }
         {
            topDescription && (
               <>
                  {
                     topDescriptionType === 'string' && (
                        <span
                           className={ `leading-18 font-${ topDescriptionFontWeight } text-${ topDescriptionFontSize } ${ topDescriptionMargin } select-none text-${ topDescriptionFontColor }` }
                        >
                           { topDescription }
                        </span>
                     )
                  }
                  {
                     topDescriptionType === 'html' && (
                        <div
                           className={ `leading-18 font-${ topDescriptionFontWeight } text-${ topDescriptionFontSize } pt-1 ${ topDescriptionMargin } select-none text-${ topDescriptionFontColor }` }
                           dangerouslySetInnerHTML={ { __html: topDescription } }
                        />
                     )
                  }
               </>
            )
         }
         <div
            { ...tooltipState }
            className={ `w-${ width } h-${ height || 'full' } relative input-wrapper ${ inputWrapperClassName }` }>
            {
               !!iconName &&
               <div className={ `absolute h-6 w-6 flex items-center justify-center my-3 mx-2 ${ iconClassName }` }>
                  <Icon
                     //color={ (showError && !focusState) ? 'error' : (isConfirmed && !focusState) ? 'success' : focusState ? 'action' : 'major dark:text-major-dark' }
                     color={ iconColor }
                     name={ iconName }
                     size='xl'
                  />
               </div>
            }
            <input
               { ...rest }
               ref={ inputRef }
               name={ name }
               value={ value }
               disabled={ disabled || disabledOnlyInputField }
               className={ `truncate ${ classNames({
                  [`rounded-lg w-full h-${ height ? height : '12' }  dark:bg-black-field dark:text-major-dark dark:shadow-none outline-none admin_input border border-${ borderStyle } text-base font-normal leading-tight py-3 ${ inputBorder } tracking-[0.02px] placeholder:!tracking-[0.02px]`]: true,
                  '': !showError,
                  'dark:border-divider-dark border-divider': !(showError && !focusState && !!errorMessage) && !isConfirmed,
                  'border-error dark:border-error-dark autofillError': (showError && !focusState && !!errorMessage),
                  'border-success dark:border-success-dark': isConfirmed,
                  'text-disabled dark:text-disabled-dark dark:border-divider-dark border-divider placeholder:text-disabled placeholder:dark:text-disabled-dark bg-grey-main !cursor-default': disabled,
                  'bg-panel placeholder:text-placeholder placeholder:dark:text-placeholder': !disabled,
                  'noneBorder': noneBorder,
                  'px-3': !iconName,
                  'pr-3 pl-9': !!iconName,
                  '!pr-10': (!!rightText || hasEmojiPicker),
                  'pr-[14px]': !!rightText && !!inputClassInStore,
                  'pl-[154px]': inputClassInStoreDetails,
                  '!h-10 !py-[10px] !px-[14px]': name === 'zoneName',
                  [inputClassName]: !!inputClassName,
               }) }` }
               placeholder={ placeholderToShow }
               type={ !showPasswordIcon ? type : showPassword ? 'text' : 'password' }
               onFocus={ (e) => handleInputFocus(e) }
               onBlur={ (e) => handleInputBlur(e) }
               onChange={ (e) => handleOnChange(e) }
            />
            {
               isConfirmed && !focusState && isConfirmedIcon &&
               <div className={ `absolute flex items-center justify-center right-0 top-0 my-2 mx-2 ${ righitIconClass }` }>
                  <Icon
                     color='success'
                     darkColor='success-dark'
                     name='circle-checked'
                     size='2xl'
                  />
               </div>
            }
            {
               showPasswordIcon && (
                  <div
                     className={ `absolute flex items-center justify-center right-0 top-0 ml-2 mr-3 w-5 select-none ${ righitIconClass } cursor-pointer` }
                     style={ (showError && !!errorMessage) ? { right: '27px' } : {} }
                     onClick={ () => setShowPassword(!showPassword) }
                     role='presentation'
                  >
                     {/* {showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />} */}
                     <Icon
                        name={ showPassword ? 'views' : 'no-views' }
                        size='xl'
                        color='secondary'
                        darkColor='secondary-dark'
                     />
                  </div>
               )
            }
            {
               !isPriceInput && showError && !focusState && !!errorMessage && showErrorIcon && (
                  <div className={ `absolute flex items-center justify-center right-0 ${ righitIconClass } ml-2 mr-[14px]` } style={ { top: '0px' } }>
                     <Icon
                        color='error'
                        darkColor='error-dark'
                        name='attention'
                        size='base'
                     />
                  </div>
               )
            }
            {
               rightText && ((!(showError && !focusState && !!errorMessage) || !showErrorIcon) || isPriceInput) &&
               <span className={ cx({
                  [`absolute select-none flex items-center justify-center right-0 top-0 mx-2 text-placeholder dark:text-placeholder-dark input_rightText ${ rightTextClassName }  ${ disabled ? '!text-disabled dark:!text-disabled-dark' : '' }`]: true,
                  'input_rightText_error': showError && !focusState && !!errorMessage,
               }) }>
                  {rightText}{rightTextHelperText ? '/' : ''}
                  {
                     rightTextHelperText && (
                        <span
                           className='text-xs leading-[1px]'
                        >
                           {rightTextHelperText}
                        </span>
                     )
                  }
               </span>
            }
         </div>
         {
            ((!hideDescription && isShowDescription()) && (!!description || !!errorMessage)) &&
            <div style={ { minHeight: '14px' } } className={ `flex flex-col mt-[6px] text-${ descriptionPosition } input-error-message` }>
               {
               // !hideDescription && description && (
                  ((!hideDescriptionWhenError && description) || (hideDescriptionWhenError && description && !(errorMessage && showError && !hideError))) && (
                     <>
                        {
                           descriptionType === 'string' && (
                              <span
                                 className={ `leading-[16px] font-${ descriptionFontWeight } text-${ descriptionFontSize } mb-${ descriptionMarginBottom ? descriptionMarginBottom : '' } select-none text-${ descriptionFontColor } ${ descriptionClassName }` }
                              >
                                 { description }
                              </span>
                           )
                        }
                        {
                           descriptionType === 'html' && (
                              <div
                                 className={ `leading-[16px] font-${ descriptionFontWeight } text-${ descriptionFontSize } pt-1 mb-1 select-none text-${ descriptionFontColor } ${ descriptionClassName }` }
                                 dangerouslySetInnerHTML={ { __html: description } }
                              />
                           )
                        }
                     </>
                  )
               }
               {
                  'v1' === errorMessageStyle && errorMessage && showError && !hideError && (
                     <span
                        style={ { minHeight: '16px' } }
                        className={ `text-xs text-error dark:text-error-dark block leading-none select-none ${ errorMessageClassName }` }
                     >
                        {!hideErrorMessage ?  errorMessage && !disabled && !focusState ? errorMessage : '' : ''}
                     </span>
                  )
               }
               {
                  'v2' === errorMessageStyle && errorMessage && (
                     <span
                        style={ { minHeight: '16px' } }
                        className='text-xs text-error  dark:text-error-dark block leading-none select-none'
                     >
                        {((errorMessage && !disabled && !focusState) || showErrorOnFocus) ? errorMessage : ''}
                     </span>
                  )
               }
            </div>
         }
      </div>
   );
};

Input.defaultProps = {
   placeholder: '',
   type: 'text',
   className: '',
   name: '',
   value: '',
   width: 'full',
   disabled: false,
   noneBorder: false,
   descriptionFontWeight: 'medium',
   descriptionFontColor: 'secondary dark:text-secondary-dark',
   isConfirmed: false,
   isConfirmedIcon: true,
   hideError: false,
   descriptionPosition: 'left',
   showPasswordIcon: false,
   hasBorder: false,
   hidePlaceholderOnFocus: false,
   borderStyle: 'solid',
   errorMessageStyle: 'v1',
   descriptionFontSize: 'xs',
   descriptionType: 'string',
   labelFontSize: 'base',
   labelFontWeight: 'medium',
   topDescriptionFontWeight: 'normal',
   topDescriptionFontColor: 'secondary dark:text-secondary-dark',
   topDescriptionFontSize: 'sm',
   topDescriptionType: 'string',
   showErrorIcon: true,
   iconColor: 'major dark:text-major-dark',
   rightTextClassName: '',
   inputClassInStoreDetails: false,
   showErrorOnFocus: false,
   labelClasses: '',
   descriptionClassName: '',
   inputClassName: '',
   ignoreErrorIcon: true,
   isBlackDisabledLabel: false,
   topDescriptionMargin: 'mb-2',
   righitIconClass: 'my-3',
   isPriceInput: false,
   disabledOnlyInputField: false,
   maxPrice: 1000,
   iconClassName: '',
   tooltipState: {},
   showErrorOnBlur: false,
   inputWrapperClassName: '',
};

Input.propTypes = {
   label: PropTypes.string,
   onChange: PropTypes.func,
   value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
   name: PropTypes.string,
   className: PropTypes.string,
   descriptionFontWeight: PropTypes.string,
   descriptionFontColor: PropTypes.string,
   type: PropTypes.string,
   errorMessage: PropTypes.string,
   disabled: PropTypes.bool,
   placeholder: PropTypes.string,
   width: PropTypes.string,
   descriptionPosition: PropTypes.string,
   inputRef: PropTypes.any,
   onInputFocus: PropTypes.func,
   onInputBlur: PropTypes.func,
   description: PropTypes.string,
   iconName: PropTypes.string,
   noneBorder: PropTypes.bool,
   isConfirmed: PropTypes.bool,
   isConfirmedIcon: PropTypes.bool,
   rightText: PropTypes.string,
   hideError: PropTypes.bool,
   showPasswordIcon: PropTypes.bool,
   hasBorder: PropTypes.bool,
   hidePlaceholderOnFocus: PropTypes.bool,
   descriptionFontSize: PropTypes.string,
   descriptionType: PropTypes.any,
   labelFontSize: PropTypes.string,
   labelFontWeight: PropTypes.string,
   topDescription: PropTypes.string,
   topDescriptionFontWeight: PropTypes.string,
   topDescriptionFontColor: PropTypes.string,
   topDescriptionFontSize: PropTypes.string,
   topDescriptionType: PropTypes.any,
   showErrorIcon: PropTypes.bool,
   iconColor: PropTypes.string,
   onSetError: PropTypes.func,
   hideDescriptionWhenError: PropTypes.bool,
   inputIconClassname: PropTypes.string,
   inputIconDisplaying: PropTypes.bool,
   inputBorder: PropTypes.string,
   labelClasses: PropTypes.any,
   priceLabel: PropTypes.string,
   inputClassInStore: PropTypes.string,
   rightTextQuantity: PropTypes.string,
   errorMessageClassName: PropTypes.string,
   isBlackDisabledLabel: PropTypes.bool,
   hideErrorMessage: PropTypes.bool,
   errorMessageStyle: PropTypes.oneOf([
      'v1',
      'v2',
   ]),
   borderStyle: PropTypes.oneOf([
      'solid',
      'dashed',
      'dotted',
      'double',
      'none',
   ]),
   height: PropTypes.string,
   descriptionMarginBottom: PropTypes.string,
   hideDescription: PropTypes.bool,
   showNewStyles: PropTypes.bool,
   rightTextClassName: PropTypes.string,
   hasEmojiPicker: PropTypes.bool,
   inputClassInStoreDetails: PropTypes.bool,
   showErrorOnFocus: PropTypes.bool,
   descriptionClassName: PropTypes.string,
   inputClassName: PropTypes.string,
   ignoreErrorIcon: PropTypes.bool,
   hideEmptyDescrition: PropTypes.bool,
   topDescriptionMargin: PropTypes.string,
   righitIconClass: PropTypes.string,
   isPriceInput: PropTypes.bool,
   disabledOnlyInputField: PropTypes.bool,
   maxPrice: PropTypes.number,
   iconClassName: PropTypes.string,
   tooltipState: PropTypes.object,
   showErrorOnBlur: PropTypes.bool,
   rightTextHelperText: PropTypes.string,
   inputWrapperClassName: PropTypes.string,
};


export default Input;
