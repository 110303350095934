import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Modal from 'common/components/modules/modal';
import Icon from 'common/components/elements/icons';
import './style.scss'
import { Link } from 'react-router-dom';
import IntercomMoreInfo, { MORE_INFO_ON_VIDEOS } from 'admin/utils/IntercomMoreInfo.js';
import Router from 'admin/routes/router';
import Intercom from 'common/utils/Intercom';
import { ARTICLE_ID_ON_PHOTOSETS, ARTICLE_ID_ON_STORIES } from 'common/utils/intercom-articles';

const AddContentModal = ({
   onCloseModal,
   animate,
}) => {
   return (
      <Modal
         contentPosition='responsive'
         onClose={ onCloseModal }
         animate={ animate }
         isCloseAction={ false }
      >
         <div className='flex items-center border-b border-divider py-3 px-4'>
            <div className='h-6 w-6 flex mr-4'>
               <Button
                  backgroundColor='transparent'
                  iconName='close'
                  fontIconColor='major'
                  classNames='h-full w-full'
                  padding='none'
                  onClick={ () => onCloseModal() }
               />
            </div>
            <span className='leading-tight text-major'>Add content</span>
         </div>
         <div className='flex flex-col add-content-modal'>
            <AddContentCard
               iconName='photo-camera'
               title='Add stories'
               description='Photos or short videos lasting less than 30 seconds'
               moreInfoText='More info on stories'
               onClickMoreInfo={ () => Intercom.showArticle(ARTICLE_ID_ON_STORIES) }
               addContentLink='STORIES'
               className='border-b border-divider'
               onCloseModal={ onCloseModal }
               queryParam='?type=add'
               // intercomClassName='intercom-more-info-on-stories'
            />
            <AddContentCard
               iconName='videos'
               title='Add videos'
               description='Videos several minutes in length of more'
               moreInfoText='More info on videos'
               onClickMoreInfo={ () => IntercomMoreInfo(MORE_INFO_ON_VIDEOS)  }
               addContentLink='VIDEOS'
               className='border-b border-divider'
               onCloseModal={ onCloseModal }
               queryParam=''
               intercomClassName='intercom-more-info-on-videos'
            />
            <AddContentCard
               title='Add photosets'
               iconName='photosets'
               description='Collections of photos'
               moreInfoText='More info on photosets'
               onClickMoreInfo={ () => Intercom.showArticle(ARTICLE_ID_ON_PHOTOSETS) }
               addContentLink='PHOTOSETS'
               onCloseModal={ onCloseModal }
               queryParam='?create=1'
               // intercomClassName='intercom-more-info-on-photosets'
            />
         </div>
      </Modal>
   );
};
const AddContentCard = ({
   title,
   iconName,
   description,
   moreInfoText,
   onClickMoreInfo,
   addContentLink,
   className,
   onCloseModal,
   queryParam,
   intercomClassName,
}) => {
   return (
      <Link
         to={ `${ Router.route(addContentLink).getCompiledPath() }${ queryParam }` }
         onClick={ () => onCloseModal(true) }
      >
         <div
            className={ `flex flex-col ${ className } px-3 py-4` }>
            <div className='flex justify-between px-1'>
               <div className='flex'>
                  <div className='min-w-contentIcon w-10 h-10 flex items-center bg-secondary-12 rounded-full justify-center'>
                     <Icon name={ iconName } size='2xl' color='secondary' />
                  </div>
                  <div className='flex flex-col mx-4'>
                     <span className='font-medium leading-tight text-major block mb-px'>{title}</span>
                     <span className='text-sm description-lineHeight text-secondary'>{description}</span>
                  </div>
               </div>
               <div className='h-6 w-6 flex mr-4'>
                  <Icon
                     name='arrow-right'
                     size='3xl'
                     color='secondary'
                  />
               </div>
            </div>
            <div
               role='presentation'
               onClick={ (e) => e.stopPropagation() }
               className='h-9 w-auto mt-3 flex items-start more-info-content ml-1'
            >
               <Button
                  text={ moreInfoText }
                  textColor='action'
                  backgroundColor='transparent'
                  textSize='base'
                  iconName='attention-outline'
                  iconPosition='left'
                  fontIconColor='action'
                  fontIconSize='base'
                  primaryColor
                  padding='none'
                  classNames={ `w-auto h-full px-3 ${ intercomClassName }` }
                  onClick={ (e) => {
                     e.preventDefault()
                     onClickMoreInfo()
                     e.stopPropagation()
                  } }
               />
            </div>
         </div>
      </Link>
   )
}
AddContentModal.propTypes = {
   onCloseModal: PropTypes.func,
   animate: PropTypes.string,
};
AddContentModal.defaultProps = {
   onCloseModal: () => {},
};

AddContentCard.propTypes = {
   title: PropTypes.string,
   iconName: PropTypes.string,
   description: PropTypes.string,
   moreInfoText: PropTypes.string,
   onClickMoreInfo: PropTypes.func,
   onCloseModal: PropTypes.func,
   addContentLink: PropTypes.string,
   className: PropTypes.string,
   queryParam: PropTypes.string,
   intercomClassName: PropTypes.string,
};

AddContentCard.defaultProps = {
   className: '',
   onClickMoreInfo: () => {},
};

export default React.memo(AddContentModal);
