
import { createSelector } from 'reselect'

const innerStateSelector = state => state.vault;

// images
const vaultImagesDataSelector = createSelector(
   innerStateSelector,
   state => state.vaultImagesData
);
const isEmptyImagesSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyImages
);
const isFetchingImagesSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingImages
);
const isFetchingImagesByFilterSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingImagesByFilter
);
const isEmptyImagesByFilterSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyImagesByFilter
);
const isNewFetchingImagesSelector = createSelector(
   innerStateSelector,
   state => state.isNewFetchingImages
);


// videos
const vaultVideosDataSelector = createSelector(
   innerStateSelector,
   state => state.vaultVideosData
);
const isEmptyVideosSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyVideos
);
const isFetchingVideosSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingVideos
);
const isFetchingVideosByFilterSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingVideosByFilter
);
const isEmptyVideosByFilterSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyVideosByFilter
);
const isNewFetchingVideosSelector = createSelector(
   innerStateSelector,
   state => state.isNewFetchingVideos
);

// all vaults

const allVaultDataSelector = createSelector(
   innerStateSelector,
   state => state.allVaultData
);

const isFetchingAllVaultSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingAllVault
);

const isEmptyAllVaultSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyAllVault
);

const isEmptyAllVaultByFilterSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyAllVaultByFilter
);

const isFetchingAllVaultByFilterSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingAllVaultByFilter
);

const isNewfetchingAllVaultsSelector = createSelector(
   innerStateSelector,
   state => state.isNewfetchingAllVaults
)

const vaultsIntedStateSelector = createSelector(
   innerStateSelector,
   state => ({
      vaultImagesIsInited: state.vaultImagesIsInited,
      vaultVideosIsInited: state.vaultVideosIsInited,
      allVaultIsInited: state.allVaultIsInited,
   })
)

const filtersInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.filtersInsideModal
)



export {
   // images
   vaultImagesDataSelector,
   isEmptyImagesSelector,
   isFetchingImagesSelector,
   isFetchingImagesByFilterSelector,
   isEmptyImagesByFilterSelector,
   isNewFetchingImagesSelector,
   // videos
   vaultVideosDataSelector,
   isEmptyVideosSelector,
   isFetchingVideosSelector,
   isFetchingVideosByFilterSelector,
   isEmptyVideosByFilterSelector,
   isNewFetchingVideosSelector,
   // all vault
   allVaultDataSelector,
   isFetchingAllVaultSelector,
   isEmptyAllVaultSelector,
   isEmptyAllVaultByFilterSelector,
   isFetchingAllVaultByFilterSelector,
   isNewfetchingAllVaultsSelector,

   vaultsIntedStateSelector,
   filtersInsideModalSelector,
}
