import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import intercomMoreInfo, { MORE_INFO_ON_SITE_APPROVAL } from 'admin/utils/IntercomMoreInfo';

const OnboardingBottomContent = ({
   // description,
   className,
   linkButtonText,
   isSubmit,
   buttonText,
   submitOnboardingStep,
   loading,
   intercomClassName,
}) => {
   return (
      <div
         className={ ` bottom-content bottom-0 left-0 w-full flex flex-col items-center justify-between my-6 ${ className }` }>
         {/* {
            description && <span className='leading-tight text-secondary text-center mb-3'> {description} </span>
         } */}
         {
            isSubmit && (
               <div className='h-11 mb-3 w-full'>
                  <Button
                     text={ buttonText }
                     padding='none'
                     classNames='h-full px-3'
                     primaryColor
                     borderRadius='large'
                     onClick={ submitOnboardingStep }
                     isLoading={ loading }
                     textSize='base'
                  />
               </div>
            )
         }
         <div className='h-9'>
            <Button
               text={ linkButtonText }
               textSize='small'
               iconName='attention-outline'
               fontIconSize='base'
               iconPosition='left'
               backgroundColor='transparent'
               padding='none'
               classNames={ `h-full px-[14px] gap-2 ${ intercomClassName } ` }
               primaryColor
               onClick={ () => intercomMoreInfo(MORE_INFO_ON_SITE_APPROVAL) }
               textMarginX={ 0 }
            />
         </div>
      </div>
   );
};

OnboardingBottomContent.propTypes = {
   linkButtonText: PropTypes.string,
   // description: PropTypes.string,
   className: PropTypes.string,
   buttonText: PropTypes.string,
   isSubmit: PropTypes.bool,
   submitOnboardingStep: PropTypes.func,
   loading: PropTypes.bool,
   intercomClassName: PropTypes.string,
};
OnboardingBottomContent.defaultProps = {
   step: {},
   className: '',
};

export default React.memo(OnboardingBottomContent);
