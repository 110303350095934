import * as types from "./types";

// get members tags
const fetchMembersTagsStart = () => ({
   type: types.FETCH_MEMBERS_TAGS_START,
});

const fetchMembersTagsCompleted = (data) => ({
   type: types.FETCH_MEMBERS_TAGS_COMPLETED,
   payload: data,
});

const fetchMembersTagsFailed = (error) => ({
   type: types.FETCH_MEMBERS_TAGS_FAILED,
   payload: error,
})

// get new members tags
const fetchNewMembersTagsStart = () => ({
   type: types.FETCH_NEW_MEMBERS_TAGS_START,
});

const fetchNewMembersTagsCompleted = (data) => ({
   type: types.FETCH_NEW_MEMBERS_TAGS_COMPLETED,
   payload: data,
});

const fetchNewMembersTagsFailed = (error) => ({
   type: types.FETCH_NEW_MEMBERS_TAGS_FAILED,
   payload: error,
})

// get members tags by filter
const tagsByFiltersStart = (filter = '') => ({
   type: types.TAGS_FILTER_START,
   payload: filter,
})

const tagsByFiltersFailed = (error) => ({
   type: types.TAGS_FILTER_FAILED,
   payload: error,
})

const tagsByFiltersCompleted = (data) => ({
   type: types.TAGS_FILTER_COMPLETED,
   payload: data,
})

// add members tag
const addNewTagStart = () => ({
   type: types.ADD_NEW_TAG_START,
});
const addNewTagCompleted = (data) => ({
   type: types.ADD_NEW_TAG_COMPLETED,
   payload: data,
});
const addNewTagFailed = (err) => ({
   type: types.ADD_NEW_TAG_FAILED,
   payload: err,
});

// update members tag
const updateMembersTagStart = () => ({
   type: types.UPDATE_MEMBERS_TAG_START,
});
const updateMembersTagCompleted = (id, data) => ({
   type: types.UPDATE_MEMBERS_TAG_COMPLETED,
   payload: { id, data },
});
const updateMembersTagFailed = (err) => ({
   type: types.UPDATE_MEMBERS_TAG_FAILED,
   payload: err,
});

// delete members tag
const deleteMembersTagStart = () => ({
   type: types.DELETE_MEMBERS_TAG_START,
});
const deleteMembersTagCompleted = (data) => ({
   type: types.DELETE_MEMBERS_TAG_COMPLETED,
   payload: data,
});
const deleteMembersTagFailed = (err) => ({
   type: types.DELETE_MEMBERS_TAG_FAILED,
   payload: err,
});

// delete members tags
const deleteMembersTagsStart = () => ({
   type: types.DELETE_MEMBERS_TAGS_START,
});
const deleteMembersTagsCompleted = (data) => ({
   type: types.DELETE_MEMBERS_TAGS_COMPLETED,
   payload: data,
});
const deleteMembersTagsFailed = (err) => ({
   type: types.DELETE_MEMBERS_TAGS_FAILED,
   payload: err,
});

export {
   fetchMembersTagsStart,
   fetchMembersTagsCompleted,
   fetchMembersTagsFailed,

   fetchNewMembersTagsStart,
   fetchNewMembersTagsCompleted,
   fetchNewMembersTagsFailed,

   tagsByFiltersStart,
   tagsByFiltersFailed,
   tagsByFiltersCompleted,

   addNewTagStart,
   addNewTagCompleted,
   addNewTagFailed,

   updateMembersTagStart,
   updateMembersTagCompleted,
   updateMembersTagFailed,

   deleteMembersTagStart,
   deleteMembersTagCompleted,
   deleteMembersTagFailed,

   deleteMembersTagsStart,
   deleteMembersTagsCompleted,
   deleteMembersTagsFailed,
}
