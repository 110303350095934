// BLOCKING IP

export const FETCH_BLOCK_IP_LIST = "blocking/FETCH_BLOCK_IP_LIST";
export const FETCH_BLOCK_IP_LIST_COMPLETED = "blocking/FETCH_BLOCK_IP_LIST_COMPLETED";
export const FETCH_BLOCK_IP_LIST_FAILED = "blocking/FETCH_BLOCK_IP_LIST_FAILED";
export const CREATE_NEW_IP_LIST_COMPLETED = "blocking/CREATE_NEW_IP_LIST_COMPLETED";
export const UPDATE_IP_LIST_COMPLETED = "blocking/UPDATE_IP_LIST_COMPLETED";
export const DELETE_IP_LIST_COMPLETED = "blocking/DELETE_IP_LIST_COMPLETED";
export const SET_ERROR_DATA = "blocking/SET_ERROR_DATA";
export const FETCH_BLOCK_NEXT_IP_LIST = "blocking/FETCH_BLOCK_NEXT_IP_LIST";
export const FETCH_BLOCK_NEXT_IP_LIST_COMPLETED = "blocking/FETCH_BLOCK_NEXT_IP_LIST_COMPLETED";

export const SET_IP_MODAL = "blocking/SET_IP_MODAL";


///BLOCKING REGIONS
export const FETCH_REGIONS = "blocking/FETCH_REGIONS";
export const FETCH_REGIONS_COMPLETED = "blocking/FETCH_REGIONS_COMPLETED";
export const FETCH_REGIONS_FAILED = "blocking/FETCH_REGIONS_FAILED";
export const UN_BLOCK_REGIONS_COMPLETED = "blocking/UN_BLOCK_REGIONS_COMPLETED";
export const BLOCK_REGIONS_COMPLETED = "blocking/BLOCK_REGIONS_COMPLETED";
export const SEARACH_REGIONS_DATA = "blocking/SEARACH_REGIONS_DATA";


// BLOCKING MEMBERS
export const FETCH_BLOCK_MEMBERS = "blocking/FETCH_BLOCK_MEMBERS";
export const FETCH_BLOCK_MEMBERS_COMPLETED = "blocking/FETCH_BLOCK_MEMBERS_COMPLETED";
export const BLOCK_MEMBERS_COMPLETED = "blocking/BLOCK_MEMBERS_COMPLETED";
export const UN_BLOCK_MEMBERS_COMPLETED = "blocking/UN_BLOCK_MEMBERS_COMPLETED";
export const UPDATE_MEMBER_STATE = "blocking/UPDATE_MEMBER_STATE";

// VPN blocked
export const FETCH_VPN_BLOCKED = "blocking/FETCH_VPN_BLOCKED";
export const FETCH_VPN_BLOCKED_COMPLETED = "blocking/FETCH_VPN_BLOCKED_COMPLETED";
