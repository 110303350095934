import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const SwitchItem = ({
   checked,
   onClick,
   label,
   className,
   fontWeight,
   children,
}) => {
   return (
      <div
         role='presentation'
         className={ cx({
            [`w-full rounded-lg flex justify-center items-center cursor-pointer relative h-10 px-4 ${ className }`]: true,
            ' bg-panel dark:bg-panel-dark rounded-btn ': checked,
            '': !checked,
         }) }
         onClick={ onClick }
      >
         <span
            className={ cx({
               [` leading-tight select-none`]: true,
               [`text-action dark:text-action-dark ${ fontWeight ? `font-${ fontWeight }` : 'font-semibold' } `]: checked,
               'text-secondary dark:text-secondary-dark ': !checked,
            }) }
         >
            { label }
         </span>
         {!!children && children}
      </div>
   );
};

SwitchItem.defaultProps = {
   className: '',
};

SwitchItem.propTypes = {
   className: PropTypes.string,
   fontWeight: PropTypes.string,
   label: PropTypes.string,
   checked: PropTypes.bool,
   onClick: PropTypes.func.isRequired,
   children: PropTypes.any,
};

export default memo(SwitchItem);
