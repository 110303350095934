const initialState = {
   scheduleData: {},
   isFetching: true,
   recordedStreams: {},
   validationErrors: {},
   isFetchingRecordFilter: false,
   isFetchingRecord: true,
   isEmptyByFilterRecord: false,
   isEmptyByRecord: false,
   isNewFetchingRecords: false,
   isFetchingSchedule: true,
   isFetchingCreateSchedule: false,
   isFetchingStartStream: false,
   isFetchingEndStream: false,
   updateErrorsData: null,
   initData: {},
   ivs: null,
   messageData: {},
   streamConversation: {
      typers: {},
      chat_room_id: 'live_stream',
      conversation_name: 'Livestream chat',
      username: 'Livestream chat',
      unread_messages_count: 0,
   },
   isFetchingMessages: true,
   conversationSatet: {
      chatType: 'live_stream',
      showConversion: true,
   },
   isConnected: false,
   onlineUsers: [],
   //////
   likedMemberByMessage: {
   },
   ////
   isFetchNewConversationMessages: false,
   inited: false,
   openStreamStateModal: {
      isopen: false,
      type: null,
   },
   currentData: {},
   tipGoalAchievedModal: false,
   tipGoalActionsAchievedModal: { isOpen: false, actions: [] },
   tipHistory: {
      data: [],
      sortBy: { name: 'Newest', value: 'date_added:desc' },
   },
   activeViewers: [],
   activeViewersFilter: {
      access: {
         name: 'Access',
         value: 'all',
      },
      memberships: { name: '', value: [] },
      status: { name: '', value: [] },
      sortBy: {
         name: 'Newest',
         value: 'date_added:desc',
      },
   },
   deleteRecordButtonDisabled: false,
   warningActivityModal: {
      isOpen: false,
      type: null,
   },
   deleteUpcomingState: {
      isOpen: false,
      isLoading: false,
   },
   fetchCheckValidation: false,
   fetchCreateLivestream: false,
   warningModal: {
      isOpen: false,
      description: null,
      ids: null,
      multiple_bundles: null,
      reason: null,
      buttonText: null,
      title: null,
      buttonIconName: null,
      cancelText: null,
      secondaryButtonClassName: null,
   },
   filtersInsideModal: {},
}

export default initialState
