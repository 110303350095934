const initialState = {
   isFetching: true,
   resources: {},
   defaultAccesses: {
      video: {},
      photoset: {},
   },
   mediaDownloadButton: false,
   wrongDownloadModal: {
      isOpen: false,
      header: null,
      body: null,
   },
   countesState: {
      videos: null,
      photosets: null,
      streams: null,
      store: null,
      stories: null,
      orderRequests: null,
   },
};

export default initialState;
