const initialState = {
   isFetching: true,
   isFetchingCategory: true,
   isFetchingResources: true,
   isFetchingOwners: true,
   isNewFetching: false,
   isFilterFetching: false,
   isEmptyByFilter: false,
   isEmpty: false,
   store: {},
   statistic: {},
   activeStore: {},
   initialActiveStore: {},
   collections: [],
   src: '',
   activeCollection: {},
   defaultCollections: [],
   customCollections: [],
   categoryActionError: null,
   isInited: false,
   newItemErrorMessage: {},
   newStore: {},
   newImgFullSrc: '',
   updateError: {},
   owners: [],
   addNewModal: {
      type: null,
      isOpen: false,
      headerTitle: null,
   },

   shippingZoneModal: true,

   storeImg: {
      loading: false,
      warning: false,
      src: '',
      path: '',
   },
   openDeleteModal: {
      isOpen: false,
      item: null,
      loading: false,
   },
   newItemButtonDisabled: false,
   openStatusMod: false,
   categoriesList: [],

   newBundle: { },
   storeValidationNumbers: {},
   scrollPosition: 0,

   orderRequests: {
      data: {},
      loading: true,
      isEmptyByFilter: false,
      isEmpty: false,
      isFilterFetching: false,
      isfetchingNewData: false,
   },

   activeOrderRequest: {},
   activeOrderRequestPageLoading: true,

   orderRequestForms: {
      loading: true,
      isEmpty: false,
      isfetchingNewData: false,
      data: {},
   },
   newBundleErrors: {},
   imageLoader: false,

   activBundle: {},
   initalActiveBundelState: {},
   bundlesErrors: {},
   scrollPositonAndFilters: {
      scrollPosition: 0,
      filters: [],
      filtersObject: {},
   },
};

export default initialState;
