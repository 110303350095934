import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types';
import { useRef } from 'react';
import Icon from 'common/components/elements/icons';
import RadioLabel from 'common/components/elements/inputs/radio-label';
import './style.scss'

function Select({
   handleChange,
   data,
   showCustomIcon,
   iconName,
   textSize = 'base',
   hideText,
   withoutIcon,
   isWideActive,
   className,
   activeItem,
   insideModal,
   removeCheckIcon,
   disabled,
   withRadioButtons,
   isFixed,
   style,
   optionsClassName,
   removeOptionsOverflow,
}) {
   const [activeOption, setActiveOption] = useState({})
   const [openOptionModal, setOpenOptionModal] = useState(false)
   const modalRef = useRef()
   const btnRef = useRef()
   const closeOnClickOutside = (e) => {
      if(!modalRef.current?.contains(e.target) && !btnRef.current?.contains(e.target)) {
         setOpenOptionModal(false)
      }
   }
   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };
   }, []);

   useEffect(() => {
      if(activeItem){
         setActiveOption(activeItem)
      }
   }, [activeItem])

   return (
      <>
         <div className='relative'>
            <div
               ref={ btnRef }
               className={ `flex gap-2 items-center h-full ${ className } cursor-pointer
               ${ disabled ? 'opacity-50' : '' }
            select-none  ${ isWideActive ? 'justify-between border bg-panel dark:bg-panel-dark dark:border-divider-dark border-divider rounded-lg px-3.5 py-2 shadow-input' : 'py-4' }` }
               role='presentation'
               onClick={ (e) => {
                  if(!disabled){
                     setOpenOptionModal(!openOptionModal)
                  }
               }  }
            >
               <div className={ `flex ${ !withoutIcon ? 'gap-2' : '' }` }>
                  {!withoutIcon && (
                     <Icon size={ 'xl' } name={ showCustomIcon ? iconName : activeOption.icon } />
                  )}
                  <span className={ `text-${ textSize } ${ hideText ? 'hidden' : '' } tracking-wide font-normal` }>
                     {activeOption.display_name}
                  </span>
               </div>
               <Icon
                  name={ `${ openOptionModal ? 'arrow-up' : 'arrow-down' }` } size='xl' className={ '!font-medium' } />
            </div>
            {openOptionModal && (
               <div
                  style={  { ...(style || {}) } }
                  ref={ modalRef }
                  className={ ` bg-panel dark:bg-panel-dark ${ isFixed ? 'fixed' : 'absolute' }
                ${ isWideActive ? `w-full ${ removeOptionsOverflow  ? '' : 'overflow-y-auto' } max-h-sx
                 dropdown-list-scroll ${ insideModal ? 'mt-2' : '' } rounded-lg` : 'overflow-hidden rounded-b-lg' }
               flex flex-col dropdown-list  bg-panel dark:bg-panel-dark shadow dark:shadow-dark z-50 py-3 ${ optionsClassName }` }>
                  {data && data.map((item, index) => {
                     return (
                        <>
                           {withRadioButtons ? (
                              <RadioLabel
                                 key={ item.id || index }
                                 id={ item.id || index }
                                 label={ item.display_name }
                                 checked={ item.display_name === activeOption.display_name }
                                 onChange={ () => {
                                    handleChange(item)
                                    setActiveOption(item)
                                    setOpenOptionModal(false)
                                 } }
                              />
                           ) : (
                              <div
                                 role='presentation'
                                 onClick={ (e) => {
                                    handleChange(item)
                                    setActiveOption(item)
                                    setOpenOptionModal(false)
                                 } }
                                 className={ `w-full duration-200 flex gap-4
                     items-center cursor-pointer ease-in
                     transition-colors hover:bg-hover
                      ${ item.id === activeOption.id ? 'bg-action justify-between' : '' }
                      ${ isWideActive ? 'gap-0 px-3.5 py-2.5' : 'gap-4 px-3 py-2' }
                       ` }
                                 key={ item.id || index }>
                                 <div className={ `flex gap-4 items-center` }>
                                    {!withoutIcon && (
                                       <Icon size={ 'xl' } name={ showCustomIcon ? iconName : item.icon } />
                                    )}
                                    <span>{item.display_name}</span>
                                 </div>
                                 {item.id === activeOption.id && !removeCheckIcon &&  (
                                    <Icon name='done' primaryColor='#231FFF' size={ 'xl' } />
                                 )}
                              </div>
                           )}
                        </>
                     )
                  })}
               </div>
            )}
         </div>
      </>
   )
}

Select.propTypes = {
   data: PropTypes.array,
   handleChange: PropTypes.func,
   showCustomIcon: PropTypes.bool,
   iconName: PropTypes.string,
   textSize: PropTypes.string,
   hideText: PropTypes.bool,
   withoutIcon: PropTypes.bool,
   isWideActive: PropTypes.bool,
   insideModal: PropTypes.bool,
   removeCheckIcon: PropTypes.bool,
   disabled: PropTypes.bool,
   withRadioButtons: PropTypes.bool,
   isFixed: PropTypes.bool,
   style: PropTypes.object,
   className: PropTypes.string,
   activeItem: PropTypes.object,
   optionsClassName: PropTypes.string,
   removeOptionsOverflow: PropTypes.bool,
}


export default Select
