import * as actions from "./actions";
import {
   getVault,
   deleteVaultFile,
} from 'admin/api/AuthApi'
import toast from "common/utils/toast";
import { uploadingVideo } from 'admin/utils/VaultVideoUpload';

// delete vault
export function deleteVault(id, type, callback) {
   return async dispatch => {
      // dispatch(actions.deleteRequest());
      await deleteVaultFile(id)
         .then(res => {
            toast.remove(`${ type } has been deleted`);
            dispatch(actions.deleteCompleted(id, type));
         })
         .catch((error) => {
            if(!!callback) {
               if(error.response.status === 409) {
                  callback(error.response.data)
               }
            } else {
               dispatch(actions.deleteFailed(error));
            }
         })
   }
}

// Images operation
export function fetchVaultImagesOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchImagesRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchImagesRequestCompleted(data))
      } catch (error) {
         dispatch(actions.fetchImagesRequestFailed(error))
      }
   }
}

export function getFilterImagesByOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchImagesByFilterRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchImagesByFilterCompleted(data))
      } catch (error) {
         if(error.code !== 'ERR_CANCELED') {
            dispatch(actions.fetchImagesByFilterFailed(error))
         }
      }
   }
}

export function getNewVaultImagesOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchNewImagesRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchNewImagesRequestCompleted(data))
      } catch (error) {
         dispatch(actions.fetchNewImagesRequestFailed(error))
      }
   }
}


// Videos operation
export function fetchVaultVideosOperation(params, isInsideModal = false) {
   return async dispatch => {
      dispatch(actions.fetchVideosRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchVideosRequestCompleted(data, isInsideModal))
      } catch (error) {
         dispatch(actions.fetchVideosRequestFailed(error))
      }
   }
}

export function getFilterVideosByOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchVideosByFilterRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchVideosByFilterCompleted(data))
      } catch (error) {
         if(error.code !== 'ERR_CANCELED') {
            dispatch(actions.fetchVideosByFilterFailed(error))
         }
      }
   }
}

export function getNewVaultVideosOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchNewVideosRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchNewVideosRequestCompleted(data))
      } catch (error) {
         dispatch(actions.fetchNewVideosRequestFailed(error))
      }
   }
}

export const startUploadOperation = (files) => {
   return (dispatch, getState) => {
      uploadingVideo(files, (id, data) => {
         if(null === id) {
            dispatch(actions.addVideos(data));
         } else {
            dispatch(actions.updateVideo(id, data));
         }
      }, _ => getState().vault.vaultVideosData.data, () => dispatch(startUploadOperation()))
   }
}

export const cancelUploadOperation = (id, status) => {
   return async dispatch => {
      if(status !== 'uploading' && status !== 'pending'){
         await deleteVaultFile(id)
            .then(res => {
               dispatch(actions.removeFile(id));
            })
      } else {
         dispatch(actions.removeFile(id))
      }
   }
}

// All vault operation

export function fetchAllVaultOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchAllVaultRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchAllVaultRequestCompleted(data, true))
      } catch (error) {
         dispatch(actions.fetchAllVaultRequestFailed(error))
      }
   }
}

export function getFilterAllVaultByOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchAllVaultByFilterRequest());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchAllVaultByFilterCompleted(data))
      } catch (error) {
         dispatch(actions.fetchAllVaultByFilterFailed(error))
      }
   }
}

export function getNewVaultsOperation(params) {
   return async dispatch => {
      dispatch(actions.fetchNewVaultsStart());
      try {
         const { data }  = await getVault(params);
         dispatch(actions.fetchNewVaultsCompleted(data))
      } catch (error) {
         dispatch(actions.fetchNewVaultsFail())
      }
   }
}
