import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import cx from 'classnames'
import {
   TransformWrapper,
   TransformComponent,
   useControls,
   // useTransformContext,
} from "react-zoom-pan-pinch";
import Button from 'common/components/elements/buttons/primary';
import { createPortal } from 'react-dom';

const Controls = ({
   isTherePlayPauseButton,
   resetToDefault,
   onClickRotate,
}) => {
   const { zoomIn, zoomOut, resetTransform } = useControls()

   useEffect(() => {
      resetTransform()
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [resetToDefault])

   return (
      <div
         className={ cx({
            'transform-component-controls-panel': true,
            '!translate-x-[-34%]': isTherePlayPauseButton,
            '!translate-x-[-45%]': isTherePlayPauseButton && window?.innerWidth < 430,
         }) }
      >
         <Button
            iconName='plus'
            backgroundColor='transparent'
            fontIconSize='doubleLarge'
            padding='0 !p-1.5'
            iconPosition='left'
            onClick={ (e) => {
               e.stopPropagation()
               zoomIn()
            } }
            classNames='transform-component-controls-buttons'
            dataToolTipContent='Zoom in'
         />
         <Button
            iconName='minus'
            backgroundColor='transparent'
            fontIconSize='doubleLarge'
            padding='0 !p-1.5'
            iconPosition='left'
            onClick={ (e) => {
               e.stopPropagation()
               zoomOut()
            } }
            classNames='transform-component-controls-buttons'
            dataToolTipContent='Zoom out'
         />
         <Button
            iconName='rotate-left'
            backgroundColor='transparent'
            fontIconSize='doubleLarge'
            padding='0 !p-1.5'
            iconPosition='left'
            onClick={ (e) => {
               e.stopPropagation()
               onClickRotate(-90)
            } }
            classNames='transform-component-controls-buttons'
            dataToolTipContent='Rotate left'
         />
         <Button
            iconName='rotate-right'
            backgroundColor='transparent'
            fontIconSize='doubleLarge'
            padding='0 !p-1.5'
            iconPosition='left'
            onClick={ (e) => {
               e.stopPropagation()
               onClickRotate(90)
            } }
            classNames='transform-component-controls-buttons'
            dataToolTipContent='Rotate right'
         />
         <Button
            iconName='revert'
            backgroundColor='transparent'
            fontIconSize='doubleLarge'
            padding='0 !p-1.5'
            iconPosition='left'
            onClick={ (e) => {
               e.stopPropagation()
               resetTransform()
               onClickRotate(0)
            } }
            classNames='transform-component-controls-buttons'
            dataToolTipContent='Revert'
         />
      </div>
   );
};

function ImageZoomPanPinch({
   children,
   isTherePlayPauseButton,
   isFullScreen,
   isShowControlButtons,
}) {
   const [rotation, setRotation] = useState(0);
   const [width, setWidth] = useState('')

   useEffect(() => {
      let element = document.getElementsByClassName('image-gallery-slides')

      if(!!element?.length){
         let height = element?.[1]?.getBoundingClientRect()?.height
         if(!!height){
            setWidth(![0, -180, 180].includes(rotation) ? height + 'px' : '100%')
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [rotation])

   const onClickRotate = (value) => {
      setRotation(prev =>  !value || ['360', '-360'].includes(String(prev + value)) ? 0 : prev + value)
   }

   return (
      <>
         <TransformWrapper
            initialScale={ 1 }
            initialPositionX={ 0 }
            initialPositionY={ 0 }
            doubleClick={ {
               disabled: true,
            } }
         >
            {
               ({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <>
                     {
                        isFullScreen ?
                           (
                              isShowControlButtons ?
                                 document.getElementById('photosets-fullscreen') && createPortal(
                                    <Controls
                                       isTherePlayPauseButton={ isTherePlayPauseButton }
                                       resetToDefault={ isFullScreen }
                                       onClickRotate={ onClickRotate }
                                    />
                                    ,
                                    document.getElementById('photosets-fullscreen')
                                 )
                                 : null
                           )
                           :
                           (
                              <Controls
                                 isTherePlayPauseButton={ isTherePlayPauseButton }
                                 resetToDefault={ isFullScreen }
                                 onClickRotate={ onClickRotate }
                              />
                           )
                     }
                     <TransformComponent
                        wrapperClass={ `h-full w-full` }
                        wrapperStyle={ {
                           transform: `rotate(${ rotation }deg)`,
                           display: 'inline-block',
                           objectFit: 'contain',
                           minWidth: width || '100%',
                           // minHeight: isFullScreen && !!width && width !== '100%' ? '100vh' : '100%',
                        } }
                        contentClass=''
                     >
                        {children}
                     </TransformComponent>
                  </>
               )
            }
         </TransformWrapper>
      </>
   )
}

ImageZoomPanPinch.propTypes = {
   children: PropTypes.any,
   isTherePlayPauseButton: PropTypes.bool,
   isFullScreen: PropTypes.bool,
   isShowControlButtons: PropTypes.bool,
}

ImageZoomPanPinch.defaultProps = {
   isTherePlayPauseButton: false,
   isShowControlButtons: true,
}

Controls.propTypes = {
   isTherePlayPauseButton: PropTypes.bool,
   resetToDefault: PropTypes.bool,
   onClickRotate: PropTypes.func,
}

export default ImageZoomPanPinch

