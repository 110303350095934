
import { createSelector } from 'reselect'

const innerStateSelector = state => state.outgoingNotifications;

const isFetchingSelector = createSelector(
   innerStateSelector,
   state => state.isFetching
);

const settingsSelector = createSelector(
   innerStateSelector,
   state => state.settings
);
const updateErrorSelector = createSelector(
   innerStateSelector,
   state => state.updateError
);
const isFetchingUpdateSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingUpdate
);
const settingBySlugSelector = createSelector(
   innerStateSelector,
   state => state.settingBySlug
);
const isFetchingBySlugSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingBySlug
);
const customNotificationModalSelector = createSelector(
   innerStateSelector,
   state => state.customNotificationModal
);

export {
   isFetchingSelector,
   settingsSelector,
   updateErrorSelector,
   isFetchingUpdateSelector,
   settingBySlugSelector,
   isFetchingBySlugSelector,
   customNotificationModalSelector,
}
