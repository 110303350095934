import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from 'common/components/elements/buttons/primary';
import ChatUserAvatar from 'common/components/modules/chat/chat-user-avatar';
import ModalDropDown from  'common/components/modules/chat/chat-modal-drop-down';
import intercomMoreInfo, { CONTACT_SUPPORT } from 'admin/utils/IntercomMoreInfo';
import { MemberOptions } from 'admin/views/members/options-modal';
import cx from 'classnames';
import TruncateText from 'common/components/elements/truncate-text';

import './style.scss';
import Router from 'admin/routes/router';

const AvatarWithName = ({
   avatarSrc,
   name,
   leftOptionsIcon,
   rightGoBackIcon,
   goToBack,
   onlineMemberCount,
   onClickAvatar,
   isMobile,
   isHelpIcon,
   isOnline,
   muteTime,
   onSetMemberOption,
   isBlocked,
   giveFreeMembership,
   openEditUsernameModal,
   openCustomTagModal,
   setMemberOptionModal,
   goToPrivateChat,
   membershipInfo,
   isDisabledNotifications,
   goToProfile,
   memberStatus,
   isMainChat,
   member,
   onOpenEditNickname,
   memberData,
}) => {
   // eslint-disable-next-line no-unused-vars
   const [openMembersOption, setOpenMemebersOptions] = useState(null)
   // eslint-disable-next-line no-unused-vars
   const [optionAnimate, setOptionAnimate] = useState(null)
   let timeout = null
   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         if(timeout){
            clearTimeout(timeout)
         }

         document.removeEventListener("mousedown", handleClickOutside);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   function handleClickOutside(event) {
      if(isMobile) {
         return
      }
      const ele = document.querySelector("#chat-member-options");
      if(ele && !ele.contains(event.target)) {
         setOpenMemebersOptions(false);
      }
   }
   function onActionClick(action){
      setMemberOptionModal(action)
      setOpenMemebersOptions(false);
   }

   function onOpenMemebersOptions(bool){
      let time = 0
      if(isMobile){
         time = bool ? 0 : 800
         setOptionAnimate(bool ? 'up' : 'down')
      }
      timeout = setTimeout(() => {
         setOpenMemebersOptions(bool)
      }, time)
   }


   const closeModalWithTimeout = () => {
      timeout = setTimeout(() => {
         setOpenMemebersOptions(false)
      }, 0)
   }


   return (
      <div
         className={ cx({
            'flex items-center h-14 p-2 chat-header-content bg-panel z-30': true,
         }) }
      >
         <div className='flex h-full items-center flex-1'>
            {
               !rightGoBackIcon  && !!avatarSrc && (
                  <div
                     className={ `w-10 h-10 ${ !!member && !Array.isArray(avatarSrc) && `**hover:cursor-pointer` }` }
                     role='presentation'
                     onClick={ () => {
                        // !!member && !Array.isArray(avatarSrc) && window.open(Router.route('MEMBERS_SHOW').getCompiledPath({ id: member?.id }), '_blank')
                     } }
                  >
                     <ChatUserAvatar
                        avatarSrc={ avatarSrc }
                        onClickAvatar={ onClickAvatar }
                        username={ name }
                     />
                  </div>
               )
            }
            {
               rightGoBackIcon && (
                  <div className='flex justify-center items-center h-10 w-10 my-2 min-w-40' >
                     <Button
                        iconName='arrow-left'
                        fontIconColor='major'
                        backgroundColor='transparent'
                        fontIconSize='doubleLarge'
                        padding={ 0 }
                        onClick={ goToBack }
                     />
                  </div>
               )
            }
            <div
               role='presentation'
               onClick={ isMobile && onClickAvatar ? () => onClickAvatar() : null }
               className='ml-2'
               style={ { maxWidth: '323px' } }
            >
               <TruncateText
                  className='ml-1'
                  text={ name }
                  textClass={ `text-major text-xl font-bold whitespace-pre ${ Array.isArray(avatarSrc) ? `cursor-default` : `hover:cursor-pointer` }` }
                  textSize='20px'
                  fontWeight='700'
                  width='100%'
                  onClick={ () => {
                     !!onClickAvatar && onClickAvatar()
                  } }
                  // isLink={ !!member && !Array.isArray(avatarSrc) }
                  // href={ Router.route('MEMBERS_SHOW').getCompiledPath({ id: member?.id }) }
                  // target='_blank'
               />
               {
                  onlineMemberCount && (
                     <span className='text-secondary'>&nbsp;• {onlineMemberCount} online </span>
                  )
               }

            </div>
            {
               isOnline && (
                  <span className='w-2 h-2 rounded-full bg-success block ml-2 mt-1' />
               )
            }
         </div>
         {
            !isMainChat && (leftOptionsIcon && 'string' === typeof avatarSrc) &&
            <div
               id='chat-member-options'
               className='w-10 h-10 relative min-w-40' >
               <Button
                  iconName='options-vertical'
                  fontIconColor='major'
                  backgroundColor='transparent'
                  fontIconSize='doubleLarge'
                  padding={ 0 }
                  onClick={ () => {
                     onOpenMemebersOptions(!openMembersOption) } }
                  classNames='h-full w-full intercom-member-options'
               />
               {
                  openMembersOption && (
                     <ModalDropDown
                        isDropDown={ !isMobile }
                        isMobile={ isMobile }
                        contentPosition='bottom'
                        bottom={ 0 }
                        height={ 124 }
                        width={ 390 }
                        right={ 90 }
                        top={ 30 }
                        left={ -367 }
                        onCloseModal={ () => onOpenMemebersOptions(false) }
                        animate={ optionAnimate }
                     >
                        <MemberOptions
                           insideChat
                           isMuted={ !!muteTime }
                           isBlocked={ !!isBlocked }
                           isMobile={ isMobile }
                           giveFreeMembership={ giveFreeMembership }
                           editUsername={ openEditUsernameModal }
                           openCustomTagModal={ openCustomTagModal }
                           setMemberOptionModal={ onActionClick }
                           goToPrivateChat={ goToPrivateChat }
                           goToProfile={ goToProfile }
                           memberStatus={ memberStatus || membershipInfo?.status }
                           isDisabledNotifications={ isDisabledNotifications }
                           closeModalWithTimeout={ closeModalWithTimeout }
                           goToDetails={ () => window.open(Router.route('MEMBERS_SHOW').getCompiledPath({ id: member?.id }), '_blank') }
                           onOpenEditNickname={ onOpenEditNickname }
                           memberData={ memberData }
                        />
                     </ModalDropDown>
                  )
               }
            </div>
         }
         {
            isHelpIcon && (
               <div
                  className='w-10 h-10 min-w-40' >
                  <Button
                     classNames='h-full w-full'
                     backgroundColor='transparent'
                     padding='none'
                     iconName='help'
                     fontIconColor='major'
                     fontIconSize='doubleLarge'
                     onClick={ () => intercomMoreInfo(CONTACT_SUPPORT) }
                  />
               </div>
            )
         }
      </div>
   )
}

AvatarWithName.defaultProps = {
   liveList: false,
   leftArrowIcon: false,
   leftOptionsIcon: false,
   rightGoBackIcon: false,
   isHelpIcon: false,
   noAvatar: false,
   chatCount: 0,
   onClick: () => {},
   goToBack: () => {},
   onSetMemberOption: () => {},
};

AvatarWithName.propTypes = {
   avatarSrc: PropTypes.any,
   name: PropTypes.string,
   leftOptionsIcon: PropTypes.bool,
   rightGoBackIcon: PropTypes.bool,
   goToBack: PropTypes.func,
   onClickAvatar: PropTypes.func,
   onlineMemberCount: PropTypes.any,
   isMobile: PropTypes.bool,
   isHelpIcon: PropTypes.bool,
   isOnline: PropTypes.bool,
   muteTime: PropTypes.any,
   onSetMemberOption: PropTypes.func,
   isBlocked: PropTypes.bool,
   giveFreeMembership: PropTypes.func,
   openEditUsernameModal: PropTypes.func,
   openCustomTagModal: PropTypes.func,
   setMemberOptionModal: PropTypes.func,
   goToPrivateChat: PropTypes.func,
   membershipInfo: PropTypes.object,
   isDisabledNotifications: PropTypes.bool,
   goToProfile: PropTypes.func,
   memberStatus: PropTypes.string,
   isMainChat: PropTypes.bool,
   member: PropTypes.object,
   onOpenEditNickname: PropTypes.func,
   memberData: PropTypes.object,
}

export default AvatarWithName


