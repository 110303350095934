import React, { memo, createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import './style.scss';

const { Provider, Consumer } = createContext({
   activeTabId: '',
   changeTab: () => {},
});

const Tab = ({
   id,
   children,
   classNames,
   name,
   tabNameClass,
   activeFontWeight,
   width,
   link,
   linkClassName,
   // activeBorderColor,
}) => (
   <Consumer>
      { ({ changeTab, activeTabId }) => {
         const view = (
            <>
               {
                  name && (
                     <span
                        className={ cx({
                           [`media-tab-name text-sm leading-tight ${ tabNameClass }`]: true,
                           'text-secondary dark:text-secondary-dark': activeTabId !== id,
                           [`${ activeFontWeight } text-action dark:text-action-dark`]: activeTabId === id,

                        }) }
                     >
                        { name }

                     </span>

                  )
               }
               {
                  children && children
               }
               <div
                  className={ cx({
                     'bottom-0 tab-item my-0 mx-auto absolute': true,
                     'bg-action dark:bg-action-dark h-[2px] w-full': activeTabId === id,
                     'bg-transparent': activeTabId !== id,
                  }) }
               />
            </>
         )
         return (
            <div
               role='presentation'
               onClick={ (e) => changeTab(id, e) }
               className={ cx({
                  [`flex relative justify-center select-none items-center cursor-pointer ${ classNames }`]: true,
                  'flex-1': !width,
                  [`${ width }`]: width,
                  'active': activeTabId === id,
               }) }
            >
               {
                  link && (
                     <Link
                        onClick={ e => e.preventDefault() }
                        to={ link }
                        target='_blank'
                        className={ linkClassName }
                     >
                        {view}
                     </Link>
                  )
               }
               {
                  !link && (
                     <>{view}</>
                  )
               }
            </div>
         )
      } }
   </Consumer>
)

const TabPanel = ({ whenActive, children }) => (
   <Consumer>
      { ({ activeTabId }) => (activeTabId === whenActive ? children : null) }
   </Consumer>
);

const TabSwitcher = ({
   initialActiveTab,
   children,
   parentOnChangeCallback,
   stopClickEvent,
   isStopClickEvent,
}) => {
   const [activeTab, setActiveTab] = useState(initialActiveTab);
   useEffect(() => {
      setActiveTab(initialActiveTab);
   }, [initialActiveTab])

   const onChangeActiveTab = (_activeTab, e) => {
      if(isStopClickEvent && stopClickEvent){
         stopClickEvent(_activeTab);
         return;
      }
      setActiveTab(_activeTab);
      if(parentOnChangeCallback) {
         parentOnChangeCallback(_activeTab, e);
      }
   };

   return (
      <Provider
         value={ {
            activeTabId: activeTab,
            changeTab: onChangeActiveTab,
         } }
      >
         { children }
      </Provider>
   );
};

Tab.propTypes = {
   id: PropTypes.string,
   children: PropTypes.any,
   classNames: PropTypes.string,
   name: PropTypes.string,
   tabNameClass: PropTypes.string,
   activeFontWeight: PropTypes.string,
   width: PropTypes.string,
   link: PropTypes.string,
   linkClassName: PropTypes.string,
   // activeBorderColor: PropTypes.string,
};
Tab.defaultProps = {
   tabNameClass: '',
   linkClassName: '',
   activeFontWeight: 'font-normal',
};
TabPanel.propTypes = {
   whenActive: PropTypes.string,
   children: PropTypes.any,
};
TabSwitcher.defaultProps = {
   initialActiveTab: 'a',
};
TabSwitcher.propTypes = {
   initialActiveTab: PropTypes.string,
   children: PropTypes.any,
   stopClickEvent: PropTypes.func,
   parentOnChangeCallback: PropTypes.func,
   isStopClickEvent: PropTypes.bool,
};

export default memo(TabSwitcher);
export {
   Tab,
   TabPanel,
};
