export const FETCH_DETAILS_START = "models/FETCH_DETAILS_START";
export const FETCH_DETAILS_COMPLETED = "models/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "models/FETCH_DETAILS_FAILED";

export const FILTER_MODELS_REQUEST = "models/FILTER_MODELS_REQUEST";
export const FILTER_MODELS_COMPLETED = "models/FILTER_MODELS_COMPLETED";
export const FILTER_MODELS_FAILED = "models/FILTER_MODELS_FAILED";

export const NEW_FETCH_REQUEST = "models/NEW_FETCH_DETAILS";
export const NEW_FETCH_DETAILS_COMPLETED = "models/NEW_FETCH_DETAILS_COMPLETED";
export const NEW_FETCH_DETAILS_FAILED = "models/NEW_FETCH_DETAILS_FAILED";
