// fetch stories data type
export const FETCH_REQUEST = "stories/FETCH_REQUEST";
export const FETCH_DETAILS_COMPLETED = "stories/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "stories/FETCH_DETAILS_FAILED";

//fetch stories next page data type
export const NEW_FETCH_REQUEST = "stories/NEW_FETCH_REQUEST";
export const NEW_FETCH_DETAILS_COMPLETED = "stories/NEW_FETCH_DETAILS_COMPLETED";
export const NEW_FETCH_DETAILS_FAILED = "stories/NEW_FETCH_DETAILS_FAILED";

//fetch filter data type
export const FILTER_STORIES_REQUEST = 'stories/FILTER_STORIES_REQUEST';
export const FILTER_STORIES_COMPLETED = 'stories/FILTER_STORIES_COMPLETED';
export const FILTER_STORIES_FAILED = "stories/FILTER_STORIES_FAILED";

// stories statistics data type
export const FETCH_STATISTICS_REQUEST = "stories/FETCH_STATISTICS_REQUEST";
export const FETCH_STATISTICS_COMPLETED = "stories/FETCH_STATISTICS_COMPLETED";
export const FETCH_STATISTICS_FAILED = "stories/FETCH_STATISTICS_FAILED";

// share store

export const FETCH_UPLOAD_IMG_COMPLETED = 'stories/FETCH_UPLOAD_IMG_COMPLETED';
export const SHANRE_NEW_POST_REQUEST = "stories/SHANRE_NEW_POST_REQUEST";
export const SHANRE_NEW_POST_COMPLETED = "stories/SHANRE_NEW_POST_COMPLETED";
export const SHANRE_NEW_POST_FAILED = "stories/SHANRE_NEW_POST_FAILED";
export const UPDATE_POST_REQUEST = "stories/UPDATE_POST_REQUEST";
export const UPDATE_POST_COMPLETED = "stories/UPDATE_POST_COMPLETED";
export const UPDATE_POST_FAILED = "stories/UPDATE_POST_FAILED";
export const UPDATE_VIDEO_COMPLETED = "stories/UPDATE_VIDEO_COMPLETED";

// delete story
export const DELETE_STORY_REQUEST = "stories/DELETE_STORY_REQUEST";
export const DELETE_STORY_COMPLETED = "stories/DELETE_STORY_COMPLETED";
export const DELETE_STORY_FAILED = "stories/DELETE_STORY_FAILED";

// get STORY
export const GET_BY_ID_REQUEST = "stories/GET_BY_ID_REQUEST";
export const GET_BY_ID_COMPLETED = "stories/GET_BY_ID_COMPLETED";
export const GET_BY_ID_FAILED = "stories/GET_BY_ID_FAILED";

//delete img
export const DELETE_IMG_REQUEST = "stories/DELETE_IMG_REQUEST";
export const DELETE_IMG_COMPLETED = "stories/DELETE_IMG_COMPLETED";
export const DELETE_IMG_FAILED = "stories/DELETE_IMG_FAILED";

// upload poter image
export const UPLOAD_POSTER_START = "stories/UPLOAD_POSTER_START";
export const UPLOAD_POSTER_COMPLETED = "stories/UPLOAD_POSTER_COMPLETED";
export const UPLOAD_POSTER_FAILED = "stories/UPLOAD_POSTER_FAILED";

/////////////////////////////////////////////////////////////////////////
export const FETCH_EDITED_STORY_REQUEST = 'stories/FETCH_EDITED_STORIES_REQUEST';
export const FETCH_EDITED_STORY_COMPLETED = 'stories/FETCH_EDITED_STORIES_COMPLETED';
export const SET_EDITED_STORY_INPUT = 'stories/SET_EDITED_STORIES_INPUT';
export const FETCH_EDITED_STORY_FAILED = 'stories/FETCH_EDITED_STORY_FAILED';

export const PIN_STORY_COMPLETED = 'stories/PIN_STORY_COMPLETED';


//reorder

export const REORDER_STORIES = 'stories/REORDER_STORIES'