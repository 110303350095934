function getPhrasesFromMessage(message) {
   if(!message || 'string' !== typeof message){
      return [];
   }
   message = message.replace(/^\s+|\s+$/g, '');
   const final_array = [];
   let other_chars = '';
   for(const char of message) {
      const type = getCharType(char);
      switch (type) {
         case 'other': {
            other_chars += char;
            break;
         }
         default: {
            if(other_chars !== '') {
               final_array.push({
                  type: 'other',
                  value: other_chars,
               });
               other_chars = '';
            }
            final_array.push({
               type,
               value: char,
            });
         }
      }
   }
   if(other_chars !== '') {
      final_array.push({
         type: 'other',
         value: other_chars,
      });
   }
   return final_array;
}

function getCharType(char) {
   let type = 'other';
   if(char === ' ') {
      type = 'space';
   } else if(char === `\n`) {
      type = 'new_line';
   } else if(/([\ud800-\udbff])([\udc00-\udfff])/g.test(char)) {
      type = 'emoji';
   }
   return type;
}

export { getPhrasesFromMessage, getCharType };
