import * as types from "./types";

const fetchRequest = (key) => ({
   type: types.FETCH_REQUEST,
   payload: key,
});

const fetchRequestCompleted = (data, emptyFilters, key) => ({
   type: types.FETCH_DETAILS_COMPLETED,
   payload: {
      videosData: data,
      emptyFilters,
      key,
   },
});

const fetchRequestFailed = error => ({
   type: types.FETCH_DETAILS_FAILED,
   payload: {
      error,
   },
});
const fetchByFilterRequest = (key) => ({
   type: types.FETCH_BY_FILTER_REQUEST,
   payload: { key },
});

const fetchByFilterRequestCompleted = (data, key) => ({
   type: types.FETCH_BY_FILTER_DETAILS_COMPLETED,
   payload: {
      videosData: data,
      key,
   },
});

const fetchByFilterRequestFailed = error => ({
   type: types.FETCH_BY_FILTER_DETAILS_FAILED,
   payload: {
      error,
   },
});
const nextPageRequest = (key) => ({
   type: types.NEXT_PAGE_REQUEST,
   payload: { key },
});

const nextPageRequestCompleted = (data, key) => ({
   type: types.NEXT_PAGE_COMPLETED,
   payload: {
      videosData: data,
      key,
   },
});

const nextPageRequestFailed = error => ({
   type: types.NEXT_PAGE_FAILED,
   payload: {
      error,
   },
});

const fetchNotCompletedRequest = () => ({
   type: types.FETCH_NOT_COMPLETED_REQUEST,
});

const fetchNotCompletedCompleted = data => ({
   type: types.FETCH_NOT_COMPLETED_COMPLETED,
   payload: {
      uncompletedList: data,
   },
});

const fetchVideoByIdRequest = () => ({
   type: types.FETCH_VIDEO_BY_ID_REQUEST,
});

const fetchVideoByIdCompleted = data => ({
   type: types.FETCH_VIDEO_BY_ID_COMPLETED,
   payload: data,
});

const fetchVideoByIdFailed = (error) => ({
   type: types.FETCH_VIDEO_BY_ID_FAILED,
   payload: error,
});
const updateVideoRequest = bool => ({
   type: types.UPDATE_VIDEO_REQUEST,
   payload: bool,
});

const updateVideoCompleted = data => ({
   type: types.UPDATE_VIDEO_COMPLETED,
   payload: data,
});

const updateVideoFailed = (error) => ({
   type: types.UPDATE_VIDEO_FAILED,
   payload: error,
});
const deleteVideoRequest = () => ({
   type: types.DELETE_VIDEO_REQUEST,
});

const deleteVideoCompleted = (id, emptyFilterParams) => ({
   type: types.DELETE_VIDEO_COMPLETED,
   payload: { id, emptyFilterParams },
});

const deleteVideoFailed = (error) => ({
   type: types.DELETE_VIDEO_FAILED,
   payload: error,
});

const setButtonDisabled = data => ({
   type: types.SET_BUTTON_DISABLED,
   payload: data,
});
const clearErrors = data => ({
   type: types.CLEAR_ERROR_REQUEST,
   payload: data,

});
const setStatusModal = bool => ({
   type: types.SET_STATUS_MODAL,
   payload: bool,
});
const setEditTitelMode = bool => ({
   type: types.SET_EDIT_TITLE_MODAL,
   payload: bool,
});
const clearState = payload => ({
   type: types.CLEAR_STATE,
   payload,
});
const setFilterData = data => ({
   type: types.SET_FILTERS,
   payload: data,
});
const destroyVideosRequest = bool => ({
   type: types.DISTORY_VIDEOS_REQUEST,
   payload: bool,
});
const destroyVideosCompleted = ids => ({
   type: types.DISTORY_VIDEOS_COMPLETED,
   payload: ids,
});
const bulkVideosRequest = bool => ({
   type: types.BULK_VIDEOS_REQUEST,
   payload: bool,
});
const bulkVideosCompleted = ids => ({
   type: types.BULK_VIDEOS_COMPLETED,
   payload: ids,
});
const multipleVideosUpdateAction = bool => ({
   type: types.MULTIPLE_UPDATE,
   payload: bool,
});
const setBulkIds = data => ({
   type: types.SET_BULK_IDS,
   payload: data,
});
const updatePublishData = (data) => ({
   type: types.UPDATE_PUBLISH_DATA,
   payload: data,
})

const updateOrAddPreviewVideo = (data) => {
   return {
      type: types.UPDATE_OR_ADD_PREVIEW_VIDEO,
      payload: data,
   };
}
const clearBulkErrorAction = (data) => {
   return {
      type: types.CLEAR_BULK_ERROR,
      payload: data,
   };
}
const setReplaceStateAction = (id, data, updateVideo = null) => {
   return {
      type: types.SET_REPLACE_STATE_ACTION,
      payload: { id, data, updateVideo },
   };
}
const updateVideoOnVideosList = (id, data) => {
   return {
      type: types.UPDATE_VIDEO_ON_VIDEOS_LIST,
      payload: { id, data },
   };
}

//reorder
const reorderVideos = (data) => {
   return {
      type: types.REORDER_VIDEOS,
      payload: data,
   }
}
const changeInitedState = () => {
   return {
      type: types.CHANGE_INITED_STATE,
   }
}
const pinVideoCompleted = (data) => {
   return {
      type: types.PIN_VIDEO_COMPLETED,
      payload: data,
   }
}

const updateBundleStateInData = (data, isDetailsPage) => {
   return ({
      type: types.UPDATE_BUNDLE_STATE_IN_DATA,
      payload: { data, isDetailsPage },
   })
}
const setWarningModal = (data) => {
   return ({
      type: types.SET_WARNING_MODAL,
      payload: data,
   })
}
const updateMessageAction = (data) => {
   return ({
      type: types.UPDATE_MESSAGE_ACTION,
      payload: data,
   })
}

const updateVideosListAction = (payload) => ({
   type: types.UPDATE_VIDEOS_LIST,
   payload,
})

export {
   fetchRequest,
   fetchRequestCompleted,
   fetchRequestFailed,

   fetchByFilterRequest,
   fetchByFilterRequestCompleted,
   fetchByFilterRequestFailed,
   nextPageRequest,
   nextPageRequestCompleted,
   nextPageRequestFailed,

   fetchNotCompletedRequest,
   fetchNotCompletedCompleted,

   fetchVideoByIdRequest,
   fetchVideoByIdCompleted,
   fetchVideoByIdFailed,
   updateVideoRequest,
   updateVideoCompleted,
   updateVideoFailed,
   deleteVideoRequest,
   deleteVideoCompleted,
   deleteVideoFailed,
   setButtonDisabled,
   clearErrors,
   setStatusModal,
   clearState,
   setFilterData,
   destroyVideosRequest,
   destroyVideosCompleted,
   bulkVideosRequest,
   bulkVideosCompleted,
   multipleVideosUpdateAction,
   setBulkIds,
   updateOrAddPreviewVideo,
   updatePublishData,
   clearBulkErrorAction,
   setEditTitelMode,
   setReplaceStateAction,
   updateVideoOnVideosList,

   reorderVideos,
   changeInitedState,
   updateBundleStateInData,

   pinVideoCompleted,
   setWarningModal,
   updateMessageAction,
   updateVideosListAction,
};
