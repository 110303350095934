import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_DETAILS]: (state, action) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetching: false,
         data: payload,
         isEmpty: payload.length === 0,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.DELETE_REDIRECT_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const newData = [...state.data].filter(element => element.id !== payload)
      return {
         ...state,
         data: newData,
         isEmpty: newData.length === 0,
      }
   },

   [types.CREATE_NEW_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const newData = [payload, ...state.data]
      return {
         ...state,
         data: newData,
         isEmpty: newData.length === 0,
      }
   },

   [types.CREATE_NEW_FAILED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         errors: payload.data,
         modal: {
            ...state.modal,
            buttonDisabled: false,
         },
      }
   },

   [types.UPDATE_DATA_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const  newData = [...state.data].map(i => {
         let element = { ...i }
         if(element.id === payload.id) {
            element = { ...element, ...payload }
            return element
         }
         return element
      })
      return {
         ...state,
         data: newData,
      }
   },

   [types.UPDATE_DATA_FAILED]: (state, action) => {
      const  { payload } = action;

      return {
         ...state,
         errors: payload.data,
         modal: {
            ...state.modal,
            buttonDisabled: false,
         },
      }
   },


   [types.SET_REDIRECT_MODAL]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         modal: {
            ...state.modal,
            ...payload,
         },
         errors: {},
      }
   },
};

export default createReducer(initialState)(reducersMap);
