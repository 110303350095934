import React from 'react';
import PropTypes from 'prop-types';
import { getCountState, upperCaseFirstLetter } from 'common/utils/utils'


const CountState = ({
   state,
   type,
   className,
   hideTooltip,
}) => {

   const getCount = (key) => {
      const count = state?.[key] || 0;
      if(['story', 'stories'].includes(type)){
         let name = count === 1 ? ` story` : 'stories';
         return `${ count } ${ name }`
      }
      if(hideTooltip) {
         return `${ count } ${ type }`
      }
      return getCountState(count, type)
   }
   const getTooltipText = (key) => {
      let text = ''
      for(const key in state) {
         if(Object.hasOwnProperty.call(state, key) && key !== 'total') {
            let name = key === 'waiting' ? 'Pending payment' : upperCaseFirstLetter(key)
            const res = `${ name } • ${ getCount(key) } </br>`;
            text = `${ text } ${ res } `;
         }
      }
      return text;
   }

   return (
      <div
         data-tooltip-offset={ 11 }
         data-tooltip-id='ams-top-tooltip'
         data-tooltip-html={ hideTooltip ? null : getTooltipText() }
         className={ `flex items-center justify-center ${ className } ${ !hideTooltip ? 'cursor-pointer' : '' }  h-5 my-auto` }>
         <span className='text-secondary text-sm leading-[20px] select-none'>{getCount('total')}</span>
      </div>
   )
}

CountState.propTypes = {
   state: PropTypes.object,
   type: PropTypes.string,
   className: PropTypes.string,
   hideTooltip: PropTypes.bool,
}
CountState.defaultProps = {
   className: 'ml-6',
   hideTooltip: false,
}

export default CountState
