import * as types from "./types";
import createReducer from "../../utils/createReducer";
import initialState from "./initial-state";

let reducersMap = {
   // memberships for filter
   [types.FETCH_MEMBERSHIPS_START]: (state) => {
      return {
         ...state,
         isFetchingMemberships: true,
      }
   },
   [types.FETCH_MEMBERSHIPS_COMPLETED]: (state, action) => {
      return {
         ...state,
         memberships: action.payload,
         isFetchingMemberships: false,
      }
   },
   [types.FETCH_MEMBERSHIPS_FAILED]: (state) => {
      return {
         ...state,
         isFetchingMemberships: false,
      }
   },

   // REVENUE
   [types.INIT_REVENUE_START]: (state, action) => {
      const { active_tab, isFetchByFilter } = action.payload
      return {
         ...state,
         revenue: {
            ...state.revenue,
            [active_tab]: {
               ...state.revenue[active_tab],
               isFetchingInit: !isFetchByFilter,
               isFetchByFilter: isFetchByFilter,
            },
         },
      }
   },
   [types.INIT_REVENUE_COMPLETED]: (state, action) => {
      const { active_tab, result } = action.payload
      return {
         ...state,
         revenue: {
            ...state.revenue,
            [active_tab]: {
               ...state.revenue[active_tab],
               ...result,
               isFetchingInit: false,
               isFetchByFilter: false,
            },
         },
      }
   },
   // chart by filter
   [types.INIT_REVENUE_CHART_BY_FILTER_START]: (state, action) => {
      const active_tab = action.payload
      return {
         ...state,
         revenue: {
            ...state.revenue,
            [active_tab]: {
               ...state.revenue[active_tab],
               isFetchByFilter: true,
            },
         },
      }
   },
   [types.INIT_REVENUE_CHART_BY_FILTER_COMPLETED]: (state, action) => {
      const { active_tab, chartData } = action.payload
      return {
         ...state,
         revenue: {
            ...state.revenue,
            [active_tab]: {
               ...state.revenue[active_tab],
               isFetchByFilter: false,
               chartData: chartData,
            },
         },
      }
   },
   [types.INIT_REVENUE_CHART_BY_FILTER_FAILED]: (state, action) => {
      const active_tab = action.payload
      return {
         ...state,
         revenue: {
            ...state.revenue,
            [active_tab]: {
               ...state.revenue[active_tab],
               isFetchByFilter: false,
            },
         },
      }
   },

   // TOP SPENDING MEMBERS
   [types.INIT_TOP_SPENDING_MEMBERS_START]: (state, action) => {
      const isNextpage = action.payload
      return {
         ...state,
         isFetchingTopSpendingMembers: !isNextpage,
         isFetchingTopSpendersNextPage: isNextpage,
      }
   },
   [types.INIT_TOP_SPENDING_MEMBERS_COMPLETED]: (state, action) => {
      const { data, last_page, isNextpage } = action.payload
      let newData
      if(isNextpage) {
         newData = [...state.topSpendingMembers, ...data]
      } else {
         newData = data
      }
      return {
         ...state,
         isFetchingTopSpendingMembers: false,
         topSpendingMembers: newData,
         last_page,
         isFetchingTopSpendersNextPage: false,
      }
   },
   [types.INIT_TOP_SPENDING_MEMBERS_FAILED]: (state, action) => {
      const { isNextpage, isCancelled } = action.payload
      return {
         ...state,
         isFetchingTopSpendingMembers: false,
         isFetchingTopSpendersNextPage: isNextpage && isCancelled,
      }
   },
   [types.UPDATE_TOP_SPENDING_MEMBER_USERNAME]: (state, action) => {
      const { id, username } = action.payload
      const newData = state.topSpendingMembers.map(user => {
         if(user?.id === id) {
            user.user_name = username
         }
         return user
      })
      return {
         ...state,
         topSpendingMembers: newData,
      }
   },
   [types.UPDATE_TOP_SPENDING_MEMBER]: (state, action) => {
      const data = action.payload
      const { id } = data
      return {
         ...state,
         topSpendingMembers: state.topSpendingMembers.map(member => {
            let newMember
            if(member.id === id) {
               newMember = {
                  ...member,
                  ...data,
               }
            }
            return Boolean(newMember) ? newMember : member
         }),
      }
   },
   // ENGAGEMENT
   [types.INIT_ENGAGEMENT_START]: (state, action) => {
      const active_tab = action.payload

      return {
         ...state,
         engagement: {
            ...state.engagement,
            [active_tab]: {
               ...state.engagement[active_tab],
               isFetchingInit: true,
            },
         },
      }
   },
   [types.INIT_ENGAGEMENT_COMPLETED]: (state, action) => {
      const { active_tab, result } = action.payload;

      return {
         ...state,
         engagement: {
            ...state.engagement,
            [active_tab]: {
               ...state.engagement[active_tab],
               isFetchingInit: false,
               chartData: result.chartData,
            },
         },
      }
   },
   [types.INIT_ENGAGEMENT_CHART_BY_FILTER_START]: (state, action) => {
      const active_tab = action.payload

      return {
         ...state,
         engagement: {
            ...state.engagement,
            [active_tab]: {
               ...state.engagement[active_tab],
               isFetchByFilter: true,
            },
         },
      }
   },
   [types.INIT_ENGAGEMENT_CHART_BY_FILTER_COMPLETED]: (state, action) => {
      const { active_tab, chartData } = action.payload

      return {
         ...state,
         engagement: {
            ...state.engagement,
            [active_tab]: {
               ...state.engagement[active_tab],
               isFetchByFilter: false,
               chartData: chartData,
            },
         },
      }
   },
   [types.INIT_ENGAGEMENT_CHART_BY_FILTER_FAILED]: (state, action) => {
      const active_tab = action.payload

      return {
         ...state,
         engagement: {
            ...state.engagement,
            [active_tab]: {
               ...state.engagement[active_tab],
               isFetchByFilter: false,
            },
         },
      }
   },

   // CONTENT LIST
   [types.INIT_CONTENT_LIST_START]: (state, action) => {
      const { active_tab, isNextPage } = action.payload

      return {
         ...state,
         contentList: {
            ...state.contentList,
            [active_tab]: {
               ...state.contentList[active_tab],
               isFetching: !isNextPage,
               isFetchingNextPage: isNextPage,
            },
         },
      }
   },
   [types.INIT_CONTENT_LIST_COMPLETED]: (state, action) => {
      const { active_tab, result, isNextPage } = action.payload

      let newData = null
      if(Boolean(result)) {
         if(isNextPage) {
            newData = {
               ...state.contentList[active_tab],
               data: [...state.contentList[active_tab].data, ...result.data],
               last_page: result.last_page,
               isFetching: false,
               isFetchingNextPage: false,
            }
         } else {
            newData = { 
               ...state.contentList[active_tab],
               ...result,
               isFetching: false,
               isFetchingNextPage: false,
            }
         }
      }

      return {
         ...state,
         contentList: {
            ...state.contentList,
            [active_tab]: newData,
         },      
      }
   },
   [types.INIT_CONTENT_LIST_FAILED]: (state, action) => {
      const { active_tab, isNextPage, isCancelled } = action.payload

      return {
         ...state,
         contentList: {
            ...state.contentList,
            [active_tab]: {
               ...state.contentList[active_tab],
               // isFetching: false,
               isFetchingNextPage: isNextPage && isCancelled,
            },
         },
      }
   },
   // ACQUISITION
   [types.INIT_ACQUISITION_START]: (state, action) => {
      const { active_tab, isFetchByFilter } = action.payload

      return {
         ...state,
         acquisition: {
            ...state.acquisition,
            [active_tab]: {
               ...state.acquisition[active_tab],
               isFetchingInit: !isFetchByFilter,
               isFetchByFilter: isFetchByFilter,
            },
         },
      }
   },
   [types.INIT_ACQUISITION_COMPLETED]: (state, action) => {
      const { active_tab, result } = action.payload
      return {
         ...state,
         acquisition: {
            ...state.acquisition,
            [active_tab]: {
               isFetchingInit: false,
               isFetchByFilter: false,
               ...result,
            },
         },
      }
   },
}


export default createReducer(initialState)(reducersMap);
