// start chat settings
export const FETCH_CHAT_SETTINGS_REQUEST = 'chat/FETCH_CHAT_SETTINGS_REQUEST';
export const FETCH_CHAT_SETTINGS_COMPLETED = 'chat/FETCH_CHAT_SETTINGS_COMPLETED';
export const FETCH_CHAT_SETTINGS_FAILED = 'chat/FETCH_CHAT_SETTINGS_FAILED';
export const UPDATE_CHAT_SETTINGS_REQUEST = 'chat/UPDATE_CHAT_SETTINGS_REQUEST';
export const UPDATE_CHAT_SETTINGS_COMPLETED = 'chat/UPDATE_CHAT_SETTINGS_COMPLETED';
export const UPDATE_CHAT_SETTINGS_FAILED = 'chat/UPDATE_CHAT_SETTINGS_FAILED';
export const CLEAR_CHAT_SETTINGS_ERROR = 'chat/CLEAR_CHAT_SETTINGS_ERROR';
// end chat settings

// start chat members
export const FETCH_CHAT_MEMBERS_REQUEST = 'chat/FETCH_CHAT_MEMBERS_REQUEST';
export const FETCH_CHAT_MEMBERS_COMPLETED = 'chat/FETCH_CHAT_MEMBERS_COMPLETED';
export const FETCH_CHAT_MEMBERS_FAILED = 'chat/FETCH_CHAT_MEMBERS_FAILED';
export const CHAT_MEMBERS_FILTER_REQUEST = 'chat/CHAT_MEMBERS_FILTER_REQUEST';
export const CHAT_MEMBERS_FILTER_COMPLETED = 'chat/CHAT_MEMBERS_FILTER_COMPLETED';
export const CHAT_MEMBERS_FILTER_FAILED = 'chat/CHAT_MEMBERS_FILTER_FAILED';
// end chat members

//start chat conversations
export const FETCH_CHAT_CONVERSATIONS_REQUEST = 'chat/FETCH_CHAT_CONVERSATIONS_REQUEST';
export const FETCH_CHAT_CONVERSATIONS_COMPLETED = 'chat/FETCH_CHAT_CONVERSATIONS_COMPLETED';
export const FETCH_CHAT_CONVERSATIONS_FAILED = 'chat/FETCH_CHAT_CONVERSATIONS_FAILED';
export const ADD_REMOVE_CONVERSATION_ITEM_ACTION = 'chat/ADD_REMOVE_CONVERSATION_ITEM_ACTION';

//end chat conversations

//start chat conversation messages
export const FETCH_CHAT_CONVERSATION_MESSAGES_REQUEST = 'chat/FETCH_CHAT_CONVERSATION_MESSAGES_REQUEST';
export const FETCH_CHAT_CONVERSATION_MESSAGES_COMPLETED = 'chat/FETCH_CHAT_CONVERSATION_MESSAGES_COMPLETED';
export const FETCH_CHAT_CONVERSATION_MESSAGES_FAILED = 'chat/FETCH_CHAT_CONVERSATION_MESSAGES_FAILED';
export const FETCH_CHAT_NEW_CONVERSATION_REQUEST = 'chat/FETCH_CHAT_NEW_CONVERSATION_REQUEST';
export const FETCH_CHAT_NEW_CONVERSATION_COMPLETED = 'chat/FETCH_CHAT_NEW_CONVERSATION_COMPLETED';
export const FETCH_CHAT_NEW_CONVERSATION_FAILED = 'chat/FETCH_CHAT_NEW_CONVERSATION_FAILED';
export const FETCH_NEW_MESSAGES_REQUEST = 'chat/FETCH_NEW_MESSAGES_REQUEST';
export const FETCH_NEW_MESSAGES_COMPLETED = 'chat/FETCH_NEW_MESSAGES_COMPLETED';
export const FETCH_NEW_MESSAGES_FAILED = 'chat/FETCH_NEW_MESSAGES_FAILED';
//end chat conversation messages


export const FETCH_LIKED_MEMBER_BY_MESSSAGE_REQUEST = 'chat/FETCH_LIKED_MEMBER_BY_MESSSAGE_REQUEST';
export const FETCH_LIKED_MEMBER_BY_MESSSAGE_COMPLETED = 'chat/FETCH_LIKED_MEMBER_BY_MESSSAGE_COMPLETED';
export const FETCH_LIKED_MEMBER_BY_MESSSAGE_FAILED = 'chat/FETCH_LIKED_MEMBER_BY_MESSSAGE_FAILED';
///////////////////////////////////////////////////////////////
export const SET_CHAT_CONVERSTION = 'chat/SET_CHAT_CONVERSTION';


export const IS_MOBILE_RIGHT_CONTENT = 'chat/IS_MOBILE_RIGHT_CONTENT';
export const SET_SHOW_DESCTOP_DIALOGUE = 'chat/SET_SHOW_DESCTOP_DIALOGUE';
export const START_DESCTOP_DIALOGUE_REQUEST = 'chat/START_DESCTOP_DIALOGUE_REQUEST';
export const START_DESCTOP_DIALOGUE_COMPLETED = 'chat/START_DESCTOP_DIALOGUE_COMPLETED';
export const SET_OPEN_DESCTOP_DIALOGUE = 'chat/SET_OPEN_DESCTOP_DIALOGUE';


export const SET_ONLINE_USERS_ACTIONS = 'chat/SET_ONLINE_USERS_ACTIONS';
export const SET_SOCKET_CONNECTED_ACTION = 'chat/SET_SOCKET_CONNECTED_ACTION';
export const CREATE_CONVERSATION = 'chat/CREATE_CONVERSATION';
export const ON_LIKE_MESSAGE = 'chat/ON_LIKE_MESSAGE';
export const ON_DELETE_MESSAGE = 'chat/ON_DELETE_MESSAGE';
export const ON_UPDATE_MESSAGE = 'chat/ON_UPDATE_MESSAGE';
export const ON_ADD_MESSAGE = 'chat/ON_ADD_MESSAGE';
export const UPDATE_CONVERSATION_DATA = 'chat/UPDATE_CONVERSATION_DATA';
export const SET_TYPER_ACTION = 'chat/SET_TYPER_ACTION';
export const SET_MUTE_MEMBER = 'chat/SET_MUTE_MEMBER';
export const FETCH_MEMBER_BY_UUID = 'chat/FETCH_MEMBER_BY_UUID';
export const SET_SOKET = 'chat/SET_SOKET';
export const CLEAR_CHAT_INFO = 'chat/CLEAR_CHAT_INFO';
export const SET_BLOCK_MEMBER = 'chat/SET_BLOCK_MEMBER';
export const CANCEL_NEW_CONVERSATION = 'chat/CANCEL_NEW_CONVERSATION';
export const UPDATE_UNREAD_PM_MESSAGE_COUNT = 'chat/UPDATE_UNREAD_PM_MESSAGE_COUNT';
export const UPDATE_ONLINE_USER_USERNAME = 'chat/UPDATE_ONLINE_USER_USERNAME';
export const SET_OPEN_DK_CHAT_NOTIFICATIONS = 'chat/SET_OPEN_DK_CHAT_NOTIFICATIONS';
export const UPDATE_CHAT_CONVERSATION_LIST = 'chat/UPDATE_CHAT_CONVERSATION_LIST';

export const FETCHING_CONVERSATION_START = 'chat/FETCHING_CONVERSATION_START';
export const FETCHING_CONVERSATION_COMPLETED = 'chat/FETCHING_CONVERSATION_COMPLETED';
export const FETCHING_CONVERSATION_FAILED = 'chat/FETCHING_CONVERSATION_FAILED';

export const UPDATE_CONVERSATION_FROM_LIST = 'chat/UPDATE_CONVERSATION_FROM_LIST';
export const TOGGLE_PIN_UNPIN_CONVERSATION_COMPLETED = 'chat/TOGGLE_PIN_UNPIN_CONVERSATION_COMPLETED'

export const UPDATE_CONVERSATIONS_FILTER = 'chat/UPDATE_CONVERSATIONS_FILTER';
export const UPDATE_CONVERSATION_MESSAGES_BY_ID = 'chat/UPDATE_CONVERSATION_MESSAGES_BY_ID'

export const CHAT_INIT_COMPLETED = 'chat/CHAT_INIT_COMPLETED'
