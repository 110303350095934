const initialState = {
   isFetching: true,
   data: [],
   errors: {},
   isEmpty: false,
   isCreateFetching: false,
   optionsModal: {
      type: null,
      isOpen: false,
      buttonDisabled: false,
   },
   customPageById: {},
   isFetchById: true,
   isFetchingUpdate: false,
   isNewFetching: false,
   openStatusMod: false,
};

export default initialState;
