// import { history } from 'admin/state/store';
// import queryString from 'query-string';

export const MORE_INFO_ON_DOMAIN_NAMES = 'more-info-on-domain-names';
export const MORE_INFO_ON_PAYOUTS = 'more-info-on-payouts';
export const CHANGE_SITE_OR_DOMAIN_NAMES = 'change-site-or-domain-name';
export const CHANGE_EMAIL_ADDRESS = 'change-email-address';
export const ERROR_ON_VIDEO_UPLOAD = 'error-on-video-upload';
export const MORE_INFO_ON_SITE_APPROVAL = 'more-info-on-site-approval';
export const MORE_INFO_ON_PAYOUT_METHODS = 'more-info-on-payout-methods';
export const MORE_INFO_ON_MEMBERSHIPS = 'more-info-on-memberships';
export const MORE_INFO_ON_MEMBERSHIPS_PRICING = 'more-info-on-memberships-pricing';
export const MORE_INFO_ON_MEMBERS = 'more-info-on-members'
export const MORE_INFO_ON_MEMBER_TAGS = 'more-info-on-member-tags'
export const MORE_INFO_ON_FREE_MEMBERS = 'more-info-on-free-members'
export const MORE_INFO_ON_CAST_MEMBERS = 'more-info-on-cast-members'
export const MORE_INFO_ON_TAGS = 'more-info-on-tags';
export const INCREASE_VIDEO_LIMIT = 'increase-video-limit'
export const MORE_INFO_ON_COLLECTIONS = 'more-info-on-collections';
export const MORE_INFO_ON_VIDEOS = 'more-info-on-videos';
export const MORE_INFO_ON_HOMEPAGE_FEATURES = 'more-info-on-homepage-features';
export const MORE_INFO_ON_VIDEO_PREVIEW = 'more-info-on-video-preview';
export const MORE_INFO_ON_POSTER_IMAGE = 'more-info-on-poster-image';
export const MORE_INFO_ON_SCREEN_NAME = 'more-info-on-screen-name';
export const I_WANT_TO_CHANGE_MY_EMAIL_ADDRESS = 'i-want-to-change-my-email-address';
export const MORE_INFO_ON_LIVE_CHAT = 'more-info-on-live-chat';
export const MORE_INFO_ON_PHOTOSETS = 'more-info-on-photosets';
export const MORE_INFO_ON_UPLOADS_PAGE = 'more-info-on-uploads-page';
export const MORE_INFO_ON_STORIES = 'more-info-on-stories';
export const MORE_INFO_ON_THUMBNAILS = 'more-info-on-thumbnails';
export const MORE_INFO_ON_REFERRAL_LINK = 'more-info-on-referral-link';
export const MORE_INFO_ON_MASS_PRIVATE_MESSAGES = 'more-info-on-mass-private-messages';
export const MORE_INFO_ON_MAILING_LIST = 'more-info-on-mailing-list';
export const MORE_INFO_ON_MUTING = 'more-info-on-muting';
export const MORE_INFO_ON_THIS_ERROR = 'more-info-on-this-error';
export const MORE_INFO_ON_TRANSACTIONS = 'more-info-on-transactions';
export const MORE_INFO_ON_WALLET_ACTIVITY = 'more-info-on-wallet-activity';
export const MORE_INFO_ON_WALLET = 'more-info-on-wallet';
export const MORE_INFO_ON_TRANSACTION_STATUSES = 'more-info-on-transaction-statuses';
export const MORE_INFO_ON_FREE_MEMBERSHIPS = 'more-info-on-complimentary-memberships'
export const I_WANT_TO_CLOSE_MY_SITE = 'i-want-to-close-my-site'
export const I_WANT_TO_SET_A_COMING_SOON_PAGE = 'i-want-to-set-a-coming-soon-page'
export const MORE_INFO_ON_COMING_SOON_PAGE = 'more-info-on-coming-soon-page'
export const MORE_INFO_ON_REFERRAL = 'more-info-on-referral';
export const CLIENT_REQUEST_CONTACT_SUPPORT_ABOUT_MEMBER = 'client-request-contact-support-about-member';
export const MORE_INFO_ON_CHAT = 'more-info-on-chat';
export const MORE_INFO_ON_STORE = 'more-info-on-store';
export const MORE_INFO_ON_BLOCKING = 'more-info-on-blocking';
export const SUPPORT_CHAT = 'support-chat';
export const CONTACT_SUPPORT = 'contact-support';
export const MORE_INFO_ON_REFERRALS = 'more-info-on-referrals';
export const MORE_INFO_ON_LOGOS_AND_ICONS = 'more-info-on-logos-and-icons';
export const MORE_INFO_ON_MY_APP = 'more-info-on-my-app';
export const MORE_INFO_ON_DOMAIN_REDIRECTS = 'more-info-on-domain-redirects';
export const MORE_INFO_ON_IP_BLACKLIST = 'more-info-on-ip-blacklist';
export const MORE_INFO_ON_IP_WHITELIST = 'more-info-on-ip-whitelist';
export const MORE_INFO_ON_HEADER = 'more-info-on-header';
export const MORE_INFO_ON_HOMEPAGE = 'more-info-on-homepage';
export const MORE_INFO_ON_GOOGLE_ANALYTICS = 'more-info-on-google-analytics';
export const MORE_INFO_ON_CUSTOM_PAGE = 'more-info-on-custom-pages';
export const MORE_INFO_ON_VAULT = 'more-info-on-vault';
export const MORE_INFO_ON_LOCKED_STATE = 'more-info-on-locked-state';
export const MORE_INFO_ON_HIDE_SCHEDULED_CONTENT = 'more-info-on-hide-scheduled-content';
export const MORE_INFO_ON_ADVANCED_SETTINGS = 'more-info-on-advanced-settings';
export const MORE_INFO_ON_MEMBERS_ACCESS = 'more-info-on-members-access';
export const MORE_INFO_ON_OTHERS_ACCESS = 'more-info-on-others-access';
export const MORE_INFO_ON_CANCELLED_PAY_MORE_TO_UNLOCK = 'more-info-on-cancelled-pay-more-to-unlock';
export const MORE_INFO_ON_CONTENT_PROTECTION = 'more-info-on-content-protection';
export const MORE_INFO_ON_NOTIFICATIONS = 'more-info-on-notifications';
export const REQUEST_LIVESTREAM_ACCESS = 'request-livestream-access';
export const REQUEST_TRACKING_CODE = 'request-tracking-code';
export const MORE_INFO_ON_DISABLING_NOTIFICATIONS = 'more-info-on-disabling-notifications';

// const intercomMoreInfo = (paramValue) => {
//    const paramName = 'trigger';
//    const query = queryString.parse(history.location.search);
//    query[paramName] = paramValue;
//    const queryParam = queryString.stringify(query);
//    const currentUrl = history.location.pathname;
//    history.replace({
//       pathname: currentUrl,
//       search: `?${ queryParam }`,
//    })
//    if(window.Intercom) {
//       setTimeout(() => {
//          window.Intercom('showNewMessage');
//          setTimeout(() => {
//             window.Intercom('update');
//          }, 100);
//       }, 100);
//    }
// }
const intercomMoreInfo = (paramValue) => {

}
export default intercomMoreInfo;
