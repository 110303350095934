import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';

const reducersMap = {
   [types.SET_INPUT]: (state, action) => {
      const { payload: { key, value } } = action;
      return {
         ...state,
         inputs: {
            ...state.inputs,
            [key]: value,
         },
      };
   },
   [types.LOGIN_START]: (state) => {
      return {
         ...state,
         errors: {},
         isInProgress: true,
      };
   },
   [types.LOGIN_COMPLETED]: (state) => {
      return {
         ...state,
         isInProgress: false,
      };
   },
   [types.LOGIN_FAILED]: (state, action) => {
      const { payload: { errors } } = action;
      return {
         ...state,
         isInProgress: false,
         errors,
      };
   },
};

export default createReducer(initialState)(reducersMap);
