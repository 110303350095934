import React from 'react';
import Icon from 'common/components/elements/icons';
import PropTypes from 'prop-types';
import { getTopPercentage } from "common/utils/utils";
import './style.scss';
import TruncateText from 'common/components/elements/truncate-text';
import Flag from 'common/components/elements/flags';

export const MemberCardInfo = ({
   location,
   membershipType,
   totalSpent,
   topPercentage,
   country,
   classNames,
   size,
   flagSize,
}) => {
   return (
      <div className={ `${ classNames }` }>
         {
            (
               <div className='flex  items-center mb-1 leading-[18px]'>
                  <Flag className={ `py-[2px] pl-0 mr-1 mb-1 ${ flagSize }` } height={ size } width={ size } country={ country?.code ? country?.code : 'International'  } />
                  <span className='text-secondary dark:text-secondary-dark  text-xs whitespace-nowrap'>{!location ? 'Location is unavailable' : location}</span>
               </div>
            )
         }
         {
            !!membershipType && (
               <div className='flex gap-2 items-center mb-1 leading-[18px]'>
                  <Icon name='membership'
                     color='secondary'
                     darkColor='secondary-dark'
                     style={ { fontSize: size } } />
                  <TruncateText
                     text={ membershipType }
                     textClass='text-xs text-secondary dark:text-secondary-dark'
                     textSize='12px'
                     fontWeight='400'
                  />
               </div>
            )
         }
         <div className='flex gap-2 items-center leading-[18px]'>
            <Icon name='money-2'
               color='secondary'
               darkColor='secondary-dark'
               style={ { fontSize: size } }  />
            <span className='text-xs text-secondary dark:text-secondary-dark '>
               Total spent: { totalSpent } USD
               { 0 < totalSpent && !!getTopPercentage(topPercentage) && ` • Top ${ getTopPercentage(topPercentage) }%` }
            </span>
         </div>
      </div>
   );
};

MemberCardInfo.defaultProps = {
   size: '16px',
   flagSize: 'h-4 w-5',
}
MemberCardInfo.propTypes = {
   location: PropTypes.string,
   membershipType: PropTypes.string,
   topPercentage: PropTypes.number,
   totalSpent: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
   ]),
   country: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
   ]),
   classNames: PropTypes.string,
   flagSize: PropTypes.string,
   size: PropTypes.string,
};
