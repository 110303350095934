import React from 'react';
import PropTypes from 'prop-types';


const AlreadyRepliedContent = ({
   date,
   message,
   isChat,
}) => {
   return (
      <div className='pl-19'>
         <div className='border-l-2 dark:border-divider-dark border-divider box-border'>
            <span className='ml-2 text-xs leading-tight text-secondary dark:text-secondary-dark'>You replied to the { isChat ? 'message' : 'comment'}</span>
            <div className='bg-panel dark:bg-panel-dark p-2 flex flex-col w-full h-full'>
               <span className='text-secondary dark:text-secondary-dark text-xs leading-tight truncate-3'>{message} </span>
               {
                  !!date && (
                     <span className='mt-2 text-right block align-end text-placeholder dark:text-placeholder-dark text-xs leading-tight'>{ date } </span>
                  )
               }
            </div>
         </div>
      </div>
   );
};

AlreadyRepliedContent.propTypes = {
   date: PropTypes.string,
   message: PropTypes.string,
   isChat: PropTypes.bool,
};
AlreadyRepliedContent.defaultProps = {
};

export default React.memo(AlreadyRepliedContent);
