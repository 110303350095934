import socketIOClient from 'socket.io-client';
import Auth from 'admin/utils/Auth'
import { getProtocol, getSiteDomain } from 'common/utils/utils';

const getSocketBaseUrl = (siteName) => {
   let url;
   url = `${ getProtocol() }${ siteName }.${ getSiteDomain() }`;
   return url
}

const initChatSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/chat`
   if(!window.adminChatSocket) {
      window.adminChatSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         transports: ['websocket', 'polling'],
         forceNew: true,
         reconnectionDelay: 50,
         auth: (cb) => {
            cb({
               token: Auth.getToken(),
            });
         },
      });
   }
   if(autoConnect) {
      if(window.adminChatSocket.disconnected) {
         window.adminChatSocket.connect()
      }
   }
   return window.adminChatSocket;
}


const initNotificationsSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/notifications`
   if(!window.adminNotificationsSocket) {
      window.adminNotificationsSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         forceNew: true,
         transports: ['websocket', 'polling'],
         reconnectionDelay: 50,
         auth: (cb) => {
            cb({
               token: Auth.getToken(),
            });
         },
      });
   }
   if(autoConnect) {
      if(window.adminNotificationsSocket.disconnected) {
         window.adminNotificationsSocket.connect()
      }
   }
   return window.adminNotificationsSocket;
}
const initLiveStreamSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/live-stream`
   if(!window.adminLiveStreamSocket) {
      window.adminLiveStreamSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         forceNew: true,
         transports: ['websocket', 'polling'],
         reconnectionDelay: 50,
         auth: (cb) => {
            cb({
               token: Auth.getToken(),
            });
         },
      });
   }
   if(autoConnect) {
      if(window.adminLiveStreamSocket.disconnected) {
         window.adminLiveStreamSocket.connect()
      }
   }
   return window.adminLiveStreamSocket;
}
const initUpdateEventsSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/admin-updates-events`
   if(!window.adminUpdateEventsSocket) {
      window.adminUpdateEventsSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         forceNew: true,
         transports: ['websocket', 'polling'],
         reconnectionDelay: 50,
         auth: (cb) => {
            cb({
               token: Auth.getToken(),
            });
         },
      });
   }
   if(autoConnect) {
      if(window.adminUpdateEventsSocket.disconnected) {
         window.adminUpdateEventsSocket.connect()
      }
   }
   return window.adminUpdateEventsSocket;
}

const initMassMessageSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/mass-message`
   if(!window.adminMassMessageSocket) {
      window.adminMassMessageSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         forceNew: true,
         transports: ['websocket', 'polling'],
      });
   }
   if(autoConnect) {
      if(window.adminMassMessageSocket.disconnected) {
         window.adminMassMessageSocket.connect()
      }
   }
   return window.adminMassMessageSocket;
}

const initOrderRequestsSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/order-requests`
   if(!window.adminOrderRequestsSocket) {
      window.adminOrderRequestsSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         transports: ['websocket', 'polling'],
         forceNew: true,
         reconnectionDelay: 50,
         auth: (cb) => {
            cb({
               token: Auth.getToken(),
            });
         },
      });
   }
   if(autoConnect) {
      if(window.adminOrderRequestsSocket.disconnected) {
         window.adminOrderRequestsSocket.connect()
      }
   }
   return window.adminOrderRequestsSocket;
}

const initExtractZipFilesSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/extract-zip-files`
   if(!window.adminExtractZipFilesSocket) {
      window.adminExtractZipFilesSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         transports: ['websocket', 'polling'],
         forceNew: true,
         reconnectionDelay: 50,
         auth: (cb) => {
            cb({
               token: Auth.getToken(),
            });
         },
      });
   }
   if(autoConnect) {
      if(window.adminExtractZipFilesSocket.disconnected) {
         window.adminExtractZipFilesSocket.connect()
      }
   }
   return window.adminExtractZipFilesSocket;
}

export {
   initChatSocket,
   initNotificationsSocket,
   initLiveStreamSocket,
   initUpdateEventsSocket,
   initMassMessageSocket,
   initOrderRequestsSocket,
   initExtractZipFilesSocket,
}
