export const FETCH_DETAILS_START = "referrals/FETCH_DETAILS_START";
export const FETCH_DETAILS_COMPLETED = "referrals/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "referrals/FETCH_DETAILS_FAILED";
export const DELETE_REFERRALS_START = "referrals/DELETE_REFERRALS_START";
export const DELETE_REFERRALS_COMPLETED = "referrals/DELETE_REFERRALS_COMPLETED";
export const DELETE_REFERRALS_FAILED = "referrals/DELETE_REFERRALS_FAILED";
export const UPDATE_REFERRALS_START = "referrals/UPDATE_REFERRALS_START";
export const UPDATE_REFERRALS_COMPLETED = "referrals/UPDATE_REFERRALS_COMPLETED";
export const UPDATE_REFERRALS_FAILED = "referrals/UPDATE_REFERRALS_FAILED";
export const CREATE_NEW_REFERRALS_START = "referrals/CREATE_NEW_REFERRALS_START";
export const CREATE_NEW_REFERRALS_COMPLETED = "referrals/CREATE_NEW_REFERRALS_COMPLETED";
export const CREATE_NEW_REFERRALS_FAILED = "referrals/CREATE_NEW_REFERRALS_FAILED";
export const SET_MODAL_SETTINGS = "referrals/SET_MODAL_SETTINGS";
