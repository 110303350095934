import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   // FETCH TIPS /
   [types.FETCH_STORY_COMMENTS_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_STORY_COMMENTS_COMPLETED]: (state, action) => {
      const { payload: { data, countComments } } = action;
      return {
         ...state,
         isFetching: false,
         comments: data,
         isEmpty: data.data.length === 0,
         commentsCount: !!countComments ? countComments : data.total,
      }
   },
   [types.FETCH_STORY_COMMENTS_FAILED]: (state) => {
      return {
         ...state,
         isFetching: false,
      }
   },
   [types.FETCH_COMMENTS_BY_FILTER_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingByFilter: true,
      }
   },
   [types.FETCH_COMMENTS_BY_FILTER_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         isAnimationId: null,
         isFetchingByFilter: false,
         comments: payload,
      }
   },
   [types.FETCH_COMMENTS_BY_FILTER_FAILED]: (state) => {
      return {
         ...state,
         isFetchingByFilter: false,
      }
   },

   [types.FETCH_NEW_COMMENTS_REQUEST]: (state) => {
      return {
         ...state,
         isNewFetching: true,
      }
   },
   [types.FETCH_NEW_COMMENTS_COMPLETED]: (state, action) => {
      const { payload } = action
      const data = {
         ...payload,
         data: [...payload.data, ...state.comments.data],
      }
      return {
         ...state,
         isNewFetching: false,
         comments: data,
      }
   },
   [types.FETCH_NEW_COMMENTS_FAILED]: (state) => {
      return {
         ...state,
         isNewFetching: false,
      }
   },

   [types.COMMENT_LIKE_REQUEST]: (state) => {
      return {
         ...state,
         // isNewFetching: true,
      }
   },

   [types.COMMENT_LIKE_COMPLETED]: (state, action) => {
      const { payload } = action
      const id = payload.commentId
      const parentId = payload.parentId
      let data = state.comments.data
      let comments = { ...state.comments };
      if(data) {
         if(parentId) {
            data = state.comments.data.map((i) => {
               const { ...element }  = i
               if(element.id === parentId) {
                  element.childs = element.childs.map(item => {
                     const { ...elm } = item
                     if(item.id === id) {
                        elm.liked = !elm.liked;
                        elm.likes_count = !elm.liked ? elm.likes_count - 1 : elm.likes_count + 1;
                        return elm
                     }
                     return elm
                  })
                  return element
               }
               return element
            })
         } else {
            data = state.comments?.data?.map((i) => {
               const { ...element } = i
               if(element.id === id){
                  element.liked = !element.liked;
                  element.likes_count = !element.liked ? element.likes_count - 1 : element.likes_count + 1;
                  return element
               }
               return element
            })
         }
         comments = {
            ...state.comments,
            data: [...data],
         };
      }
      return {
         ...state,
         isNewFetching: false,
         comments: { ...comments },
      }
   },
   [types.COMMENT_LIKE_FAILED]: (state) => {
      return {
         ...state,
      }
   },

   [types.COMMENT_DELETE_REQUEST]: (state) => {
      return {
         ...state,
         // isNewFetching: true,
      }
   },

   [types.COMMENT_DELETE_COMPLETED]: (state, action) => {
      const { payload } = action
      const id = +payload.commentId
      const parentId = payload.parentId
      let data = state.comments.data;
      let count = +state.commentsCount;
      data.forEach(item => {
         if(item.id === id) {
            count -= item.childs.length + 1
         } else if(item.childs.length > 0) {
            item.childs.forEach(childItem => {
               if(childItem.id === id){
                  count -= 1;
               }
            });
         }
      });
      if(parentId){
         data = state.comments.data.map(i => {
            let { ...item } = i;
            if(item.id === parentId){
               item.childs = item.childs.filter(element => element.id !== +id)
               return item
            }
            return item
         })
      } else {
         data = state.comments.data.filter(i => i.id !== +id)
      }
      return {
         ...state,
         isNewFetching: false,
         comments: {
            ...state.comments,
            data: [...data],
         },
         isEmpty: data.length === 0,
         commentsCount: count,
      }
   },
   [types.COMMENT_DELETE_FAILED]: (state) => {
      return {
         ...state,
      }
   },

   [types.SEND_COMMENT_REQUEST]: (state) => {
      return {
         ...state,
         hideReply: false,
         // isNewFetching: true,
      }
   },

   [types.SEND_COMMENT_COMPLETED]: (state, action) => {
      const { payload } = action
      if(!!Object.keys(state.comments).length) {
         let data = state.comments?.data
         if(!payload.parent_id){
            data = data?.filter(item => item.id !== payload.id);
            data = [...data, payload]
         } else {
            data = state.comments?.data?.map(element => {
               let { ...i } = element
               if(i.id === payload.parent_id){
                  i.childs = i.childs.filter(item => item.id !== payload.id);
                  i.childs = [...i.childs, payload]
                  return i;
               }
               return i;
            })
         }
         return {
            ...state,
            isNewFetching: false,
            comments: {
               ...state.comments,
               data: [...data],
            },
            isEmpty: false,
            isAnimationId: payload.id,
            commentsCount: state.commentsCount + 1,
            hideReply: true,
         }
      } else {
         return {
            ...state,
         }
      }
   },
   [types.SEND_COMMENT_FAILED]: (state) => {
      return {
         ...state,
         hideReply: true,
      }
   },

   [types.COMMENT_UPDATE_COMPLETED]: (state, action) => {
      let data = state.comments.data
      let newComment = []
      const { payload } = action;
      const id = payload.comment_id;
      const parentId = payload.parent_id;
      const likesCount = payload.likes_count;
      if(parentId){
         newComment = data.map(i => {
            let element = { ...i }
            if(element.id === parentId) {
               let index = element.childs.findIndex(elem => elem.id === id);
               element.childs[index] = {
                  ...element.childs[index],
                  likes_count: likesCount,
               }
            }
            return element
         })
      } else {
         newComment = data.map(i => {
            let element = { ...i }
            if(element.id === id) {
               element = { ...element, likes_count: likesCount }
               return element
            }
            return element
         })
      }
      return {
         ...state,
         comments: {
            ...state.comments,
            data: newComment,
         },
      }
   },

   [types.COMMENT_MEMBER_UPDATE_COMPLETED]: (state, action) => {
      const { payload: { id, data } } = action;
      let commentsData = state.comments.data;
      let newCommentsData = [];
      newCommentsData = commentsData.map(comment => {
         if(comment.member_id === id) {
            comment.user = {
               ...comment.user,
               ...data,
            }
            if(comment.childs && comment.childs.length > 0) {
               let newChildsData = comment.childs;
               newChildsData = newChildsData.map(item => {
                  if(item.member_id === id) {
                     item = {
                        ...item,
                        user: {
                           ...item.user,
                           ...data,
                        },
                     }
                  }
                  return item;
               })
               comment.childs = [
                  ...newChildsData,
               ]
            }
         }
         return comment
      })

      return {
         ...state,
         comments: {
            ...state.comments,
            data: [
               ...newCommentsData,
            ],
         },
      }
   },
   [types.UPDATE_COMMENT_MEMBER_BY_UUID]: (state, action) => {
      const { payload: { uuid, data } } = action;
      let commentsData = state.comments.data;
      let newCommentsData = [];
      newCommentsData = commentsData.map(comment => {

         if(comment.user.uuid === uuid){
            comment.user = {
               ...comment.user,
               ...data,
            }
            if(comment.childs && comment.childs.length > 0) {
               let newChildsData = comment.childs;
               newChildsData = newChildsData.map(item => {
                  if(item.user.uuid === uuid) {
                     item = {
                        ...item,
                        user: {
                           ...item.user,
                           ...data,
                        },
                     }
                  }
                  return item;
               })
               comment.childs = [
                  ...newChildsData,
               ]
            }
         }
         return comment
      })

      return {
         ...state,
         comments: {
            ...state.comments,
            data: [
               ...newCommentsData,
            ],
         },
      }
   },
   [types.UPDATE_COMMENT_MEMBERS_FAILED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         updateErrors: payload,
      }
   },
};

export default createReducer(initialState)(reducersMap);
