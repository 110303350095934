const initialState = {
   isVideoUploadLimitReached: false,
   isIncreaseMyLimitClicked: false,
   videos: [
      // {
      //    id: 1,
      //    title: 'video-name-lorem-ipsum.m. sdskdhhhj sdbgisdgusidgufdddddddddddddddsd gusidgu.',
      //    posterImage: 'https://i.pinimg.com/736x/c8/bd/a9/c8bda99ff35a1aca879a77d8dfb84dd2.jpg',
      //    uploadingStatus: 'ready', // api call
      // },
      // {
      //    id: 2,
      //    title: 'video-name-lorem-ipsum.m. sdskdhhhj sdbgisdgusidgufdddddddddddddddsd gusidgu.',
      //    uploadingStatus: 'optimizing', // api call
      //    percentage: 7,
      // },
      // {
      //    id: 3,
      //    title: 'video-name-lorem-ipsum.m. sdskdhhhj sdbgisdgusidgufdddddddddddddddsd gusidgu.',
      //    uploadingStatus: 'uploading', // cancel evaporate
      //    percentage: 8,
      // },
      // // {
      // //    id: 4,
      // //    title: 'video-name-lorem-ipsum.m. sdskdhhhj sdbgisdgusidgufdddddddddddddddsd gusidgu.',
      // //    uploadingStatus: 'paused',
      // // },
      // {
      //    id: 5,
      //    title: 'video-name-lorem-ipsum.m. sdskdhhhj sdbgisdgusidgufdddddddddddddddsd gusidgu.',
      //    uploadingStatus: 'pending', // delete from queue
      // },
      // {
      //    id: 6,
      //    title: 'video-name-lorem-ipsum.m. sdskdhhhj sdbgisdgusidgufdddddddddddddddsd gusidgu.',
      //    uploadingStatus: 'in_optimization_queue', // api call
      // },
      // {
      //    id: 7,
      //    title: 'video-name-lorem-ipsum.m. sdskdhhhj sdbgisdgusidgufdddddddddddddddsd gusidgu.',
      //    uploadingStatus: 'error', // api call
      // },
   ],
   photosets: [],
   isDistoryFetching: false,
};

export default initialState;
