import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from 'react-router';
import { push } from 'connected-react-router';
import { connect } from "react-redux";
import Router from 'admin/routes/router';
import Auth from 'admin/utils/Auth';
import { currentLocationPathSelector } from 'admin/state/modules/router/selectors';

class Simple extends Component {
   static propTypes = {
      locationPath: PropTypes.string,
      goToHomePage: PropTypes.func,
   };

   componentDidMount() {
      if(!Auth.isTokenExists()) {
         const { locationPath } = this.props;
         let query = '';
         let pageName = '';
         if(!!Router.route('SIGN_IN').match(locationPath)) {
            pageName = '';
            query = window.location.search;
            Auth.redirectSignIn(query, null, pageName);
            return
         }
         if(!!Router.route('CONFIRM_SIGN_UP').match(locationPath)) {
            let computedMatch = Router.route('CONFIRM_SIGN_UP').match(locationPath);
            const { params } = computedMatch;
            const token = params && params.token ? params.token : ''
            pageName = 'verification-code';
            query = `?token=${ token }`;
            Auth.redirectSignIn(query, null, pageName);
            return
         }
         if(!!Router.route('SIGN_UP').match(locationPath)) {
            pageName = 'sign-up';
            query = window.location.search;
            Auth.redirectSignIn(query, null, pageName);
            return
         }
         if(!!Router.route('PASSWORD_RESET').match(locationPath)) {
            pageName = 'reset-password';
            query = window.location.search;
            Auth.redirectSignIn(query, null, pageName);
            return
         }
         if(!!Router.route('PASSWORD_RESET_TOKEN').match(locationPath)) {
            let computedMatch = Router.route('PASSWORD_RESET_TOKEN').match(locationPath);
            const { params } = computedMatch;
            const token = params && params.token ? params.token : ''
            pageName = `reset-password/${ token }`;
            Auth.redirectSignIn(query, null, pageName);
            return
         }
      } else {
         const { goToHomePage } = this.props
         goToHomePage();
      }
   }

   render() {
      if(!!Auth.isTokenExists()) {
         return (
            <Route
               render={ () => {
                  return <></>;
               } }
            />
         );
      }
      return null;
   }
}

const mapStateToProps = (state) => {
   return {
      locationPath: currentLocationPathSelector(state),
   };
}

const mapDispatchToProps = (dispatch) => ({
   goToHomePage: () => {
      dispatch(push(Router.route('HOMEPAGE').getCompiledPath()));
   },
});

export default connect(mapStateToProps, mapDispatchToProps)(Simple);
