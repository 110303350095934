import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';
import QueryParams from "admin/utils/QueryParams";

let reducersMap = {
   // FETCH TIPS /
   [types.FETCH_TRANSACTIONS_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
         selectFetching: false,
      }
   },

   [types.FETCH_TRANSACTIONS_COMPLETED]: (state, action) => {
      const filters = QueryParams.getFilters()
      return {
         ...state,
         isFetching: false,
         data: action.payload,
         isEmpty: Boolean(filters.promotion_id) ? false : action.payload.data.length === 0,
         isEmptyByFilter: Boolean(filters.promotion_id) ? action.payload.data.length === 0 : false,
      }
   },

   [types.FETCH_TRANSACTIONS_FAILED]: (state) => {
      return {
         ...state,
         isFetching: false,
      }
   },
   [types.FETCH_TRANSACTIONS_BY_FILTER_START]: (state) => {
      return {
         ...state,
         isFetchingByFilter: true,
         selectFetching: false,
      }
   },

   [types.FETCH_TRANSACTIONS_BY_FILTER_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingByFilter: false,
         data: action.payload,
         isEmptyByFilter: action.payload.data.length === 0,
      }
   },

   [types.FETCH_TRANSACTIONS_BY_FILTER_FAILED]: (state) => {
      return {
         ...state,
         isFetchingByFilter: false,
      }
   },

   [types.FETCH_DETAILS_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingDetails: true,
         selectFetching: false,
      }
   },

   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingDetails: false,
         details: action.payload,
      }
   },

   [types.FETCH_DETAILS_FAILED]: (state) => {
      return {
         ...state,
         isFetchingDetails: false,
      }
   },
   [types.FETCH_NEW_DATA_START]: (state) => {
      return {
         ...state,
         isFetchingNew: true,
      }
   },

   [types.FETCH_NEW_DATA_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const res = payload.data;
      return {
         ...state,
         isFetchingNew: false,
         data: {
            ...res,
            data: [
               ...state.data.data,
               ...res.data,
            ],
         },
      }
   },

   [types.FETCH_NEW_DATA_FAILED]: (state) => {
      return {
         ...state,
         isFetchingNew: false,
      }
   },
   [types.EXPORT_CSV_START]: (state) => {
      return {
         ...state,
         isFetchingExportCSV: true,
      }
   },

   [types.EXPORT_CSV_COMPLETED]: (state) => {
      return {
         ...state,
         isFetchingExportCSV: false,
      }
   },




   // wallet activity
   [types.FETCH_TRANSACTIONS_WALLET_ACTIVITY_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingWalletActivity: true,
      }
   },

   [types.FETCH_TRANSACTIONS_WALLET_ACTIVITY_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingWalletActivity: false,
         walletActivityData: action.payload,
         isEmptyWalletActivity: action.payload.data.length === 0,
      }
   },

   [types.FETCH_TRANSACTIONS_WALLET_ACTIVITY_FAILED]: (state) => {
      return {
         ...state,
         isFetchingWalletActivity: false,
      }
   },

   //wallet activity filter
   [types.FETCH_TRANSACTIONS_WALLET_ACTIVITY_BY_FILTER_START]: (state) => {
      return {
         ...state,
         isFetchingWalletActivityByFilter: true,
      }
   },

   [types.FETCH_TRANSACTIONS_WALLET_ACTIVITY_BY_FILTER_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingWalletActivityByFilter: false,
         walletActivityData: action.payload,
         isEmptyWalletActivityByFilter: action.payload.data.length === 0,
      }
   },

   [types.FETCH_TRANSACTIONS_WALLET_ACTIVITY_BY_FILTER_FAILED]: (state) => {
      return {
         ...state,
         isFetchingWalletActivityByFilter: false,
      }
   },

   // new wallet activity
   [types.FETCH_NEW_WALLET_ACTIVITY_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingWalletActivityNew: true,
      }
   },

   [types.FETCH_NEW_WALLET_ACTIVITY_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetchingWalletActivityNew: false,
         walletActivityData: {
            ...payload,
            data: [
               ...state.walletActivityData.data,
               ...payload.data,
            ],
         },
      }
   },

   [types.FETCH_NEW_WALLET_ACTIVITY_FAILED]: (state) => {
      return {
         ...state,
         isFetchingWalletActivityNew: false,
      }
   },

   //wallet activity details
   [types.FETCH_WALLET_ACTIVITY_DETAILS_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingWalletActivityDetails: true,
      }
   },

   [types.FETCH_WALLET_ACTIVITY_DETAILS_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingWalletActivityDetails: false,
         walletActivityDetails: action.payload,
      }
   },

   [types.FETCH_WALLET_ACTIVITY_DETAILS_FAILED]: (state) => {
      return {
         ...state,
         isFetchingWalletActivityDetails: false,
      }
   },

   // export wallet activity csv
   [types.EXPORT_WALLET_ACTIVITY_CSV_START]: (state) => {
      return {
         ...state,
         isFetchingWalletActivityExportCSV: true,
      }
   },

   [types.EXPORT_WALLET_ACTIVITY_CSV_COMPLETED]: (state) => {
      return {
         ...state,
         isFetchingWalletActivityExportCSV: false,
      }
   },

   [types.EXPORT_WALLET_ACTIVITY_CSV_FAILED]: (state) => {
      return {
         ...state,
         isFetchingWalletActivityExportCSV: false,
      }
   },
   [types.UPDATE_TRANSACTIONS_BY_MEMBER]: (state, action) => {
      const { member_nickname, username } = action.payload

      let updatedTransactionsData = state?.data?.data

      if(!!state?.data?.data) {
         updatedTransactionsData = updatedTransactionsData
            ?.map(item => {
               if(item?.member_username === username) {
                  return {
                     ...item,
                     member_nickname,
                  }

               } else {
                  return item
               }
            })
      }

      let updatedTransactionDetails = state?.details

      if(!!updatedTransactionDetails && updatedTransactionDetails?.member_username === username) {
         updatedTransactionDetails = {
            ...updatedTransactionDetails,
            member_nickname,
         }
      }


      let updatedWalletActivityData = state?.walletActivityData?.data

      if(!!state?.walletActivityData?.data) {
         updatedWalletActivityData = updatedWalletActivityData
            ?.map(item => {
               if(item?.member_username === username) {
                  return {
                     ...item,
                     member_nickname,
                  }

               } else {
                  return item
               }
            })
      }

      let updatedWalletActivityDetails = state?.walletActivityDetails

      if(!!updatedWalletActivityDetails && updatedWalletActivityDetails?.member_username === username) {
         updatedWalletActivityDetails = {
            ...updatedWalletActivityDetails,
            member_nickname,
         }
      }

      return {
         ...state,
         data: {
            ...state.data,
            data: updatedTransactionsData,
         },
         walletActivityData: {
            ...state.walletActivityData,
            data: updatedWalletActivityData,
         },
         walletActivityDetails: updatedWalletActivityDetails,
         details: updatedTransactionDetails,
      }
   },
};

export default createReducer(initialState)(reducersMap);
