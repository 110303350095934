import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {

   [types.FETCH_AFFILIATE_PROGRAM_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_AFFILIATE_PROGRAM_DETAILS_COMPLETED]: (state, action) => {

      // console.log('INIT', action);

      return {
         ...state,
         data: {
            ...action.payload,
         },
         isFetching: false,
      }
   },
   [types.FETCH_AFFILIATE_PROGRAM_DETAILS_FAILED]: (state) => {
      return {
         ...state,
         isFetching: false,
      }
   },
   [types.UPDATE_AFFILIATE_PROGRAM_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.UPDATE_AFFILIATE_PROGRAM_COMPLETED]: (state, action) => {

      // console.log('UPDATE', action);
      const { updated_percentages } = action.payload

      if(updated_percentages !== undefined) {
         return {
            ...state,
            data: {
               ...state.data,
               percentage: Object.keys(updated_percentages).reduce((prev, curr) => {

                  prev[curr] = {
                     admin: String(updated_percentages[curr]).split('_')[0],
                     affiliate: String(updated_percentages[curr]).split('_')[1],
                  }
                  return prev
               }, {}),
            },
            isFetching: false,
         }
      } else {
         return {
            ...state,
            data: {
               ...state.data,
               ...action.payload,
            },
            isFetching: false,
         }
      }
   },
   [types.FETCH_AFFILIATE_PROGRAM_DETAILS_FAILED]: (state) => {
      return {
         ...state,
         isFetching: false,
      }
   },
   [types.UPDATE_AFFILIATE_PROGRAM_FAILED]: (state, action) => {

      // let errors = {
      //    new_joins: [],
      //    rebills: [],
      //    store_sales: [],
      //    tips: [],
      //    unlocks: [],
      // }

      // if(errors) {
      //    Object.keys(action.payload.data).forEach(item => {
      //       errors[item] = ['Required']
      //    })
      // }

      return {
         ...state,
         isFetching: false,
         errors: action.payload.data,
      }
   },
   [types.SET_AFFILIATE_PROGRAM_ERRORS]: (state, action) => {

      return {
         ...state,
         isFetching: false,
         errors: {
            ...state.errors,
            [action.payload.type]: action.payload.value,
         },
      }
   },
   [types.FETCH_AFFILIATES_LIST_DATA_START]: (state, action) => {
      const isNextPage = action.payload

      return {
         ...state,
         isFetchingListData: !Boolean(isNextPage),
         isFetchingListDataNextPage: Boolean(isNextPage),
      }
   },
   [types.FETCH_AFFILIATES_LIST_DATA_COMPLETED]: (state, action) => {

      return {
         ...state,
         isFetchingListData: false,
         isFetchingListDataNextPage: false,
         affiliatesListData: action.payload,
      }
   },
   [types.FETCH_AFFILIATES_LIST_DATA_FAILED]: (state) => {
      return {
         ...state,
         isFetchingListData: false,
         isFetchingListDataNextPage: false,
      }
   },
};

export default createReducer(initialState)(reducersMap);
