import { useState, useEffect } from "react";

import Icon from "common/components/elements/icons";
import { upperCaseFirstLetter } from "common/utils/utils";
import PropTypes from 'prop-types';
import cx from 'classnames';
import EmojiPicker from 'common/components/elements/emoji-mart';
import { Tooltip } from 'react-tooltip';
import { createPortal } from 'react-dom';
const WRRAPER_CLASS_NAME = 'min-w-[32px] h-8 min-h-[32px] ';

const toolbarMMSIconsName = {
   bold: 'text-bold',
   italic: 'text-italic',
   underline: 'text-underline',
   link: 'link',

   code: 'code-block',
   'source-code': 'code',
   list: {
      ordered: 'numbering',
      bullet: 'bullets',
   },
   indent: {
      '+1': 'indent-increase',
      '-1': 'indent-descrease',
   },
   undo: 'revert',
   redo: 'redo',
   lineheight: 'alignment-left',
   clean: 'broom',
};

const getIconName = (icon, value, iconName = null) => {
   if(iconName) return iconName
   if('string' === typeof icon) return icon
   return icon?.[value]
};

const ToolbarIcon = ({ name }) => {
   return (
      <Icon name={ name } color='secondary group-hover/toolbar:text-action toolbar-icon' size='xl' />
   )
};

const getTitleTooltipText = (tool, options) => {
   if('background' === tool){
      return 'Text background';
   }
   const toolTitle = upperCaseFirstLetter(tool.replace('-', ' '));
   if(options){
      return `${ toolTitle } ${ options }`
   }
   return toolTitle;
}

const ToolbarButtonWrraper = ({
   tool,
   disabled,
   className,
   children,
   tooltipContanet,
   options,
   ...rest
}) => {
   return (
      <button
         { ...rest }
         style={ {
            width: 'max-content',
         } }
         className={ cx({
            [`${ WRRAPER_CLASS_NAME } items-center justify-center w-8 min-w-[32px] flex ${ className || '' }`]: true,
            'opacity-50 !cursor-auto': disabled,
            'custom-toolbar group/toolbar': !disabled,
            [`ql-${ tool }`]: tool,
         }) }
         type='button'
         data-tooltip-id='ams-top-tooltip'
         data-tooltip-content={ (disabled && !['undo', 'redo'].includes(tool)) ? 'Variable is not available' : tooltipContanet }
         value={ options }
         aria-label={ tool }
      >
         {
            children && children
         }
      </button>
   )
}

const ToolbarButton = ({
   tool,
   options,
   editor,
   isMoreTool,
   iconName,
   disabled,
   className,
   ...rest
}) => {
   const toolIconName = getIconName(toolbarMMSIconsName[tool], options, iconName);
   return (
      <ToolbarButtonWrraper
         { ...rest }
         disabled={ disabled }
         tool={ !isMoreTool ? tool : null }
         tooltipContanet={ getTitleTooltipText(tool, options) }
         className={ className }
         options={ options }
      >
         {
            toolIconName && (
               <ToolbarIcon name={ toolIconName } />
            )
         }
      </ToolbarButtonWrraper>
   )
}
const ToolbarSelect = ({
   tool,
   options,
   className,
   ...rest
}) => {
   return (
      <select
         { ...rest }
         style={ {
            width: 'max-content',
         } }
         data-tooltip-id='ams-top-tooltip'
         data-tooltip-content={ getTitleTooltipText(tool) }
         className={ cx({
            [`custom-toolbar ${ WRRAPER_CLASS_NAME } ${ className || '' }`]: true,
            [`ql-${ tool }`]: tool,
            'ql-picker ql-color-picker': ['background', 'color'].includes(tool),
            'px-3': ['header'].includes(tool),
         }) }
      >
         {
            options.map((option, index) => {
               return (
                  <option
                     key={ index.toString() }
                     value={ option }
                     className={ `ql-${ tool }-${ option }` }
                  >
                     {option}
                  </option>
               )
            })
         }
      </select>
   )
}
const ToolbarEmoji = ({
   addEmoji,
   isMobile,
   id,
   hideModalScrool,
   closeOnEmojiSelect,
   className,
}) => {
   const [showEmojis, setShowEmojis] = useState(false);
   useEffect(() => {
      if(!isMobile){
         window.addEventListener('click', closeOnClickOutside, true);
      }
      return () => {
         if(!isMobile) {
            window.removeEventListener('click', closeOnClickOutside, true);
         }
      };
   // eslint-disable-next-line
   }, []);

   const hideScrollForModal = bool => {
      if(hideModalScrool){
         const modal = document.querySelector('.modal-content');
         if(modal && !isMobile){
            modal.style.overflow = bool ? 'revert' : ''
         }
      }
   }

   function closeOnClickOutside(e) {
      if(isMobile){
         return
      } else {
         if(!e.target.closest(`#${ id }`) && !e.target.closest(`#${ `${ id }_tooltip` }`)) {
            setShowEmojis(false);
            hideScrollForModal(false);
         }
      }
   }
   if(isMobile) return null
   return (
      <ToolbarButtonWrraper
         id={ id }
         tooltipContanet='Emoji'
         className={ className }

      >
         <div
            data-tooltip-id={ `${ id }_tooltip` }
            role='presentation'
            onClick={ (e) => {
               const bool = !showEmojis;
               setShowEmojis(bool);
               hideScrollForModal(bool);
               e.stopPropagation();
            } }
            className='flex items-center justify-center w-full h-wull'
         >
            <ToolbarIcon name={ 'emoji' } />

         </div>
         {
            showEmojis && !isMobile &&  createPortal(
               <Tooltip
                  place='top-left'
                  id={ `${ id }_tooltip` }
                  // content=''
                  className='flex shadow dark:shadow'
                  clickable
                  isOpen={ showEmojis }
                  noArrow
                  variant='light'
                  style={ {
                     width: '352px',
                     zIndex: 60,
                  } }
                  openOnClick
               >
                  <EmojiPicker
                     onEmojiSelect={  (e) => {
                        if(e?.id && e?.name && !!closeOnEmojiSelect) {
                           setShowEmojis(false);
                        }
                        addEmoji(e, () => {
                           setShowEmojis(false);
                           hideScrollForModal(false);
                        })
                     } }
                     style={ {
                        width: '352px',
                        position: 'absolute',
                        zIndex: 60,
                        bottom: 0,
                     } }

                  />
               </Tooltip>
               ,
               document.getElementById('tooltips')
            )
         }
      </ToolbarButtonWrraper>
   )
}

ToolbarButton.propTypes = {
   keysArray: PropTypes.array,
   options: PropTypes.array,
   tool: PropTypes.string,
   editor: PropTypes.any,
   isMoreTool: PropTypes.bool,
   disabled: PropTypes.bool,
   iconName: PropTypes.string,
   className: PropTypes.string,
}
ToolbarButtonWrraper.propTypes = {
   keysArray: PropTypes.array,
   options: PropTypes.array,
   tool: PropTypes.string,
   editor: PropTypes.any,
   isMoreTool: PropTypes.bool,
   disabled: PropTypes.bool,
   iconName: PropTypes.string,
   tooltipContanet: PropTypes.string,
   className: PropTypes.string,
   children: PropTypes.any,
}

ToolbarSelect.propTypes = {
   options: PropTypes.array,
   tool: PropTypes.string,
   className: PropTypes.string,
}
ToolbarIcon.propTypes = {
   name: PropTypes.array,
}
ToolbarEmoji.propTypes = {
   isMobile: PropTypes.bool,
   addEmoji: PropTypes.func,
   id: PropTypes.string,
   hideModalScrool: PropTypes.bool,
   closeOnEmojiSelect: PropTypes.bool,
   className: PropTypes.string,
}
export {
   toolbarMMSIconsName,
   ToolbarIcon,
   ToolbarButton,
   getIconName,
   ToolbarSelect,
   ToolbarEmoji,
   WRRAPER_CLASS_NAME,
   ToolbarButtonWrraper,
}
