const initialState = {
   data: [],
   isFetching: true,
   modalSettings: {
      id: null,
      isOpen: false,
   },
   isEmpty: true,
};

export default initialState;
