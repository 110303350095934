import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Button from 'common/components/elements/buttons/primary'
import RadioBox from 'common/components/elements/inputs/radio-label';
import intercomMoreInfo, {
   MORE_INFO_ON_MUTING,
   MORE_INFO_ON_DISABLING_NOTIFICATIONS,
} from "admin/utils/IntercomMoreInfo";
import QueryParams from 'admin/utils/QueryParams';
import IconLabel from 'common/components/elements/icon-label';

import './style.scss'


const MuteMember = ({
   muteTime,
   onMuteTimeChange,
   onCloseModal,
   disabled,
   username,
   isMute,
}) => {

   useEffect(() => {
      QueryParams.addQueryParam('manage', isMute ? 'mute-member' : 'disable-notifications')
      return () => {
         QueryParams.removeQueryParam('manage')
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return (
      <div
         className='pb-6 overflow-x-hidden'
      >
         <div className='flex  py-4 px-4 items-center'>
            <div className='flex w-6 h-6 items-center justify-center mr-4'>
               <Button
                  padding='none'
                  onClick={ onCloseModal }
                  classNames='h-full w-full'
                  iconName='close'
                  backgroundColor='transparent'
                  fontIconColor='secondary'
               />
            </div>
         </div>
         <div className='flex items-center justify-center' >
            <IconLabel
               className='w-10 h-10'
               name={ `${ isMute ? 'chat-mute' : 'off-notificartion' }` }
               size='2xl'
               color='action'
            />
         </div>
         <span className='leading-8 mt-3 flex items-center justify-center text-major text-xl font-semibold'> { isMute ? 'Mute member' : 'Disable Notifications' } </span>
         <div className='flex flex-col mt-4 items-center'>
            <span
               // style={ { lineHeight: '24px' } }
               className='text-secondary text-base px-4 block leading-6 text-center'
            >
               {
                  isMute && (
                     <>If muted, {username}  <br />will not be able to send any comments or chat messages</>
                  )
               }
               {
                  !isMute && (
                     <>
                        If disabled, you won’t receive any notifications from { username }.
                     </>
                  )
               }
            </span>
            <div className={ `${ isMute ? 'w-56' : 'w-80' } h-5 mt-6 mb-6 ` } >
               <Button
                  text={ isMute ? 'More info on muting' : 'More info on disabling notifications' }
                  textSize='small'
                  textColor='action'
                  padding='none'
                  classNames={ `h-full w-full leading-5 ${ isMute ? 'intercom-more-info-on-muting' : 'intercom-more-info-on-disabling-notifications' }` }
                  iconName='attention-outline'
                  iconPosition='left'
                  alignment='center'
                  backgroundColor='transparent'
                  primaryColor
                  fontIconSize='large'
                  onClick={ () => intercomMoreInfo(isMute ? MORE_INFO_ON_MUTING : MORE_INFO_ON_DISABLING_NOTIFICATIONS) }
               />
            </div>
            {/* <div className='border-b border-divider px-4 mb-3 mx-3' ></div> */}
         </div>
         <div className='mx-5 border-solid rounded-lg overflow-hidden'
            style={ {
               borderWidth: '1px',
               // paddingBottom: '2px',
            } }
         >
            <RadioBox
               label='Mute for 1 hour'
               id='1_hour'
               checked={ muteTime === 3600  }
               onChange={ () => onMuteTimeChange(3600) }
               disabled={ disabled }
               textColor='major'
               className={ disabled ? 'cursor-not-allowed h-10 pb-2' : ' h-10 pb-3 hover:bg-hover' }

            />
            <RadioBox
               label='Mute for 24 hour'
               id='24_hour'
               checked={ muteTime === 86400 }
               onChange={ () => onMuteTimeChange(86400) }
               textColor='major'
               disabled={ disabled }
               className={ disabled ? 'cursor-not-allowed h-10 pb-2' : ' h-10 pb-3 hover:bg-hover' }
            />
            <RadioBox
               label='Mute for 48 hour'
               id='48_hour'
               checked={ muteTime === 172800 }
               onChange={ () => onMuteTimeChange(172800) }
               textColor='major'
               disabled={ disabled }
               className={ disabled ? 'cursor-not-allowed h-10 pb-2' : ' h-10 pb-3 hover:bg-hover' }
            />
            <RadioBox
               label={ isMute ? 'Until I unmute' : 'Until I enable' }
               id='until_unmute'
               checked={ muteTime === 'forever' }
               onChange={ () => onMuteTimeChange(null) }
               textColor='major'
               disabled={ disabled }
               className={ disabled ? 'cursor-not-allowed h-10 pb-2' : ' h-10 pb-3 hover:bg-hover' }
            />
         </div>

      </div>
   )
}

MuteMember.propTypes = {
   muteTime: PropTypes.any,
   onMuteTimeChange: PropTypes.func,
   onCloseModal: PropTypes.func,
   disabled: PropTypes.bool,
   username: PropTypes.string,
   isMute: PropTypes.bool,
}
MuteMember.defaultProps = {
   muteTime: 'default',
   isMute: true,
   onMuteTimeChange: () => {},
   onCloseModal: () => {},
}

export default MuteMember

