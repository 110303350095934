import React, { memo  } from 'react';
import Modal from 'common/components/modules/modal';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import cx from 'classnames';

const CameraAccessDialog = ({
   onClose,
   type,
   deviceName = 'camera',
}) => {
   const getModalState = () => {
      let state = {
         icon: '',
         title: '',
         description: '',
         iconColor: 'major-dark',
         bgColor: '',
      }
      switch (type) {
         case 'allow':
            state = {
               icon: 'upward-left',
               title: `Allow ${ deviceName }`,
               description: `Click “Allow” above to give access to your ${ deviceName }.`,
               bgColor: 'bg-action',
               iconColor: 'major-dark',
            }
            break;
         case 'block':
            state = {
               icon: 'details',
               title: `Your ${ deviceName } is blocked`,
               description: `Access to your ${ deviceName } is currently blocked. To continue, please unblock and allow access.`,
               iconColor: 'warning',
            }
            break;
         case 'not_found':
            state = {
               icon: 'details',
               title: `No ${ deviceName } found`,
               description: `It seems that there is no ${ deviceName } connected to your device. Please make sure you have a compatible ${ deviceName } plugged in or try using a different device.`,
               iconColor: 'warning',
            }
            break;

         default:
            break;
      }
      return state;
   }

   const state = getModalState();

   return (
      <Modal
         onClose={ onClose }
         roundedModal='lg'
         contentPosition='center'
         isCloseAction={ false }
         contentWidth='375px'
         otherClassName={
            cx({
               'p-4': true,
               'border border-warning': ['block', 'not_found'].includes(type),
            })
         }
      >

         <div className='flex'>

            <div
               className={
                  cx({
                     'w-6 h-6 bg rounded-full flex justify-center items-center mr-2 mb-4': true,
                     [`${ state.bgColor }`]: ['allow'].includes(type),
                     // 'w-6 h-6': ['block', 'not_found'].includes(type),
                  })
               }
            >
               <Icon name={ state.icon } color={ state.iconColor } size='2xl' />
            </div>
            <span className='text-[#041527] dark:text-major-dark font-base leading-24px font-medium'>{state.title}</span>
         </div>
         <span className='text-secondary dark:text-secondary-dark  text-sm leading-[20px]'>
            {state.description}
         </span>
         <div
            className={
               cx({
                  'flex mt-3 items-center': true,
                  'justify-between': 'block' === type,
                  'justify-end': 'block' !== type,
               })
            }
         >
            {
               type === 'block' && (
                  <div className='h-9 intercom-how-to-unblock-camera'>
                     <Button
                        text='How do I do this?'
                        padding='none'
                        classNames='h-full px-3'
                        backgroundColor='transparent'
                        iconName='help-outline'
                        iconPosition='left'
                        fontIconSize='large'
                        primaryColor
                        textSize='base'
                        fontWeight='medium'
                        onClick={ () => {} }
                     />
                  </div>
               )
            }
            <div className='h-9'>
               <Button
                  text='Ok'
                  padding='none'
                  classNames='h-full'
                  backgroundColor='transparent'
                  textColor='secondary dark:text-secondary-dark '
                  textSize='base'
                  fontWeight='medium'
                  onClick={ onClose }
               />
            </div>
         </div>

      </Modal>
   )
}

CameraAccessDialog.propTypes = {
   type: PropTypes.oneOf([
      'allow', 'block', 'not_found',
   ]).isRequired,
   onClose: PropTypes.func.isRequired,
   deviceName: PropTypes.string,
};


export default memo(CameraAccessDialog);
