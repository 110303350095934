import * as types from "./types";

const addUploaders = (data) => {
   return {
      type: types.ADD_UPLOADERS,
      payload: data,
   };
}
const addVideos = (data) => {
   return {
      type: types.ADD_VIDEOS,
      payload: data,
   };
}

const toggleVideoUploadLimit = (isReached) => {
   return {
      type: types.TOGGLE_VIDEO_UPLOAD_LIMIT,
      payload: isReached,
   };
}

const toggleIncreaseMyLimitReachedClicked = (isClicked) => {
   return {
      type: types.TOGGLE_INCREASE_MY_LIMIT_CLICKED,
      payload: isClicked,
   };
}

const updateOrAddVideo = (id, body) => {
   return {
      type: types.UPDATE_OR_ADD_VIDEO,
      payload: {
         id,
         body,
      },
   };
}

const removeVideo = (id, body) => {
   return {
      type: types.REMOVE_VIDEO,
      payload: {
         id,
         body,
      },
   };
}

const resetUploadsVideos = () => ({
   type: types.RESET_VIDEOS,
});
const deletePhotosetsUploadCompleted = id => ({
   type: types.RESET_PHOTOSTES,
   payload: id,
});
const addOrUpdatePhotosetCompleted = data => ({
   type: types.ADD_OR_UPDATE_PHOTOSTES,
   payload: data,
});
const destroyRequest = bool => ({
   type: types.DESTORY_MEDIA_REQUEST,
   payload: bool,
});
const destroyCompleted = data => ({
   type: types.DESTORY_MEDIA_COMPLETED,
   payload: data,
});

export {
   addVideos,
   toggleVideoUploadLimit,
   toggleIncreaseMyLimitReachedClicked,
   updateOrAddVideo,
   removeVideo,
   resetUploadsVideos,
   deletePhotosetsUploadCompleted,
   addOrUpdatePhotosetCompleted,
   addUploaders,
   destroyCompleted,
   destroyRequest,
};
