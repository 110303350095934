export const FETCH_DETAILS_START = "my-account/FETCH_DETAILS_START";
export const FETCH_DETAILS_COMPLETED = "my-account/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "my-account/FETCH_DETAILS_FAILED";

export const FETCH_PAYOUT_DETAILS_START = "my-account/FETCH_PAYOUT_DETAILS_START";
export const FETCH_PAYOUT_DETAILS_COMPLETED = "my-account/FETCH_PAYOUT_DETAILS_COMPLETED";
export const FETCH_PAYOUT_DETAILS_FAILED = "my-account/FETCH_PAYOUT_DETAILS_FAILED";

export const UPDATE_FAILED = "my-account/UPDATE_FAILED";
export const UPDATE_COMPLETED = "my-account/UPDATE_COMPLETED";
export const UPDATE_START = "my-account/UPDATE_START";

// upload avatar

export const UPLOAD_PROFILE_AVATAR_START = "my-account/UPLOAD_PROFILE_AVATAR_START";
export const UPLOAD_PROFILE_AVATAR_COMPLETED = "my-account/UPLOAD_PROFILE_AVATAR_COMPLETED";
export const UPLOAD_PROFILE_AVATAR_FAILED = "my-account/UPLOAD_PROFILE_AVATAR_FAILED";

// update password
export const UPDATE_PASSWORD = "my-account/UPDATE_PASSWORD";
export const UPDATE_PASSWORD_COMPLETED = "my-account/UPDATE_PASSWORD_COMPLETED";
export const UPDATE_PASSWORD_FAILED = "my-account/UPDATE_PASSWORD_FAILED";


// update Payout Preferences
export const UPDATE_PAYOUT = "my-account/UPDATE_PAYOUT";
export const UPDATE_PAYOUT_COMPLETED = "my-account/UPDATE_PAYOUT_COMPLETED";
export const UPDATE_PAYOUT_FAILED = "my-account/UPDATE_PAYOUT_FAILED";

export const GO_DETAILS_PAGE = "my-account/GO_DETAILS_PAGE";
export const REMOVE_PAYOUT_ERRORS = "my-account/REMOVE_PAYOUT_ERRORS";


// Notification preferences
export const FETCH_NOTIFICATION_PREFERENCES_START = "my-account/FETCH_NOTIFICATION_PREFERENCES_START";
export const FETCH_NOTIFICATION_PREFERENCES_COMPLETED = "my-account/FETCH_NOTIFICATION_PREFERENCES_COMPLETED";
export const FETCH_NOTIFICATION_PREFERENCES_FAILED = "my-account/FETCH_NOTIFICATION_PREFERENCES_FAILED";

export const UPDATE_NOTIFICATION_PREFERENCES_START = "my-account/UPDATE_NOTIFICATION_PREFERENCES_START";
export const UPDATE_NOTIFICATION_PREFERENCES_COMPLETED = "my-account/UPDATE_NOTIFICATION_PREFERENCES_COMPLETED";
export const UPDATE_NOTIFICATION_PREFERENCES_FAILED = "my-account/UPDATE_NOTIFICATION_PREFERENCES_FAILED";

export const UNSUBSCRIBE_FROM_ALL_NOTIFICATIONS = 'my-account/UNSUBSCRIBE_FROM_ALL_NOTIFICATIONS';

export const SET_SECURITY_TAB_MODAL = 'my-account/SET_SECURITY_TAB_MODAL';

export const SET_TWO_FA_MODAL_ACTION = 'my-account/SET_TWO_FA_MODAL_ACTION';
export const SET_VALID_PASWWORD_INPUTS = 'my-account/SET_VALID_PASWWORD_INPUTS';


export const UPDATE_EMAIL_START = 'my-account/UPDATE_EMAIL_START';
export const UPDATE_EMAIL_COMPLETED = 'my-account/UPDATE_EMAIL_COMPLETED';
export const UPDATE_EMAIL_FAILED = 'my-account/UPDATE_EMAIL_FAILED';

// set password types
export const SET_PASSWORD_START = "my-account/SET_PASSWORD_START";
export const SET_PASSWORD_COMPLETED = "my-account/SET_PASSWORD_COMPLETED";
export const SET_PASSWORD_FAILED = "my-account/SET_PASSWORD_FAILED";
export const SET_ADDRESS_EDIT_MODE = "my-account/SET_ADDRESS_EDIT_MODE";
