import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Router from 'admin/routes/router';
import { currentLocationPathSelector } from 'admin/state/modules/router/selectors';
import { siteInfoSelector, authUserSelector, screenWidthSelector, twitterAccountSelector } from 'admin/state/modules/common/selectors';
import OnboardingProgressBar from 'common/components/modules/progress-bar';
import {
   stepsSelector,
   isFetchingSelector,
   openStepsModalSelector,
   dialogSelector,
   isOpenAddContentModalSelector,
} from 'admin/state/modules/onboarding/selectors';

import {
   goToPagesOperation,
   submitForReviewOperation,
   submitForLaunchSiteOperation,
   openStepsModalOperation,
} from 'admin/state/modules/onboarding/operations';
import {
   setDialogCompleted,
   setAddContentModal as setAddContentModalAction,
} from 'admin/state/modules/onboarding/actions';
import OnboardingStepper from 'admin/views/onboarding/stepper';
import PageLoader from 'common/components/modules/page-loading';
import OnboardingDialog from 'admin/views/onboarding/stepper/dialog';
import { siteDetailsRefreshOperation } from 'admin/state/modules/common/operations';
import AddContentModal from 'admin/views/onboarding/add-content-modal';
import QueryParams from 'admin/utils/QueryParams';
import OnboardingStepsModalWrapper from 'admin/views/onboarding/onboarding-steps-modal-wrapper';

class ProgressBarContainer extends Component {
   static propTypes = {
      siteInfo: PropTypes.object.isRequired,
      locationPath: PropTypes.string.isRequired,
      steps: PropTypes.object,
      goToPages: PropTypes.func,
      openStepsModal: PropTypes.func,
      openModal: PropTypes.bool,
      submitForReview: PropTypes.func,
      submitForLaunchSite: PropTypes.func,
      refreshSiteDetails: PropTypes.func,
      isFetchingSteps: PropTypes.bool,
      authUser: PropTypes.object,
      dialog: PropTypes.object,
      setDialogAction: PropTypes.func,
      setAddContentModal: PropTypes.func,
      isOpenAddContentModal: PropTypes.bool,
      screenWidth: PropTypes.number,
      twitterAccount: PropTypes.object,
   };
   state = {
      animate: null,
      animateDialog: null,
   }
   componentDidMount() {
      this.initHeader();
      this.syncHeader();
      setTimeout(() => {
         this.initHeader();
         this.syncHeader();
      }, 100);
   }

   static getDerivedStateFromProps(nextProps, prevState) {
      let newState = {
         ...prevState,
      }
      if(nextProps.openModal) {
         newState = {
            ...newState,
            animate: 'up',
         }
         return newState;
      } else {
         newState = {
            ...newState,
            animate: 'down',
         }
         return newState;
      }
   }

   initHeader = () => {
      this.header = document.querySelector('.page__header');
      this.layoutContent = document.querySelector('.layout-content');
   }

   componentDidUpdate() {
      this.syncHeader();

      const { openModal, isOpenAddContentModal } = this.props;
      if(!openModal && !!QueryParams.getParam('stepper')) {
         this.openStepsModal(true)
      }
      if(openModal && !isOpenAddContentModal && !!QueryParams.getParam('addContent')) {
         this.openAddContentModal(true)
      }
   }

   componentWillUnmount() {
      this.syncHeader();
   }

   syncHeader = () => {
      if(this.header && this.layoutContent) {
         let height = this.header.offsetHeight;
         let layoutContentPaddingTopValue = parseInt(this.layoutContent.style.paddingTop);
         if(Number.isInteger(height) && Number.isInteger(layoutContentPaddingTopValue) && height !== layoutContentPaddingTopValue) {
            this.layoutContent.style.paddingTop = `${ height }px`;
         }
      }
   }

   submitOnboardingStep = (status) => {
      let animate = 'up'
      this.setState({ animateDialog: animate });
      let dialog = {
         open: true,
         loading: false,
      }
      const {
         setDialogAction,
      } = this.props
      switch (status) {
         case 'ready_to_submit_for_review':
            dialog = {
               ...dialog,
               type: 'ready_to_submit_for_review',
            }
            break;
         case 'ready_to_launch':
            dialog = {
               ...dialog,
               type: 'launch_site',
            }
            break;
         default:
            break;
      }
      setDialogAction(dialog)
   }

   onCloseModal = () => {
      let animate = 'down'
      this.setState({ animateDialog: animate });
      let dialog = {
         open: false,
         type: '',
      }
      const {
         setDialogAction,
      } = this.props
      setDialogAction(dialog)
   }

   goToPages = (slug) => {
      const { goToPages, siteInfo } = this.props;
      if('content_story' === slug) {
         this.openAddContentModal(true)
      } else {
         goToPages(slug, true, !!siteInfo.site_selected_payout)
      }
   }
   openStepsModal = (bool) => {
      const { openStepsModal, openModal } = this.props;
      let animate = 'up';
      if(!bool) {
         animate = 'down'
      }
      this.setState({ animate: animate });
      openStepsModal();
      if(bool){
         const { refreshSiteDetails } = this.props
         refreshSiteDetails()
      }
      if(!openModal && !QueryParams.getParam('stepper')) {
         QueryParams.setQueryParam('stepper', 'true')
      }
      if(openModal) {
         QueryParams.removeQueryParam('stepper')
      }
   }

   openAddContentModal = (bool) => {
      const { setAddContentModal } = this.props
      if(bool) {
         QueryParams.setQueryParam('addContent', 'true')
      } else {
         QueryParams.removeQueryParam('addContent')
      }
      setAddContentModal(bool)
   }


   getOnboardingProgressBar = (isHomePage) => {
      const {
         siteInfo,
         steps,
         openModal,
         isFetchingSteps,
         authUser,
         dialog,
         submitForReview,
         submitForLaunchSite,
         refreshSiteDetails,
         isOpenAddContentModal,
         screenWidth,
         twitterAccount,
      } = this.props;
      const { animate, animateDialog } = this.state;
      let modalContent = null;
      if(isFetchingSteps) {
         modalContent = (
            <PageLoader customStyle='h-100' />
         );
      } else {
         modalContent = (
            <>
               <OnboardingStepper
                  onCloseModal={ () => this.openStepsModal(true) }
                  status={ steps.site_status ? steps.site_status :  siteInfo.site_status  }
                  steps={ steps.steps }
                  percentage={ steps.percentage_of_completed_steps }
                  onStepClick={ (slug) => this.goToPages(slug, true) }
                  isModal={ true }
                  submitOnboardingStep={ () => this.submitOnboardingStep(steps.site_status) }
                  twitterAccount={ twitterAccount }
               />
               {
                  dialog.open && (
                     <OnboardingDialog
                        adminEmail={ authUser.email }
                        type={ dialog.type }
                        buttonLoading={ dialog.loading }
                        link={ siteInfo.site_url }
                        onCloseModal={ this.onCloseModal }
                        onClickSubmitForReview={ () => submitForReview() }
                        onClickLaunchSite={ () => submitForLaunchSite() }
                        reviewSubmitted={ () => {
                           refreshSiteDetails();
                           setTimeout(() => {
                              this.onCloseModal();
                           }, 200);
                        } }
                        siteLaunched={ () => {
                           refreshSiteDetails();
                           setTimeout(() => {
                              this.onCloseModal();
                           }, 200);
                        } }
                        screenWidth={ screenWidth }
                        animate={ screenWidth < 640 ? animateDialog : null }
                     />
                  )
               }
               {
                  isOpenAddContentModal && (
                     <AddContentModal
                        onCloseModal={ (isAction) => {
                           if(isAction){
                              this.openStepsModal(false);
                           }
                           this.openAddContentModal(false)
                        } }
                        animate={ screenWidth < 640 ? animate : null }
                     />
                  )
               }
            </>

         );
      }
      const status = steps.site_status ? steps.site_status :  siteInfo.site_status
      return (
         <>
            <OnboardingProgressBar
               status={ status }
               percentage={ steps.percentage_of_completed_steps }
               isHomePage={ isHomePage }
               openStepsModal={ () => this.openStepsModal(true) }
               stepsList={ steps.steps || [] }
            />
            {
               openModal && (
                  // <Modal
                  //    onClose={ () => this.openStepsModal(false) }
                  //    contentPosition='responsive'
                  //    animate={ screenWidth < 640 ? animate : null }
                  //    isCloseAction={ false }
                  //    contentWidth={ screenWidth < 640  ? '100%' : '510px' }
                  // >
                  //    { modalContent }
                  // </Modal>
                  <OnboardingStepsModalWrapper
                     isMobile={ screenWidth <= 1024 }
                     onClose={ () => this.openStepsModal(false) }
                     siteStatus={ status }
                  >
                     { modalContent }
                  </OnboardingStepsModalWrapper>
               )
            }
         </>
      );
   }

   render() {
      const {
         siteInfo,
         locationPath,
      } = this.props
      let progressBar = null;
      let isHomePage = !!Router.route('HOMEPAGE').match(locationPath);

      if(siteInfo.site_status !== 'live'){
         progressBar = this.getOnboardingProgressBar(isHomePage);
      }

      if(siteInfo.site_status === 'demo_mode') {
         progressBar = null
      }

      return progressBar;
   }
}

const mapStateToProps = (state) => {
   return {
      locationPath: currentLocationPathSelector(state),
      siteInfo: siteInfoSelector(state),
      steps: stepsSelector(state),
      openModal: openStepsModalSelector(state),
      isFetchingSteps: isFetchingSelector(state),
      dialog: dialogSelector(state),
      authUser: authUserSelector(state),
      isOpenAddContentModal: isOpenAddContentModalSelector(state),
      screenWidth: screenWidthSelector(state),
      twitterAccount: twitterAccountSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      openStepsModal: () => dispatch(openStepsModalOperation()),
      goToPages: (slug, isChangeModal, hasPayout) => {
         dispatch(goToPagesOperation(slug, isChangeModal, hasPayout))
      },
      submitForReview: data => {
         dispatch(submitForReviewOperation(data))
      },
      submitForLaunchSite: data => {
         dispatch(submitForLaunchSiteOperation(data))
      },
      setDialogAction: data => {
         dispatch(setDialogCompleted(data))
      },
      refreshSiteDetails: () => dispatch(siteDetailsRefreshOperation()),
      setAddContentModal: bool => {
         dispatch(setAddContentModalAction(bool))
      },
   }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProgressBarContainer);
