import React, { useState, useEffect } from 'react'
import PropTypes  from 'prop-types'
import Icon from 'common/components/elements/icons'
import Button from 'common/components/elements/buttons/primary'
import Modal from 'common/components/modules/modal';
import NumberFormatter from 'admin/utils/NumberFormatter';
import './style.scss';
import QueryParams from 'admin/utils/QueryParams';
import TruncateText from 'common/components/elements/truncate-text';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { siteInfoSelector } from 'admin/state/modules/common/selectors';

const TagCard = ({
   isInsideMassMessage,
   tagIcon,
   name,
   id,
   rule,
   showOnPage,
   isMobile,
   openMemberListModal,
   setTagOption,
   getTagInfo,
   isInsideModal,
   isSelected,
   onSelectCard,
   classNames,
   insideFilter,
   tagMembersCount,
   fromTagsPage,
   isBulkSelect,
   isBulkSelected,
   onBulkSelect,
   loadingText,
   isDemoMode,
}) => {
   const [tagOptionsModal, setTagOptionsModal] = useState(false)
   const numberFormatter = new NumberFormatter();

   const handleIconClick = (e) => {
      e.stopPropagation();
      setTagOptionsModal(!tagOptionsModal)
      getTagInfo()
   }
   return (
      <>
         <div
            role='presentation'
            className={
               `flex items-center tag-card-content cursor-pointer
               ${ (!fromTagsPage && !!rule && !insideFilter) ? 'hover:bg-transparent' : 'hover:bg-hover' }
              bg-panel  ${ !insideFilter ? 'rounded-lg' : '' }
                w-full h-full leading-tight tracking-wide justify-between select-none relative
                ${ classNames } member-tag-${ id } ${ isInsideModal ? 'mb-2' : '' }`
            }
            onClick={ () => {
               if(!!rule && isSelected && !insideFilter && !isInsideMassMessage) {
                  return;
               }
               if(isInsideMassMessage){
                  onSelectCard(!isSelected)
                  return;
               } else if((insideFilter && (rule || !rule)) || ((!rule) && (isInsideModal || insideFilter))){
                  onSelectCard(!isSelected)
                  return;
               }
               !!getTagInfo && getTagInfo()
               !!setTagOption && !isBulkSelect &&  setTagOption('edit')
               isBulkSelect && onBulkSelect(id)
            } }
            data-tooltip-content={ (isInsideModal && !!rule && !isInsideMassMessage) ? 'Auto tags cannot be manually applied' : null }
            data-tooltip-id={ (isInsideModal && !!rule && !isInsideMassMessage) ? 'ams-top-tooltip' : null }
         >
            <div className={ `w-full flex gap-3 items-center ${ !!rule ? '' : ' flex-no-wrap' }` }>
               <span className='tag-icon' style={ {
                  opacity: `${ (!fromTagsPage && !!rule && !insideFilter && !isInsideMassMessage) ? '0.4' : '1' }`,
               } }>{tagIcon}</span>
               <div className='tag-card-info w-full flex flex-col'>
                  <div className='w-full flex items-center text-sm'>
                     <div className={ `tags-card-name${ isInsideModal && !isInsideMassMessage ? '-inside-modal' : insideFilter && !isInsideMassMessage ? '-inside-filter' : '' } flex flex-col text-sm` }>
                        <TruncateText
                           className={ `tags-card-name${ isInsideModal ? '-inside-modal' : insideFilter ? '-inside-filter' : '' } flex flex-col text-sm` }
                           text={ name }
                           textClass={ `tag-name font-semibold text-sm leading-16 mr-1 text-${ (!fromTagsPage && !!rule && !insideFilter && !isInsideMassMessage) ? 'disabled' : 'major' }` }
                           textSize='14px'
                           fontWeight='600'
                           width='auto'
                        />
                        <p className={ `leading-16 text-sm font-normal text-${ (!fromTagsPage && !!rule && !insideFilter && !isInsideMassMessage) ? 'disabled' : 'major' }` }>
                           {!!loadingText && !isInsideMassMessage && <span className={ `block mt-1 text-${ (!fromTagsPage && !!rule && !insideFilter) ? 'disabled' : 'secondary' } leading-18 text-xs` }>{loadingText}</span>}
                           { !loadingText && <>{numberFormatter.withCommas(tagMembersCount)} member{tagMembersCount === 1 ? '' : 's'}</>}
                        </p>
                     </div>
                  </div>
                  {
                     !loadingText && !!rule && (
                        <span className={ `block mt-1 text-${ (!fromTagsPage && !!rule && !insideFilter && !isInsideMassMessage) ? 'disabled' : 'secondary' } leading-18 text-xs` }> {rule} </span>
                     )
                  }
               </div>
            </div>
            <div>
               {(isInsideModal || insideFilter || isBulkSelect) ? (
                  <div
                     className={
                        cx({
                           [`flex items-center justify-center cursor-pointer select-none border h-5 w-5 min-h-[20px] min-w-[20px] rounded`]: true,
                           'skin-primary-border bg-hover dark:bg-hover-dark': isSelected || isBulkSelected,
                           'bg-panel dark:bg-black-field shadow-inner border-secondary-12 dark:border-secondary-dark-12': !isSelected && !isBulkSelected,
                        })
                     }
                  >
                     {
                        (isSelected || isBulkSelected) && (
                           <div className='h-5 w-5 flex items-center justify-center'>
                              <Icon
                                 style={ {
                                    fontWeight: '800',
                                 } }
                                 color='action'
                                 name='done'
                                 size='base'
                              />
                           </div>
                        )
                     }
                  </div>
               ) : (
                  <Icon
                     name='options-vertical'
                     size='xl'
                     color='secondary'
                     onClick={ (e) => {
                        if(!isBulkSelect) {
                           handleIconClick(e)
                        } else {
                           e.stopPropagation()
                           onBulkSelect(id)
                        }
                     } }
                  />
               )}
            </div>
         </div>
         <div className='relative'>
            {
               tagOptionsModal && (
                  <OptionsModal
                     onCloseModal={ () => setTagOptionsModal(false) }
                     desktopType={ isMobile ? 'mobile' : 'descktop' }
                     openMemberListModal={ openMemberListModal }
                     isMobile={ isMobile }
                     id={ id }
                     setTagOption={ setTagOption }
                     buttonDisabled={ tagMembersCount < 1 }
                  />
               )
            }
         </div>
      </>
   )
}


const OptionsModal = ({
   isMobile,
   onCloseModal,
   desktopType,
   openMemberListModal,
   id,
   setTagOption,
   buttonDisabled,
}) => {
   const siteInfo = useSelector(siteInfoSelector)
   const [animate, setAnimate] = useState('up');
   let timeout = null;
   const isDemoMode = siteInfo?.site_status === 'demo_mode'

   useEffect(() => {
      return () => {
         if(timeout){
            clearTimeout(timeout)
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   function closeOnClickOutside(e) {
      if(!isMobile && !e.target.closest(`.member-tag-${ id }`)) {
         onCloseModal()
      }
   }


   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };
   // eslint-disable-next-line
   }, [onCloseModal,isMobile]);

   function modalClose(){
      setAnimate('down')
      timeout = setTimeout(() => {
         onCloseModal()
      }, 800)
   }



   const closeModalWithTimeout = () => {
      timeout = setTimeout(() => {
         onCloseModal()
      }, 50)
   }

   const actions = (e, type) => {
      e.stopPropagation()
      closeModalWithTimeout()
      if(type === 'open_member_list_modal'){
         openMemberListModal();
         return;
      }
      setTagOption(type, id)
   }

   const view = (
      <div className={ `member-tags-options shadow dark:shadow-dark member-tag-${ id } flex flex-col w-full bg-panel py-1 items-center justify-between ${ desktopType === 'mobile' ? '' : 'border rounded-lg' }` }>
         <div className='member-tags-options-item flex w-full h-10 items-center justify-between'>
            <Button
               text='Edit tag'
               textPosition='left'
               textSize='small'
               fontWeight='normal'
               lineHeight='leading-5'
               textColor='major '
               classNames='h-full w-full gap-3 px-4'
               iconName='pencil'
               fontIconColor='secondary'
               iconPosition='left'
               alignment='end'
               padding='none'
               backgroundColor='transparent'
               fontIconSize='extraLarge'
               onClick={ (e) => {
                  actions(e, 'edit')
                  QueryParams.pushQueryParam('manage', 'edit-tag')
               } }
               textMarginX={ false }
            />
         </div>
         <div className='member-tags-options-item flex w-full h-10 items-center justify-between'
            data-tooltip-content={ buttonDisabled ? 'There are no members assigned to this tag' : null }
            data-tooltip-id={ buttonDisabled ? 'ams-bottom-tooltip' : null }
         >
            <Button
               text='Send mass message'
               textPosition='left'
               textSize='small'
               fontWeight='normal'
               lineHeight='leading-5'
               textColor='major '
               iconName='messaging'
               fontIconColor='secondary'
               iconPosition='left'
               alignment='end'
               padding='none'
               backgroundColor='transparent'
               fontIconSize='extraLarge'
               onClick={ e => actions(e, 'send_mass_private_message') }
               disabled={ buttonDisabled }
               classNames={ `h-full w-full gap-3 px-4 ${ !buttonDisabled ? '' : 'opacity-50' }` }
               textMarginX={ false }


            />
         </div>
         <div className='member-tags-options-item flex w-full h-10 items-center justify-between'>
            <Button
               text='Delete tag'
               iconName='delete'
               textPosition='left'
               textSize='small'
               fontWeight='normal'
               lineHeight='leading-5'
               fontIconSize='extraLarge'
               iconPosition='left'
               classNames='h-full w-full gap-3 px-4'
               backgroundColor='transparent'
               fontIconColor='error'
               textColor='error'
               padding='none'
               alignment='end'
               onClick={ e => {
                  if(isDemoMode) return
                  actions(e, 'delete')
                  QueryParams.pushQueryParam('manage', 'delete-tag')
               } }
               textMarginX={ false }
               disabled={ isDemoMode }
               dataToolTipContent={ !!isDemoMode ? 'Not available in demo' : '' }
            />
         </div>
      </div>
   )
   if(isMobile) {
      return (
         <Modal
            onClose={ modalClose }
            animate={ animate }
         >
            {view}
         </Modal>
      )
   } else {
      return (
         <div
            style={ { bottom: '-102px', right: '0px' } }
            className='absolute z-30 bottom-0'>
            {view}
         </div>
      )
   }
}


OptionsModal.propTypes = {
   isMobile: PropTypes.bool,
   onCloseModal: PropTypes.func,
   desktopType: PropTypes.string,
   openMemberListModal: PropTypes.func,
   id: PropTypes.number,
   setTagOption: PropTypes.func,
   buttonDisabled: PropTypes.bool,
}

TagCard.propTypes = {
   isInsideMassMessage: PropTypes.bool,
   tagIcon: PropTypes.string,
   name: PropTypes.string,
   id: PropTypes.number,
   rule: PropTypes.string,
   showOnPage: PropTypes.bool,
   isMobile: PropTypes.bool,
   openMemberListModal: PropTypes.func,
   setTagOption: PropTypes.func,
   getTagInfo: PropTypes.func,
   isInsideModal: PropTypes.bool,
   isSelected: PropTypes.bool,
   onSelectCard: PropTypes.func,
   classNames: PropTypes.string,
   insideFilter: PropTypes.bool,
   tagMembersCount: PropTypes.number,
   fromTagsPage: PropTypes.bool,
   isBulkSelect: PropTypes.bool,
   isBulkSelected: PropTypes.bool,
   onBulkSelect: PropTypes.func,
   loadingText: PropTypes.string,
   isDemoMode: PropTypes.bool,
}

export default TagCard
