export const FETCH_DETAILS = "domainRedirects/FETCH_DETAILS";
export const FETCH_DETAILS_COMPLETED = "domainRedirects/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "domainRedirects/FETCH_DETAILS_FAILED";

export const DELETE_REDIRECT_COMPLETED = "domainRedirects/DELETE_REDIRECT_COMPLETED";

export const CREATE_NEW_COMPLETED = "domainRedirects/CREATE_NEW_COMPLETED";
export const CREATE_NEW_FAILED = "domainRedirects/CREATE_NEW_FAILED";

export const UPDATE_DATA_COMPLETED = "domainRedirects/UPDATE_DATA_COMPLETED";
export const UPDATE_DATA_FAILED = "domainRedirects/UPDATE_DATA_FAILED";

export const SET_REDIRECT_MODAL = "domainRedirects/SET_REDIRECT_MODAL";
