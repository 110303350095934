import React from 'react'
import PropTypes from 'prop-types';
import CreateEditModal from 'common/components/modules/modal-wrapper';

const DiscardChangeMethodModal = ({
   action,
   onCloseModal,
   isMobile,
   description,
   buttonText,
}) => {
   return (
      <CreateEditModal
         type='confirm'
         icon='cancel'
         iconColor='error'
         descriptionColorClass='text-secondary dark:text-secondary-dark'
         action={ action }
         onCloseModal={ onCloseModal }
         title='Discard changes?'
         description={ description }
         cancelText='Keep editing'
         buttonText={ buttonText }
         nonPrimaryColor
         textColor='error'
         contentWidth='375px'
         isMobile={ isMobile }
         buttonClassName='mt-8 text-error'
         iconSize='2xl'
         isCloseAction={ true }
         confirmButtonProperties={ {
            textClassName: 'dark:text-error-dark',
         } }
      />
   )
}

DiscardChangeMethodModal.propTypes = {
   onCloseModal: PropTypes.func,
   action: PropTypes.func,
   isMobile: PropTypes.bool,
   description: PropTypes.string,
   buttonText: PropTypes.string,
};

DiscardChangeMethodModal.defaultProps = {
   description: 'Changes you have made so far will not be saved',
   buttonText: 'Discard changes',
};

export default DiscardChangeMethodModal;
