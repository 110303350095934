import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   // FETCH TIPS /
   [types.FETCH_COLORS_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
         selectFetching: false,
         isFetchingDeleteSite: false,
      }
   },

   [types.FETCH_COLORS_COMPLETED]: (state, action) => {
      let customPalette = {
         primary_color: action.payload?.[0]?.primary_color || window.skinPrimaryColor,
         secondary_color: action.payload?.[0]?.secondary_color || window.skinSecondaryColor,
      }
      const firstPallet = Array.isArray(action?.payload) ? action.payload.find(color => color.is_custom) : null;
      if(firstPallet){
         customPalette = firstPallet;
      }
      return {
         ...state,
         isFetching: false,
         colors: action.payload,
         customPalette,
      }
   },

   [types.FETCH_COLORS_FAILED]: (state) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.CHANGE_COLORS_COMPLETED]: (state, action) => {
      const {
         data,
         isAdd,
      } = action.payload
      let newColors = [...state.colors];
      let filteredColors = newColors;
      if(isAdd){
         filteredColors = [data, ...newColors]
      } else {
         filteredColors = newColors.filter((color) => {
            if(color.id === data.id){
               color.primary_color = data.primary_color;
               color.secondary_color = data.secondary_color;
               color.selected = data.selected;
            } else {
               if(data.selected){
                  color.selected = false;
               }
            }
            return color;
         });
      }
      return {
         ...state,
         colors: filteredColors,
         selectColor: data,
      }
   },

   [types.UPDATE_CUSTOM_PALETTE_COMPLETED]: (state, action) => {
      return {
         ...state,
         customPalette: {
            ...state.customPalette, ...action.payload,
         },
      }
   },
   [types.SELECT_COLORS_REQUEST]: (state, action) => {
      return {
         ...state,
         selectFetching: action.payload,
      }
   },
   [types.SELECT_COLORS_FAILED]: (state, action) => {
      return {
         ...state,
         selectFetching: false,
      }
   },

   [types.DELETE_MY_SITE_FETCHING]: (state, action) => {
      return {
         ...state,
         isFetchingDeleteSite: action.payload,
      }
   },

   // logo

   [types.FETCH_LOGO_DATA_COMPLETED]: (state, action) => {
      const { data, colors } = action.payload
      let newState = {}
      if(!!colors){
         newState = {
            siteColorsArray: colors,
         }
      }
      return {
         ...state,
         logoData: data,
         isFetchingLogo: false,
         ...newState,
      }
   },
   [types.FETCH_LOGO_DATA_FETCHING]: (state, action) => {
      return {
         ...state,
         isFetchingLogo: true,
      }
   },
   [types.SET_UPLOAD_LOGO]: (state, action) => {
      return {
         ...state,
         uploadLogo: {
            ...state.uploadLogo,
            ...action.payload,
         },
      }
   },
   [types.UPDATE_LOGO_DATA]: (state, action) => {
      return {
         ...state,
         logoData: {
            ...state.logoData,
            ...action.payload,
         },
      }
   },


};

export default createReducer(initialState)(reducersMap);
