import React, { useState, useEffect  } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import EmojiPicker from 'common/components/elements/emoji-mart';

const EmojiContent = ({
   primaryIcon,
   primaryIconSize,
   switchShowEmojis,
   addEmoji,
   isMobile,
   style,
   className,
   top,
   id,
   xPositions,
   width,
   hideModalScrool,
   iconColor,
   darkIconColor,
   size,
   onSetShowEmojis,
   closeOnEmojiSelect,
   iconClassName = '',
}) => {
   const [showEmojis, setShowEmojis] = useState(false);
   useEffect(() => {
      if(!isMobile){
         window.addEventListener('click', closeOnClickOutside, true);
      }
      return () => {
         if(!isMobile) {
            window.removeEventListener('click', closeOnClickOutside, true);
         }
      };
   // eslint-disable-next-line
   }, []);

   const hideScrollForModal = bool => {
      if(hideModalScrool){
         const modal = document.querySelector('.modal-content');
         if(modal && !isMobile){
            modal.style.overflow = bool ? 'revert' : ''
         }
      }
   }

   function closeOnClickOutside(e) {
      if(isMobile){
         return
      } else {
         if(!e.target.closest(`#${ id }`)) {
            setShowEmojis(false);
            hideScrollForModal(false);
            if(onSetShowEmojis){
               onSetShowEmojis(false, true)
            }
         }
      }
   }
   if(isMobile) return null
   return (
      <div id={ id }>
         <div
            style={ style ? { ...style } : {} }
            className={ `flex h-7 items-center cursor-pointer ${ className }` }>
            <div
               role='presentation'
               onClick={ (e) => {
                  const bool = !showEmojis;
                  setShowEmojis(bool);
                  if(onSetShowEmojis){
                     onSetShowEmojis(bool)
                  }
                  hideScrollForModal(bool);
                  e.stopPropagation();
                  switchShowEmojis();
               } }
               className={ `flex items-center justify-center ${ size }` }
            >
               <Icon
                  name={ primaryIcon ? primaryIcon : 'emoji' }
                  color={ iconColor }
                  darkColor={ darkIconColor }
                  style={ { fontSize: primaryIconSize ? primaryIconSize : '24px' } }
                  className={ iconClassName }
                  iconClassName='!text-placeholder dark:!text-placeholder-dark hover:!text-secondary dark:hover:!text-secondary-dark'
               />
            </div>

         </div>
         {showEmojis && !isMobile && (
            <EmojiPicker
               onEmojiSelect={  (e) => {
                  if(e?.id && e?.name && !!closeOnEmojiSelect) {
                     setShowEmojis(false);
                  }

                  addEmoji(e, () => {
                     setShowEmojis(false);
                     hideScrollForModal(false);
                     if(onSetShowEmojis){
                        onSetShowEmojis(false)
                     }
                  })
               } }
               style={ {
                  ...xPositions,
                  width: width,
                  position: 'absolute',
                  top: top,
                  zIndex: 60,
               } }

            />
         )}
      </div>
   )
}

EmojiContent.defaultProps = {
   xPositions: {
      right: 0,
   },
   width: '352px',
   iconColor: 'placeholder',
   darkIconColor: 'placeholder-dark',
   size: 'w-7 h-8 m-1',
}
EmojiContent.propTypes = {
   primaryIcon: PropTypes.string,
   primaryIconSize: PropTypes.string,
   size: PropTypes.string,
   isMobile: PropTypes.bool,
   addEmoji: PropTypes.func,
   className: PropTypes.string,
   switchShowEmojis: PropTypes.func,
   top: PropTypes.string,
   style: PropTypes.object,
   xPositions: PropTypes.object,
   id: PropTypes.string,
   width: PropTypes.string,
   darkIconColor: PropTypes.string,
   iconColor: PropTypes.string,
   hideModalScrool: PropTypes.bool,
   onSetShowEmojis: PropTypes.func,
   closeOnEmojiSelect: PropTypes.bool,
   iconClassName: PropTypes.string,
}
export default EmojiContent;
