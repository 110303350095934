import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import Modal from '../modal';
import Button from 'common/components/elements/buttons/primary';
import MobileFilterContent from './content';

import './style.scss'

const MobileFilter = ({
   sortOptions,
   selectedSortValue,
   sortOptionValueKey,
   sortOptionNameKey,
   sortName,
   disabledSortOptions,
   disabledSortOptionTooltipText,
   onFilterChange,
   filtersList,
   showClearAllFilters,
   onClickClearAllFilters,
   onDownloadCsv,
   isLoadingCsv,
   onDownloadPdf,
   isLoadingPdf,
   filtersCount,
   filterButtonClassName,
   isOpen,
   onCloseModal,
   isHiddenButton,
}) => {
   const [animate, setAnimate] = useState('up');
   const [isOpenContent, setIsOpenContent] = useState(isOpen)

   const onCloseContent = () => {
      setAnimate('down')
      setTimeout(() => {
         setIsOpenContent(false)
         setAnimate('up')

         if(Boolean(onCloseModal)) {
            onCloseModal()
         }
      }, 800)
   }

   const filtersCountToDisplay = filtersCount > 9 ? '9+' : filtersCount

   return (
      <>
         <div
            className={ `w-10 h-10 ${ isHiddenButton ? 'hidden' : 'flex' } items-center justify-center p-2 border border-divider bg-panel hover:bg-grey-hover rounded-lg cursor-pointer relative select-none ${ filterButtonClassName }` }
            role='presentation'
            onClick={ () => setIsOpenContent(true) }
         >
            <Icon
               name='filter-new'
               size='2xl'
               color='secondary'
            />
            {
               Boolean(filtersCount) && (
                  <div
                     className='aspect-square min-w-[20px] min-h-[20px] flex justify-center items-center bg-error rounded-full absolute top-[-6px] right-[-6px] p-[2px]'
                  >
                     <span className='text-panel text-xs font-medium'>{filtersCountToDisplay}</span>
                  </div>
               )
            }
         </div>
         {
            isOpenContent && (
               <Modal
                  onClose={ onCloseContent }
                  roundedModal='lg'
                  animate={ animate }
                  contentPosition='bottom'
                  otherClassName='flex flex-col select-none'
               >
                  <MobileFilterContent
                     sortOptions={ sortOptions }
                     selectedSortValue={ selectedSortValue }
                     sortOptionValueKey={ sortOptionValueKey }
                     sortOptionNameKey={ sortOptionNameKey }
                     sortName={ sortName }
                     disabledSortOptions={ disabledSortOptions }
                     disabledSortOptionTooltipText={ disabledSortOptionTooltipText }
                     onFilterChange={ onFilterChange }
                     filtersList={ filtersList }
                     onDownloadCsv={ onDownloadCsv }
                     isLoadingCsv={ isLoadingCsv }
                     onDownloadPdf={ onDownloadPdf }
                     isLoadingPdf={ isLoadingPdf }
                  />
                  <div
                     className='w-full p-4 flex justify-between items-center'
                  >
                     {
                        showClearAllFilters ? (
                           <Button
                              text='Clear all filters'
                              backgroundColor='transparent'
                              classNames='!w-[max-content] h-9 px-3.5 py-2 gap-2'
                              textColor='secondary'
                              textSize='small'
                              textClassName='!m-0 !leading-5'
                              borderRadius='large'
                              onClick={ () => {
                                 onClickClearAllFilters()
                                 onCloseContent()
                              } }
                              iconName='cancel'
                              iconPosition='left'
                              fontIconColor='secondary'
                              alignment='center'
                           />
                        ) : (
                           <Button
                              text='Close'
                              backgroundColor='transparent'
                              classNames='!w-[max-content] h-9 px-3.5 py-2'
                              textColor='secondary'
                              textSize='small'
                              textClassName='!m-0'
                              borderRadius='large'
                              onClick={ onCloseContent }
                           />
                        )
                     }
                     <Button
                        text='Apply'
                        primaryColor
                        classNames='!w-[max-content] h-9 px-3.5 py-2'
                        textSize='small'
                        textClassName='!m-0'
                        borderRadius='large'
                        onClick={ onCloseContent }
                        disabled={ filtersCount === 0 }
                     />
                  </div>
               </Modal>
            )
         }
      </>
   )
}

MobileFilter.defaultProps = {
   sortOptionValueKey: 'value',
   sortOptionNameKey: 'name',
   showClearAllFilters: false,
   filterButtonClassName: '',
   isOpen: false,
   isHiddenButton: false,
}

MobileFilter.propTypes = {
   sortOptions: PropTypes.array,
   selectedSortValue: PropTypes.string,
   sortOptionValueKey: PropTypes.string,
   sortOptionNameKey: PropTypes.string,
   disabledSortOptions: PropTypes.array,
   disabledSortOptionTooltipText: PropTypes.string,
   onFilterChange: PropTypes.func,
   sortName: PropTypes.string,
   filtersList: PropTypes.arrayOf(
      PropTypes.shape({
         type: PropTypes.string,
         title: PropTypes.string,
         isMultiple: PropTypes.bool,
         optionNameKey: PropTypes.string,
         optionValueKey: PropTypes.string,
         selectedFilter: PropTypes.object,
         filterName: PropTypes.string,
         onOpenFilter: PropTypes.func,
         isLoading: PropTypes.bool,
         isSearchable: PropTypes.bool,
         searchPlaceholder: PropTypes.string,
         onSearch: PropTypes.func,
         isEmpty: PropTypes.bool,
         isEmptyByFilter: PropTypes.bool,
         onScrollContent: PropTypes.func,
         isLoadingNextPage: PropTypes.bool,
         isLoadingBySearch: PropTypes.bool,
         useMemberAvatar: PropTypes.bool,
         avatarSrcKey: PropTypes.string,
         images: PropTypes.object,
         useImagesAsOptions: PropTypes.bool,
         useIconLabel: PropTypes.bool,
         useSpanAsIcon: PropTypes.bool,
         labelClassName: PropTypes.string,
         date: PropTypes.object,
         dateStartKey: PropTypes.string,
         dateEndKey: PropTypes.string,
         secondTextKey: PropTypes.string,
         checkBoxClassName: PropTypes.string,
         optionIconKey: PropTypes.string,
         optionIconColorKey: PropTypes.string,
         disabled: PropTypes.bool,
         disabledOptions: PropTypes.array,
         labelTooltipText: PropTypes.string,
         showSelectAll: PropTypes.bool,
         isSelectedAll: PropTypes.bool,
         contentId: PropTypes.string,
         checkboxMargin: PropTypes.string,
         selectedLabelClassName: PropTypes.string,
         itemContentClassName: PropTypes.string,
         options: PropTypes.array,
         optionsMaxHeight: PropTypes.string,
         optionsListClassName: PropTypes.string,
      })
   ),
   showClearAllFilters: PropTypes.bool,
   onClickClearAllFilters: PropTypes.func,
   onDownloadCsv: PropTypes.func,
   isLoadingCsv: PropTypes.bool,
   filtersCount: PropTypes.number,
   filterButtonClassName: PropTypes.string,
   onDownloadPdf: PropTypes.func,
   isLoadingPdf: PropTypes.bool,
   isOpen: PropTypes.bool,
   onCloseModal: PropTypes.func,
   isHiddenButton: PropTypes.bool,
}

export default memo(MobileFilter)
