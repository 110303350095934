const initialState = {
   isFetching: true,
   list: {},
   isEmpty: false,
   listError: null,

   isFetchingByFilter: false,
   isEmptyByFilter: false,
   promotionsFilters: {},

   isNewFetching: false,

   currentPromotion: {},
   isFetchingById: true,
   isFetchingEditPromotion: false,
   editErrors: {},

   createData: {},
   isFetchingCreate: false,
   createErrors: {},

   isFetchingOptionsAction: false,
   actionsErrors: {},

   isFetchingPromotionsForFilter: true,
   isFetchingInfoFilter: true,
   isUnprocessablePricingWarning: false,
   promotionsForFilter: [],

   membershipDurationError: null,
};

export default initialState;
