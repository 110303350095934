import React from 'react'
import Modal from 'common/components/modules/modal';
import Button from 'common/components/elements/buttons/primary';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';

export const ExpireFreeMembershipModal = ({
   isMobile,
   expireFreeMemberShip,
   onClose,
}) => {
   return (
      <div className='w-60'>
         <Modal
            onClose={ () => {} }
            contentPosition={ isMobile ? 'bottom' : 'center' }
            marginX='5'
            contentWidth={ isMobile ? '100%' : '375px' }

         >
            <div className='z-10 bg-panel'>
               <div className='flex items-center justify-center mt-6' >
                  <div className='h-10 w-10 rounded-full flex items-center justify-center bg-secondary-12'
                  >
                     <Icon name={ `present` } color='secondary' size={ '2xl' } className={ `h-10 w-10 text-2xl pt-3  `  } style={ { paddingTop: '10px' } } />
                  </div>
               </div>
               <span className='leading-8 mt-3 mb-4 px-4 text-center flex items-center justify-center text-major text-xl font-semibold select-none' style={ {
                  lineHeight: '30px',
               } }>Expire complimentary membership?</span>
               <div className='mx-6 text-center mt-4 mb-6'>
                  <p className='leading-6 text-base font-normal mb-4 text-secondary' >
                     Member will revert to previous status and will no longer have access to your content
                  </p>
                  <Button
                     text='Yes, expire membership'
                     // textColor='action'
                     primaryColor
                     textSize='base'
                     backgroundColor='transparent'
                     onClick={ expireFreeMemberShip }
                     classNames='leading-5 text-sm font-medium mt-8 skin-primary-text h-9'
                  />
                  <Button
                     text='No'
                     textColor='secondary'
                     textSize='base'
                     backgroundColor='transparent'
                     onClick={ onClose }
                     classNames='leading-5 text-sm text-secondary font-medium h-9 mt-3'
                  />
               </div>
            </div>
         </Modal>
      </div>
   )
}


ExpireFreeMembershipModal.propTypes = {
   expireFreeMemberShip: PropTypes.func,
   onClose: PropTypes.func,
   isMobile: PropTypes.bool,
}

