import * as types from "./types";

const fetchSettingsRequest = () => ({
   type: types.FETCH_OUTGOING_NOTIFICATIONS_REQUEST,
});

const fetchSettingsCompleted = (payload) => ({
   type: types.FETCH_OUTGOING_NOTIFICATIONS_COMPLETED,
   payload: payload,
})

const fetchSettingsFailed = (err) => ({
   type: types.FETCH_OUTGOING_NOTIFICATIONS_FAILED,
   payload: {
      err,
   },
})
const updateSettingsRequest = () => ({
   type: types.UPDATE_OUTGOING_NOTIFICATIONS_REQUEST,
});

const updateSettingsCompleted = (slug, data) => ({
   type: types.UPDATE_OUTGOING_NOTIFICATIONS_COMPLETED,
   payload: { slug, data },
})

const updateSettingsFailed = (err) => ({
   type: types.UPDATE_OUTGOING_NOTIFICATIONS_FAILED,
   payload: err,
})
const fetchSettingBySlugRequest = () => ({
   type: types.FETCH_OUTGOING_NOTIFICATION_BY_SLUG_REQUEST,
});

const fetchSettingBySlugCompleted = (data) => ({
   type: types.FETCH_OUTGOING_NOTIFICATION_BY_SLUG_COMPLETED,
   payload: data,
})

const fetchSettingBySlugFailed = (err) => ({
   type: types.FETCH_OUTGOING_NOTIFICATION_BY_SLUG_FAILED,
   payload: err,
})

const setCustomNotificationModal = (data) => ({
   type: types.SET_CUSTOM_NOTIFICATION_MODAL,
   payload: data,
})

const addCustomNotifcationsCompleted = (data) => ({
   type: types.ADD_CUSTOM_NOTIFICATIONS_COMPLETED,
   payload: data,
})

const deleteCustomNotifcationCompleted = (slug) => ({
   type: types.DELETE_CUSTOM_NOTIFICATION_COMPLETED,
   payload: slug,
})
const clearUpdateErrorStateAction = (slug) => ({
   type: types.CLEAR_UPDATE_ERROR_STATE,
   payload: slug,
})
export {
   fetchSettingsRequest,
   fetchSettingsCompleted,
   fetchSettingsFailed,

   updateSettingsRequest,
   updateSettingsCompleted,
   updateSettingsFailed,

   fetchSettingBySlugRequest,
   fetchSettingBySlugCompleted,
   fetchSettingBySlugFailed,

   setCustomNotificationModal,

   addCustomNotifcationsCompleted,

   deleteCustomNotifcationCompleted,
   clearUpdateErrorStateAction,
};
