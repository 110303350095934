import React from 'react';
import PropTypes from 'prop-types';

const NoMoreResults = ({ className }) => {
   return (
      <span className={ `text-placeholder dark:text-placeholder-dark w-full text-center select-none block ${ className }` }>No more results to display</span>
   );
};

NoMoreResults.defaultProps = {
   className: 'py-3',
};

NoMoreResults.propTypes = {
   className: PropTypes.string,
};

export default NoMoreResults;
