
import appIconFontStyles from './app-icon-font-styles.txt'
const settings = {
   api: 'https://fonts.googleapis.com/css2?family=',
   api2: 'https://fonts.googleapis.com/css?family=',
};
const googleFontUrl = 'https://raw.githubusercontent.com/jonathantneal/google-fonts-complete/master/google-fonts.json';

export default {

   getParameterByName(name, url) {
      // if(!url) url = window.location.href;
      // name = name.replace(/[\[\]]/g, "\\$&");
      // var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      //    results = regex.exec(url);
      // if(!results) return null;
      // if(!results[2]) return '';
      // return decodeURIComponent(results[2].replace(/\+/g, " "));
      // if(v.category !== 'sans-serif') return; //filter only sans-serif fonts. replace with your filter condition(s)
   },
   getJSON(url, callback) {

      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'json';

      xhr.onload = function() {
         var status = xhr.status;
         if(status === 200) {
            callback(status, xhr.response);
         } else {
            callback(status);
         }
      };

      xhr.send();
   },
   getfontSize(element){
      if(!element) {
         return
      }
      const maxWidth = 232
      const fontSize = 70
      let minFontSize = 20;
      const textSvg = element.querySelectorAll('text')[0]
      textSvg.setAttributeNS(null, 'font-size',  `${ fontSize }px`)
      let width = textSvg.getBoundingClientRect().width;
      let res = 70
      if(width > maxWidth) {
         let newfontSize = fontSize;
         let decrement = 1;
         let newWidth = width;
         while(width > maxWidth) {

            newfontSize -= decrement;
            if(newfontSize < minFontSize) {
               return { fontSize: `${ minFontSize }px` };
            }
            textSvg.setAttributeNS(null, 'font-size',  `${ Math.round(newfontSize) }px`)
            newWidth = textSvg.getBoundingClientRect().width;
            if(newWidth < maxWidth && decrement === 1){
               decrement = 0.1;
               newfontSize += 1;
            } else {
               width = newWidth;
            }
         }
         res = Math.round(newfontSize)
      } else {
         res = fontSize
      }
      return res
   },

   getGoogleFonts(text, callback, fontsArray = null) {
      let allFonts = []
      // let newUrl = settings.api2
      this.getJSON(googleFontUrl, async (status, response) => {
         let favoritFont = [];
         if(fontsArray){
            favoritFont = [...fontsArray]
         } else {
            favoritFont = [
               'Lobster', 'Abril Fatface', 'Dancing Script', 'Anton', 'Parisienne', 'Ultra', 'Rajdhani', 'Notable',
               'DM Serif Display', 'Shrikhand', 'Arizonia', 'Rubik Mono One', 'Clicker Script', 'Josefin Sans', 'Yesteryear',
            ]
         }
         for(let index = 0; index < favoritFont.length; index++) {
            const font = favoritFont[index];
            let elem = Object.keys(response).find(elm => elm.toLocaleLowerCase === font.toLocaleLowerCase);
            if(!!elem) {
               let fontName = font.split(" ").join("+")
               let url = settings.api + fontName + '&display=swap'
               let defaultSettings = {
                  font_family: font,
                  font_name: font,
                  font_url: url,
                  width: 232,
               }
               let size = 0
               let svg = null
               let element = null
               let value = {}
               element = this.logoSvgMaket(defaultSettings, text)
               svg = document.querySelector('body').appendChild(element)


               const fontValue = () =>  new Promise((resolve) => {
                  this.loadFont(svg, url, (asd) => {
                     size = this.getfontSize(asd)
                     value = {
                        value: index, font: font, name: font, url: url, logo_font_size: size,
                     }
                     resolve(value)
                  })
               });
               value = await fontValue()
               document.querySelector('body').removeChild(element)
               // await new Promise((resolve) => {

               //    size = this.getfontSize(svg)

               //    return resolve(size)
               // });
               // const svg =  document.querySelector('body').appendChild(element)

               // if(index === 0) {
               //    newUrl = newUrl + `${ font.split(" ").join("+") }`
               // } else {
               //    newUrl = newUrl + `|${ font.split(" ").join("+") }`
               // }
               // after added favorit should be generate new url,
               //get newUrl value and change this file admin/views/my-site/design/site-logo/font.css

               await allFonts.push(value)
               //
            }

         }
         // if(200 === status) {
         //    Object.keys(response).forEach(async (font, index) => {
         //       if('icon' !== response[font].category && index < 100) {
         //          let fontName = font
         //          if('Material Icons' === font) {
         //          }
         //          if('Sunflower' === font || 'UnifrakturCook' === font || 'Buda' === font) {
         //             fontName = `${ fontName }:wght@300;700`
         //          }
         //          if('Molle' === font) {
         //             fontName = `${ fontName }:ital@1`
         //          }
         //          if('Zilla' === font) {
         //             fontName = `${ fontName }+Slab:wght@500&`
         //          }
         //          let url = settings.api + fontName + '&display=swap'
         //          let value = {
         //             value: index, font: font, name: font, url: url,
         //          }
         //          if(index === 0) {
         //             newUrl = newUrl + `${ font.split(" ").join("+") }`
         //          } else {
         //             newUrl = newUrl + `|${ font.split(" ").join("+") }`
         //          }
         //          await allFonts.push(value)
         //       }
         //    })
         // }
         callback(allFonts)
      })

   },

   loadFont(svg, url, callback) {
      const request = new XMLHttpRequest();
      if(!url) {
         return
      }
      request.open("get", url);
      request.responseType = "text";
      request.send();
      request.onloadend = () => {
         let css = request.response;
         const fontURLs = css.match(/https?:\/\/[^ \\)]+/g);
         let loaded = 0;
         fontURLs.forEach(url => {
            const request = new XMLHttpRequest();
            request.open("get", url);
            request.responseType = "blob";
            request.onloadend = () => {
               const reader = new FileReader();
               reader.onloadend = () => {
                  css = css.replace(new RegExp(url), reader.result);
                  loaded++;
                  if(loaded === fontURLs.length){
                     var style = document.createElement('style');
                     style.setAttribute('type', 'text/css');
                     style.innerHTML = css;
                     var defs = document.createElement('defs');
                     defs.appendChild(style);
                     if(svg) {
                        svg.insertBefore(defs, svg.firstChild);
                        callback(svg)
                     }
                  }
               };
               reader.readAsDataURL(request.response);
            };
            request.send();
         });
      };
   },

   async convertSvgToBase64(svg){
      const fontValue = () =>  new Promise((resolve) => {
         const request = new XMLHttpRequest();
         request.open("get", appIconFontStyles);
         request.responseType = "text";
         request.send();
         request.onloadend = (res) => {
            var style = document.createElement('style');
            style.setAttribute('type', 'text/css');
            style.innerHTML = request.response;
            var defs = document.createElement('defs');
            defs.appendChild(style);
            svg.insertBefore(defs, svg.firstChild);
            return resolve(svg)
         }
      });
      const res = await fontValue()
      let base64 = window.btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(res))))
      let url = 'data:image/svg+xml;base64,' + base64
      return url
   },

   faviconSvgMaket(settings, svgText){
      var xmlns = "http://www.w3.org/2000/svg";
      var boxWidth = 80;
      var boxHeight = 80;

      var svgElem = document.createElementNS(xmlns, "svg");
      svgElem.setAttributeNS(null, "viewBox", "0 0 " + 100 + " " + 100);
      svgElem.setAttributeNS(null, "width", boxWidth);
      svgElem.setAttributeNS(null, "height", boxHeight);
      var rect1 = document.createElementNS(xmlns, "rect");
      svgElem.appendChild(rect1);
      rect1.setAttributeNS(null, 'x', '0');
      rect1.setAttributeNS(null, 'y', '0');
      rect1.setAttributeNS(null, 'rx', '22px');
      rect1.setAttributeNS(null, 'ry', '22px');
      rect1.setAttributeNS(null, 'width', '100');
      rect1.setAttributeNS(null, 'height', '100');
      rect1.setAttributeNS(null,  'fill', settings.background);
      rect1.setAttributeNS(null,  'stroke',  settings.background);
      rect1.setAttributeNS(null,  'stroke-width',  1);

      var textNode = document.createTextNode(svgText);
      var text = document.createElementNS(xmlns, "text");
      text.appendChild(textNode)
      svgElem.appendChild(text);
      text.setAttributeNS(null, 'x', '50');
      text.setAttributeNS(null, 'y', '50');
      text.setAttributeNS(null, 'dx', settings.dx);
      text.setAttributeNS(null, 'dy', settings.dy);
      text.setAttributeNS(null, 'font-size', settings.font_size);
      text.setAttributeNS(null, 'font-family', settings.font_family);
      text.setAttributeNS(null,  'fill', settings.color);
      text.setAttributeNS(null,  'text-anchor', 'middle');
      text.setAttributeNS(null,  'dominant-baseline', 'central');

      return svgElem
   },
   logoSvgMaket(settings, svgText){
      var xmlns = "http://www.w3.org/2000/svg";
      var boxWidth = 232;
      var boxHeight = 56;

      var svgElem = document.createElementNS(xmlns, "svg");
      svgElem.setAttribute("xmlns", xmlns);
      svgElem.setAttribute("xmlns:xlink", 'http://www.w3.org/1999/xlink');
      svgElem.setAttributeNS(null, "viewBox", '0 0 100 100');
      svgElem.setAttributeNS(null, "width", boxWidth);
      svgElem.setAttributeNS(null, "max-width", 232);
      svgElem.setAttributeNS(null, "height", boxHeight);
      var textNode = document.createTextNode(svgText);
      var text = document.createElementNS(xmlns, "text");
      text.appendChild(textNode)
      text.setAttributeNS(null, 'x', '50');
      text.setAttributeNS(null, 'y', '50');
      text.setAttributeNS(null, 'dx', '0');
      text.setAttributeNS(null, 'font-size', settings.logo_font_size);
      text.setAttributeNS(null, 'font-family', settings.font_family);
      text.setAttributeNS(null, 'style', `font-family: ${ settings.font_family } !important`);
      text.setAttributeNS(null,  'fill', settings.color);
      text.setAttributeNS(null,  'text-anchor', 'middle');
      text.setAttributeNS(null,  'dominant-baseline', 'central');
      svgElem.appendChild(text);
      return svgElem
   },



}
