import * as types from './types';

// start chat settings

const fetchChatSettingsRequest = () => ({
   type: types.FETCH_CHAT_SETTINGS_REQUEST,
});
const fetchChatSettingsCompleted = data => ({
   type: types.FETCH_CHAT_SETTINGS_COMPLETED,
   payload: data,
});
const fetchChatSettingsFailed = error => ({
   type: types.FETCH_CHAT_SETTINGS_FAILED,
   payload: error,
});

const updateChatSettingsRequest = () => ({
   type: types.UPDATE_CHAT_SETTINGS_REQUEST,
});
const updateChatSettingsCompleted = data => ({
   type: types.UPDATE_CHAT_SETTINGS_COMPLETED,
   payload: data,
});
const updateChatSettingsFailed = error => ({
   type: types.UPDATE_CHAT_SETTINGS_FAILED,
   payload: error,
});
const clearSettingsErrorState = error => ({
   type: types.CLEAR_CHAT_SETTINGS_ERROR,
   payload: error,
});

// end chat settings

// start chat members list
const fetchChatMembersRequest = () => ({
   type: types.FETCH_CHAT_MEMBERS_REQUEST,
});
const fetchChatMembersCompleted = data => ({
   type: types.FETCH_CHAT_MEMBERS_COMPLETED,
   payload: data,
});
const fetchChatMembersFailed = error => ({
   type: types.FETCH_CHAT_MEMBERS_FAILED,
   payload: error,
});

const chatMembersFilterRequest = bool => ({
   type: types.CHAT_MEMBERS_FILTER_REQUEST,
   payload: bool,
});
const chatMembersFilterCompleted = (data, bool) => ({
   type: types.CHAT_MEMBERS_FILTER_COMPLETED,
   payload: { data, bool },
});
const chatMembersFilterFailed = error => ({
   type: types.CHAT_MEMBERS_FILTER_FAILED,
   payload: error,
});

// end chat members list

// start conversations

const fetchConversationsRequest = bool => ({
   type: types.FETCH_CHAT_CONVERSATIONS_REQUEST,
   payload: bool,
});
const fetchConversationsCompleted = data => ({
   type: types.FETCH_CHAT_CONVERSATIONS_COMPLETED,
   payload: data,
});
const fetchConversationsFailed = error => ({
   type: types.FETCH_CHAT_CONVERSATIONS_FAILED,
   payload: error,
});
const addRemoveConverstionItemAction = (data, search, hidden) => ({
   type: types.ADD_REMOVE_CONVERSATION_ITEM_ACTION,
   payload: { data, search, hidden },
});
// end conversations

// start conversation messages

const fetchConversationMessagesRequest = () => ({
   type: types.FETCH_CHAT_CONVERSATION_MESSAGES_REQUEST,
});
const fetchConversationMessagesCompleted = data => ({
   type: types.FETCH_CHAT_CONVERSATION_MESSAGES_COMPLETED,
   payload: data,
});
const fetchConversationMessagesFailed = error => ({
   type: types.FETCH_CHAT_CONVERSATION_MESSAGES_FAILED,
   payload: error,
});
const fetchNewConversationRequest = () => ({
   type: types.FETCH_CHAT_NEW_CONVERSATION_REQUEST,
});
const fetchNewConversationCompleted = data => ({
   type: types.FETCH_CHAT_NEW_CONVERSATION_COMPLETED,
   payload: data,
});
const fetchNewConversationFailed = error => ({
   type: types.FETCH_CHAT_NEW_CONVERSATION_FAILED,
   payload: error,
});

const fetchNewMessagesRequest = () => ({
   type: types.FETCH_NEW_MESSAGES_REQUEST,
});
const fetchNewMessagesCompleted = data => ({
   type: types.FETCH_NEW_MESSAGES_COMPLETED,
   payload: data,
});
const fetchNewMessagesFailed = error => ({
   type: types.FETCH_NEW_MESSAGES_FAILED,
   payload: error,
});
// end conversation messages

const fetchLikedMemberByMessageRequest = bool => ({
   type: types.FETCH_LIKED_MEMBER_BY_MESSSAGE_REQUEST,
   payload: bool,
});
const fetchLikedMemberByMessageCompleted = data => ({
   type: types.FETCH_LIKED_MEMBER_BY_MESSSAGE_COMPLETED,
   payload: data,
});
const fetchLikedMemberByMessageFailed = error => ({
   type: types.FETCH_LIKED_MEMBER_BY_MESSSAGE_FAILED,
   payload: error,
});

/////////////////////////////////////////////
const setChatConversationAction = data => ({
   type: types.SET_CHAT_CONVERSTION,
   payload: data,
});

const isMobileRightContent = (bool) => ({
   type: types.IS_MOBILE_RIGHT_CONTENT,
   payload: bool,
});
const setShowDkDialogue = (bool) => ({
   type: types.SET_SHOW_DESCTOP_DIALOGUE,
   payload: bool,
});

const desktopChatDialogueCompleted = (data, bool) => ({
   type: types.START_DESCTOP_DIALOGUE_COMPLETED,
   payload: { data, bool },
});
const setOpenDesktopDialogueAction = (bool) => ({
   type: types.SET_OPEN_DESCTOP_DIALOGUE,
   payload: bool,
});


const setOnlineUsersAction = (data, action) => ({
   type: types.SET_ONLINE_USERS_ACTIONS,
   payload: { data, action },
});
const setSocketConnectedAction = bool => ({
   type: types.SET_SOCKET_CONNECTED_ACTION,
   payload: bool,
});
const createConversation = (conversation, member, search) => ({
   type: types.CREATE_CONVERSATION,
   payload: { conversation, member, search },
});


const onLikeMessageAction = data => ({
   type: types.ON_LIKE_MESSAGE,
   payload: data,
});
const deleteMessageAction = data => ({
   type: types.ON_DELETE_MESSAGE,
   payload: data,
});
const addNewMessageAction = (data, search, dontNeedToAddConversationToList) => ({
   type: types.ON_ADD_MESSAGE,
   payload: { data, search, dontNeedToAddConversationToList },
});
const updateMessageAction = data => ({
   type: types.ON_UPDATE_MESSAGE,
   payload: data,
});
const updateConverstionAction = data => ({
   type: types.UPDATE_CONVERSATION_DATA,
   payload: data,
});
const setTyperAction = (data, isGroup, typers) => ({
   type: types.SET_TYPER_ACTION,
   payload: { data, isGroup, typers },
});
const setMuteMemberAction = info => ({
   type: types.SET_MUTE_MEMBER,
   payload: { info },
});
const fetchMemberByUuidAction = data => ({
   type: types.FETCH_MEMBER_BY_UUID,
   payload: data,
});
const setSoket = data => ({
   type: types.SET_SOKET,
   payload: data,
});
const clearChatInfo = () => ({
   type: types.CLEAR_CHAT_INFO,
});
const setBlockMemberAction = data => ({
   type: types.SET_BLOCK_MEMBER,
   payload: data,
});
const cancelNewConversation = () => ({
   type: types.CANCEL_NEW_CONVERSATION,
});
const updateUnreamPmMessageCountAction = count => ({
   type: types.UPDATE_UNREAD_PM_MESSAGE_COUNT,
   payload: count,
});
const updateOnlineUserUsername = data => ({
   type: types.UPDATE_ONLINE_USER_USERNAME,
   payload: data,
})
const setOpenDkChatNotification = data => ({
   type: types.SET_OPEN_DK_CHAT_NOTIFICATIONS,
   payload: data,
})
const updateChatConversationList = data => ({
   type: types.UPDATE_CHAT_CONVERSATION_LIST,
   payload: data,
})

const fetchingConversationStart = conversationId => ({
   type: types.FETCHING_CONVERSATION_START,
   payload: conversationId,
})
const fetchingConversationCompleted = conversation => ({
   type: types.FETCHING_CONVERSATION_COMPLETED,
   payload: conversation,
})
const fetchingConversationFailed = conversationId => ({
   type: types.FETCHING_CONVERSATION_FAILED,
   payload: conversationId,
})
const updateConversationFromListAction = (conversationId, data = {}) => ({
   type: types.UPDATE_CONVERSATION_FROM_LIST,
   payload: { conversationId, data },
})
const togglePinUnpinConversationCompleted = (conversationId, newConversationsList) => ({
   type: types.TOGGLE_PIN_UNPIN_CONVERSATION_COMPLETED,
   payload: { conversationId, newConversationsList },
})

const updateConversationsFilterAction = (updatedFields = {}, allFilters) => ({
   type: types.UPDATE_CONVERSATIONS_FILTER,
   payload: { updatedFields, allFilters },
})

const updateConversationMessagesByIdAction = (payload) => ({
   type: types.UPDATE_CONVERSATION_MESSAGES_BY_ID,
   payload,
})

const chatInitCompleted = (data) => ({
   type: types.CHAT_INIT_COMPLETED,
   payload: data,
})

export {
   fetchChatSettingsRequest,
   fetchChatSettingsCompleted,
   fetchChatSettingsFailed,
   updateChatSettingsRequest,
   updateChatSettingsCompleted,
   updateChatSettingsFailed,
   clearSettingsErrorState,
   //////////////////////////////
   fetchChatMembersRequest,
   fetchChatMembersCompleted,
   fetchChatMembersFailed,
   chatMembersFilterRequest,
   chatMembersFilterCompleted,
   chatMembersFilterFailed,
   /////////////////////////////
   fetchConversationsRequest,
   fetchConversationsCompleted,
   fetchConversationsFailed,
   addRemoveConverstionItemAction,
   /////////////////////////////
   fetchConversationMessagesRequest,
   fetchConversationMessagesCompleted,
   fetchConversationMessagesFailed,
   fetchNewConversationRequest,
   fetchNewConversationCompleted,
   fetchNewConversationFailed,
   fetchNewMessagesRequest,
   fetchNewMessagesCompleted,
   fetchNewMessagesFailed,

   /////////////////////////////
   fetchLikedMemberByMessageRequest,
   fetchLikedMemberByMessageCompleted,
   fetchLikedMemberByMessageFailed,
   /////////////////////////////
   setChatConversationAction,

   isMobileRightContent,
   setShowDkDialogue,
   desktopChatDialogueCompleted,
   setOpenDesktopDialogueAction,

   setOnlineUsersAction,
   setSocketConnectedAction,
   createConversation,
   onLikeMessageAction,
   deleteMessageAction,
   addNewMessageAction,
   updateMessageAction,
   updateConverstionAction,
   setTyperAction,
   setMuteMemberAction,

   fetchMemberByUuidAction,
   setSoket,
   clearChatInfo,
   setBlockMemberAction,
   cancelNewConversation,

   updateUnreamPmMessageCountAction,
   updateOnlineUserUsername,

   setOpenDkChatNotification,
   updateChatConversationList,

   fetchingConversationStart,
   fetchingConversationCompleted,
   fetchingConversationFailed,
   updateConversationFromListAction,
   togglePinUnpinConversationCompleted,
   updateConversationsFilterAction,
   updateConversationMessagesByIdAction,
   chatInitCompleted,
};
