import React from 'react'
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import DateFormatter from 'common/utils/DateFormatter';
import NumberFormatter from 'admin/utils/NumberFormatter';
import { getCountState } from 'common/utils/utils'
import './style.scss'
import cx from 'classnames'

const MediaChip = ({
   type,
   className,
   countOrDuration,
   name,
   photoIcon,
   isBlurBackground,
}) => {

   if(!countOrDuration){
      return null
   }
   let newCountOrDuration = countOrDuration;
   if('video' === type || type.includes('video')){
      newCountOrDuration = DateFormatter.getSecondsConversion(countOrDuration);
   } else {
      const numberFormatter = new NumberFormatter();
      newCountOrDuration = getCountState(numberFormatter.withCommas(countOrDuration), name, countOrDuration)
   }
   return (
      <div className={ cx({
         'flex items-center gap-1': true,
         [className]: !!className,
         'pl-1': type === 'video' && isBlurBackground,
         'bg-major-20 backdrop-blur-[10px] h-7 rounded py-[3px] px-2': isBlurBackground,

      }) }
      >
         {/* <div className='h-5 w-5 flex items-center justify-center mr-px'> */}
         <Icon
            name={ 'photoset' === type ? photoIcon : 'play-default' }
            color='major-dark'
            size={ 'photoset' === type ? 'base' : 'xl' }
         />
         {/* </div> */}
         <span className='text-major-dark text-sm font-medium leading-[18px]'>
            { newCountOrDuration }
         </span>
      </div>
   )
}
MediaChip.propTypes = {
   className: PropTypes.string,
   countOrDuration: PropTypes.any,
   type: PropTypes.string,
   name: PropTypes.string,
   photoIcon: PropTypes.string,
   isBlurBackground: PropTypes.bool,
};
MediaChip.defaultProps = {
   className: '',
   name: 'photo',
   photoIcon: 'several-images',
   isBlurBackground: true,
};

export default MediaChip
