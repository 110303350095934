const initialState = {
   isOpenPopup: false,
   isFetchingNotifications: true,
   isFetchingNewNotifications: false,
   isFetchingByFilterNotifications: false,
   isEmptyNotifications: false,
   isEmptyByFilterNotifications: false,
   unseenCount: 0,
   unreadCount: 0,
   list: {},
   replyModal: {
      type: 'comment',
      isOpen: false,
   },
   isOpenReplyComment: false,
   replyComments: {},
   replyChatMessage: {},
   notificationsPanel: {
      isOpen: false,
      animationClass: null,
   },
};

export default initialState;
