/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Webcam from "react-webcam";
import Modal from 'common/components/modules/modal';
import Button from 'common/components/elements/buttons/primary';
import PageHeader from 'common/components/modules/page-header';
import './style.scss';


const WebcamCapture = ({
   onChangeFile,
   onClose,
   title,
   type,
   constraints,
}) => {
   const mediaRecorderRef = useRef(null);
   const streamRef = useRef(null);
   const [capturing, setCapturing] = useState(false);
   const [disabled, setDisabled] = useState(true);
   const [recordedChunks, setRecordedChunks] = useState([]);
   const videoConstraints = {
      width: 482,
      height: 271,
      facingMode: "user",
      border: "1px solid #F2F4F7;",
      boxSizing: 'border-box',
   };
   const webcamRef = useRef(null);

   const capture = useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      if(window.localStream && window.localStream.getVideoTracks && window.localStream.getVideoTracks() && window.localStream.getVideoTracks()[0]){
         window.localStream.getVideoTracks()[0].stop();
      }
      onChangeFile(imageSrc);
   }, [webcamRef, onChangeFile]);

   const handleDataAvailable = useCallback(
      ({ data }) => {
         if(data.size > 0) {
            setRecordedChunks((prev) => {
               return prev.concat(data)
            });
         }
      },
      [setRecordedChunks]
   );

   const onSetVideo = useCallback(() => {
      const blob = new Blob(recordedChunks, {
         type: "video/webm",
      });
      if(!!window.localStream) {
         let tracks = window.localStream.getVideoTracks();
         if(tracks && tracks.length > 0){
            tracks.forEach(item => {
               item.stop();
            })
         }
      }
      const url = URL.createObjectURL(blob);
      setRecordedChunks([]);
      onChangeFile(url, blob)
   }, [recordedChunks, onChangeFile]);

   useEffect(() => {
      if(recordedChunks.length){
         onSetVideo()
      }
   }, [recordedChunks, onSetVideo])
   useEffect(() => {
      setDisabled(true);
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
         streamRef.current = stream;
         setDisabled(false);
      })
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const startRecord = useCallback(() => {
      setCapturing(true);
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
         type: "video/webm",
      });
      mediaRecorderRef.current.addEventListener(
         "dataavailable",
         handleDataAvailable
      );
      mediaRecorderRef.current.start();
   }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);



   const stopRecord = useCallback(() => {
      mediaRecorderRef.current.stop();
      setCapturing(false);
   }, [mediaRecorderRef, setCapturing]);

   const onCloseModal = () => {
      onClose(false);
      if(streamRef.current && streamRef.current.getVideoTracks && streamRef.current.getVideoTracks()){
         const tracks = streamRef.current.getVideoTracks();
         if(tracks && tracks.length > 0){
            tracks.forEach(item => {
               item.stop();
            })
         }
      }
   }
   return (
      <Modal
         contentPosition='center'
         onClose={ () => onCloseModal() }
         contentWidth='500px'
         isCloseAction={ false }
         roundedModal='lg'
      >
         <div>
            <PageHeader
               title={ title }
               leftIconName='close'
               leftIconAction={ () => onCloseModal() }
               isFixed={ false }
               isHelpIcon={ false }
               className='modal-header'
               fontIconSize='extraLarge'
            />
         </div>
         <div className='p-2 pb-0 w-full'>
            {
               type === 'video' ? (
                  <>
                     <Webcam
                        audio={ false }
                        ref={ webcamRef }
                        screenshotFormat='video'
                        style={ { border: "1px solid #F2F4F7", boxSizing: 'border-box', width: '100%' } }
                        videoConstraints={ videoConstraints }
                     />
                  </>
               ) : (
                  <Webcam
                     audio={ false }
                     ref={ webcamRef }
                     screenshotFormat='image/jpeg'
                     style={ { border: "1px solid #F2F4F7", boxSizing: 'border-box', width: '100%' } }
                     videoConstraints={ videoConstraints }
                  />
               )
            }
            <BottomContent
               capture={ capture }
               type={ type }
               capturing={ capturing }
               startRecord={ startRecord }
               stopRecord={ stopRecord }
               disabled={ disabled }
            />
         </div>
      </Modal>
   );
};

const BottomContent = ({
   type,
   capturing,
   capture,
   stopRecord,
   startRecord,
   disabled,
}) => {
   return (
      <div className='w-full py-4'>
         {
            type === 'image' ? (
               <div className='w-full flex items-center justify-center'>
                  <div className='w-10 h-10'>
                     <Button
                        text=''
                        fontWeight='bold'
                        textSize='base'
                        classNames='h-full py-3 font-medium'
                        borderRadius='large'
                        iconName='photo-camera'
                        iconPosition='left'
                        primaryColor
                        onClick={ capture }
                        disabled={ disabled }
                     />
                  </div>
               </div>
            ) : (
               <div className='w-full flex items-center justify-between px-2'>
                  <div className='flex items-center w-24'>
                     {
                        capturing && (
                           <>
                              <div className='w-2 h-2 rounded-full bg-error dark:bg-error-dark mr-1'></div>
                              <CountUpTimer />
                           </>
                        )
                     }
                  </div>
                  {
                     capturing ? (
                        <div
                           role='presentation'
                           onClick={ stopRecord }
                           className='w-10 h-10 cursor-pointer record-drop-shadow rounded-lg bg-panel dark:bg-panel-dark flex items-center justify-center'
                        >
                           <div className='w-4 h-4 rounded bg-error dark:bg-error-dark'>
                           </div>
                        </div>
                     ) : (
                        <div className='w-10 h-10'>
                           <Button
                              text=''
                              fontWeight='bold'
                              textSize='base'
                              classNames='h-full py-3 font-medium'
                              borderRadius='large'
                              iconName='video-camera'
                              iconPosition='left'
                              primaryColor
                              onClick={ startRecord }
                              disabled={ disabled }
                           />
                        </div>
                     )
                  }
                  <div className='w-24'></div>
               </div>
            )
         }
      </div>
   )
}

const CountUpTimer = () => {
   const [[hrs, mins, secs], setTime] = useState([0, 0, 0]);
   const tick = () => {
      if(secs !== 59){
         setTime([hrs, mins, secs + 1]);
      }
      if(secs === 59) {
         setTime([hrs, mins + 1, 0]);
      }
      if(mins === 59 && secs === 59) {
         setTime([hrs + 1, 0, 0]);
      }
   };

   useEffect(() => {
      const timerId = setInterval(() => tick(), 1000);
      return () => clearInterval(timerId);
   });


   return (
      <div className='flex items-center text-major dark:text-major-dark'>
         <span>
            {`
            ${ hrs > 0 ? hrs.toString().padStart(2, '0') + ':' : '' }${ mins
         .toString()
         .padStart(2, '0') }:${ secs.toString().padStart(2, '0') }`
            }
         </span>
      </div>
   );
}

WebcamCapture.defaultProps = {
   onChangeFile: () => {},
   title: 'Take photo',
   constraints: { video: true },
};

WebcamCapture.propTypes = {
   onChangeFile: PropTypes.func,
   onClose: PropTypes.func,
   title: PropTypes.string,
   type: PropTypes.string,
   constraints: PropTypes.object,
};

BottomContent.propTypes = {
   type: PropTypes.string,
   capturing: PropTypes.bool,
   capture: PropTypes.func,
   stopRecord: PropTypes.func,
   startRecord: PropTypes.func,
   disabled: PropTypes.bool,
};

export default WebcamCapture;




