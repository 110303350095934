import Evaporate from 'evaporate';
import sha256 from 'js-sha256';
import sparkMD5 from 'spark-md5';
import Auth from 'admin/utils/Auth';
import { getProtocol } from './utils';
const apiUrl = `${ getProtocol() }/${ process.env.REACT_APP_AMS_DOMAIN }/api/v2`;

const config = {
   signerUrl: `${ apiUrl }/get-s3-signature`,
   timeUrl: `${ apiUrl }/get-time`,
   aws_key: process.env.REACT_APP_AWS_S3_MULTIPART_UPLOAD_ACCESS_KEY_ID,
   bucket: process.env.REACT_APP_AWS_S3_BUCKET,
   cloudfront: true,
   computeContentMd5: true,
   awsRegion: process.env.REACT_APP_AWS_S3_REGION,
   s3Acceleration: 'production' === process.env.NODE_ENV,
   cryptoMd5Method: (d) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
   cryptoHexEncodedHash256: sha256,
   logging: false,
   signHeaders: {
      Authorization: () => {
         return `Bearer ${ Auth.getToken() }`;
      },
   },
};
let evaporateInstance = null;
const init = () => {
   return new Promise((resolve, reject) => {
      if(evaporateInstance) {
         resolve(evaporateInstance);
      } else {
         Evaporate.create(config).then((evaporate) => {
            evaporateInstance = evaporate;
            resolve(evaporateInstance);
         });
      }
   });
}

export {
   init,
}
