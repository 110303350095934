import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import  LiveChatNotifications  from 'admin/views/chat/notifications-list'
import { screenWidthSelector, siteInfoSelector, authUserSelector, isOnlineStreamSelector } from 'admin/state/modules/common/selectors';
import {
   fetchNewConversationOperation, fetchConversationsOperation, fetchConversationMessagesOperation,
} from 'admin/state/modules/chat/operations';
import {
   setSocketConnectedAction,
   desktopChatDialogueCompleted,
   setChatConversationAction,
   setShowDkDialogue,
   setOpenDkChatNotification,
   updateConverstionAction,
   updateUnreamPmMessageCountAction,
} from 'admin/state/modules/chat/actions';
import { setNotificationsPanelAction } from 'admin/state/modules/notifications/actions';
import { notificationsPanelSelector } from 'admin/state/modules/notifications/selectors';
import { currentLocationPathSelector } from 'admin/state/modules/router/selectors';
import { setLiveStreamConversationState } from 'admin/state/modules/livestream/actions';

import { push } from 'connected-react-router';
import { getVaultById } from 'admin/api/AuthApi';
import Router from 'admin/routes/router';

import {
   conversationsListSelector,
   groupConversationSelector,
   isFetchConversationSelector,
   isFetchNewConversationSelector,
   initedConverstionMessagesSelector,
   memberByUuidSelector,
   initedConversationSelector,
   activeConverstaionSelector,
   openDesctopDialogueSelector,
   openDkChatNotificationSelector,
} from 'admin/state/modules/chat/selectors';
import ChatHepler from 'common/utils/chat';
import QueryParams from 'admin/utils/QueryParams';
const IS_CURSOR_PAGINATION = ChatHepler.isCursorPagination();

class ChatNotificationsContainer extends Component {

   filter = {
      page: 1,
      sortBy: 'date:desc',
   }
   static propTypes = {
      desktopChatDialogue: PropTypes.func,
      goTo: PropTypes.func,
      conversationsList: PropTypes.any,
      screenWidth: PropTypes.number,
      isFetchConversation: PropTypes.bool,
      groupConversation: PropTypes.object,
      fetchNewConversation: PropTypes.func,
      isFetchNewConversation: PropTypes.bool,
      initedConverstionMessages: PropTypes.object,
      fetchMessages: PropTypes.func,
      setChatConversation: PropTypes.func,
      onlineUsers: PropTypes.array,
      authUser: PropTypes.object,
      onReadMessage: PropTypes.func,
      fetchConversations: PropTypes.func,
      initedConversation: PropTypes.bool,
      memberByUuid: PropTypes.object,
      setShowDkDialogue: PropTypes.func,
      openDesctopDialogue: PropTypes.bool,
      setNotificationsPanel: PropTypes.func,
      notificationsPanel: PropTypes.object,
      onlyPmList: PropTypes.bool,
      locationPath: PropTypes.string,
      isOnlineStream: PropTypes.bool,
      setLiveStreamConversationState: PropTypes.func,
      onClickChatItem: PropTypes.func,
      openDkChatNotification: PropTypes.bool,
      setOpenDkChatNotification: PropTypes.func,
      // updateConverstion: PropTypes.func,
      // updateMUnreamPmMessageCount: PropTypes.func,
   }

   socket = null


   componentDidMount(){
      if(QueryParams.getParam('conversation') && typeof +QueryParams.getParam('conversation') === 'number'){
         this.onClickChatItem({ chat_room_id: +QueryParams.getParam('conversation') })
      }
   }

   conversationsScroll = (e) => {
      const { scrollHeight, clientHeight, scrollTop } = e.target
      if(((scrollHeight - scrollTop) - clientHeight < 20)) {
         this.nextPage()
      }
   }
   nextPage =  () => {
      const {
         fetchNewConversation,
         isFetchNewConversation,
         conversationsList: { current_page: currentPage, last_page: pages, next_cursor: nextCursor },
      } = this.props;
      let query = [];
      if(IS_CURSOR_PAGINATION){
         if(nextCursor && !isFetchNewConversation){
            this.filter = { ...this.filter, cursor: nextCursor };
            query = this.generateQuery();
            fetchNewConversation(query);
         }
         return;
      }
      if(currentPage < pages && !isFetchNewConversation) {
         this.filter = { ...this.filter, page: currentPage + 1 };
         query = this.generateQuery();
         fetchNewConversation(query);
      }
      return
   }
   generateQuery = () => {
      const query = [];
      Object.keys(this.filter).forEach((key) => {
         if(IS_CURSOR_PAGINATION && 'page' === key){
            return;
         }
         if(!IS_CURSOR_PAGINATION && 'cursor' === key){
            return;
         }
         if(key === 'count' || key === 'page' || 'cursor' === key || key === 'sortBy') {
            query.push(`${ key }=${ this.filter[key] }`);
         } else {
            query.push(`query[]=${ key }=${ this.filter[key] }`)
         }
      })
      return query;
   }

   fetchChat = () => {
      const {
         fetchConversations,
         initedConversation,
         setShowDkDialogue,
         openDesctopDialogue,
         setNotificationsPanel,
         notificationsPanel: {
            isOpen,
         },
      } = this.props
      if(isOpen) {
         setNotificationsPanel({ isOpen: false });
      }
      if(openDesctopDialogue) {
         setShowDkDialogue(false)
      }
      fetchConversations([], false, initedConversation)
   }

   onClickChatItem = async (data) => {
      const {
         desktopChatDialogue,
         groupConversation,
         initedConverstionMessages,
         fetchMessages,
         setChatConversation,
         authUser,
         memberByUuid,
         onReadMessage,
         // activeConverstaion,

         locationPath,
         isOnlineStream,
         setLiveStreamConversationState,
         onClickChatItem,
         setOpenDkChatNotification,
         openDkChatNotification,
         // updateConverstion,
         // updateMUnreamPmMessageCount,
      } = this.props
      ChatHepler.inputState(false);
      const id = data.chat_room_id;
      if(openDkChatNotification){
         setOpenDkChatNotification(false);
      }

      // if(data?.chat_room_id) {
      //    updateConverstion({ conversationId: id, data: { unread_messages_count: 0 } })
      //    updateMUnreamPmMessageCount(-data?.unread_messages_count);
      // }

      if(!!Router.route('LIVE_STREAM_STUDIO').match(locationPath) && isOnlineStream) {
         setLiveStreamConversationState({
            showConversion: true,
            chatType: 'chat',
         })
      }
      if(id && initedConverstionMessages && !initedConverstionMessages[id]) {
         let ollContents = window.currentConversationContent ? JSON.parse(window.currentConversationContent) : {}
         let content = {}
         if(!!QueryParams.getParam('upload_video')) {
            if(ollContents[id] && ollContents[id].type !== 'video_upload'){
               try {
                  let { data } = await getVaultById(QueryParams.getParam('upload_video'))
                  content = {
                     resources: [],
                     src: [],
                     videoUpload: {
                        ...data,
                     },
                     type: 'upload_video',
                  }
                  ollContents = {
                     ...ollContents,
                     [id]: content,
                  }
                  window.currentConversationContent = JSON.stringify(ollContents)

               } catch (error) {
                  QueryParams.removeQueryParam('upload_video')
               }

            } else {
               QueryParams.removeQueryParam('upload_video')
            }
         }
         await fetchMessages(data, groupConversation,  { ...memberByUuid, authUser }, () => {
            const id = data.chat_room_id
            if(id && data.unread_messages_count && data.unread_messages_count > 0) {
               onReadMessage(id)
            }
            desktopChatDialogue({  isOpen: true, ...data }, true)
            const tooltipItem = document.querySelector('#admin-chat-notification')
            tooltipItem.classList.remove('show')
            ChatHepler.inputState()
            ChatHepler.syncDialogueScroll(null, data.chat_room_id)
         })
      } else {
         // const dialogue = document.querySelector(`#messages-content-${ activeConverstaion.chat_room_id }`)
         // if(window.scrolledPosition && dialogue) {
         //    window.scrolledPosition[activeConverstaion.chat_room_id] = dialogue ? dialogue.scrollTop : null
         // }
         let ollContents = window.currentConversationContent ? JSON.parse(window.currentConversationContent) : {}
         let content = {
            content: ollContents[data.chat_room_id],
         }
         // if(ollContents[data.chat_room_id]) {
         //    content = { content: ollContents[data.chat_room_id] }
         //    if(ollContents[data.chat_room_id].type === 'upload_video'){
         //       const { videoUpload } = ollContents[data.chat_room_id]
         //       QueryParams.addQueryParam('upload_video', videoUpload.id)
         //    }

         // } else if(!!QueryParams.getParam('upload_video')) {
         //    QueryParams.removeQueryParam('upload_video')
         // }
         await setChatConversation({ ...data, ...content })
         desktopChatDialogue({  isOpen: true, ...data }, true)
         const tooltipItem = document.querySelector('#admin-chat-notification')
         if(!!tooltipItem){
            tooltipItem.classList.remove('show')
         }
         ChatHepler.inputState()
         ChatHepler.syncDialogueScroll(null, data.chat_room_id)
         const id = data.chat_room_id
         if(id && data.unread_messages_count && data.unread_messages_count > 0) {
            onReadMessage(id)
         }
      }
      if(QueryParams.getParsedSearchParams().conversation !== data.chat_room_id) {
         QueryParams.addQueryParam('conversation', data.chat_room_id)
      }

      if(onClickChatItem) {
         onClickChatItem(data)
      }
   }

   render(){
      const {
         conversationsList,
         screenWidth,
         goTo,
         groupConversation,
         isFetchConversation,
         isFetchNewConversation,
         onlineUsers,
         authUser,
         // activeConverstaion,
         onlyPmList,
         openDkChatNotification,
      } = this.props
      return (
         <LiveChatNotifications
            list={ !isFetchConversation ? conversationsList.data : [] }
            onClickChat={  (data) => this.onClickChatItem(data) }
            isMobile={ screenWidth < 1025 }
            gotToChatList={ () => {
               goTo(Router.route('CHAT').getCompiledPath());

            } }
            groupConversation={ groupConversation }
            isFetchConversation={ isFetchConversation }
            isFetchNewConversation={ isFetchNewConversation }
            onScrollList={ this.conversationsScroll }
            onlineUsers={ onlineUsers }
            authUser={ authUser }
            openChatDropDown={ this.fetchChat }
            onlyPmList={ onlyPmList }
            openDkChatNotification={ openDkChatNotification }
         />

      )
   }
}

const mapStateToProps = (state) => {
   return {
      screenWidth: screenWidthSelector(state),
      authUser: authUserSelector(state),
      siteInfo: siteInfoSelector(state),
      conversationsList: conversationsListSelector(state),
      groupConversation: groupConversationSelector(state),
      isFetchConversation: isFetchConversationSelector(state),
      isFetchNewConversation: isFetchNewConversationSelector(state),
      initedConverstionMessages: initedConverstionMessagesSelector(state),
      memberByUuid: memberByUuidSelector(state),
      initedConversation: initedConversationSelector(state),
      activeConverstaion: activeConverstaionSelector(state),
      openDesctopDialogue: openDesctopDialogueSelector(state),
      notificationsPanel: notificationsPanelSelector(state),
      isOnlineStream: isOnlineStreamSelector(state),
      locationPath: currentLocationPathSelector(state),
      openDkChatNotification: openDkChatNotificationSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      desktopChatDialogue: (data, bool) => dispatch(desktopChatDialogueCompleted(data, bool)),
      goTo: (location) => {
         dispatch(push(location));
      },
      fetchNewConversation: (query, inited) => dispatch(fetchNewConversationOperation(query, false, inited)),
      setSocketConnectedAction: (bool) => dispatch(setSocketConnectedAction(bool)),
      fetchConversations: (...arg) => dispatch(fetchConversationsOperation(...arg)),
      fetchMessages: (chat, group, member, callback) => dispatch(fetchConversationMessagesOperation(chat, group, member, callback, false)),
      setChatConversation: (data) => dispatch(setChatConversationAction(data)),
      setShowDkDialogue: (data) => dispatch(setShowDkDialogue(data)),
      setNotificationsPanel: data => dispatch(setNotificationsPanelAction(data)),
      setLiveStreamConversationState: data => dispatch(setLiveStreamConversationState(data)),
      setOpenDkChatNotification: (bool) => dispatch(setOpenDkChatNotification(bool)),
      updateConverstion: data => dispatch(updateConverstionAction(data)),
      updateMUnreamPmMessageCount: (count) => dispatch(updateUnreamPmMessageCountAction(count)),

   }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChatNotificationsContainer);
