const initialState = {
   isFetching: true,
   data: [],
   errors: {},
   isEmpty: false,
   modal: {
      type: null,
      isOpen: false,
      buttonDisabled: false,
      item: null,
   },
};

export default initialState;
