import React from 'react'
import PropTypes from 'prop-types'
import Modal from "common/components/modules/modal";
import Button from 'common/components/elements/buttons/primary';
import Avatar from '../../avatar';
// import ChatItemLoading from 'common/components/modules/chat/list-loading';

const ChatLikesModal = ({
   isMobile,
   chatLikesModalTogglehandler,
   headerText,
   fetching,
   onScroll,
   members,
   newFetching,
   onlineMembersList,
   adminUuid,
}) => {
   const contentPosition = !isMobile ? "center" : "bottom"
   return (
      <>
         <Modal
            onClose={ chatLikesModalTogglehandler }
            contentPosition={ contentPosition }
            isCloseAction={ false }
         >
            <div className='flex flex-col'  >
               <div className='flex border-b items-center py-3 px-4'>
                  <div className='w-6 h-6 mr-4' >
                     <Button
                        iconName='close'
                        fontIconColor='major'
                        darkIconcolor='major-dark'
                        backgroundColor='transparent'
                        fontIconSize='doubleLarge'
                        padding={ 0 }
                        onClick={ chatLikesModalTogglehandler }
                        classNames='h-full w-full'
                     />
                  </div>
                  <span className='text-base leading-tight text-major dark:text-major-dark'>{headerText}</span>
               </div>
               {/* {
                  onlineUsersfetching.isFetching && (
                     <ChatItemLoading
                        type='online_member'
                        count={ onlineUsersfetching.fetchingCount || 3 }
                     />
                  )
               } */}
               <div
                  onScroll={ () => {
                     if(!!onScroll) {
                        onScroll()
                     }
                  } }
                  style={ { maxHeight: "350px" } } className='overflow-visible overflow-y-auto mt-1 py-2'>
                  {
                     !!fetching ? (
                        <div className='flex h-full w-full items-center justify-center'>Loding </div>
                     ) : (
                        <>
                           {
                              members.map((member, index) => (
                                 <div
                                    key={ member.id || index.toString() }
                                    className='flex items-center py-1 px-4'>
                                    <div className='w-10 h-10 flex items-center justify-center overflow-hidden rounded-full mr-4'>
                                       {
                                          (adminUuid && member?.uuid === adminUuid) ? (
                                             <img src={ member.avatar_compressed_src  || member.avatar } alt='' className='h-full w-full object-center object-cover' />
                                          ) : (
                                             <Avatar
                                                avatarSrc={ member.avatar_compressed_src  || member.avatar }
                                                username={ member.username }
                                             />
                                          )
                                       }
                                    </div>
                                    <span className='leading-tight text-major dark:text-major-dark'>{member.username}</span>

                                    {
                                       (onlineMembersList || member.is_online) && (
                                          <span className='w-2 h-2 rounded-full bg-success dark:bg-success-dark blovk ml-2' />
                                       )
                                    }
                                 </div>
                              ))
                           }
                           {
                              members.length === 0 && (
                                 <div className='py-10 flex items-center justify-center'>
                                    <span className='text-placeholder leading-tight text-center dark:text-placeholder-dark'>
                                       No one is online in the moment.
                                    </span>
                                 </div>
                              )
                           }
                           {
                              newFetching && (
                                 <div className='flex h-full w-full items-center justify-center dark:text-major-dark'>Loding </div>
                              )
                           }
                        </>
                     )

                  }
               </div>
            </div>
         </Modal>
      </>
   )
}

ChatLikesModal.propTypes = {
   isMobile: PropTypes.bool,
   chatLikesModalTogglehandler: PropTypes.func,
   headerText: PropTypes.string,
   fetching: PropTypes.bool,
   onScroll: PropTypes.func,
   members: PropTypes.array,
   newFetching: PropTypes.bool,
   onlineMembersList: PropTypes.bool,
   adminUuid: PropTypes.string,
}

ChatLikesModal.defaultProps = {
   members: [],
}

export default ChatLikesModal;

