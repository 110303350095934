import React, { useEffect, useRef } from 'react'
import './style.scss'
import cx from 'classnames'
import { PropTypes } from 'prop-types'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import Icon from 'common/components/elements/icons'

const DragHandle = sortableHandle(({ isRotate, type, disabledState, tooltiptext }) =>  {
   return (
      <div
         className={
            cx({
               'absolute w-full h-full  z-[15]  rounded-lg flex items-center justify-center drag-cursor bg-major-60 ': true,
               '!rotate-2': isRotate,
               '!h-[calc(100%-48px)] rounded-br-none rounded-bl-none': type === 'photosets' || type === 'store' || type === 'videos',
               '!h-[calc(100%-84px)]': type === 'store',
               '!cursor-default': disabledState,
               'left-[1.5px]': isRotate && type !== 'stories',
            })
         }
         data-tooltip-id='ams-top-tooltip'
         data-tooltip-content={ disabledState ? `Unable to reorder ${ tooltiptext } content` : null }
         data-tooltip-offset={ 11 }
      >
         {
            !disabledState &&
            <div className='flex flex-row gap-1 items-center'>
               <Icon
                  name='drag'
                  size='2xl'
                  color='major-dark'
               />
               <p className='text-lg text-major-dark'>Drag to reorder</p>
            </div>
         }
         {
            (type === 'photosets' || type === 'store' || type === 'videos') && (
               <div
                  className={
                     cx({
                        'absolute  w-full ': true,
                        'h-12 -bottom-12': type !== 'store',
                        '-bottom-[84px] h-[84px]': type === 'store',
                     })
                  }
               >
               </div>
            )
         }
      </div>
   )

});

export const SortableItem = sortableElement(({ children, className, isRotate, type, isReorderMode, disabledState, tooltiptext }) => {
   return (
      <div
         className={ className }
      >
         {
            isReorderMode &&
            <DragHandle
               isRotate={ isRotate }
               type={ type }
               disabledState={ disabledState }
               tooltiptext={ tooltiptext }
            />
         }
         {children}
      </div>
   ) });

const SortableContainer = sortableContainer(({ children, className, containerRef }) => {
   return <div
      ref={ containerRef }
      className={ className }>
      {children}
   </div>
})

function ReorderingContent({
   contentType,
   data,
   onSortEnd,
   children,
   setDraggableItem,
   isInsideModal,
   screenWidth,
}) {

   const containerRef = useRef(null)

   const onSortEnding = ({ oldIndex, newIndex }) => {
      const newData = arrayMoveImmutable(data, oldIndex, newIndex)

      if(oldIndex !== newIndex){
         onSortEnd({ newData, newIndex })
      }
      setDraggableItem(null)

   }

   useEffect(() => {
      if(isInsideModal && !!containerRef) {
         let mainElementId = `select-from-${ contentType || 'vaults' }`;
         // let filtersContentId = `${ contentType }-filters-container`
         let element = document.getElementById(mainElementId);
         // let filtersContent = document.getElementById(filtersContentId);
         if(!element) return
         // console.log(filtersContentId);
         const paddingRightValue =
            containerRef.current.offsetHeight > element.getBoundingClientRect().height
               ? screenWidth < 701 ? '0px' : '8px'
               : '24px';

         // const filtersRightPadding = containerRef?.current?.offsetHeight > element.getBoundingClientRect().height && screenWidth < 1025 ? '8px' : '24px'

         // filtersContent.style.paddingRight = filtersRightPadding
         containerRef.current.style.paddingRight = paddingRightValue;
      }
   }, [isInsideModal, contentType, data, containerRef, screenWidth]);

   return (
      <SortableContainer
         helperClass='sortable-helper'
         useDragHandle
         axis='xy'
         useWindowAsScrollContainer
         onSortEnd={ onSortEnding }
         updateBeforeSortStart={ (e) => {
            setDraggableItem(e.index)
         } }
         className={
            cx({
               'w-full px-6 max-[700px]:px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 select-none cards-grid': contentType === 'stories',
               'w-full my-6 px-6 max-[700px]:px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4': contentType === 'videos' ||  contentType === 'photosets' || contentType === 'recorded_streams',
               'grid my-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 xxl:grid-cols-4 xxl:gap-8 px-4 sm:px-6 ': contentType === 'store',
               'grid mt-0 my-6 !grid-cols-1 sm:!grid-cols-2 xl:!grid-cols-3 gap-5 px-4 sm:px-6 ': isInsideModal,
            })
         }
         containerRef={ containerRef }
      >
         {children && children}
      </SortableContainer>
   )
}

ReorderingContent.propTypes = {
   contentType: PropTypes.string,
   data: PropTypes.object,
   onSortEnd: PropTypes.func,
   children: PropTypes.any,
   setDraggableItem: PropTypes.func,
   isInsideModal: PropTypes.bool,
   screenWidth: PropTypes.number,
}

ReorderingContent.defaultProps = {
   isInsideModal: false,
}

export default ReorderingContent
