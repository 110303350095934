import React from 'react';
import Icon from 'common/components/elements/icons';
import PropTypes from 'prop-types';
import './style.scss';

const MemberTagItem = ({
   emoji,
   name,
   isShowRemoveButton,
   onRemoveTagToMember,
   insideChat,
   isListCard,
   tagId,
   onFilterShowResult,
   isShowPage,
   setHoverBg,
   classNames,
   iconColor,
}) => {
   return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <div className={ `member-tag-item${ insideChat ? '-inside-chat' : isShowPage || isListCard ? '-list' : '' } select-none h-[32px] rounded-full flex items-center border px-2 bg-panel dark:bg-panel-dark group hover:bg-hover dark:hover:bg-hover-dark ${ name?.length > 10 ? 'truncate' : '' } ${ classNames }` }
         role='presentation'
         onClick={ (e) => {
            e.stopPropagation()
            e.preventDefault()
            !!onFilterShowResult && onFilterShowResult(String(tagId), "member_tags")
         } }
         onFocus={ _ => {} }
         onMouseOver={ () => setHoverBg(false) }
         onMouseLeave={ () => setHoverBg(true) }
         data-tooltip-content={ `${ name }` }
         data-tooltip-id='ams-bottom-tooltip'
      >
         <div className='member-tag-item-icon h-4 w-4 flex items-center justify-center' >
            <span className='text-xs w-full h-full' aria-label='image' role='img'> { emoji } </span>
         </div>
         <span className={ `member-tag-item-name text-major dark:text-major-dark ${ name?.length > 10 ? 'truncate' : '' }  ml-2 text-xs leading-[18px] font-normal truncate group-hover:underline group-hover:cursor-pointer` }

         > { name } </span>
         {
            !!isShowRemoveButton && (
               <Icon
                  name='cancel'
                  size='base'
                  color={ iconColor }
                  className='ml-1'
                  onClick={ (e) => {
                     e.stopPropagation()
                     onRemoveTagToMember()
                  } }
               />
            )
         }
      </div>
   );
};




MemberTagItem.propTypes = {
   emoji: PropTypes.string,
   name: PropTypes.string,
   isShowRemoveButton: PropTypes.bool,
   onRemoveTagToMember: PropTypes.func,
   insideChat: PropTypes.bool,
   isListCard: PropTypes.bool,
   tagId: PropTypes.number,
   onFilterShowResult: PropTypes.func,
   isShowPage: PropTypes.bool,
   setHoverBg: PropTypes.func,
   classNames: PropTypes.string,
   iconColor: PropTypes.string,
};

MemberTagItem.defaultProps = {
   iconColor: 'placeholder',
   isShowRemoveButton: false,
   insideChat: false,
   isListCard: false,
};

export default React.memo(MemberTagItem);
