import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_DETAILS]: (state, action) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetching: false,
         data: payload,
         isEmpty: payload.data.length === 0,
         openStatusMod: false,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.DELETE_CUSTOM_PAGE]: (state, action) => {
      const  { payload } = action;
      const newData = [...state.data.data].filter(element => element.id !== payload)
      return {
         ...state,
         data: {
            ...state.data,
            data: newData,
         },
         isEmpty: newData.length === 0,
         optionsModal: {
            type: null,
            isOpen: false,
            buttonDisabled: false,
         },
      }
   },

   [types.CREATE_NEW_FETCHING]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isCreateFetching: payload,
         errors: {},
      }
   },

   [types.CREATE_NEW_FAILED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         errors: payload.data,
         isCreateFetching: false,
      }
   },
   [types.UPDATE_DATA_START]: (state, action) => {
      return {
         ...state,
         isFetchingUpdate: true,
      }
   },
   [types.UPDATE_DATA_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         customPageById: {
            ...state.customPageById,
            ...payload,
         },
         isFetchingUpdate: false,
         errors: {},
         openStatusMod: false,
      }
   },

   [types.UPDATE_DATA_FAILED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         errors: payload.data,
         isFetchingUpdate: false,
         openStatusMod: !!payload.data.publish_date,
      }
   },
   [types.SET_OPTIONS_MODAL]: (state, action) => {
      const  { payload } = action;

      return {
         ...state,
         optionsModal: {
            ...state.optionsModal,
            ...payload,
         },
      }
   },
   [types.FETCH_DETAILS_BY_ID_START]: (state, action) => {
      const  { payload } = action;

      return {
         ...state,
         isFetchById: payload,
      }
   },
   [types.FETCH_DETAILS_BY_ID_COMPLETED]: (state, action) => {
      const  { payload } = action;
      let customPage = payload;
      let galleryImages = [];
      [...payload.gallery_images].forEach((element, index) => {
         galleryImages.push({ src: element, index: index, full_src: payload.gallery_images_full_src[index] })
      });
      customPage.gallery_images = galleryImages;
      return {
         ...state,
         customPageById: payload,
         isFetchById: false,
      }
   },
   [types.FETCH_NEW_DATA]: (state, action) => {
      const  { payload } = action;

      return {
         ...state,
         isNewFetching: payload,
      }
   },
   [types.FETCH_NEW_DATA_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         data: {
            ...payload,
            data: [...state.data.data, ...payload.data],
         },
         isNewFetching: false,
      }
   },
   [types.CLEAR_ERRORS_ACTION]: (state, action) => {
      return {
         ...state,
         errors: {},
      }
   },

};

export default createReducer(initialState)(reducersMap);
