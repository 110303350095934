import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';

const WaveformUnlockContent = ({
   price,
   onClickUnlockButton,
}) => {
   return (
      <div
         role='presentation'
         onClick={ (e) => {
            onClickUnlockButton()
            e.stopPropagation()
         } }
         className='flex flex-1 h-full justify-between items-center cursor-pointer !w-fit'>
         <div className='flex h-4 w-4'>
            {/* <Icon name='lock' color='[#344054]' darkcolor='major-dark' /> */}
            <Icon name='lock' color='secondary' />
         </div>
         <div className='flex h-4 w-4 mx-2'>
            {/* <Icon name='mic' color='[#344054]' darkcolor='major-dark'  /> */}
            <Icon name='mic' color='secondary'  />
         </div>
         {/* <span className='text-sm text-[#344054] dark:text-major-dark leading-20px'>Unlock for {price} USD</span> */}
         <span className='text-sm text-secondary dark:text-major-dark leading-20px'>Unlock for {price} USD</span>
      </div>

   )
}
WaveformUnlockContent.propTypes = {
   onClickUnlockButton: PropTypes.func,
   price: PropTypes.any,
}
export default WaveformUnlockContent;
