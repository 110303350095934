import * as types from "./types";

// delete vault
const deleteRequest = () => ({
   type: types.DELETE_START,
});
const deleteCompleted = (id, type) => ({
   type: types.DELETE_COMPLETED,
   payload: { id, type },
});
const deleteFailed = error => ({
   type: types.DELETE_FAILED,
   payload: {
      error,
   },
});


// Images actions
const fetchImagesRequest = () => ({
   type: types.FETCH_IMAGES_REQUEST_START,
});
const fetchImagesRequestCompleted = data => ({
   type: types.FETCH_IMAGES_REQUEST_COMPLETED,
   payload: data,
});
const fetchImagesRequestFailed = error => ({
   type: types.FETCH_IMAGES_REQUEST_FAILED,
   payload: {
      error,
   },
});

const fetchImagesByFilterRequest = () => ({
   type: types.FETCH_IMAGES_BY_FILTER_START,
});
const fetchImagesByFilterCompleted = data => ({
   type: types.FETCH_IMAGES_BY_FILTER_COMPLETED,
   payload: data,
});
const fetchImagesByFilterFailed = error => ({
   type: types.FETCH_IMAGES_BY_FILTER_FAILED,
   payload: {
      error,
   },
});

const fetchNewImagesRequest = () => ({
   type: types.FETCH_NEW_IMAGES_REQUEST_START,
});
const fetchNewImagesRequestCompleted = data => ({
   type: types.FETCH_NEW_IMAGES_REQUEST_COMPLETED,
   payload: data,
});
const fetchNewImagesRequestFailed = error => ({
   type: types.FETCH_NEW_IMAGES_REQUEST_FAILED,
   payload: {
      error,
   },
});

const uploadPhotos = (data, status, byHash) => ({
   type: types.CHANGE_VAULT_DATA,
   payload: { data, status, byHash },
});


// videos actions
const fetchVideosRequest = () => ({
   type: types.FETCH_VIDEOS_REQUEST_START,
});
const fetchVideosRequestCompleted = (data, isInsideModal) => ({
   type: types.FETCH_VIDEOS_REQUEST_COMPLETED,
   payload: { data, isInsideModal },
});
const fetchVideosRequestFailed = error => ({
   type: types.FETCH_VIDEOS_REQUEST_FAILED,
   payload: {
      error,
   },
});

const fetchVideosByFilterRequest = () => ({
   type: types.FETCH_VIDEOS_BY_FILTER_START,
});
const fetchVideosByFilterCompleted = data => ({
   type: types.FETCH_VIDEOS_BY_FILTER_COMPLETED,
   payload: data,
});
const fetchVideosByFilterFailed = error => ({
   type: types.FETCH_VIDEOS_BY_FILTER_FAILED,
   payload: {
      error,
   },
});

const fetchNewVideosRequest = () => ({
   type: types.FETCH_NEW_VIDEOS_REQUEST_START,
});
const fetchNewVideosRequestCompleted = data => ({
   type: types.FETCH_NEW_VIDEOS_REQUEST_COMPLETED,
   payload: data,
});
const fetchNewVideosRequestFailed = error => ({
   type: types.FETCH_NEW_VIDEOS_REQUEST_FAILED,
   payload: {
      error,
   },
});

const removeFile = (id) => ({
   type: types.REMOVE_FILE,
   payload: { id },
});

const addVideos = (data) => ({
   type: types.ADD_VIDEOS,
   payload: data,
});

const updateVideo = (id, data, byHash) => {
   return {
      type: types.UPDATE_VIDEO,
      payload: { id, data, byHash },
   };
}

const setInsideModalAction = (bool) => {
   return {
      type: types.SET_INSIDE_MODAL,
      payload: bool,
   };
}

// all vault

const fetchAllVaultRequest = () => ({
   type: types.FETCH_ALL_VAULT_REQUEST_START,
});

const fetchAllVaultRequestCompleted = (data, isInsideModal) => ({
   type: types.FETCH_ALL_VAULT_REQUEST_COMPLETED,
   payload: { data, isInsideModal },
});

const fetchAllVaultRequestFailed = error => ({
   type: types.FETCH_ALL_VAULT_REQUEST_FAILED,
   payload: error,
});

const fetchAllVaultByFilterRequest = () => ({
   type: types.FETCH_ALL_VAULT_BY_FILTER_START,
});

const fetchAllVaultByFilterCompleted = data => ({
   type: types.FETCH_ALL_VAULT_BY_FILTER_COMPLETED,
   payload: data,
});

const fetchAllVaultByFilterFailed = error => ({
   type: types.FETCH_ALL_VAULT_BY_FILTER_FAILED,
   payload: {
      error,
   },
});

const fetchNewVaultsStart = () => ({
   type: types.FETCH_NEW_VAULTS_REQUEST_START,
});

const fetchNewVaultsCompleted = data => ({
   type: types.FETCH_NEW_VAULTS_REQUEST_COMPLETED,
   payload: data,
});

const fetchNewVaultsFail = () => ({
   type: types.FETCH_NEW_VAULTS_REQUEST_FAILED,
});

const updateBundleStateInData = (data, isDetailsPage) => {
   return ({
      type: types.UPDATE_BUNDLE_STATE_IN_DATA,
      payload: { data, isDetailsPage },
   })
}
const updateVaultMessageAction = (data) => {
   return ({
      type: types.UPDATE_VAULT_MESSAGE_ACTION,
      payload: data,
   })
}

const clearStateAction = (payload) => {
   return ({
      type: types.CLEAR_STATE,
      payload,
   })
}

export {
   deleteRequest,
   deleteCompleted,
   deleteFailed,

   // images
   fetchImagesRequest,
   fetchImagesRequestCompleted,
   fetchImagesRequestFailed,

   fetchImagesByFilterRequest,
   fetchImagesByFilterCompleted,
   fetchImagesByFilterFailed,

   fetchNewImagesRequest,
   fetchNewImagesRequestCompleted,
   fetchNewImagesRequestFailed,

   uploadPhotos,

   // videos
   fetchVideosRequest,
   fetchVideosRequestCompleted,
   fetchVideosRequestFailed,

   fetchVideosByFilterRequest,
   fetchVideosByFilterCompleted,
   fetchVideosByFilterFailed,

   fetchNewVideosRequest,
   fetchNewVideosRequestCompleted,
   fetchNewVideosRequestFailed,

   removeFile,
   addVideos,
   updateVideo,
   setInsideModalAction,

   // all vault
   fetchAllVaultRequest,
   fetchAllVaultRequestCompleted,
   fetchAllVaultRequestFailed,
   fetchAllVaultByFilterRequest,
   fetchAllVaultByFilterCompleted,
   fetchAllVaultByFilterFailed,
   fetchNewVaultsStart,
   fetchNewVaultsCompleted,
   fetchNewVaultsFail,
   updateBundleStateInData,
   updateVaultMessageAction,

   clearStateAction,
};
