import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import cx from 'classnames';
import { EditPriceButtons, EditPriceContent } from './price';
import WarningModal from 'common/components/modules/chat/content-warning-modal';
import Waveform from 'common/components/modules/audio-player';
import GenerateColor from 'common/utils/generateTextColor.js'
import { getDuration } from 'common/utils/audioRecording';
import toast from 'common/utils/toast';
import TruncateText from 'common/components/elements/truncate-text';
import Icon from 'common/components/elements/icons';


const VoiceMessage = ({
   isMobile,
   maxPrice = 100,
   onStop,
   onCancel,
   duration,
   audioAsblob,
   audioSrc,
   onSend,
   disabledSendTo,
   withoutPricingContnet,
   defaultVoicePrice,
   onOpenMicSettings,
   withoutMicSettings,
   isLoadingSendBtn,
   replyMessage,
   isAdmin,
   showNickname,
   canelReplyMessage,
   authUser,
   setReplyData = () => {},
   isSavedReplies,
   repliesModalState,
   replyData,
   onUpdateRecordStatus,
   setMessageType = () => {},
   durationInfo,
   showNewStylesInField,
   isOpenEditContent,
   setIsOpenEditContent,
   priceDetails,
   setPriceDetails,
}) => {
   const [status, setStatus] = useState('progress');
   const [error, setError] = useState(null);
   const [showWarningModal, setShowWarningModal] =  useState(false);
   // const [price, setPrice] =  useState();
   // const [isOpenEditContent, setIsOpenEditContent] =  useState(false);

   const leavChatConversation = (e) => {
      const data = e?.detail.payload;
      const audio_recorded_status = data?.audio_recorded_status;
      switch (audio_recorded_status) {
         case 'start':
            onStop();
            break;
         case 'stop':
            // onStop();
            break;
         case 'limit_reached':
            onStop(duration)
            setStatus('complited');
            break;
         default:
            onCancel(true);
            break;
      }
   }
   useEffect(() => {
      window.addEventListener('leav-chat-conversation', leavChatConversation)
      return () => {
         window.removeEventListener('leav-chat-conversation', leavChatConversation)

      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {

      if(isSavedReplies) {
         setMessageType('voice')
      }

      if(replyData) {
         if(replyData?.voice_message_data) {
            setStatus('complited')
         }
         if(replyData?.voice_message_price) {
            setPriceDetails({
               price: replyData?.voice_message_price,
               make_default_price_for_voice_messages: !!replyData?.voice_message_make_default_price_for_voice_messages,
            });
            setIsOpenEditContent(false);
            setError(null)

         }

      }

      return () => {
         if(isSavedReplies) {
            onUpdateRecordStatus()
            setMessageType(null)
         }
      }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const handleSave = () => {
      let val = priceDetails?.price;
      let invalidPrice = val < 0.25 || val > maxPrice || val === ''

      if(!invalidPrice){
         const dontShow = +localStorage.getItem('dont_show_chat_coast_warning_modal')
         if(val <= 4 && !dontShow) {
            setShowWarningModal(true)
         } else {
            savePrice()
         }
      } else {
         setError(`Price must be between 0.25 and ${ maxPrice } USD`)
      }
   }

   const savePrice = () => {
      console.log('savePrice', { priceDetails });

      setReplyData({
         [`voice_message_make_default_price_for_voice_messages`]: priceDetails?.make_default_price_for_voice_messages,
         [`voice_message_price`]: priceDetails?.price,
      }, repliesModalState)

      setIsOpenEditContent(false);
   }


   const onClickPriceButton = (action, data) => {

      switch (action) {
         case 'set':
            setIsOpenEditContent(true);
            break;
         case 'save':
            handleSave();
            break;
         case 'cancel':
            setIsOpenEditContent(false);
            setPriceDetails({
               price: null,
               make_default_price_for_voice_messages: false,
            });
            setError(null)

            setReplyData({
               [`voice_message_make_default_price_for_voice_messages`]: false,
               [`voice_message_price`]: null,
            }, repliesModalState)

            break;
         case 'closeModal':
            setError(null)
            setIsOpenEditContent(false);
            setPriceDetails({
               price: data?.price,
               make_default_price_for_voice_messages: data?.makeDefaultPrice,
            });
            setReplyData({
               [`voice_message_make_default_price_for_voice_messages`]: data?.make_default_price_for_voice_messages,
               [`voice_message_price`]: data?.price,
            }, repliesModalState)
            break;

         default:
            break;
      }
   }


   const getColorForVoiceMessage = () => {
      let color = '#ffffff';
      let opasitiHex = '#ffffff';
      let name = 'major-dark';
      const textColor = GenerateColor.adaptColor(window.skinPrimaryColor)
      switch (textColor) {
         case 'major-dark':
            color = '#E0E0E0';
            opasitiHex = '#e0e0e01f';
            name = 'major-dark';
            break;
         case 'major':
            color = '#667085';
            opasitiHex = '#6670851f';
            name = 'secondary';
            break;
         default:
      }
      return { hex: color, name, opasitiHex: opasitiHex }
   }

   let waveformColor = {};
   if(!isOpenEditContent && audioSrc){
      waveformColor = getColorForVoiceMessage();
   }

   const durationValue = +durationInfo?.duration_in_minutes
   const durationInHours = Math.floor(durationValue / (60))
   const durationInDays = Math.floor(durationValue / (60 * 24))

   return (
      <div
         className={ cx({
            'flex bg-panel dark:bg-panel-dark dark:border-divider-dark border-divider border-t !p-4 relative z-20': true,
            'justify-between py-6': 'progress' === status,
            'flex-col py-4': 'complited' === status,
            'flex-col': replyMessage &&  replyMessage.parentMessageId,
            [`shadow dark:shadow-dark `]: !isSavedReplies,
            [`min-h-[79px] bottom-1.5`]: !showNewStylesInField,
            [`!border-none !shadow-none !pb-3 !pt-6 min-h-[92px] gap-3 rounded-lg`]: showNewStylesInField,
         }) }
         id='chat-input-field'
      >
         {
            replyMessage &&  replyMessage.parentMessageId &&
            <div
               className={ cx({
                  'flex flex-col gap-1.5 pb-3': true,
               }) }
            >
               <div className='flex gap-2 items-center'>
                  <p className='text-sm font-normal leading-[18px] text-secondary dark:text-secondary-dark flex gap-1'>
                     Replying to
                     <TruncateText
                        className='flex text-secondary dark:text-secondary-dark'
                        text={ isAdmin || showNickname ? replyMessage?.user?.nickname ||  replyMessage?.user?.username || 'your message' : replyMessage?.user?.uuid === authUser?.uuid ? 'your message' : replyMessage.user.username }
                        textClass='text-xm font-semibold'
                        textSize='14px'
                        fontWeight='600'
                        width='200px'
                     />
                  </p>
                  <Icon
                     name='cancel'
                     onClick={ canelReplyMessage }
                     color='secondary'
                     className='cursor-pointer'
                  />
               </div>
               {
                  !['text_message'].includes(replyMessage.type) && !!replyMessage.type && (
                     <div className='flex max-w-250 text-secondary dark:text-secondary-dark items-start'>
                        <span className='text-xs '>{replyMessage.type === 'voice_message' ? 'Voice message' : 'Content'}!</span>
                     </div>
                  )
               }
               {
                  replyMessage.text && 'voice_message' !== replyMessage.type  && (
                     <div className='max-w-[307px] w-fit bg-placeholder-12 dark:bg-placeholder-12 py-0.5 px-1 rounded-lg'>
                        <TruncateText
                           className='flex text-secondary dark:text-secondary-dark !h-4'
                           text={ ['mass_message'].includes(replyMessage.type) ? replyMessage?.text?.subject : replyMessage?.text  }
                           textClass='text-xs !leading-4'
                           textSize='12px'
                           fontWeight='400'
                           width='fit-content'
                        />
                     </div>
                  )
               }
            </div>
         }
         {
            showWarningModal && (
               <WarningModal
                  isMobile={ isMobile }
                  info='Items that cost less than 4.00 USD can be purchased only using the wallet'
                  actionText='Ok'
                  isCheckbox
                  action={ () => {
                     setShowWarningModal(false)
                     savePrice()
                  } }
                  closeModal={ () => {
                     setShowWarningModal(false)
                  } }
                  showNewStyles
                  hideCloseButton
                  iconColor='action'
                  iconName='wallet'
               />

            )
         }
         {
            'complited' === status && (
               <>
                  <div
                     className={ cx({
                        'flex justify-between items-center gap-4': true,
                        'flex-row-reverse': showNewStylesInField,
                        '': !showNewStylesInField,
                     }) }
                  >
                     {
                        !withoutPricingContnet && isOpenEditContent &&
                           <>
                              <EditPriceContent
                                 maxPrice={ maxPrice }
                                 error={ error }
                                 handleSave={ handleSave }
                                 price={ priceDetails?.price }
                                 makeDefaultPrice={ priceDetails?.make_default_price_for_voice_messages }
                                 setPriceDetails={ (name, value) => {
                                    setPriceDetails({
                                       ...priceDetails,
                                       [name]: value,
                                    }) } }
                                 setError={ value => setError(value) }
                                 isMobile={ isMobile }
                                 onClick={ onClickPriceButton }
                                 hideCheckbox={ isSavedReplies }
                              />
                           </>
                     }
                     {
                        !isOpenEditContent &&
                           <>
                              <IconButton
                                 iconName='delete'
                                 fontIconColor='error dark:!text-error-dark'
                                 onClick={ () => onCancel(true, () => {
                                    toast.info('Voice message has been deleted');
                                    setReplyData({
                                       [`voice_message_make_default_price_for_voice_messages`]: false,
                                       [`voice_message_price`]: '',
                                       [`voice_message_data`]: '',
                                       [`voice_message_duration`]: null,
                                       [`voice_message_data_src`]: null,
                                    }, repliesModalState)

                                 }) }
                                 fontIconSize='doubleLarge'
                              />
                              {
                                 audioSrc &&
                                    <div className={ cx({
                                       'skin-primary-background px-3 py-2 rounded-full': true,
                                       'w-full': showNewStylesInField,
                                       'w-[270px]': !showNewStylesInField,
                                    }) }>
                                       <Waveform
                                          type={ audioAsblob?.type }
                                          duration={ duration }
                                          url={ audioSrc }
                                          textColor={ waveformColor?.name }
                                          colorState={
                                             {
                                                waveColor: waveformColor.opasitiHex,
                                                progressColor: waveformColor.hex,
                                             }
                                          }
                                       />
                                    </div>
                              }
                              <div  className={ cx({
                                 [`flex`]: !isSavedReplies || !showNewStylesInField,
                                 [`!invisible`]: isSavedReplies,
                                 [`!hidden`]: showNewStylesInField,
                              }) }>
                                 <IconButton
                                    primaryColor
                                    fontIconSize='doubleLarge'
                                    iconName='send'
                                    onClick={ _ => {
                                       if((!disabledSendTo  && (!!durationInfo?.duration_in_minutes) && isAdmin)) {
                                          onSend(withoutPricingContnet ? { price: 0, make_default_price_for_voice_messages: false } : priceDetails, true)
                                       } else {
                                          onSend(withoutPricingContnet ? { price: 0, make_default_price_for_voice_messages: false } : priceDetails)
                                       }
                                    }
                                    }
                                    disabled={ disabledSendTo }
                                    isLoadingSendBtn={ isLoadingSendBtn }
                                    className={ cx({
                                       [`!invisible`]: isSavedReplies,
                                    }) }
                                 />
                              </div>
                           </>
                     }
                  </div>
                  {
                     !showNewStylesInField && !withoutPricingContnet && (
                        <EditPriceButtons
                           onClick={ onClickPriceButton }
                           price={ priceDetails?.price }
                           isOpenEditContent={ isOpenEditContent }
                        />
                     )
                  }
                  <div className={ cx({
                     'w-full h-11 p-3 **my-3 inline-flex flex-col justify-start items-start bg-placeholder-12 **gap-3 rounded-lg select-none': true,
                     [`invisible`]: (!priceDetails?.price && !durationValue),
                  }) }>

                     <div className={ cx({
                        'inline-flex gap-2': true,
                        [`hidden`]: (!priceDetails?.price && !durationValue),
                     }) }>
                        {
                           priceDetails?.price && <div className='flex justify-center gap-1 items-center'>
                              <Icon
                                 name={ `lock` }
                                 color={ `secondary` }
                                 size={ `xl` }
                              />
                              <p className='text-secondary font-medium leading-5'>
                                 {+priceDetails?.price} USD
                              </p>
                           </div>
                        }
                        {
                           (!!priceDetails?.price && !!durationValue) &&  <div className='w-[1px] h-5 bg-disabled' />
                        }
                        {
                           !!durationValue && !!durationValue && <>
                              <div className='flex justify-center gap-1 items-center'>
                                 <Icon
                                    name={ `duration` }
                                    color={ `secondary` }
                                    size={ `xl` }
                                 />
                                 <p className='text-secondary font-medium leading-5'>

                                    {
                                       durationValue  ?
                                          (
                                             +durationValue < 60 ?
                                                +durationValue + `${ +durationValue === 1 ? ` min` : ' mins' }`
                                                :
                                                durationInHours > 24 ? durationInDays + `${ durationInDays === 1 ? ' day' : ' days' }` : durationInHours + `${ durationInHours === 1 ? ' hour' : ' hours' }`
                                          )
                                          :
                                          '0 hours'
                                    }
                                 </p>
                              </div>
                           </>
                        }
                     </div>

                     {/* {
                        !!durationValue && <>
                           <div className='flex justify-center gap-1 items-center'>
                              <Icon
                                 name={ `duration` }
                                 color={ `secondary` }
                                 size={ `base` }
                              />
                              <p className='text-secondary font-medium leading-5'>

                                 {
                                    durationValue  ?
                                       (
                                          +durationValue < 60 ?
                                             +durationValue + `${ +durationValue === 1 ? ` min` : ' mins' }`
                                             :
                                             durationInHours > 24 ? durationInDays + `${ durationInDays === 1 ? ' day' : ' days' }` : durationInHours + `${ durationInHours === 1 ? ' hour' : ' hours' }`
                                       )
                                       :
                                       '0 hours'
                                 }
                              </p>
                           </div>
                        </>
                     } */}
                  </div>
               </>
            )
         }
         {
            'progress' === status &&
            <div className={ cx({
               'flex flex-col items-center w-full': true,
               'flex justify-between': showNewStylesInField,
               'gap-3': !showNewStylesInField,
            }) }>
               <div className={ cx({
                  'flex items-center justify-between w-full': true,
                  '!h-10': showNewStylesInField,
               }) }>
                  <div className='flex items-center'>
                     <div className='w-2.5 h-2.5 min-w-[10px] rounded-full bg-error dark:bg-error-dark mr-2' />
                     <span className='text-major dark:text-major-dark text-base leading-[20px] select-none w-14 '>{getDuration(duration, true)}</span>
                  </div>
                  <IconButton
                     iconName='stop'
                     fontIconColor='error dark:!text-error-dark'
                     onClick={ _ => {
                        onStop()
                        setStatus('complited')
                     } }
                     fontIconSize={ showNewStylesInField ? 'extraLarge' : 'doubleLarge' }
                     className={ cx({
                        'mr-[47px]': !withoutMicSettings,
                        '!h-5 !w-5': showNewStylesInField,
                     })  }
                  />
                  {
                     !withoutMicSettings &&
                     <IconButton
                        iconName='settings'
                        fontIconColor='secondary dark:!text-secondary-dark'
                        onClick={ () => onOpenMicSettings() }
                        // className=''
                        fontIconSize='extraLarge'
                     />
                  }
               </div>
               <Button
                  text='Cancel'
                  textMarginX='0'
                  padding={ 0 }
                  textColor='secondary'
                  classNames={ `!w-[fit-content] ${ showNewStylesInField ? `mt-[6px] !h-5 mb-[26px]` : `px-3.5 py-2.5` }` }
                  backgroundColor='transparent'
                  textSize='small'
                  onClick={ () => onCancel(false) }
                  textClassName={ `${ showNewStylesInField ? `!leading-5` : `` }` }
               />
            </div>
         }

      </div>
   )
};

const IconButton = ({
   iconName,
   fontIconColor,
   primaryColor,
   onClick,
   disabled,
   fontIconSize = 'large',
   className = '',
   isLoadingSendBtn,
}) => {
   return (
      <div className={ `flex h-6 w-6 ${ className || '' }` }>
         <Button
            onClick={ onClick }
            iconName={ isLoadingSendBtn ? '' : iconName }
            textMarginX='0'
            padding={ 0 }
            fontIconColor={ fontIconColor }
            backgroundColor='transparent'
            fontIconSize={ fontIconSize }
            primaryColor={ primaryColor }
            disabled={ disabled }
            classNames={ `${ !disabled ? '' : 'opacity-50' } h-full` }
            loaderClassName='!border-[1px] !w-4 !h-4'
            isLoading={ isLoadingSendBtn }

         />
      </div>
   )
}

VoiceMessage.propTypes = {
   isMobile: PropTypes.bool,
   onStop: PropTypes.func,
   maxPrice: PropTypes.any,
   onCancel: PropTypes.func,
   duration: PropTypes.any,
   audioAsblob: PropTypes.object,
   audioSrc: PropTypes.string,
   onSend: PropTypes.func,
   disabledSendTo: PropTypes.bool,
   withoutPricingContnet: PropTypes.bool,
   defaultVoicePrice: PropTypes.number,
   onOpenMicSettings: PropTypes.func,
   withoutMicSettings: PropTypes.bool,
   isLoadingSendBtn: PropTypes.bool,
   replyMessage: PropTypes.object,
   isAdmin: PropTypes.bool,
   showNickname: PropTypes.bool,
   canelReplyMessage: PropTypes.func,
   authUser: PropTypes.object,
   setReplyData: PropTypes.func,
   isSavedReplies: PropTypes.bool,
   repliesModalState: PropTypes.string,
   replyData: PropTypes.any,
   onUpdateRecordStatus: PropTypes.func,
   setMessageType: PropTypes.func,
   durationInfo: PropTypes.object,
   showNewStylesInField: PropTypes.bool,
   isOpenEditContent: PropTypes.bool,
   setIsOpenEditContent: PropTypes.func,
   priceDetails: PropTypes.object,
   setPriceDetails: PropTypes.func,
};
IconButton.propTypes = {
   iconName: PropTypes.string,
   fontIconColor: PropTypes.string,
   onClick: PropTypes.func,
   primaryColor: PropTypes.bool,
   disabled: PropTypes.bool,
   fontIconSize: PropTypes.string,
   className: PropTypes.string,
   isLoadingSendBtn: PropTypes.bool,
};
VoiceMessage.defaultProps = {
   isLoadingSendBtn: false,
}


export default VoiceMessage
