const initialState = {
   isFetching: true,
   isNewFetching: false,
   comments: {},
   isAnimationId: null,
   commentsCount: 0,
   hideReply: false,
   updateErrors: {},
};

export default initialState;
