

import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const NotificationMedia = ({
   src,
   type,
   onClick,
   isResourceDeleted,
}) => {
   if(isResourceDeleted || !src) {
      const fill = window.site_dark_bg ? '#E0E0E0' : '#616784'
      return (
         <div className='h-10 w-10 flex rounded-full bg-panel dark:bg-panel-dark border dark:border-divider-dark border-divider items-center justify-center'>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
               <path fill-rule='evenodd' clip-rule='evenodd' d='M3.51514 8.50399C3.51514 8.11374 3.76766 7.85358 4.14645 7.85358C4.46211 7.85358 4.77776 8.11374 4.77776 8.50399V16.3089C4.77776 16.6341 5.09342 16.9593 5.40908 16.9593H11.5569C11.5492 17.0938 11.5454 17.2295 11.5454 17.3661C11.5454 17.6693 11.5645 17.9677 11.6014 18.2601H4.77776C4.08332 18.2601 3.51514 17.6747 3.51514 16.9593V11.1056V8.50399ZM18.6667 10.8651V7.85358C18.6667 7.13813 18.0985 6.55277 17.404 6.55277H12.3535L11.4697 5.6422C11.2172 5.38203 10.9015 5.25195 10.5858 5.25195H7.30302C6.60857 5.25195 6.04039 5.83732 6.04039 6.55277V14.3576C6.04039 15.0731 6.60857 15.6585 7.30302 15.6585H11.7537C11.9293 14.9546 12.2109 14.2972 12.5789 13.7072H8.56564C8.31312 13.7072 8.12372 13.382 8.31312 13.1869L10.5227 10.13C10.649 9.93488 10.9015 9.93488 11.0278 10.13L12.9848 12.7316L14.3106 11.1056C14.4369 10.9755 14.6894 10.9755 14.8156 11.1056L15.0504 11.4152C15.8478 11.0084 16.74 10.7807 17.6817 10.7807C18.017 10.7807 18.346 10.8096 18.6667 10.8651Z' fill={ fill } />
               <ellipse cx='17.682' cy='17.3666' rx='4.31818' ry='4.63415' fill={ fill } />
               <path d='M15.9289 19.0743C15.9289 19.3426 16.1919 19.5621 16.5133 19.5621H18.851C19.1724 19.5621 19.4354 19.3426 19.4354 19.0743V16.6353C19.4354 16.367 19.1724 16.1475 18.851 16.1475H16.5133C16.1919 16.1475 15.9289 16.367 15.9289 16.6353V19.0743ZM19.4354 15.4158H18.7049L18.5004 15.245C18.4419 15.1963 18.3835 15.1719 18.2958 15.1719H17.0685C16.9809 15.1719 16.9224 15.1963 16.864 15.245L16.6594 15.4158H15.9289C15.7536 15.4158 15.6367 15.5133 15.6367 15.6597C15.6367 15.806 15.7536 15.9036 15.9289 15.9036H19.4354C19.5815 15.9036 19.7276 15.806 19.7276 15.6597C19.7276 15.5133 19.5815 15.4158 19.4354 15.4158Z' fill={ window.site_dark_bg ? 'black' : 'white' } />
            </svg>
         </div>
      )
   }
   return (
      <div
         className={ `media-box-shadow rounded-lg overflow-hidden cursor-pointer ${ type === 'story' ? 'big'  : 'small' }-notification-media-content` }
         role='presentation'
         onClick={ onClick }
      >
         <img
            className='w-full h-full object-cover object-center'
            src={ src }
            alt=''
         />
      </div>

   );
};

NotificationMedia.propTypes = {
   src: PropTypes.string,
   type: PropTypes.string,
   onClick: PropTypes.func,
   isResourceDeleted: PropTypes.bool,
};
NotificationMedia.defaultProps = {
   src: '',
   onClick: () => {},
};

export default React.memo(NotificationMedia);
