const smartlook = window.smartlook;
const smartlookPause = async () => {
   if(smartlook){
      await smartlook('pause');
   }
}
const smartlookResume = async () => {
   if(smartlook){
      await smartlook('resume');
   }
}
const smartlookIdentify = async (uuid, options) => {
   if(smartlook){
      await window.smartlook('identify', uuid, options);
   }
}

export {
   smartlookPause,
   smartlookResume,
   smartlookIdentify,
}
