import React, { useState, useEffect } from "react";
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import {
   MuiPickersUtilsProvider,
   KeyboardDatePicker,
   KeyboardDateTimePicker,
   KeyboardTimePicker,
} from "@material-ui/pickers";
import Grid from '@material-ui/core/Grid';
import '@material-ui/core/styles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { createTheme } from "@material-ui/core";
import classNames from 'classnames';
import './style.scss'
import moment from "moment";
import Icon from "../../icons";
function BasicDateTimePicker({
   label,
   labelType,
   className,
   onChangeDate,
   value,
   name,
   errorMessage,
   placeholder,
   minValue,
   type,
   disabled,
   clearable,
   onCloseModal,
   onOpenModal,
   labelFontSize,
   labelFontWeight,
   isStart,
   pickerStyle,
   pickerWrapperClassName,
   hasArrowIcon,
   dateTimePickerFormat,
   openDatePicker,
   keyboardIconClassName,
   maxValue,
   typeing,
   calendarIconColor,
   datePickerClassName,
   labelClassName,
   onClickLabelOpenDatePicker,
   dateFormat,
   views,
   openPickerOnChangeOpenProp,
   showErrorBorder,
}) {
   const [selectedDate, handleDateChange] = useState(value ? value : null);
   const [isError, setIsError] = useState(!!errorMessage);
   const [validError, setValiError] = useState(errorMessage);
   const [isOpen, setIsOpen] = useState(openDatePicker ? true : false);

   useEffect(() => {
      if(!openPickerOnChangeOpenProp) return;

      if(openDatePicker) {
         setIsOpen(openDatePicker)
      }
   }, [openDatePicker, openPickerOnChangeOpenProp])

   useEffect(() => {
      if(errorMessage) {
         setIsError(!!errorMessage)
         setValiError(errorMessage)
      }
   }, [errorMessage]);

   useEffect(() => {
      if(!value) {
         handleDateChange(null)
      }
   }, [value]);

   const customTheme = createTheme({
      palette: {
         primary: {
            main: window.skinPrimaryColor,
         },
         secondary: {
            main: window.skinSecondaryColor,
         },
      },
   })
   let pickerState = {
      clearable: clearable,
      value: selectedDate,
      placeholder: placeholder,
      onChange: date => {
         if(isError) {
            setIsError(false)
         }
         handleDateChange(date)
         if(typeing){
            onChangeDate(name, date);
         }
      },
      onAccept: date => {
         if(isError) {
            setIsError(false)
         }
         onChangeDate(name, date)
      },
      onClose: date => {
         if(onCloseModal) {
            onCloseModal()
         }
         setIsOpen(false);
      },
      // onError: error => {
      //    if(!!error && 'string' === typeof error && (error.includes('Invalid Date') || error.includes('Invalid Time Format'))) {
      //       let error = 'Enter a valid date';
      //       if('time' === type) {
      //          error = 'Enter a valid time'
      //       }
      //       setValiError(error)
      //       setIsError(true)
      //    }
      // },
      onOpen: date => {
         if(onOpenModal) {
            onOpenModal()
         }
         setIsOpen(true);
      },
      // onBlur: () => {
      //    alert(1)
      //    if(isError) {
      //       setIsError(false)
      //    }
      //    onChangeDate(name, selectedDate)
      // },
      minDate: minValue,
      disabled: disabled,
      name: name,
      className: `${ className }`,
      error: isError,
      helperText: isError ? validError : null,
      maxDate: maxValue,
   }

   if(minValue) {
      pickerState = {
         ...pickerState,
         minDate: minValue,
      }
   }
   if(maxValue) {
      pickerState = {
         ...pickerState,
         maxDate: maxValue,
      }
   }
   if(views) {
      pickerState = {
         ...pickerState,
         views: views,
      }
   }
   const { userAgent } = window.navigator;
   const isIos = /iP(ad|od|hone)/i.test(userAgent)
   if(selectedDate && isIos) {
      pickerState.defaultValue = moment(selectedDate).format('MMMM DD, yyy  hh:mm A')
   }
   return (
      <>
         {
            labelType === 'html' ? (
               <div
                  className={ `text-${ labelFontSize } font-${ labelFontWeight }  ${ classNames({
                     'leading-tight mb-2 select-none': true,
                     'text-placeholder dark:text-placeholder-dark': disabled,
                     'text-major dark:text-major-dark': !disabled,
                     [labelClassName]: !!labelClassName,
                  }) } ` }
                  dangerouslySetInnerHTML={ { __html: label } }
                  role='presentation'
                  onClick={ () => {
                     if(onClickLabelOpenDatePicker) {
                        setIsOpen(!isOpen)
                     }
                  } }
               />
            ) : (
               <span
                  className={ `text-${ labelFontSize } font-${ labelFontWeight }  ${ classNames({
                     'leading-tight block mb-2 select-none': true,
                     'text-placeholder dark:text-placeholder-dark': disabled,
                     'text-major dark:text-major-dark': !disabled,
                     [labelClassName]: !!labelClassName,
                  }) } ` }
                  role='presentation'
                  onClick={ () => {
                     if(onClickLabelOpenDatePicker) {
                        setIsOpen(!isOpen)
                     }
                  } }
               >
                  {label}
               </span>
            )
         }
         <MuiThemeProvider theme={ customTheme }>
            <MuiPickersUtilsProvider
               utils={ DateFnsUtils }
            >
               <Grid
                  container
                  className={ `ams-custom-datePicker ${ datePickerClassName }` }
               >
                  {
                     'date' === type && (
                        <div className={ `flex ${ pickerWrapperClassName }` }>
                           <KeyboardDatePicker
                              format={ dateFormat }
                              open={ isOpen }
                              keyboardIcon={ <Icon name={ 'calendar' } color={ calendarIconColor } className={ `${ keyboardIconClassName }` } /> }
                              InputProps={ {
                                 readOnly: !typeing,
                                 onClick: () => {
                                    if(!typeing){
                                       setIsOpen(true);
                                       if(!!onOpenModal){
                                          onOpenModal()
                                       }
                                    }
                                 },
                                 // onError: error => {
                                 //    if(!!error && 'string' === typeof error && (error.includes('Invalid Date') || error.includes('Invalid Time Format'))) {
                                 //       let error = 'Enter a valid date';
                                 //       if('time' === type) {
                                 //          error = 'Enter a valid time'
                                 //       }
                                 //       setValiError(error)
                                 //       setIsError(true)
                                 //    }
                                 // },
                                 style: {
                                    width: '100%',
                                    height: '44px',
                                    border: showErrorBorder ? '1px solid #D92D20' : '1px solid #F2F4F7',
                                    borderRadius: '8px',
                                    ...pickerStyle,
                                 },
                                 disableUnderline: true,
                              } }
                              InputAdornmentProps={ { position: 'start' } }
                              disableFuture={ isStart }
                              { ...pickerState }
                           />
                           <div className='relative left-[-43px] w-0 flex items-center cursor-pointer'
                              role={ 'presentation' }
                              onClick={ () => {
                                 setIsOpen(true)
                                 if(onOpenModal) {
                                    onOpenModal()
                                 }
                              } }
                           >
                              <Icon name='arrow-down' size='xl' color='secondary'  />
                           </div>
                        </div>

                     )
                  }
                  {
                     'time' === type && (
                        <KeyboardTimePicker
                           format='hh:mm aa'
                           { ...pickerState }
                           mask='__:__ _M'
                        />

                     )
                  }
                  {
                     'datetime' === type && (
                        <div className={ `flex w-full ${ pickerWrapperClassName }` }>
                           <KeyboardDateTimePicker
                              format={ Boolean(dateTimePickerFormat) ? dateTimePickerFormat : 'MMMM dd, yyy  hh:mm aa' }
                              open={ isOpen }
                              keyboardIcon={ <Icon name={ 'calendar' } color={ selectedDate ? calendarIconColor : 'placeholder' } className={ `${ keyboardIconClassName }` } /> }
                              InputProps={ {
                                 readOnly: !typeing,
                                 onClick: () => {
                                    if(!typeing){
                                       setIsOpen(true);
                                       if(!!onOpenModal){
                                          onOpenModal()
                                       }
                                    }
                                 },
                                 // onError: error => {
                                 //    if(!!error && 'string' === typeof error && (error.includes('Invalid Date') || error.includes('Invalid Time Format'))) {
                                 //       let error = 'Enter a valid date';
                                 //       if('time' === type) {
                                 //          error = 'Enter a valid time'
                                 //       }
                                 //       setValiError(error)
                                 //       setIsError(true)
                                 //    }
                                 // },
                                 style: {
                                    width: '100%',
                                    height: '44px',
                                    border: showErrorBorder ? '1px solid #D92D20' : '1px solid #F2F4F7',
                                    borderRadius: '8px',
                                    ...pickerStyle,
                                 },
                                 disableUnderline: true,
                              } }
                              InputAdornmentProps={ { position: 'start' } }
                              { ...pickerState }
                           />
                           {
                              hasArrowIcon && (
                                 <div className='relative left-[-43px] w-0 flex items-center cursor-pointer'
                                    role={ 'presentation' }
                                    onClick={ () => {
                                       setIsOpen(true)
                                       if(onOpenModal) {
                                          onOpenModal()
                                       }
                                    } }
                                 >
                                    <Icon name='arrow-down' size='xl' color='secondary' />
                                 </div>
                              )
                           }
                        </div>
                     )
                  }
               </Grid>
            </MuiPickersUtilsProvider>

         </MuiThemeProvider>
      </>
   );
}

BasicDateTimePicker.defaultProps = {
   labelType: 'text',
   className: '',
   type: 'date',
   disabled: false,
   clearable: true,
   labelFontSize: 'base',
   labelFontWeight: 'medium',
   hasArrowIcon: true,
   openDatePicker: false,
   keyboardIconClassName: '',
   typeing: false,
   calendarIconColor: 'secondary',
   datePickerClassName: '',
   onClickLabelOpenDatePicker: false,
   dateFormat: 'MM/dd/yyyy',
   openPickerOnChangeOpenProp: false,
};

BasicDateTimePicker.propTypes = {
   label: PropTypes.string,
   labelType: PropTypes.string,
   value: PropTypes.any,
   className: PropTypes.string,
   onChangeDate: PropTypes.func,
   name: PropTypes.string,
   errorMessage: PropTypes.any,
   placeholder: PropTypes.string,
   type: PropTypes.string,
   minValue: PropTypes.string,
   disabled: PropTypes.bool,
   clearable: PropTypes.bool,
   onCloseModal: PropTypes.func,
   onOpenModal: PropTypes.func,
   labelFontSize: PropTypes.string,
   labelFontWeight: PropTypes.string,
   isStart: PropTypes.bool,
   pickerStyle: PropTypes.object,
   pickerWrapperClassName: PropTypes.string,
   hasArrowIcon: PropTypes.bool,
   dateTimePickerFormat: PropTypes.string,
   openDatePicker: PropTypes.bool,
   keyboardIconClassName: PropTypes.string,
   maxValue: PropTypes.string,
   typeing: PropTypes.bool,
   calendarIconColor: PropTypes.string,
   datePickerClassName: PropTypes.string,
   labelClassName: PropTypes.string,
   onClickLabelOpenDatePicker: PropTypes.bool,
   views: PropTypes.array,
   dateFormat: PropTypes.string,
   openPickerOnChangeOpenProp: PropTypes.bool,
   showErrorBorder: PropTypes.bool,
};

export default BasicDateTimePicker;
