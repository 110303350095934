import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import toast, { positionToasterContainer } from 'common/utils/toast';
import { Tooltip } from 'react-tooltip';
import { createPortal } from 'react-dom';
import Icon from 'common/components/elements/icons';
import EmojiPicker from 'common/components/elements/emoji-mart';
import cx from 'classnames'
import { getAllowedVideoExtentions, getBrowserName, insertTextMac } from 'common/utils/utils';
import VariableList from '../../variable-list';

const ChatOptions = ({
   isMobile,
   onClick,
   uploadFile,
   optionsData,
   isAdmin,
   isMessageToLong,
   switchShowEmojis,
   showEmojis,
   addEmoji,
   isUpdateState,
   isMiniChat,
   showVariables,
   variableList,
   inputRef,
   onCustomAction,
   showFrequentlyUsedEmojis,
   isDemoMode,
   disableAttachments,
   hideEmojies,
   showMediaButtonText,
}) => {
   const [openChooseModal, setOpenChooseModal] = useState(false)

   let buttonRef = createRef();
   let timeout = null;

   useEffect(() => {
      const handleClickOutside = (event) => {
         const optionTooltip = document.querySelector('#chat_options_menu_tooltip')
         if(optionTooltip && optionTooltip.contains(event.target)) {
            return
         }
         if(buttonRef.current && !buttonRef.current.contains(event.target)) {
            if(openChooseModal) {
               setOpenChooseModal(false)
            }
         }
      }
      if(!isMobile){
         document.addEventListener('mousedown', handleClickOutside);
      }
      return () => {
         if(!isMobile){
            document.removeEventListener('mousedown', handleClickOutside);
         }
      }
   }, [buttonRef, isMobile, openChooseModal, setOpenChooseModal, inputRef]);

   useEffect(() => {
      return () =>  clearTimeout(timeout)
   }, [timeout]);

   const addVariableIntoInput = (value, type = 'input') => {
      let el = inputRef.current;

      const selectionStart = el.selectionStart;
      if('safari' !== getBrowserName()){
         window.document.execCommand('insertText', false, value);
      } else {
         insertTextMac(el, selectionStart, value)
      }
      el.selectionStart = el.selectionEnd = selectionStart + value.length;

      const selection = window.getSelection();
      const range = document.createRange();
      selection.removeAllRanges();
      range.selectNodeContents(el);
      range.collapse(false);
      selection.addRange(range);

      el.focus();

      window.document.execCommand('insertText', false, value);

      !!onCustomAction && onCustomAction('body', selection?.anchorNode?.textContent)

      return
   }

   const getCurrentPosition = (type = 'input') => {
      let oField = inputRef.current

      let iCaretPos = 0;
      if(document.selection) {
         oField.focus();
         let oSel = document.selection.createRange();
         oSel.moveStart('character', -oField.value.length);
         iCaretPos = oSel.text.length;
      }

      else if(oField.selectionStart || +oField.selectionStart === 0)
         iCaretPos = oField.selectionDirection === 'backward' ? oField.selectionStart : oField.selectionEnd;

      return iCaretPos;
   }

   const onFocusInput = async (type) => {
      if('input' === type) {
         let ctrl = inputRef.current;

         let start = getCurrentPosition()
         if(!start) {
            start = ctrl?.value?.length
         }
         if(ctrl != null) {
            if(ctrl.createTextRange) {
               let range = ctrl.createTextRange();
               range.move('character', start);
               range.select();
            }
            else {
               if(ctrl.selectionStart) {
                  ctrl.focus();
                  ctrl.setSelectionRange(start, start);
               }
               else
                  ctrl.focus();
            }
         }
      }
      // if('editor' === type) {
      //    const ctrl = editorRef.current
      //    const editor = ctrl.editor
      //    editor.focus();
      // }
   }

   const view = (
      <div className={ cx({
         'flex ': true,
      }) }>
         {
            optionsData && optionsData.map((option, index) => {
               return (
                  <ChatOptionItem
                     className={ option.className || '' }
                     option={ option }
                     isUpload={ Boolean(option?.isUpload) }
                     key={ index.toString() }
                     type={ option.type }
                     title={ option.title }
                     options={ option?.options ?? [] }
                     iconName={ option.iconName }
                     uploadFile={ (e, type) => {
                        uploadFile(e, type)
                        if(openChooseModal) {
                           setOpenChooseModal(false)
                        }
                     } }
                     onClick={ (type, resourceType) => {
                        onClick(type, resourceType)
                        if(openChooseModal) {
                           setOpenChooseModal(false)
                        }
                     } }
                     isAdmin={ isAdmin }
                     isMobile={ false }
                     groupType={ option?.groupType ?? '' }
                     resourceType={ option?.resourceType ?? '' }
                     tooltipText={ option.tooltipText }
                     isUpdateState={ isUpdateState }
                     isMiniChat={ isMiniChat }
                     isDemoMode={ isDemoMode }
                     disableAttachments={ disableAttachments }
                     showMediaButtonText={ showMediaButtonText }
                  />
               )
            })
         }
      </div>
   )
   return (
      <div
         ref={ buttonRef }
         data-tooltip-id={ isMobile ? null : 'chat_options_menu_tooltip' }
         className={ `h-6 w-6 flex mr-3 ${ isAdmin ? 'intercom-new-chat-options' : '' }` }
      >
         <div className={ cx({
            'relative flex items-center': true,
         }) }>
            {
               showEmojis && !isMobile && !showFrequentlyUsedEmojis &&
               <EmojiPicker
                  className='chat-emoji-picker'
                  onEmojiSelect={ e => addEmoji(e) }
                  style={ {
                     position: 'absolute',
                     width: '352px',
                     bottom: '30px',
                     left: 0,
                     zIndex: 1000,
                  } }
               />
            }
            {
               !!optionsData && view
            }
            {
               !!hideEmojies && !isMobile && !isMessageToLong && !showFrequentlyUsedEmojis &&
                  <div
                     id='emoji-mart'
                     role='presentation'
                     className={ cx({
                        'flex items-center cursor-pointer': true,
                        'pl-3 border-l border-divider dark:border-divider-dark': !!optionsData,
                     }) }
                     onClick={ (e) => { e.stopPropagation();  switchShowEmojis() } }
                  >
                     <Icon
                        name='emoji' color='placeholder' darkColor='placeholder-dark' style={ { fontSize: '20px' } }
                        className='duration-200 hover:text-secondary'
                     />
                  </div>
            }
            {
               showVariables  &&
                  <div className='relative h-5 w-5'>
                     <VariableList
                        variableList={ variableList }
                        selectVariable={ (variable) => {
                           addVariableIntoInput(variable, 'input')
                        } }
                        onClickOpen={ (variable) => onFocusInput('input') }
                        name={ `chat` }
                        className='ml-2'
                        showIcon
                     />
                  </div>
            }
         </div>
         {
            !isMobile  && (
               <>
                  {
                     createPortal(
                        <Tooltip
                           openOnClick
                           role='dialog'
                           variant={ window.site_dark_bg ? 'dark' : 'light' }
                           id='chat_options_menu_tooltip'
                           place='top'
                           clickable
                           isOpen={ openChooseModal }
                           noArrow
                           style={ {
                              padding: 0,
                              borderRadius: '8px',
                           } }
                           className='shadow dark:shadow-dark border dark:border-divider-dark border-divider min-w-[303px] z-60'
                        >
                           {view}
                        </Tooltip>,
                        document.getElementById('tooltips')
                     )
                  }
               </>
            )
         }
      </div>
   )
};

const ChatOptionItem = ({
   type,
   isMobile,
   onClick,
   uploadFile,
   closeModal,
   isUpload,
   title,
   iconName,
   className,
   isAdmin,
   options,
   groupType,
   resourceType,
   tooltipText,
   option,
   isUpdateState,
   isMiniChat,
   isDemoMode,
   disableAttachments,
   showMediaButtonText,
}) => {
   let fileUploader = createRef()
   const [modalState, setModalState] = useState({
      isOpen: false,
      type: null,
      options: [],
      uploadType: 'photo_unlock',
   })

   const getAccept = (isUpload) => {
      let accept = null;
      if(!isUpload) return accept;
      if('video_unlock' === type) {
         const allowedExtentions = getAllowedVideoExtentions();
         accept = allowedExtentions.join(',');
      } else {
         accept = 'image/png, image/jpeg, image/jpg, image';
      }
      return accept;
   }
   let accept = getAccept(isUpload, type);

   const handleClickOutside = e => {
      if(isMobile) return
      if
      (
         !e.target.closest(`.chat_option_${ option.groupType }`)
      ) {
         setModalState({ ...modalState, isOpen: false })
      }
   }

   useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => {
         document.removeEventListener('click', handleClickOutside);
      };
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const view =
   modalState.isOpen &&
      <div className={ ` absolute bottom-7  py-1 border border-divider dark:border-divider-dark rounded-lg bg-panel dark:bg-panel-dark chat_options_modal_${ title } min-w-[320px] z-60 ${ isMiniChat && groupType === 'vaults' ? '-left-32' : '' }` }>
         {
            modalState?.options?.map((it, i) => {
               return (
                  <ChatOptionItem
                     className={ it.className || '' }
                     option={ it }
                     isUpload={ Boolean(it?.isUpload) }
                     key={ i }
                     type={ it.type }
                     title={ it.title }
                     options={ it?.options ?? [] }
                     iconName={ it.iconName }
                     uploadFile={ (e, type) => {
                        setModalState({ ...modalState, isOpen: false })
                        uploadFile(e, type)
                     } }
                     onClick={ (type, resourceType) => {
                        setModalState({ ...modalState, isOpen: false })
                        onClick(type, resourceType)
                     } }
                     isAdmin={ isAdmin }
                     isMobile={ true }
                     groupType={ it?.groupType ?? '' }
                     resourceType={ it?.resourceType ?? '' }
                     tooltipText={ it.tooltipText }
                     isMiniChat={ isMiniChat }
                     disableAttachments={ disableAttachments }
                  />
               )
            })
         }
      </div>

   return (
      <div className={ cx({
         [`w-full chat_option_${ option.groupType }`]: true,
         'pr-3': ! isMobile,
         '!cursor-default': (option.groupType === 'attach' || !!option?.isUpload) && isDemoMode,
      }) }>

         {
            isUpload && (
               <input
                  name={ `${ type }_upload` }
                  id={ `${ type }_upload` }
                  hidden
                  disabled={ isDemoMode }
                  type='file'
                  ref={ fileUploader }
                  onChange={ (e) => {
                     if('video_unlock' === type){
                        const allowedExtentions = accept;
                        const file = e.target.files[0] || {}
                        if(!allowedExtentions.includes(file.type)){
                           toast.error('You may upload only video files', isMobile ? { onOpen: () => {
                              positionToasterContainer('60px')

                           } } : {})
                           closeModal()

                           return
                        }
                        uploadFile(e, type)
                     } else {
                        uploadFile(e, type)

                     }
                  }  }
                  multiple={ isAdmin && 'photo_unlock' === type }
                  accept={ 'photo_unlock' === type ? accept : `.mov,${ accept }` }
               />
            )
         }
         <div
            className='relative'
            data-tooltip-content={
               isMobile ? null : (option?.disabledTooltip ? option?.disabledTooltip :
                  disableAttachments ?
                     'Unable to attach content on replying' :
                     ((option.groupType === 'attach' || !!option?.isUpload) && isDemoMode ? 'Not available in demo' :
                        !!tooltipText && !isMobile && !modalState.isOpen ? isUpdateState ? 'Unable to attach content on edit mode' : tooltipText : null))
            }
            data-tooltip-id={ isMobile ? null : 'ams-top-tooltip' }
         >
            <Button
               fontIconSize='extraLarge'
               padding='none'
               classNames={ cx({
                  [`h-full w-full whitespace-nowrap`]: true,
                  'py-2.5 px-4': isMobile,
                  [className]: className,
                  '!cursor-default dark:hover:!bg-transparent': disableAttachments || isUpdateState || option.isDisabled || ((option.groupType === 'attach' || !!option?.isUpload) && isDemoMode),
                  'intercom-saved-replies': type === 'saved_replies',
               }) }
               backgroundColor={ `transparent ${ !option?.isDisabled && isMobile ? 'hover:bg-hover dark:hover:bg-hover-dark' : 'hover:bg-transparent' }` }
               textColor={ option.textColor || 'major dark:text-major-dark' }
               fontIconColor={ option.fontIconColor || (disableAttachments || isUpdateState || option.isDisabled || ((option.groupType === 'attach' || !!option?.isUpload) && isDemoMode) ? 'disabled' : isMobile ? 'secondary' : 'placeholder') }
               darkIconColor={ disableAttachments || isUpdateState || option.isDisabled || ((option.groupType === 'attach' || !!option?.isUpload) && isDemoMode) ? 'disabled-dark' : isMobile ? 'secondary-dark' : 'placeholder-dark' }
               iconPosition='left'
               alignment='end'
               textSize='base'
               fontWeight='normal'
               text={ (isMobile || showMediaButtonText) && title }
               iconName={ iconName }
               iconClassName={
                  cx({
                     'duration-200 hover:text-secondary': !isMobile,
                     '!text-disabled dark:!text-disabled-dark hover:!text-disabled dark:hover:!text-disabled-dark': option?.disabled || disableAttachments || isUpdateState || option.isDisabled || ((option.groupType === 'attach' || !!option?.isUpload) && isDemoMode),
                  })
               }
               onClick={ () => {
                  if(option?.disabled || disableAttachments || isUpdateState || option.isDisabled || (((option.groupType === 'attach' || !!option?.isUpload) && isDemoMode) && isDemoMode)) return

                  if(!isMobile && ['attach', 'vaults'].includes(groupType)) {
                     setModalState({
                        isOpen: !modalState.isOpen,
                        type,
                        options: [...options],
                     })
                     return
                  }
                  if(isUpload){
                     fileUploader.current.click();
                  } else {
                     onClick(type, resourceType);
                  }
               } }
               textMarginX=' ml-3'
               textClassName={ option.textClassName || '' }
            />
            {view}
         </div>

      </div>
   )
}

ChatOptions.propTypes = {
   isMobile: PropTypes.bool,
   onClick: PropTypes.func,
   uploadFile: PropTypes.func,
   optionsData: PropTypes.array,
   isAdmin: PropTypes.bool,
   isMessageToLong: PropTypes.bool,
   switchShowEmojis: PropTypes.func,
   showEmojis: PropTypes.bool,
   addEmoji: PropTypes.func,
   isUpdateState: PropTypes.bool,
   isMiniChat: PropTypes.bool,
   showVariables: PropTypes.bool,
   variableList: PropTypes.array,
   inputRef: PropTypes.object,
   onCustomAction: PropTypes.func,
   showFrequentlyUsedEmojis: PropTypes.bool,
   isDemoMode: PropTypes.bool,
   disableAttachments: PropTypes.bool,
   hideEmojies: PropTypes.bool,
   showMediaButtonText: PropTypes.bool,
};
ChatOptions.defaultProps = {
   className: '',
   isUpdateState: false,
   isMiniChat: false,
   isDemoMode: false,
   hideEmojies: true,
   showMediaButtonText: false,
}
ChatOptionItem.propTypes = {
   type: PropTypes.string,
   isMobile: PropTypes.bool,
   onClick: PropTypes.func,
   uploadFile: PropTypes.func,
   closeModal: PropTypes.func,
   isUpload: PropTypes.bool,
   title: PropTypes.string,
   iconName: PropTypes.string,
   className: PropTypes.string,
   isAdmin: PropTypes.bool,
   options: PropTypes.array,
   groupType: PropTypes.string,
   resourceType: PropTypes.string,
   tooltipText: PropTypes.string,
   option: PropTypes.object,
   isUpdateState: PropTypes.bool,
   isMiniChat: PropTypes.bool,
   isDemoMode: PropTypes.bool,
   disableAttachments: PropTypes.bool,
   showMediaButtonText: PropTypes.bool,
}
export default ChatOptions
