const initialState = {
   data: [],
   isFetching: false,
   modalSettings: {
      activeId: null,
      isOpen: false,
      type: 'option',
   },
   isEmpty: false,
};

export default initialState;
