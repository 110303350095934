import React, { memo  } from 'react';
import Modal from 'common/components/modules/modal';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import CreateEditModal from 'common/components/modules/modal-wrapper';


const CameraAccessDialog = ({
   onClose,
   type,
   handleHelp,
   isNewStyles,
   isMobile,
   howDoIDoThisButtonProps,
}) => {

   if(isNewStyles) {

      const getModalState = () => {
         switch (type) {
            case 'allow':
               return {
                  type: 'action',
                  title: 'Allow camera',
                  iconName: 'upward-left',
                  iconColor: 'action',
                  descriptionColorClass: 'text-secondary dark:text-secondary-dark ',
                  buttonText: '',
                  withoutCloseButton: true,
                  buttonBackgroundColor: 'transparent',
                  headerClass: 'mb-4',
                  nonPrimaryColor: true,
                  secondButtonTitle: 'Ok',
                  secondButtonAction: () => onClose(),
                  withoutConfirmButton: true,
               }

            default:
            case 'block':
               return {
                  type: 'action',
                  title: 'Your camera is blocked',
                  iconName: 'attention-outline',
                  iconColor: 'warning',
                  descriptionColorClass: 'text-secondary dark:text-secondary-dark ',
                  buttonText: '',
                  withoutCloseButton: true,
                  buttonBackgroundColor: 'transparent',
                  headerClass: 'mb-4',
                  nonPrimaryColor: true,
                  secondButtonTitle: 'Ok',
                  secondButtonAction: () => onClose(),
                  withoutConfirmButton: true,
               }
         }
      }

      const modalState = getModalState()

      return (
         <CreateEditModal
            isMobile={ isMobile }
            type={ modalState.type }
            icon={ modalState.iconName }
            iconColor={ modalState.iconColor }
            textColor={ modalState.textColor }
            descriptionColorClass={ modalState.descriptionColorClass }
            title={ modalState.title }
            description={ modalState.description }
            cancelText={ modalState.cancelText }
            buttonText={ modalState.buttonText }
            nonPrimaryColor={ modalState.nonPrimaryColor }
            contentWidth='375px'
            className='relative'
            buttonClassName={ modalState.buttonClassName }
            iconSize='2xl'
            onCloseModal={ onClose }
            withoutCloseButton={ modalState.withoutCloseButton }
            secondButtonPosition='bottom'
            secondButtonClassName='mt-3'
            secondButtonTitle={ modalState.secondButtonTitle }
            secondButtonAction={ modalState.secondButtonAction }
            buttonBackgroundColor={ modalState.buttonBackgroundColor }
            headerClass={ modalState.headerClass }
            withoutConfirmButton={ modalState.withoutConfirmButton }
         >
            {
               'block' === type && (
                  <div className='flex flex-col items-center gap-8'>
                     <span
                        className='leading-6 text-center text-secondary dark:text-secondary-dark '
                     >
                        Access to your camera is currently blocked. To continue, please unblock and allow access.
                     </span>
                     <div className='h-9'>
                        <Button
                           text='How do I do this?'
                           padding='none'
                           classNames='h-full pl-[14px] pr-1.5 intercom-how-to-unblock-camera'
                           backgroundColor='transparent'
                           iconName='help-outline'
                           iconPosition='left'
                           fontIconSize='extraLarge'
                           primaryColor
                           textSize='small'
                           fontWeight='medium'
                           onClick={ () => {} }
                           textClassName='leading-5'
                           { ...howDoIDoThisButtonProps }
                        />
                     </div>
                  </div>
               )
            }
            {
               'allow' === type && (
                  <div className='mb-5 flex justify-center'>
                     <span
                        className='leading-6 text-center text-secondary dark:text-secondary-dark '
                     >
                        Click “Allow” above to give access to your camera
                     </span>
                  </div>
               )
            }
         </CreateEditModal>
      )
   }

   return (
      <Modal
         onClose={ onClose }
         roundedModal='lg'
         contentPosition='center'
         isCloseAction={ false }
         contentWidth='375px'
      >
         <div className={ `camera-access-dialog flex flex-col p-4 rounded-lg ${ type === 'block' ? 'border-2 border-warning dark:border-warning-dark box-border' : '' }` }>
            {
               type === 'allow' ? (
                  <>
                     <div className='camera-access-title flex items-center'>
                        <div className='w-10 h-10 bg-panel-dark dark:text-major-dark rounded-full flex justify-center items-center'>
                           <Icon name='upward-left' color='major-dark' size='2xl' />
                        </div>
                        <span className='text-major dark:text-major-dark text-2xl leading-28 block ml-4'>Allow camera</span>
                     </div>
                     <div className='camera-access-description my-5'>
                        <span className='text-major dark:text-major-dark leading-20 block'>
                           Click “Allow” above to give access to your camera
                        </span>
                     </div>
                     <div className='camera-access-actions flex items-center justify-between'>
                        <div className='h-6'></div>
                        <div className='h-6'>
                           <Button
                              text='Ok'
                              padding='none'
                              classNames='h-full px-3'
                              backgroundColor='transparent'
                              textColor='secondary dark:text-secondary-dark'
                              textSize='base'
                              fontWeight='medium'
                              onClick={ onClose }
                           />
                        </div>
                     </div>
                  </>
               ) : (
                  <>
                     <div className='camera-access-title flex items-center'>
                        <div className='w-6 h-6  rounded-full flex justify-center items-center'>
                           <Icon name='attention' color='warning' darkColor='warning-dark' size='2xl' />
                        </div>
                        <span className='text-major dark:text-major-dark font-bold leading-20 block ml-1'>Your camera is blocked</span>
                     </div>
                     <div className='camera-access-description mb-7 mt-4'>
                        <span className='text-secondary dark:text-secondary-dark text-sm leading-18 block'>
                           Access to your camera is currently blocked. To continue, please unblock and allow access.
                        </span>
                     </div>
                     <div className='camera-access-actions flex items-center justify-between'>
                        <div className='h-6 intercom-how-to-unblock-camera'>
                           <Button
                              text='How do I do this?'
                              padding='none'
                              classNames='h-full px-3'
                              backgroundColor='transparent'
                              iconName='help-outline'
                              iconPosition='left'
                              fontIconSize='large'
                              primaryColor
                              textSize='base'
                              fontWeight='medium'
                              onClick={ () => {} }
                           />
                        </div>
                        <div className='h-6'>
                           <Button
                              text='Ok'
                              padding='none'
                              classNames='h-full'
                              backgroundColor='transparent'
                              textColor='secondary dark:text-secondary-dark'
                              textSize='base'
                              fontWeight='medium'
                              onClick={ onClose }
                              waveColor='rgba(106, 106, 106, 0.8)'
                           />
                        </div>
                     </div>
                  </>
               )
            }
         </div>
      </Modal>
   )
}

CameraAccessDialog.defaultProps = {
   howDoIDoThisButtonProps: {},
}

CameraAccessDialog.propTypes = {
   type: PropTypes.oneOf([
      'allow', 'block',
   ]).isRequired,
   onClose: PropTypes.func.isRequired,
   handleHelp: PropTypes.func,
   isNewStyles: PropTypes.bool,
   isMobile: PropTypes.bool,
   howDoIDoThisButtonProps: PropTypes.object,
};


export default memo(CameraAccessDialog);
