import photo2 from 'assets/images/photo_5.png'

export default {
   list: [
      {
         id: 1,
         title: 'Char dzdzya',
         // src: photo1,
         status: 'ready',
         src: 'https://dfhvrmdevpx54.cloudfront.net/videos/uQBtKfPIWFOl/outputs/thumbnails/uQBtKfPIWFOl.0000003.jpg',
         poster: 'https://dfhvrmdevpx54.cloudfront.net/videos/uQBtKfPIWFOl/outputs/thumbnails/uQBtKfPIWFOl.0000003.jpg',
         description: 'Description',
         download_available_for_active_members: 0,
         access_level: '',
         viewsCount: '1234',
         likesCount: '245',
         time: '17:55',
         commentsCount: '175',
         text: 'Lorem ipsum dolor sit amet',
         completed: 1,
         is_published: 1,
         is_publish_enabled: 0,
         created_at: "2020-03-03 12:38:10",
      },
      {
         id: 2,
         title: 'Caxik',
         src: 'https://dfhvrmdevpx54.cloudfront.net/videos/4HfT8Kk2Mgsl/outputs/thumbnails/4HfT8Kk2Mgsl.0000000.jpg',
         status: 'ready',
         poster: 'https://dfhvrmdevpx54.cloudfront.net/videos/4HfT8Kk2Mgsl/outputs/thumbnails/4HfT8Kk2Mgsl.0000000.jpg',
         description: 'Description',
         time: '17:55',
         viewsCount: '1234',
         likesCount: '245',
         commentsCount: '175',
         text: 'Lorem ipsum dolor sit amet',
         completed: 0,
         is_published: 1,
         created_at: "2020-03-03 12:38:10",
         is_publish_enabled: 0,
      },
      {
         id: 4,
         title: 'Ax office office ',
         status: 'paused',
         src: 'https://dfhvrmdevpx54.cloudfront.net/videos/LsCWmpa4UQHY/outputs/thumbnails/LsCWmpa4UQHY.0000000.jpg',
         poster: 'https://dfhvrmdevpx54.cloudfront.net/videos/LsCWmpa4UQHY/outputs/thumbnails/LsCWmpa4UQHY.0000000.jpg',
         description: 'Description',
         time: '17:55',
         viewsCount: '1234',
         likesCount: '245',
         commentsCount: '175',
         text: 'Lorem ipsum dolor sit amet',
         download_available_for_active_members: 0,
         completed: 0,
         is_published: 1,
         is_publish_enabled: 0,
         created_at: "2020-04-30 03:00:00",
      },
      {
         id: 3,
         title: 'dsfdfdf',
         status: 'uploading',
         src: 'https://dfhvrmdevpx54.cloudfront.net/videos/LsCWmpa4UQHY/outputs/thumbnails/LsCWmpa4UQHY.0000000.jpg',
         poster: 'https://dfhvrmdevpx54.cloudfront.net/videos/LsCWmpa4UQHY/outputs/thumbnails/LsCWmpa4UQHY.0000000.jpg',

         description: 'Description',
         time: '17:55',
         viewsCount: '1234',
         likesCount: '245',
         commentsCount: '175',
         text: 'Lorem ipsum dolor sit amet',
         download_available_for_active_members: 0,
         completed: 1,
         is_published: 0,
         is_publish_enabled: 0,
         created_at: "2020-03-03 12:38:10",
      },

      {
         id: 5,
         title: 'First dfsdfsdf dfsdfsdf dsfsdfsdf',
         src: photo2,
         status: 0,
         poster: '',
         description: 'Description',
         time: '17:55',
         viewsCount: '1234',
         likesCount: '245',
         commentsCount: '175',
         text: 'Lorem ipsum dolor sit amet',
         download_available_for_active_members: 1,
         completed: 1,
         is_published: 0,
         is_publish_enabled: 1,
         is_publish_enabled_date: "2020-04-30 03:00:00",
         created_at: "2020-04-30 03:00:00",
      },
      {
         id: 6,
         title: 'First dfsdfsdf dfsdfsdf dsfsdfsdf',
         src: photo2,
         status: 1,
         poster: '',
         description: 'Description',
         time: '17:55',
         viewsCount: '1234',
         likesCount: '245',
         commentsCount: '175',
         text: 'Lorem ipsum dolor sit amet',
         download_available_for_active_members: 1,
         completed: 1,
         is_published: 0,
         is_publish_enabled: 1,
         is_publish_enabled_date: "2020-04-30 03:00:00",
         created_at: "2020-03-03 12:38:10",
      },
      {
         credits_price_for_members: 13,
         credits_price_for_public: 52,
         description: "sxfgvb",
         download_available_for_active_members: 0,
         download_available_for_public: 1,
         id: 167,
         is_free: "0",
         is_future_publish_enabled: true,
         is_published: 0,
         likes_count: 0,
         price_for_members: 31,
         price_for_public: 25,
         price_is_same_for_all_members: 0,
         prices: [],
         publish_date: "2020-04-30 03:00:00",
         tags: [],
         title: "dcfgvbh",
         views_count: 0,
         created_at: "2020-03-03 12:38:10",
      },
   ],
}
