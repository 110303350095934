import photo1 from "../../../assets/images/photo_2.png";
import photo2 from "../../../assets/images/photo_5.png";

export default {
   list: [
      {
         id: 1,
         time: '10 photos',
         viewsCount: '1234',
         likesCount: '245',
         commentsCount: '175',
         status: 'Published 16 min ago',
         src: photo1,
         text: 'Lorem ipsum dolor sit amet',
      },
      {
         id: 2,
         time: '10 photos',
         viewsCount: '4567',
         likesCount: '564',
         commentsCount: '852',
         status: 'Published',
         src: photo2,
         text: 'Lorem ipsum dolor sit amet',
      },
   ],
}
