import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.css';

const TextArea = ({
   onChange,
   value,
   placeholder,
   type,
   disabled,
   className,
   name,
   label,
   TextAreaRef,
   width,
   errorMessage,
   onTextAreaFocus,
   onTextAreaBlur,
   description,
   descriptionFontWeight,
   descriptionFontColor,
   noneBorder,
   hideError,
   descriptionPosition,
   borderStyle,
   height,
   descriptionFontSize,
   labelFontWeight,
   areaClassName,
   onSetError,
   areWrapperClassName,
   ...rest
}) => {
   const [focusState, setFocusState] = useState(false);
   const [showError, setShowError] = useState(false);

   useEffect(() => {
      if(errorMessage) {
         setShowError(true)
         if(onSetError){
            onSetError(true);
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [errorMessage]);

   function handleOnChange(e) {
      setShowError(false)
      if(onSetError){
         onSetError(false);
      }
      if(onChange) {
         onChange(e.target.name, e.target.value);
      }
   }

   function handleTextAreaFocus(e) {
      if(onTextAreaFocus) {
         onTextAreaFocus(e);
      }
   }

   function handleTextAreaBlur(e) {
      setFocusState(false);
      if(onTextAreaBlur) {
         onTextAreaBlur(e);
      }
   }

   return (
      <div className={ `inline-flex flex-col w-${ width } h-auto ${ className }` }>
         {label
         && (
            <span
               className={ classNames({
                  [`leading-tight font-${ labelFontWeight } text-base mb-2`]: true,
                  'text-placeholder dark:text-placeholder-dark': disabled,
                  'text-major dark:text-major-dark': !disabled,
               }) }
            >
               {label}
            </span>
         )}
         <div className={ `w-full h-${ height } relative ${ areWrapperClassName }` }>
            <textarea
               { ...rest }
               ref={ TextAreaRef }
               name={ name }
               value={ value }
               disabled={ disabled }
               className={ `${ classNames({
                  [`admin_textarea placeholder:!leading-tight !leading-tight placeholder:text-placeholder placeholder:dark:text-placeholder placeholder:!tracking-[0.02px] placeholder:text-base rounded-lg w-full h-full dark:bg-black-field text-major dark:text-major-dark ${ areaClassName } p-3 outline-none border resize-none border-${ borderStyle }`]: true,
                  '': !showError,
                  'dark:border-divider-dark border-divider': !(showError && !focusState && !!errorMessage),
                  'border-error dark:border-error-dark': (showError && !focusState && !!errorMessage),
                  'text-disabled dark:text-disabled-dark dark:border-divider-dark border-divider placeholder:text-disabled placeholder:dark:text-disabled-dark bg-grey-main': disabled,
                  'bg-panel': !disabled,
                  'noneBorder': noneBorder,
               }) }` }
               placeholder={ placeholder }
               onFocus={ () => handleTextAreaFocus() }
               onBlur={ (e) => handleTextAreaBlur(e) }
               onChange={ (e) => handleOnChange(e) }
            />

         </div>
         <div className={ `flex flex-col mt-1.5 text-${ descriptionPosition } textarea-error-message` }>
            {
               description && (
                  <span
                     className={ `leading-none font-${ descriptionFontWeight } text-${ descriptionFontSize } text-${ descriptionFontColor } select-none` }
                  >
                     {description}
                  </span>
               )
            }
            {
               errorMessage && showError && !hideError && (
                  <span
                     className={ `text-xs text-error dark:text-error-dark block leading-none ${ !!description ? 'mt-1.5' : '' }` }
                  >
                     {errorMessage && !disabled && !focusState ? errorMessage : ''}
                  </span>
               )
            }
         </div>
      </div>
   );
};

TextArea.defaultProps = {
   placeholder: '',
   type: 'text',
   className: '',
   name: '',
   value: '',
   width: 'full',
   disabled: false,
   noneBorder: false,
   descriptionFontWeight: 'medium',
   descriptionFontColor: 'secondary dark:text-secondary-dark',
   hideError: false,
   descriptionPosition: 'left',
   borderStyle: 'solid',
   height: '40',
   descriptionFontSize: 'xs',
   labelFontWeight: 'medium',
   areWrapperClassName: '',
};

TextArea.propTypes = {
   label: PropTypes.string,
   onChange: PropTypes.func,
   value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
   name: PropTypes.string,
   className: PropTypes.string,
   descriptionFontWeight: PropTypes.string,
   descriptionFontColor: PropTypes.string,
   type: PropTypes.string,
   errorMessage: PropTypes.string,
   disabled: PropTypes.bool,
   placeholder: PropTypes.string,
   width: PropTypes.string,
   height: PropTypes.string,
   descriptionPosition: PropTypes.string,
   TextAreaRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
   ]),
   onTextAreaFocus: PropTypes.func,
   onSetError: PropTypes.func,
   onTextAreaBlur: PropTypes.func,
   description: PropTypes.string,
   noneBorder: PropTypes.bool,
   hideError: PropTypes.bool,
   descriptionFontSize: PropTypes.string,
   labelFontWeight: PropTypes.string,
   areaClassName: PropTypes.string,
   borderStyle: PropTypes.oneOf([
      'solid',
      'dashed',
      'dotted',
      'double',
      'none',
   ]),
   areWrapperClassName: PropTypes.string,
};


export default TextArea;

