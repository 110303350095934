import { isMobileAndTablet } from 'common/utils/utils';
import QueryParams from 'admin/utils/QueryParams';

export default () => {
   if(isMobileAndTablet()) {
      document.body.classList.add('select-none')
   }
   if(QueryParams.getParam('superAdminSession')) {
      localStorage.setItem('superAdminSession', 1);
      QueryParams.removeQueryParam('superAdminSession');
   }
};
