export const FETCH_REQUEST = "videos/FETCH_REQUEST";
export const FETCH_DETAILS_COMPLETED = "videos/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "videos/FETCH_DETAILS_FAILED";

export const FETCH_BY_FILTER_REQUEST = "videos/FETCH_BY_FILTER_REQUEST";
export const FETCH_BY_FILTER_DETAILS_COMPLETED = "videos/FETCH_BY_FILTER_DETAILS_COMPLETED";
export const FETCH_BY_FILTER_DETAILS_FAILED = "videos/FETCH_BY_FILTER_DETAILS_FAILED";
export const NEXT_PAGE_REQUEST = "videos/NEXT_PAGE_REQUEST";
export const NEXT_PAGE_COMPLETED = "videos/NEXT_PAGE_COMPLETED";
export const NEXT_PAGE_FAILED = "videos/NEXT_PAGE_FAILED";

export const FETCH_NOT_COMPLETED_REQUEST = "videos/FETCH_NOT_COMPLETED_REQUEST";
export const FETCH_NOT_COMPLETED_COMPLETED = "videos/FETCH_NOT_COMPLETED_COMPLETED";
export const FETCH_NOT_COMPLETED_FAILED = "videos/FETCH_NOT_COMPLETED_FAILED";

export const FETCH_VIDEO_BY_ID_REQUEST = 'videos/FETCH_VIDEO_BY_ID_REQUEST';
export const FETCH_VIDEO_BY_ID_COMPLETED = 'videos/FETCH_VIDEO_BY_ID_COMPLETED';
export const FETCH_VIDEO_BY_ID_FAILED = 'videos/FETCH_VIDEO_BY_ID_FAILED';

export const UPDATE_VIDEO_REQUEST = 'videos/UPDATE_VIDEO_REQUEST';
export const UPDATE_VIDEO_COMPLETED = 'videos/UPDATE_VIDEO_COMPLETED';
export const UPDATE_VIDEO_FAILED = 'videos/UPDATE_VIDEO_FAILED';
export const DELETE_VIDEO_REQUEST = 'videos/DELETE_VIDEO_REQUEST';
export const DELETE_VIDEO_COMPLETED = 'videos/DELETE_VIDEO_COMPLETED';
export const DELETE_VIDEO_FAILED = 'videos/DELETE_VIDEO_FAILED';

export const SET_BUTTON_DISABLED = 'videos/SET_BUTTON_DISABLED';
export const CLEAR_ERROR_REQUEST = 'videos/CLEAR_ERROR_REQUEST';
export const SET_STATUS_MODAL = 'videos/SET_STATUS_MODAL';
export const CLEAR_STATE = 'videos/CLEAR_STATE';
export const SET_FILTERS = 'videos/SET_FILTERS';

export const DISTORY_VIDEOS_REQUEST = 'videos/DISTORY_VIDEOS_REQUEST';
export const DISTORY_VIDEOS_COMPLETED = 'videos/DISTORY_VIDEOS_COMPLETED';
export const BULK_VIDEOS_REQUEST = 'videos/BULK_VIDEOS_REQUEST';
export const BULK_VIDEOS_COMPLETED = 'videos/BULK_VIDEOS_COMPLETED';
export const MULTIPLE_UPDATE = 'videos/MULTIPLE_UPDATE';
export const SET_BULK_IDS = 'videos/SET_BULK_IDS';
export const UPDATE_OR_ADD_PREVIEW_VIDEO = 'videos/UPDATE_OR_ADD_PREVIEW_VIDEO';
export const UPDATE_PUBLISH_DATA = 'videos/UPDATE_PUBLISH_DATA'
export const CLEAR_BULK_ERROR = 'videos/CLEAR_BULK_ERROR'
export const SET_EDIT_TITLE_MODAL = 'videos/SET_EDIT_TITLE_MODAL'
export const SET_REPLACE_STATE_ACTION = 'videos/SET_REPLACE_STATE_ACTION'
export const UPDATE_VIDEO_ON_VIDEOS_LIST = 'videos/UPDATE_VIDEO_ON_VIDEOS_LIST'

export const REORDER_VIDEOS = 'videos/REORDER_VIDEOS'
export const CHANGE_INITED_STATE = 'videos/CHANGE_INITED_STATE'

export const UPDATE_BUNDLE_STATE_IN_DATA = 'videos/UPDATE_BUNDLE_STATE_IN_DATA'
export const PIN_VIDEO_COMPLETED = 'videos/PIN_VIDEO_COMPLETED'
export const SET_WARNING_MODAL = 'videos/SET_WARNING_MODAL'
export const UPDATE_MESSAGE_ACTION = 'videos/UPDATE_MESSAGE_ACTION'

export const UPDATE_VIDEOS_LIST = 'photoset/UPDATE_VIDEOS_LIST'
