
export const FETCH_SHIPPING_ZONE_COUNTRIES = "shipping/FETCH_SHIPPING_ZONE_COUNTRIES"
export const FETCH_SHIPPING_ZONE_COUNTRIES_COMPLETED = "shipping/FETCH_SHIPPING_ZONE_COUNTRIES_COMPLETED"
export const FETCH_SHIPPING_ZONE_COUNTRIES_FAILED = "shipping/FETCH_SHIPPING_ZONE_COUNTRIES_FAILED"
export const FETCH_SHIPPING_ZONE_UPDATE = "shipping/FETCH_SHIPPING_ZONE_UPDATE"
export const FETCH_SHIPPING_ZONE_DELETE = "shipping/FETCH_SHIPPING_ZONE_DELETE"
export const FETCH_NEW_SHIPPING_OPTIONS = 'shipping/FETCH_NEW_SHIPPING_OPTIONS'
export const FETCH_SHIPPING_ZONE_COMPLETED = 'shipping/FETCH_SHIPPING_ZONE_COMPLETED'
export const FETCH_NEW_SHIPPING_OPTIONS_ADD = 'shipping/FETCH_NEW_SHIPPING_OPTIONS_ADD'
export const FETCH_NEW_SHIPPING_RATE_CHANGE = 'shipping/FETCH_NEW_SHIPPING_RATE_CHANGE'
export const SAVE_NEW_SHIPPING_ZONE_DATA = 'shipping/SAVE_NEW_SHIPPING_ZONE_DATA'
export const DELETE_DATA_FOR_NAME = 'shipping/DELETE_DATA_FOR_NAME'
export const RECHANGE_SHIPPING_DATA = ' shipping/RECHANGE_SHIPPING_DATA'
export const VALIDATION_SHIPPING_ERROR = 'shipping/VALIDATION_SHIPPING_ERROR'
