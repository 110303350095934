import * as types from "./types";

const getPayoutsWithdrawalsRequest = (bool) => ({
   type: types.FETCH_WITHDRAWALS_REQUEST,
   payload: bool,
});

const getPayoutsWithdrawalsCompleted = (data) => ({
   type: types.FETCH_WITHDRAWALS_COMPLETED,
   payload: data,
})
const getPayoutsWithdrawalInfoRequest = (bool) => ({
   type: types.FETCH_WITHDRAWAL_INFO_REQUEST,
   payload: bool,
});

const getPayoutsWithdrawalInfoCompleted = (data) => ({
   type: types.FETCH_WITHDRAWAL_INFO_COMPLETED,
   payload: data,
})
const updateNowRequest = (bool) => ({
   type: types.UPDATE_NOW_REQUEST,
   payload: bool,
});
const withdrawNowRequest = (bool) => ({
   type: types.WITHDRAWAL_NOW_REQUEST,
   payload: bool,
});

const withdrawNowCompleted = (data) => ({
   type: types.WITHDRAWAL_NOW_COMPLETED,
   payload: data,
})
const commitWithdrawRequest = (bool) => ({
   type: types.COMMIT_WITHDRAWAL_REQUEST,
   payload: bool,
});

const commitWithdrawCompleted = (data) => ({
   type: types.COMMIT_WITHDRAWAL_COMPLETED,
   payload: data,
})

const updateAutomaticWithdrawalCompleted = (data) => ({
   type: types.UPDATE_AUTOMATIC_WITHDRAWAL_COMPLETED,
   payload: data,
})
const updateAutomaticWithdrawalFailed = (data) => ({
   type: types.UPDATE_AUTOMATIC_WITHDRAWAL_FAILED,
   payload: data,
})
const updateAutomaticWithdrawalRequest = (data) => ({
   type: types.UPDATE_AUTOMATIC_WITHDRAWAL_REQUEST,
   payload: data,
})
// Adjustments
const getPayoutsAdjustmentsRequest = (bool) => ({
   type: types.FETCH_ADJUSTMENTS_REQUEST,
   payload: bool,
});

const getPayoutsAdjustmentsCompleted = (data) => ({
   type: types.FETCH_ADJUSTMENTS_COMPLETED,
   payload: data,
});
const getCollapseAdjustmentRequest = (id, bool) => ({
   type: types.FETCH_COLLAPSE_ADJUSTMENT_REQUEST,
   payload: { id, bool },
});

const getCollapseAdjustmentCompleted = (id, data) => ({
   type: types.FETCH_COLLAPSE_ADJUSTMENT_COMPLETED,
   payload: { id, data },
});
// Holds
const getPayoutsHoldsRequest = (bool) => ({
   type: types.FETCH_HOLDS_REQUEST,
   payload: bool,
});

const getPayoutsHoldsCompleted = (data) => ({
   type: types.FETCH_HOLDS_COMPLETED,
   payload: data,
});
// History
const getPayoutsHistoryRequest = (bool) => ({
   type: types.FETCH_HISTORY_REQUEST,
   payload: bool,
});

const getPayoutsHistoryCompleted = (data) => ({
   type: types.FETCH_HISTORY_COMPLETED,
   payload: data,
});
// Init
const getPayoutsInitRequest = (bool) => ({
   type: types.FETCH_INIT_REQUEST,
   payload: bool,
});

const getPayoutsInitCompleted = (data) => ({
   type: types.FETCH_INIT_COMPLETED,
   payload: data,
});

//payoutSrv
const getPayoutsPayoutSrvCountryRequest = (bool) => ({
   type: types.FETCH_PAYOUT_SRV_COUNTRY_REQUEST,
   payload: bool,
});

const getPayoutsPayoutSrvCountryCompleted = (data) => ({
   type: types.FETCH_PAYOUT_SRV_COUNTRY_COMPLETED,
   payload: data,
});
const getPayoutSrvServicesForCountryRequest = (bool) => ({
   type: types.GET_PAYOUT_SRV_SERVICES_FOR_COUNTRY_REQUEST,
   payload: bool,
});

const getPayoutSrvServicesForCountryCompleted = (data) => ({
   type: types.GET_PAYOUT_SRV_SERVICES_FOR_COUNTRY_COMPLETED,
   payload: data,
});
const updatePayoutInitAction = (data) => ({
   type: types.UPDATE_PAYOUT_INIT,
   payload: data,
});
const getPayoutSrvAttributesForTokenRequest = (bool) => ({
   type: types.GET_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_REQUEST,
   payload: bool,
});

const getPayoutSrvAttributesForTokenCompleted = (data) => ({
   type: types.GET_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_COMPLETED,
   payload: data,
});
const createPayoutSrvAttributesForTokenRequest = (bool) => ({
   type: types.CREATE_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_REQUEST,
   payload: bool,
});

const createPayoutSrvAttributesForTokenCompleted = (data) => ({
   type: types.CREATE_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_COMPLETED,
   payload: data,
});
const createPayoutSrvAttributesForTokenFailed = (data) => ({
   type: types.CREATE_PAYOUT_SRV_ATTRIBUTES_FOR_TOKEN_FAILED,
   payload: data,
});
const setWalletPayoutMethodRequest = (bool) => ({
   type: types.SET_WALLET_PAYOUT_METHOD_REQUEST,
   payload: bool,
});

const setWalletPayoutMethodCompleted = (data) => ({
   type: types.SET_WALLET_PAYOUT_METHOD_COMPLETED,
   payload: data,
});
const setWalletPayoutMethodFailed = (data) => ({
   type: types.SET_WALLET_PAYOUT_METHOD_FAILED,
   payload: data,
});
const deleteCurrentPayoutMethodAction = (data, isSuccess = false) => ({
   type: types.DELETE_CURRENT_PAYOUT_METHOD_ACTION,
   payload: { data, isSuccess },
});
const update2FaModalStateAction = (data) => ({
   type: types.UPDATE_2FA_MODAL_STATE_ACTION,
   payload: data,
});
const setPayoutTermsModalAction = (data) => ({
   type: types.SET_PAYOUT_TERMS_MODAL,
   payload: data,
});
const setOpenIncompleteLegalInfoModal = (bool, data) => ({
   type: types.OPEN_INCOMPLETE_LEGAL_INFO_MODAL,
   payload: { bool, data },
});

export {
   //Withdrawals
   getPayoutsWithdrawalsRequest,
   getPayoutsWithdrawalsCompleted,
   getPayoutsWithdrawalInfoRequest,
   getPayoutsWithdrawalInfoCompleted,
   updateNowRequest,
   withdrawNowRequest,
   withdrawNowCompleted,
   commitWithdrawRequest,
   commitWithdrawCompleted,
   updateAutomaticWithdrawalCompleted,
   updateAutomaticWithdrawalFailed,
   updateAutomaticWithdrawalRequest,
   //Adjustments
   getPayoutsAdjustmentsRequest,
   getPayoutsAdjustmentsCompleted,
   getCollapseAdjustmentRequest,
   getCollapseAdjustmentCompleted,
   //Holds
   getPayoutsHoldsRequest,
   getPayoutsHoldsCompleted,
   //History
   getPayoutsHistoryRequest,
   getPayoutsHistoryCompleted,
   //init
   getPayoutsInitRequest,
   getPayoutsInitCompleted,

   // payoutSrv
   getPayoutsPayoutSrvCountryRequest,
   getPayoutsPayoutSrvCountryCompleted,
   getPayoutSrvServicesForCountryRequest,
   getPayoutSrvServicesForCountryCompleted,
   updatePayoutInitAction,
   getPayoutSrvAttributesForTokenRequest,
   getPayoutSrvAttributesForTokenCompleted,
   createPayoutSrvAttributesForTokenRequest,
   createPayoutSrvAttributesForTokenCompleted,
   createPayoutSrvAttributesForTokenFailed,
   setWalletPayoutMethodRequest,
   setWalletPayoutMethodCompleted,
   setWalletPayoutMethodFailed,
   deleteCurrentPayoutMethodAction,
   update2FaModalStateAction,
   setPayoutTermsModalAction,
   setOpenIncompleteLegalInfoModal,
};
