
import { createSelector } from 'reselect'

const innerStateSelector = state => state.members;


// const membersSelector = createSelector(
//    innerStateSelector,
//    (state) => (state.membersData)
// );
const membersSelector = (state) => {
   let result = state.members.membersData;
   const { onlineUsers } = state.chat;
   const updateData = [...result.data].map(i => {
      let { ...elm } = i;
      const isOnline = onlineUsers.findIndex(e => e.uuid === elm.uuid) > -1;
      elm.is_online = isOnline;
      return elm
   })
   return { ...result, data: updateData }
};


const memberSelector = createSelector(
   innerStateSelector,
   (state) => (state.member)
);

const isNewFetching = createSelector(
   innerStateSelector,
   state => state.isNewFetching
);

const membershipsSelector = createSelector(
   innerStateSelector,
   (state) => (state.memberships)
);

const isFetchingMemberships = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingMemberships)
);

const fetchingSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetching)
);

const filterFetchingSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFilterFetching)
);

const statisticsDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.statistics)
);

const isStatisticsFetching = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingStatistics)
);

const freeMemberToAddIds = createSelector(
   innerStateSelector,
   (state) => state.freeMemberToAddIds
)

const freeMemberToAddMembership = createSelector(
   innerStateSelector,
   (state) => state.freeMemberToAddMembership
)

const isFetchingAddFreeMember = createSelector(
   innerStateSelector,
   (state) => state.isFetchingAddFreeMember
)

const isFetchingAddNewPerson = createSelector(
   innerStateSelector,
   (state) => state.isFetchingAddNewPerson
)

const errorsSelector = createSelector(
   innerStateSelector,
   (state) => state.errors
)

const emptyDataSelector = createSelector(
   innerStateSelector,
   state => state.isEmpty
);
const isEmptyByFilterSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyByFilter
);
const updateErrorsSelector = createSelector(
   innerStateSelector,
   state => state.updateErrors
);
const isOpenUsernameModalSelector = createSelector(
   innerStateSelector,
   state => state.isOpenUsernameModal
);

const isFetchingWalletHistorySelector = createSelector(
   innerStateSelector,
   state => state.isFetchingWalletHistory
);

const walletHistorySelector = createSelector(
   innerStateSelector,
   state => state.walletHistory
);

const isEmptyWalletHistorySelector = createSelector(
   innerStateSelector,
   state => state.isEmptyWalletHistory
);
const isNewFetchingWalletHistorySelector = createSelector(
   innerStateSelector,
   state => state.isNewFetchingWalletHistory
);

// specific member tags
const isFetchingMemberTagsSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingMemberTags
);
const isEmptyMemberTagsSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyMemberTags
);
const memberTagsSelector = createSelector(
   innerStateSelector,
   state => state.memberTags
);
const activeMemberSelector = createSelector(
   innerStateSelector,
   state => state.activeMember
);

const isFetchingAddNoteSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingAddNote
);
const isFetchingDeleteNoteSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingDeleteNote
);
const isFetchingUpdateNoteSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingUpdateNote
);

export {
   membersSelector,
   fetchingSelector,
   filterFetchingSelector,
   statisticsDataSelector,
   isStatisticsFetching,
   memberSelector,
   membershipsSelector,
   isFetchingMemberships,
   isNewFetching,
   freeMemberToAddIds,
   freeMemberToAddMembership,
   isFetchingAddFreeMember,
   isFetchingAddNewPerson,
   errorsSelector,
   emptyDataSelector,
   isEmptyByFilterSelector,
   updateErrorsSelector,
   isOpenUsernameModalSelector,
   isFetchingWalletHistorySelector,
   walletHistorySelector,
   isEmptyWalletHistorySelector,
   isNewFetchingWalletHistorySelector,
   isFetchingMemberTagsSelector,
   isEmptyMemberTagsSelector,
   memberTagsSelector,
   activeMemberSelector,
   isFetchingAddNoteSelector,
   isFetchingDeleteNoteSelector,
   isFetchingUpdateNoteSelector,
}
