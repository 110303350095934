const initialState = {
   selectColor: {},
   isFetching: true,
   colors: [],
   customPalette: {
      primary_color: '#FF2480',
      secondary_color: '#3D3D3D',
   },
   selectFetching: false,
   isFetchingDeleteSite: false,

   isFetchingLogo: true,
   logoData: {},
   siteColorsArray: [],
   uploadLogo: {
      main: false,
      favicon: false,
      isOpenModal: false,
   },
};

export default initialState;
