export default class NumberFormatter
{
   withCommas(number = 0) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   }

   withSpaces(number = 0) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
   }
   withDots(number = 0) {
      number = Number(number).toFixed(2);
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   }
}
