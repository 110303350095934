import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';



let reducersMap = {
   // fetch members tags
   [types.FETCH_MEMBERS_TAGS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
         isEmptyByFilter: false,
         isFetchingByFilter: false,
      }
   },
   [types.FETCH_MEMBERS_TAGS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         tagsData: action.payload,
         isFetching: false,
         isEmpty: payload.data.length === 0,
      }
   },
   [types.FETCH_MEMBERS_TAGS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
         errorData: action.payload,
      }
   },

   // fetch new members tags
   [types.FETCH_NEW_MEMBERS_TAGS_START]: (state) => {
      return {
         ...state,
         isNewFetching: true,
      }
   },
   [types.FETCH_NEW_MEMBERS_TAGS_COMPLETED]: (state, action) => {
      const tagsData = action.payload;
      return {
         ...state,
         tagsData: {
            ...tagsData,
            data: [
               ...state.tagsData.data,
               ...tagsData.data,
            ],
         },
         isNewFetching: false,
      }
   },
   [types.FETCH_NEW_MEMBERS_TAGS_FAILED]: (state, action) => {
      return {
         ...state,
         isNewFetching: false,
         errorData: action.payload,
      }
   },

   // fetch members tags by filters
   [types.TAGS_FILTER_START]: (state, action) => {
      return {
         ...state,
         isFetchingByFilter: true,
         activeFilter: action?.payload,
      }
   },
   [types.TAGS_FILTER_COMPLETED]: (state, action) => {
      const { payload } = action;
      let data
      if(state?.activeFilter === 'auto') {
         data = {
            ...action.payload,
            data: action.payload?.data.filter(tag => !!tag?.rule),
         }
      } 
      else if(state?.activeFilter === 'custom') {
         data = {
            ...action.payload,
            data: action.payload?.data.filter(tag => !tag?.rule),
         }
      }
      else {
         data = {
            ...action.payload,
            data: action.payload?.data,
         }
      }
      return {
         ...state,
         isFetchingByFilter: false,
         tagsData: data,
         isEmptyByFilter: payload.data.length === 0,
      }
   },
   [types.TAGS_FILTER_FAILED]: (state) => {
      return {
         ...state,
         isFetchingByFilter: false,
      }
   },

   // add members tag
   [types.ADD_NEW_TAG_START]: (state, action) => {
      return {
         ...state,
      }
   },
   [types.ADD_NEW_TAG_COMPLETED]: (state, action) => {
      const { payload } = action;
      const isAutoTagFilter = state.tagsData?.data?.filter(tag => !!tag?.rule) && payload[1] === 'auto'
      const isCustomTagFilter = state.tagsData?.data?.filter(tag => !tag?.rule) && payload[1] === 'custom'

      let data

      if(isAutoTagFilter && !payload[0].rule) {
         data = {
            ...state.tagsData,
            data: [
               ...state.tagsData?.data?.filter(tag => !!tag?.rule),
            ],
         }
      }
      else if(isCustomTagFilter && payload[0].rule) {
         data = {
            ...state.tagsData,
            data: [
               ...state.tagsData?.data?.filter(tag => !tag?.rule),
            ],
         }
      }
      else if(isAutoTagFilter && state.tagsData?.data?.length === 0 && !payload[0].rule) {
         data = {
            ...state.tagsData,
            data: [
               ...state.tagsData?.data?.filter(tag => !!tag?.rule),
               payload[0],
            ],
         }
      }
      else if(isAutoTagFilter && state.tagsData?.data?.length === 0 && !!payload[0].rule) {
         data = {
            ...state.tagsData,
            data: [
               ...state.tagsData?.data,
               payload[0],
            ],
         }
         
      }
      else if(isCustomTagFilter && state.tagsData?.data?.length === 0 && !payload[0].rule) {
         data = {
            ...state.tagsData,
            data: [
               ...state.tagsData?.data,
               payload[0],
            ],
         }
      }
      
      else {
         data = {
            ...state.tagsData,
            data: [
               ...state.tagsData?.data,
               payload.length === 2 ? payload[0] : payload,
            ],
         }
      }
      return {
         ...state,
         tagsData: { ...data },
         isEmpty: false,
         isEmptyByFilter: false,
      }
   },
   [types.ADD_NEW_TAG_FAILED]: (state, action) => {
      return {
         ...state,
      }
   },

   // add members tag
   [types.UPDATE_MEMBERS_TAG_START]: (state, action) => {
      return {
         ...state,
      }
   },
   [types.UPDATE_MEMBERS_TAG_COMPLETED]: (state, action) => {
      const { id, data } = action.payload;
      let tagsData = state.tagsData?.data.map(item => {
         if(item.id === id) {
            return {
               ...item,
               ...data,
            };
         }
         return item;
      })
      return {
         ...state,
         tagsData: {
            ...state.tagsData,
            data: [...tagsData],
         },
      }
   },
   [types.UPDATE_MEMBERS_TAG_FAILED]: (state, action) => {
      return {
         ...state,
      }
   },

   // delete members tag
   [types.DELETE_MEMBERS_TAG_START]: (state, action) => {
      return {
         ...state,
      }
   },
   [types.DELETE_MEMBERS_TAG_COMPLETED]: (state, action) => {
      const id = action.payload;
      let data = state.tagsData?.data?.filter(item => item.id !== id)
      return {
         ...state,
         tagsData: {
            ...state.tagsData,
            data: [...data],
         },
         isEmpty: data.length === 0,
      }
   },
   [types.DELETE_MEMBERS_TAG_FAILED]: (state, action) => {
      return {
         ...state,
      }
   },
   // delete member tags
   [types.DELETE_MEMBERS_TAGS_START]: (state, action) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.DELETE_MEMBERS_TAGS_COMPLETED]: (state, action) => {
      const ids = action.payload?.ids;
      let data = state.tagsData?.data?.filter(item => !ids.includes(item.id))
      return {
         ...state,
         isFetching: false,
         tagsData: {
            ...state.tagsData,
            data: [...data],
         },
         isEmpty: data.length === 0,
      }
   },
   [types.DELETE_MEMBERS_TAGS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

}

export default createReducer(initialState)(reducersMap);
