const initialState = {
   isFetching: true,
   isEmpty: false,
   isFetchingByFilter: false,
   isEmptyByFilter: false,
   isNewFetchingTags: false,
   tagsData: {
      data: [],
   },
   errorData: {},
   activeFilter: '',
}
export default initialState
