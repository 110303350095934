import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   [types.FETCH_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload: { data } } = action;
      return {
         ...state,
         data: data,
         isFetching: false,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.FETCH_PAYOUT_DETAILS_START]: (state) => {
      return {
         ...state,
         // isFetching: true,
         isPayoutFetching: true,
      }
   },
   [types.FETCH_PAYOUT_DETAILS_COMPLETED]: (state, action) => {
      const  { payload: { data } } = action;
      let activeValue = {}
      if(data.selected_payout && data.data[data.selected_payout]){
         activeValue = data.data[data.selected_payout]
      }
      return {
         ...state,
         // isFetching: false,
         isPayoutFetching: false,
         activePayout: { data: activeValue, type: data.selected_payout },
         payoutData: data,
      }
   },

   [types.FETCH_PAYOUT_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         // isFetching: false,
         isPayoutFetching: false,
      }
   },

   [types.UPDATE_START]: (state, action) => {
      return {
         ...state,
         errors: {},
         isFetchUpdate: true,
      }
   },
   [types.UPDATE_COMPLETED]: (state, action) => {
      return {
         ...state,
         data: {
            ...state.data,
            ...action.payload,
         },
         errors: {},
         isFetchUpdate: false,
      }
   },
   [types.UPDATE_FAILED]: (state, action) => {
      const  { payload: { data } } = action;
      return {
         ...state,
         errors: data,
         isFetchUpdate: false,
      }
   },

   // update password
   [types.UPDATE_PASSWORD]: (state, action) => {
      return {
         ...state,
         fetchingChangePassword: true,
         updatePasswordError: {},
      }
   },
   [types.UPDATE_PASSWORD_COMPLETED]: (state, action) => {
      const payload = { action };
      let newState = {};
      if(payload){
         newState = {
            data: {
               ...state.data,
               ...action.payload,
            },
         };
      }
      return {
         ...state,
         fetchingChangePassword: false,
         updatePasswordError: {},
         validPasswordInputs: {},
         securityTabModal: {
            isOpen: false,
            type: null,
         },
         ...newState,
      }
   },
   [types.UPDATE_PASSWORD_FAILED]: (state, action) => {
      const  { payload: { errors, validInput } } = action;
      return {
         ...state,
         updatePasswordError: errors,
         validPasswordInputs: { ...state.validPasswordInputs, ...validInput },
         fetchingChangePassword: false,
      }
   },
   [types.SET_VALID_PASWWORD_INPUTS]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         validPasswordInputs: { ...state.validPasswordInputs, ...payload },
      }
   },

   // update password
   [types.UPDATE_PAYOUT]: (state, action) => {
      return {
         ...state,
         payoutErrors: {
            ach: {},
            sepa: {},
            wire: {},
            paypal: {},
            cosmopayment: {},
            paxum: {},
         },
      }
   },
   [types.UPDATE_PAYOUT_COMPLETED]: (state, action) => {
      const { payload } = action
      let data = {
         data: {
            ...state.payoutData.data,
            [payload.type]: {
               email: payload.email,
            },
         },
         selected_payout: payload.type,
      }

      return {
         ...state,
         activeDetailsType: payload.type,
         detailsValue: payload,
         payoutData: data,
      }
   },
   [types.UPDATE_PAYOUT_FAILED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         payoutErrors: {
            ...state.payoutErrors,
            [payload.type]: payload.data,
         },
      }
   },

   [types.GO_DETAILS_PAGE]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         activeDetailsType: payload,
      }
   },

   [types.REMOVE_PAYOUT_ERRORS]: (state) => {
      return {
         ...state,
         payoutErrors: {
            ach: {},
            sepa: {},
            wire: {},
            paypal: {},
            cosmopayment: {},
            paxum: {},
         },
      }
   },


   // Notification preferences

   [types.FETCH_NOTIFICATION_PREFERENCES_START]: (state) => {
      return {
         ...state,
         isFetchingNotificationPreferences: true,
      }
   },
   [types.FETCH_NOTIFICATION_PREFERENCES_COMPLETED]: (state, action) => {
      const  { payload } = action;
      let data = {
         ...state.notificationPreferences,
         ...payload,
      }
      return {
         ...state,
         isFetchingNotificationPreferences: false,
         notificationPreferences: data,
      }
   },
   [types.FETCH_NOTIFICATION_PREFERENCES_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingNotificationPreferences: false,
      }
   },

   [types.UPDATE_NOTIFICATION_PREFERENCES_START]: (state) => {
      return { ...state }
   },
   [types.UPDATE_NOTIFICATION_PREFERENCES_COMPLETED]: (state, action) => {
      const  { payload: { key, payload } } = action;
      let data = {
         ...state.notificationPreferences,
         [key]: { ...payload },
      }
      return {
         ...state,
         notificationPreferences: data,
      }
   },
   [types.UPDATE_NOTIFICATION_PREFERENCES_FAILED]: (state, action) => {
      return { ...state }
   },


   [types.UNSUBSCRIBE_FROM_ALL_NOTIFICATIONS]: (state, action) => {
      let data = Object.entries(state.notificationPreferences);
      let newData = {};
      for(const [key] of data) {
         newData[key] = {
            in_app: false,
            push: false,
            email: false,
            notify_me_every: "day",
         }
      }
      return {
         ...state,
         notificationPreferences: { ...newData },
      }
   },

   [types.SET_SECURITY_TAB_MODAL]: (state, action) => {
      const { payload } = action;
      let newState = {}
      if('change_password' === payload?.type || 'set_password' === payload?.type){
         newState = {
            updatePasswordError: {},
            validPasswordInputs: {},
         }
      }
      if('change_email' === payload?.type){
         newState = {
            updateEmailError: {},
         }
      }
      return {
         ...state,
         ...newState,
         securityTabModal: payload,
      }
   },

   [types.SET_TWO_FA_MODAL_ACTION]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         twoFaModal: {
            ...state.twoFaModal,
            ...payload,
         },
      };
   },

   [types.UPDATE_EMAIL_START]: (state, action) => {
      return {
         ...state,
         updateEmailError: {},
         fetchingChangeEmail: true,
      };
   },
   [types.UPDATE_EMAIL_COMPLETED]: (state, action) => {
      const { payload: { securityTabModal, profileData } } = action;
      let newState = {};
      if(profileData){
         newState = {
            data: {
               ...state.data,
               ...profileData,
            },
         }
      }
      return {
         ...state,
         fetchingChangeEmail: false,
         ...newState,
         securityTabModal: {
            ...state.securityTabModal,
            ...securityTabModal,
         },
      };
   },
   [types.UPDATE_EMAIL_FAILED]: (state, action) => {
      const { payload } = action;
      console.log(payload)
      return {
         ...state,
         fetchingChangeEmail: false,
         updateEmailError: payload,
      };
   },

   // set password
   [types.SET_PASSWORD_START]: (state) => {
      return {
         ...state,
         fetchingSetPassword: true,
      }
   },
   [types.SET_PASSWORD_COMPLETED]: (state, action) => {
      const { payload: { securityTabModal, profileData } } = action;
      let newState = {};
      if(profileData){
         newState = {
            data: {
               ...state.data,
               ...profileData,
            },
         }
      }
      return {
         ...state,
         fetchingSetPassword: false,
         ...newState,
         securityTabModal: {
            ...state.securityTabModal,
            ...securityTabModal,
         },
      };
   },
   [types.SET_PASSWORD_FAILED]: (state, action) => {
      const  { payload: { errors, validInput } } = action;
      return {
         ...state,
         updatePasswordError: errors,
         validPasswordInputs: { ...state.validPasswordInputs, ...validInput },
         fetchingSetPassword: false,
      }
   },
   [types.SET_ADDRESS_EDIT_MODE]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         addressEditMode: payload,
      }
   },
};

export default createReducer(initialState)(reducersMap);
