import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   [types.ADD_UPLOADERS]: (state, action) => {
      return {
         ...state,
         ...action.payload,
      }
   },
   [types.ADD_VIDEOS]: (state, action) => {
      return {
         ...state,
         videos: [...action.payload.videos, ...state.videos],
      }
   },
   [types.TOGGLE_VIDEO_UPLOAD_LIMIT]: (state, action) => {
      return {
         ...state,
         isVideoUploadLimitReached: action.payload,
         isIncreaseMyLimitClicked: false,
      }
   },
   [types.TOGGLE_INCREASE_MY_LIMIT_CLICKED]: (state, action) => {
      return {
         ...state,
         isIncreaseMyLimitClicked: action.payload,
      }
   },
   [types.UPDATE_OR_ADD_VIDEO]: (state, action) => {
      const candidate = state.videos.find((video) => {
         return action.payload.id === video.id;
      });
      let newVideos = null;
      if(candidate) {
         newVideos = state.videos.map((video) => {
            if(action.payload.id === video.id) {
               return {
                  ...video,
                  ...action.payload.body,
               }
            }
            return {
               ...video,
            }
         });
      } else {
         newVideos = [action.payload.body, ...state.videos];
      }
      return {
         ...state,
         videos: newVideos,
      }
   },
   [types.REMOVE_VIDEO]: (state, action) => {
      return {
         ...state,
         videos: state.videos.filter((video) => {
            return action.payload.id + '' !== video.id + '';
         }),
      }
   },
   [types.RESET_VIDEOS]: () => {
      return {
         ...initialState,
      }
   },
   [types.RESET_PHOTOSTES]: (state, action) => {
      return {
         ...state,
         photosets: [...state.photosets].filter(photoset => photoset.id !== Number(action.payload)),
      }
   },
   [types.ADD_OR_UPDATE_PHOTOSTES]: (state, action) => {
      const {
         data,
         id,
      } = action.payload
      const candidate = state.photosets.find((photo) => id === photo.id)
      let newPhotoset = null;

      if(candidate) {
         newPhotoset = state.photosets.map((photo) => {
            if(id === photo.id) {
               return {
                  ...photo,
                  ...data,
               }
            }
            return photo
         });
      } else {
         const { photos, id, cover } = data
         let coverData = photos?.find(el => +el?.id === +cover)
         let newData = {
            cover_image: coverData?.cf_thumb_v2_path,
            id,
            photos_count: photos?.length,
         }
         newPhotoset = [newData, ...state.photosets];
      }

      return {
         ...state,
         photosets: newPhotoset,
      }
   },

   [types.DESTORY_MEDIA_REQUEST]: (state, action) => {
      return {
         ...state,
         isDistoryFetching: action.payload,
      }
   },
   [types.DESTORY_MEDIA_COMPLETED]: (state, action) => {
      const { payload: { ids, type, excludedIds } } = action
      let newState = {}
      if('video' === type){
         newState = {
            videos: 'all' === ids ? [...state.videos].filter(video => excludedIds.includes(video.id)) : [...state.videos].filter(video => !ids.includes(video.id)),
         }
      }
      if('photoset' === type){
         newState = {
            photosets: 'all' === ids ? [...state.photosets].filter(photoset => excludedIds.includes(photoset.id)) : [...state.photosets].filter(photoset => !ids.includes(photoset.id)),
         }
      }
      return {
         ...state,
         isDistoryFetching: false,
         ...newState,
      }
   },
};

export default createReducer(initialState)(reducersMap);
