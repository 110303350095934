const initialState = {
   isFetching: true,
   isFetchingStats: true,
   isFetchingById: true,
   isFetchingExportCSV: false,
   isNewFetching: false,
   isEmpty: false,
   payoutDetails: {},
   payouts: {},
   statistic: {},
};

export default initialState;
