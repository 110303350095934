import React, { Suspense, useEffect } from "react";
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCurrentRoute } from 'admin/routes/router';

function Suspenser({
   children,
}) {
   return (
      <Suspense fallback={ <div></div> }>
         { children }
      </Suspense>
   );
}

Suspenser.propTypes = {
   children: PropTypes.any,
};

export default (WrappedComponent) => props => {
   const { location } = useSelector(state => state.router)
   useEffect(() => {
      const pathname = location?.pathname || '';
      const currentRoute = getCurrentRoute(pathname);
      if(currentRoute){
         const pageTitle = currentRoute.getTitle();
         const documentTitle = `Dashboard - ${ pageTitle }`;
         if(pageTitle && window.document.title !== documentTitle){
            window.document.title = documentTitle;
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return (
      <Suspenser>
         <WrappedComponent { ...props } />
      </Suspenser>
   )
};
