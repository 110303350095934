export const FETCH_DETAILS = "customPages/FETCH_DETAILS";
export const FETCH_DETAILS_COMPLETED = "customPages/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "customPages/FETCH_DETAILS_FAILED";

export const DELETE_CUSTOM_PAGE = "customPages/DELETE_CUSTOM_PAGE";

export const CREATE_NEW_FETCHING = "customPages/CREATE_NEW_FETCHING";
export const CREATE_NEW_FAILED = "customPages/CREATE_NEW_FAILED";

export const UPDATE_DATA_START = "customPages/UPDATE_DATA_START";
export const UPDATE_DATA_COMPLETED = "customPages/UPDATE_DATA_COMPLETED";
export const UPDATE_DATA_FAILED = "customPages/UPDATE_DATA_FAILED";
export const SET_OPTIONS_MODAL = "customPages/SET_OPTIONS_MODAL";

export const FETCH_DETAILS_BY_ID_START = "customPages/FETCH_DETAILS_BY_ID_START";
export const FETCH_DETAILS_BY_ID_COMPLETED = "customPages/FETCH_DETAILS_BY_ID_COMPLETED";
export const FETCH_NEW_DATA = "customPages/FETCH_NEW_DATA";
export const FETCH_NEW_DATA_COMPLETED = "customPages/FETCH_NEW_DATA_COMPLETED";
export const CLEAR_ERRORS_ACTION = "customPages/CLEAR_ERRORS_ACTION";
