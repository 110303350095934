const initialState = {
   data: {},
   isFetching: true,
   isEmpty: true,
   errors: {},
   affiliatesListData: {},
   isFetchingListData: true,
   isFetchingListDataNextPage: false,
};

export default initialState;
