import PropTypes from 'prop-types';
import ConfirmModal from 'common/components/modules/modal-wrapper';
import { getSiteNameHtml } from 'common/utils/helper';
import mylistLogo from 'assets/images/mylist-logo-icon.svg'


const CreateMylistAccountModal = ({
   onCloseModal,
   onClickLearnMore,
   onClickCreate,
   isLoading,
}) => {

   return (
      <ConfirmModal
         type='confirm'
         isTitleHtml
         title={ `Create ${ getSiteNameHtml('list') } account` }
         titleClassName='!font-normal'
         description={ `
            With a ${ getSiteNameHtml('list') } account, you can easily share your social links, receive gifts from, and engage with your fans. All with a 100% payout rate!
         ` }
         buttonText={ `Create ${ getSiteNameHtml('list') } account` }
         buttonIconName='go-to'
         primaryButtonClassName='gap-2 !px-[14px]'
         confirmButtonProperties={ {
            fontIconSize: 'extraLarge',
            textMarginX: '0',
         } }
         secondaryButtonClassName='px-[6px]'
         secondButtonTitle={ `Learn more about ${ getSiteNameHtml('list') }` }
         secondButtonDisabled={ false }
         secondButtonClassName='px-[6px]'
         overrideSecondButtonProps={ {
            textColor: 'secondary',
         } }
         imageAsIconSrc={ mylistLogo }
         onCloseModal={ onCloseModal }
         secondButtonAction={ onClickLearnMore }
         action={ onClickCreate }
         isLoading={ isLoading }
      />
   )
}

CreateMylistAccountModal.propTypes = {
   onCloseModal: PropTypes.func,
   onClickLearnMore: PropTypes.func,
   onClickCreate: PropTypes.func,
   isLoading: PropTypes.bool,
}

export default CreateMylistAccountModal;
