import * as types from "./types";

//FETCH DATA /
const fetchTransactionsRequest = () => ({
   type: types.FETCH_TRANSACTIONS_REQUEST,
});

const fetchTransactionsCompleted = (data) => ({
   type: types.FETCH_TRANSACTIONS_COMPLETED,
   payload: data,
})

const fetchTransactionsFailed = (err) => ({
   type: types.FETCH_TRANSACTIONS_FAILED,
   payload: {
      err,
   },
})

const fetchTransactionsByFilterRequest = () => ({
   type: types.FETCH_TRANSACTIONS_BY_FILTER_START,
});

const fetchTransactionsByFilterCompleted = (data) => ({
   type: types.FETCH_TRANSACTIONS_BY_FILTER_COMPLETED,
   payload: data,
})

const fetchTransactionsByFilterFailed = (err) => ({
   type: types.FETCH_TRANSACTIONS_BY_FILTER_FAILED,
   payload: {
      err,
   },
})

const getNewDataRequest = () => ({
   type: types.FETCH_NEW_DATA_START,
});

const getNewDataCompleted = (data) => ({
   type: types.FETCH_NEW_DATA_COMPLETED,
   payload: data,
})

const getNewDataFailed = (err) => ({
   type: types.FETCH_NEW_DATA_FAILED,
   payload: {
      err,
   },
})

//FETCH details /
const fetchDetailsRequest = () => ({
   type: types.FETCH_DETAILS_REQUEST,
});

const fetchDetailsCompleted = (data) => ({
   type: types.FETCH_DETAILS_COMPLETED,
   payload: data,
})

const fetchDetailsFailed = (err) => ({
   type: types.FETCH_DETAILS_FAILED,
   payload: {
      err,
   },
})

const exportCSVFileRequest = () => ({
   type: types.EXPORT_CSV_START,
});

const exportCSVFileCompleted = () => ({
   type: types.EXPORT_CSV_COMPLETED,
})


//FETCH WALLET ACTIVITY DATA /

const fetchTransactionsWalletActivityRequest = () => ({
   type: types.FETCH_TRANSACTIONS_WALLET_ACTIVITY_REQUEST,
});

const fetchTransactionsWalletActivityCompleted = (data) => ({
   type: types.FETCH_TRANSACTIONS_WALLET_ACTIVITY_COMPLETED,
   payload: data,
})

const fetchTransactionsWalletActivityFailed = (err) => ({
   type: types.FETCH_TRANSACTIONS_WALLET_ACTIVITY_FAILED,
   payload: {
      err,
   },
})

const fetchTransactionsWalletActivityByFilterRequest = () => ({
   type: types.FETCH_TRANSACTIONS_WALLET_ACTIVITY_BY_FILTER_START,
});

const fetchTransactionsWalletActivityByFilterCompleted = (data) => ({
   type: types.FETCH_TRANSACTIONS_WALLET_ACTIVITY_BY_FILTER_COMPLETED,
   payload: data,
})

const fetchTransactionsWalletActivityByFilterFailed = (err) => ({
   type: types.FETCH_TRANSACTIONS_WALLET_ACTIVITY_BY_FILTER_FAILED,
   payload: {
      err,
   },
})


const fetchNewTransactionsWalletActivityRequest = () => ({
   type: types.FETCH_NEW_WALLET_ACTIVITY_REQUEST,
});

const fetchNewTransactionsWalletActivityCompleted = (data) => ({
   type: types.FETCH_NEW_WALLET_ACTIVITY_COMPLETED,
   payload: data,
})

const fetchNewTransactionsWalletActivityFailed = (err) => ({
   type: types.FETCH_NEW_WALLET_ACTIVITY_FAILED,
   payload: {
      err,
   },
})

//FETCH WALLET ACTIVITY details /
const fetchWalletActivityDetailsRequest = () => ({
   type: types.FETCH_WALLET_ACTIVITY_DETAILS_REQUEST,
});

const fetchWalletActivityDetailsCompleted = (data) => ({
   type: types.FETCH_WALLET_ACTIVITY_DETAILS_COMPLETED,
   payload: data,
})

const fetchWalletActivityDetailsFailed = (err) => ({
   type: types.FETCH_WALLET_ACTIVITY_DETAILS_FAILED,
   payload: {
      err,
   },
})

// EXPORT WALLET ACTIVITY CSV
const exportWalletActivityCSVFileRequest = () => ({
   type: types.EXPORT_WALLET_ACTIVITY_CSV_START,
});

const exportWalletActivityCSVFileCompleted = () => ({
   type: types.EXPORT_WALLET_ACTIVITY_CSV_COMPLETED,
})
const exportWalletActivityCSVFileFailed = () => ({
   type: types.EXPORT_WALLET_ACTIVITY_CSV_FAILED,
})

const updateTransactionsDataByMemberUsernameAction = (payload) => ({
   type: types.UPDATE_TRANSACTIONS_BY_MEMBER,
   payload,
});


export {
   fetchTransactionsRequest,
   fetchTransactionsCompleted,
   fetchTransactionsFailed,

   fetchTransactionsByFilterRequest,
   fetchTransactionsByFilterCompleted,
   fetchTransactionsByFilterFailed,

   fetchDetailsRequest,
   fetchDetailsCompleted,
   fetchDetailsFailed,
   getNewDataRequest,
   getNewDataCompleted,
   getNewDataFailed,

   exportCSVFileRequest,
   exportCSVFileCompleted,

   //wallet activity
   fetchTransactionsWalletActivityRequest,
   fetchTransactionsWalletActivityCompleted,
   fetchTransactionsWalletActivityFailed,

   fetchTransactionsWalletActivityByFilterRequest,
   fetchTransactionsWalletActivityByFilterCompleted,
   fetchTransactionsWalletActivityByFilterFailed,

   fetchWalletActivityDetailsRequest,
   fetchWalletActivityDetailsCompleted,
   fetchWalletActivityDetailsFailed,

   fetchNewTransactionsWalletActivityRequest,
   fetchNewTransactionsWalletActivityCompleted,
   fetchNewTransactionsWalletActivityFailed,

   exportWalletActivityCSVFileRequest,
   exportWalletActivityCSVFileCompleted,
   exportWalletActivityCSVFileFailed,

   updateTransactionsDataByMemberUsernameAction,
};
