import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from "common/components/elements/icons";
import Radio from 'common/components/elements/inputs/radio-label';
import Modal from '../modal';
import TruncateText from 'common/components/elements/truncate-text';


export default function DropDownModal({
   data,
   onChange,
   initialValue,
   disabled,
   label,
   description,
   errorMessage,
   activeValue,
   uniqueId,
   mainClassName,
   descriptionClassName,
   wrapperClassname,
   optionsListClassName,
   onInputClick,
   errorMessageClassName,
   valueToSkip,
   screenWidth,
   useModalForMobile,
   iconColor,
   preventOnChange,
   currentOptionClassName,
   optionsIconClassName,
   valueKey,
   nameKey,
   children,
   wrapperId,
   isEmptyText,
   maxHeight,
   isLanguageModal,
   optionTooltipText,
   mobileSize = 640,
   closeOnMobileClickOutside = true,
   targetClassToIgnoreOnClick,
}) {

   const [openList, setOpenList] = useState(false)
   // const [focused, setFocused] = useState(false)
   // const [blurred, setBlurred] = useState(true)
   const [currentOption, setCurrentOption] = useState(initialValue)

   function closeOnClickOutside(e) {

      if(document.querySelectorAll(targetClassToIgnoreOnClick) && openList && screenWidth < mobileSize) {
         setOpenList(true)
         return
      }

      if(disabled) {
         return
      }

      if(!e.target.closest(`#${ uniqueId }`)) {
         setOpenList(false)
      }
      if(!e.target.closest(`#${ uniqueId }`)) {
         // setBlurred(true)
         // setFocused(false)
      }
      if(e.target.closest(`#${ uniqueId }_current_value`)) {
         setOpenList(!openList)
      }
   }

   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };
   })

   useEffect(() => {
      !preventOnChange && setCurrentOption(initialValue)
   }, [initialValue, preventOnChange])

   return (
      <div
         className={ `${ mainClassName }` }
         id={ wrapperId }
      >
         {
            !!description &&
            <label htmlFor='customSelect'>
               <p className={ ` select-none ${ descriptionClassName }` }>{description}</p>
            </label>
         }
         <div
            className={ `relative ${ errorMessage ? `border**border-solid**border-error rounded-lg` : '' } ${ wrapperClassname }` }
            role='presentation'
            onClick={ () => {
               if(disabled) {
                  return
               }
               if(!onInputClick) {
                  return
               }
               onInputClick(label)
            }
            }
         >
            <div
               className={ cx({
                  [`w-full relative py-[10px] px-[14px] flex items-center justify-between
                          hover:cursor-pointer rounded-lg h-10 bg-panel hover:bg-hover border border-solid border-divider`]: true,
                  // [`border border-solid border-grey-border`]: !focused && blurred,
                  // [`border border-solid border-l-blue`]: focused,
                  [`border border-solid border-error`]: errorMessage,
                  [`!text-disabled dark:!text-disabled-dark hover:bg-transparent hover:cursor-auto`]: disabled,
                  [`justify-evenly gap-2 !px-[16px]`]: currentOption?.icon,
               }) }
               role='presentation'
               id={ `${ uniqueId }_current_value` }
               onClick={ async () => {
                  if(disabled) {
                     setOpenList(false)
                     return
                  }
                  // setBlurred(false)
                  // setFocused(true)
                  if(!openList && !!document) {
                     let listEl = await document?.getElementById(uniqueId)
                     let currentEl = await document?.getElementById(`${ uniqueId }`)
                     if(listEl) {
                        listEl.scrollTop = currentEl?.offsetTop;
                     }
                     return
                  }
               } }
            >
               <div className={ `${ !!currentOption?.icon ? 'flex flex-row justify-between gap-2 items-center' : '' }` }>
                  {
                     !!currentOption?.icon &&
                        <Icon
                           name={ currentOption?.icon }
                           size='xl'
                           color={ iconColor }
                        />
                  }
                  <TruncateText
                     text={ currentOption?.[nameKey] }
                     textClass={ cx({
                        [`leading-5 py-[2px] flex-1 select-none`]: true,
                        'text-major': !disabled,
                        'text-placeholder': (currentOption?.value === 'placeholder' && !disabled) || !currentOption[valueKey],
                        [currentOptionClassName]: !!currentOptionClassName,
                     }) }
                     notShowTooltip
                  />
                  {/* <p className={ `${ !disabled ? `text-major` : `` } leading-5 py-[2px] flex-1 select-none ${ currentOption?.value === 'placeholder' && !disabled ? `text-placeholder` : `` } ${ currentOptionClassName }` }>{currentOption?.[nameKey]}</p> */}
               </div>
               <button type='button' className={ `min-h-6 min-w-6 flex items-center justify-center focus:outline-none ${ disabled ? `hover:cursor-auto` : `` }` } >
                  <Icon
                     name={ openList ? 'arrow-up' : 'arrow-down' }
                     size='xl'
                     color={  currentOption?.[valueKey] === 'placeholder' || !currentOption?.[valueKey] ? 'placeholder' : iconColor ? iconColor : (disabled ? `disabled` : 'secondary') }
                     darkColor={ disabled ? `disabled-dark` : 'secondary-dark' }
                  />
               </button>
            </div>
            {
               useModalForMobile && screenWidth < mobileSize && <p className={ `text-xs text-error h-[18px] mt-[6px] ${ errorMessageClassName }` }>{errorMessage ? errorMessage : null}</p>
            }
            {
               !useModalForMobile ?
                  (
                     <>
                        <div
                           className={ ` ${ openList ? 'flex' : 'hidden' } w-full flex-col absolute border border-solid border-divider rounded-lg  bg-panel z-20  top-11 shadow dark:shadow-dark ${ optionsListClassName }` }
                           id={ `${ uniqueId }` }
                        >
                           {
                              !data?.length ?
                                 <div className='px-4 py-8'>
                                    <span className='leading-tight text-secondary'>{isEmptyText}</span>
                                 </div>
                                 :
                                 <div
                                    className={ `flex flex-col max-h-[${ maxHeight }px] overflow-x-hidden overflow-y-auto ams-custom-scrool py-1 w-full` }
                                 >
                                    {
                                       data?.map((option, i) => {
                                          if(!!valueToSkip && option?.[valueKey] === valueToSkip) {
                                             return null
                                          } else {
                                             return <div key={ `${ uniqueId }_${ i }` } className='flex w-full h-full relative' role='presentation' onClick={ () => {
                                                if(option?.disabled) {
                                                   return;
                                                }
                                                !preventOnChange && setCurrentOption(option)
                                                onChange(option)
                                                setOpenList(false)
                                             } }>
                                                <label
                                                   htmlFor={ `${ uniqueId }-label` }
                                                   className={ cx({
                                                      'flex w-full justify-between h-10 px-[14px] py-[10px] items-center radio-input-label': true,
                                                      'bg-hover': activeValue === option?.[valueKey],
                                                      'hover:cursor-pointer hover:bg-hover': !option?.disabled,
                                                      'opacity-50': option?.disabled,
                                                   }) }
                                                   data-tooltip-id={ optionTooltipText ? 'ams-top-tooltip' : undefined }
                                                   data-tooltip-content={ optionTooltipText ? optionTooltipText : undefined }
                                                >
                                                   <div className='flex'>
                                                      <div className={ `${ !!option?.icon ? 'flex flex-row gap-2 items-center' : '' }` }>
                                                         {
                                                            !!option?.icon &&
                                                            <Icon
                                                               name={ option?.icon }
                                                               size='xl'
                                                               color={ iconColor }
                                                            />
                                                         }
                                                         <p
                                                            className='label-text leading-6 text-major select-none'
                                                         >
                                                            {option?.[nameKey]}
                                                         </p>
                                                      </div>
                                                   </div>
                                                   {
                                                      !isLanguageModal &&
                                                         <div className={ `radio-input-component ${ optionsIconClassName }` }>
                                                            <Radio
                                                               checked={ activeValue === option?.[valueKey] }
                                                               newStyle={ true }
                                                               labelSize='sm'
                                                               showNewRadioStyles
                                                               radioClassName='!w-5'
                                                               className='!p-0'
                                                            />
                                                         </div>
                                                   }
                                                </label>
                                             </div>
                                          }
                                       })
                                    }
                                 </div>
                           }
                           { children && children }
                        </div>
                        <p className={ `text-xs text-error h-[18px] mt-[6px] ${ errorMessageClassName }` }>{errorMessage ? errorMessage : null}</p>
                     </>
                  )
                  :
                  (
                     screenWidth < mobileSize ?
                        openList &&
                        <Modal
                           contentPosition={ 'bottom' }
                           onClose={ () => setOpenList(false) }
                           contentWidth={ '100%' }
                           closeOnClickOutside={ closeOnMobileClickOutside }
                        >
                           <div
                              id={ `${ uniqueId }` }
                              className={ `  flex w-full flex-col py-1 border border-solid border-divider rounded-lg  bg-panel z-20 shadow dark:shadow-dark ${ optionsListClassName }` }
                           >
                              <div
                                 className='overflow-x-hidden overflow-y-auto ams-custom-scrool flex flex-col'
                              >
                                 {
                                    !data?.length ?
                                       <div className='px-4 py-8'>
                                          <span className='leading-tight text-secondary'>{isEmptyText}</span>
                                       </div>
                                       :
                                       data.map((option, i) => {

                                          if(!!valueToSkip && option?.[valueKey] === valueToSkip) {
                                             // eslint-disable-next-line array-callback-return
                                             return;
                                          } else {
                                             return <div
                                                key={ `${ uniqueId }_${ i }` }
                                                className='flex w-full h-full relative'
                                                role='presentation'
                                                onClick={ () => {
                                                   if(option?.disabled) {
                                                      return;
                                                   }
                                                   !preventOnChange && setCurrentOption(option)
                                                   onChange(option)
                                                   setOpenList(false)
                                                } }>
                                                <label
                                                   htmlFor={ `${ uniqueId }-label` }
                                                   className={ cx({
                                                      'flex w-full justify-between h-10 px-[14px] py-[10px] items-center radio-input-label': true,
                                                      'bg-hover': activeValue === option?.[valueKey],
                                                      'hover:cursor-pointer hover:bg-hover': !option?.disabled,
                                                      'opacity-50': option?.disabled,
                                                   }) }
                                                   data-tooltip-id={ optionTooltipText ? 'ams-top-tooltip' : undefined }
                                                   data-tooltip-content={ optionTooltipText ? optionTooltipText : undefined }
                                                >
                                                   <div className='flex'>
                                                      <p className='label-text leading-6 text-major select-none'>
                                                         {option?.[nameKey]}
                                                      </p>
                                                   </div>
                                                   {
                                                      !isLanguageModal &&
                                                      <div className={ `radio-input-component ${ optionsIconClassName }` }>
                                                         <Radio
                                                            checked={ activeValue === option?.[valueKey] }
                                                            newStyle={ true }
                                                            labelSize='sm'
                                                            showNewRadioStyles
                                                            radioClassName='!w-5'
                                                            className='!p-0'
                                                         />
                                                      </div>
                                                   }
                                                </label>
                                             </div>
                                          }
                                       })
                                 }
                              </div>
                              {children && children}
                           </div>
                        </Modal>
                        :
                        <>

                           <div
                              id={ `${ uniqueId }` }
                              className={ ` ${ openList ? 'flex' : 'hidden' } w-full flex-col absolute border border-solid border-divider rounded-lg  bg-panel z-20 top-11 shadow dark:shadow-dark ${ optionsListClassName }` }
                           >
                              <div
                                 className={ `overflow-x-hidden overflow-y-auto ams-custom-scrool flex flex-col max-h-[${ maxHeight }px] py-1 w-full` }
                              >
                                 {
                                    !data?.length ?
                                       <div className='px-4 py-8'>
                                          <span className='leading-tight text-secondary'>{isEmptyText}</span>
                                       </div>
                                       :
                                       data.map((option, i) => {

                                          if(!!valueToSkip && option?.[valueKey] === valueToSkip) {
                                             // eslint-disable-next-line array-callback-return
                                             return;
                                          } else {
                                             return <div key={ `${ uniqueId }_${ i }` } className='flex w-full h-full relative' role='presentation' onClick={ () => {
                                                if(option?.disabled) {
                                                   return;
                                                }
                                                !preventOnChange && setCurrentOption(option)
                                                onChange(option)
                                                setOpenList(false)
                                             } }>
                                                <label
                                                   htmlFor={ `${ uniqueId }-label` }
                                                   className={ cx({
                                                      'flex w-full justify-between h-10 px-[14px] py-[10px] items-center radio-input-label': true,
                                                      'bg-hover': activeValue === option?.[valueKey],
                                                      'hover:cursor-pointer hover:bg-hover': !option?.disabled,
                                                      'opacity-50': option?.disabled,
                                                   }) }
                                                   data-tooltip-id={ optionTooltipText ? 'ams-top-tooltip' : undefined }
                                                   data-tooltip-content={ optionTooltipText ? optionTooltipText : undefined }
                                                >
                                                   <div className='flex'>
                                                      <p className='label-text leading-6 text-major select-none'>
                                                         {option?.[nameKey]}
                                                      </p>
                                                   </div>
                                                   {
                                                      !isLanguageModal &&
                                                      <div className={ `radio-input-component ${ optionsIconClassName }` }>
                                                         <Radio
                                                            checked={ activeValue === option?.[valueKey] }
                                                            newStyle={ true }
                                                            labelSize='sm'
                                                            showNewRadioStyles
                                                            radioClassName='!w-5'
                                                            className='!p-0'
                                                         />
                                                      </div>
                                                   }
                                                </label>
                                             </div>
                                          }
                                       })
                                 }
                              </div>
                              {children && children}
                           </div>
                           <p className={ `text-xs text-error h-[18px] mt-[6px] ${ errorMessageClassName }` }>{errorMessage ? errorMessage : null}</p>
                        </>
                  )
            }
         </div>
      </div>
   )
}
DropDownModal.propTypes = {
   onChange: PropTypes.func,
   initialValue: PropTypes.object,
   disabled: PropTypes.bool,
   label: PropTypes.string,
   mainClassName: PropTypes.string,
   descriptionClassName: PropTypes.string,
   wrapperClassname: PropTypes.string,
   errorMessage: PropTypes.string,
   data: PropTypes.array,
   description: PropTypes.string,
   uniqueId: PropTypes.any,
   optionsListClassName: PropTypes.string,
   activeValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
   onInputClick: PropTypes.func,
   errorMessageClassName: PropTypes.string,
   valueToSkip: PropTypes.string,
   screenWidth: PropTypes.number,
   useModalForMobile: PropTypes.bool,
   iconColor: PropTypes.string,
   valueKey: PropTypes.string,
   nameKey: PropTypes.string,
   preventOnChange: PropTypes.bool,
   currentOptionClassName: PropTypes.string,
   optionsIconClassName: PropTypes.string,
   children: PropTypes.any,
   wrapperId: PropTypes.string,
   isEmptyText: PropTypes.string,
   maxHeight: PropTypes.string,
   isLanguageModal: PropTypes.bool,
   optionTooltipText: PropTypes.string,
   mobileSize: PropTypes.number,
   targetClassToIgnoreOnClick: PropTypes.string,
   closeOnMobileClickOutside: PropTypes.bool,
}
DropDownModal.defaultProps = {
   cssStyle: {},
   searchable: false,
   register: () => { },
   uniqueId: 'custom-select',
   descriptionClassName: ``,
   wrapperClassname: ``,
   optionsListClassName: ``,
   errorMessageClassName: 'absolute right-0',
   useModalForMobile: false,
   iconColor: 'secondary',
   nameKey: 'name',
   valueKey: 'value',
   preventOnChange: false,
   currentOptionClassName: ``,
   wrapperId: ``,
   isEmptyText: ``,
   maxHeight: `210`,
   isLanguageModal: false,
}
