const initialState = {
   isFetching: true,
   isNewFetching: false,
   isFetchingByFilter: false,
   isEmptyByFilter: false,
   isEmpty: false,
   photosetsData: {},
   activeData: {},
   casts: [],
   owners: [],
   isFetchingById: true,
   isUpdateFetching: false,
   isDeleteFetching: false,
   newPhotosets: {
   },
   photos: [],
   newPhotos: [],
   cover: { },
   errors: {},
   customPrices: [],
   accessing: [],
   deletePhotos: [],
   photosLength: 0,
   statsData: {},
   isFetchingStats: true,
   isFetchingValidate: false,
   activeTab: null,
   deleteButtonDisabled: false,
   openEditTitelMod: false,
   openStatusMod: false,
   updateStatusFetching: false,

   key: '',
   isEmptyByFilterInsideModal: false,
   isEmptyInsideModal: false,
   photosetsDataInsideModal: {},
   isFetchingByFilterInsideModal: false,
   isFetchingInsideModal: true,
   isNewFetchingInsideModal: false,
   updateButtonDisabled: false,
   isInited: false,
   filters: {},
   filtersInsideModal: {},
   isDistoryFetching: false,
   bulkEditIds: {},
   isFetchingByIds: true,
   photosetsDataByIds: { data: [], resources: {} },
   bulkUpdateFetching: false,
   bulkUpdateErrors: {},
   warningModal: {
      isOpen: false,
      description: null,
      ids: null,
      multiple_bundles: null,
      reason: null,
      buttonText: null,
      title: null,
      buttonIconName: null,
      cancelText: null,
      secondaryButtonClassName: null,
   },
};

export default initialState;
