import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MiniChatContainer from './mini-chat';
import LiveChatNotifications from './notifications';
import ChatProviderContainer from 'admin/containers/modules/chat';
import { currentLocationPathSelector } from 'admin/state/modules/router/selectors';
import { Consumer } from 'admin/containers/modules/chat/context';

import Router from 'admin/routes/router';
// import QueryParams from 'admin/utils/QueryParams';
class ChatOtherContainer extends Component {
   static propTypes = {
      locationPath: PropTypes.string,
      isLivestreamPage: PropTypes.bool,
   }

   render(){
      const {
         locationPath,
         isLivestreamPage,
      } = this.props;
      if(!!Router.route('CHAT').match(locationPath)) {
         return null
      }
      const onlineStream = (!!Router.route('LIVE_STREAM_SCHEDULE').match(locationPath) || !!Router.route('LIVE_STREAM_STUDIO').match(locationPath)); //TO DO UPDATE
      return (
         <ChatProviderContainer >
            <Consumer >
               { ({
                  onDeleteMessag,
                  onUserTyping,
                  groupChatTypersMessage,
                  onLikeMessage,
                  onSendMessage,
                  converstionInfo,
                  onReadMessage,
                  onlineUsers,
                  onSetMemberOption,
                  onUnMuteMember,
                  updateMessageUnlockDetails,
                  updateMessageData,
               }) => {
                  return (
                     <>
                        {
                           !isLivestreamPage && (
                              <>
                                 <LiveChatNotifications
                                    onReadMessage={ onReadMessage }
                                    onlineUsers={ onlineUsers }
                                 />
                                 {
                                    !onlineStream && (
                                       <MiniChatContainer
                                          onUserTyping={ onUserTyping }
                                          typerText={ groupChatTypersMessage }
                                          onLikeMessage={ onLikeMessage }
                                          onDeleteMessag={ onDeleteMessag }
                                          onSendMessage={ onSendMessage }
                                          converstionInfo={ converstionInfo }
                                          groupChatTypersMessage={ groupChatTypersMessage }
                                          onReadMessage={ onReadMessage }
                                          onlineUsers={ onlineUsers }
                                          onSetMemberOption={ onSetMemberOption }
                                          onUnMuteMember={ onUnMuteMember }
                                          updateMessageUnlockDetails={ updateMessageUnlockDetails }
                                          updateMessageData={ updateMessageData }
                                       />
                                    )
                                 }
                              </>
                           )
                        }

                     </>
                  )
               }}
            </Consumer>
         </ChatProviderContainer>

      );
   }

}

const mapStateToProps = (state) => {
   return {
      locationPath: currentLocationPathSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatOtherContainer);
