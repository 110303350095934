
export const FETCH_DOMAIN_START = 'domain-name/FETCH_DOMAIN_START';
export const FETCH_DOMAIN_COMPLETED = 'domain-name/FETCH_DOMAIN_COMPLETED';
export const FETCH_DOMAIN_FAILED = 'domain-name/FETCH_DOMAIN_FAILED';

export const POINT_DOMAIN_START = 'domain-name/POINT_DOMAIN_START';
export const POINT_DOMAIN_COMPLETED = 'domain-name/POINT_DOMAIN_COMPLETED';
export const POINT_DOMAIN_FAILED = 'domain-name/POINT_DOMAIN_FAILED';

export const UPDATE_DATA_START = 'domain-name/UPDATE_DATA_START';
export const UPDATE_DATA_COMPLETED = 'domain-name/UPDATE_DATA_COMPLETED';
export const UPDATE_DATA_FAILED = 'domain-name/UPDATE_DATA_FAILED';
export const CHANGE_DOMAIN_STATUS_COMPLETED = 'domain-name/CHANGE_DOMAIN_STATUS_COMPLETED';