import { createSelector } from 'reselect'

const innerStateSelector = state => state.notifications

const isOpenPopupSelector = createSelector(
   innerStateSelector,
   (state) => (state.isOpenPopup)
);

const listSelector = createSelector(
   innerStateSelector,
   (state) => (state.list)
);

const isOpenReplyCommentSelector = createSelector(
   innerStateSelector,
   (state) => (state.isOpenReplyComment)
);

const replyModalStateSelector = createSelector(
   innerStateSelector,
   (state) => (state.replyModal)
);

const replyCommentsSelector = createSelector(
   innerStateSelector,
   (state) => (state.replyComments)
);
const replyChatMessageSelector = createSelector(
   innerStateSelector,
   (state) => (state.replyChatMessage)
);

const unseenNotificationsCountSelector = createSelector(
   innerStateSelector,
   (state) => {
      let count = state.unseenCount;
      if(count > 99){
         count = '99+'
      }
      return {
         count: state.unseenCount,
         badgeCount: count,
      };
   }
);

const unreadNotificationsCountSelector = createSelector(
   innerStateSelector,
   (state) => (state.unreadCount)
);

const isFetchingNotificationsSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingNotifications)
);

const isFetchingNewNotificationsSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingNewNotifications)
);
const isFetchingReplyCommentSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingReplyComment)
);

const notificationsPanelSelector = createSelector(
   innerStateSelector,
   (state) => (state.notificationsPanel)
);

const isEmptyNotificationsSelector = createSelector(
   innerStateSelector,
   (state) => (state.isEmptyNotifications)
);

const isEmptyByFilterNotificationsSelector = createSelector(
   innerStateSelector,
   (state) => (state.isEmptyByFilterNotifications)
);

const isFetchingByFilterNotificationsSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingByFilterNotifications)
);

export {
   isOpenReplyCommentSelector,
   replyModalStateSelector,
   isOpenPopupSelector,
   listSelector,
   replyCommentsSelector,
   replyChatMessageSelector,
   unseenNotificationsCountSelector,
   unreadNotificationsCountSelector,
   isFetchingNotificationsSelector,
   isFetchingNewNotificationsSelector,
   isFetchingReplyCommentSelector,
   notificationsPanelSelector,
   isEmptyNotificationsSelector,
   isEmptyByFilterNotificationsSelector,
   isFetchingByFilterNotificationsSelector,
}
