import React, { useMemo, useState } from 'react';
import ReactNbsp from 'react-nbsp';
import PropTypes from 'prop-types';
import ChatUserAvatar from 'common/components/modules/chat/chat-user-avatar';
import SpinnerAnimation from 'common/components/modules/spinner-animation';
import './style.scss';
import DateFormatter from 'common/utils/DateFormatter';
// import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import cx from 'classnames';
import { getPhrasesFromMessage } from 'common/utils/messageParser'
// import { MemberCardInfo } from "common/components/modules/member-card/member-info";
import TruncateText from 'common/components/elements/truncate-text';
import ChatListItemOptions from './modules/options';
import Loader from 'common/components/elements/loader/circle';
import LastMessage from './modules/last-message';

const ChatLIstItem = ({
   isActive,
   onClick,
   chat,
   authUserUuid,
   isGroup,
   isNew,
   cancelNewConversation,
   showMembersCount,
   isAdmin,
   hiddeBorder,
   isFromConversations,
   adminUuid,
   isMobile,
   onClickOpenCloseChat,
   fetchingConversation,
   highlitedText,
   onClickPinUnpinChat,
   hideOptions,
}) => {
   const [isOpenOptions, setIsOpenOptions] = useState(false)

   const hasNewMessage = chat.unread_messages_count > 0
   const userIsTyping = chat ? chat.user_is_typing : false
   const draftMessage = !isActive && isAdmin ? window?.draftMessages?.[chat.chat_room_id] || {} : {}

   let lastMessage = {}
   if(chat.last_message) {
      lastMessage = chat.last_message
   }
   const lastMessageText = chat.last_message?.text

   const memberNickname = authUserUuid ===  lastMessage.user_uuid ? 'You' : lastMessage?.nickname || chat?.member_nickname || chat?.member?.nickname

   const renderedMessage = useMemo(() => {

      // Validation
      if(!lastMessageText || typeof lastMessage.text !== 'string') return null

      /*start message phrase rendering, links new lines spaces*/

      // const phrases_array = getPhrasesFromMessage(lastMessageText);

      const slicedText = lastMessageText.substring(0, 100)
      const phrases_array = getPhrasesFromMessage(slicedText);

      const final_messages = [];
      // eslint-disable-next-line no-useless-escape
      const link_find_pattern = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
      const linksStyle = {
         color: 'inherit',
         textDecoration: 'underline',
      };
      let phrases_key = 1;
      let author

      if(isAdmin && isGroup && memberNickname) {
         author = `${ memberNickname }:`
      } else {
         author = authUserUuid ===  lastMessage.user_uuid ? 'You:' : (lastMessage.username ? `${ lastMessage.username }:` : '')
      }

      if(author) {
         final_messages.push(<span
            className='word-normal'
            key={ ++phrases_key }>{author}</span>);
         final_messages.push(<ReactNbsp key={ ++phrases_key } />);
      }
      for(const item of phrases_array) {
         const { value, type } = item;

         let matches = null
         if(type === 'other' && (matches = value.match(link_find_pattern))) {

            let [link] = matches;
            const lowerCased = link.toLowerCase();
            let link_text = link;

            let link_right_chars, link_left_chars = '';
            link_left_chars = value.substring(0, value.indexOf(link));
            link_right_chars = value.substring(value.indexOf(link) + link.length, value.length);
            if(!lowerCased.includes('http')) {
               link = 'http://' + link;
            }
            if(link_left_chars) {
               final_messages.push(<span
                  className={ `${ link_left_chars.length > 15 ? 'word-break' : 'word-normal' }` }
                  key={ ++phrases_key }>{link_left_chars}</span>);
            }
            final_messages.push(
               <a key={ ++phrases_key } style={ linksStyle } target='_blank' href={ link } rel='noreferrer'>{link_text}</a>
            );
            if(link_right_chars) {
               final_messages.push(<span className={ `${ link_right_chars.length > 15 ? 'word-break' : 'word-normal' }` }
                  key={ ++phrases_key }>{link_right_chars}</span>);
            }
         }
         // else if(type === 'new_line') {
         // final_messages.push(<br key={ ++phrases_key } />);
         // }
         else if(type === 'space'){
            final_messages.push(<ReactNbsp key={ ++phrases_key } />);
         } else if(type === 'emoji') {
            final_messages.push(<span className='emoji-char' key={ ++phrases_key }>{value}</span>);
         } else {
            final_messages.push(<span className={ `${ value.length > 15 ? 'word-break' : 'word-normal' }` }
               key={ ++phrases_key }>{value}</span>);
         }
      }
      /*end message phrase rendering, links new lines spaces*/
      return final_messages

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [chat.last_message])

   const getMemberBadge = () => {
      let badgeTags = ''
      let hasBadge = (!!chat?.member?.member_tags && chat?.member?.member_tags?.badge)
      let hasAutoTag = (!!chat?.member?.member_tags && 'function' === typeof chat?.member?.member_tags.filter && chat?.member?.member_tags.filter(item => !!item.rule && item.show_icon_as_badge === 1))
      let hasCustomTag = (!!chat?.member?.member_tags && 'function' === typeof chat?.member?.member_tags.filter && chat?.member?.member_tags.filter(item => !item.rule && item.show_icon_as_badge === 1))

      if(!!hasAutoTag[0]) {
         badgeTags = hasAutoTag?.[0]?.emoji
      }
      if(!!hasCustomTag[0] && !hasAutoTag[0]) {
         badgeTags = hasCustomTag?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))[0]?.emoji
      }
      if(!!hasBadge) {
         badgeTags = hasBadge
      }
      return badgeTags
   }

   const getLastMessageDate = () => {
      if((!isAdmin || isGroup) && lastMessage?.created_at){
         return lastMessage.created_at;
      }
      if(lastMessage && chat?.room_last_message_at){
         return chat.room_last_message_at;
      }
      return null;
   }

   let badgeEmoji = getMemberBadge()
   const isClosed = 'closed' === chat.type
   // console.log({ chat });
   return (
      <div
         id={ `ams_converstion_by_${ chat.chat_room_id }` }
         role='presentation'
         onClick={ (e) => {
            if(isOpenOptions || fetchingConversation) return;
            onClick(e)
         } }
         className={ cx({
            'cursor-pointer min-chat-item-h chat-conversation-item flex gap-3 px-6 py-3 relative hover:bg-hover dark:hover:bg-hover-dark': true,
            'bg-hover dark:bg-hover-dark active-shadow': isActive && isAdmin,
            ' group/list_item': !isMobile,
         }) }
      >
         {
            fetchingConversation ? (
               <div
                  className='w-full flex items-center justify-center'
               >
                  <Loader />
               </div>
            ) : (
               <>
                  <div className='flex relative h-[min-content] mt-1'>
                     {
                        isFromConversations && <div className='relative'>
                           {
                              !!badgeEmoji && (
                                 <div className='w-5 h-5 rounded-full bg-panel dark:bg-panel-dark flex items-center justify-center' style={ {
                                    position: 'absolute',
                                    zIndex: '20',
                                    top: '22px',
                                    left: '-6px',
                                 } }>
                                    <span role='img' aria-label='icon' className='text-xs' >{badgeEmoji}</span>
                                 </div>
                              )
                           }
                        </div>
                     }
                     <ChatUserAvatar
                        // className='mb-7'
                        avatarSrc={ isGroup ? chat.avatars : chat.user_avatar }
                        username={ isAdmin ? (chat?.member_nickname || chat?.nickname || chat?.username || null) : chat.username || null }
                        showOnlyImage={ !isGroup && Boolean(adminUuid) && adminUuid === chat?.user_uuid }
                     />
                     {
                        !!chat.is_online && (
                           <div
                              className='w-[14px] h-[14px] absolute bottom-[-2px] right-[-2px] border-2 border-divider rounded-full bg-success dark:bg-success-dark'
                           />
                        )
                     }
                  </div>
                  <div
                     // 100% - width of avatar+gap(12px)
                     className='w-full flex flex-col gap-1 max-w-[calc(100%-52px)] relative z-5'
                  >
                     <div
                        className='flex justify-between items-center gap-2 relative'
                     >
                        <div className='text-major text-base font-medium leading-6 flex items-center justify-start dark:text-major-dark'>
                           {
                              chat.is_pinned && (
                                 <div
                                    data-tooltip-content='Pinned chat'
                                    data-tooltip-id='ams-top-tooltip'
                                    className='w-4 h-4 inline-flex items-center justify-center mr-2'>
                                    <Icon
                                       name='pin'
                                       size='base'
                                       darkColor='secondary-dark'
                                       color='secondary'
                                       className='rotate-[35deg]'
                                    />
                                 </div>
                              )
                           }
                           {
                              isGroup && (
                                 <>
                                    {
                                       !!chat.members_count  && !!showMembersCount ? (
                                          `${ chat.conversation_name } • ${ chat.members_count } members`
                                       ) : (
                                          `${ chat.conversation_name }`
                                       )
                                    }
                                 </>
                              )
                           }
                           {
                              !isGroup && (
                                 <TruncateText
                                    text={ chat?.member_nickname || chat?.username }
                                    textClass='leading-6 whitespace-pre tracking-[0.0125em] whitespace-pre notranslate'
                                    textSize='16px'
                                    fontWeight='500'
                                    highlitedText={ highlitedText }
                                 />
                              )
                           }
                           {
                              (!!chat.user_muted || !!chat.muted_since) && (
                                 <div
                                    data-tooltip-content='Member is muted'
                                    data-tooltip-id='ams-bottom-tooltip'
                                    className='w-4 h-4 ml-2 inline-flex items-center justify-center'>
                                    <Icon name='chat-mute' size='sm'
                                       darkColor='placeholder-dark'
                                       color='placeholder' />
                                 </div>
                              )
                           }
                           {
                              !!chat.user_banned && (
                                 <div
                                    data-tooltip-content='Member is blocked'
                                    data-tooltip-id='ams-bottom-tooltip'
                                    className='w-4 h-4 ml-2 inline-flex items-center justify-center'>
                                    <Icon name='block' size='sm' color='error' darkColor='error-dark' />
                                 </div>
                              )
                           }
                        </div>
                        <div
                           className='flex items-center gap-1 h-full'
                        >
                           {
                              hasNewMessage && (
                                 <div
                                    className='spect-square min-w-[20px] min-h-[20px] bg-error dark:bg-error-dark rounded-full flex items-center justify-center'
                                 >
                                    <span
                                       className='text-major-dark text-xs font-medium'
                                    >
                                       { chat.unread_messages_count > 100 ? '99+' : chat.unread_messages_count }
                                    </span>
                                 </div>
                              )
                           }
                           {
                              isAdmin && isFromConversations && !isGroup && !isNew && !hideOptions && (
                                 <ChatListItemOptions
                                    isMobile={ isMobile }
                                    isOpenOptions={ isOpenOptions }
                                    setIsOpenOptions={ setIsOpenOptions }
                                    roomId={ chat.chat_room_id }
                                    onClickOpenCloseChat={ () => {
                                       onClickOpenCloseChat()
                                       setIsOpenOptions(false)
                                    } }
                                    isClosed={ isClosed }
                                    isActive={ isActive }
                                    isPinned={ chat.is_pinned }
                                    onClickPinUnpinChat={ () => {
                                       onClickPinUnpinChat()
                                       setIsOpenOptions(false)
                                    } }
                                 />
                              )
                           }
                        </div>
                     </div>
                     {
                        !!draftMessage?.draftText ?
                           <div className='flex flex-row gap-1 text-[13px] flex-nowrap'>
                              <p className='text-warning dark:text-warning-dark leading-4 whitespace-nowrap'>Draft:</p>
                              <TruncateText
                                 text={ draftMessage?.draftText }
                                 width='100%'
                                 className='w-full'
                                 textClass={ `text-[13px] leading-4 text-secondary dark:text-secondary-dark` }
                                 notShowTooltip={ window.innerWidth < 1024 }
                                 textSize='13px'
                              />
                           </div>
                           :
                           <>
                              {
                                 userIsTyping && (
                                    <SpinnerAnimation
                                       className='px-4 h-4'
                                    />
                                 )
                              }
                              {
                                 !userIsTyping && lastMessage && !lastMessage.user_uuid && (
                                    <span
                                       className='text-secondary dark:text-secondary-dark text-sm'
                                    >
                                       No messages yet
                                    </span>
                                 )
                              }
                              {
                                 !userIsTyping && lastMessage.user_uuid && (
                                    <div
                                       className='flex items-center justify-between gap-2'
                                    >
                                       {
                                          !!(typeof lastMessage.text === 'string') ? (
                                             <LastMessage
                                                tooltipId={ chat.chat_room_id }
                                                tooltipText={ lastMessage.text }
                                             >
                                                {renderedMessage}
                                             </LastMessage>
                                          ) : lastMessage.text && lastMessage.text.subject
                                             ? (
                                                <TruncateText
                                                   text={  authUserUuid ===  lastMessage.user_uuid ? 'You: ' + lastMessage.text.subject : lastMessage.text.subject }
                                                   width='100%'
                                                   className='w-full'
                                                   textClass={ `text-[13px] leading-4 ${ hasNewMessage ? 'text-major font-medium dark:text-major-dark' : 'text-secondary placeholder dark:text-placeholder-dark' }` }
                                                   notShowTooltip={ window.innerWidth < 1024 }
                                                   textSize='13px'
                                                />
                                             ) : ''

                                       }
                                       {
                                          getLastMessageDate() && DateFormatter.checkIsValid(getLastMessageDate()) && (
                                             <span
                                                className='text-secondary dark:text-secondary-dark text-xs leading-4 whitespace-nowrap'
                                             >
                                                { DateFormatter.calculateTimeAgo(getLastMessageDate()) }
                                             </span>
                                          )
                                       }
                                    </div>
                                 )
                              }
                           </>
                     }
                  </div>
               </>
            )
         }
      </div>
   );
};
ChatLIstItem.defaultProps = {
   chat: {},
   cancelNewConversation: () => {},
   isAdmin: false,
   hiddeBorder: false,
};
ChatLIstItem.propTypes = {
   isActive: PropTypes.bool,
   onClick: PropTypes.func,
   chat: PropTypes.object,
   authUserUuid: PropTypes.string,
   isGroup: PropTypes.bool,
   isNew: PropTypes.bool,
   cancelNewConversation: PropTypes.func,
   showMembersCount: PropTypes.bool,
   isAdmin: PropTypes.bool,
   hiddeBorder: PropTypes.bool,
   isFromConversations: PropTypes.bool,
   adminUuid: PropTypes.string,
   isMobile: PropTypes.bool,
   onClickOpenCloseChat: PropTypes.func,
   fetchingConversation: PropTypes.bool,
   highlitedText: PropTypes.string,
   onClickPinUnpinChat: PropTypes.func,
   hideOptions: PropTypes.bool,
};

export default ChatLIstItem;
