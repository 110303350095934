import * as types from "./types";
import createReducer from "../../utils/createReducer";
import _ from "lodash";
import initialState from "./initial-state";

let reducersMap = {
   [types.FETCH_NEW_SHIPPING_OPTIONS]: (state, action) => {
      return {
         ...state,
         name: action.payload.name,
         countries: [...action.payload.countries],
         zonesArray: {
            ...state.zonesArray,
            ...action.payload.zonesArray,
         },
      };
   },
   [types.FETCH_NEW_SHIPPING_OPTIONS_ADD]: (state, action) => {
      return {
         ...state,
         zonesArray: [
            ...state.zonesArray,
            {
               id: state.zonesArray.at(-1).id + 1,
               shipping_options: [
                  {
                     name: "",
                     rate_type: "",
                     estimated_delivery_time: "",
                     rate: 0,
                  },
               ],
            },
         ],
      };
   },
   [types.FETCH_NEW_SHIPPING_RATE_CHANGE]: (state, action) => {
      const newArray = state.zonesArray.map((item) => {
         if(item.id === action.payload.id) {
            return {
               ...item,
               shipping_options: {
                  ...item.shipping_options,
                  rate_type: action?.payload?.rate,
               },
            };
         }
         return item;
      });
      return {
         ...state,
         zonesArray: newArray,
      };
   },
   [types.DELETE_DATA_FOR_NAME]: (state, action) => {
      return {
         ...state,
         name: action.payload,
      };
   },
   [types.VALIDATION_SHIPPING_ERROR]: (state, action) => {
      let newState = _.cloneDeep(state);
      for(const key in action.payload) {
         const result = key.split(".");
         if(result[0] === "name") {
            newState = {
               ...newState,
               error: {
                  ...newState.error,
                  name: action.payload[key][0],
               },
            };
         }
         if(result[0] === "countries") {
            newState = {
               ...newState,
               error: {
                  ...newState.error,
                  countries: action.payload[key][0],
               },
            };
         }
         if(result[1] && typeof +result[1] === "number") {
            newState = {
               ...newState,
               zonesArray: {
                  ...newState.zonesArray,
                  [result[1]]: {
                     ...newState.zonesArray[result[1]],
                     error: {
                        ...newState.zonesArray[result[1]].error,
                        [result[2]]: action.payload[key][0]
                           ? action.payload[key][0]
                           : "",
                     },
                  },
               },
            };
         }
      }
      return {
         ...newState,
      };
   },
   [types.RECHANGE_SHIPPING_DATA]: (state, action) => {
      action.payload.map((item) => {
         return {
            [item.id]: {
               ...item.options,
            },
         };
      });
      return {
         ...state,
         zonesArray: {
            ...state.zonesArray,
         },
      };
   },
   [types.SAVE_NEW_SHIPPING_ZONE_DATA]: (state, action) => {
      let { name, value, id } = action.payload;
      const array = state.zonesArray?.map((item) => {
         if(item.id === id) {
            return {
               ...item,
               shipping_options: {
                  ...item.shipping_options,
                  [name]: value,
               },
            };
         }

         return item;
      });
      return {
         ...state,
         zonesArray: array,
      };
   },
   [types.FETCH_SHIPPING_ZONE_UPDATE]: (state, action) => {
      const array = action.payload.options.reduce((acum, val, index) => {
         acum[index] = val
         acum[index].error = { ...state.zonesArray[0].error }
         return acum
      }, {})
      return {
         ...state,
         countries: [...action.payload.countries],
         name: action.payload.name,
         zonesArray: {
            ...array,
            error: state.zonesArray[0].error,
         },
      }
   },
};

export default createReducer(initialState)(reducersMap);
