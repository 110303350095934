import { reverse } from 'named-urls';
import { matchPath } from 'react-router';
import routes from './routeCodes';
routes.route = (routeName) => {
   return routes[routeName];
};


function getCompiledPath(params = {}) {
   return reverse(this.path, params);
}

function getMask() {
   return this.path;
}

function localRouteisTopLevel() {
   return !!this.topLevel;
}

function match(locationPath) {
   return matchPath(locationPath, {
      path: this.path,
      exact: this.exact,
      strict: false,
   })
}

function getParent() {
   return routes[this.parentKey];
}

function getTitle() {
   return this.title;
}

function getExtraTitle() {
   return this.extraTitle;
}
function getLastItemCallback() {
   return this.lastItemCallback;
}

function setTitle(title) {
   this.title = title;
}
function setParent(parentKey) {
   this.parentKey = parentKey;
}
function setExtraTitle(extraTitle) {
   this.extraTitle = extraTitle;
}
function setLastItemCallback(callback) {
   this.lastItemCallback = callback;
}

function getCancel() {
   return this.cancel;
}

function getGoBackById() {
   return !!this.goBackById;
}

function getHasBackSearchParams() {
   return !!this.hasBackSearchParams;
}
function getBackSearchParamsTitle() {
   return this.backSearchParamsTitle;
}
function getBackSearchParams() {
   return this.backSearchParams;
}

Object.getOwnPropertyNames(routes).forEach((key) => {
   routes[key].getCompiledPath = getCompiledPath;
   routes[key].getMask = getMask;
   routes[key].match = match;
   routes[key].isTopLevelRoute = localRouteisTopLevel;
   routes[key].getParent = getParent;
   routes[key].getTitle = getTitle;
   routes[key].getCancel = getCancel;
   routes[key].getGoBackById = getGoBackById;
   routes[key].setTitle = setTitle;
   routes[key].setParent = setParent;
   routes[key].setExtraTitle = setExtraTitle;
   routes[key].getExtraTitle = getExtraTitle;
   routes[key].getLastItemCallback = getLastItemCallback;
   routes[key].setLastItemCallback = setLastItemCallback;
   routes[key].getBackSearchParams = getBackSearchParams;
   routes[key].getHasBackSearchParams = getHasBackSearchParams;
   routes[key].getBackSearchParamsTitle = getBackSearchParamsTitle;
});



export function isTopLevelRoute(locationPath) {
   let isTopLevel = false;
   const route = getCurrentRoute(locationPath);
   if(route && route.match(locationPath)) {
      isTopLevel = route.isTopLevelRoute();
   }
   return isTopLevel;
}

export function getCurrentRoute(locationPath) {
   let isTopLevel = null;
   const props = Object.getOwnPropertyNames(routes);
   for(let i = 0; i < props.length; i++) {
      const route = routes[props[i]];
      if(route.match(locationPath)) {
         isTopLevel = route;
      }
   }
   return isTopLevel;
}

export default routes;
