import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';

const reducersMap = {
   [types.OPEN_STEPS_MODAL_COMPLETED]: (state) => {
      return {
         ...state,
         openStepsModal: !state.openStepsModal,
      };
   },

   [types.TOGGLE_IS_FETCHING]: (state, action) => {
      return {
         ...state,
         isFetching: action.payload,
      };
   },
   [types.SITES_STATUS_CHANGE]: (state, action) => {
      return {
         ...state,
         steps: {
            ...state.steps,
            site_status: action.payload,
         },
      };
   },

   [types.SET_DIALOG_COMPLETED]: (state, action) => {
      return {
         ...state,
         dialog: {
            ...state.dialog,
            ...action.payload,
         },
      };
   },
   [types.SET_ADD_CONTENT_MODAL]: (state, action) => {
      return {
         ...state,
         isOpenAddContentModal: action.payload,
      };
   },
};

export default createReducer(initialState)(reducersMap);
