import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons'
import './style.scss';
import Modal from '../../modal';
import { MessageElement } from '../multiple-attachment-message';
import Button from 'common/components/elements/buttons/primary';
import generateTextColor from 'common/utils/generateTextColor';
import cx from "classnames";


const MultipleAttachmentModal = ({
   data,
   isAdmin,
   width,
   adminMessage,
   onClose,
   onRemoveMedia,
   goToDetails,
   siteInfo,
   onCloseChat,
   getContentLink,
}) => {

   const [animate, setAnimate] = useState('up')
   const { attachments } = data?.unlock_details
   let timeout

   const onCloseModal = () => {
      if(window.innerWidth < 1024) {

         setAnimate('down')
         timeout = setTimeout(() => {
            onClose()
         }, 800);

         return
      }
      onClose()

   }

   useEffect(() => {
      return () => {
         clearTimeout(timeout)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const getModalWidth = () => {
      let width = 'auto'
      if(attachments.length === 2) {
         width = '60vw'
         if(window?.innerWidth > 1920) {
            width = '50vw'
         }
      }
      if(attachments.length >= 3) {
         width = '80vw'
         if(window?.innerWidth > 1920) {
            width = '60vw'
         }
      }

      return width
   }

   const getGridCols = () => {
      let grid = 'grid-cols-1'

      if(attachments.length === 2) {

         // 'grid grid-cols-1 [@media(min-width:460px) and @media(max-width:1024px)]:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-2 mt-8 overflow-y-auto ams-custom-scrool overflow-x-hidden': !isAdmin,

         if(window?.innerWidth > 640) {
            grid = 'grid-cols-2'
         }
      }

      if(attachments.length >= 3) {

         // 'grid grid-cols-1 [@media(min-width:460px) and @media(max-width:1024px)]:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-2 mt-8 overflow-y-auto ams-custom-scrool overflow-x-hidden': !isAdmin,

         if(window?.innerWidth > 640) {
            grid = 'grid-cols-2'
         }

         if(window?.innerWidth > 1024) {
            grid = 'grid-cols-3'
         }
      }


      return grid
   }

   const hasAccess = (content, index) => {
      return   (
         (!!Number(content.resource_id) || (typeof content.resource_id === 'string' && content.resource_id.length === 32))
      && ['video', 'photoset'].includes(content.resource_type)
      && (
         (
            ['video', 'photoset'].includes(content.resource_type)
            && data?.unlock_details?.attachmentsStatuses
            && 'status' in data?.unlock_details?.attachmentsStatuses?.[index]
            && data?.unlock_details?.attachmentsStatuses?.[index]?.status !== 'is_hidden_content'
            && (!!content?.publish_date)
         )
         ||
         (((data?.unlock_details.unlock_price === 0) || (data?.unlock_details.is_unlocked && +data?.unlock_details.unlock_price > 0)) && (!content?.publish_date || (content?.publish_date && data?.unlock_details?.attachmentsStatuses?.[index]?.status === 'is_hidden_content')))
         ||
         (!!content.locked_image_is_blur && !!content.cover && !content?.publish_date)
      )
      )
   }


   return (
      <Modal
         contentPosition={ window.innerWidth < 1024 ? 'bottom' : 'center' }
         onClose={ () => onCloseModal() }
         contentWidth={ window.innerWidth < 1024 ? '100%' : getModalWidth() }
         animate={ window.innerWidth < 1024 ? animate : null }
         className='attachment-popup h-full overflow-hidden'
      >
         <div className='flex flex-col bg-panel dark:bg-panel-dark p-6 max-lg:px-4 max items-center text-center select-none gap-1'>
            <div
               id='attachments_modal_header'
               className='w-full flex flex-col items-center max-lg:px-2'>
               <div
                  className={ `h-10 w-10 mx-auto flex items-center justify-center mb-3 rounded-full ` }
                  style={ {
                     backgroundColor: generateTextColor.generateRGBColor(!isAdmin ? window?.skinSecondaryColor : window?.skinPrimaryColor, 0.12),
                  } }
               >
                  <Icon
                     name={ 'content' }
                     size={ '2xl' }
                     color={ `action` }
                     primaryColor={ !isAdmin ? window?.skinSecondaryColor : window?.skinPrimaryColor }
                  />
               </div>
               <p className='text-major dark:text-major-dark text-xl font-semibold leading-6'>
                  Attachments
               </p>
               {
                  isAdmin &&  !!(!data?.unlock_details?.is_unlocked) &&
                  <p className='text-secondary dark:text-secondary-dark text-base leading-5 mt-4'>
                     You have { isAdmin ? `attached` : `unlocked` } { attachments?.length } { attachments?.length === 1 ? 'item' : 'items' } which include the following content
                  </p>
               }
            </div>
            <div
               className={ cx({
                  'w-full mt-8 overflow-y-auto ams-custom-scrool': true,
                  [`grid ${ getGridCols() } gap-3`]: isAdmin,
                  [`grid ${ getGridCols() } gap-x-2 gap-y-6  overflow-x-hidden`]: !isAdmin,
               }) }
               style={ {
                  maxHeight: window.innerWidth > 1024 ? '55vh' : 400,
                  minHeight: 80,
               } }
            >
               {
                  attachments?.map((content, index) => {
                     let hideDetails = !isAdmin && (data?.unlock_details?.attachmentsStatuses?.[index]?.status === 'locked_free_content' || (!!data?.unlock_details.is_unlocked && +data?.unlock_details.unlock_price > 0) || (+data?.unlock_details.unlock_price === 0)) && ['photo_vault', 'video_vault']?.includes(content?.resource_type)
                     return (
                        <MessageElement
                           key={ index }
                           content={ content }
                           width={ 'auto' }
                           isAdmin={ isAdmin }
                           unlockDetails={ data?.unlock_details }
                           adminMessage={ adminMessage }
                           isUnlocked={ data?.unlock_details?.is_unlocked }
                           onRemoveMedia={ () => onRemoveMedia(content, index, data) }
                           title={ !isAdmin ? content.title || (content.resource_type.includes('video') ? 'Video' : 'Photo') : null }
                           hasDetailsPage={ hasAccess(content, index) || hideDetails }
                           siteInfo={ siteInfo }
                           index={ index }
                           contentStatus={ data?.unlock_details?.attachmentsStatuses?.[index]?.status }
                           closeChat={ onCloseChat }
                           contentLink={ () => {
                              if(!isAdmin && (hasAccess(content, index) || hideDetails)) {
                                 return getContentLink(content, data?.unlock_details?.attachmentsStatuses?.[index]?.status)
                              } else  if(isAdmin){
                                 return getContentLink(content, data?.unlock_details?.attachmentsStatuses?.[index]?.status)
                              } else {
                                 return null
                              }

                           } }
                        />
                     )
                  }
                  )
               }
            </div>
            <Button
               text='Ok'
               backgroundColor='transparent'
               textSize='small'
               primaryColor
               classNames='!w-[47px] mt-8 py-[9px] px-3.5'
               textClassName='!leading-[18px]'
               padding='none'
               textMarginX='0'
               onClick={ () => onCloseModal() }
            />

         </div>
      </Modal>
   )
}


MultipleAttachmentModal.propTypes = {
   data: PropTypes.object,
   isAdmin: PropTypes.bool,
   width: PropTypes.any,
   adminMessage: PropTypes.bool,
   onClose: PropTypes.func,
   onRemoveMedia: PropTypes.func,
   goToDetails: PropTypes.func,
   siteInfo: PropTypes.object,
   onCloseChat: PropTypes.func,
   getContentLink: PropTypes.func,
};

MultipleAttachmentModal.defaultProps = {
   data: {},
   width: '250px',
};

export default MultipleAttachmentModal;
