import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'common/components/modules/page-header';
import Icon from 'common/components/elements/icons';
import './style.scss';
import DateFormatter from 'common/utils/DateFormatter'
import moment from 'moment';
import account from 'assets/images/avatar.svg'
import PageLoader from 'common/components/modules/page-loading';
import TruncateText from 'common/components/elements/truncate-text';
import Avatar from 'common/components/modules/avatar';
import { getMemberTotalSpent } from 'common/utils/utils';


const TippersModal = ({
   content,
   onModalClose,
   contentId,
   onTipsModalClose,
}) => {


   const totalTips = content?.reduce((prev, curr) => {
      prev.totalAmount += +curr.amount;
      ++prev.tipCount;
      return prev
   }, {
      totalAmount: 0,
      tipCount: 0,
   })

   const getPrice = (price, position) => {
      let priceValue = Number(price);
      let result = 0;
      if(typeof priceValue === 'number' && !isNaN(priceValue)){
         result = priceValue.toFixed(position);
      }
      return result
   }

   return (
      <div>
         <>
            <PageHeader
               title={ 'Tip history' }
               fontIconSize='doubleLarge'
               fontIconColor='major'
               leftIconName='close'
               leftIconAction={ () => {
                  onModalClose()
                  onTipsModalClose()
               } }
               isFixed={ false }
               className='notranslate tip-header'
               isHelpIcon={ false }
            />
         </>
         {
            content?.length > 0 &&
            <div className='tip-total' style={ {
               display: 'flex',
               height: '52px',
               width: 'auto',
               justifyContent: 'flex-end',
               alignItems: 'center',
               padding: '0 16px',

            } }>
               <p className='text-secondary mr-1'>Total:</p> {totalTips?.totalAmount > 0 ? ` ${ totalTips.tipCount } ${ totalTips.tipCount === 1 ? 'tip' : 'tips' } (${ totalTips.totalAmount } USD)` : 'Tips'}
            </div>
         }
         {
            content?.length === 0 &&
            <div className='tip-not-found'>
               <Icon name='tip'
                  color='placeholder'
                  style={ {
                     fontSize: '44px',
                     marginBottom: '20px',
                  } } />
               <p className='text-placeholder'
                  style={ {
                     fontSize: '16px',
                     lineHeight: '20px',
                     fontWeight: '400',
                  } }
               >You haven’t received any tips on this content yet</p>

            </div>
         }

         {
            content === null ?
               (
                  <div style={ {
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     position: 'relative',
                     top: '150px',

                  } }>
                     <PageLoader customStyle='relative top-0' withLoader />
                  </div>
               )
               :
               (
                  <div className='overflow-y-auto' >
                     {!!content?.length && content?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((tip, index) => {

                        if(tip.tippable_id === contentId) {
                           return (
                              <div key={ tip.id }>
                                 <div className='tipper-modal
                              h-full w-full bg-panel flex items-center
                              justify-between sm:justify-around p-4
                            '>
                                    <>
                                       <div className='avatar flex bg-gray-100 tip-avatar' style={ {
                                          height: '40px',
                                          width: '40px',
                                          borderRadius: '50%',
                                       } } >
                                          <Avatar
                                             avatarSrc={ tip.member?.avatar_compressed_src ? tip.member.avatar_compressed_src : account }
                                             username={ tip.member?.nickname || tip.member?.username || 'Deleted member' }
                                          />
                                       </div>
                                    </>
                                    <div className='tippers-title flex flex-col gap-1 '>
                                       <p className='pt-2' style={ {
                                          fontSize: '16px',
                                          lineHeight: '20px',
                                          fontWeight: '500',
                                       } }>{tip.member?.nickname || tip.member?.username || 'Deleted member'}</p>
                                       {tip?.member && <div className='flex items-center gap-1'>
                                          <div className='flex items-center'>
                                             <div className='flex mr-1 rounded-full border border-success users-info-icon' style={ { padding: '7px' } }>
                                                <Icon name='membership' color='success' style={ { fontSize: '9px' } } />
                                             </div>
                                             <TruncateText
                                                text={ tip.member?.membership_type }
                                                textSize='12px'
                                                textClass='text-xs text-placeholder'
                                             />
                                             {/* <span className='text-xs text-placeholder truncate max-w-[210px]' style={ { lineHeight: '14px' } }>{tip.member?.membership_type}</span> */}
                                          </div>
                                       </div>}
                                       {tip?.member && <div className='flex items-center gap-1'>
                                          <div className='flex items-center'>
                                             <div className='flex mr-1 rounded-full border border-divider users-info-icon' style={ { padding: '7px' } }>
                                                <Icon name='money-2' color='secondary' style={ { fontSize: '12px' } } />
                                             </div>
                                             <span className='text-xs text-placeholder truncate' style={ { lineHeight: '14px' } }>
                                                Total spent: { getMemberTotalSpent(tip.member) } USD
                                             </span>
                                          </div>
                                          {tip.member?.top_percentage > 0 &&
                                          <p className='text-xs font-normal text-placeholder'>
                                             • Top { getPrice(tip.member?.top_percentage, 2) }%
                                          </p>
                                          }
                                       </div>}
                                    </div>
                                    <div className='tip-info flex items-end flex-col pb-4'>
                                       <p style={ {
                                          fontSize: '16px',
                                          lineHeight: '20px',
                                          fontWeight: '500',
                                       } }>{Number(tip.amount).toFixed()} USD</p>
                                       <p className='text-xs font-normal text-placeholder'>{moment(DateFormatter.convertToUserTz(tip.created_at)).fromNow()}</p>
                                    </div>
                                 </div>
                              </div>
                           )
                        } else {
                           return null
                        }
                     })
                     }
                  </div>
               )
         }


      </div>
   );
};


TippersModal.propTypes = {
   content: PropTypes.array,
   onModalClose: PropTypes.func,
   contentId: PropTypes.number,
   onTipsModalClose: PropTypes.func,
};


export default React.memo(TippersModal);
