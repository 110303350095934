import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   // all models data /
   [types.FETCH_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  res = action.payload;
      return {
         ...state,
         isFetching: false,
         data: {
            ...res.data,
            data: res.data.data,
         },
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   // next page data /
   [types.NEW_FETCH_REQUEST]: (state, action) => {
      return {
         ...state,
         isNewFetching: true,
      }
   },
   [types.NEW_FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const res = payload.data;
      return {
         ...state,
         isNewFetching: false,
         data: {
            ...res,
            data: [...state.data.data, ...res.data],
         },

      }
   },
   [types.NEW_FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isNewFetching: false,
      }
   },

   // filtered models data /
   [types.FILTER_MODELS_REQUEST]: (state) => {
      return {
         ...state,
         isFilterFetching: true,
      }
   },
   [types.FILTER_MODELS_COMPLETED]: (state, action) => {
      const  res = action.payload;
      return {
         ...state,
         isFilterFetching: false,
         data: {
            ...res.data,
            data: res.data.data,
         },
      }
   },
   [types.FILTER_MODELS_FAILED]: (state, action) => {
      return {
         ...state,
         isFilterFetching: false,
      }
   },

};

export default createReducer(initialState)(reducersMap);
