import { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import data from '@emoji-mart/data'
import EmojiPicker from 'common/components/elements/emoji-mart'
import Icon from 'common/components/elements/icons'
import { FrequentlyUsed, SearchIndex, init } from 'emoji-mart'
import cx from 'classnames'


const MessageFieldEmojisPicker = ({
   showEmojis,
   switchShowEmojis,
   addEmoji,
   topPosition,
   openVoiceMessage,
}) => {
   const [emojiWidth, setEmojiWidth] = useState(28)
   const [maxCountToDisplay, setMaxCountToDisplay] = useState(0)
   const wrapperRef = useRef(null)
   let observer = useRef(null)

   //  Calculate emoji width on various devices
   useEffect(() => {
      if(wrapperRef.current) {
         const spanElement = document.createElement('span')
         spanElement.classList.add('text-xl', 'leading-6', 'invisible', 'absolute')
         spanElement.innerHTML = '👍'

         wrapperRef.current.appendChild(spanElement)
         setEmojiWidth(spanElement.offsetWidth)
         wrapperRef.current.removeChild(spanElement);
      }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [wrapperRef.current])

   useEffect(() => {
      if(wrapperRef.current) {
         observer.current = new ResizeObserver(entries => {
            for(let entry of entries) {
               const emojisListWrapperWidth = entry.contentRect.width;
               const count = emojisListWrapperWidth / (emojiWidth + 8)

               setMaxCountToDisplay(Math.floor(count))
            }
         })
         observer.current.observe(wrapperRef.current)
      }

      return () => {
         if(observer.current) {
            observer.current.disconnect()
         }
      }
   }, [emojiWidth])

   const frequentEmojis = useMemo(() => {
      init({ data })
      return FrequentlyUsed.get({ maxFrequentRows: 1 })?.slice(0, maxCountToDisplay).map(item => SearchIndex.get(item))

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [maxCountToDisplay, showEmojis])


   return (
      <div
         className={ cx({
            'w-full bg-grey-main mb-2 px-[14px] py-2 flex justify-between gap-4 relative intercom-new-emoji-bar select-none rounded-t-lg': true,
            'hidden': openVoiceMessage,
         }) }
      >
         <div
            className='w-full flex items-center gap-2 max-w-[100%] overflow-hidden'
            ref={ wrapperRef }
         >
            {
               frequentEmojis && Array.isArray(frequentEmojis) && frequentEmojis.map(item => (
                  <span
                     key={ item.id }
                     role='presentation'
                     className='text-xl leading-6 cursor-pointer'
                     onClick={ () => addEmoji({ ...item, ...item.skins[0] }) }
                  >
                     { item.skins[0].native }
                  </span>
               ))
            }
         </div>
         <div
            className='cursor-pointer flex items-center'
            role='presentation'
            onClick={ (e) => {
               e.stopPropagation();
               switchShowEmojis();
            } }
            id='emoji-mart'
         >
            <Icon
               name='emoji' color='placeholder' darkColor='placeholder-dark' style={ { fontSize: '20px' } }
               className='duration-200 hover:text-secondary'
            />
         </div>
         {
            showEmojis && (
               <EmojiPicker
                  className='chat-emoji-picker'
                  onEmojiSelect={ e => addEmoji(e) }
                  style={ {
                     position: 'absolute',
                     width: '352px',
                     bottom: '38px',
                     right: '0',
                     zIndex: 1000,
                     top: topPosition,
                  } }
               />
            )
         }
      </div>
   )
}

MessageFieldEmojisPicker.propTypes = {
   showEmojis: PropTypes.bool,
   switchShowEmojis: PropTypes.func,
   addEmoji: PropTypes.func,
   topPosition: PropTypes.any,
   openVoiceMessage: PropTypes.bool,
}

MessageFieldEmojisPicker.defaultProps = {
   topPosition: 'auto',
}

export default MessageFieldEmojisPicker;
