import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { collapseMenu, resetCommonDetails, setViewAsModal } from 'admin/state/modules/common/actions';
import { resetUploadsVideos } from 'admin/state/modules/uploads/actions';
import {
   isMenuOpenedSelector,
   isMenuAnimationSelector,
   receivedOrdersCount,
   accountTypeSelector,
   isLoadingConnectToMylistSelector,
} from 'admin/state/modules/common/selectors';
import { checkAndUnlockDemoPublicSiteOperation, goToMylistOperation, updateIsOfflineModeOperation } from 'admin/state/modules/common/operations';
import { currentLocationPathSelector } from 'admin/state/modules/router/selectors';
import {
   authUserSelector,
   screenWidthSelector,
   siteInfoSelector,
   isUpdateOfflineModeSelector,
} from 'admin/state/modules/common/selectors';
import Navigation from 'common/components/modules/navigation';
import Router from 'admin/routes/router';
import { push } from 'connected-react-router';
import Auth from 'admin/utils/Auth';
import NavbarInformationAndPolicies from 'common/components/modules/information-and-policies/navbar-information-and-policies';
import { loggedInEvent } from 'admin/api/AuthApi.js';
import { copyText } from 'common/utils/utils.js';
import toast from 'common/utils/toast';
import SyncPageScoll from 'common/utils/pageScrollSync';
import { clearChatInfo } from 'admin/state/modules/chat/actions';
import QueryParams from 'admin/utils/QueryParams';
import { history } from "admin/state/store";
import CreateEditModal from 'common/components/modules/modal-wrapper';
import { isLocalhost, isNewPayoutVersion } from 'admin/utils/Helpers';
import OfflineMode from 'admin/views/offline-mode';
import SwitchToMylist from 'admin/views/switch-to-mylist';
import CreateMylistAccountModal from 'admin/views/create-mylist-account-modal';

const ROUTESTOMATCH = [
   'STORIES',
   'VIDEOS',
   'PHOTOSETS',
   'STORE',
   'VAULT',
   'LIVE_STREAM',
   'RECORDED_VIDEO',
   'STORIES_STATISTICS',
   'STORIES_REORDER',
   'STORIES_CREATE',
   'VIDEOS_SHOW',
   'VIDEOS_BULK_EDIT',
   'VIDEOS_REORDER',
   'VIDEOS_PUBLISH',
   'PHOTOSETS_REORDER',
   'PHOTOSETS_STATISTICS',
   'PHOTOSETS_PUBLISH',
   'PHOTOSETS_SHOW',
   'PHOTOSETS_EDIT_PHOTOS',
   'PHOTOSETS_PUBLISH_PHOTOS',
   'PHOTOSETS_BULK_EDIT',
   'STORE_REORDER',
   'STORE_DETAILS',
   'STORE_MANAGE_CATEGORIES',
   'STORE_ADD_NEW',
   'STORE_SHIPPING',
   'STORE_ADD_NEW_SHIPPING',
   'STORE_EDIT_SHIPPING_ZONE',
   'STORE_CREATE_BUNDLE',
   'STORE_EDIT_BUNDLE',
   'STORE_ORDER_REQUESTS',
   // 'MANAGE_ORDER_FORM_TEMPLATES',
   // 'CREATE_ORDER_REQUEST_FORM',
   // 'EDIT_ORDER_REQUEST_FORM',
   'ORDER_REQUEST_DETAILS',
   'TAGS',
   'COLLECTIONS',
   'LIVE_STREAM_CREATE',
   'LIVE_STREAM_REORDER',
   'LIVE_STREAM_STUDIO',
   'CAST_MEMBERS',
   'CAST_MEMBERS_CREATE',
];

class NavigationContainer extends Component {
   static propTypes = {
      isMenuOpened: PropTypes.bool.isRequired,
      collapseMenu: PropTypes.func.isRequired,
      goTo: PropTypes.func.isRequired,
      locationPath: PropTypes.string.isRequired,
      user: PropTypes.object.isRequired,
      logout: PropTypes.func.isRequired,
      isMenuAnimation: PropTypes.string,
      screenWidth: PropTypes.number,
      siteInfo: PropTypes.object,
      setViewAsModal: PropTypes.func,
      ordersCount: PropTypes.number,
      updateIsOfflineMode: PropTypes.func,
      isUpdateOfflineMode: PropTypes.bool,
      accountType: PropTypes.string,
      isLoadingConnectToMylist: PropTypes.bool,
      goToMylist: PropTypes.func,
      checkAndUnlockDemoPublicSite: PropTypes.func,
   };

   state = {
      openModal: false,
      intercomUnreadCount: 0,
      openLogoutModal: false,
      intercomUnreadNewsCount: 0,
      isOpenCreateMylistModal: false,
   }
   unlisten = null;
   intercomeTrigger = QueryParams.getParam('trigger') || null;

   async componentDidMount(){
      this.historylisten();

      if(window?.Intercom){
         window.Intercom('onUnreadCountChange', (unreadCount) => {
            const intercomUnreadCount = unreadCount > 99 ? '99+' : unreadCount;
            this.setState({ intercomUnreadCount })
         });
         window.Intercom('onHide', () => {
            if('contact-support' === QueryParams.getParam('trigger')){
               QueryParams.popQueryParam('trigger');
               this.intercomeTrigger = null;
            }
         });

         // Fetch to intercom to get unread news count
         try {
            const body = {
               app_id: process.env.REACT_APP_INTERCOM_APP_ID,
            }
            if(window.intercomSettings) {
               body.user_data = {
                  hide_launcher_selector: window.intercomSettings?.hide_launcher_selector,
                  user_id: window.intercomSettings?.user_id,
                  user_hash: window.intercomSettings?.user_hash,
               }
            }
            const intercomResponse = await fetch('https://api-iam.intercom.io/messenger/web/ping', {
               method: 'POST',
               body: JSON.stringify(body),
               headers: {
                  "Content-Type": "application/json",
               },
            })
            const { unread_news_items_count } = await intercomResponse.json()
            if(unread_news_items_count) {
               const intercomUnreadNewsCount = unread_news_items_count > 99 ? '99+' : unread_news_items_count
               this.setState({ intercomUnreadNewsCount })
            }
         } catch (error) {
            console.log(error);
         }

      }
   }

   historylisten = () => {
      this.unlisten = history.listen((location, action) => {
         if('POP' === action && 'contact-support' === this.intercomeTrigger && !location.search){
            if(!window.Intercom) return;
            window.Intercom('hide');
            this.intercomeTrigger = null;
         }
      });
   }

   componentWillUnmount(){
      this.unlisten();
   }

   collapseMenu = () => {
      const { collapseMenu } = this.props;
      collapseMenu();
   };

   toggleOpenModal = () => {
      this.setState(state => ({
         openModal: !state.openModal,
      }))
   }

   logOut = () => {
      const { logout, locationPath } = this.props;
      // TODO prompt want to logout
      logout(locationPath, window.location.search);
   };

   handleRouteChange = (location, search) =>  {
      const { goTo } = this.props;
      goTo(Router.route(location).getCompiledPath(), search);
      this.collapseMenu();
   }

   handleClickLiveSupport = () => {
      if('contact-support' !== QueryParams.getParam('trigger')){
         QueryParams.pushQueryParam('trigger', 'contact-support');
         this.intercomeTrigger = 'contact-support';
      }
      if(window.Intercom) {
         window.Intercom('show');
      }
   }

   onOpenCloseViewAsModal = bool => {
      const { setViewAsModal } = this.props;
      if(bool) {
         QueryParams.addQueryParam('open-modal', 'view-as');
      } else {
         QueryParams.removeQueryParam('open-modal');
      }
      setViewAsModal(bool)
   }


   showOrHidelogOut = bool => {
      this.setState({ openLogoutModal: bool });
   }

   withoutPayoutsPage = () => {
      const { siteInfo, user } = this.props;
      return 'demo_mode' === siteInfo?.site_status || siteInfo.white_label || isNewPayoutVersion(user.app_id);
   }

   getSupportUnreadSummary = () => {
      const { intercomUnreadCount, intercomUnreadNewsCount } = this.state;
      if('99+' === intercomUnreadCount || '99+' === intercomUnreadNewsCount) {
         return '99+'
      }
      const sum = intercomUnreadCount + intercomUnreadNewsCount

      return sum > 99 ? '99+' : sum

   }

   openCloseCreateMylistModal = (isOpen) => {
      this.setState({
         isOpenCreateMylistModal: isOpen,
      })
   }

   onClickSwithToMylist = async () => {
      const { accountType, goToMylist } = this.props;
      if('all' !== accountType) {
         this.openCloseCreateMylistModal(true)
      } else {
         goToMylist()
      }
   }

   render() {
      const {
         isMenuOpened,
         locationPath,
         user,
         isMenuAnimation,
         screenWidth,
         siteInfo,
         goTo,
         ordersCount,
         updateIsOfflineMode,
         isUpdateOfflineMode,
         accountType,
         isLoadingConnectToMylist,
         goToMylist,
         checkAndUnlockDemoPublicSite,
      } = this.props;
      const { intercomUnreadCount, openLogoutModal, intercomUnreadNewsCount, isOpenCreateMylistModal } = this.state;
      const wrapperClasses = classnames(`fixed top-0 left-0 z-50 w-full h-full ${ isMenuAnimation }`)
      const body = document.body;
      const type = screenWidth > 700 ? 'desktop' : 'mobile';
      if(type === 'mobile') {
         SyncPageScoll.syncScroll(isMenuOpened)
      } else {
         body.classList.remove('body_non_scroll-v2');
      }

      const matchedRoute = ROUTESTOMATCH
         .map(routeName => Router.route(routeName).match(locationPath))
         .find(matchedRoute => matchedRoute);

      const isDemoMode = siteInfo?.site_status === 'demo_mode'

      const buttomLinkNavigations = (
         <>
            <Navigation.Link
               title='News'
               onClick={ () => {
                  if(window.Intercom) {
                     window.Intercom('show');
                     window.Intercom('showNews')
                     this.setState({ intercomUnreadNewsCount: 0 })
                  }
               } }
               icon={ 'news' }
               isSubLink
               unreadCount={ intercomUnreadNewsCount }
            />
            <Navigation.Link
               title='Support chat'
               onClick={ () => {
                  this.handleClickLiveSupport();
               } }
               icon={ 'support-chat' }
               unreadCount={ intercomUnreadCount }
               isSubLink
            />
            <Navigation.Link
               title='Help Center'
               onClick={ () => {
                  if(window.Intercom) {
                     window.Intercom('showSpace', 'help');
                  }
                  this.collapseMenu()
               } }
               icon={ 'help-outline' }
               isSubLink
            />
            {/* <Navigation.Link
               title='Provide feedback'
               onClick={ () => {} }
               data-tooltip-content='Provide feedback'
               className='provide-feedback'
            /> */}
            <Navigation.Link
               title='Feedback & Community'
               onClick={ () => {
                  if(isDemoMode) return;
                  loggedInEvent(user)
                  window.open(`https://feedback.mymember.site/feature-requests?ssoToken=${ user.canny_token }`, '_blank');
               } }
               // data-tooltip-content='Suggest a feature'
               icon={ 'new-feedback' }
               isSubLink
               isDemoMode={ isDemoMode }
               data-tooltip-content={ isDemoMode ? 'Not available in demo' : undefined }
            />
            <Navigation.Link
               title='Information & Policies'
               onClick={ () => {
                  this.toggleOpenModal();
                  this.collapseMenu();
               } }
               // data-tooltip-content='Information and Policies'
               icon={ 'attention-outline' }
               isSubLink
               iconClassName={ `!text-[17px]` }
            />
         </>
      )

      const navigation = (
         <>
            <Navigation
               type={ type }
               onClose={ this.collapseMenu }
               onClickMyAccount={ () => this.handleRouteChange('MY_ACCOUNT') }
               user={ {
                  avatar: user.avatar_src ? user.avatar_src : user.compressed_avatar_src,
                  screen_name: user.screen_name,
               } }
               isAdmin
               onClickPreview={ () => this.onOpenCloseViewAsModal(true) }
               onShareLink={ () => {
                  checkAndUnlockDemoPublicSite(() => {
                     copyText(siteInfo.site_url)
                     toast.info('Your sharing link has been copied');
                  })
               } }
               onClickWebsiteLogo={ () => {
                  goTo(Router.route('HOMEPAGE').getCompiledPath())
                  type === 'mobile' && this.collapseMenu()
               } }
               siteInfo={ siteInfo }
               onClickSiteUrl={ () => {
                  checkAndUnlockDemoPublicSite(() => {
                     if(isLocalhost()) {
                        window?.open('http://' + siteInfo?.site_url, '_blank')
                     } else {
                        window?.open('https://' + siteInfo?.site_url, '_blank')
                     }
                  })
               } }
            >
               <Navigation.Group>
                  <Navigation.Link
                     icon='home'
                     title='Home'
                     to={ Router.route('HOMEPAGE').getCompiledPath() }
                     onClick={ () => this.handleRouteChange('HOMEPAGE') }
                     active={ !!Router.route('HOMEPAGE').match(locationPath) }
                     data-tooltip-content='Home'
                     isSimple
                  />
                  <Navigation.Subgroup
                     icon='color-new'
                     title='Design'
                     isActiveOneOfLinks={
                        Boolean(
                           Router.route('MY_SITE_DESIGN').match(locationPath)
                           || Router.route('MY_SITE_COLORS').match(locationPath)
                           || Router.route('MY_SITE_COLORS_PREVIEW').match(locationPath)
                           || Router.route('MY_SITE_COMING_SOON').match(locationPath)
                           || Router.route('MY_SITE_LOGO').match(locationPath)
                           || Router.route('MY_SITE_DESIGN').match(locationPath)
                           || Router.route('MY_SITE_HOMEPAGE').match(locationPath)
                           || Router.route('MY_SITE_ABOUT_ME').match(locationPath)
                           || Router.route('MY_SITE_HEADER').match(locationPath)
                           || Router.route('MY_SITE_CUSTOM_PAGE').match(locationPath)
                           || Router.route('MY_SITE_CREATE_NEW_CUSTOM_PAGE').match(locationPath)
                           || Router.route('MY_SITE_EDIT_CUSTOM_PAGE').match(locationPath)
                        )
                     }
                  >
                     <Navigation.Link
                        title='Designer'
                        to={ Router.route('MY_SITE_DESIGN').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('MY_SITE_DESIGN') }
                        active={ !!Router.route('MY_SITE_DESIGN').match(locationPath) }
                        icon={ `desginer` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='View my site'
                        font='normal'
                        to={ `${ window.location.pathname }?open-modal=view-as` }
                        onClick={ () => this.onOpenCloseViewAsModal(true) }
                        active={ QueryParams.getParam('open-modal') === 'view-as' }
                        icon={ `views` }
                        isSubLink
                     />
                  </Navigation.Subgroup>
                  <Navigation.Subgroup
                     icon='fundraiser'
                     title='Stats'
                     isActiveOneOfLinks={
                        Boolean(Router.route('STATISTICS_REVENUE').match(locationPath) || Router.route('STATISTICS_ENGAGEMENT').match(locationPath) || Router.route('STATISTICS_ACQUISITION').match(locationPath))
                     }
                  >
                     <Navigation.Link
                        title='Revenue'
                        font='normal'
                        to={ Router.route('STATISTICS_REVENUE').getCompiledPath() }
                        onClick={ () => this.handleRouteChange('STATISTICS_REVENUE') }
                        active={ Router.route('STATISTICS_REVENUE').match(locationPath) }
                        icon={ `revenue` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Engagement'
                        to={ Router.route('STATISTICS_ENGAGEMENT').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('STATISTICS_ENGAGEMENT') }
                        active={ !!Router.route('STATISTICS_ENGAGEMENT').match(locationPath) }
                        icon={ `engagement` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Acquisition'
                        font='normal'
                        to={ Router.route('STATISTICS_ACQUISITION').getCompiledPath() }
                        onClick={ () => this.handleRouteChange('STATISTICS_ACQUISITION') }
                        active={ Router.route('STATISTICS_ACQUISITION').match(locationPath) }
                        icon={ `acquisition` }
                        isSubLink
                     />
                  </Navigation.Subgroup>
                  <Navigation.Link
                     icon='activity-new'
                     title='Activity'
                     to={ Router.route('GLOBAL_ACTIVITY').getCompiledPath() }
                     onClick={ () => this.handleRouteChange('GLOBAL_ACTIVITY') }
                     active={ !!Router.route('GLOBAL_ACTIVITY').match(locationPath) }
                     data-tooltip-content='Activity'
                     className='intercom-global-activity'
                     isSimple
                  />
                  {/* <Navigation.Link
                     icon='web-site'
                     title='My site'
                     to={ Router.route('MY_SITE').getCompiledPath() }
                     onClick={ () => this.handleRouteChange('MY_SITE') }
                     active={ !!Router.route('MY_SITE').match(locationPath) }
                     data-tooltip-content='My site'
                     className='intercom-my-site'
                  /> */}

                  <Navigation.Subgroup
                     icon='content'
                     title='Content'
                     isActiveOneOfLinks={ matchedRoute }
                     count={ ordersCount }
                  >
                     <Navigation.Link
                        title='Livestream'
                        to={ Router.route('LIVE_STREAM').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('LIVE_STREAM') }
                        active={ Router.route('LIVE_STREAM').match(locationPath) }
                        icon={ `online-stream` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Stories'
                        to={ Router.route('STORIES').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('STORIES') }
                        active={ Router.route('STORIES').match(locationPath) }
                        icon={ `stories` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Videos'
                        to={ Router.route('VIDEOS').getCompiledPath() }
                        onClick={ () => this.handleRouteChange('VIDEOS') }
                        font='normal'
                        active={ Router.route('VIDEOS').match(locationPath) }
                        icon={ `videos` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Photosets'
                        to={ Router.route('PHOTOSETS').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('PHOTOSETS') }
                        active={ Router.route('PHOTOSETS').match(locationPath) }
                        icon={ `photosets` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Store'
                        to={ Router.route('STORE').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('STORE') }
                        active={ Router.route('STORE').match(locationPath) }
                        icon={ `store` }
                        isSubLink
                        count={ ordersCount }
                     />
                     <Navigation.Link
                        title='Vault'
                        to={ Router.route('VAULT').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('VAULT') }
                        active={ Router.route('VAULT').match(locationPath) }
                        icon={ `vault-new` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Content tags'
                        to={ Router.route('TAGS').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('TAGS') }
                        active={ Router.route('TAGS').match(locationPath) }
                        icon={ `tag-new` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Collections'
                        to={ Router.route('COLLECTIONS').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('COLLECTIONS') }
                        active={ Router.route('COLLECTIONS').match(locationPath) }
                        icon={ `collection` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Cast members'
                        to={ Router.route('CAST_MEMBERS').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('CAST_MEMBERS') }
                        active={ Boolean(!!Router.route('CAST_MEMBERS').match(locationPath) || !!Router.route('CAST_MEMBERS_CREATE').match(locationPath)) }
                        icon='cast-members-new'
                        isSubLink
                     />
                  </Navigation.Subgroup>
                  {/* <Navigation.Link
                     icon='photo-camera'
                     title='Stories'
                     to={ Router.route('STORIES').getCompiledPath() }
                     onClick={ () => this.handleRouteChange('STORIES') }
                     active={ !!Router.route('STORIES').match(locationPath) }
                     data-tooltip-content='Stories'
                  /> */}

                  <Navigation.Subgroup
                     icon='messaging'
                     title='Messaging'
                     isActiveOneOfLinks={
                        Boolean(Router.route('CHAT').match(locationPath)
                        || Router.route('MASS_MESSAGE').match(locationPath)
                        ||  Router.route('CHAT_SETTINGS').match(locationPath)
                        ||  Router.route('MANAGE_EMAIL_SUBSCRIBERS').match(locationPath)
                        ||  Router.route('MANAGE_EMAIL_UNSUBSCRIBERS').match(locationPath)
                        ||  Router.route('SEND_MASS_MESSAGE').match(locationPath)
                        )
                     }
                     containerWidth={ 280 }
                  >
                     <Navigation.Link
                        title='Chat'
                        font='normal'
                        to={ Router.route('CHAT').getCompiledPath() }
                        onClick={ () => this.handleRouteChange('CHAT') }
                        active={ Router.route('CHAT').match(locationPath) && !Router.route('CHAT_SETTINGS').match(locationPath) }
                        existPath={ Router.route('CHAT_SETTINGS').getCompiledPath() }
                        className='intercom-chat'
                        icon={ `chat` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Mass message'
                        to={ Router.route('MASS_MESSAGE').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('MASS_MESSAGE') }
                        active={ !!Router.route('MASS_MESSAGE').match(locationPath) }
                        icon={ `messaging` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Chat settings'
                        font='normal'
                        to={ Router.route('CHAT_SETTINGS').getCompiledPath() }
                        onClick={ () => this.handleRouteChange('CHAT_SETTINGS') }
                        active={ Router.route('CHAT_SETTINGS').match(locationPath) }
                        className='intercom-chat-settings'
                        icon={ `chat-settings` }
                        isSubLink
                     />
                  </Navigation.Subgroup>
                  <Navigation.Subgroup
                     icon='group'
                     title='Members'
                     isActiveOneOfLinks={
                        Boolean(Router.route('MEMBERS').match(locationPath) || Router.route('MEMBER_TAGS').match(locationPath)) || Router.route('MEMBERS_SHOW').match(locationPath)
                     }
                  >
                     <Navigation.Link
                        title='Member list'
                        to={ Router.route('MEMBERS').getCompiledPath() }
                        onClick={ () => this.handleRouteChange('MEMBERS') }
                        active={ Router.route('MEMBERS').match(locationPath) }
                        icon={ `member-list-new` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Member tags'
                        to={ Router.route('MEMBER_TAGS').getCompiledPath() }
                        onClick={ () => this.handleRouteChange('MEMBER_TAGS') }
                        active={ Router.route('MEMBER_TAGS').match(locationPath) }
                        icon={ `tag-new` }
                        isSubLink
                     />
                  </Navigation.Subgroup>
                  {/* <Navigation.Subgroup
                     title='Memberships'
                     icon='membership'
                     isActiveOneOfLinks={
                        Boolean(Router.route('PROMOTIONS').match(locationPath) || Router.route('MEMBERSHIPS').match(locationPath))
                     }
                  >
                     <Navigation.Link
                        title='Memberships'
                        to={ Router.route('MEMBERSHIPS').getCompiledPath() }
                        onClick={ () => this.handleRouteChange('MEMBERSHIPS') }
                        active={ !!Router.route('MEMBERSHIPS').match(locationPath) }
                     />
                  </Navigation.Subgroup> */}
                  <Navigation.Link
                     icon='membership'
                     title='Memberships'
                     to={ Router.route('MEMBERSHIPS').getCompiledPath() }
                     onClick={ () => this.handleRouteChange('MEMBERSHIPS') }
                     active={
                        Boolean(
                           !!Router.route('MEMBERSHIPS').match(locationPath)
                          || !!Router.route('MEMBERSHIPS_CREATE').match(locationPath)
                          || !!Router.route('MEMBERSHIPS_REORDER').match(locationPath)
                          || !!Router.route('MEMBERSHIPS_EDIT_GENERAL').match(locationPath)
                        )
                     }
                     data-tooltip-content='Memberships'
                     isSimple
                  />
                  {/* <Navigation.Link
                     icon='cast-members-new'
                     title='Cast members'
                     to={ Router.route('CAST_MEMBERS').getCompiledPath() }
                     onClick={ () => this.handleRouteChange('CAST_MEMBERS') }
                     active={
                        Boolean(
                           !!Router.route('CAST_MEMBERS').match(locationPath)
                           || !!Router.route('CAST_MEMBERS_CREATE').match(locationPath)
                        )
                     }
                     data-tooltip-content='Cast members'
                     isSimple
                  /> */}
                  {/* <Navigation.Link
                     icon='transactions'
                     title='Transactions'
                     to={ Router.route('TRANSACTIONS').getCompiledPath() }
                     onClick={ () => this.handleRouteChange('TRANSACTIONS') }
                     active={ !!Router.route('TRANSACTIONS').match(locationPath) || !!Router.route('TRANSACTIONS_WALLET_ACTIVITY').match(locationPath) }
                     data-tooltip-content='Transactions'
                  /> */}
                  {
                     this.withoutPayoutsPage() && (
                        <Navigation.Link
                           icon='transactions-new'
                           title='Transactions'
                           to={ Router.route('TRANSACTIONS').getCompiledPath() }
                           onClick={ () => this.handleRouteChange('TRANSACTIONS') }
                           active={  Boolean(
                              Router.route('TRANSACTIONS').match(locationPath)
                              || Router.route('TRANSACTIONS_DETAILS').match(locationPath)
                              || Router.route('TRANSACTIONS_WALLET_ACTIVITY').match(locationPath)
                              || Router.route('TRANSACTIONS_WALLET_ACTIVITY_DETAILS').match(locationPath)
                           ) }
                           data-tooltip-content='Transactions'
                           isSimple
                        />
                     )

                  }
                  {
                     !this.withoutPayoutsPage() && (
                        <Navigation.Subgroup
                           icon='money'
                           title='Financials'
                           isActiveOneOfLinks={
                              Boolean(
                                 Router.route('PAYOUT').match(locationPath)
                           || Router.route('TRANSACTIONS').match(locationPath)
                           || Router.route('TRANSACTIONS_DETAILS').match(locationPath)
                           || Router.route('TRANSACTIONS_WALLET_ACTIVITY').match(locationPath)
                           || Router.route('TRANSACTIONS_WALLET_ACTIVITY_DETAILS').match(locationPath)
                              )
                           }
                        >
                           <Navigation.Link
                              title='Payouts'
                              to={ Router.route('PAYOUT').getCompiledPath() }
                              font='normal'
                              onClick={ () => this.handleRouteChange('PAYOUT') }
                              active={ !!Router.route('PAYOUT').match(locationPath) }
                              icon={ `wallet` }
                              isSubLink
                           />
                           <Navigation.Link
                              title='Transactions'
                              to={ Router.route('TRANSACTIONS').getCompiledPath() }
                              font='normal'
                              onClick={ () => this.handleRouteChange('TRANSACTIONS') }
                              active={ !!Router.route('TRANSACTIONS').match(locationPath) }
                              icon={ `transactions-new` }
                              isSubLink
                           />
                        </Navigation.Subgroup>

                     )
                  }
                  <Navigation.Subgroup
                     icon='campaign'
                     title='Marketing'
                     isActiveOneOfLinks={
                        Boolean(
                           !!(Router.route('AFFILIATE_PROGRAM').match(locationPath)
                           || !!Router.route('REFERRALS').match(locationPath))
                           || !!Router.route('PROMOTIONS').match(locationPath)
                           || !!Router.route('MY_AFFILIATES').match(locationPath)
                           || !!Router.route('PROMOTIONS_DETAILS').match(locationPath)
                           || !!Router.route('PROMOTIONS_CREATE').match(locationPath)
                        )
                     }
                     containerWidth={ 280 }
                  >
                     <Navigation.Link
                        title='Promotions'
                        to={ Router.route('PROMOTIONS').getCompiledPath() }
                        onClick={ () => this.handleRouteChange('PROMOTIONS') }
                        active={ !!Router.route('PROMOTIONS').match(locationPath) }
                        icon={ `promotion` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='Refer creators & earn'
                        to={ Router.route('REFERRALS').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('REFERRALS') }
                        active={ !!Router.route('REFERRALS').match(locationPath) }
                        icon={ `referral` }
                        isSubLink
                     />
                     <Navigation.Link
                        title='My affiliate program'
                        to={ Router.route('AFFILIATE_PROGRAM').getCompiledPath() }
                        font='normal'
                        onClick={ () => this.handleRouteChange('AFFILIATE_PROGRAM') }
                        active={ !!Router.route('AFFILIATE_PROGRAM').match(locationPath) }
                        icon={ `money-outline-new` }
                        isSubLink
                     />
                  </Navigation.Subgroup>
                  <Navigation.Link
                     icon='settings-new'
                     title='Settings'
                     to={ Router.route('MY_SITE_SETTINGS').getCompiledPath() }
                     onClick={ () => this.handleRouteChange('MY_SITE_SETTINGS') }
                     active={
                        Boolean(
                           !!Router.route('MY_SITE_SETTINGS').match(locationPath)
                           || !!Router.route('MY_SITE_BLOCK_IP_WHITE_LIST').match(locationPath)
                           || !!Router.route('MY_SITE_BLOCK_IP_BLACK_LIST').match(locationPath)
                           || !!Router.route('MY_SITE_BLOCK_IP_BLACK_LIST').match(locationPath)
                           || !!Router.route('MY_SITE_BLOCK_REGIONS').match(locationPath)
                           || !!Router.route('MY_SITE_BLOCK_MEMBERS').match(locationPath)
                           || !!Router.route('MY_SITE_SCHEDULED_CONTENT').match(locationPath)
                           || !!Router.route('MY_SITE_DOMAIN_REDIRECTS').match(locationPath)
                           || !!Router.route('MY_SITE_SEO').match(locationPath)
                           || !!Router.route('MY_SITE_TRACKING_CODE').match(locationPath)
                           || !!Router.route('MY_SITE_WATERMARK').match(locationPath)
                           || !!Router.route('MY_SITE_CONTENT_CARDS').match(locationPath)
                           || !!Router.route('MY_SITE_LEGAL_PAGES').match(locationPath)
                           || !!Router.route('SINGULAR_OR_PLURAL_ADMINS').match(locationPath)
                           || !!Router.route('MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS').match(locationPath)
                           || !!Router.route('MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS_EDIT').match(locationPath)
                           || !!Router.route('MY_SITE_LEGAL_PAGES_TERMS_OF_USE').match(locationPath)
                           || !!Router.route('MY_SITE_LEGAL_PAGES_PRIVACY_POLICY').match(locationPath)
                           || !!Router.route('MY_SITE_LEGAL_PAGES_COOKIE_POLICY').match(locationPath)
                           || !!Router.route('MY_SITE_LEGAL_PAGES_GDRP_POLICY').match(locationPath)
                           || !!Router.route('MY_SITE_LEGAL_PAGES_2257_COMPLIANCE').match(locationPath)
                           || !!Router.route('MY_SITE_LEGAL_PAGES_DMCA').match(locationPath)
                           || !!Router.route('MY_SITE_LEGAL_PAGES_CCBILL').match(locationPath)
                           || !!Router.route('MY_DOMAIN_NAME').match(locationPath)
                           || !!Router.route('TWITTER_INTEGRATION').match(locationPath)
                           || !!Router.route('MY_SITE_CONTENT_CATEGORIES').match(locationPath)
                           || !!Router.route('MY_SITE_CONTENT_STORAGE').match(locationPath)
                        )
                     }
                     data-tooltip-content='Settings'
                     isSimple
                  />
               </Navigation.Group>

               <div className={ `intercom-bot-class flex flex-1 flex-col ${ type === 'desktop' ? 'justify-end' : '**mt-4 justify-end' }` }>
                  <Navigation.Group>
                     {
                        type === 'mobile' && (
                           <>
                              <Navigation.Group>
                                 <Navigation.Subgroup
                                    icon='info'
                                    title='Support'
                                    isActiveOneOfLinks={ false }
                                    unreadCount={ this.getSupportUnreadSummary() }
                                 >
                                    <Navigation.Link
                                       title='News'
                                       onClick={ () => {
                                          if(window.Intercom) {
                                             window.Intercom('showNews')
                                             this.setState({ intercomUnreadNewsCount: 0 })
                                          }
                                       } }
                                       icon={ 'news' }
                                       isSubLink
                                       unreadCount={ intercomUnreadNewsCount }
                                    />
                                    <Navigation.Link
                                       title='Support chat'
                                       onClick={ () => {
                                          this.handleClickLiveSupport();
                                       } }
                                       icon={ 'support-chat' }
                                       unreadCount={ intercomUnreadCount }
                                       isSubLink
                                    />
                                    <Navigation.Link
                                       title='Help center'
                                       onClick={ () => {
                                          if(window.Intercom) {
                                             window.Intercom('showSpace', 'help');
                                          }
                                          this.collapseMenu()
                                       } }
                                       icon={ 'help-outline' }
                                       isSubLink
                                    />
                                    {/* <Navigation.Link
                                    title='Provide feedback'
                                    onClick={ () => {} }
                                    data-tooltip-content='Provide feedback'
                                    className='provide-feedback'
                                 /> */}
                                    <Navigation.Link
                                       title='Feedback & Community'
                                       onClick={ () => {
                                          if(isDemoMode) return;
                                          loggedInEvent(user)
                                          window.open(`https://feedback.mymember.site/feature-requests?ssoToken=${ user.canny_token }`, '_blank');
                                       } }
                                       // data-tooltip-content='Suggest a feature'
                                       icon={ 'new-feedback' }
                                       isSubLink
                                       isDemoMode={ isDemoMode }
                                       data-tooltip-content={ isDemoMode ? 'Not available in demo' : undefined }
                                    />
                                    <Navigation.Link
                                       title='Information & Policies'
                                       onClick={ () => {
                                          this.toggleOpenModal();
                                          this.collapseMenu();
                                       } }
                                       // data-tooltip-content='Information and Policies'
                                       icon={ 'attention-outline' }
                                       isSubLink
                                       iconClassName={ `!text-[17px]` }
                                    />
                                 </Navigation.Subgroup>
                                 <Navigation.Subgroup
                                    title='My account'
                                    icon='account'
                                    isActiveOneOfLinks={
                                       !!(Router.route('MY_ACCOUNT').match(locationPath))
                                    }
                                    useAvatar
                                    avatarSrc={ user.avatar_src ? user.avatar_src : user.compressed_avatar_src }
                                    className='intercom-admin-my-account'
                                 >
                                    {/* <div
                                       className='w-full px-2 py-3 flex gap-3 cursor-pointer hover:bg-hover hover:h-full'
                                       role='presentation'
                                       onClick={ () => this.handleRouteChange('MY_ACCOUNT') }
                                    >
                                       <img
                                          src={ user.avatar_src ? user.avatar_src : user.compressed_avatar_src  }
                                          alt='Avatar'
                                          className='object-center object-cover rounded-full w-10 h-10'
                                       />
                                       <div
                                          className='flex flex-col items-center justify-center'
                                       >
                                          <span className='font-medium text-major'>My account</span>
                                       </div>
                                    </div> */}
                                    <OfflineMode
                                       isOfflineMode={ siteInfo.is_offline_mode }
                                       updateIsOfflineMode={ updateIsOfflineMode }
                                       disabled={ isUpdateOfflineMode }
                                       style={ {
                                          paddingLeft: '44px',
                                       } }
                                       isDemoMode={ isDemoMode }
                                    />
                                    <Navigation.Link
                                       title='My account'
                                       onClick={ () => this.handleRouteChange('MY_ACCOUNT') }
                                       font='normal'
                                       active={ !!(Router.route('MY_ACCOUNT').match(locationPath)) }
                                       icon='account-settings'
                                       isMobile
                                       className={ `pl-[56px] ${ !!(Router.route('MY_ACCOUNT').match(locationPath)) ? `bg-hover [&>span]:!text-major [&>div>span.ams-icon]:!text-secondary ` : `` }` }
                                       isSubLink
                                    />
                                    <>
                                       {
                                          Boolean(siteInfo.show_mylist) && (
                                             <SwitchToMylist
                                                isSubLink
                                                accountType={ accountType }
                                                onClick={ this.onClickSwithToMylist }
                                             />
                                          )}
                                    </>

                                    <Navigation.Link
                                       title='Log out'
                                       onClick={ () => {
                                          this.showOrHidelogOut(true)
                                          this.collapseMenu();
                                       } }
                                       font='normal'
                                       active={ false }
                                       isLogout
                                       icon='sign-out'
                                       isMobile
                                       className={ `pl-[56px] [&>span]:!text-error` }
                                       isSubLink
                                    />
                                 </Navigation.Subgroup>
                              </Navigation.Group>
                           </>
                        )
                     }
                     {
                        type === 'desktop' && (
                           <>
                              <Navigation.Subgroup
                                 icon='info'
                                 title='Help'
                                 unreadCount={ this.getSupportUnreadSummary() }
                                 containerWidth={ 280 }
                              >
                                 {buttomLinkNavigations}
                              </Navigation.Subgroup>
                              <Navigation.Subgroup
                                 src={ user.avatar_src ? user.avatar_src : user.compressed_avatar_src  }
                                 title='My account'
                                 isActiveOneOfLinks={
                                    !!(Router.route('MY_ACCOUNT').match(locationPath))
                                 }
                                 className='intercom-admin-my-account'
                                 containerWidth={ 282 }
                              >
                                 <OfflineMode
                                    isOfflineMode={ siteInfo.is_offline_mode }
                                    updateIsOfflineMode={ data => updateIsOfflineMode(data, user) }
                                    disabled={ isUpdateOfflineMode }
                                    isDemoMode={ isDemoMode }
                                 />
                                 <Navigation.Link
                                    title='My account'
                                    to={ `${ Router.route('MY_ACCOUNT').getCompiledPath() }?activeTab=public_profile` }
                                    font='normal'
                                    onClick={ () =>  this.handleRouteChange('MY_ACCOUNT', '?activeTab=public_profile')  }
                                    active={ !!Router.route('MY_ACCOUNT').match(locationPath) }
                                    className={ `${ !!(Router.route('MY_ACCOUNT').match(locationPath)) ? `bg-hover [&>span]:!text-major [&>div>span.ams-icon]:!text-secondary ` : `` }` }
                                    icon='account-settings'
                                    isSubLink
                                 />
                                 <>
                                    {
                                       Boolean(siteInfo.show_mylist) && (
                                          <SwitchToMylist
                                             accountType={ accountType }
                                             onClick={ this.onClickSwithToMylist }
                                          />
                                       )}
                                 </>

                                 <Navigation.Link
                                    title='Log out'
                                    onClick={ () => this.showOrHidelogOut(true) }
                                    font='normal'
                                    active={ false }
                                    isLogout
                                    icon='sign-out'
                                    isSubLink
                                    className={ '[&>span]:!text-error' }
                                 />
                              </Navigation.Subgroup>
                           </>
                        )
                     }
                  </Navigation.Group>

               </div>
            </Navigation>
            {
               this.state.openModal && (
                  <NavbarInformationAndPolicies
                     showButton={ false }
                     isOpen={ this.state.openModal }
                     onClose={ this.toggleOpenModal }
                     modalCenter={  type !== 'mobile' }
                  />

               )
            }
            {
               openLogoutModal && (
                  <CreateEditModal
                     type='confirm'
                     icon='sign-out'
                     iconColor='error'
                     descriptionColorClass='text-secondary'
                     action={ () => this.logOut() }
                     onCloseModal={ () => this.showOrHidelogOut(false) }
                     title='Log out?'
                     description='Are you sure you want to log out from your account?'
                     cancelText='Cancel'
                     buttonText='Yes, log out'
                     nonPrimaryColor
                     textColor='error'
                     contentWidth='375px'
                     isMobile={ type === 'mobile' }
                     buttonClassName='mt-8 text-error'
                     iconSize='2xl'
                     isCloseAction={ true }
                  />
               )

            }
            {
               isOpenCreateMylistModal && (
                  <CreateMylistAccountModal
                     onCloseModal={ () => this.openCloseCreateMylistModal(false) }
                     onClickCreate={ () => {
                        goToMylist(() => this.openCloseCreateMylistModal(false))
                     } }
                     isLoading={ isLoadingConnectToMylist }
                  />
               )
            }
         </>
      )
      if(type === 'mobile') {
         return (
            <div
               className={ wrapperClasses }
               onClick={ () => {
                  if(!this.state.openModal && !openLogoutModal) {
                     this.collapseMenu()
                  }
               } }
               role='presentation'
               style={ {
                  backgroundColor: 'rgba(248, 249, 250, 0.7)',
               } }
               onTouchMove={ (e) => e.stopPropagation() }
            >
               { navigation }
            </div>
         )
      }
      return (
         <div
            role='presentation'
            style={ {
               width: '56px',
               height: '100vh',
            } }
         >
            <div
               className='fixed top-0 left-0 h-full'
            >
               { navigation }
            </div>
         </div>
      )

   }
}
const mapStateToProps = (state) => {
   return {
      isMenuOpened: isMenuOpenedSelector(state),
      locationPath: currentLocationPathSelector(state),
      user: authUserSelector(state),
      isMenuAnimation: isMenuAnimationSelector(state),
      screenWidth: screenWidthSelector(state),
      siteInfo: siteInfoSelector(state),
      ordersCount: receivedOrdersCount(state),
      isUpdateOfflineMode: isUpdateOfflineModeSelector(state),
      accountType: accountTypeSelector(state),
      isLoadingConnectToMylist: isLoadingConnectToMylistSelector(state),
   }
}
const mapDispatchToProps = (dispatch) => {
   return {
      collapseMenu: () => {
         dispatch(collapseMenu())
      },
      goTo: (location, search = '') => {
         dispatch(push({ pathname: location, search }))
      },
      logout: (path, search) => {
         Auth.logout();
         dispatch(resetCommonDetails());
         dispatch(resetUploadsVideos());
         dispatch(clearChatInfo());
         let existPath = path && path !== '/';
         let goBack = existPath ? `go-back=${ path }` : '';
         if(!!search) {
            goBack = `${ goBack }${ encodeURIComponent(search) }`
         }
         Auth.redirectSignIn(goBack, () => {
            dispatch(push({
               pathname: Router.route('SIGN_IN').getCompiledPath(),
               search: goBack,
            }));
         });
      },
      setViewAsModal: bool =>  dispatch(setViewAsModal(bool)),
      updateIsOfflineMode: (data) =>  dispatch(updateIsOfflineModeOperation(data)),
      goToMylist: (callback) => dispatch(goToMylistOperation(callback)),
      checkAndUnlockDemoPublicSite: (callback) => dispatch(checkAndUnlockDemoPublicSiteOperation(callback)),
   }
};
export default connect(mapStateToProps, mapDispatchToProps)(NavigationContainer);
