const initialState = {
   isFetching: true,
   isFetchingByFilter: false,
   pricingsData: [],
   activeData: {},
   newPricing: {},
   detailsError: {},
   isFetchingUpdate: false,
   isFetchingAvailable: true,
   isEmpty: false,
   isEmptyByFilter: false,
   isOpenNewMembershipModal: false,
   isOpenEditMembershipModal: false,
   isContinue: true,
   membershipInfo: {},
   isCheckedValidation: false,
   isFechingCreate: false,
   addPricingFetching: false,
   deletePricingFetching: false,
   isFetchingById: true,
   duplicatePricingFetching: false,
   benefitErrors: {},
   duplicateError: {},
   membershipFilters: {},
};

export default initialState;
