import {
   getStoryComments,
   getStoryCommentsByFilter,
   storyCommentLike,
   storyCommentDelete,
   sendStoryComment,
   updateMember,
}  from 'admin/api/AuthApi';

import {
   fetchStoryCommentsRequest,
   fetchStoryCommentsCompleted,
   fetchStoryCommentsFailed,

   fetchCommentsByFilterRequest,
   fetchCommentsByFilterCompleted,
   fetchCommentsByFilterFailed,

   fetchNewCommentsRequest,
   fetchNewCommentsCompleted,
   fetchNewCommentsFailed,

   commentLikeRequest,
   commentLikeCompleted,
   commentLikeFailed,

   commentDeleteRequest,
   commentDeleteCompleted,
   commentDeleteFailed,

   sendCommentRequest,
   sendCommentCompleted,
   sendCommentFailed,

   commentMemberUpdateCompleted,
   commentMemberUpdateFailed,
} from "./actions";
import {
   replyCommentsToggleLikeCompleted as notificationsReplyCommentsLikeCompleted,
   replyCommentsDeleteCompleted as notificationsReplyCommentDeleteCompleted,
} from 'admin/state/modules/notifications/actions';

import toast from "common/utils/toast";
import { setMemberUsernameModal } from '../members/actions';

export const getStoryCommentsOperation = (id, type, countComments, query = []) => {
   return async (dispatch) => {
      dispatch(fetchStoryCommentsRequest())
      try {
         const response = await getStoryComments(id, type, query);
         dispatch(fetchStoryCommentsCompleted(response.data, countComments))
      } catch (error) {
         dispatch(fetchStoryCommentsFailed(error))
      }
   };
};

export const getStoryCommentsByFilterOperation = (id, query, type) => {
   return async (dispatch) => {
      dispatch(fetchCommentsByFilterRequest())
      try {
         const response = await getStoryCommentsByFilter(id, query, type);
         dispatch(fetchCommentsByFilterCompleted(response.data))
      } catch (error) {
         dispatch(fetchCommentsByFilterFailed(error))
      }
   };
};

export const commentLikeOperation = (id, commentId, parentId, type, isReply = true) => {
   return async (dispatch) => {
      dispatch(commentLikeRequest())
      try {
         await storyCommentLike(id, commentId, type);
         dispatch(commentLikeCompleted({ commentId, parentId }))
         if(isReply) {
            dispatch(notificationsReplyCommentsLikeCompleted({ commentId, parentId }))
         }
      } catch (error) {
         dispatch(commentLikeFailed(error))
      }
   };
};

export const commentDeleteOperation = (id, commentId, parentId, type) => {
   return async (dispatch) => {
      dispatch(commentDeleteRequest())
      try {
         await storyCommentDelete(id, commentId, type);
         dispatch(commentDeleteCompleted({ commentId, parentId }))
         dispatch(notificationsReplyCommentDeleteCompleted({ commentId, parentId }))
         toast.remove('Comment has been deleted')
      } catch (error) {
         dispatch(commentDeleteFailed(error))
      }
   };
};

export const getNewCommentsOperation = (id, query, type) => {
   return async (dispatch) => {
      dispatch(fetchNewCommentsRequest())
      try {
         const response = await getStoryCommentsByFilter(id, query, type);
         dispatch(fetchNewCommentsCompleted(response.data))
      } catch (error) {
         dispatch(fetchNewCommentsFailed(error))
      }
   };
};

export const sendCommentOperation = (data, type) => {
   return async (dispatch) => {
      dispatch(sendCommentRequest());
      try {
         const response = await sendStoryComment(data, type);
         // window.scrollTo({
         //    top: !data.parent_id ?  0 : document.getElementById('activeReply') ? document.getElementById('activeReply').offsetTop : 0,
         //    left: 0,
         //    behavior: 'smooth',
         // });
         dispatch(sendCommentCompleted(response.data))
         if(!response?.data?.parent_id) {
            setTimeout(() => {
               const element = document.getElementById('comments-list')
               if(element) {
                  element.scrollTo({ top: element.scrollHeight, left: 0, behavior: 'smooth' })
               }
            }, 200)
         }
         toast.info('Your comment has been posted')
      } catch (error) {
         dispatch(sendCommentFailed(error))
      }
   };
};


export function updateCommentMemberOperation(data, id, callBack) {
   return async dispatch => {
      dispatch(commentMemberUpdateFailed({}));
      try {
         await updateMember(id, data)
         dispatch(commentMemberUpdateCompleted({ id, data }));
         toast.success('Your changes have been saved')
         if(callBack){
            callBack()
         }
         dispatch(setMemberUsernameModal(false))
      } catch (err) {
         if(callBack){
            callBack()
         }
         if(err && err.response) {
            dispatch(commentMemberUpdateFailed(err.response.data))
         }
      }
   }
}

export const blockMemberOperation = (id, data, name, type) => {
   return async (dispatch) => {
      try {
         await updateMember(id, data, type);
         dispatch(commentMemberUpdateCompleted({ id, banned: data.banned }))
         if(data.banned) {
            toast.remove(`${ name } has been blocked`)
         } else {
            toast.info(`${ name } has been unblocked`)
         }
      } catch (error) {
      }
   };
};
