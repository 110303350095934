import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from 'react-router';
import { connect } from "react-redux";
import { siteDetailsInitOperation } from 'admin/state/modules/common/operations';
import { authUserSelector, isSiteInitedSelector } from 'admin/state/modules/common/selectors';
import { push } from 'connected-react-router';
import Router from 'admin/routes/router';

class Guest extends Component {
   static propTypes = {
      authUser: PropTypes.object,
      isSiteInited: PropTypes.bool.isRequired,
      component: PropTypes.any,
      init: PropTypes.func.isRequired,
      goToHomePage: PropTypes.func.isRequired,
   };

   componentDidMount() {
      const { init } = this.props;
      init();
   }

   componentDidUpdate() {
      const { isSiteInited, authUser, goToHomePage } = this.props;
      if(isSiteInited && authUser) {
         goToHomePage();
      }
   }

   render() {
      const {
         component: ChildComponent,
         authUser,
         isSiteInited,
         ...rest
      } = this.props;

      if(isSiteInited && !authUser) {
         return (
            <Route
               { ...rest }
               render={ (matchProps) => {
                  return <ChildComponent { ...matchProps } />;
               } }
            />
         );
      }
      return null;
   }
}

const mapStateToProps = (state) => {
   return {
      authUser: authUserSelector(state),
      isSiteInited: isSiteInitedSelector(state),
   };
}

const mapDispatchToProps = (dispatch) => ({
   init: () => {
      dispatch(siteDetailsInitOperation())
   },
   goToHomePage: () => {
      dispatch(push(Router.route('HOMEPAGE').getCompiledPath()));
   },
});

export default connect(mapStateToProps, mapDispatchToProps)(Guest);
