// FETCH TIPS
export const FETCH_TIPS_REQUEST = 'tips/FETCH_TIPS_REQUEST';
export const FETCH_TIPS_COMPLETED = 'tips/FETCH_TIPS_COMPLETED';
export const FETCH_TIPS_FAILED = 'tips/FETCH_TIPS_FAILED';

// FETCH NEW PAGE TIPS
export const FETCH_NEW_TIPS_REQUEST = 'tips/FETCH_NEW_TIPS_REQUEST';
export const FETCH_NEW_TIPS_COMPLETED = 'tips/FETCH_NEW_TIPS_COMPLETED';
export const FETCH_NEW_TIPS_FAILED = 'tips/FETCH_NEW_TIPS_FAILED';

// FETCH TIPS SETTINGS DATA
export const FETCH_TIPS_SETTINGS_REQUEST = 'tips/FETCH_TIPS_SETTINGS_REQUEST';
export const FETCH_TIPS_SETTINGS_COMPLETED = 'tips/FETCH_TIPS_SETTINGS_COMPLETED';
export const FETCH_TIPS_SETTINGS_FAILED = 'tips/FETCH_TIPS_SETTINGS_FAILED';

// FETCH TIPS STATISTICS
export const FETCH_TIPS_STATISTICS_REQUEST = 'tips/FETCH_TIPS_STATISTICS_REQUEST';
export const FETCH_TIPS_STATISTICS_COMPLETED = 'tips/FETCH_TIPS_STATISTICS_COMPLETED';
export const FETCH_TIPS_STATISTICS_FAILED = 'tips/FETCH_TIPS_STATISTICS_FAILED';
