
import { createSelector } from 'reselect'

const innerStateSelector = state => state.resources;

const isFetchingSelector = createSelector(
   innerStateSelector,
   state => state.isFetching
);

const resourcesSelector = createSelector(
   innerStateSelector,
   state => state.resources
);

const defaultAccessesVideoSelector = createSelector(
   innerStateSelector,
   state => state.defaultAccesses.video
);

const defaultAccessesPhotosetSelector = createSelector(
   innerStateSelector,
   state => state.defaultAccesses.photoset
);

const isFetchingDefaultAccessSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingDefaultAccess
);

const mediaDownloadButtonSelector = createSelector(
   innerStateSelector,
   state => state.mediaDownloadButton
);
const wrongDownloadModalSelector = createSelector(
   innerStateSelector,
   state => state.wrongDownloadModal
);
const countesStateSelector = createSelector(
   innerStateSelector,
   state => state.countesState
);

export {
   isFetchingSelector,
   resourcesSelector,
   defaultAccessesVideoSelector,
   isFetchingDefaultAccessSelector,
   defaultAccessesPhotosetSelector,

   mediaDownloadButtonSelector,
   wrongDownloadModalSelector,
   countesStateSelector,
}
