import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChatMessageField from 'common/components/modules/chat/new-message-field';
import './style.scss';

const NotificationReply = ({
   replyMessage,
   isMobile,
   sendMessage,
   cancelReplyMessage,
   userAvatar,
   isAdmin,
}) => {
   const inputRef = React.createRef();
   const [replyData, setReplyData] = useState({});
   useEffect(() => {
      let reply = {
         ...replyMessage,
         payload: {
            ...replyMessage.payload,
            user: replyMessage.user,
         },
      };
      delete reply.user;
      setReplyData(reply);

      if(inputRef && inputRef.current && !isMobile) {
         inputRef.current.click();
         inputRef.current.focus();
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className='notification-reply-comment flex flex-col mt-1'>
         <ChatMessageField
            isMobile={ false }
            isAdmin={ false }
            isMuted={ false }
            sendMessage={ (text) => sendMessage(text, replyData) }
            canelReplyMessage={ cancelReplyMessage }
            replyMessage={ replyData?.payload }
            text={ 'text' }
            mutedInfo={ {} }
            inputRef={ inputRef }
            isConnected={ true }
            isGroup={ false }
            isMessageToLong={ false }
            userUuid={ '' }
            content={ {} }
            maxPrice={ 10 }
            // isShowUser
            userAvatar={ userAvatar }
            intoNotificationCard
            showNickname={ isAdmin }
         />
      </div>
   );
};

NotificationReply.propTypes = {
   isMobile: PropTypes.bool,
   replyMessage: PropTypes.object,
   sendMessage: PropTypes.func,
   cancelReplyMessage: PropTypes.func,
   userAvatar: PropTypes.string,
   isAdmin: PropTypes.bool,
};
NotificationReply.defaultProps = {
   onCloseModal: () => {},
   comments: [],
};

export default React.memo(NotificationReply);
