import { copyText, getProtocol } from 'common/utils/utils.js';
import toast from 'common/utils/toast';

const CopyContentLink = (siteUrl, id, type) => {
   let link = window.location.href
   if(id){
      let baseUrl = `${ getProtocol() }${ siteUrl }`;
      let suffix = '';
      switch (type) {
         case 'store' :
            suffix = `/store/${ id }`;
            break;
         case 'story' :
            suffix = `/stories/${ id }`;
            break;
         case 'video' :
            suffix = `/videos/${ id }`;
            break;
         case 'photoset' :
            suffix = `/photosets/${ id }`;
            break;
         case 'recorded-stream' :
            suffix = `/recorded-streams/${ id }`;
            break;
         default:
            break;
      }
      link = baseUrl + suffix;
   }
   copyText(link);
   toast.info('Your sharing link has been copied');
};

export {
   CopyContentLink,
};
