import * as types from "./types";

const fetchDetails = () => ({
   type: types.FETCH_DETAILS,
});

const fetchDetailsCompleted = (data, fetchByFilter) => {
   return {
      type: types.FETCH_DETAILS_COMPLETED,
      payload: { data, fetchByFilter },
   }
};

const fetchDetailsFailed = (err) => ({
   type: types.FETCH_DETAILS_FAILED,
   payload: err,
});

// next page data
const fetchNewStore = () => ({
   type: types.FETCH_NEW_STORE,
});

const fetchNewStoreCompleted = (data) => {
   return {
      type: types.FETCH_NEW_STORE_COMPLETED,
      payload: data,
   }
};

const fetchNewStoreFailed = (err) => ({
   type: types.FETCH_NEW_STORE_FAILED,
   payload: err,
});


// by filter
const fetchFilterStore = () => ({
   type: types.FETCH_FILTER_STORE,
});

const fetchFilterStoreCompleted = (data) => {
   return {
      type: types.FETCH_FILTER_STORE_COMPLETED,
      payload: data,
   }
};

const afterFilterCompleted = () => ({
   type: types.AFTER_FILTER_COMPLETED,
})

const fetchFilterStoreFailed = (err) => ({
   type: types.FETCH_FILTER_STORE_FAILED,
   payload: err,
});

// get by id
const fetchStoreById = () => ({
   type: types.FETCH_STORE_BY_ID,
});

const fetchStoreByIdCompleted = (data) => {
   return {
      type: types.FETCH_STORE_BY_ID_COMPLETED,
      payload: data,
   }
};

const fetchStoreByIdFailed = (err) => ({
   type: types.FETCH_STORE_BY_ID_FAILED,
   payload: err,
});


// update store data
const updateStoreList = (data) => ({
   type: types.UPDATE_STORE_LIST,
   payload: data,
});

const updateStoreFailed = (err) => ({
   type: types.UPDATE_FAILED,
   payload: err,
});
const deleteStoreCompleted = id => ({
   type: types.DELETE_STORE_COMPLETED,
   payload: id,
});

// create new store item
const createNewStore = () => ({
   type: types.CREATE_NEW_STORE,
});

const createNewStoreCompleted = (data) => {
   return {
      type: types.CREATE_NEW_STORE_COMPLETED,
      payload: data,
   }
};

const createNewStoreFailed = (err) => ({
   type: types.CREATE_NEW_STORE_FAILED,
   payload: err,
});

// save new store data
const saveNewStoreDataCompleted = data => ({
   type: types.SAVE_NEW_STORE_DATA,
   payload: data,
});


const changeLoadingState = bool => ({
   type: types.CHANGE_LOADING_STATE,
   payload: bool,
})


///////////////////////////////
const fetchResources = () => ({
   type: types.FETCH_RESOURCES,
});

const fetchResourcesCompleted = (data) => {
   return {
      type: types.FETCH_RESOURCES_COMPLETED,
      payload: data,
   }
};

const fetchResourcesFailed = (err) => ({
   type: types.FETCH_RESOURCES_FAILED,
   payload: err,
});

// colection by id
const fetchCollectionById = () => ({
   type: types.FETCH_COLLECTION_BY_ID,
});

const fetchCollectionByIdCompleted = (data) => {
   return {
      type: types.FETCH_COLLECTION_BY_ID_COMPLETED,
      payload: data,
   }
};

const fetchCollectionByIdFailed = (err) => ({
   type: types.FETCH_COLLECTION_BY_ID_FAILED,
   payload: err,
});

const setStoreImg = data => ({
   type: types.SET_STORE_IMG,
   payload: data,
});
const setDeleteModal = data => ({
   type: types.SET_DELETE_MODAL,
   payload: data,
});
const setNewItemButtonnDisabled = bool => ({
   type: types.SET_NEW_BUTTON_DISABLED,
   payload: bool,
});


// update category
const updateCategoryData = () => ({
   type: types.UPDATE_CATEGORY,
});

const updateCategoryDataCompleted = (data) => {
   return {
      type: types.UPDATE_CATEGORY_COMPLETED,
      payload: data,
   }
};

const updateCategoryDataFailed = (err) => ({
   type: types.UPDATE_CATEGORY_FAILED,
   payload: err,
});

// create category
const createNewCategory = () => ({
   type: types.CREATE_CATEGORY,
});

const createNewCategoryCompleted = (data) => {
   return {
      type: types.CREATE_CATEGORY_COMPLETED,
      payload: data,
   }
};

const createNewCategoryFailed = (err) => ({
   type: types.CREATE_CATEGORY_FAILED,
   payload: err,
});

// delete category
const deleteCategory = () => ({
   type: types.DELETE_CATEGORY,
});

const deleteCategoryCompleted = (data) => {
   return {
      type: types.DELETE_CATEGORY_COMPLETED,
      payload: data,
   }
};

const deleteCategoryFailed = (err) => ({
   type: types.DELETE_CATEGORY_FAILED,
   payload: err,
});

const openModalForShippingZone = data => ({
   type: types.OPEN_SHIPPING_ZONE_MODAL,
   payload: data,
})

const fetchDataByFilters = () => ({
   type: types.FETCH_DATA_BY_FILTERS,
})

const fetchAllCategories = (data) => ({
   type: types.FETCH_STORE_CATEGORIES,
   payload: data,
})

const changeShippingZones = (data) => ({
   type: types.CHANGE_CHECKED_SHIPPING_ZONES,
   payload: data,
})

const changeStoreEditData = (data) => ({
   type: types.CHANGE_STORE_DETAILS_DATA,
   payload: data,
})

const addOrRemoveImage = (data) => ({
   type: types.ADD_OR_REMOVE_IMAGE,
   payload: data,
})

const changeStoreDetailsErrors = (data) => ({
   type: types.CHANGE_STORE_DETAILS_ERRORS,
   payload: data,
})

const getStoreValidationNubers = (data) => ({
   type: types.GET_STORE_VALIDATION_NUMBERS,
   payload: data,
})

const saveStoreScrollPositionAndFilters = (data) => ({
   type: types.SAVE_STORE_SCROLL_POSITION_AND_FILTERS,
   payload: data,
})


//order requests start

const fetchOrderRequestsStart = () => ({
   type: types.FETCH_ORDER_REQUESTS_START,
})

const fetchOrderRequestsCompleted = (data) => ({
   type: types.FETCH_ORDER_REQUESTS_COMPLETED,
   payload: data,
})

const fetchOrderRequestsByFilterStart = () => ({
   type: types.FETCH_ORDER_REQUESTS_BY_FILTER_START,
})

const fetchOrderRequestsByFilterCompleted = (data) => ({
   type: types.FETCH_ORDER_REQUESTS_BY_FILTER_COMPLETED,
   payload: data,
})

const fetchOrderRequestsByFilterFail = () => ({
   type: types.FETCH_ORDER_REQUESTS_BY_FILTER_FAIL,
})

const fetchNewOrderRequestsStart = () => ({
   type: types.FETCH_NEW_ORDER_REQUESTS_START,
})

const fetchActiveOrderRequestStart = () => ({
   type: types.FETCH_ORDER_REQUEST_BY_ID_START,
})

const fetchActiveOrderRequestCompleted = (data) => ({
   type: types.FETCH_ORDER_REQUEST_BY_ID_COMPLETED,
   payload: data,
})

const fetchActiveOrderRequestFail = () => ({
   type: types.FETCH_ORDER_REQUEST_BY_ID_FAIL,
})

const updateActiveOrderRequest = (payload) => ({
   type: types.UPDATE_ACTIVE_ORDER_REQUEST,
   payload,
})

const updateOrderRequestsData = (payload) => ({
   type: types.UPDATE_ORDER_REQUESTS_DATA,
   payload,
})

const updateOrderRequestsListData = (payload) => ({
   type: types.UPDATE_ORDER_REQUESTS_LIST_DATA,
   payload,
})

const fetchNewOrderRequestsCompleted = (data) => ({
   type: types.FETCH_NEW_ORDER_REQUESTS_COMPLETED,
   payload: data,
})

const fetchNewOrderRequestsFail = () => ({
   type: types.FETCH_NEW_ORDER_REQUESTS_FAIL,
})

const fetchOrderRequestForms = (data) => ({
   type: types.FETCH_ORDER_REQUEST_FORMS,
   payload: data,
})

const fetchNewOrderRequestFormsStart = () => ({
   type: types.FETCH_NEW_ORDER_REQUEST_FORMS_START,
})

const fetchNewOrderRequestFormsCompleted = (data) => ({
   type: types.FETCH_NEW_ORDER_REQUEST_FORMS_COMPLETED,
   payload: data,
})

const fetchNewOrderRequestFormsFail = () => ({
   type: types.FETCH_NEW_ORDER_REQUEST_FORMS_FAIL,
})

const deleteForm = (id) => ({
   type: types.DELETE_ORDER_REQUEST_FORM,
   payload: id,
})

//order requests end

//content bundle

const initResourcesBundleStart = () => ({
   type: types.FETCH_RESOURCES_FOR_NEW_BUNDLE,
})

const initResourcesBundleCompleted = (data) => ({
   type: types.FETCH_RESOURCES_FOR_BUNDLE_COMPLETED,
   payload: data,
})

const changeNewBundleData = (data) => ({
   type: types.CHANGE_NEW_BUNDLE_DATA,
   payload: data,
})

const changeNewBundleErrors = (errors) => ({
   type: types.CHANGE_NEW_BUNDLE_ERRORS,
   payload: errors,
})

const clearNewBundleData = () => ({
   type: types.CLEAR_NEW_BUNDLE_DATA,
})

const changeAccessData = (data) => ({
   type: types.CHANGE_ACCESS_DATA,
   payload: data,
})

const setImageLoader = (bool) => ({
   type: types.SET_IMAGE_LOADER,
   payload: bool,
})

// edit bundle

const setActiveBundleErrors = (errors) => ({
   type: types.SET_ACTIVE_BUNDLE_ERRORS,
   payload: errors,
})

const clearActiveBundleData = () => ({
   type: types.CLEAR_ACTIVE_BUNDLE_DATA,
})

const changeActiveBundleAccesses = (accesses) => ({
   type: types.CHANGE_ACTIVE_BUNDLE_ACCESSES,
   payload: accesses,
})

const changeActiveBundleData = (data) => ({
   type: types.CHANGE_ACTIVE_BUNDLE_DATA,
   payload: data,
})

const pinOrUnpinBundleItems = (data) => ({
   type: types.PIN_OR_UNPIN_BUNDLE_ITEMS,
   payload: data,
})

const deleteStoreBundleItem = () => ({
   type: types.DELETE_STORE_BUNDLE_ITEM,
})

const reorderStore = (data) => ({
   type: types.REORDER_STORE,
   payload: data,
})

const changeInitedState = (payload) => ({
   type: types.CHANGE_INITED_STATE,
   payload,
})

export {
   fetchDetails,
   fetchDetailsFailed,
   fetchDetailsCompleted,

   // next page data
   fetchNewStore,
   fetchNewStoreCompleted,
   fetchNewStoreFailed,

   // by filter
   fetchFilterStore,
   fetchFilterStoreCompleted,
   fetchFilterStoreFailed,
   fetchDataByFilters,
   afterFilterCompleted,

   // get by id
   fetchStoreById,
   fetchStoreByIdCompleted,
   fetchStoreByIdFailed,

   // update
   updateStoreList,
   updateStoreFailed,
   changeStoreEditData,

   // create store
   createNewStore,
   createNewStoreCompleted,
   createNewStoreFailed,
   saveNewStoreDataCompleted,

   deleteStoreCompleted,

   fetchResources,
   fetchResourcesCompleted,
   fetchResourcesFailed,

   // get collection By ID
   fetchCollectionById,
   fetchCollectionByIdCompleted,
   fetchCollectionByIdFailed,

   //update category
   updateCategoryData,
   updateCategoryDataCompleted,
   updateCategoryDataFailed,

   // create new category
   createNewCategory,
   createNewCategoryCompleted,
   createNewCategoryFailed,

   // dlete category
   deleteCategory,
   deleteCategoryCompleted,
   deleteCategoryFailed,

   setStoreImg,
   setDeleteModal,
   setNewItemButtonnDisabled,

   openModalForShippingZone,

   fetchAllCategories,
   changeShippingZones,
   addOrRemoveImage,
   changeStoreDetailsErrors,

   getStoreValidationNubers,
   saveStoreScrollPositionAndFilters,

   // content bundle
   changeNewBundleData,
   initResourcesBundleCompleted,
   initResourcesBundleStart,
   changeNewBundleErrors,
   clearNewBundleData,
   changeAccessData,
   setImageLoader,
   setActiveBundleErrors,
   clearActiveBundleData,
   changeActiveBundleAccesses,
   changeActiveBundleData,
   pinOrUnpinBundleItems,
   deleteStoreBundleItem,

   //order requests
   fetchOrderRequestsStart,
   fetchOrderRequestsCompleted,
   fetchOrderRequestsByFilterStart,
   fetchOrderRequestsByFilterCompleted,
   fetchOrderRequestsByFilterFail,
   fetchNewOrderRequestsStart,
   fetchNewOrderRequestsCompleted,
   fetchNewOrderRequestsFail,
   fetchOrderRequestForms,
   reorderStore,
   changeInitedState,
   fetchNewOrderRequestFormsStart,
   fetchNewOrderRequestFormsCompleted,
   fetchNewOrderRequestFormsFail,
   deleteForm,

   changeLoadingState,

   fetchActiveOrderRequestStart,
   fetchActiveOrderRequestCompleted,
   fetchActiveOrderRequestFail,
   updateActiveOrderRequest,
   updateOrderRequestsData,
   updateOrderRequestsListData,
};
