import Intercom from 'common/utils/Intercom';
import { isLocalhost } from 'admin/utils/Helpers';
import { getProtocol, getSiteDomain } from 'common/utils/utils';
import { getSiteNameAbbreviatur } from 'common/utils/helper';

const setCrossSubdomainCookie = (name, value, days) => {
   const assign = name + "=" + escape(value) + ";";
   const d = new Date();
   d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
   const expires = "expires=" + d.toUTCString() + ";";
   const path = "path=/;";
   const regExpValue = document.domain.match(/[^\\.]*\.[^.]*$/);
   if(regExpValue) {
      const domain = "domain=" + (regExpValue[0]) + ";";
      document.cookie = assign + expires + path + domain;
   }
}

export default  {
   setToken: (token) => {
      return localStorage.setItem('authToken', token);
   },
   getToken: () => {
      return localStorage.getItem('authToken');
   },
   isTokenExists: () => {
      return !!localStorage.getItem('authToken');
   },
   logout: () => {
      const chatSocket = window.adminChatSocket
      if(chatSocket && chatSocket.connected) {
         chatSocket.disconnect()
         window.adminChatSocket = null
      }
      window.document.title = getSiteNameAbbreviatur();
      Intercom.shutDown();
      Intercom.boot();
      setCrossSubdomainCookie('amsAdminLogout', '1', 1000);
      localStorage.removeItem('superAdminSession');
      window.commentsSocket = null;
      window.uploadsSocket = null;
      window.memberAreaSocket = null;
      return localStorage.removeItem('authToken');
   },
   redirectSignIn: (params = '', callBack, page = '') => {
      if(callBack && !!isLocalhost()){
         callBack()
      } else {
         let lendingUrl = `${ getProtocol() }/${ getSiteDomain() }`;
         if(page) lendingUrl = `${ lendingUrl }/${ page }`
         if(params){
            params = params.replace('?', '');
            lendingUrl = `${ lendingUrl }?${ params }`;
         }
         window.location.replace(lendingUrl)
      }
   },
}
