import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/modules/modal';
import Button from 'common/components/elements/buttons/primary';


const WrongDownloadModal = ({
   header,
   body,
   closeModal,
   isCloseAction,
}) => {
   return (
      <Modal
         onClose={ () => {
            closeModal({
               isOpen: false,
               body: null,
               header: null,
               is_limited: false,
            })
         } }
         contentPosition='center'
         isCloseAction={ isCloseAction }
      >
         <div className='flex flex-col text-center p-5'>
            <span className='text-major font-medium text-xl'>{header}</span>
            <span className='leading-tight text-secondary mt-3'>{body}</span>
            <div className='h-9 mt-4'>
               <Button
                  text='Ok'
                  backgroundColor='transparent'
                  primaryColor
                  classNames='h-full'
                  padding='none'
                  onClick={  () => {
                     closeModal({
                        isOpen: false,
                        body: null,
                        header: null,
                     })
                  } }
               />
            </div>
         </div>
      </Modal>
   )
}

WrongDownloadModal.propTypes = {
   header: PropTypes.string,
   body: PropTypes.string,
   closeModal: PropTypes.func,
   isCloseAction: PropTypes.bool,
}

export default WrongDownloadModal
