import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_RESOURCES_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },

   [types.FETCH_RESOURCES_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetching: false,
         resources: payload,
      }
   },

   [types.FETCH_RESOURCES_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },
   [types.DEFAULT_ACCESSES_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingDefaultAccess: true,
      }
   },

   [types.DEFAULT_ACCESSES_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetchingDefaultAccess: false,
         defaultAccesses: payload,
      }
   },

   [types.DEFAULT_ACCESSES_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingDefaultAccess: false,
      }
   },

   [types.SET_DOWNLOAD_BUTTON_LOADING]: (state, action) => {
      return {
         ...state,
         mediaDownloadButton: action.payload,
      }
   },
   [types.SET_WRONG_DOWNLOAD_MODAL]: (state, action) => {
      return {
         ...state,
         wrongDownloadModal: action.payload,
      }
   },
   [types.SET_COUNTS_STATE]: (state, action) => {
      const {
         data, type,
      } = action.payload;
      let newState = { ...action.countesState };
      if(type){
         newState = {
            ...newState,
            [type]: {
               ...newState[types],
               ...data,
            },
         }
      } else {
         newState = {
            ...newState,
            ...data,
         }
      }
      return {
         ...state,
         countesState: newState,
      }
   },

   [types.CREATE_NEW_RESOURCE_TAG]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         resources: {
            ...state?.resources,
            tags: [
               ...state?.resources?.tags,
               payload,
            ],
         },

      }
   },
};

export default createReducer(initialState)(reducersMap);
