import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import CreateEditModal from 'common/components/modules/modal-wrapper';

import { Tooltip } from 'react-tooltip';
import Radio from 'common/components/elements/inputs/radio-label';
import TruncateText from 'common/components/elements/truncate-text';

const MicSettingsModal = ({
   isMobile,
   devices,
   onSaveDevice,
   activeDeviceId,
   onCloseModal,
   activeDeviceLable,
}) => {
   const [openChooseModal, setOpenChooseModal] = useState(false)
   const [activeDevice, setActiveDevice] = useState({ id: activeDeviceId, label: activeDeviceLable })

   let contentRef = createRef();

   useEffect(() => {
      const handleClickOutside = (event) => {
         if(contentRef.current && !contentRef.current.contains(event.target)) {
            if(openChooseModal) {
               setOpenChooseModal(false)
            }
         }

         if(event.target.closest(`#radio-label_${ activeDevice?.id }_filter`)) {
            setOpenChooseModal(false)
         }
      }
      if(!isMobile){
         document.addEventListener('mousedown', handleClickOutside);
      }
      return () => {
         if(!isMobile){
            document.removeEventListener('mousedown', handleClickOutside);
         }
      }
   }, [contentRef, isMobile, openChooseModal, setOpenChooseModal, activeDevice]);

   const onDeviceChange = (id, label) => {
      setActiveDevice({ id, label })
      setOpenChooseModal(false)
   }

   const view = (
      <div className='flex flex-col py-1'>
         {
            devices && devices.map((device, index) => {
               return (
                  <Radio
                     key={  index.toString() }
                     id={ `${ device.deviceId }_filter` }
                     onChange={ () => onDeviceChange(device?.deviceId, device.label) }
                     checked={ activeDevice.id === device?.deviceId  }
                     className='text-major py-[10px] px-4 hover:bg-hover dark:hover:bg-hover-dark'
                  >
                     <TruncateText
                        text={ device.label }
                        textClass='text-major dark:text-major-dark leading-[18px]'
                        className='w-full'
                        width='calc(100% - 26px)'
                        tooltipId='ams-bottom-tooltip'
                     />
                  </Radio>
               )
            })
         }
      </div>
   )

   return (
      <CreateEditModal
         type='action'
         icon='mic'
         title='Microphone'
         iconColor='action'
         buttonText='Ok'
         descriptionColorClass='text-secondary dark:text-secondary-dark'
         action={ () => onSaveDevice(activeDevice) }
         onCloseModal={ onCloseModal }
         contentWidth='375px'
         // textColor='[#FF3636]'
         isMobile={ isMobile }
         buttonBackgroundColor='transparent'
         // buttonClassName='mt-8 text-[#FF3636]'
      >
         <div
            role='presentation'
            onClick={ (ev) => {
               ev.stopPropagation()
               if(ev.target.closest(`#radio-label_${ activeDevice?.id }_filter`)) {
                  setOpenChooseModal(false)
               } else {
                  setOpenChooseModal(!openChooseModal)
               }
            } }
            ref={ contentRef }
            data-tooltip-id={ isMobile ? null : 'chat_mic_settings_select' }
            className='flex h-10 justify-between gap-[6px] rounded-lg py-[10px] px-[14px] bg-panel dark:bg-panel-dark border dark:border-divider-dark border-divider hover:bg-hover dark:hover:bg-hover-dark cursor-pointer select-none'>
            <TruncateText
               text={ activeDevice.label }
               textClass='text-major dark:text-major-dark leading-[20px] text-base'
               className='w-full'
               width='calc(100% - 26px)'
               tooltipId='ams-top-tooltip'
            />
            <div className='flex items-center justify-center h-5 w-5'>
               <Icon
                  name={ openChooseModal ? 'arrow-up' : 'arrow-down' }
                  color='secondary'
                  darkColor='secondary-dark'
               />

            </div>
            {
               !isMobile && (
                  <Tooltip
                     openOnClick
                     variant={ window.site_dark_bg ? 'dark' : 'light' }
                     id='chat_mic_settings_select'
                     place='bottom'
                     clickable
                     isOpen={ openChooseModal }
                     noArrow
                     style={ {
                        padding: 0,
                        borderRadius: '8px',
                        maxWidth: '335px',
                        width: '335px',
                     } }
                     className='shadow dark:shadow-dark border dark:border-divider-dark border-divider min-w-[335px]'
                     offset={ 4 }
                  >
                     {view}
                  </Tooltip>
               )
            }
         </div>
      </CreateEditModal>
   )
};


MicSettingsModal.propTypes = {
   isMobile: PropTypes.bool,
   devices: PropTypes.array,
   onSaveDevice: PropTypes.func,
   activeDeviceId: PropTypes.string,
   onCloseModal: PropTypes.func,
   activeDeviceLable: PropTypes.string,
};
MicSettingsModal.defaultProps = {
   className: '',
}

export default MicSettingsModal
