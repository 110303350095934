import React, { Fragment } from 'react';
import './style.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';

const ChatListLoading = ({
   count = 1,
   type = 'chat_item',
}) => {
   var items = [];
   for(var i = 0; i < count; i++) {
      items.push(<ChatListLoadingItem type={ type } />);
   }
   return (
      items.map((item, index) => {
         return (
            <Fragment key={ index.toString() }>
               {item}
            </Fragment>
         )
      })
   );
};

const ChatListLoadingItem = ({
   type,
}) => {
   return (
      <div
         className={ cx({
            'flex': true,
            'cursor-pointer min-chat-item-h py-3 justify-between px-6': 'chat_item' === type,
            'items-center px-4 py-1': 'online_member' === type,
         }) }
         role='presentation'
      >
         <div className='flex flex-1 mr-4'>
            <div
               className={ cx({
                  'w-10 h-10 rounded-full loading-bg bg-placeholder': true,
                  // 'mb-8': 'chat_item' === type,
               }) }
               style={ { minWidth: '40px' } }
            />
            {
               'chat_item' === type && (
                  <div className='flex flex-col align-center ml-3 w-full'>

                     <div className='h-6 loading-bg bg-placeholder rounded-full' />
                     <div className='mt-1 h-4 loading-bg bg-placeholder rounded-full' />
                     {/* <div className='mt-1 h-1 loading-bg bg-placeholder rounded-full' /> */}
                  </div>
               )
            }
            {
               'online_member' === type && (
                  <div className='h-5 loading-bg bg-placeholder rounded-full ml-4 w-full my-auto' />
               )
            }
         </div>
      </div>
   )
}
ChatListLoadingItem.propTypes = {
   type: PropTypes.string,
}
export default ChatListLoading;
