import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';

const reducersMap = {
   // PASSWORD RESET LINK /
   [types.PASSWORD_RESET_LINK_REQUEST]: (state, action) => {
      return {
         ...state,
         isInProgress: true,
         errors: {},
      }
   },
   [types.PASSWORD_RESET_LINK_COMPLETED]: (state, action) => {
      return {
         ...state,
         isInProgress: false,
      }
   },
   [types.PASSWORD_RESET_LINK_FAILED]: (state, action) => {
      const { payload: { errors } } = action;
      return {
         ...state,
         isInProgress: false,
         errors,
      }
   },

   // RESET PASSWORD /
   [types.RESET_PASSWORD_REQUEST]: (state) => {
      return {
         ...state,
         isInProgress: true,
         errors: {},
      }
   },
   [types.RESET_PASSWORD_COMPLETED]: (state) => {
      return {
         ...state,
         isInProgress: false,
      }
   },
   [types.RESET_PASSWORD_FAILED]: (state, action) => {
      const { payload: { errors } } = action;
      return {
         ...state,
         isInProgress: false,
         errors,
      }
   },
};

export default createReducer(initialState)(reducersMap);
