import React from 'react'
import PropTypes from 'prop-types'
import ChatUserAvatar from 'common/components/modules/chat/chat-user-avatar';
import cx from 'classnames';
import Icon from 'common/components/elements/icons';

const ChatAvatarsBar = ({ showMiniChat, list, isOnlineStream }) => {
   return (
      <div
         className={ cx({
            'flex flex-col': true,
            'justify-end p-2': isOnlineStream,
            '': !isOnlineStream,
         }) }
         style={ {
            pointerEvents: 'all',
         } }
      >
         {
            list.map((item, index) => {
               const avatarSrc = item.user_avatar ? item.user_avatar : item.avatars
               const chatCount = item.unread_messages_count  || 0
               return (

                  <div
                     key={ item.chat_room_id + index.toString() }
                     role='presentation'
                     onClick={ () => showMiniChat(item) }
                     className='flex justify-center items-center w-12 h-12 rounded-full mt-3 bg-center bg-contain relative cursor-pointer'
                  >
                     <div
                        data-tooltip-content={  item.member_nickname || item.username  }
                        data-tooltip-id={  (item.member_nickname || item.username)  ? 'ams-left-tooltip' : null }
                     >
                        {
                           item.chat_room_id === 'live_stream' && (
                              <div
                                 style={ {
                                    backgroundColor: '#231FFF',
                                 } }
                                 className='flex items-center justify-center w-10 h-10 min-w-10 rounded-full'>
                                 <Icon name='chat' color='major-dark' size='xl' />
                              </div>
                           )
                        }
                        {
                           item.chat_room_id !== 'live_stream' && (
                              <ChatUserAvatar
                                 avatarSrc={ avatarSrc }
                                 size='12'
                                 groupSize='40.62px'
                                 position='3.69'
                                 username={ item.member_nickname || item.username || null }
                                 firstLetterClassName='!text-2xl'
                              />

                           )
                        }
                     </div>
                     { chatCount !== 0 &&  (
                        <div
                           className='bg-error dark:bg-error-dark h-5 w-5 flex items-center justify-center rounded-full absolute z-20'
                           style={ { top: '0px', left: '30px' } }
                        >
                           <span
                              style={
                                 chatCount > 100 ? { fontSize: '10px' } : {}
                              }
                              className='text-xs text-major-dark font-medium'>{ chatCount > 100 ? '99+' : chatCount}</span>
                        </div>
                     )}
                     { item.is_online &&  (
                        <span
                           style={ {
                              height: '10px',
                              width: '10px',
                              bottom: '2px',
                              right: '2px',
                           } }
                           className='rounded-full bg-success dark:bg-success-dark block ml-2 mt-1 absolute border border-white'></span>
                     )}
                  </div>
               ) }
            )
         }
      </div>
   )
}

ChatAvatarsBar.propTypes = {
   showMiniChat: PropTypes.func,
   isOnlineStream: PropTypes.bool,
   list: PropTypes.any,
}
ChatAvatarsBar.defaultProps = {
   list: [],
}

export default ChatAvatarsBar

