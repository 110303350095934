
const checkDeviceSupport = async (callback) => {
   let constraints = { video: true };
   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
   if(isSafari) {
      navigator.mediaDevices.getUserMedia(constraints)
         .then(stream => {
            window.localStream = stream;
            if(callback){
               callback(true, isSafari);
            }
            return
         })
         .catch(err => {
            if(callback){
               callback(true, isSafari, err.name);
            }
            return
         })
   } else {
      navigator.mediaDevices.enumerateDevices()
         .then(devices => {
            if(devices) {
               let videoDevices = devices.filter(item => item.kind.includes('video'));
               if(videoDevices && !!videoDevices.length) {
                  if(callback){
                     callback(true, isSafari);
                  }
               } else {
                  if(callback){
                     callback(false, isSafari);
                  }
                  return;
               }
            }
         })
   }
}

export {
   checkDeviceSupport,
}
