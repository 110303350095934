import { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import Button from "common/components/elements/buttons/primary"
import IconLabel from "common/components/elements/icon-label"
import Input from "common/components/elements/inputs/inputs"
import Modal from "common/components/modules/modal"
import ConfirmDialog from "common/components/modules/confirm-dialog"
import EmojiContent from "common/components/modules/emoji-content"
import { addEmojiOnInput, getCurrentPosition, switchShowEmojis } from "common/utils/utils"
import toast from "common/utils/toast"

const AddEditNicknameModal = ({
   nickname,
   username,
   // isMobile,
   errorMessage,
   onSaveNickname,
   closeModal,
   maxLimit,
   onChangeSuccess,
}) => {
   const [name, setName] = useState(nickname)
   const [loading, setLoading] = useState(false)
   const [animate, setAnimate] = useState('up')
   const [hideText, setHideText] = useState(!!errorMessage)
   const [showDiscardModal, setShowDiscardModal] = useState(false)
   // const [allowToClose, setAllowToClose] = useState(true)
   let timeout = null

   let inputRef = useRef(null);
   const [cursorPosition, setCursorPosition] = useState(null);


   useEffect(() => {
      setName(nickname)
   }, [nickname]);

   useEffect(() => {

      if(inputRef) {
         inputRef?.focus()
      }

      return () => {
         clearTimeout(timeout)
         // setAllowToClose(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onClose = () => {
      // if(nickname !== name?.trim()) {
      //    // setAllowToClose(false)
      //    // setShowDiscardModal(false)
      //    return
      // }
      if(window?.innerWidth < 1025) {
         setAnimate('down')
         timeout = setTimeout(() => {
            setAnimate('up')
            // allowToClose &&
            closeModal()
         }, 800)
      } else {
         // allowToClose &&
         closeModal()
      }
   }

   function closeOnClickOutside(e) {
      if(e.target.closest('.update-member')) {
         if(!hideText) {
            setHideText(false)
         }
      }
   }

   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };
      // eslint-disable-next-line
   }, [hideText]);

   return (
      (
         <Modal
            contentPosition={ window?.innerWidth < 1025 ? 'bottom' : 'center' }
            onClose={ onClose }
            contentWidth={ window?.innerWidth < 1025 ? '100%' : '375px' }
            animate={ window?.innerWidth < 1025 ? animate : null }
            closeOnClickOutside={ false }
            otherClassName={ `ams-custom-scrool` }
         >

            {
               !showDiscardModal ? (
                  <div className='p-6'>
                     {/* <div className='flex h-14 items-center px-4'>
                        <div className='w-6 h-6 flex items-center justify-cente mr-4'>
                           <Button
                              iconName='close'
                              fontIconColor='secondary'
                              darkIconColor='secondary-dark'
                              backgroundColor='transparent'
                              onClick={ () => onClose() }
                           />
                        </div>
                     </div> */}
                     <div className='flex items-center justify-center' >
                        <IconLabel
                           className='w-10 h-10'
                           name='pencil-new'
                           size='2xl'
                           color='action'
                        />
                     </div>
                     <span className='leading-6 mt-3 flex items-center text-xl justify-center text-major dark:text-major-dark text-center font-semibold'>Give {username} a nickname</span>
                     <span className='leading-5 mt-4 flex items-center text-base justify-center text-secondary dark:text-secondary-dark text-center font-normal'>Nicknames can be edited/undone at anytime, and are visible only to you. The member will not see the nickname.</span>

                     <div className='flex mt-4 relative'>
                        <Input
                           // label='Nickname'
                           name='nickname'
                           value={ name }
                           onChange={ (name, value) => {
                              if(maxLimit && maxLimit < value?.length) {
                                 toast.error(`${ maxLimit } character limit has been reached`,  { toastId: 'nickname_limit_reached' })
                                 return
                              }
                              setName(value)
                           } }
                           errorMessage={ errorMessage }
                           placeholder='Enter nickname'
                           autoComplete='off'
                           description={ maxLimit ? `${ name?.length || 0 } of ${ maxLimit } characters` : '' }
                           descriptionPosition='right'
                           descriptionFontWeight={ 'normal' }
                           labelFontWeight={ 'medium' }
                           labelFontSize='sm'
                           height={ '10' }
                           hideDescriptionWhenError={ hideText }
                           onInputFocus={ () => {
                              if(!!errorMessage) {
                                 setHideText(false)
                              }
                           } }
                           onInputBlur={ () => {
                              const positionCursor = getCurrentPosition(inputRef);
                              setCursorPosition(positionCursor);
                           } }
                           inputRef={  (ref) => inputRef = ref }
                           errorMessageClassName={ `!leading-[18px] ` }
                           descriptionMarginBottom={ '0' }
                           inputClassName={ `!pr-[36px]` }
                        />
                        {
                           !hideText &&
                           <EmojiContent
                              switchShowEmojis={ () => switchShowEmojis(inputRef, cursorPosition) }
                              addEmoji={ (e) => addEmojiOnInput(e, inputRef) }
                              isMobile={ window?.innerWidth < 1025 }
                              style={ {
                                 width: '36px',
                                 height: '36px',
                                 top: '-3px',
                                 marginTop: '6px',
                                 right: '-16px',
                              } }
                              className='absolute mr-4'
                              xPositions={ {
                                 right: '-10px',
                              } }
                              top='-110px'
                              id='nickname_emoji_icon'
                              iconColor='secondary'
                              size='h-7 w-7 my-[10px] ml-1 mr-[14px]'
                              closeOnEmojiSelect
                              primaryIconSize='20px'
                           />
                        }
                     </div>
                     <div className='h-full w-auto px-5 flex items-center justify-center mt-8 flex-col gap-3'>
                        <Button
                           text='Save'
                           borderRadius='large'
                           onClick={ () => {
                              setLoading(true)
                              onSaveNickname(
                                 { nickname: String(name).trim() },
                                 (status) => {
                                    if(status === 'success') {
                                       setLoading(false)
                                       closeModal()
                                       onChangeSuccess({ nickname: String(name).trim() })
                                       toast.success(`Nickname has been ${ !nickname ? `added` : `updated` }`)
                                    }
                                 },
                                 true
                              )
                              if(!!errorMessage || !hideText) {
                                 setHideText(true)
                              }
                           }
                           }
                           disabled={
                              name?.length === 0
                               || (nickname === name?.trim())
                               || (name?.trim()?.length === 0)
                           }
                           primaryColor
                           isLoading={ !errorMessage && loading }
                           classNames='!w-[fit-content]'
                           lineHeight='leading-5'
                           padding={ 2 }
                           textSize='small'
                           backgroundColor='transparent'
                           textColor='action'
                        />
                        {
                           nickname &&
                           <Button
                              text='Delete nickname'
                              borderRadius='large'
                              onClick={ () => {
                                 // onSaveNickname(
                                 //    { nickname: null },
                                 //    (status) => {
                                 //       if(status === 'success') {
                                 //          setLoading(false)
                                 //          closeModal()
                                 //          onChangeSuccess({ nickname: null })
                                 //          toast.success(`Nickname has been ${ `deleted` }`)
                                 //       }
                                 //    },
                                 //    true
                                 // )
                                 setShowDiscardModal(true)
                              }  }
                              // isLoading={ loading }
                              classNames='!w-[fit-content]'
                              lineHeight='leading-5'
                              padding={ 2 }
                              textSize='small'
                              backgroundColor='transparent'
                              textColor='error'
                              disabled={ !nickname }
                           />
                        }
                        <Button
                           text='Cancel'
                           borderRadius='large'
                           onClick={ () => {
                              closeModal()
                           }
                           }
                           // isLoading={ loading }
                           classNames='!w-[fit-content]'
                           lineHeight='leading-5'
                           padding={ 2 }
                           textSize='small'
                           backgroundColor='transparent'
                           textColor='secondary'
                        />
                     </div>
                  </div>
               ) : (
                  <ConfirmDialog
                     modalInfo={ {
                        title: `Delete nickname?`,
                        actionText: 'Yes, delete',
                        description: 'Are you sure you want to delete this nickname? This action cannot be undone.',
                        action: () => {
                           // closeModal()
                           onSaveNickname(
                              { nickname: '' },
                              (status) => {
                                 if(status === 'success') {
                                    setLoading(false)
                                    // closeModal()
                                    onClose()
                                    onChangeSuccess({ nickname: '' })
                                    toast.success(`Nickname has been ${ `deleted` }`)
                                 }
                              },
                              true
                           )
                        },
                     } }
                     closeConfirmDialog={ () => {
                        setShowDiscardModal(false)
                     }  }
                     cancelText='Cancel'
                     iconName='cancel'
                     showNewStyles
                     descriptionClassName={ `!mx-0` }
                  />
               )
            }
         </Modal>
      )
   )
}

AddEditNicknameModal.defaultProps = {
   maxLimit: 30,
};

AddEditNicknameModal.propTypes = {
   nickname: PropTypes.string,
   username: PropTypes.string,
   // isMobile: PropTypes.bool,
   errorMessage: PropTypes.string,
   onSaveNickname: PropTypes.func,
   closeModal: PropTypes.func,
   maxLimit: PropTypes.number,
   onChangeSuccess: PropTypes.func,
}

export default AddEditNicknameModal


