import React from 'react';
import PropTypes from 'react';
import { getSiteUrl } from 'sites/utils/Helpers';


const PrivacyPolicy = ({
   legalPages,
}) => {

   let currentInfo

   if(!!legalPages && !!legalPages?.length) {
      currentInfo = legalPages?.find(it => it.name === 'privacy_policy')
   }

   return (
      currentInfo?.html_content ?
         <>
            <div dangerouslySetInnerHTML={ {
               __html: currentInfo?.html_content,
            } } className='text-major dark:text-major-dark policy-list-dist ql-editor'
            />
         </>
         :
         <>
            <span className='text-2xl text-major dark:text-major-dark mb-3 mt-6'>
               <span className='text-2xl text-major dark:text-major-dark mb-3 mt-6'>Personal Data We Collect and How It Is Used
                  <br />
                  <br />
               </span>
            </span>
            <div className='dark:text-secondary-dark text-secondary'>
               We are committed to safeguarding your personal data. Below is a detailed list of the types of personal data we collect, how it is used, and the circumstances under which it may be shared:
               <div style={ { marginTop: '20px' } }>
                  <span style={ { fontWeight: 700 } }>1. Email Address</span>
                  <ul style={ { paddingLeft: '30px', listStyle: 'disc', marginTop: '12px' } }>
                     <li style={ { margin: '4px 0' } }><span style={ { fontWeight: 700 } }>Purpose:</span> Used to maintain and manage your account, communicate important updates, facilitate
                        purchases, and provide support when needed
                     </li>
                     <li style={ { margin: '4px 0' } }>
                        <span style={ { fontWeight: 700 } }>Sharing:</span>
                        <ul style={ { paddingLeft: '30px', listStyle: 'revert' } }>
                           <li style={ { margin: '4px 0' } }>
                              Shared with payment processors, but only when a purchase is made.
                           </li>
                           <li style={ { margin: '4px 0' } }>
                              Shared with Intercom, but only if you contact us for support. For more information, please refer to&nbsp;
                              <a href='https://www.intercom.com/legal/privacy'>Intercom's Privacy Policy.</a>
                           </li>
                        </ul>
                     </li>
                  </ul>
               </div>

               <div style={ { marginTop: '20px' } }>
                  <span style={ { fontWeight: 700 } }>2. IP Address</span>
                  <ul style={ { paddingLeft: '30px', listStyle: 'disc', marginTop: '12px' } }>
                     <li style={ { margin: '4px 0' } }><span style={ { fontWeight: 700 } }>Purpose:</span> Collected for security purposes, troubleshooting technical issues, analyzing website performance, and providing support when needed.</li>
                     <li style={ { margin: '4px 0' } }>
                        <span style={ { fontWeight: 700 } }>Sharing:</span>
                        <ul style={ { paddingLeft: '30px', listStyle: 'revert' } }>
                           <li style={ { margin: '4px 0' } }>
                              Shared with Google Analytics to help us understand website usage patterns.
                           </li>
                           <li style={ { margin: '4px 0' } }>
                              Shared with Intercom, but only if you contact us for support. For more information, please refer to &nbsp;
                              <a href='https://www.intercom.com/legal/privacy'>Intercom's Privacy Policy.</a>
                           </li>
                        </ul>
                     </li>
                  </ul>
               </div>
               <div style={ { margin: '20px 0' } }>
                  <span style={ { fontWeight: 700 } }>3. Age Verification (if applicable)</span>
                  <ul style={ { paddingLeft: '30px', listStyle: 'disc', marginTop: '12px' } }>
                     <li style={ { margin: '4px 0' } }>
                        <span style={ { fontWeight: 700 } }>Purpose:</span> If required, we may request documents to verify your age in compliance with applicable laws or regulations.
                     </li>
                     <li style={ { margin: '4px 0' } }>
                        <span style={ { fontWeight: 700 } }>Sharing:</span> These documents are   <span style={ { fontWeight: 700 } }>not shared with any third parties</span> and are securely destroyed immediately after the verification process is completed.
                     </li>
                  </ul>
               </div>
               We ensure that all data shared with third parties is used only for the specified purposes and handled in accordance with applicable privacy laws.
            </div>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>Cookies</p>
            <p className='dark:text-secondary-dark '>
               A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. {getSiteUrl()} uses cookies to help identify and track visitors, and usage patterns. If you do not wish to have cookies placed on your computer by {getSiteUrl()}, you must set your browser to refuse cookies, with the drawback that certain features may not function properly.  Click here to see our <a href='/?information-and-policies=cookie-policy'>Cookie Policy</a>.
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>Business Transfers</p>
            <p className='dark:text-secondary-dark '>
               If {getSiteUrl()}, or substantially all of its assets, was acquired, or in the unlikely event that {getSiteUrl()} went out of business or entered bankruptcy, user information would be one of the assets that would be transferred or acquired by the third party. You acknowledge that such transfers may occur, and that any acquirer may continue to use your personal information as set forth in this policy.
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>Privacy Policy Changes</p>
            <p className='dark:text-secondary-dark '>
               Although most changes are likely to be minor, {getSiteUrl()} may change its Privacy Policy from time to time at its sole discretion.
            </p>
         </>
   )
};

PrivacyPolicy.propTypes = {
   legalPages: PropTypes.array,
}


export default PrivacyPolicy;
