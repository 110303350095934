import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {

   [types.FETCH_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },

   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetching: false,
         data: payload,
         isEmpty: payload.length === 0,
      }
   },

   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.DELETE_COLLECTIONS_START]: (state) => {
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            buttonDisabled: true,
         },
      }
   },

   [types.DELETE_COLLECTIONS_COMPLETED]: (state, action) => {
      const { payload } = action;
      const newData = [...state.data].filter(i => i.id !== payload)
      return {
         ...state,
         data: newData,
         modalSettings: {
            ...state.modalSettings,
            isOpen: false,
            title: '',
            activeId: null,
            buttonDisabled: false,
            error: null,
         },
         isEmpty: newData.length === 0,
      }
   },

   [types.DELETE_COLLECTIONS_FAILED]: (state, action) => {
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            buttonDisabled: false,
         },
      }
   },

   [types.UPDATE_COLLECTIONS_START]: (state) => {
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            buttonDisabled: true,
            error: null,
         },
      }
   },

   [types.UPDATE_COLLECTIONS_COMPLETED]: (state, action) => {
      const { payload } = action;
      const newData = [...state.data].map(i => {
         const { ...elm } = i
         if(elm.id === payload.id) {
            elm.title = payload.title
         }
         return elm
      })
      return {
         ...state,
         data: newData,
         modalSettings: {
            ...state.modalSettings,
            isOpen: false,
            title: '',
            activeId: null,
            buttonDisabled: false,
            error: null,
         },
      }
   },

   [types.CREATE_NEW_COLLECTIONS_START]: (state) => {
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            error: null,
         },
      }
   },

   [types.CREATE_NEW_COLLECTIONS_COMPLETED]: (state, action) => {
      const { payload } = action;
      let newData = [...state.data];
      if(!state.data.find(item => item.id === payload.id)) {
         newData = [{ ...payload }, ...state.data]
      }
      return {
         ...state,
         data: newData,
         isEmpty: newData.length === 0,
         modalSettings: {
            ...state.modalSettings,
            isOpen: false,
            title: '',
            activeId: null,
            buttonDisabled: false,
            error: null,
         },
      }
   },

   [types.CREATE_NEW_COLLECTIONS_FAILED]: (state, action) => {
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            buttonDisabled: false,
            error: action.payload,
         },
      }
   },
   [types.UPDATE_COLLECTIONS_FAILED]: (state, action) => {
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            buttonDisabled: false,
            error: action.payload,
         },
      }
   },

   [types.COLLECTIONS_REORDER_START]: (state) => {
      return {
         ...state,
      }
   },

   [types.COLLECTIONS_REORDER_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         data: payload,
      }
   },

   [types.COLLECTIONS_REORDER_FAILED]: (state, action) => {
      return {
         ...state,
      }
   },

   [types.SET_MODAL_SETTINGS]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         modalSettings: {
            ...state.modalSettings,
            ...payload,
            error: null,
         },
      }
   },
};

export default createReducer(initialState)(reducersMap);
