import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Button from "common/components/elements/buttons/primary";
import Modal from "common/components/modules/modal";
import classNames from "classnames";
// import toast from "common/utils/toast";

const ChatListItemOptions = ({
   isMobile,
   isOpenOptions,
   setIsOpenOptions,
   roomId,
   onClickOpenCloseChat,
   isClosed,
   isActive,
   isPinned,
   onClickPinUnpinChat,
}) => {
   const clickOutsideId = `chat_list_item_options_${ roomId }`

   if(isMobile) {
      return (
         <div
            className='relative group/option_button h-full'
         >
            <Button
               iconName='options-vertical'
               backgroundColor='transparent'
               classNames={ `!w-6 h-full ${ clickOutsideId }` }
               borderRadius='large'
               padding='0'
               fontIconColor={ isOpenOptions ? 'action' : 'secondary group-hover/option_button:text-action' }
               fontIconSize='doubleLarge'
               onClick={ (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setIsOpenOptions(!isOpenOptions)
               } }
            />
            {
               isOpenOptions && (
                  <Options
                     isMobile={ isMobile }
                     onCloseModal={ () => setIsOpenOptions(false) }
                     clickOutsideId={ clickOutsideId }
                     onClickOpenCloseChat={ onClickOpenCloseChat }
                     isClosed={ isClosed }
                     isPinned={ isPinned }
                     onClickPinUnpinChat={ onClickPinUnpinChat }
                  />
               )
            }
         </div>
      )
   }

   return (
      <div
         className={ classNames({
            'absolute top-0 py-1 pl-1 right-[-8px] gap-2 z-1': true,
            'bg-hover hidden group-hover/list_item:flex': !isOpenOptions,
            'bg-panel group-hover/list_item:bg-hover flex z-2': isOpenOptions,
            '!bg-hover': isActive,
         }) }
      >
         <div
            data-tooltip-id='ams-top-tooltip'
            data-tooltip-content={ isClosed ? 'Re-open chat' : 'Close chat' }
            className='group/option_button h-10'
         >
            <Button
               iconName={ isClosed ? 'unarchive' : 'archive' }
               backgroundColor='panel'
               classNames='!w-10 h-full hover:!bg-panel'
               borderRadius='large'
               padding='0'
               fontIconColor='secondary group-hover/option_button:text-action'
               fontIconSize='doubleLarge'
               onClick={ (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onClickOpenCloseChat()
               } }
            />
         </div>
         <div
            className='relative group/option_button h-10'
         >
            <Button
               iconName='options-vertical'
               backgroundColor='panel'
               classNames={ `!w-10 h-full hover:!bg-panel ${ clickOutsideId }` }
               borderRadius='large'
               padding='0'
               fontIconColor={ isOpenOptions ? 'action' : 'secondary group-hover/option_button:text-action' }
               fontIconSize='doubleLarge'
               onClick={ (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setIsOpenOptions(!isOpenOptions)
               } }
            />
            {
               isOpenOptions && (
                  <Options
                     isMobile={ isMobile }
                     onCloseModal={ () => setIsOpenOptions(false) }
                     clickOutsideId={ clickOutsideId }
                     onClickOpenCloseChat={ onClickOpenCloseChat }
                     isClosed={ isClosed }
                     isPinned={ isPinned }
                     onClickPinUnpinChat={ onClickPinUnpinChat }
                  />
               )
            }
         </div>
      </div>
   )
}

ChatListItemOptions.propTypes = {
   isMobile: PropTypes.bool,
   isOpenOptions: PropTypes.bool,
   setIsOpenOptions: PropTypes.func,
   roomId: PropTypes.number,
   onClickOpenCloseChat: PropTypes.func,
   isClosed: PropTypes.bool,
   isActive: PropTypes.bool,
   isPinned: PropTypes.bool,
   onClickPinUnpinChat: PropTypes.func,
}

export default ChatListItemOptions;

const Options = ({
   isMobile,
   onCloseModal,
   clickOutsideId,
   onClickOpenCloseChat,
   isClosed,
   isPinned,
   onClickPinUnpinChat,
}) => {
   const [animate, setAnimate] = useState('up');
   let timeout = null;

   useEffect(() => {
      return () => {
         if(timeout){
            clearTimeout(timeout)
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [timeout]);
   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };   // eslint-disable-next-line
    }, [onCloseModal, isMobile]);

   function closeOnClickOutside(e) {
      if(!isMobile && !e.target.closest(`.${ clickOutsideId }`)) {
         onCloseModal()
      }
   }

   function modalClose(e){
      e.preventDefault()
      e.stopPropagation()
      setAnimate('down')
      timeout = setTimeout(() => {
         onCloseModal()
      }, 800)
   }

   const view = (
      <div
         className={ `${ clickOutsideId } w-full flex flex-col py-1` }
      >
         <div
            className='w-full h-10 hover:bg-hover'
         >
            <Button
               classNames='h-full w-full gap-3 pl-4'
               text={ isPinned ? 'Unpin' : 'Pin' }
               backgroundColor='transparent'
               iconPosition='left'
               iconName={ isPinned ? 'unpin' : 'pin' }
               fontIconColor='secondary'
               textColor='major'
               fontWeight='normal'
               textSize='small'
               textMarginX='0'
               textPosition='left'
               padding='none'
               alignment='end'
               fontIconSize='extraLarge'
               onClick={ (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onClickPinUnpinChat()
               } }
               waveActive={ false }
               iconClassName='rotate-[35deg]'
            />
         </div>
         {/* <div
            className='w-full h-10 hover:bg-hover'
         >
            <Button
               classNames='h-full w-full gap-3 pl-4'
               text='Mark as unread'
               backgroundColor='transparent'
               iconPosition='left'
               iconName='unread-mark'
               fontIconColor='secondary'
               textColor='major'
               fontWeight='normal'
               textSize='small'
               textMarginX='0'
               textPosition='left'
               padding='none'
               alignment='end'
               fontIconSize='extraLarge'
               onClick={ () => {
                  toast.error('Not implemented')
               } }
               waveActive={ false }
            />
         </div> */}
         <div
            className='w-full h-10 hover:bg-hover'
         >
            <Button
               classNames='h-full w-full gap-3 pl-4'
               text={ isClosed ? 'Re-open' : 'Close' }
               backgroundColor='transparent'
               iconPosition='left'
               iconName={ isClosed ? 'unarchive' : 'archive' }
               fontIconColor='secondary'
               textColor='major'
               fontWeight='normal'
               textSize='small'
               textMarginX='0'
               textPosition='left'
               padding='none'
               alignment='end'
               fontIconSize='extraLarge'
               onClick={ () => onClickOpenCloseChat() }
               waveActive={ false }
            />
         </div>
      </div>
   )

   if(isMobile) {
      return (
         <Modal
            onClose={ modalClose }
            animate={ isMobile ? animate : null }
         >
            {view}
         </Modal>
      )
   }

   return (
      <div
         className='absolute top-11 right-0 w-[200px] bg-panel z-10 rounded-lg overflow-hidden shadow'
      >
         {view}
      </div>
   )
}

Options.propTypes = {
   isMobile: PropTypes.bool,
   onCloseModal: PropTypes.func,
   clickOutsideId: PropTypes.string,
   onClickOpenCloseChat: PropTypes.func,
   isClosed: PropTypes.bool,
   isPinned: PropTypes.bool,
   onClickPinUnpinChat: PropTypes.func,
}
