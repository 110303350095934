export const FETCH_MEMBERS_TAGS_START = 'memberTags/FETCH_MEMBERS_TAGS_START'
export const FETCH_MEMBERS_TAGS_COMPLETED = 'memberTags/FETCH_MEMBERS_TAGS_COMPLETED'
export const FETCH_MEMBERS_TAGS_FAILED = 'memberTags/FETCH_MEMBERS_TAGS_FAILED'

export const FETCH_NEW_MEMBERS_TAGS_START = 'memberTags/FETCH_NEW_MEMBERS_TAGS_START'
export const FETCH_NEW_MEMBERS_TAGS_COMPLETED = 'memberTags/FETCH_NEW_MEMBERS_TAGS_COMPLETED'
export const FETCH_NEW_MEMBERS_TAGS_FAILED = 'memberTags/FETCH_NEW_MEMBERS_TAGS_FAILED'

export const TAGS_FILTER_START = 'memberTags/TAGS_FILTER_START'
export const TAGS_FILTER_FAILED = 'memberTags/TAGS_FILTER_FAILED'
export const TAGS_FILTER_COMPLETED = 'memberTags/TAGS_FILTER_COMPLETED'

export const ADD_NEW_TAG_START = 'memberTags/ADD_NEW_TAG_START'
export const ADD_NEW_TAG_COMPLETED = 'memberTags/ADD_NEW_TAG_COMPLETED'
export const ADD_NEW_TAG_FAILED = 'memberTags/ADD_NEW_TAG_FAILED'

export const UPDATE_MEMBERS_TAG_START = 'memberTags/UPDATE_MEMBERS_TAG_START'
export const UPDATE_MEMBERS_TAG_COMPLETED = 'memberTags/UPDATE_MEMBERS_TAG_COMPLETED'
export const UPDATE_MEMBERS_TAG_FAILED = 'memberTags/UPDATE_MEMBERS_TAG_FAILED'

export const DELETE_MEMBERS_TAG_START = 'memberTags/DELETE_MEMBERS_TAG_START'
export const DELETE_MEMBERS_TAG_COMPLETED = 'memberTags/DELETE_MEMBERS_TAG_COMPLETED'
export const DELETE_MEMBERS_TAG_FAILED = 'memberTags/DELETE_MEMBERS_TAG_FAILED'

export const DELETE_MEMBERS_TAGS_START = 'memberTags/DELETE_MEMBERS_TAGS_START'
export const DELETE_MEMBERS_TAGS_COMPLETED = 'memberTags/DELETE_MEMBERS_TAGS_COMPLETED'
export const DELETE_MEMBERS_TAGS_FAILED = 'memberTags/DELETE_MEMBERS_TAGS_FAILED'

