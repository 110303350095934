import * as mockData from 'admin/mock-data';

class BackendMocker {
   constructor(resource) {
      this.resource = mockData[resource];
   }

   index(params) {
      let list = [...this.resource.list];
      const { queryParams } = params;
      if(queryParams) {
         /* TODO fake filter mechanism */
         list = list.filter(i => {
            return i.completed === 1;
         })
      }
      const data = { data: list };
      return this.asyncMethod(data)
   }

   show(id, params) {
      const item = this.resource.list.find(i => i.id === parseFloat(id));
      const data = { data: item };
      return this.asyncMethod(data);
   }

   asyncMethod(data, error = null, timeout = 1000, shouldReject = false) {
      return new Promise((resolve, reject) => {
         setTimeout(() => {
            if(shouldReject) {
               reject(error);
            } else {
               resolve(data);
            }
         }, timeout);
      });
   }
}

export default BackendMocker;
