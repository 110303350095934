import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label';
import ResponsiveModal from 'common/components/modules/responsive-modal';
import { getSupportEmail, getCapitalizeSiteName, getSiteNameAbbreviatur, getSupportEmailText } from 'common/utils/helper';

import './index.scss';

const Agreement = ({
   onAcceptAgreement,
   onDeclineAgreement,
   onClickContentPolicy,
   showAcceptButton,
   height,
   width,
   isCloseButtonVisible,
}) => {
   const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);
   return (
      <>
         <ResponsiveModal
            headerTitle='Site Administrator Agreement'
            headerLeftIconAction={ onDeclineAgreement }
            onClose={ onDeclineAgreement }
            width={ width }
            height={ height }
            footerButtonText='Accept and continue'
            isDisabled={ !isAgreementAccepted }
            isLoading={ false }
            foooterButtonAction={ onAcceptAgreement }
            isCloseButtonVisible={ isCloseButtonVisible }
            className='site-admin-agreement-modal'
            contentClass='p-0'
         >
            <div className='agreement'>
               <AgreementContent
                  onClickContentPolicy={ onClickContentPolicy }
               />
               {
                  showAcceptButton && (
                     <div className='agreement_checkbox_wrapper w-full bg-panel flex'>
                        <div className='m-auto'>
                           <label
                              htmlFor='checkbox_agree'
                           >
                              <CheckboxLabel
                                 label='I accept these terms'
                                 onChange={ (name, value) => setIsAgreementAccepted(value) }
                                 checked={ isAgreementAccepted }
                                 name='agree'
                                 textColor='secondary'
                              />
                           </label>
                        </div>
                     </div>
                  )
               }
            </div>
         </ResponsiveModal>
      </>
   );
};

const AgreementContent = ({
   onClickContentPolicy,
}) => {
   return (
      <>
         <div className='agreement-wrapper'>
            <section className='intro'>
               <p>
                  Welcome to <span dangerouslySetInnerHTML={ { __html: getCapitalizeSiteName() } } />, an internet-based platform for creating and hosting adult-themed
                  e-commerce membership websites. This Site Administrator Agreement (this "<span className='font-medium'>Agreement</span>") is a legally
                  binding contract by and between {getSiteNameAbbreviatur()} Media Inc. ("<span className='font-medium'>{getSiteNameAbbreviatur()}</span>") and you, and shall be effective immediately
                  upon your acceptance hereof, which you signify by clicking the "I Accept" button.
               </p>
               <p>
                  This Agreement governs your access to and use of the Platform (as defined below). This Agreement
                  incorporates by reference {getSiteNameAbbreviatur()}’ <span role='presentation' onClick={ onClickContentPolicy } className='cursor-pointer text-action font-medium'>Content Policy</span> and all other operating rules, policies, price schedules
                  and other supplemental terms and conditions or documents that may be published from time to time by
                  {getSiteNameAbbreviatur()} on the Platform.
               </p>
               <p>
                  In consideration of the covenants contained herein and for other good and valuable consideration, the
                  receipt and sufficiency of which are hereby acknowledged, you and {getSiteNameAbbreviatur()} agree as follows:
               </p>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>1. Definitions</h2>
               <p className='my-2'>In this Agreement:</p>
               <ul className='list-disc pl-7'>
                  <li><span className='font-medium'>"Administrator Account"</span> means your account through which you access the Platform as a Site Administrator;</li>
                  <li><span className='font-medium'>"Cast Member"</span> means any individual, other than a Model, who is depicted in Content;</li>
                  <li><span className='font-medium'>"Content"</span> means intellectual property, in the form of text, audio, video, images or otherwise, that is uploaded, published or contributed to any Site or streamed live from any Site;</li>
                  <li><span className='font-medium'>"Content Policy"</span> means the policy currently available <span role='presentation' onClick={ onClickContentPolicy } className='cursor-pointer text-action font-medium'>here</span>, as the same may be updated by {getSiteNameAbbreviatur()} from time to time;</li>
                  <li><span className='font-medium'>"Member"</span> means an individual or entity in whose name a Member Account is registered; </li>
                  <li><span className='font-medium'>"Member Account"</span> means an account through which a registered user of a Site accesses such Site;</li>
                  <li><span className='font-medium'>"Model"</span> means any individual who is visually depicted in Content engaging in, or assisting another person to engage in, sexually explicit conduct;</li>
                  <li><span className='font-medium'>"Platform"</span> means the internet-based platform, owned and operated by {getSiteNameAbbreviatur()}, for creating and hosting adult-themed e-commerce membership websites, including all related web-applications and services;</li>
                  <li><span className='font-medium'>"Site"</span> means an e-commerce membership website that is hosted by the Platform and of which you are the Site Administrator;</li>
                  <li><span className='font-medium'>"Site Administrator"</span> means an individual who administers, through the Platform, the Site or Sites of one or more Models; and</li>
                  <li><span className='font-medium'>"Member Terms of Use"</span> means the legal agreement containing the terms that govern every Member’s access to and use of any Site.</li>
               </ul>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>2. Site Administrator Account; Registration</h2>
               <ul className='lower-alpha-parentheses-list-type pl-7'>
                  <li>To access and use the Platform as a Site Administrator you must first complete the registration process (which follows your acceptance of this Agreement) that creates your Administrator Account.  {getSiteNameAbbreviatur()} reserves the right to treat as abandoned and to cancel any incomplete registration.  Cancellation of a registration by {getSiteNameAbbreviatur()} will result in the termination of this Agreement (in accordance herewith).</li>
                  <li>The information you provide during registration must be complete, accurate and current, and you are responsible for keeping your registration information complete and up-to-date.</li>
                  <li>Under no circumstances will any person other than yourself be permitted to access your Administrator Account, and you not cannot assign or otherwise transfer your Administrator Account to any other person or entity.  You are responsible for keeping your Administrator Account password confidential and secure, and you must not divulge your Administrator Account password to anyone under any circumstances.</li>
                  <li>You agree that you are solely responsible and liable for all activity that occurs under your Administrator Account regardless of whether the activities are undertaken by you, your employees or a third party (including your contractors or agents) and, except to the extent caused by a breach of this Agreement by {getSiteNameAbbreviatur()}, neither {getSiteNameAbbreviatur()} nor any of its agents, employees or contractors are responsible for any unauthorized access to your account or for any losses or harm resulting therefrom.</li>
                  <li>You agree to contact {getSiteNameAbbreviatur()} immediately if you believe an unauthorized third party may be using your Administrator Account or if your login credentials are lost or stolen.  You may terminate your Administrator Account and this Agreement at any time in accordance with section 14 hereof.</li>
                  <li>You shall provide notice to {getSiteNameAbbreviatur()} of any changes in your legal name and/or changes in your address of residence (as applicable).</li>
               </ul>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>3. License to Use Platform</h2>
               <p className='mt-1'>
                  Subject to the terms and conditions of this Agreement, {getSiteNameAbbreviatur()} grants you a limited, personal, revocable, non-exclusive, non-transferable license to access and use the software and services that comprise the Platform.  This license is limited by all terms and conditions set forth herein and shall persist for as long as this Agreement remains effective or until otherwise rescinded by {getSiteNameAbbreviatur()}, as {getSiteNameAbbreviatur()} may do at any time without notice or obligation to you or any other person or entity.  You may not reproduce, copy, or redistribute, in whole or in part, any of the software or any part of the graphic interface that form part of the Platform or any intellectual property therein.  No rights of any kind in any software or other property that comprise the Platform shall accrue to you, to any Model, to any Cast Member or to any Member.
               </p>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>4. Disruptions in Service</h2>
               <p className='mt-1'>
                  {getSiteNameAbbreviatur()} will use commercially reasonable efforts to maintain the accessibility of the Platform, however, {getSiteNameAbbreviatur()} cannot guarantee uninterrupted access at all times.  Occurrences such as unusually high Platform traffic, power outages, natural disasters, changes in law, strikes, etc. may from time to time prevent access to the Platform by you, Models, Members and any others.  {getSiteNameAbbreviatur()} SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE THAT RESULTS FROM YOU, ANY MODEL, ANY MEMBER OR ANY OTHER PERSON BEING UNABLE TO ACCESS THE PLATFORM OR ANY CONTENT STORED THEREON.
               </p>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>5. Members</h2>
               <p className='mt-1'>
                  {getSiteNameAbbreviatur()} reserves the right to suspend or terminate any Member Account without any obligation or liability to you, to the Member in question or to any other person or entity for any harm or loss arising from any such suspension or termination by {getSiteNameAbbreviatur()} of such Member Account.  {getSiteNameAbbreviatur()} will not provide any refund or reimbursement of any monies received by you from any Member on account of {getSiteNameAbbreviatur()}’ suspension or termination of the Member Account of any such Member.
               </p>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>6. Models</h2>
               <ul className='lower-alpha-parentheses-list-type pl-7'>
                  <li>Every Model must be at least the age of majority in the jurisdiction in which the Model resides.</li>
                  <li>
                     Prior to the production of any Content, you must do each of the following:
                     <ul className='lower-roman-parentheses-list-type pl-7'>
                        <li>Provide to {getSiteNameAbbreviatur()} (in accordance with subsection 17.3 hereof) a scanned copy or high-resolution photograph of a valid identification card ("<span className='font-medium'>ID Card</span>") of every Model to be depicted in the Content.  Each ID Card must be government-issued, be valid at the time immediately prior to the production of the Content, bear the Model’s name, date of birth and recent and recognizable photograph.  Any scanned copy of an ID Card provided to {getSiteNameAbbreviatur()} pursuant to this subsection 6(b)(i) must be of sufficient quality such that the Model be readily identifiable from the picture thereon and the Model’s name and date of birth be clearly legible.</li>
                        <li>Provide to {getSiteNameAbbreviatur()} (in accordance with subsection 17.3 hereof) a list of  'stage names' (a "<span className='font-medium'>Names List</span>") in respect of every Model to be depicted in the Content.  The Names List of any particular Model must include every name, other than the Model's legal name, by which the Model is, has been or may in the future be known including every alias, nickname, stage name, professional name and, if applicable, the Model’s maiden name.  If at any time a name that is not included in the Names List of a Model previously provided to {getSiteNameAbbreviatur()} is published on a Site in respect of such Model, you shall provide to {getSiteNameAbbreviatur()} an updated and current Names List in respect of such Model.</li>
                     </ul>
                  </li>
                  <li>Scanned copies of ID Cards provided to {getSiteNameAbbreviatur()} by you pursuant to subsection 6(b)(i) above may be redacted to exclude information not specified in such subsection, such as addresses, telephone numbers, and social security numbers.</li>
                  <li>If you are or will at any time be a Model then prior to the production of or publication to the Platform of any Content in which you are depicted as a Model you must comply with subsections 6(a) through (c) above in respect of yourself.</li>
               </ul>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>7. Content and Sites</h2>
               <ul className='lower-alpha-parentheses-list-type pl-7'>
                  <li>{getSiteNameAbbreviatur()} reserves the right to modify or delete any Content, to modify or delete any Site, and to modify the Platform for any reason without notice, obligation or compensation to you or any other person or entity.  The reservation of these rights by {getSiteNameAbbreviatur()} does not in any way obviate or change your obligations with respect to Content as provided herein.</li>
                  <li>The Platform automatically incorporates on each page of every Site a hyperlink to the Member Terms of Use.  You must not, by act or omission, purport to amend, append, repeal, supplement, qualify or circumscribe the Member Terms of Use or otherwise alter the terms thereof or make any representations to any Member concerning the rules governing their access to and use of any Site.</li>
                  <li>The Platform automatically publishes to every Site and affixes to certain Content statements or notices that are required by law, and you must not remove, alter or conceal any such statements or notices.</li>
                  <li>You must keep a backup copy of all Content that you produce on or upload to the Platform.</li>
               </ul>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>8. License to Content and Derived Data</h2>
               <ul className='lower-alpha-parentheses-list-type pl-7'>
                  <li>As between you and {getSiteNameAbbreviatur()}, you own all rights, title and interest in and to all Content.  You hereby grant to {getSiteNameAbbreviatur()} a worldwide, irrevocable, perpetual, royalty-free, non-exclusive license to access, use, copy, modify, delete, make and retain copies of, publish to and broadcast from the Platform, and to otherwise make use of any and all Content, in whole or in part, and – in respect of Content in which you are depicted as a Model or Cast Member – in conjunction with any aliases, stage or professional names by which you are, have previously been, or may in the future be, known as needed by {getSiteNameAbbreviatur()} to provide the Platform and related services, to comply with applicable laws and regulations, and for the exercise by {getSiteNameAbbreviatur()} of its rights under this Agreement (the "<span className='font-medium'>Permissible Uses</span>").  This license shall survive any termination of this Agreement and shall persist until no longer required by {getSiteNameAbbreviatur()} for the Permissible Uses and shall terminate thereafter.</li>
                  <li>{getSiteNameAbbreviatur()} has the right to create analytics, compile and freely use any data derived from the use of the Platform by you, by Models and by Members ("<span className='font-medium'>Derived Data</span>").  For greater certainty, Derived Data is anonymized and does not include any personally identifiable information.  All right, title and interest in and to Derived Data shall be owned by {getSiteNameAbbreviatur()}.</li>
                  <li>YOU UNDERSTAND AND AGREE THAT {getSiteNameAbbreviatur()} SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY DAMAGES OR OTHER HARM THAT MAY RESULT FROM THE LOSS, CORRUPTION OR DELETION OF ANY CONTENT, HOWEVER CAUSED.  YOU FURTHER UNDERSTAND AND AGREE THAT {getSiteNameAbbreviatur()} SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY UNAUTHORIZED USE, DUPLICATION, PUBLICATION, THEFT OR MISAPPROPRIATION OF ANY CONTENT OR ANY INTELLECTUAL PROPERTY THEREIN OR ANY RESULTANT HARM OR LOSS TO YOU OR ANY PERSON.</li>
               </ul>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>9. Restrictions and Responsibilities</h2>
               <ul className='lower-alpha-parentheses-list-type pl-7'>
                  <li>{getSiteNameAbbreviatur()} may impose restrictions on your use of and access to certain features of the Platform and may impose additional charges for the use of certain features of the Platform.</li>
                  <li>
                     You represent, warrant and covenant that in your capacity as a Site Administrator, a Model (where applicable), a Cast Member (where applicable), a user of the Platform and a party to this Agreement you will not:
                     <ul className='lower-roman-parentheses-list-type pl-7'>
                        <li>intentionally or unintentionally violate any applicable local, state, provincial national or international laws or regulations;</li>
                        <li>modify, translate, make derivative works of, disassemble, decompile, reverse compile or reverse engineer any part of the Platform;</li>
                        <li>violate this Agreement; or</li>
                        <li>represent yourself, whether by action or omission, as being affiliated with or partnered to {getSiteNameAbbreviatur()} or as being in anything other than an arm’s length commercial relationship with {getSiteNameAbbreviatur()}.</li>
                     </ul>
                  </li>
                  <li>You represent, warrant and covenant that you will use your best efforts to ensure that none of the aforementioned items 9(b)(i) through 9(b)(iv) will be done by any Model or Cast Member.</li>
                  <li>
                     You understand and agree that you are solely responsible for:
                     <ul className='lower-roman-parentheses-list-type pl-7'>
                        <li>ensuring that all Content complies with the <span role='presentation' onClick={ onClickContentPolicy } className='cursor-pointer text-action font-medium'>Content Policy</span> and with all applicable laws and regulations and that the use of and publication to any Site of any Content does not violate the rights of any person or entity;</li>
                        <li>ensuring that your use of the Platform complies with all laws and regulations of the jurisdiction in which you reside or from which you access the Platform;</li>
                        <li>keeping a backup copy of all Content that is produced on or uploaded to the Platform by you or by any Model; and</li>
                        <li>properly handling and processing any notices received by you from any person claiming a violation of rights in respect of any Content, including notices pursuant to the Digital Millennium Copyright Act (United States) (the <span className='font-medium'>DMCA</span>).</li>
                     </ul>
                  </li>
                  <li>
                     You shall not:
                     <ul className='lower-roman-parentheses-list-type pl-7'>
                        <li>contact any Member by any means other than those provided by the Platform;</li>
                        <li>use the Platform to engage in or facilitate any activities except those for which the Platform is expressly intended including, without limitation, prostitution or making arrangements for contact between you and any Member, between any Member and any Model, or between or among Members outside of the Platform; or</li>
                        <li>acquire, share, record or make any use of any contact information or any personally identifiable information of any Member that may become known to you through the Platform.</li>
                     </ul>
                  </li>
               </ul>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>10. Copyright and Trademark Information</h2>
               <p className='mt-1'>
                  The Platform and the information and materials that it contains are the property of {getSiteNameAbbreviatur()} and its licensors, and are protected from unauthorized copying and dissemination by copyright law, trademark law, international conventions and other intellectual property laws.  All {getSiteNameAbbreviatur()} product names and logos and those of its licensors are trademarks or registered trademarks.  Nothing contained in this Agreement shall be construed as granting, by implication, estoppel, or otherwise, any license or right to use any part of the Platform or any materials displayed thereon, through the use of framing or otherwise, except: (i) as expressly permitted by this Agreement; or (ii) with the prior written permission of {getSiteNameAbbreviatur()}.
               </p>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>11. Indemnification</h2>
               <p className='mt-1'>
                  You agree to defend, indemnify and hold {getSiteNameAbbreviatur()} (including all of its successors and assigns), its directors, officers, employees, agents, contractors, partners and licensors (each an "<span className='font-medium'>Indemnified Party</span>") harmless from any claim or demand and harmless with respect to any and all costs, expenses, losses, damages, judgments and settlements arising therefrom, including reasonable attorneys’ fees, made by any third party against any Indemnified Party relating to: (i) the use of the Platform by you, any Model or any Member; (ii) any Content; or (iii) any violation by you of this Agreement or of any law or regulation.  These obligations shall survive the termination or expiration of this Agreement and your use of the Platform.
               </p>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>12. Release</h2>
               <p className='mt-1'>
                  You agree to release, discharge, and hold harmless each Indemnified Party from any and all damages, liabilities, costs, expenses, claims, and/or judgments of any kind or nature whatsoever arising from any Permissible Uses including, but not limited to, claims based on copyright infringement, invasion of privacy, right of publicity, libel, defamation, or false light, or claims arising from or relating to any alteration, blurring, optical illusion, use in composite form, distortion, or other modification that may occur, intentionally or otherwise, in connection with any of the Permissible Uses.  You further agree to release, discharge and hold harmless each Indemnified Party from any and all damages, liabilities, costs, expenses, claims and/or judgments of any kind whatsoever arising from any unauthorized use, duplication, publication, theft or misappropriation by any third party of any Content or of any intellectual property that forms any part of any Site.  You acknowledge and agree that this Agreement is binding upon you, your heirs, legal representatives, and assigns.
               </p>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>13. Representations and Warranties</h2>
               <p className='my-2'>You hereby represent and warrant that:</p>
               <ul className='lower-roman-parentheses-list-type pl-7'>
                  <li>you are of full age and are competent to execute this Agreement;</li>
                  <li>you are not prohibited from using the Platform by any laws or regulations of the jurisdiction in which you reside or from which you access the Platform;</li>
                  <li>the rights granted under this Agreement do not and will not conflict with or violate any commitment you have made to any person or entity;</li>
                  <li>you have read this Agreement in its entirety and have fully understood the meaning and effect of its contents;</li>
                  <li>you or your licensors own all right, title, and interest in and to all Content;</li>
                  <li>you have all rights in and to all Content necessary to grant the rights contemplated by this Agreement and to publish, broadcast, monetize and make such other uses of Content as are contemplated by this Agreement without violating the rights of any other person or entity;</li>
                  <li>you will not publish to or distribute from the Platform any Content in respect of which you do not have all the necessary rights to so publish or broadcast from every Model and Cast Member depicted in any such Content;</li>
                  <li>in the absence of the rights described in subsections 13(v) to (vi) above in respect of any Content, you will not publish or cause or allow to be published any such Content on the Platform; and</li>
                  <li>you have all the necessary rights in and to all intellectual property that will form any part of any Site and your use of such intellectual property does not and will not violate the rights of any person or entity.</li>
               </ul>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>14. Term and Termination</h2>
               <p className='mt-1'>
                  <span className='font-semibold underline'>Term of Agreement:</span>  This Agreement will take effect upon your acceptance hereof and will continue in force until terminated in accordance herewith.
               </p>
               <p className='mt-1'>
                  <span className='font-semibold underline'>Termination by You:</span>  You may terminate this Agreement upon fifteen (15) days’ written notice to {getSiteNameAbbreviatur()}, such notice to be given in accordance with subsection 17.3 below.
               </p>
               <div className='mt-1'>
                  <span className='font-semibold underline'>Termination by {getSiteNameAbbreviatur()}:</span>  {getSiteNameAbbreviatur()} may suspend or terminate this Agreement:
                  <ul className='lower-alpha-parentheses-list-type pl-7'>
                     <li>at any time with your written agreement;</li>
                     <li>upon fifteen (15) days’ notice to you; or</li>
                     <li>
                        at any time and without prior notice for cause, where cause shall include, but not be limited to:
                        <ul className='lower-roman-parentheses-list-type pl-7'>
                           <li>a material breach of this Agreement by you, the materiality of any such breach being determined by {getSiteNameAbbreviatur()} in its sole discretion;</li>
                           <li>a request or order from law enforcement, a judicial body, or other government entity; </li>
                           <li>the publication of Content that is unlawful on any Site;</li>
                           <li>a claim by any third party in respect of any Content;</li>
                           <li>your inability to establish the legality of any Content or of your being vested of sufficient rights to any Content as required by law or as required in order to comply with this Agreement; or</li>
                           <li>your having ceased to operate in the ordinary course, having made an assignment for the benefit of creditors or similar disposition of your assets, or having become the subject of any bankruptcy, reorganization, liquidation, dissolution or similar proceeding.</li>
                        </ul>
                     </li>
                  </ul>
               </div>
               <p className='mt-1'>
                  <span className='font-semibold px-3'>14.4</span><span className='font-semibold underline'>Effect of Termination:</span>
               </p>
               <p className='mt-1'>
                  <span className='font-bold underline'>Outstanding Fees:</span> Termination of this Agreement shall not relieve you of the obligation to pay any amounts that were owing by you to {getSiteNameAbbreviatur()} immediately prior to the termination of this Agreement.
               </p>
               <p className='mt-1'>
                  <span className='font-bold underline'>Surviving Provisions:</span> Notwithstanding the foregoing, the following sections and subsections of this Agreement shall survive any termination thereof:  2(d), 3, 4, 7(d), 8, 9(d)(iii), 9(e)(iii), 10, 11, 12, 14.4, 15, 17.1 and 17.6.
               </p>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>15. Important Disclaimers and Limitations</h2>
               <ul className='lower-alpha-parentheses-list-type pl-7'>
                  <li>THE PLATFORM IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.  {getSiteNameAbbreviatur()} (INCLUDING ITS SUBCONTRACTORS, AGENTS, LICENSORS, SUPPLIERS, DIRECTORS AND/OR EMPLOYEES) SPECIFICALLY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND IN RESPECT OF THE PLATFORM INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED REPRESENTATIONS AND/OR WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.</li>
                  <li>YOUR USE OF THE PLATFORM IS AT YOUR OWN RISK, AND {getSiteNameAbbreviatur()} BEARS NO RESPONSIBILITY OR LIABILITY FOR ANY LOSS OR HARM TO YOU, ANY MODEL, ANY CAST MEMBER, ANY MEMBER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE INCLUDING, BUT NOT LIMITED TO, LOSS OR CORRUPTION OF CONTENT, UNAUTHORIZED DUPLICATION, THEFT OR MISAPPROPRIATION OF CONTENT OR ANY INTELLECTUAL PROPERTY THEREIN, HARM TO REPUTATION, ARISING OUT OF OR IN CONNECTION WITH ANY ACTIVITY THAT OCCURS THROUGH YOUR ADMINISTRATOR ACCOUNT OR ON ANY SITE, ANY INTERACTIONS BETWEEN MEMBERS AND MODELS, YOUR USE OF THE PLATFORM OR ANY DELAY OR IMPEDIMENT TO SUCH USE, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EVEN IN THE EVENT OF A FUNDAMENTAL BREACH BY {getSiteNameAbbreviatur()} AND EVEN IF {getSiteNameAbbreviatur()} HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.</li>
                  <li>UNDER NO CIRCUMSTANCES SHALL {getSiteNameAbbreviatur()}, ITS DIRECTORS, EMPLOYEES, LICENSORS OR SUPPLIERS BE LIABLE FOR LOST INCOME OR PROFITS OR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES WHATSOEVER ARISING OUT OF OR IN CONNECTION WITH THE PLATFORM OR THIS AGREEMENT, HOWEVER ARISING.</li>
                  <li>{getSiteNameAbbreviatur()} DISCLAIMS ANY DUTIES OF A BAILEE, AND YOU HEREBY WAIVE ALL RIGHTS AND REMEDIES OF A BAILOR (WHETHER ARISING UNDER STATUTE OR COMMON LAW) IN RELATION TO OR ARISING OUT OF THE POSSESSION OF CONTENT BY {getSiteNameAbbreviatur()}, ITS AGENTS, DIRECTORS AND/OR EMPLOYEES OR ANY OF ITS OR THEIR CONTRACTORS OR AGENTS.</li>
                  <li>{getSiteNameAbbreviatur()} WILL NOT BE RESPONSIBLE OR LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES THAT MAY RESULT OR ARISE OUT OF THE SUSPENSION OR TERMINATION OF THIS AGREEMENT AND YOUR ADMINISTRATOR ACCOUNT.</li>
                  <li>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, {getSiteNameAbbreviatur()}’ LIABILITY AND THE LIABILITY OF ITS LICENSORS, DIRECTORS, AGENTS AND EMPLOYEES, TO YOU, TO ANY MODEL, TO ANY CAST MEMBER, TO ANY MEMBER OR TO ANY THIRD PARTY FOR ANY DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION) WILL AT ALL TIMES BE LIMITED TO THE GREATER OF (I) FIFTY UNITED STATES DOLLARS (US$50) AND (II) AMOUNTS YOU HAVE PAID TO {getSiteNameAbbreviatur()} (IF ANY) OVER THE TWELVE (12) MONTHS PRECEEDING THE ACTION.  THE FOREGOING LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</li>
                  <li>Some jurisdictions do not allow the exclusion or limitation of liability for incidental or consequential damages.  As such, to the extent such exclusions or limitations are specifically prohibited by applicable law, some of the aforementioned exclusions or limitations may not apply to you.</li>
               </ul>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>16. Changes to This Agreement</h2>
               <p className='mt-1'>
                  This Agreement and any other documents, policies and terms and conditions that are incorporated by reference herein may be amended or updated by {getSiteNameAbbreviatur()} from time to time without notice to you.  However, if and when {getSiteNameAbbreviatur()} does make any such amendments or updates {getSiteNameAbbreviatur()} will endeavour to provide notice to you of the change.  You can access the text of this Agreement that is in effect at any time on the Platform when logged-in to your Administrator Account.  It is your responsibility to keep abreast of any changes to this Agreement, and your continued use of the Platform following any amendments or updates to this Agreement shall signify your assent to and acceptance of this Agreement as revised.  Any new features or services that may be added to or made available through the Platform from time to time will be subject to this Agreement.
               </p>
            </section>
            <section className='mt-4'>
               <h2 className='text-base font-semibold underline text-major'>17. General</h2>
               <p>
                  <span className='font-medium underline'>Feedback:</span> {getSiteNameAbbreviatur()} welcomes any suggestions, comments and feedback in respect of the Platform and related services ("<span className='font-medium'>Feedback</span>").  You agree that if you provide Feedback to {getSiteNameAbbreviatur()} that: (i) {getSiteNameAbbreviatur()} is not subject to any confidentiality obligations in respect of the Feedback; (ii) the Feedback is not confidential or proprietary information belonging to you or to any third party and that you have all of the necessary rights to disclose the Feedback to {getSiteNameAbbreviatur()} in accordance with the terms of this Agreement; (iii) {getSiteNameAbbreviatur()} (including all of its successors and assigns) shall have a paid-up, royalty-free, worldwide, transferable, sublicensable, assignable, irrevocable, perpetual license to use or incorporate into the Platform and any other product and/or service any Feedback provided by you in respect of the Platform or any related service; and (iv) neither you nor any other person or entity shall be entitled to receive any compensation or reimbursement of any kind in respect of the Feedback.
               </p>
               <p>
                  <span className='font-medium underline'>Relationship of the Parties:</span> Nothing contained herein is intended to, or shall be deemed to, establish a partnership, franchise, joint venture, fiduciary or employment relationship between the parties to this Agreement and neither party is an agent of the other for any purpose or has the authority to bind the other.
               </p>
               <p>
                  <span className='font-medium underline'>Notices and Deliveries:</span> Any notice or other communication (each a <span className='font-medium'>Communication</span>) required or permitted to be given hereunder shall be given in writing by email using the English language.  Communications by you to {getSiteNameAbbreviatur()} shall be sent to <a target='blank' href='mailto:support@mymember.site' className='text-action font-medium'><span dangerouslySetInnerHTML={ { __html: getSupportEmailText() } } /></a>, and Communications by {getSiteNameAbbreviatur()} to you shall be sent to the email address saved in your Administrator Account.  Any Communication sent or transmitted as provided above shall be deemed to have been received on the same day it is sent or transmitted.  You must ensure that the email address saved in your Administrator Account is at all times valid.  You agree that {getSiteNameAbbreviatur()} may send you electronic messages, including email and SMS/MMS messages (as applicable), from time to time concerning this Agreement, the Platform, any Site, and/or your Administrator Account.
               </p>
               <p>
                  <span className='font-medium underline'>Headings:</span> Headings of articles, sections and subsections in this Agreement are inserted for convenience of reference only and do not affect the construction or interpretation of this Agreement.
               </p>
               <p>
                  <span className='font-medium underline'>Assignment:</span> You may not transfer or assign this Agreement (whether voluntarily, by operation of law, or otherwise) or any rights or licenses granted by this Agreement without the prior written consent of {getSiteNameAbbreviatur()}.  {getSiteNameAbbreviatur()} may assign this Agreement at any time without notice.
               </p>
               <p>
                  <span className='font-medium underline'>Governing Law & Disputes:</span> This Agreement shall be governed by the laws of the Province of Ontario and the laws of Canada applicable therein.  No choice of laws rules of any jurisdiction shall apply to this Agreement.  The courts of the Province of Ontario shall have jurisdiction over any legal action or proceeding arising out of or relating to this Agreement or your use of the Platform and you consent to the jurisdiction of such courts for any such action or proceeding.  You hereby waive all rights that you may have or that may hereafter arise to contest such jurisdiction of such courts.  The parties hereto waive any right to a jury trial with respect to any action brought in connection with this Agreement.  The application of the United Nations Convention on Contracts for the International Sale of Goods to this Agreement is expressly excluded.  You agree that any claim or cause of action arising out of or related to this Agreement or your use of the Platform must be filed no later than one (1) year after the cause of action arises or be forever barred.
               </p>
               <p>
                  <span className='font-medium underline'>Entire Agreement & Survival:</span> This Agreement, including any agreements or policies incorporated herein, constitutes the entire agreement between you and {getSiteNameAbbreviatur()} concerning the subject matter hereof and supersedes any and all prior agreements, negotiations, correspondence, understandings and communications between the you and {getSiteNameAbbreviatur()}, whether written or oral, respecting the subject matter hereof.
               </p>
               <p>
                  <span className='font-medium underline'>Waiver & Severability:</span> Failure by {getSiteNameAbbreviatur()} to exercise or enforce any of its rights under this Agreement shall not constitute a waiver of such rights.  If any part of this Agreement is held to be unlawful, void, or unenforceable, that part shall be deemed severed and shall not affect the validity and enforceability of the remaining provisions hereof.
               </p>
               <p>
                  <span className='font-medium underline'>Questions/Contact:</span> If you have any questions about this Agreement or if you would like to provide Feedback, please contact us at: <a target='blank' href='mailto:support@mymember.site' className='text-action font-medium'>{getSupportEmail()}</a>.
               </p>
            </section>
            <p className='mt-7 pb-3'>
               Last Updated: March 27, 2020
               <br />
               v2.4
            </p>
         </div>
      </>
   )
}

AgreementContent.propTypes = {
   onClickContentPolicy: PropTypes.func,
};

Agreement.propTypes = {
   onAcceptAgreement: PropTypes.func,
   onDeclineAgreement: PropTypes.func,
   onClickContentPolicy: PropTypes.func,
   height: PropTypes.string,
   width: PropTypes.string,
   showAcceptButton: PropTypes.bool,
   isCloseButtonVisible: PropTypes.bool,
};

Agreement.defaultProps = {
   onAcceptAgreement: () => { console.log('onAcceptAgreement') },
   onDeclineAgreement: () => { console.log('onDeclineAgreement') },
   onClickContentPolicy: () => { console.log('onClickContentPolicy') },
   height: '100%',
   width: '420px',
   showAcceptButton: true,
   isCloseButtonVisible: true,
};

export default React.memo(Agreement);
