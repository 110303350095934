const initialState = {
   isFetching: true,
   storiesData: {
      data: [],
   },
   isNewFetching: false,
   notCompletedList: [],

   newStory: {},

   isSharedStoryFetching: false,
   sharedStory: {},

   statistics: {},
   errors: null,
   isEmpty: false,
   isEmptyByFilter: false,
   isStatisticsFetching: true,
   isFetchingDelete: false,

   // share image

   initImgEvent: null,
   shareErrors: null,
   video: {},
   customPosterImage: {
      loading: false,
      full_src: '',
      src: '',
   },
   activeStory: {},
   isFetchingStory: true,
   isPublished: false,

   isReorderMode: false,
   reorderedState: [],
   initialReorderState: [],
};

export default initialState;
