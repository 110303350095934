
import { createSelector } from 'reselect'

const innerStateSelector = state => state.photosets;

const photosetsDataSelector = createSelector(
   innerStateSelector,
   state => state.photosetsData
);

const isPhotosetsDataFetching = createSelector(
   innerStateSelector,
   state => state.isFetching
);
const isNewFetchingSelector = createSelector(
   innerStateSelector,
   state => state.isNewFetching
);
const isEmptyByFilterSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyByFilter
);
const isFetchingByFilterSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingByFilter
);
const isEmptySelector = createSelector(
   innerStateSelector,
   state => state.isEmpty
);
const isFetchingByIdSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingById
);
const activeDataSelector = createSelector(
   innerStateSelector,
   state => state.activeData
);
const castMembersSelector = createSelector(
   innerStateSelector,
   state => state.casts
);
const ownersSelector = createSelector(
   innerStateSelector,
   state => state.owners
);
const isUpdateFetchingSelector = createSelector(
   innerStateSelector,
   state => state.isUpdateFetching
);
const isDeleteFetchingSelector = createSelector(
   innerStateSelector,
   state => state.isDeleteFetching
);
const photosSelector = createSelector(
   innerStateSelector,
   state => state.photos
);
const newPhotosSelector = createSelector(
   innerStateSelector,
   state => state.newPhotos
);
const coverSelector = createSelector(
   innerStateSelector,
   state => state.cover
);
const newPhotosetsSelector = createSelector(
   innerStateSelector,
   state => state.newPhotosets
);
const customPricesSelector = createSelector(
   innerStateSelector,
   state => state.customPrices
);
const isFetchingAccessSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingAccess
);
const accessingSelector = createSelector(
   innerStateSelector,
   state => state.accessing
);
const photosLengthSelector = createSelector(
   innerStateSelector,
   state => state.photosLength
);
const deletePhotosSelector = createSelector(
   innerStateSelector,
   state => state.deletePhotos
);
const isFetchingStatsSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingStats
);
const statsDataSelector = createSelector(
   innerStateSelector,
   state => state.statsData
);
const errorsDataSelector = createSelector(
   innerStateSelector,
   state => state.errors
);
const isFetchingValidateSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingValidate
);
const activeTabsSelector = createSelector(
   innerStateSelector,
   state => state.activeTab
);
const deleteButtonDisabledSelector = createSelector(
   innerStateSelector,
   state => state.deleteButtonDisabled
);
const openEditTitelModSelector = createSelector(
   innerStateSelector,
   state => state.openEditTitelMod
);
const openStatusModSelector = createSelector(
   innerStateSelector,
   state => state.openStatusMod
);
const updateStatusFetchingSelector = createSelector(
   innerStateSelector,
   state => state.updateStatusFetching
);

const updateButtonDisabledSelector = createSelector(
   innerStateSelector,
   state => state.updateButtonDisabled
);

const isErrorsSelector = createSelector(
   innerStateSelector,
   state => state.isErrors
);
const isFetchingInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingInsideModal
);
const listInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.photosetsDataInsideModal
);
const isFetchingByFilterInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingByFilterInsideModal
);
const isNewFetchingInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.isNewFetchingInsideModal
);
const isEmptyByFilterInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyByFilterInsideModal
);
const isEmptyInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.isEmptyInsideModal
);
const isInitedSelector = createSelector(
   innerStateSelector,
   state => state.isInited
);
const filtersSelector = createSelector(
   innerStateSelector,
   state => state.filters
);
const isDistoryFetchingSelector = createSelector(
   innerStateSelector,
   state => state.isDistoryFetching
);
const bulkEditIdsSelector = createSelector(
   innerStateSelector,
   state => state.bulkEditIds
);
const photosetsDataByIdsSelector = createSelector(
   innerStateSelector,
   state => state.photosetsDataByIds
);
const isFetchingByIdsSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingByIds
);
const bulkUpdateFetchingSelector = createSelector(
   innerStateSelector,
   state => state.bulkUpdateFetching
);
const bulkUpdateErrorsSelector = createSelector(
   innerStateSelector,
   state => state.bulkUpdateErrors
);
const warningModalSelector = createSelector(
   innerStateSelector,
   state => state.warningModal
);
const filtersInsideModalSelector = createSelector(
   innerStateSelector,
   state => state.filtersInsideModal
);
export {
   photosetsDataSelector,
   isPhotosetsDataFetching,
   isNewFetchingSelector,
   isEmptyByFilterSelector,
   isFetchingByFilterSelector,
   isEmptySelector,
   isFetchingByIdSelector,
   activeDataSelector,
   ownersSelector,
   castMembersSelector,
   isUpdateFetchingSelector,
   isDeleteFetchingSelector,
   photosSelector,
   newPhotosSelector,
   coverSelector,
   newPhotosetsSelector,
   customPricesSelector,
   isFetchingAccessSelector,
   accessingSelector,
   photosLengthSelector,
   deletePhotosSelector,
   statsDataSelector,
   isFetchingStatsSelector,
   errorsDataSelector,
   isFetchingValidateSelector,
   activeTabsSelector,
   deleteButtonDisabledSelector,
   openEditTitelModSelector,
   openStatusModSelector,
   updateStatusFetchingSelector,

   updateButtonDisabledSelector,
   isErrorsSelector,

   isFetchingInsideModalSelector,
   listInsideModalSelector,
   isFetchingByFilterInsideModalSelector,
   isNewFetchingInsideModalSelector,
   isEmptyByFilterInsideModalSelector,
   isEmptyInsideModalSelector,
   isInitedSelector,
   filtersSelector,
   isDistoryFetchingSelector,
   bulkEditIdsSelector,
   photosetsDataByIdsSelector,
   isFetchingByIdsSelector,
   bulkUpdateFetchingSelector,
   bulkUpdateErrorsSelector,
   warningModalSelector,
   filtersInsideModalSelector,
}
