import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { ToolbarIcon,  ToolbarButtonWrraper } from '../modules';
import { useSelector } from 'react-redux';
import Modal from 'common/components/modules/modal';

import './style.scss'


const VariableList = ({ variableList, selectVariable, onClickOpen, name, className }) => {
   const screenWidth = useSelector(state => state.common.screenWidth);
   const [animate, setAnimate] = useState('up');

   const isMobile = screenWidth < 1025;
   let timeout = useRef(null);
   const [showTweetVariable, setShowTweetVariable] = useState(false);

   function closeOnClickOutside(e) {
      if(isMobile){
         return;
      }
      if(e.target && !e.target.closest(`#${ name }-variable-list`) && !e.target.closest(`#${ name }-variable-button`)) {
         setShowTweetVariable(false)
      }
   }
   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };   // eslint-disable-next-line
   }, []);

   useEffect(() => {
      return () =>  clearTimeout(timeout.current);
   }, []);



   const onCloseModal = () => {
      setAnimate('down');
      timeout.current = setTimeout(() => {
         setShowTweetVariable(false);
         setAnimate('up');
      }, 800)
   }

   const view = (
      <div className='flex py-1 flex-col w-full'>
         {
            variableList.map((item, index) => {
               return (
                  <div
                     key={ index.toString() }
                     role='presentation'
                     onClick={ () => {
                        if(isMobile){
                           onCloseModal();
                        } else {
                           setShowTweetVariable(false);
                        }
                        selectVariable(item?.text);
                     } }
                     className='flex cursor-pointer items-center px-4 py-[10px] hover:bg-hover h-10 pl-4'
                  >
                     <span className='block text-major leading-[20px] text-base'>
                        { item?.text }
                     </span>
                  </div>
               )
            })
         }
      </div>
   )
   return (
      <ToolbarButtonWrraper
         disabled={ !variableList?.length }
         className={ className }
         id={ `${ name }-variable-button` }
         tooltipContanet='Add variable'
      >
         <div
            data-tooltip-id={ `${ name }-variable-list` }
            role='presentation'
            onClick={ (e) => {
               setShowTweetVariable(!showTweetVariable);
               if(!showTweetVariable){
                  onClickOpen();
               }
            } }
            className='flex items-center justify-center w-full h-wull'
         >
            <ToolbarIcon name='variable' />
         </div>

         {
            isMobile && showTweetVariable && (
               <Modal
                  animate={ animate }
                  onClose={ onCloseModal }
               >
                  {view}
               </Modal>

            )
         }
         {
            !isMobile && createPortal(
               <Tooltip
                  id={ `${ name }-variable-list` }
                  content=''
                  className='flex shadow dark:shadow'
                  place='bottom-end'
                  clickable
                  isOpen={ showTweetVariable }
                  noArrow
                  variant='light'
                  style={ {
                     width: '240px',
                     borderRadius: '8px',
                     zIndex: 60,
                  } }
                  openOnClick
               >
                  {view}
               </Tooltip>,
               document.getElementById('tooltips')
            )
         }
      </ToolbarButtonWrraper>

   )
}



VariableList.propTypes = {
   selectVariable: PropTypes.func,
   name: PropTypes.string,
   onClickOpen: PropTypes.func,
   variableList: PropTypes.array,
   className: PropTypes.string,
};
VariableList.defaultProps = {
   className: '',
}

export default VariableList
