const initialState = {
   isFetching: false,
   openStepsModal: false,
   dialog: {
      type: '',
      open: false,
      loading: false,
   },
   isOpenAddContentModal: false,
};

export default initialState;
