const initialState = {
   memberships: [],
   isFetchingMemberships: true,
   topSpendingMembers: [],
   last_page: 1,
   isFetchingTopSpendingMembers: true,
   isFetchingTopSpendersNextPage: false,
   revenue: {
      total_revenue: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
         currentDayData: {},
      },
      revenue_growth_rate: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
         currentDayData: {},
      },
      revenue_churn_rate: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
         currentDayData: {},
      },
   },
   engagement: {
      active_users: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
      },
      content_views: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
      },
      content_likes: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
      },
      content_comments: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
      },
      content_unlocks: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
      },
   },
   acquisition: {
      unique_visitors: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
         currentDayData: {},
      },
      traffic_sources: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: [],
      },
      locations: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: [],
      },
      devices: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
      },
      bounce_rate: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
         currentDayData: {},
      },
      account_creations: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
         currentDayData: {},
      },
      joins: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
         currentDayData: {},
      },
      account_conversion_rate: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
         currentDayData: {},
      },
      join_conversion_rate: {
         isFetchingInit: true,
         isFetchByFilter: false,
         chartData: {},
         currentDayData: {},
      },
   },
   contentList: {
      active_users: null,
      content_views: {
         data: [],
         last_page: 1,
         isFetching: true,
         isFetchingNextPage: false,
      },
      content_likes: {
         data: [],
         last_page: 1,
         isFetching: true,
         isFetchingNextPage: false,
      },
      content_comments: {
         data: [],
         last_page: 1,
         isFetching: true,
         isFetchingNextPage: false,
      },
      content_unlocks: {
         data: [],
         last_page: 1,
         isFetching: true,
         isFetchingNextPage: false,
      },
   },
};
 
export default initialState;
 