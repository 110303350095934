import React, { Component, memo } from 'react';
import AddNewPhotoset from 'admin/views/photosets/add-new-photoset';
import PropTypes from 'prop-types';
import toast from "common/utils/toast";
import '../style.scss'
import ConfirmModal from 'common/components/modules/modal-wrapper';
import cx from 'classnames'


class PhotosetCreateContainer extends Component {
   static propTypes = {
      photos: PropTypes.array,
      cover: PropTypes.any,
      selectCoverPhoto: PropTypes.func,
      photosLength: PropTypes.number,
      onFileChange: PropTypes.func,
      onDeleteImage: PropTypes.func,
      onSortEnd: PropTypes.func,
      lockedImageIsBlur: PropTypes.bool,
      onClickUpdate: PropTypes.func,
      screenWidth: PropTypes.number,
      isEditPage: PropTypes.bool,
      zipFiles: PropTypes.array,
      uploadZipAgain: PropTypes.func,
      isUnlockedBefore: PropTypes.bool,
      error: PropTypes.object,
      onChangeErrors: PropTypes.func,
      thumbnails: PropTypes.array,
      isDemoMode: PropTypes.bool,
      thumbnailIsSfw: PropTypes.bool,
   }

   state = {
      isLength: false,
      isOpenConfirmModal: false,
      deleteItem: null,
   }

   componentDidMount(){
      const { photos } = this.props;
      this.setState({ isLength: !!photos.length })
   }

   openRemoveModal = (photo, bool) => {
      const { photos, isEditPage } = this.props;

      if(isEditPage && photos.length === 1) {
         toast.error('Photoset must contain at least 1 file')
      } else {
         this.setState({ isOpenConfirmModal: bool, deleteItem: photo })
      }
   }

   render() {
      const {
         photos,
         cover,
         selectCoverPhoto,
         photosLength,
         onFileChange,
         onDeleteImage,
         onSortEnd,
         lockedImageIsBlur,
         onClickUpdate,
         screenWidth,
         isEditPage,
         zipFiles,
         uploadZipAgain,
         isUnlockedBefore,
         error,
         onChangeErrors,
         thumbnails,
         isDemoMode,
         thumbnailIsSfw,
      } = this.props

      const { isOpenConfirmModal, deleteItem } = this.state

      return <>
         <div className={ cx({ 'photos-content-wraper': !isEditPage }) }>
            <AddNewPhotoset
               photosCount={ photosLength }
               photos={ photos }
               cover={ cover }
               fileUpload={ onFileChange }
               removePhoto={ (photo) => this.openRemoveModal(photo, true) }
               makeCover={ selectCoverPhoto }
               onSortPhotosEnd={ onSortEnd }
               lockedImageIsBlur={ lockedImageIsBlur }
               onClickUpdate={ onClickUpdate }
               screenWidth={ screenWidth }
               isEditPage={ isEditPage }
               zipFiles={ zipFiles }
               uploadZipAgain={ uploadZipAgain }
               isUnlockedBefore={ isUnlockedBefore }
               error={ error }
               onChangeErrors={ onChangeErrors }
               thumbnails={ thumbnails }
               isDemoMode={ isDemoMode }
               thumbnailIsSfw={ thumbnailIsSfw }
            />
         </div>
         {
            isOpenConfirmModal &&
            <ConfirmModal
               onCloseModal={ _ => this.openRemoveModal(null, false) }
               isCloseAction
               type='confirm'
               icon='delete'
               withoutCloseButton
               iconColor='error'
               // title={ `Delete ${ deleteItem?.type === 'zip' ? 'zip file' : 'photo' }?` }
               // description={ `Are you sure you want to delete this ${ deleteItem?.type === 'zip' ? 'zip file' : 'photo' }?` }
               title={ `Delete file?` }
               description={ `Are you sure you want to delete this file?` }
               buttonText='Yes, delete'
               confirmButtonProperties={ {
                  textClassName: '!text-error',
               } }
               action={ () => {
                  onDeleteImage(deleteItem)
                  this.openRemoveModal(null, false)
               } }
               iconSize='2xl'
               isMobile={ screenWidth < 901 }
            />
         }

      </>
   }
}

export default memo(PhotosetCreateContainer);
