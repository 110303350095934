const initialState = {
   isFetching: true,
   settings: {},
   isFetchingUpdate: false,
   updateError: {},
   isFetchingBySlug: true,
   settingBySlug: {},
   customNotificationModal: {
      resourceType: null,
      isOpen: false,
      action: null,
      buttonLoading: false,
      slug: null,
      resources: [],
      isFetchingNextPage: false,
      isFetching: true,
      currentPage: 0,
      pages: 0,
      isEmpty: false,
   },
};

export default initialState;
