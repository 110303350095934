export const FETCH_RESOURCES_REQUEST = 'resources/FETCH_RESOURCES_REQUEST';
export const FETCH_RESOURCES_COMPLETED = 'resources/FETCH_RESOURCES_COMPLETED';
export const FETCH_RESOURCES_FAILED = 'resources/FETCH_RESOURCES_FAILED';

export const DEFAULT_ACCESSES_REQUEST = 'resources/DEFAULT_ACCESSES_REQUEST';
export const DEFAULT_ACCESSES_COMPLETED = 'resources/DEFAULT_ACCESSES_COMPLETED';
export const DEFAULT_ACCESSES_FAILED = 'resources/DEFAULT_ACCESSES_FAILED';

export const SET_DOWNLOAD_BUTTON_LOADING = 'resources/SET_DOWNLOAD_BUTTON_LOADING';
export const SET_WRONG_DOWNLOAD_MODAL = 'resources/SET_WRONG_DOWNLOAD_MODAL';
export const SET_COUNTS_STATE = 'resources/SET_COUNTS_STATE';
export const CREATE_NEW_RESOURCE_TAG = 'resources/CREATE_NEW_RESOURCE_TAG';
