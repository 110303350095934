const initialState = {
   isFetching: true,
   isEmpty: false,
   isFilterFetching: false,
   isEmptyByFilter: false,
   isNewFetching: true,
   isEmptyByScroll: false,
   isFetchingAddNewCastMember: false,
   isFetchingNew: false,
   isSuccessAddNewCastMember: true,
   isFetchingUpdateCastMember: true,
   deleteCastMember: false,
   castMembersData: {
      data: [],
   },
   file: {},
   castMember: {},
   errors: {},
   adminCastMember: {},
};

export default initialState;
