import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Loader from 'common/components/elements/loader/circle';
import FontIcon from 'common/components/elements/icons';
import GenerateColor from 'common/utils/generateTextColor.js'
import './style.scss';


const TextSizes = {
   extraLarge: 'xl',
   large: 'lg',
   base: 'base',
   small: 'sm',
   extraSmall: 'xs',
};

const IconSizes = {
   tripleLarge: '3xl',
   doubleLarge: '2xl',
   extraLarge: 'xl',
   large: 'lg',
   base: 'base',
   small: 'sm',
   extraSmall: 'xs',
};

const BorderRadius = {
   none: 'none',
   large: 'lg',
   label: 'label',
   xlg: 'xlg',
   btn: 'btn',
   full: 'full',
};

const Button = ({
   text,
   textSize,
   textColor,
   textPosition,
   iconName,
   secondIconName,
   Icon,
   iconSize,
   fontIconSize,
   fontIconColor,
   iconFill,
   iconPosition,
   alignment,
   borderRadius,
   backgroundColor,
   isLoading,
   onClick,
   twoIcon,
   rightFixedIcon,
   padding,
   classNames,
   fontWeight,
   disabled,
   disabledBackgroundColor,
   loaderColor,
   primaryColor,
   textMarginX,
   textMarginExistSecondIcon,
   type,
   verticalAlignment,
   minWMax,
   lineHeight,
   isNewPrimaryButton,
   darkIconColor,
   textClassName,
   textStyles,
   accessbtn,
   iconClassName,
   disabledElClassName,
   ignoreDisabledState,
   hideIconOnLoading,
   loaderClassName,
   dataToolTipContent,
   dataToolTipId,
   hoverStateV2,
   loaderSize,
   ignoreHover,
   isDefaultActionPrimaryColor,
   id,
   fontIconClassName,
}) => {
   const [customColor, setCustomColorState] = useState(null);
   // eslint-disable-next-line no-param-reassign
   twoIcon = rightFixedIcon ? true : twoIcon;
   // eslint-disable-next-line no-param-reassign
   alignment = rightFixedIcon ? 'between' : alignment;

   let generateTextColor = textColor;
   let generateIcon = fontIconColor;
   let textDark = darkIconColor;
   if(primaryColor) {
      textDark = '';
   }
   if(primaryColor && backgroundColor !== 'transparent'){
      generateTextColor = GenerateColor.adaptColor(window.skinPrimaryColor)
      generateIcon = GenerateColor.adaptColor(window.skinPrimaryColor)
   }
   // let generateWaveColor = waveColor
   // if(primaryColor && backgroundColor === 'transparent') {
   //    generateWaveColor = GenerateColor.generateRGBColor(window.skinPrimaryColor, 0.8)
   // }
   const isActionPrimaryColor = isDefaultActionPrimaryColor || (window.skinPrimaryColor && '#231fff' === window.skinPrimaryColor.toLocaleLowerCase());
   const handleMouseEnter = () => {
      if(primaryColor && !isActionPrimaryColor && backgroundColor !== 'transparent'){
         let color = GenerateColor.generateRGBColor(window.skinPrimaryColor, 0.7);
         setCustomColorState(color);
      }
      // if(!primaryColor && color){
      //    setCustomColorState(GenerateColor.hangeShade(color, 11));
      // }
   };

   const handleMouseLeave = () => {
      if(customColor){
         setCustomColorState(null);
      }
   };
   const getLoaderColor = () => {
      if(primaryColor){
         const adaptColor = 'major' === GenerateColor.adaptColor(window.skinPrimaryColor) ? 'black' : 'white';
         return backgroundColor !== 'transparent' ? adaptColor : 'primary'
      }
      return null
   };

   return (
      <button
         style={ customColor ? {
            background: customColor,
         } : {} }
         onMouseEnter={ handleMouseEnter }
         onMouseLeave={ handleMouseLeave }
         disabled={ disabled }
         type={ type }
         onClick={ !isLoading ? onClick : null }
         className={ cx(
            `group outline-none focus:outline-none focus flex items-${ verticalAlignment } w-full relative select-none ${ minWMax ? 'min-w-max' : '' }
            justify-${ alignment } ${ borderRadius === 'default' ? 'rounded' : `rounded-${ BorderRadius[borderRadius] }` } p-${ padding }`,
            iconPosition === 'left' && 'flex-row-reverse',
            !customColor && !disabled && primaryColor && backgroundColor !== 'transparent' && !isActionPrimaryColor && 'skin-primary-background',
            !customColor && !disabled && primaryColor && backgroundColor !== 'transparent' && isActionPrimaryColor && 'hover:!bg-action-hover bg-action dark:bg-action-dark dark:hover:!bg-action-dark-hover',
            !primaryColor && `bg-${ backgroundColor }`,
            !disabled && primaryColor && backgroundColor !== 'transparent' && window.skinPrimaryColor && isActionPrimaryColor && `hover:!bg-action-hover dark:hover:!bg-action-dark-hover`,
            !disabled && !primaryColor && backgroundColor !== 'transparent' && `hover:bg-hover dark:hover:bg-grey-hover-dark`,
            !disabled && !primaryColor && backgroundColor === 'action' && `hover:!bg-action-hover dark:hover:!bg-action-dark-hover`,
            disabled && backgroundColor !== 'transparent' && 'bg-disabled dark:bg-disabled-dark',
            Boolean(hoverStateV2) && backgroundColor === 'transparent' && hoverStateV2,
            classNames
         ) }
         data-tooltip-content={ dataToolTipContent }
         data-tooltip-id={ dataToolTipId }
         id={ id }
      >
         {
            secondIconName && (
               <span className='flex'>
                  <FontIcon
                     name={ secondIconName }
                     size={ IconSizes[fontIconSize] }
                     color={ !disabled ? (accessbtn ? 'text-indigo-600' : !primaryColor || backgroundColor !== 'transparent' ? generateIcon : '') : '' }
                     darkColor={ accessbtn ? 'text-indigo-600' : textDark }
                     className={ cx(
                        accessbtn && 'text-indigo-600',
                        !disabled && primaryColor && backgroundColor === 'transparent' && !isActionPrimaryColor && 'skin-primary-text',
                        !disabled && primaryColor && backgroundColor === 'transparent' && isActionPrimaryColor && 'text-action dark:text-action-dark',
                        disabled && backgroundColor === 'transparent' && 'text-disabled dark:text-disabled-dark',
                        disabled && backgroundColor !== 'transparent' && 'text-major-dark',
                        disabled && backgroundColor === 'panel' && '!text-disabled'
                     ) }
                  />
               </span>
            )
         }
         {
            (twoIcon && !secondIconName) && (
               iconName ? (
                  <span className={ cx(
                     'flex button-two-icon',
                     rightFixedIcon && 'opacity-0'
                  ) }
                  >
                     <FontIcon
                        name={ iconName }
                        size={ IconSizes[fontIconSize] }
                        darkColor={ disabled ? textDark : '' }
                        className={ cx(
                           (isLoading || rightFixedIcon) && '!opacity-0',
                           !disabled && (!primaryColor || backgroundColor !== 'transparent') && generateIcon,
                           disabled && backgroundColor === 'transparent' && 'text-disabled dark:text-disabled-dark',
                           disabled && backgroundColor !== 'transparent' && 'text-major-dark',
                           disabled && backgroundColor === 'panel' && '!text-disabled',
                           !!fontIconClassName && fontIconClassName
                        ) }
                     />
                  </span>
               ) : (
                  Icon && (
                     <span className='button-two-icon'>
                        <Icon
                           fill={ iconFill }
                           width={ iconSize }
                           height={ iconSize }
                           className={ cx(
                              'mx-1',
                              (isLoading || rightFixedIcon) && 'opacity-0',
                              !disabled && (!primaryColor || backgroundColor !== 'transparent') && generateIcon,
                              disabled && backgroundColor === 'transparent' && 'text-disabled dark:text-disabled-dark',
                              disabled && backgroundColor !== 'transparent' && 'text-major-dark',
                              disabled && backgroundColor === 'panel' && '!text-disabled',
                              !!fontIconClassName && fontIconClassName
                           ) }
                        />
                     </span>
                  )
               )
            )
         }
         {
            text && (
               <div
                  className={ cx(
                     `${ secondIconName ? iconPosition === 'left' ? `w-full ml-${ textMarginExistSecondIcon }` : `w-full mr-${ textMarginExistSecondIcon }` : '' } mx-${ textMarginX }  ${ lineHeight ? lineHeight : 'leading-tight' }  font-${ fontWeight } text-${ TextSizes[textSize] } text-${ textPosition }`,
                     isLoading && 'opacity-0',
                     !disabled && accessbtn ? 'text-indigo-600' : !disabled && primaryColor && backgroundColor === 'transparent' && !isActionPrimaryColor && 'skin-primary-text',
                     !disabled && accessbtn ? 'text-indigo-600' : !disabled && primaryColor && backgroundColor === 'transparent' && isActionPrimaryColor && 'text-action dark:text-action-dark',
                     !disabled && (!primaryColor || backgroundColor !== 'transparent') && `text-${ generateTextColor }`,
                     textClassName && textClassName,
                     !hoverStateV2 && !disabled && backgroundColor === 'transparent' && !ignoreHover && `group-hover:underline`,
                     disabled && backgroundColor === 'transparent' && '!text-disabled dark:!text-disabled-dark',
                     disabled && backgroundColor !== 'transparent' && 'text-major-dark',
                     disabled && backgroundColor === 'panel' && '!text-disabled'

                  ) }
                  dangerouslySetInnerHTML={ { __html: text } }
                  style={ { ...textStyles } }
               />
            )
         }
         {
            !(hideIconOnLoading && isLoading) && (
               iconName ? (
                  <FontIcon
                     name={ iconName }
                     size={ IconSizes[fontIconSize] }
                     color={ !disabled ? (!primaryColor || backgroundColor !== 'transparent' ? generateIcon : '') : '' }
                     darkColor={ textDark }
                     className={ cx(
                        iconClassName,
                        isLoading && 'opacity-0',
                        !disabled && primaryColor && backgroundColor === 'transparent' && !isActionPrimaryColor && 'skin-primary-text',
                        !disabled && primaryColor && backgroundColor === 'transparent' && isActionPrimaryColor && 'text-action dark:text-action-dark',
                        disabled && backgroundColor === 'transparent' && 'text-disabled dark:text-disabled-dark',
                        disabled && backgroundColor !== 'transparent' && 'text-major-dark',
                        disabled && backgroundColor === 'panel' && '!text-disabled',
                        !!fontIconClassName && fontIconClassName
                     ) }
                  />
               ) : (
                  Icon && (
                     <Icon
                        fill={ iconFill }
                        width={ iconSize }
                        height={ iconSize }
                        className={ cx(
                           'mx-1',
                           isLoading && 'opacity-0',
                           !disabled && primaryColor && backgroundColor === 'transparent' && window.skinPrimaryColor && isActionPrimaryColor && `hover:!text-action-hover dark:hover:!text-action-dark-hover`,
                           !disabled && !primaryColor && backgroundColor === 'transparent' && `hover:text-hover dark:hover:text-hover-dark`,
                           disabled && backgroundColor === 'transparent' && 'text-disabled dark:text-disabled-dark',
                           disabled && backgroundColor !== 'transparent' && 'text-major-dark',
                           !!fontIconClassName && fontIconClassName
                        ) }
                     />
                  )
               )
            )
         }
         {
            isLoading && (
               <div className='button-loader'>
                  <Loader
                     color={ getLoaderColor() || loaderColor }
                     size={ loaderSize }
                     className={ loaderClassName }
                  />
               </div>
            )
         }
      </button>
   );
};

Button.defaultProps = {
   // text: 'Button',
   textSize: 'large',
   type: 'button',
   textColor: 'major-dark',
   textPosition: 'center',
   iconSize: 22,
   fontIconSize: 'extraLarge',
   fontIconColor: 'major-dark',
   iconFill: '#fff',
   iconPosition: 'right',
   alignment: 'center',
   verticalAlignment: 'center',
   borderRadius: 'none',
   backgroundColor: 'action',
   isLoading: false,
   twoIcon: false,
   rightFixedIcon: false,
   disabled: false,
   padding: 3,
   fontWeight: 'medium',
   loaderColor: 'white',
   primaryColor: false,
   textMarginX: '2',
   textMarginExistSecondIcon: '8',
   minWMax: false,
   isNewPrimaryButton: false,
   darkIconColor: 'major-dark',
   textClassName: '',
   textStyles: {},
   accessbtn: false,
   iconClassName: '',
   disabledElClassName: '',
   ignoreDisabledState: false,
   hideIconOnLoading: false,
   loaderClassName: '',
   dataToolTipContent: '',
   dataToolTipId: 'ams-top-tooltip',
   hoverStateV2: null,
   loaderSize: 7,
   fontIconClassName: '',
   isDefaultActionPrimaryColor: false,
};

Button.propTypes = {
   text: PropTypes.string,
   type: PropTypes.string,
   fontWeight: PropTypes.string,
   textSize: PropTypes.oneOf([
      'extraLarge', 'large', 'base', 'small', 'extraSmall',
   ]),
   textColor: PropTypes.string,
   textPosition: PropTypes.oneOf([
      'left', 'center', 'right',
   ]),
   iconName: PropTypes.string,
   secondIconName: PropTypes.string,
   Icon: PropTypes.any,
   iconSize: PropTypes.any,
   fontIconSize: PropTypes.oneOf([
      'tripleLarge', 'doubleLarge', 'extraLarge', 'large', 'base', 'small', 'extraSmall',
   ]),
   fontIconColor: PropTypes.string,
   iconFill: PropTypes.string,
   iconPosition: PropTypes.oneOf([
      'left', 'right',
   ]),
   alignment: PropTypes.oneOf([
      'center', 'end', 'start', 'between', 'around',
   ]),
   verticalAlignment: PropTypes.oneOf([
      'center', 'end', 'start', 'stretch',
   ]),
   borderRadius: PropTypes.oneOf([
      'none', 'large', 'label', 'xlg', 'btn', 'full',
   ]),
   backgroundColor: PropTypes.string,
   isLoading: PropTypes.bool,
   classNames: PropTypes.string,
   twoIcon: PropTypes.bool,
   rightFixedIcon: PropTypes.bool,
   padding: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
   onClick: PropTypes.func,
   disabled: PropTypes.bool,
   primaryColor: PropTypes.bool,
   loaderColor: PropTypes.string,
   disabledBackgroundColor: PropTypes.string,
   textMarginX: PropTypes.string,
   textMarginExistSecondIcon: PropTypes.string,
   minWMax: PropTypes.bool,
   isNewPrimaryButton: PropTypes.bool,
   lineHeight: PropTypes.string,
   darkIconColor: PropTypes.string,
   textClassName: PropTypes.string,
   textStyles: PropTypes.object,
   accessbtn: PropTypes.bool,
   iconClassName: PropTypes.string,
   disabledElClassName: PropTypes.string,
   ignoreDisabledState: PropTypes.bool,
   hideIconOnLoading: PropTypes.bool,
   loaderClassName: PropTypes.string,
   dataToolTipContent: PropTypes.string,
   dataToolTipId: PropTypes.string,
   hoverStateV2: PropTypes.string,
   loaderSize: PropTypes.any,
   ignoreHover: PropTypes.any,
   isDefaultActionPrimaryColor: PropTypes.bool,
   id: PropTypes.string,
   fontIconClassName: PropTypes.string,
};

export default Button;
