import React from 'react'
import PropTypes from 'prop-types'
import SwitchItem from 'common/components/modules/multiply-switch/item-v2';

import './style.scss'

const ChatCoversationV2 = ({
   dialogue,
   updateContentState,
   activeIndex,
   // chatActiveConverstaion,
   publicConverstaion,
   unreadPMChatMessagesCount,
   groupConverstaion,
   style,
   isMobile,
}) => {
   return (
      <div
         style={ isMobile ? {
            ...style,
         } : {} }
         className='flex flex-col h-full w-full relative'>
         <div
            style={ {
               background: '#FAFBFC',
               border: '1px solid #F2F4F7',
               padding: '6px',
               minHeight: '54px',
            } }
            className={ `rounded-lg flex items-center justify-center overflow-x-auto ${ isMobile ? 'max-w-full mx-4' : 'max-w-full overflow-x-auto mx-auto' }` }>
            <div className='flex w-full'>
               <SwitchItem
                  checked={ publicConverstaion.chat_room_id === activeIndex }
                  onClick={ () => {
                     if(publicConverstaion.chat_room_id === activeIndex) return;
                     updateContentState(publicConverstaion.chat_room_id)
                  } }
                  label='Livestream chat'
                  className='whitespace-nowrap'
               >
                  <UnreadMessagesCount count={ publicConverstaion.unread_messages_count || 0 } />
               </SwitchItem>
               <SwitchItem
                  checked={ 'chat' === activeIndex }
                  onClick={ () => {
                     if('chat' === activeIndex) return;
                     updateContentState('chat')
                  } }
                  label='Private chats'
                  className='whitespace-nowrap mx-2'
               >
                  <UnreadMessagesCount count={ unreadPMChatMessagesCount || 0 } />
               </SwitchItem>
               <SwitchItem
                  checked={ 'group' === activeIndex  }
                  onClick={ () => {
                     if('group' === activeIndex) return;
                     updateContentState('group')
                  } }
                  label={ groupConverstaion.conversation_name }
                  className='whitespace-nowrap'
               >
                  <UnreadMessagesCount count={ groupConverstaion.unread_messages_count || 0 } />
               </SwitchItem>

            </div>
         </div>
         {
            dialogue && (
               dialogue
            )
         }
      </div>
   )
}

const UnreadMessagesCount = ({ count }) => {
   if(!count || count < 1) return null
   return (

      <div className='chat-left-new-count bg-error rounded-full flex items-center justify-center ml-2'>
         <span className='text-major-dark text-xs font-medium'> { count > 100 ? '99+' : count} </span>
      </div>
   )
}

UnreadMessagesCount.propTypes = {
   count: PropTypes.number,
};
ChatCoversationV2.propTypes = {
   dialogue: PropTypes.any,
   publicConverstaion: PropTypes.object,
   updateContentState: PropTypes.func,
   activeIndex: PropTypes.any,
   // chatActiveConverstaion: PropTypes.object,
   groupConverstaion: PropTypes.object,
   unreadPMChatMessagesCount: PropTypes.number,
   style: PropTypes.object,
   isMobile: PropTypes.bool,
}

export default ChatCoversationV2

