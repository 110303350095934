const initialState = {
   inputs: {
      email: '',
      password: '',
   },
   isInProgress: false,
   errors: {},
};

export default initialState;
