const initialState = {
   isFetching: true,
   isFetchingDetails: false,
   isFetchingByFilter: false,
   isFetchingNew: false,
   isEmptyByFilter: false,
   isFetchingExportCSV: false,
   isEmpty: false,
   data: {},
   details: {},

   // wallet activity
   isFetchingWalletActivity: true,
   isFetchingWalletActivityDetails: false,
   isFetchingWalletActivityByFilter: false,
   isFetchingWalletActivityNew: false,
   isEmptyWalletActivityByFilter: false,
   isFetchingWalletActivityExportCSV: false,
   isEmptyWalletActivity: false,
   walletActivityData: {},
   walletActivityDetails: {},
};

export default initialState;
