import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '../../avatar'

function ChatUserAvatar({
   avatarSrc,
   size,
   groupSize,
   position,
   onClickAvatar,
   className,
   username,
   showOnlyImage,
   firstLetterClassName,
}) {
   return (
      <div
         role='presentation'
         onClick={ () => {
            if(!!onClickAvatar) {
               onClickAvatar()
            }
         } }
         style={ { minWidth: '40px' } }
         className={ ` ${ className } flex justify-center items-center w-${ size } h-${ size } rounded-full relative ${ onClickAvatar ? 'cursor-pointer' : '' }` }>
         {
            showOnlyImage && !Array.isArray(avatarSrc) ? (
               <img className='h-full min-w-full object-center object-cover rounded-full' src={ avatarSrc } alt='' />

            ) : (
               !!Array.isArray(avatarSrc) ? (
                  <>
                     {
                        avatarSrc.map((img, index) => {
                           if(index > 2){
                              return null
                           }

                           return (
                              <div
                                 style={ {
                                    bottom: `${ index * position }px`,
                                    left: `${ index  * position }px`,
                                    width: groupSize,
                                    height: groupSize,
                                 } }
                                 key={ index.toString() }
                                 className='border absolute rounded-full overflow-hidden p-px flex justify-center items-center border-white'>
                                 <Avatar
                                    avatarSrc={ img }
                                    className='absolute'
                                 />
                              </div>
                           )
                        })
                     }
                  </>
               ) : (
                  Boolean(username) ? (
                     <Avatar
                        avatarSrc={ avatarSrc }
                        username={  Boolean(username) ? username : null }
                        // firstLetterClassName='!text-2xl'
                        firstLetterClassName={ firstLetterClassName }
                     />
                  ) : (
                     <img className='h-full min-w-full object-center object-cover rounded-full' src={ avatarSrc } alt='' />
                  )
               )
            )
         }
      </div>
   )
}

ChatUserAvatar.propTypes = {
   avatarSrc: PropTypes.any,
   size: PropTypes.string,
   position: PropTypes.string,
   groupSize: PropTypes.string,
   onClickAvatar: PropTypes.func,
   className: PropTypes.string,
   username: PropTypes.string,
   showOnlyImage: PropTypes.bool,
   firstLetterClassName: PropTypes.string,
}
ChatUserAvatar.defaultProps = {
   size: '10',
   className: '',
   groupSize: '33.85px',
   position: '3.08',
   onClickAvatar: () => {},
   showOnlyImage: false,
   firstLetterClassName: '',
}
export default ChatUserAvatar


