
import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const SpinnerAnimation = ({
   text,
   className,
}) => {
   return (
      <div className={ `flex items-center ${ className }` }>
         <div className='spinner mr-1'>
            <div className='bounce1 skin-secondary-background' />
            <div className='bounce2 skin-secondary-background' />
            <div className='bounce3 skin-secondary-background' />
         </div>
         {
            text && (
               <span className='text-xs text-secondary dark:text-secondary-dark font-medium'> {text} </span>
            )
         }
      </div>

   );
}
SpinnerAnimation.propTypes = {
   text: PropTypes.string,
   className: PropTypes.string,
};
SpinnerAnimation.defaultProps = {
   className: '',
}
export default SpinnerAnimation;


