import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';

const MemberBlocekdMutedLabels = ({
   muted,
   banned,
   disabledNotifications,
   id,
   className,
   size,
   iconSize,
}) => {
   return (
      <>
         <div className={ `flex h-4 ${ className }` }>
            {
               !!muted && (
                  <>
                     <div
                        data-tooltip-content='Member cannot send messages because they’re muted'
                        data-tooltip-id='ams-bottom-tooltip'
                        className={ `${ size } mx-1 inline-flex items-center justify-center` }
                     >
                        <Icon name='chat-mute' size={ iconSize } color='placeholder' />
                     </div>

                  </>
               )
            }
            {
               !!disabledNotifications && (
                  <>
                     <div
                        data-tooltip-content='Notifications for this member are disabled'
                        data-tooltip-id='ams-bottom-tooltip'
                        className={ `${ size } mx-1 inline-flex items-center justify-center` }
                     >
                        <Icon name='off-notificartion' size={ iconSize } color='placeholder' />
                     </div>

                  </>
               )
            }
            {
               !!banned && (
                  <>
                     <div
                        data-tooltip-content='Member is blocked and does not have access to your site'
                        data-tooltip-id='ams-bottom-tooltip'
                        className={ `${ size } ml-1 inline-flex items-center justify-center` }
                     >
                        <Icon name='block' size={ iconSize } color='error' darkColor='error-dark' />
                     </div>

                  </>
               )
            }
         </div>
      </>
   );
};

MemberBlocekdMutedLabels.defaultProps = {
   size: 'w-4 h-4',
   iconSize: 'sm',
}
MemberBlocekdMutedLabels.propTypes = {
   banned: PropTypes.bool,
   muted: PropTypes.bool,
   disabledNotifications: PropTypes.bool,
   id: PropTypes.any,
   className: PropTypes.string,
   size: PropTypes.string,
   iconSize: PropTypes.string,
};


export default React.memo(MemberBlocekdMutedLabels);
