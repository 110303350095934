// memberships for filter
export const FETCH_MEMBERSHIPS_START = "statistics/FETCH_MEMBERSHIPS_START";
export const FETCH_MEMBERSHIPS_COMPLETED = "statistics/FETCH_MEMBERSHIPS_COMPLETED";
export const FETCH_MEMBERSHIPS_FAILED = "statistics/FETCH_MEMBERSHIPS_FAILED";

// REVENUE

export const INIT_REVENUE_START = "statistics/INIT_REVENUE_START";
export const INIT_REVENUE_COMPLETED = "statistics/INIT_REVENUE_COMPLETED";
export const INIT_REVENUE_CHART_BY_FILTER_START = "statistics/INIT_REVENUE_CHART_BY_FILTER_START";
export const INIT_REVENUE_CHART_BY_FILTER_COMPLETED  = "statistics/INIT_REVENUE_CHART_BY_FILTER_COMPLETED";
export const INIT_REVENUE_CHART_BY_FILTER_FAILED = "statistics/INIT_REVENUE_CHART_BY_FILTER_FAILED";

// TOP SPENDING MEMBERS
export const INIT_TOP_SPENDING_MEMBERS_START = "statistics/INIT_TOP_SPENDING_MEMBERS_START";
export const INIT_TOP_SPENDING_MEMBERS_COMPLETED = "statistics/INIT_TOP_SPENDING_MEMBERS_COMPLETED";
export const INIT_TOP_SPENDING_MEMBERS_FAILED = "statistics/INIT_TOP_SPENDING_MEMBERS_FAILED";
export const UPDATE_TOP_SPENDING_MEMBER_USERNAME = "statistics/UPDATE_TOP_SPENDING_MEMBER_USERNAME";
export const UPDATE_TOP_SPENDING_MEMBER = "statistics/UPDATE_TOP_SPENDING_MEMBER"

// ENGAGEMENT
export const INIT_ENGAGEMENT_START = "statistics/INIT_ENGAGEMENT_START";
export const INIT_ENGAGEMENT_COMPLETED = "statistics/INIT_ENGAGEMENT_COMPLETED";
export const INIT_ENGAGEMENT_CHART_BY_FILTER_START = "statistics/INIT_ENGAGEMENT_CHART_BY_FILTER_START";
export const INIT_ENGAGEMENT_CHART_BY_FILTER_COMPLETED = "statistics/INIT_ENGAGEMENT_CHART_BY_FILTER_COMPLETED";
export const INIT_ENGAGEMENT_CHART_BY_FILTER_FAILED = "statistics/INIT_ENGAGEMENT_CHART_BY_FILTER_FAILED";

// CONTENT LIST
export const INIT_CONTENT_LIST_START = "statistics/INIT_CONTENT_LIST_START";
export const INIT_CONTENT_LIST_COMPLETED = "statistics/INIT_CONTENT_LIST_COMPLETED";
export const INIT_CONTENT_LIST_FAILED = "statistics/INIT_CONTENT_LIST_FAILED";

// ACQUISITION
export const INIT_ACQUISITION_START = "statistics/INIT_ACQUISITION_START";
export const INIT_ACQUISITION_COMPLETED = "statistics/INIT_ACQUISITION_COMPLETED"