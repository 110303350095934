import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';
// import { getSetDifference } from "common/utils/utils";

let reducersMap = {
   // all members data /
   [types.FETCH_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const { payload: { membersData, ids, isEmptyFilters } } = action;
      let data = membersData
      let emptyState = {}

      if(!!ids) {
         data = {
            ...membersData,
            data: membersData?.data?.filter(member => !ids.includes(member?.id)),
         }
      }
      if(isEmptyFilters) {
         emptyState = {
            isEmpty: false,
            isEmptyByFilter: data.data.length !== 0 ? false : isEmptyFilters,
         }
         if(data.data.length === 0) {
            emptyState.isEmpty = true
         }
      } else {
         emptyState = {
            isEmpty: false,
            isEmptyByFilter: data.data.length === 0,
         }
      }
      return {
         ...state,
         isFetching: false,
         membersData: {
            ...data,
         },
         ...emptyState,
      }
   },

   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   // next page data /
   [types.NEW_FETCH_REQUEST]: (state, action) => {
      return {
         ...state,
         isNewFetching: true,
      }
   },
   [types.NEW_FETCH_DETAILS_COMPLETED]: (state, action) => {
      const { payload: { membersData } } = action;
      const data = membersData.data;
      return {
         ...state,
         isNewFetching: false,
         membersData: {
            ...membersData,
            data: [...state.membersData.data, ...data],
         },

      }
   },
   [types.NEW_FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isNewFetching: false,
      }
   },

   //member data /
   [types.FETCH_MEMBER_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_MEMBER_COMPLETED]: (state, action) => {
      const member = action.payload;
      let membersData = { ...state.membersData };
      if(!!membersData?.data && !!membersData?.data.length) {
         let data = membersData.data?.map(item => {
            if(item.id === member.id) {
               return { ...member };
            }
            return item;
         })
         membersData  = {
            ...membersData,
            data: [
               ...data,
            ],
         };
      }
      return {
         ...state,
         isFetching: false,
         member: { ...member },
         membersData: { ...membersData },
      }
   },
   [types.FETCH_MEMBER_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   // filtered members data /
   [types.FILTER_MEMBERS_REQUEST]: (state) => {
      return {
         ...state,
         isFilterFetching: true,
      }
   },
   [types.FILTER_MEMBERS_COMPLETED]: (state, action) => {
      const { payload: { membersData, idsToExcept } } = action;

      let membersDt = membersData
      if(!!idsToExcept) {
         membersDt = {
            ...membersData,
            data: membersData?.data?.filter(member => !idsToExcept.includes(member?.id)),
            total: membersData?.data?.filter(member => !idsToExcept.includes(member?.id))?.length,
         }
      }

      return {
         ...state,
         isFilterFetching: false,
         membersData: {
            ...membersDt,
         },
         isEmptyByFilter: membersDt.data.length === 0,
      }
   },
   [types.FILTER_MEMBERS_FAILED]: (state, action) => {
      return {
         ...state,
         isFilterFetching: false,
         isEmptyByFilter: false,
      }
   },

   // member statistics /
   [types.FETCH_STATISTICS_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingStatistics: true,
      }
   },
   [types.FETCH_STATISTICS_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingStatistics: false,
         statistics: action.payload,
      }
   },
   [types.FETCH_STATISTICS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingStatistics: false,
      }
   },

   // memberships data /
   [types.FETCH_MEMBERSHIPS_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingMemberships: true,
      }
   },
   [types.FETCH_MEMBERSHIPS_COMPLETED]: (state, action) => {
      return {
         ...state,
         memberships: action.payload,
         isFetchingMemberships: false,
      }
   },
   [types.FETCH_MEMBERSHIPS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingMemberships: false,
      }
   },

   //set free member to add id
   [types.SET_FREE_MEMBER_TO_ADD_ID]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         freeMemberToAddIds: payload,
      }
   },

   //set free member to add membership
   [types.SET_FREE_MEMBER_TO_ADD_MEMBERSHIP]: (state, action) => {
      return {
         ...state,
         freeMemberToAddMembership: action.payload,
      }
   },

   // add free member
   [types.ADD_FREE_MEMBER_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingAddFreeMember: true,
      }
   },
   [types.ADD_FREE_MEMBER_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingAddFreeMember: false,
         isFetchingStatistics: false,
      }
   },
   [types.ADD_FREE_MEMBER_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingAddFreeMember: false,
      }
   },

   // add new person /
   [types.ADD_NEW_PERSON_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingAddNewPerson: true,
         errors: {},
      }
   },
   [types.ADD_NEW_PERSON_COMPLETED]: (state, action) => {
      const { member } = action.payload

      let data = state?.membersData

      if(!!member) {
         data = {
            ...state.membersData,
            data: [
               ...state?.membersData?.data,
               member,
            ],
         }
      }

      return {
         ...state,
         isFetchingAddNewPerson: false,
         isEmpty: false,
         membersData: { ...data },
      }
   },
   [types.ADD_NEW_PERSON_FAILED]: (state, action) => {
      const { payload: { errors } } = action;
      return {
         ...state,
         isFetchingAddNewPerson: false,
         errors,
      }
   },

   [types.UPDATE_MEMBERS_COMPLETED]: (state, action) => {
      const { payload: {
         id,
         data,
      }  } = action;
      const newData = [...state.membersData.data].map((elm) => {
         let { ...item } = elm
         if(item.id === id) {
            item = {
               ...item,
               ...data,
            }
         }
         return item
      })
      return {
         ...state,
         membersData: {
            ...state.membersData,
            data: newData,
         },
         member: {
            ...state.member,
            ...data,
         },
         isOpenUsernameModal: false,
         updateErrors: {},
      }
   },
   [types.UPDATE_MEMBERS_BY_UUID]: (state, action) => {
      const { payload: {
         uuid,
         data,
      }  } = action;
      let newState = {}
      if(state.membersData && state.membersData.data && !!state.membersData.data.find(member => member.uuid === uuid)) {
         const newData = [...state.membersData.data].map((elm) => {
            let { ...item } = elm
            if(item.uuid === uuid) {
               item = {
                  ...item,
                  ...data,
               }
            }
            return item
         })
         newState = {
            membersData: {
               ...state.membersData,
               data: newData,
            },
         }
      }

      if(state.member && state.member.uuid === uuid) {
         newState = {
            ...newState,
            member: {
               ...state.member,
               ...data,
            },
         }
      }
      return {
         ...state,
         ...newState,
      }
   },
   [types.UPDATE_MULTIPLE_MEMBERS_BY_UUIDS]: (state, action) => {
      const { payload: {
         uuids,
         isSelectAll,
         data,
      }  } = action;
      let newState = {}
      let membersData = { ...state.membersData };
      if(membersData?.data) {
         const newData = membersData.data.map((item) => {
            if((isSelectAll && !uuids.includes(item.uuid)) || (!isSelectAll && uuids.includes(item.uuid))) {
               item = {
                  ...item,
                  ...data,
               }
            }
            return item
         })
         newState = {
            membersData: {
               ...state.membersData,
               data: newData,
            },
         }
      }
      if(!!Object.keys(state?.member).length && ((isSelectAll && !uuids.includes(state.member.uuid)) || (!isSelectAll && uuids.includes(state.member.uuid)))) {
         newState = {
            ...newState,
            member: {
               ...state.member,
               ...data,
            },
         }
      }
      return {
         ...state,
         ...newState,
      }
   },
   [types.UPDATE_MEMBERS_FAILED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         updateErrors: payload,
      }
   },
   [types.SET_MEMBER_USERNAME_MODAL]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isOpenUsernameModal: payload,
         updateErrors: {},
      }
   },

   [types.REMOVE_TAG_TO_MEMBER]: (state, action) => {
      const { member_id, tag_id } = action.payload;
      let members = [...state.membersData.data];
      let newData = members.map(item => {
         if(item.id === member_id && 'function' === typeof item?.member_tags?.filter) {
            let memberTags = item.member_tags.filter(el => el.id !== tag_id);
            return {
               ...item,
               member_tags: [...memberTags],
            }
         }
         return item;
      });

      let member = { ...state.member };
      if(!!Object.keys(member).length && 'function' === typeof member?.member_tags?.filter) {
         let memberTags = member.member_tags.filter(el => el.id !== tag_id);
         member = {
            ...member,
            member_tags: [...memberTags],
         }
      }
      return {
         ...state,
         membersData: {
            ...state.membersData,
            data: [...newData],
         },
         member,
      }
   },

   [types.CHANGE_TAG_TO_MEMBER]: (state, action) => {
      const {
         members_ids,
         membersTag,
         // tag_ids,
         memberTagIds,
         updateIsAll,
         excludedIds,
         isBulkEdit,
         allTags,
         addDataAfterCreate,
      } = action.payload;
      let members = [...state.membersData.data];
      let newData = members.map(element => {
         let { ...item } = element;
         if(members_ids.includes(item.id) || (isBulkEdit && updateIsAll && !excludedIds.includes(item.id))) {
            let badge = null;
            let lastFourTags = [];
            let oldMembersTags = [];
            let oldMembersTagsId = [];
            if(isBulkEdit){
               oldMembersTagsId = item?.member_tags?.tags_ids || [];
               oldMembersTags = item?.memberTags ||  [...allTags]?.filter(el => oldMembersTagsId.includes(el.id));
            }
            let uniqMembersTag = [...oldMembersTags, ...membersTag];
            let uniqMembersTagIds = [...oldMembersTagsId, ...memberTagIds];

            uniqMembersTag = [...new Set(uniqMembersTag.map(item => item))];
            uniqMembersTagIds = [...new Set(uniqMembersTagIds.map(item => item))];

            if(addDataAfterCreate && !addDataAfterCreate?.rule) {
               uniqMembersTag = [...uniqMembersTag, addDataAfterCreate]
               uniqMembersTagIds = [...uniqMembersTagIds, addDataAfterCreate?.id]
            }

            if(uniqMembersTag && uniqMembersTag.length > 0){
               lastFourTags = uniqMembersTag.slice(-4)//.reverse();
            }

            let customTag = null

            uniqMembersTag.forEach(el => {
               if(el.rule && el.show_icon_as_badge === 1) {
                  badge = el.emoji
                  customTag = el.emoji
               } else if(!el.rule && el.show_icon_as_badge === 1 && !customTag) {
                  badge = el.emoji
               }
            })
            return {
               ...item,
               memberTags: uniqMembersTag,
               member_tags: {
                  tags_ids: uniqMembersTagIds,
                  last_four_tags: lastFourTags,
                  tags_count: uniqMembersTagIds.length,
                  badge: customTag || badge,
               },
            }
         }

         return item;
      })
      let newState = {}
      let updateMember = { ...state.member }
      if(action.payload.members_ids.includes(updateMember.id)){
         newState = {
            ...newState,
            member: {
               ...state.member,
               member_tags: membersTag?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)),
            },
         }
         updateMember = {
            ...updateMember,
            member_tags: membersTag?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)),
         }
      }

      return {
         ...state,
         membersData: {
            ...state.membersData,
            data: [...newData],
         },
         ...newState,
      }
   },

   // fetch member wallet history
   [types.FETCH_MEMBER_WALLET_HISTORY_START]: (state) => {
      return {
         ...state,
         isFetchingWalletHistory: true,
      }
   },
   [types.FETCH_MEMBER_WALLET_HISTORY_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetchingWalletHistory: false,
         walletHistory: { ...payload },
         isEmptyWalletHistory: payload.data.length === 0,
      }
   },

   [types.FETCH_MEMBER_WALLET_HISTORY_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingWalletHistory: false,
      }
   },

   // new fetch member wallet history
   [types.NEW_FETCH_MEMBER_WALLET_HISTORY_START]: (state) => {
      return {
         ...state,
         isNewFetchingWalletHistory: true,
      }
   },
   [types.NEW_FETCH_MEMBER_WALLET_HISTORY_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isNewFetchingWalletHistory: false,
         walletHistory: {
            ...payload,
            data: [
               ...state.walletHistory.data,
               ...payload.data,
            ],
         },
      }
   },

   [types.NEW_FETCH_MEMBER_WALLET_HISTORY_FAILED]: (state, action) => {
      return {
         ...state,
         isNewFetchingWalletHistory: false,
      }
   },

   // fetch member tags
   [types.FETCH_MEMBER_TAGS_START]: (state) => {
      return {
         ...state,
         isFetchingMemberTags: true,
      }
   },
   [types.FETCH_MEMBER_TAGS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetchingMemberTags: false,
         memberTags: [...payload],
         isEmptyMemberTags: payload.length === 0,
      }
   },

   [types.FETCH_MEMBER_TAGS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingMemberTags: false,
      }
   },
   [types.SET_ACTIVE_MEMBER]: (state, action) => {
      const { data } = action.payload
      return {
         ...state,
         activeMember: data,
      }
   },

   // Member notes
   [types.ADD_NOTE_START]: (state, action) => {
      return {
         ...state,
         isFetchingAddNote: true,
      }
   },
   [types.ADD_NOTE_COMPLETED]: (state, action) => {
      const { payload: { memebrId, data } } = action;
      let newState = {};
      if(state?.member?.id === memebrId){
         let oldNotes = state?.member?.notes || [];
         oldNotes = [...oldNotes].map((note) => {
            const { ...el } = note;
            if('add' === el.flag){
               el.flag = null;
            }
            return el;
         });
         newState = {
            member: {
               ...state.member,
               notes: [
                  { ...data, flag: 'add' },
                  ...oldNotes,
               ],
            },
         }
      }
      return {
         ...state,
         ...newState,
         isFetchingAddNote: false,
      }
   },
   [types.ADD_NOTE_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingAddNote: false,
      }
   },
   [types.UPDATE_NOTE_START]: (state, action) => {
      return {
         ...state,
         isFetchingUpdateNote: true,
      }
   },
   [types.UPDATE_NOTE_COMPLETED]: (state, action) => {
      const { payload: { memebrId, noteId, data } } = action;
      let newState = {};
      if(state?.member?.id === memebrId){
         const oldNotes = state?.member?.notes || [];
         newState = {
            member: {
               ...state.member,
               notes: [...oldNotes].map(item => {
                  let elm = { ...item }
                  if(elm.id === noteId){
                     elm = {
                        ...elm,
                        ...data,
                     }
                  }
                  return elm
               }),
            },
         }
      }
      return {
         ...state,
         ...newState,
         isFetchingUpdateNote: false,
      }
   },
   [types.UPDATE_NOTE_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingUpdateNote: false,
      }
   },
   [types.DELETE_NOTE_START]: (state, action) => {
      return {
         ...state,
         isFetchingDeleteNote: true,
      }
   },
   [types.DELETE_NOTE_COMPLETED]: (state, action) => {
      const { memebrId, noteId } = action.payload;
      let newState = {};

      if(state?.member?.id === memebrId){
         const oldNotes = state?.member?.notes || [];
         newState = {
            member: {
               ...state.member,
               notes: [...oldNotes].filter(note => note.id !== noteId),
            },
         }
      }
      return {
         ...state,
         ...newState,
         isFetchingDeleteNote: false,
      }
   },
   [types.DELETE_NOTE_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingDeleteNote: false,
      }
   },
   [types.CLEAR_MEMBERS_DATA]: (state) => {
      return {
         ...state,
         membersData: {
            ...state.membersData,
            data: [],
         },
      }
   },
};

export default createReducer(initialState)(reducersMap);
