import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import { copyText } from 'common/utils/utils.js';
import intercomMoreInfo, { MORE_INFO_ON_SITE_APPROVAL } from 'admin/utils/IntercomMoreInfo';
import toast from 'common/utils/toast';
import CreateEditModal from 'common/components/modules/modal-wrapper';
import TruncateText from 'common/components/elements/truncate-text';
import { getSiteNameAbbreviatur } from 'common/utils/helper';

const OnboardingDialog = ({
   type,
   adminEmail,
   link,
   onCloseModal,
   onClickSubmitForReview,
   onClickLaunchSite,
   reviewSubmitted,
   siteLaunched,
   screenWidth,
}) => {

   const modalTypes = {
      ready_to_submit_for_review: {
         title: 'Are you sure you want to submit your site for review?',
         description: `We ask that you submit your site for review only when you are ready to launch, as you’ll be required to launch your site within 48 hours of receiving approval.`,
         primaryButton: {
            text: 'Yes, I want to submit for review',
            click: () => onClickSubmitForReview(),
         },
         secondaryButton: {
            text: 'No, I’ll submit later',
            click: () => onCloseModal(),
         },
         icon: 'domain-redirect',
         color: 'action',
      },
      review_submitted: {
         title: 'Submitted',
         description: `Your site has been submitted for review by the ${ getSiteNameAbbreviatur() } team. We will notify you at ${ adminEmail } when we have an update.`,
         primaryButton: {
            text: 'More info on site approval',
            icon: 'attention-outline',
            click: () => intercomMoreInfo(MORE_INFO_ON_SITE_APPROVAL),
            intercomClassName: 'intercom-more-info-on-site-approval',
         },
         secondaryButton: {
            text: 'Done',
            click: () => reviewSubmitted(),
         },
         icon: 'done',
         color: 'success',
      },

      launch_site: {
         title: 'Launch your site?',
         description: 'Are you sure you want to launch your site?',
         primaryButton: {
            text: 'Yes, launch my site',
            click: () => onClickLaunchSite(),
         },
         secondaryButton: {
            text: 'Cancel',
            click: () => onCloseModal(),
         },
         icon: 'launch',
         color: 'action',
      },

      congratulations: {
         title: 'Congratulations!',
         description: 'Your site is now live. Copy and share the link to announce your site launch.',
         primaryButton: {
            text: 'Done',
            click: () => siteLaunched(),
         },
         icon: 'done',
         color: 'success',
         withoutCancelButton: true,
      },
   }
   const modal = modalTypes[type] || {}

   return (
      <CreateEditModal
         icon={ modal.icon }
         iconColor={ modal.color }
         title={ modal.title }
         description={ modal.description }
         descriptionColorClass='text-secondary'
         type='confirm'
         buttonText={ modal?.primaryButton?.text || 'Ok' }
         cancelText={ modal?.secondaryButton?.text || 'Ok' }
         buttonIconName={ modal?.primaryButton?.icon || null }
         cancelButtonAction={ () => {
            if(modal?.secondaryButton?.click){
               modal?.secondaryButton.click()
            }
         } }
         onCloseModal={ onCloseModal }
         action={ () => {
            if(modal?.primaryButton?.click){
               modal?.primaryButton.click()
            }
         } }
         primaryButtonClassName={ modal?.primaryButton?.intercomClassName || '' }
         isMobile={  screenWidth < 701  }
         contentWidth='375px'
         withoutCancelButton={ modal.withoutCancelButton }
         buttonClassName='mt-8'
      >
         {
            type === 'congratulations' && (
               <div className='flex pt-4 w-full'>
                  <div className='border border-divider rounded-lg h-10 flex items-center pl-3.5 pr-3 w-full justify-between'>
                     <TruncateText
                        text={ `http://${ link }` }
                        textClass='leading-tight text-secondary mr-2'
                     />
                     <div className='h-4'>
                        <Button
                           iconName='copy'
                           fontIconSize='base'
                           fontIconColor='placeholder'
                           backgroundColor='transparent'
                           padding='none'
                           classNames='h-4 w-4'
                           onClick={ _ => {
                              copyText(`http://${ link }`);
                              toast.info('Link has been copied')
                           } }
                        />
                     </div>
                  </div>
               </div>
            )
         }
      </CreateEditModal>
   )
};


OnboardingDialog.propTypes = {
   type: PropTypes.string,
   adminEmail: PropTypes.string,
   link: PropTypes.string,
   onCloseModal: PropTypes.func,
   onClickSubmitForReview: PropTypes.func,
   onClickLaunchSite: PropTypes.func,
   reviewSubmitted: PropTypes.func,
   siteLaunched: PropTypes.func,
   screenWidth: PropTypes.number,
};
OnboardingDialog.defaultProps = {
   type: 'review_submitted',
   className: '',
   onCloseModal: () => {},
   onClickSubmitForReview: () => {},
   onClickLaunchSite: () => {},
   reviewSubmitted: () => {},
   siteLaunched: () => {},
};

export default React.memo(OnboardingDialog);
