import React, {  useEffect, useRef, useState } from 'react'
import '../style.scss'
import PropTypes from 'prop-types';
import Filter from 'common/components/responsive/filter';
import Card from '../card'
import CircleLoader from 'common/components/elements/loader/circle';
import EmptyStates from 'common/components/modules/empty-states';
import PageLoader from 'common/components/modules/page-loading';
import Icon from 'common/components/elements/icons';
import cx from 'classnames'
import { createPortal } from 'react-dom';
import Intercom from 'common/utils/Intercom';
import { ARTICLE_ID_ON_THE_VAULT } from 'common/utils/intercom-articles';

function VaultContent({
   isInsideModal,
   desktopType,
   data,
   isFetchingByFilter,
   isEmptyAllVaultByFilter,
   onSimpleFilter,
   selectedCards,
   showNewStyles,
   isEmpty,
   isFetching,
   onFilterByType,
   filters,
   onChangeSelected,
   isNewfetchingAllVaults,
   selected,
   setSelectedItems,
   onSelectCard,
   contentType,
   screenWidth,
   bundleId,
   updateMessageUnlockDetails,
}) {

   const containerRef = useRef(null)

   useEffect(() => {
      if(activeFilters?.[0] === 'all_vault') {
         if(selected && selected.length !== 0) {
            let newData = data?.data?.filter(i => selected.includes(i.id))
            setSelectedItems(newData)
            onSelectCard(newData)
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data])

   const types = [
      { name: 'All', value: 'all_vault', icon: '' },
      { name: 'Photos', value: 'image', icon: 'photosets' },
      { name: 'Videos', value: 'video', icon: 'videos' },
   ]

   const [activeFilters, setActiveFilters] = useState(['all_vault'])

   const onChangeFilter = (value) => {
      let actives = [...activeFilters]
      if(!activeFilters.includes(value)){
         actives.push(value)
      }
      if(value === 'all_vault') {
         setActiveFilters(['all_vault'])
      } else {
         if(activeFilters.includes(value)) {
            let idx = actives.findIndex(el => el === value)
            actives.splice(idx, 1)
         }
         if(actives.includes('all_vault')) {
            let idx = actives.findIndex(el => el === 'all_vault')
            actives.splice(idx, 1)
         }
         if(!actives.length) {
            actives.push('all_vault')
         }
         setActiveFilters(actives)
      }
      if(actives.length === 1 && activeFilters.length === 1 && activeFilters[0] === actives[0]) {
         return
      }

      onFilterByType(actives)
   }

   useEffect(() => {
      if(isInsideModal && !!containerRef?.current) {
         let element = document.getElementById('select-from-vaults');
         let filters = document.getElementById('vaults-filters-container')
         const paddingRightValue =
            containerRef?.current?.offsetHeight > element?.getBoundingClientRect().height
               ? desktopType === 'mobile' ? '0px' : '8px'
               : '24px';

         const filtersRightPadding = containerRef?.current?.offsetHeight > element?.getBoundingClientRect().height && screenWidth < 1025 ? '8px' : '24px'

         filters.style.paddingRight = filtersRightPadding
         containerRef.current.style.paddingRight = paddingRightValue;

      }
   }, [isInsideModal, contentType, data, containerRef, desktopType, screenWidth]);

   const view = (
      <div
         id='vaults-filters-container'
         className={ cx({
            'vault-header px-6 max-ms:px-4': true,
            'pb-6': screenWidth < 1025,
         }) }>
         <div className='flex justify-between flex-wrap gap-2 w-full'>
            <div className='flex flex-wrap gap-2'>
               {
                  types.map((item, index) => (
                     <div
                        className={ cx({
                           [`flex flex-row ${ !!item.icon && 'gap-2' } items-center border border-divider hover:bg-hover cursor-pointer rounded-lg px-[14px] py-2 h-10`]: true,
                           ' text-action bg-hover': activeFilters.includes(item.value),
                        }) }
                        role='presentation'
                        onClick={ () => {
                           onChangeFilter(item.value)
                        } }
                        key={ index }
                     >
                        <Icon name={ item.icon } className={ cx({ '!text-xl text-secondary': true, '!text-action': activeFilters.includes(item.value) }) } />
                        <p className='text-sm font-medium select-none'>{item.name}</p>
                     </div>
                  ))
               }
            </div>
            <div className='flex'>
               <div className='w-full z-20'>
                  <Filter
                     name={  'Sorting' }
                     data={ [
                        { name: 'Newest', value: 'date_added:desc' },
                        { name: 'Oldest', value: 'date_added:asc' },
                     ] }
                     desktopType={ desktopType }
                     className=''
                     onFilterChange={ (value, name) => onSimpleFilter(value, name) }
                     activeSelectedValue={ {
                        name: filters.value === 'date_added:desc' ? '' : 'Oldest',
                        value: filters.value,
                     } }
                     height='10'
                     contentStyles={ {
                        right: '0',
                        top: '44px',
                        width: '200px',
                     } }
                  />
               </div>
            </div>
         </div>
      </div>
   )

   if(isFetching){
      return <PageLoader withLoader={ isInsideModal } customStyle='h-full' />
   }
   if(isEmpty){
      return (
         <div className='empty-height flex h-full items-center'>
            <EmptyStates
               icon='vault-v2'
               noContentText='You don’t have any vault'
               buttonText={ isInsideModal ? null : '' }
               className='h-full'
               onAdd={ null }
               linkButtonText='More info on the Vault'
               linkButtonIcon='attention-outline'
               onLinkClick={ () => Intercom.showArticle(ARTICLE_ID_ON_THE_VAULT) }
               dontAddIntercomClassName
            />
         </div>
      )
   }
   return (
      <div className='vault-page'
         id='select-from-all_vaults'
      >
         <div className='vault'>
            {
               contentType === 'store' && screenWidth > 1024 ?
                  createPortal(
                     view
                     ,
                     document.getElementById('vaults-filter-content')
                  )
                  : view
            }
            <div className={
               cx({
                  'vault-content h-full': true,
                  'flex justify-center !h-[54vh] ': isFetchingByFilter || isEmptyAllVaultByFilter,
               })
            }>
               {
                  isEmptyAllVaultByFilter ?
                     <div className='empty-height flex h-full items-center'>
                        <EmptyStates
                           type='search'
                           result='No search resulte'
                        />
                     </div>
                     :
                     <div>
                        <div
                           ref={ containerRef }
                           className={ cx({
                              'w-full grid py-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 px-4 md:px-6': true,
                              'pt-0': isInsideModal,
                           }) }
                        >
                           {
                              !isFetchingByFilter && data && data?.data?.map((item, index) => {
                                 let isChecked = false;
                                 if(selectedCards.includes(item.id)) {
                                    isChecked = true
                                 }

                                 return (
                                    <div
                                       className=''
                                       key={ item.id }
                                    >
                                       <Card
                                          data={ item }
                                          desktopType={ desktopType }
                                          openDeleteConfirmModal={ () => {} }
                                          selectCard={ () => onChangeSelected(isChecked, item) }
                                          isChecked={ isChecked }
                                          isInsideModal={ isInsideModal }
                                          removeFile={ () => {} }
                                          showNewStyles={ showNewStyles }
                                          disabled={ contentType === 'store' && item?.already_added_to_bundle_id !== null && item?.already_added_to_bundle_id !== bundleId }
                                          isMultipleSelect
                                          updateMessageUnlockDetails={ updateMessageUnlockDetails }
                                       />
                                    </div>
                                 )
                              })
                           }
                        </div>
                        {
                           isFetchingByFilter && (
                              <div className='flex h-full w-full justify-center items-center '>
                                 <CircleLoader />
                              </div>
                           )
                        }
                        {
                           isNewfetchingAllVaults  && (
                              <div className='flex h-full w-full justify-center items-center '>
                                 <CircleLoader />
                              </div>
                           )
                        }
                     </div>
               }
            </div>
         </div>
      </div>
   )
}

VaultContent.propTypes = {
   isInsideModal: PropTypes.bool,
   desktopType: PropTypes.string,
   data: PropTypes.array,
   isFetchingByFilter: PropTypes.bool,
   filters: PropTypes.object,
   isEmptyAllVaultByFilter: PropTypes.bool,
   onSimpleFilter: PropTypes.func,
   selectedCards: PropTypes.array,
   showNewStyles: PropTypes.bool,
   isEmpty: PropTypes.bool,
   isFetching: PropTypes.bool,
   onFilterByType: PropTypes.func,
   onChangeSelected: PropTypes.func,
   isNewfetchingAllVaults: PropTypes.bool,
   selected: PropTypes.array,
   setSelectedItems: PropTypes.func,
   onSelectCard: PropTypes.func,
   contentType: PropTypes.string,
   screenWidth: PropTypes.number,
   bundleId: PropTypes.any,
   updateMessageUnlockDetails: PropTypes.func,
}

VaultContent.defaultProps = {
   showNewStyles: false,
   contentType: 'store',
   bundleId: null,
}


export default VaultContent
