const initialState = {
   isFetching: false,
   isNewFetching: false,
   tipsData: {
      data: [],
   },
   statistics: {},
   tipsSettings: {},
};

export default initialState;
