import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_BLOCK_IP_LIST]: (state, action) => {
      return {
         ...state,
         isFetchingIpList: true,
         ipListData: {},
         isEmptyIpList: false,
         ipModal: {
            isOpen: false,
            buttonDisabled: false,
            item: {},
         },
         ipErrorMesage: {},
      }
   },
   [types.FETCH_BLOCK_IP_LIST_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetchingIpList: false,
         ipListData: payload,
         isEmptyIpList: payload.data.length === 0,
      }
   },

   [types.FETCH_BLOCK_IP_LIST_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingIpList: false,
      }
   },

   [types.CREATE_NEW_IP_LIST_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const newList = [payload, ...state.ipListData.data]
      return {
         ...state,
         ipListData: {
            ...state.ipListData,
            data: newList,
            total: state.ipListData.total + 1,
         },
         isEmptyIpList: newList.length === 0,
      }

   },
   [types.UPDATE_IP_LIST_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const newList = [...state.ipListData.data].map(elm => {
         let { ...i } = elm
         if(i.id === payload.id) {
            i = { ...i, ...payload.data }
         }
         return i
      })
      return {
         ...state,
         ipListData: {
            ...state.ipListData,
            data: newList,
         },
      }

   },
   [types.DELETE_IP_LIST_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const newList = [...state.ipListData.data].filter(elm => elm.id !== payload)
      return {
         ...state,
         ipListData: {
            ...state.ipListData,
            data: newList,
            total: state.ipListData.total - 1,
         },
         isEmptyIpList: newList.length === 0,
      }

   },
   [types.FETCH_BLOCK_NEXT_IP_LIST]: (state, action) => {
      return {
         ...state,
         fetchNewIpList: action.payload,
      }

   },
   [types.FETCH_BLOCK_NEXT_IP_LIST_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const newList = [...state.ipListData.data, ...payload.data]
      return {
         ...state,
         fetchNewIpList: false,
         ipListData: {
            ...payload,
            data: newList,
         },
      }

   },

   [types.SET_IP_MODAL]: (state, action) => {
      const  { payload } = action;
      let newState = {}
      if('boolean' === typeof payload.isOpen && !payload.isOpen) {
         newState = {
            ipErrorMesage: {},
         }
      }
      return {
         ...state,
         ...newState,
         ipModal: {
            ...state.ipModal,
            ...payload,
         },
      }
   },
   [types.SET_ERROR_DATA]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         ipErrorMesage: payload.data,
      }
   },
   ////////////////////////////////////////////////
   [types.FETCH_REGIONS]: (state, action) => {
      return {
         ...state,
         isFetchingRegions: true,
      }
   },
   [types.FETCH_REGIONS_COMPLETED]: (state, action) => {
      const  { payload: { data, blockingRegions } } = action;
      const armedForces = sortRegionsData(data, 'us_state', 'armed_forces', blockingRegions)
      const outlyingTerritories = sortRegionsData(data, 'us_state', 'is_outlying_territories', blockingRegions)
      const stateList = sortRegionsData(data, 'us_state', 'state_list', blockingRegions)
      const useStets = [...stateList, ...outlyingTerritories, ...armedForces]
      const newData = {
         canadian_states: sortRegionsData(data, 'canadian_state', 'canadian_states', blockingRegions),
         countries: sortRegionsData(data, 'country', 'countries', blockingRegions),
         us_states: useStets.sort((a, b) => {
            if(a.code === b.code) return null
            return a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
         }) }
      let newState = {};
      if('vpnBlocked' in action.payload){
         newState = {
            vpnBlocked: action.payload.vpnBlocked,
         }
      }
      return {
         ...state,
         isFetchingRegions: false,
         regions: newData,
         initialRegionsData: newData,
         ...newState,
      }
   },



   [types.FETCH_REGIONS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingRegions: false,

      }
   },
   [types.BLOCK_REGIONS_COMPLETED]: (state, action) => {
      const  { payload: {
         data,
         key,
         subKey,
      } } = action;
      const newData = blockUnblockRegion(state.regions, true, key, subKey, data)
      const initialRegionsData = blockUnblockRegion(state.initialRegionsData, true, key, subKey, data)
      return {
         ...state,
         regions: newData,
         initialRegionsData: initialRegionsData,
      }
   },

   [types.UN_BLOCK_REGIONS_COMPLETED]: (state, action) => {
      const  { payload: {
         data,
         key,
         subKey,
      } } = action;

      const newData = blockUnblockRegion(state.regions, false, key, subKey, data)
      const initialRegionsData = blockUnblockRegion(state.initialRegionsData, false, key, subKey, data)
      return {
         ...state,
         regions: newData,
         initialRegionsData: initialRegionsData,
      }
   },
   [types.SEARACH_REGIONS_DATA]: (state, action) => {
      const  { payload: {
         type, value,
      } } = action;

      let val = value ? value.replaceAll('\\', '\\\\') : ''

      // eslint-disable-next-line array-callback-return
      const newData = [...state.initialRegionsData[type]].filter(item => {
         if(item.value && item.value.toLowerCase().search(val.toLowerCase()) !== -1) {
            return item
         }
      })
      return {
         ...state,
         regions: {
            ...state.regions,
            [type]: newData,
         },
      }
   },

   ////////////////////////////////////////////////
   [types.FETCH_BLOCK_MEMBERS]: (state, action) => {
      return {
         ...state,
         isFetchingBlockMembers: true,
      }
   },
   [types.FETCH_BLOCK_MEMBERS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetchingBlockMembers: false,
         blockMembersList: payload.data,
         isEmptyBlockMembers: payload.data.length === 0,
      }
   },
   [types.BLOCK_MEMBERS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const data = [...state.blockMembersList, payload]
      return {
         ...state,
         blockMembersList: data,
         isEmptyBlockMembers: data.length === 0,
      }
   },
   [types.UN_BLOCK_MEMBERS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const data = [...state.blockMembersList].filter(item => item.id !== payload.id)
      return {
         ...state,
         blockMembersList: data,
         isEmptyBlockMembers: data.length === 0,
      }
   },
   [types.UPDATE_MEMBER_STATE]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         updateMember: {
            ...state.updateMember,
            ...payload,
         },
      }
   },
   [types.FETCH_VPN_BLOCKED]: (state, action) => {
      return {
         ...state,
         isFetchingUpdateVpnBlocking: !!action.payload,
      }
   },
   [types.FETCH_VPN_BLOCKED_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingUpdateVpnBlocking: false,
         vpnBlocked: action.payload,
      }
   },

};

function sortRegionsData(data, blockingRegionName, regionName, blockingData){
   const blocking = blockingData.filter(i => i.type === blockingRegionName)
   const allData = [];
   Object.entries(data[regionName]).forEach((region) => {
      let blocked = blocking.find(item => region.includes(item.code))
      const item = { value: region[1], code: region[0], id: blocked ? blocked.id : null, banned: !!blocked }
      allData.push(item);
   });
   return allData
}

function blockUnblockRegion(regions, banned, key, subKey, data){
   let newData = {
      ...regions,
   }
   if(!subKey) {
      newData[key] =  newData[key].map(item => {
         const { ...elm } = item
         if(elm.code === data.code) {
            elm.banned = banned
            elm.id = data.id
         }
         return elm
      })
   } else {
      newData[key][subKey] = newData[key][subKey].map(item => {
         const { ...elm } = item
         if(elm.code === data.code) {
            elm.banned = banned
            elm.id = data.id
         }
         return elm
      })
   }
   return newData
}

export default createReducer(initialState)(reducersMap);
