const initialState = {
   isFetching: true,
   isNewFetching: false,
   isFilterFetching: false,
   isFetchingAddFreeMember: false,
   isFetchingAddNewPerson: true,
   isFetchingStatistics: true,
   membersData: {
      data: [],
   },
   statistics: {},
   member: {},
   memberships: [],
   isFetchingMemberships: true,
   freeMemberToAddIds: [],
   freeMemberToAddMembership: null,
   errors: {},
   isEmpty: false,
   isEmptyByFilter: false,
   updateErrors: {},
   isOpenUsernameModal: false,
   isFetchingWalletHistory: false,
   isNewFetchingWalletHistory: false,
   isEmptyWalletHistory: false,
   walletHistory: {
      data: [],
   },
   isFetchingMemberTags: true,
   isEmptyMemberTags: false,
   memberTags: [],
   activeMember: {},
   isFetchingAddNote: false,
   isFetchingDeleteNote: false,
   isFetchingUpdateNote: false,
};

export default initialState;
