import React from 'react';
import PullToRefresh from 'pulltorefreshjs';
import Loader from 'common/components/elements/loader/circle';
import ReactDOMServer from 'react-dom/server'

class PullRefresh {
   static init() {
      return {
         mainElement: '.pullRefresh_content', // above which element?
         instructionsPullToRefresh: ' ',
         instructionsReleaseToRefresh: ' ',
         instructionsRefreshing: ' ',
         distReload: '50',
         distMax: '1000',
         resistanceFunction: () => {
            window.isPullRefresh  = true
            return 0.7
         },
         iconArrow: `
               <div class='flex justify-center items-center'>
                  <svg width="16" height="25" viewBox="0 0 16 25" fill="red" xmlns="http://www.w3.org/2000/svg">
                     <path opacity="0.9" d="M7.29289 24.7071C7.68342 25.0976 8.31658 25.0976 8.70711 24.7071L15.0711 18.3431C15.4616 17.9526 15.4616 17.3195 15.0711 16.9289C14.6805 16.5384 14.0474 16.5384 13.6569 16.9289L8 22.5858L2.34315 16.9289C1.95262 16.5384 1.31946 16.5384 0.928932 16.9289C0.538407 17.3195 0.538407 17.9526 0.928932 18.3431L7.29289 24.7071ZM7 -2.91409e-08L7 24L9 24L9 2.91409e-08L7 -2.91409e-08Z" fill=${ window.skinSecondaryColor } fill-opacity="0.5"/>
                  </svg>
               </div>
               `,
         iconRefreshing: `
               <div class='flex justify-center items-center'>
                  ${ ReactDOMServer.renderToString(<Loader size='8'  />) }
               </div>`,
         shouldPullToRefresh: () => {
            window.isPullRefresh = false
            const modal = document.querySelector('#modals-root')
            const navigation = document.querySelector('.layout').getElementsByClassName('menu-hidden-animation')[0]
            if(!modal && !navigation && !window.isPullRefresh){
               return window.scrollY === 0;
            }
         },
      };
   }

   static async destroyAll() {
      window.isPullRefresh  = false
      PullToRefresh.destroyAll()
   }
}
export default PullRefresh;
