/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import cx from 'classnames';
import Icon from 'common/components/elements/icons';
import PropTypes from 'prop-types';
import MemberBlocekdMutedLabels from '../member-blocked-muted-labels';
import './style.scss';
import OptionsModal from 'admin/views/members/options-modal';
import MemberTagItem from './member-tag-item';
import Button from 'common/components/elements/buttons/primary';
import { MemberCardInfo } from './member-info'
import { createPortal } from 'react-dom';
import TruncateText from 'common/components/elements/truncate-text';
import { Tooltip } from 'react-tooltip';
import CodeInfo from 'admin/views/promotions/modules/code-info';
import Avatar from '../avatar';
import Router from 'admin/routes/router';
import { getMemberTotalSpent } from 'common/utils/utils';


const MemberCard = ({
   avatar,
   username,
   status,
   className,
   onClick,
   selected,
   id,
   banned,
   muted,
   topPercentage,
   isBulkEdit,
   isMobile,
   isAddNew,
   desktopType,
   membershipType,
   isSelected,
   isOnlineMember,
   openEditUsernameModal,
   goToPrivateChat,
   giveFreeMemberShip,
   setMemberOptionModal,
   getMemberData,
   isInsideModal,
   goToProfile,
   addCustomTagModal,
   member,
   disabledNotifications,
   isListCard,
   seeMoreTags,
   onTagMember,
   insideChat,
   onFilterShowResult,
   // memberCountryFlag,
   country,
   isShowPage,
   memberTags,
   onCardHover,
   isDetailsPage,
   onOptionsClick,
   user,
   goToDetails,
   onOpenEditNickname,
   optionModalBottomPosition,
}) => {
   const optionButtonRef = React.useRef();
   const [openOptionsModal, setOpenOptionsModal] = useState(false);
   const [hoverBg, setHoverBg] = useState(true);
   const getMemberType = (type) => {
      const memberStatus = {
         name: '',
         color: '',
      };
      switch (type) {
         case 'active':
            memberStatus.name = 'Active';
            memberStatus.color = 'bg-success-12 dark:bg-success-dark-12';
            memberStatus.textColor = 'text-success dark:text-success-dark';
            break;
         case 'incomplete':
            memberStatus.name = 'Guest';
            memberStatus.color = 'bg-pending-12 dark:bg-pending-dark-12';
            memberStatus.textColor = 'text-warning dark:text-warning-dark';
            break;
         case 'cancelled':
            memberStatus.name = 'Cancelled';
            memberStatus.color = 'bg-error-12 dark:bg-error-dark-12';
            memberStatus.textColor = 'text-error dark:text-error-dark';
            break;
         case 'expired':
            memberStatus.name = 'Expired';
            memberStatus.color = 'bg-secondary-12 dark:bg-secondary-dark-12';
            memberStatus.textColor = 'text-secondary dark:text-secondary-dark ';
            break;
         case 'free':
            memberStatus.name = 'Complimentary';
            memberStatus.color = 'bg-action-12 dark:bg-action-dark-12';
            memberStatus.textColor = 'text-action dark:text-action-dark';
            break;
         default:
            break;
      }

      return memberStatus;
   };

   const getMemberBadge = () => {
      let badgeTags = ''
      let hasBadge = (!!member?.member_tags && member?.member_tags?.badge)
      let hasAutoTag = (!!member?.member_tags && 'function' === typeof member?.member?.member_tags.filter &&  member?.member_tags.filter(item => !!item.rule && item.show_icon_as_badge === 1))
      let hasCustomTag = (!!member?.member_tags &&  'function' === typeof member?.member?.member_tags.filter && member?.member_tags.filter(item => !item.rule && item.show_icon_as_badge === 1))

      if(!!hasAutoTag[0]) {
         badgeTags = hasAutoTag?.[0]?.emoji
      }
      if(!!hasCustomTag[0] && !hasAutoTag[0]) {
         badgeTags = hasCustomTag?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))[0]?.emoji
      }
      if(!!hasBadge) {
         badgeTags = hasBadge
      }
      return badgeTags
   }

   let badgeEmoji = '';
   let memberTagsCount = 0

   if(isListCard) {
      badgeEmoji = member?.member_tags?.badge;
      memberTagsCount = member?.member_tags?.tags_count;
      memberTags = member?.member_tags?.last_four_tags;
   } else {
      badgeEmoji = getMemberBadge()
      memberTagsCount = member?.member_tags?.length;
      memberTags = member?.member_tags;
   }

   function getTagsWidth(tagsAmount) {
      let tagsWidth = ''
      // console.log('tagsAmount', tagsAmount);
      switch (tagsAmount) {
         case 1:
            tagsWidth = 'max-w-[120px]'
            break;
         case 2:
            tagsWidth += 'max-w-[117px]';
            break;
         case 3:
            tagsWidth += 'max-w-[105px]';
            break;
         case 4:
            tagsWidth += 'max-w-[23%]';
            break;
         default:
            tagsWidth = 'max-w-[23%]';
      }
      return tagsWidth;
   }
   let membershipName = membershipType

   if(member?.member_status === 'expired' && !!member?.pricing_name) {
      membershipName = member?.pricing_name
   }

   return (
      <>
         <div
            role='presentation'
            onClick={ (e) => {
               if(!isBulkEdit && !isInsideModal && !isAddNew){
                  e.stopPropagation()
                  e.preventDefault()
                  goToProfile(id)
                  return;
               }
               onClick(!isSelected);
            } }
            onMouseOver={ () => !!onCardHover && onCardHover() }
            className={
               cx(`
                  member-card member-${ id } ${ !!(isListCard || isAddNew) ? 'cursor-pointer ' : '' } h-full relative w-full rounded-lg bg-panel dark:bg-panel-dark border-solid border
                  dark:border-divider-dark border-divider py-4 flex flex-col gap-2 pr-4 pl-4 ${ className }`,
               selected && ['skin-secondary-border'],
               hoverBg && !isDetailsPage && 'member-card-hover'
               )
            }
         >
            <div className={ `flex justify-between` }>
               <div className='flex gap-3'>
                  {
                     avatar ? (
                        <div className={ `member-card-avatar w-16 h-16 min-w-[64px] relative` }>
                           <Avatar
                              avatarSrc={ avatar }
                              username={ username }
                              firstLetterClassName='text-2xl'
                              imageClassName='hover:cursor-pointer'
                              className={ `object-contain relative ${ insideChat && `hover:cursor-pointer` }` }
                              onClick={ () => {
                                 insideChat && window?.open(Router.route('MEMBERS_SHOW').getCompiledPath({ id }), '_blank')
                              } }
                           />
                           {
                              isOnlineMember && (
                                 <span className='status-circle bg-success dark:bg-success-dark border-2 border-major-dark dark:border-major w-4 h-4 min-w-[16px] min-h-[16px]'></span>
                              )
                           }
                           {
                              !!badgeEmoji && (
                                 <div className='w-7 h-7 rounded-full bg-panel dark:bg-panel-dark member-badge absolute flex items-center justify-center'>
                                    <span
                                       role='img'
                                       aria-label='icon'
                                       className='text-xs'>{badgeEmoji}</span>
                                 </div>
                              )
                           }
                        </div>
                     ) : (
                        <div className='flex w-16 h-16 items-center justify-center non-avatar-content rounded-full' >
                           <Icon
                              style={ { fontSize: '41px', color: '#F3F5F6' } }
                              name='account'
                           />
                           {
                              !!badgeEmoji && (
                                 <div className='rounded-full bg-panel dark:bg-panel-dark member-badge absolute px-1'>
                                    <span
                                       role='img'
                                       aria-label='icon'
                                       className='text-sm'>{badgeEmoji}</span>
                                 </div>
                              )
                           }
                        </div>
                     )
                  }
                  <div className={ `flex flex-col` }>
                     <div className='flex gap-1'>
                        <div className={ cx('h-6 flex items-center justify-center px-3 py-1 rounded-full status-text', getMemberType(status).color, getMemberType(status).textColor) } >
                           <span className='text-xs leading-14px'>{getMemberType(status).name}</span>
                        </div>
                        {
                           Boolean(member.promotion) && (
                              <>
                                 <div
                                    className='w-6 h-6 rounded-full flex items-center justify-center bg-pending-12'
                                    data-tooltip-id={ `ams-html-tooltip-${ id }` }
                                 >
                                    <Icon
                                       name='hero-section'
                                       color='warning'
                                       className='text-warning text-xs'
                                       size='xs'
                                    />
                                 </div>
                                 {
                                    createPortal(
                                       <Tooltip
                                          place='top'
                                          className='ams-black-tooltips'
                                          id={ `ams-html-tooltip-${ id }` }
                                          clickable
                                       >
                                          <div className='flex items-center gap-1'>
                                             <span>Used promo code:</span>
                                             <CodeInfo
                                                code={ member.promotion.code }
                                                showTooltip={ false }
                                                iconColor='major-dark'
                                                iconSize='small'
                                             />
                                          </div>
                                       </Tooltip>
                                       ,
                                       document.getElementById('tooltips')
                                    )
                                 }
                              </>
                           )
                        }
                     </div>
                     <div className={ `flex mt-1 mb-1 ${ !!(isListCard || isAddNew) ? 'list-username-labels' : isDetailsPage ? 'member-details-label' : 'username-labels' } items-center` }>

                        <TruncateText
                           text={ username }
                           textClass={ `username font-bold text-base leading-6 whitespace-pre text-major ${ insideChat && 'cursor-pointer' }` }
                           textSize='16px'
                           fontWeight='700'
                           onClick={ () => {
                              if(insideChat) {
                                 window?.open(Router.route('MEMBERS_SHOW').getCompiledPath({ id }), '_blank')
                              }
                           } }
                        />
                        <MemberBlocekdMutedLabels
                           muted={ muted }
                           banned={ banned }
                           disabledNotifications={ disabledNotifications }
                           id={ id }
                        />
                     </div>
                     <MemberCardInfo
                        location={ member?.location }
                        membershipType={ membershipName }
                        totalSpent={ getMemberTotalSpent(member) }
                        topPercentage={ topPercentage }
                        country={ country }
                        classNames={ `${ insideChat ? `max-w-[52vw] lg:max-w-[205px]` : isListCard ? ` max-w-[52vw] sm:max-w-[100%] md:max-w-[26vw] lg:max-w-[16.5vw] xl:max-w-[19vw]` : `max-w-[52vw] sm:max-w-[370px]` }` }
                     />
                  </div>
               </div>
               <div className='icons mt-1' style={ {
                  marginLeft: `${ ((disabledNotifications && muted) || (muted || disabledNotifications)) || (username?.length > 20) ? '-12px' : '0px' }`,
               } }>
                  {
                     (isAddNew || isBulkEdit || isInsideModal) && (
                        <div
                           className={
                              cx({
                                 [`flex items-center justify-center cursor-pointer select-none border h-5 w-5 min-h-[20px] min-w-[20px] rounded-xsm`]: true,
                                 'skin-primary-border bg-hover dark:bg-hover-dark': selected || isSelected,
                                 'bg-panel dark:bg-black-field shadow-inner border-secondary-12 dark:border-secondary-dark-12': !selected && !isSelected,
                              })
                           }
                        >
                           {
                              (selected || isSelected) && (
                                 <div className='h-5 w-5 flex items-center justify-center'>
                                    <Icon
                                       style={ {
                                          fontWeight: '800',
                                       } }
                                       color='action'
                                       name='done'
                                       size='base'
                                    />
                                 </div>
                              )
                           }
                        </div>
                     )
                  }
                  {
                     ((!isBulkEdit && !isAddNew && !isInsideModal && insideChat) || (!isBulkEdit && !isAddNew && !isInsideModal && !insideChat)) && (
                        <div
                           ref={ optionButtonRef }
                           className='w-6 h-6 flex items-center justify-center cursor-pointer intercom-member-options'
                        >
                           <Icon
                              name='options-vertical'
                              size='2xl'
                              color='secondary'
                              darkColor='secondary-dark'
                              onClick={ (e) => {
                                 e.stopPropagation()
                                 e.preventDefault()
                                 if(!!onOptionsClick) {
                                    onOptionsClick(e, member)
                                    getMemberData(member)
                                 } else {
                                    getMemberData(member)
                                    setOpenOptionsModal(!openOptionsModal)
                                 }
                              } }
                           />
                        </div>
                     )
                  }
               </div>
            </div>
            {
               !!memberTagsCount ? (
                  <div className={ `w-full flex flex-row items-start justify-start gap-2 ${ memberTagsCount > 1 ? 'ml-0' : 'ml-0 sm:ml-2 lg:ml-0 xl:ml-19' }` }>
                     {/* <div className={ `w-auto member-tags flex flex-wrap  gap-2 text-major ml-${ memberTagsCount > 1 ? '0' : '19' }` }> */}
                     {
                        memberTags
                           ?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
                           ?.map((tag, index) => {
                              if(!!(isShowPage || isListCard || isAddNew) &&  index > 1) {
                                 return null;
                              }
                              return (
                                 <MemberTagItem
                                    key={ tag.id }
                                    emoji={ tag.emoji }
                                    name={ tag.name }
                                    insideChat={ insideChat }
                                    isListCard={ isListCard }
                                    tagId={ tag.id }
                                    onFilterShowResult={ onFilterShowResult }
                                    setHoverBg={ (bool) => setHoverBg(bool) }
                                    classNames={ `${ getTagsWidth(memberTagsCount) }` }

                                 />
                              )
                           })
                     }
                     {/* </div> */}
                     {
                        !!(isShowPage || isListCard || isAddNew) && memberTagsCount > 2 && (
                           <div className='inline-flex h-8 items-center'>
                              <Button
                                 text={ `See ${ memberTagsCount - 2 } more tag${ memberTagsCount - 2 > 1 ? 's' : '' }` }
                                 onClick={ (e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    getMemberData(member)
                                    seeMoreTags()
                                 } }
                                 iconName='tag-new'
                                 iconPosition='left'
                                 borderRadius='large'
                                 backgroundColor='transparent'
                                 textColor='secondary dark:text-secondary-dark'
                                 textSize='extraSmall'
                                 fontIconSize='extraSmall'
                                 fontIconColor='secondary'
                                 darkIconColor='secondary-dark'
                                 fontWeight='normal'
                                 padding='1'
                                 lineHeight='leading-4'
                                 classNames='whitespace-nowrap'
                                 textMarginX='2'
                                 alignment='end'
                              />
                           </div>
                        )
                     }
                     {
                        !!(isShowPage || isListCard || isAddNew) && (memberTagsCount < 3) && (
                           <div className='tag-member-button bg-panel dark:bg-panel-dark border rounded-full pl-2'>
                              <Button
                                 text='Tag member'
                                 onClick={ (e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    onTagMember()
                                 } }
                                 iconName='tag-new'
                                 iconPosition='left'
                                 borderRadius='large'
                                 backgroundColor='transparent'
                                 textColor='secondary dark:text-secondary-dark '
                                 textSize='extraSmall'
                                 fontIconSize='extraSmall'
                                 fontIconColor='secondary dark:text-secondary-dark '
                                 fontWeight='normal'
                                 padding='none'
                                 classNames='h-6 whitespace-nowrap pt-[5px]'
                              />
                           </div>
                        )
                     }
                  </div>
               ) : (
                  <div className='tag-member-button bg-panel dark:bg-panel-dark border rounded-full pl-2 ml-19'>
                     <Button
                        text='Tag member'
                        onClick={ (e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           onTagMember()
                        } }
                        iconName='tag-new'
                        iconPosition='left'
                        borderRadius='large'
                        backgroundColor='transparent'
                        textColor='secondary dark:text-secondary-dark '
                        textSize='extraSmall'
                        fontIconSize='extraSmall'
                        fontIconColor='secondary dark:text-secondary-dark '
                        fontWeight='normal'
                        padding='none'
                        waveColor='rgba(33, 150, 243, 0.8)'
                        classNames='h-6 pt-[5px]'
                     />
                  </div>
               )
            }
         </div>
         <div className={ `${ insideChat ? 'absolute' : 'relative' }` }>
            {
               openOptionsModal && (
                  <>
                     {
                        insideChat ||  <OptionsModal
                           isMobile={ isMobile }
                           bottomPosition={ optionModalBottomPosition }
                           desktopType={ desktopType }
                           onCloseModal={ () => setOpenOptionsModal(false) }
                           editUsername={ () => openEditUsernameModal(member) }
                           goToPrivateChat={ () => goToPrivateChat(member) }
                           memberStatus={ status }
                           giveFreeMemberShip={ () => giveFreeMemberShip(member) }
                           setMemberOptionModal={ setMemberOptionModal }
                           isMuted={ !!muted }
                           isBlocked={ !!banned }
                           id={ id }
                           goToProfile={ () => goToProfile(id) }
                           openCustomTagModal={ () => addCustomTagModal(member) }
                           isDisabledNotifications={ !!disabledNotifications }
                           optionButtonRef={ optionButtonRef }
                           rightPosition={ `${ insideChat ? '-283px' : '0px' }` }
                           insideChat={ insideChat }
                           memberUsername={ member?.username }
                           goToDetails={ goToDetails }
                           onOpenEditNickname={ onOpenEditNickname }
                           memberData={ member }
                        />
                     }
                     {
                        insideChat &&  createPortal(
                           <div className='lg:visible' style={ {
                              position: 'absolute',
                              right: '20px',
                              top: '410px',
                           } }>
                              <OptionsModal
                                 isMobile={ isMobile }
                                 bottomPosition={ `${ (window?.innerWidth > 1024 && window?.innerWidth < 1280) ? '0px' : '40px' }` }
                                 desktopType={ desktopType }
                                 onCloseModal={ () => setOpenOptionsModal(false) }
                                 editUsername={ () => openEditUsernameModal(member) }
                                 goToPrivateChat={ () => goToPrivateChat(member) }
                                 memberStatus={ status }
                                 giveFreeMemberShip={ () => giveFreeMemberShip(member) }
                                 setMemberOptionModal={ setMemberOptionModal }
                                 isMuted={ !!muted }
                                 isBlocked={ !!banned }
                                 id={ id }
                                 goToProfile={ () => goToProfile(id) }
                                 openCustomTagModal={ () => addCustomTagModal(member) }
                                 isDisabledNotifications={ !!disabledNotifications }
                                 optionButtonRef={ optionButtonRef }
                                 rightPosition={ `0px` }
                                 insideChat={ insideChat }
                                 memberUsername={ member?.username }
                                 goToDetails={ goToDetails }
                                 onOpenEditNickname={ onOpenEditNickname }
                                 memberData={ member }
                              />
                           </div>
                           ,
                           document.getElementById('member-option-modal')
                        )
                     }

                  </>

               )
            }
         </div>
      </>
   );
};




MemberCard.propTypes = {
   username: PropTypes.string,
   status: PropTypes.string,
   avatar: PropTypes.string,
   className: PropTypes.string,
   selected: PropTypes.bool,
   onClick: PropTypes.func,
   id: PropTypes.any,
   muted: PropTypes.any,
   banned: PropTypes.any,
   isSelected: PropTypes.bool,
   isAddNew: PropTypes.bool,
   isBulkEdit: PropTypes.bool,
   membershipType: PropTypes.string,
   isOnlineMember: PropTypes.bool,
   isMobile: PropTypes.bool,
   desktopType: PropTypes.string,
   openEditUsernameModal: PropTypes.func,
   goToPrivateChat: PropTypes.func,
   giveFreeMemberShip: PropTypes.func,
   setMemberOptionModal: PropTypes.func,
   getMemberData: PropTypes.func,
   isInsideModal: PropTypes.bool,
   goToProfile: PropTypes.func,
   addCustomTagModal: PropTypes.func,
   member: PropTypes.object,
   disabledNotifications: PropTypes.bool,
   location: PropTypes.string,
   topPercentage: PropTypes.number,
   isListCard: PropTypes.bool,
   seeMoreTags: PropTypes.func,
   onTagMember: PropTypes.func,
   insideChat: PropTypes.bool,
   onFilterShowResult: PropTypes.func,
   country: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
   ]),
   isShowPage: PropTypes.bool,
   memberTags: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
   ]),
   onCardHover: PropTypes.func,
   isDetailsPage: PropTypes.bool,
   user: PropTypes.object,
   onOptionsClick: PropTypes.func,
   goToDetails: PropTypes.func,
   onOpenEditNickname: PropTypes.func,
   optionModalBottomPosition: PropTypes.string,
};

MemberCard.defaultProps = {
   username: 'Username',
   status: 'active',
   className: '',
   selected: false,
   isListCard: false,
   onClick: () => {},
   goToProfile: () => {},
   onTagMember: () => {},
   seeMoreTags: () => {},
   getMemberData: () => {},
   openEditUsernameModal: () => {},
   goToPrivateChat: () => {},
   giveFreeMemberShip: () => {},
   setMemberOptionModal: () => {},
   onFilterShowResult: () => {},
   user: {},
   optionModalBottomPosition: '-193px',
};

export default React.memo(MemberCard);
