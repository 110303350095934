import {
   toggleVideoUploadLimit,
   addVideos,
   updateOrAddVideo,
   destroyRequest,
   destroyCompleted,
} from "./actions";
import { makeRandomString } from 'common/utils/utils';
import { init as BuildEvaporate } from 'common/utils/evaporate';
import {
   canUploadVideo,
   createVideo,
   destroyVideos,
   destroyPhotosets,
} from 'admin/api/AuthApi';
import toast from "common/utils/toast";
import QueryParams from 'admin/utils/QueryParams';

const startUploadOperation = (files) => {
   return (dispatch, getState) => {
      if(files) {
         const videosToAdd = [];
         files.forEach((file) => {
            const { name } = file;
            const extension = name.substr(name.lastIndexOf('.') + 1).toLowerCase();
            const newFileRandomName = makeRandomString(32);
            const newFileName = `${ newFileRandomName }.${ extension }`;
            const uploadPath = `videos/${ newFileRandomName }/${ newFileName }`;
            const candidate = {
               id: makeRandomString(8),
               title: file.name.split('.').slice(0, -1).join('.'),
               uploadingStatus: 'pending',
               uploadInfo: {
                  file,
                  uploadPath,
                  extension,
                  newFileRandomName,
                  newFileName,
               },
            };
            videosToAdd.push(candidate)
         });
         dispatch(addVideos({ videos: videosToAdd }));
      }
      let firstPendingVideo = null;
      let inProgressVideo = null;
      const videos = getState().uploads.videos;
      videos.forEach((video) => {
         const { uploadingStatus } = video;
         if('pending' === uploadingStatus && !firstPendingVideo) {
            firstPendingVideo = video;
         }
         if('uploading' === uploadingStatus && !inProgressVideo) {
            inProgressVideo = video;
         }
      });
      if(!inProgressVideo && firstPendingVideo) {
         canUploadVideo().then((response) => {
            const can = response.data.status;
            if(can) {
               BuildEvaporate().then((evaporate) => {
                  evaporate.add({
                     file: firstPendingVideo.uploadInfo.file,
                     name: firstPendingVideo.uploadInfo.uploadPath,
                     progress: (percent, stats) => {
                        const formattedPercent = Math.round(percent * 100);
                        const info = {
                           percentage: formattedPercent,
                        };
                        dispatch(updateOrAddVideo(firstPendingVideo.id, info));
                     },
                     error: (mssg) => console.log('Error', mssg),
                     paused: () => console.log('Paused'),
                     pausing: () => console.log('Pausing'),
                     resumed: () => console.log('Resumed'),
                     cancelled: () => {
                        console.log('Cancelled')
                        setTimeout(() => {
                           dispatch(startUploadOperation());
                        }, 10);
                     },
                     started: (fileKey) => {
                        const info = {
                           uploadingStatus: 'uploading',
                        };
                        dispatch(updateOrAddVideo(firstPendingVideo.id, info));
                     },
                     uploadInitiated: (s3Id) => {},
                     warn: (mssg) => console.log('Warning', mssg),
                  }).then(
                     async (awsObjectKey) => {
                        dispatch(updateOrAddVideo(firstPendingVideo.id, { start_createing: true }));
                        setTimeout(async () => {
                           const info =  await createNewVideo(firstPendingVideo)
                           dispatch(updateOrAddVideo(firstPendingVideo.id, info));
                           setTimeout(() => {
                              dispatch(startUploadOperation());
                           }, 10);
                        }, 5000);
                     },
                     (reason) => {
                        console.log('File did not upload sucessfully:', reason)
                     }
                  );
               });
            } else {
               dispatch(toggleVideoUploadLimit(true));
               QueryParams.addQueryParam('open-modal', 'reachedlimit');
            }
         });
      }
   };
}
async function createNewVideo(firstPendingVideo) {
   try {
      const { data } =  await createVideo({
         title: firstPendingVideo.title,
         extname: firstPendingVideo.uploadInfo.extension,
         basename: firstPendingVideo.uploadInfo.newFileRandomName,
      });
      const video = data;
      const {
         id,
         v2_optimization_status,
      } = video;
      const errorStatuses = [
         'mediainfo_error',
         'optimization_error',
         'other_error',
      ];
      let status = null;
      if(errorStatuses.includes(v2_optimization_status)) {
         status = 'error'
      } else if('in_optimization_queue' === v2_optimization_status) {
         status = 'in_optimization_queue';
      } else if('optimizing' === v2_optimization_status) {
         status = 'optimizing';
      }
      const info = {
         uploadingStatus: status,
         id,
         start_createing: false,
      };
      return Promise.resolve(info);
   } catch (error) {
      console.log('File did not upload sucessfully:', error)
      return Promise.reject(error);
   }
}
const destroyMediaOperation = (ids, callBack, type) => {
   return async (dispatch) => {
      dispatch(destroyRequest(true));
      try {
         let data = {}
         if(ids.all) {
            data = { count: 'all', completed: ids.completed,  excludedIds: ids.excludedIds }
         } else {
            data = { data: ids.ids }
         }
         if('video' === type) {
            await destroyVideos(data);
         } else {
            await destroyPhotosets(data);
         }
         dispatch(destroyCompleted({ ids: ids.all ? 'all' : ids.ids, type, excludedIds: ids.excludedIds }));
         if(callBack) {
            callBack()
         }
         toast.remove(`${ type }s has been deleted`)
      } catch (error) {
         dispatch(destroyRequest(false));
      }
   };
}

export {
   startUploadOperation,
   destroyMediaOperation,
};
