function getStepTitle(slug) {
   let title = null;
   switch (slug) {
      case 'legal_info':{
         title = 'Add your legal info';
         break;
      }
      case 'content_story':{
         title = 'Add 5 pieces of content';
         break;
      }
      case 'domain':{
         title = 'Set up your site & domain name';
         break;
      }
      case 'payout_preferences':{
         title = 'Set your payout preferences';
         break;
      }
      case 'content_category': {
         title = 'Select your content categories';
         break;
      }
      case 'profile_picture': {
         title = 'Add your profile photo';
         break;
      }
      case 'original_file_backups':{
         title = 'Optional original file backups';
         break;
      }
      default: {}
   }
   return title;
}

const stepsSelector = (state) => {
   const { siteSteps, siteDomainDetails, siteInfo } = state.common;
   let result = {
      steps: [],
      percentage_of_completed_steps: 0,
      site_status: null,
   };
   const defaulDomain = siteDomainDetails.default_domain;
   const siteDomain = siteInfo?.site_url;
   if(Object.keys(siteSteps).length > 0) {
      const {
         steps,
         percentage_of_completed_steps,
         site_status,
         stories_count: storiesCount,
      } = siteSteps;
      result.percentage_of_completed_steps = percentage_of_completed_steps;
      result.site_status = site_status;

      const SORTED_STEPS_SLUGS = ['content_story', 'content_category', 'domain', 'profile_picture', 'legal_info', 'payout_preferences', 'original_file_backups']
      SORTED_STEPS_SLUGS.forEach((slug, index) => {
         const step = {};
         const isCompleted = steps[slug];
         let description = '';
         switch (slug) {
            case 'legal_info':{
               description = isCompleted ? 'Your legal information is private' : 'Your legal documentation';
               break;
            }
            case 'content_story':{
               const text = "pieces of content ";
               description = storiesCount > 5 ? `${ storiesCount } ${ text }` : `${ storiesCount } of 5 added`
               break;
            }
            case 'domain':{
               description = defaulDomain;
               if('custom_domain' === siteDomainDetails?.domain_type && siteDomainDetails?.custom_domain){
                  description = siteDomainDetails.custom_domain;
               }
               if(isCompleted){
                  description = siteDomain;
               }
               step['not_pointing'] = 'custom_domain' === siteDomainDetails?.domain_type && (!siteDomainDetails?.is_pointing || !isCompleted);
               break;
            }
            case 'payout_preferences':{
               description = 'How do you want to be paid?';
               break;
            }
            case 'content_category': {
               description = 'Select what categories you feel your content falls into';
               break;
            }
            case 'profile_picture': {
               description = '';
               break;
            }
            case 'original_file_backups':{
               description = 'Set your file backup settings';
               break;
            }
            default: {}
         }
         step['id'] = index;
         step['slug'] = slug;
         step['status'] = isCompleted;
         step['title'] = getStepTitle(slug);
         step['description'] = description;
         result.steps.push(step);
      });
   }
   return result;
};

const isFetchingSelector = (state) => {
   return state.onboarding.isFetching;
};

const openStepsModalSelector = (state) => {
   return state.onboarding.openStepsModal;
};

const dialogSelector = (state) => {
   return state.onboarding.dialog;
};

const isDomainStepCompletedSelector = (state) => {
   let completed = true;
   if(state.common.siteSteps && state.common.siteSteps.steps && !state.common.siteSteps.steps.domain) {
      completed = false;
   }
   return completed;
};

const isOpenAddContentModalSelector = (state) => {
   return state.onboarding.isOpenAddContentModal;
};

export {
   stepsSelector,
   isFetchingSelector,
   openStepsModalSelector,
   dialogSelector,
   isDomainStepCompletedSelector,
   isOpenAddContentModalSelector,
}
