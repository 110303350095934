import React from 'react';
import PropTypes from 'prop-types';
import ChatUserAvatar from 'common/components/modules/chat/chat-user-avatar';
import Button from 'common/components/elements/buttons/primary';

const NewMessageChat = ({
   data,
}) => {
   return (
      <div className='flex flex-col items-center my-auto justify-center'>
         <ChatUserAvatar
            className='h-[52px] w-[52px]'
            avatarSrc={  data.avatar }
            username={ data.username }
         />
         <span className='leading-tight text-placeholder dark:text-placeholder-dark mt-4 text-center w-full'>
            {data.text}
         </span>
         {
            data && data.button_text && (
               <div className='flex justify-between mt-8 items-center cursor-pointer'>
                  <Button
                     text={ data.button_text }
                     textSize='base'
                     classNames='h-full w-full intercom-more-info-on-chat'
                     iconName='attention-outline'
                     iconPosition='left'
                     alignment='end'
                     backgroundColor='transparent'
                     primaryColor
                     fontIconSize='large'
                     textPosition='left'
                     onClick={ data.action }
                  />
               </div>
            )
         }
      </div>
   );
};


NewMessageChat.propTypes = {
   data: PropTypes.object,
};

NewMessageChat.defaultProps = {
   member: {},
};

export default NewMessageChat;
