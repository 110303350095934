import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';
import DateFormatter from 'common/utils/DateFormatter';
import { isEmpty } from "lodash";

let reducersMap = {
   [types.CREATE_OR_UPDATE_RECORD_STREAM]: (state, action) => {
      const data = action.payload || {};
      let oldRecordedStreams = { ...state.recordedStreams };
      let oldRecordedStreamsData = [...oldRecordedStreams.data];
      const exist = oldRecordedStreamsData.findIndex(element => element.id === data.id);
      if(exist < 0){
         oldRecordedStreams = {
            ...oldRecordedStreams,
            data: [data, ...oldRecordedStreamsData],
         }

      } else {
         oldRecordedStreams = {
            ...oldRecordedStreams,
            data: [...oldRecordedStreamsData].map(e => {
               let { ...item } = e;
               if(item.id === data.id){
                  item = {
                     ...item,
                     ...data,
                  };
               }
               return item
            }),
         }
      }
      return {
         ...state,
         recordedStreams: oldRecordedStreams,
         isEmptyByFilterRecord: oldRecordedStreams.data.length === 0,
      }
   },
   [types.FETCH_INDEX_REQUEST]: (state, action) => {
      return {
         ...state,
         ...action.payload,
      }
   },
   [types.SET_DELET_RECORD_BUTTON_LOADING]: (state, action) => {
      const {
         bool,
         id,
      } = action.payload;
      let newState = {};
      if(id) {
         const data = [...state.recordedStreams.data].filter(e => e.id !== id)
         newState = {
            recordedStreams: {
               ...state.recordedStreams,
               data,
            },
         }
      }
      return {
         ...state,
         ...newState,
         deleteRecordButtonDisabled: bool,
      }
   },
   [types.UPDATE_CURRENT_DATA]: (state, action) => {
      const { data, initiator } = action.payload;
      const oldData = state.currentData;
      let newState = { ...data };
      let achievedState = {}
      if(initiator === 'tip') {
         const {
            tip_goal_price,
            total_tipped,
            tip_actions_data,
            tip_goal_is_on,
         } = data;
         const  oldTotal = +oldData.total_tipped || 0;
         if((oldTotal < tip_goal_price) && (total_tipped >= tip_goal_price)){
            achievedState = {
               ...achievedState,
               tipGoalAchievedModal: tip_goal_is_on,
            }
         }
         const achievedActions = [];
         tip_actions_data.forEach((element) => {
            const oldAction = oldData.tip_actions_data.find(el => el.id === element.id);
            const { total, action_goal_price, action_goal_is_on } = element;
            if(action_goal_is_on && (oldAction && +oldAction.total < +action_goal_price) && (+total >= +action_goal_price)){
               achievedActions.push(element)
            }
         });

         if(achievedActions.length > 0){
            achievedState = {
               ...achievedState,
               tipGoalActionsAchievedModal: { isOpen: true, actions: achievedActions },
            }
         }
         let newTipActions = [...state.currentData.tip_actions_data].map(item => {
            let { ...elm } = item
            let action = [...tip_actions_data].find(action => action.id === elm.id);
            if(action){
               elm.quantity = action.quantity;
               elm.total = action.total;
            }
            return elm
         })
         newState.tip_actions_data = newTipActions
      }
      return {
         ...state,
         ...achievedState,
         currentData: {
            ...state.currentData,
            ...newState,
         },
      }
   },
   [types.UPDATE_SAVED_DATA]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         savedData: {
            ...state.savedData,
            ...payload,
         },
      }
   },
   [types.SET_VALIDATION_ERROR]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         validationErrors: {
            ...state.validationErrors,
            ...payload,
         },
         fetchCheckValidation: false,
         fetchCreateLivestream: false,
      }
   },
   [types.FETCH_NEX_RECORDS_REQUEST]: (state, action) => {
      const {
         payload,
      } = action
      return {
         ...state,
         isNewFetchingRecords: !!payload,
      }
   },
   [types.FETCH_NEX_RECORDS_COMPLETED]: (state, action) => {
      const {
         payload,
      } = action;
      return {
         ...state,
         isNewFetchingRecords: false,
         recordedStreams: {
            ...payload,
            data: [...state.recordedStreams.data, ...payload.data],
         },
         isFetchingStartStream: false,
         isEmptyByFilterRecord: false,
         isEmptyByRecord: false,
      }
   },
   [types.UPDATE_DATA_COMPLETED]: (state, action) => {
      const {
         data,
         type,
      } = action.payload;
      let newState = {}
      if('scheduled' === type && 'scheduled_livestream_date' in data){
         newState = {
            initData: {
               ...state.initData,
               scheduled_date: data.scheduled_livestream_date,
            },
         }
      }
      return {
         ...state,
         ...newState,
         isFetchingCreateSchedule: false,
         [`${ type }Data`]: {
            ...state[`${ type }Data`],
            ...data,
         },
         updateErrorsData: null,
      }
   },
   [types.FETCH_LIVE_STREAM_MESSAGES]: (state, action) => {
      const {
         payload,
      } = action;
      return {
         ...state,
         isFetchingMessages: !!payload,
      }
   },
   [types.FETCH_LIVE_STREAM_MESSAGES_COMPLETED]: (state, action) => {
      const {
         payload,
      } = action;
      return {
         ...state,
         messageData: payload,
         isFetchingMessages: false,
      }
   },
   [types.SET_LIVE_STREAM_CONVERSATION_STATE]: (state, action) => {
      const {
         payload,
      } = action;
      return {
         ...state,
         conversationSatet: {
            ...state.conversationSatet,
            ...payload,
         },
      }
   },

   ///////////////////////////////////////////////////
   [types.ADD_MESSAGE]: (state, action) => {
      const {
         payload: { message, isMyMessage },
      } = action;
      let newState = {};

      if('tip' === message.type){
         const  tipHistoryPayload = {
            id: Math.random() * (700 - 2000) + 2000,
            member_id: message.user.id,
            amount: message.text.price,
            created_at: message.sent_at,
            member_info: {
               username: message.user.username,
               avatar: message.user.avatar_compressed_src,
               total_spent: message.user.total_spent,
               total_spent_sum: message.user.total_spent_sum,
               wallet_balance: message.user.wallet_balance,
               top_percentage: message.user.top_percentage,
               membership_status: message.user.membership_status,
               membership_name: message.user.membership_name,
               nickname: message.user.nickname,
            },
         }
         newState = {
            ...newState,
            tipHistory: {
               ...state.tipHistory,
               data: [
                  tipHistoryPayload,
                  ...state.tipHistory.data,
               ],
            },
         }
      }
      if(!isMyMessage) {
         let oldUnreamMessagesCount = state.streamConversation.unread_messages_count || 0
         newState = {
            ...newState,
            streamConversation: {
               ...state.streamConversation,
               unread_messages_count: ++oldUnreamMessagesCount,
            },
         }
      }
      return {
         ...state,
         ...newState,
         messageData: {
            ...state.messageData,
            data: [
               message,
               ...state.messageData.data,
            ],
         },
      }
   },
   [types.UPDATE_MESSAGE]: (state, action) => {
      const {
         payload: { messageId, message },
      } = action;
      let updateData = [...state.messageData.data].map(i => {
         let{ ...el } = i
         if(el.id === messageId){
            el = {
               ...el,
               ...message,
            }
         }
         return el
      })
      return {
         ...state,
         messageData: {
            ...state.messageData,
            data: updateData,
         },
      }
   },
   [types.SET_TYPER_ACTION]: (state, action) => {
      const {
         typers,
      } = action.payload;
      let newState = { ...state };
      newState = {
         ...newState,
         streamConversation: {
            ...state.streamConversation,
            typers: typers,
            user_is_typing: !isEmpty(typers),
         },
      }
      return newState;
   },

   [types.DELETE_MESSAGE_ACTION]: (state, action) => {
      const {
         payload,
      } = action;
      let updateData = [...state.messageData.data].filter(message => message.id !== payload);
      return {
         ...state,
         messageData: {
            ...state.messageData,
            data: updateData,
         },
      };
   },
   [types.LIKE_MESSAGE_ACTION]: (state, action) => {
      const {
         isLiked,
         messageId,
         isMyLiked,
      } = action.payload
      let data = [...state.messageData.data].map(elm => {
         let { ...i } = elm
         if(i.id === messageId){
            let count = elm.likes_count_from_other_users || 0
            if(isLiked) {
               count = ++count
            }
            if(!isLiked && count > 0) {
               count = --count
            }
            if(!isMyLiked) {
               i = {
                  ...i,
                  likes_count_from_other_users: count,
               }
            }
            if(isMyLiked) {
               i = {
                  ...i,
                  is_liked_by_user: isLiked,
               }
            }
         }
         return i
      })
      return {
         ...state,
         messageData: {
            ...state.messageData,
            data: data,
         },
      };
   },
   [types.SET_IS_CONNECTED_ACTION]: (state, action) => {
      return {
         ...state,
         isConnected: action.payload,
      }
   },
   [types.SET_ONLINE_USERS_ACTION]: (state, action) => {
      const {
         data, action: memberAction,
      } = action.payload
      let users = [...state.onlineUsers]
      switch (memberAction) {
         case 'push':
            users = [...users, { ...data }]
            break;
         case 'pop':
            users = [...users].filter(item => item.uuid !== data.uuid)
            break;

         default:
            users = [...data]
            break;
      }
      return {
         ...state,
         onlineUsers: users,
      };
   },

   [types.FETCH_LIKED_MEMBER_BY_MESSSAGE_REQUEST]: (state, action) => {
      let newData = {}
      if(!!action.payload) {
         newData = {
            fetchingScroll: false,
         }
      } else {
         newData = {
            fetching: false,
         }
      }
      return {
         ...state,
         likedMemberByMessage: {
            ...newData,
            data: [],
         },
      };
   },
   [types.FETCH_LIKED_MEMBER_BY_MESSSAGE_COMPLETED]: (state, action) => {
      const {
         data,
         isPagination,
      } = action.payload

      let newData = {
         ...data,
      }
      if(isPagination) {
         newData = {
            ...newData,
            fetchingScroll: false,
         }
      } else {
         newData = {
            ...newData,
            fetching: false,
         }
      }
      return {
         ...state,
         likedMemberByMessage: {
            ...newData,
         },
      };
   },
   [types.FETCH_LIKED_MEMBER_BY_MESSSAGE_FAILED]: (state, action) => {
      return {
         ...state,
         likedMemberByMessage: {
            fetching: false,
            fetchingScroll: false,
            data: [],
         },
      };
   },

   [types.FETCH_NEW_MESSAGES_REQUEST]: (state) => {
      return {
         ...state,
         isFetchNewConversationMessages: true,
      };
   },
   [types.FETCH_NEW_MESSAGES_COMPLETED]: (state, action) => {
      let data = [...state.messageData.data, ...action.payload.data]
      return {
         ...state,
         isFetchNewConversationMessages: false,
         messageData: {
            ...action.payload,
            data: data,
         },
      };
   },
   [types.FETCH_NEW_MESSAGES_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchNewConversationMessages: false,
      };
   },
   [types.SET_STREAM_CONVERASTION_INFO]: (state, action) => {
      return {
         ...state,
         streamConversation: {
            ...state.streamConversation,
            ...action.payload,
         },
      };
   },
   [types.SET_TIP_GOALL_ACHIEVED_MODAL]: (state, action) => {
      return {
         ...state,
         tipGoalAchievedModal: !!action.payload,
      };
   },
   [types.SET_TIP_ACTIONS_GOALL_ACHIEVED_MODAL]: (state, action) => {
      return {
         ...state,
         tipGoalActionsAchievedModal: {
            ...state.tipGoalActionsAchievedModal,
            ...action.payload,
         },
      };
   },
   [types.SET_TIP_HISTORY_ACTION]: (state, action) => {
      return {
         ...state,
         tipHistory: {
            ...state.tipHistory,
            ...action.payload,
         },
      };
   },
   [types.ACTIVE_VIEWER_CLEAR_FILTER]: (state) => {
      const initalFilter = {
         access: {
            name: 'Access',
            value: 'all',
         },
         memberships: { name: '', value: [] },
         status: { name: '', value: [] },
         sortBy: {
            name: 'Newest',
            value: 'date_added:desc',
         },
      }
      const newActiveViewers = filterActiveViewer(initalFilter, state.activeViewers, true)
      return {
         ...state,
         activeViewers: newActiveViewers,
         activeViewersFilter: initalFilter,
      };
   },
   [types.SET_ACTIVE_VIEWER_STATES]: (state, action) => {
      let newActiveViewers = [...action.payload];
      const oldActiveViewers = [...state.activeViewers];
      const oldActiveViewersId =  [...oldActiveViewers].reduce(
         (previousValue, currentValue) => {
            if(currentValue.uuid) {
               return previousValue = [...previousValue, currentValue.uuid];
            }
            return previousValue;
         },
         []
      );
      newActiveViewers = [...newActiveViewers].map(item => {
         const { ...viewer } = item;
         if(oldActiveViewersId && !oldActiveViewersId.includes(viewer.uuid)){
            viewer.new_user = true
         } else {
            viewer.new_user = false
         }
         return viewer
      });

      newActiveViewers = filterActiveViewer(state.activeViewersFilter, newActiveViewers)
      return {
         ...state,
         activeViewers: newActiveViewers,
      }
   },
   [types.ACTIVE_VIEWER_FILTER]: (state, action) => {
      const filter = action.payload

      const {
         key,
         value,
         name,
      } = filter;
      const activeViewersFilter = {
         ...state.activeViewersFilter,
         [key]: { value, name },
      }
      const newActiveViewers = filterActiveViewer(activeViewersFilter, state.activeViewers, true)
      return {
         ...state,
         activeViewers: newActiveViewers,
         activeViewersFilter,
      }
   },

   [types.SET_WARNING_ACTIVITY_MODAL]: (state, action) => {
      return {
         ...state,
         warningActivityModal: {
            ...state.warningActivityModal,
            ...action.payload,
         },
      }
   },
   [types.SET_DELETE_UPCOMING_STATE]: (state, action) => {
      return {
         ...state,
         deleteUpcomingState: {
            ...state.deleteUpcomingState,
            ...action.payload,
         },
      }
   },
   [types.FETCH_CHECK_VALISATION_REQUEST]: (state, action) => {
      let newState = {};
      if(action.payload){
         newState = {
            validationErrors: {},
         }
      }
      return {
         ...state,
         ...newState,
         fetchCheckValidation: action.payload,
      }
   },
   [types.FETCH_CREATE_LIVESTREAM_REQUEST]: (state, action) => {
      return {
         ...state,
         fetchCreateLivestream: action.payload,
         inited: false,
      }
   },
   [types.SET_DELETE_UPCOMING_COMPLETED]: (state, action) => {
      return {
         ...state,
         deleteUpcomingState: {
            isOpen: false,
            isLoading: false,
         },
         initData: {
            ...state.initData,
            scheduled_date: null,
         },
         scheduleData: action.payload,
      }
   },
   [types.UPDATE_TIP_ACTION_DATA]: (state, action) => {
      const {
         id,
         data,
      } = action.payload;
      return {
         ...state,
         currentData: {
            ...state.currentData,
            tip_actions_data: [...state.currentData.tip_actions_data].map(el => {
               let { ...action } = el;
               if(id === action.id){
                  action = {
                     ...action,
                     ...data,
                  }
               }
               return action
            }),
         },
      }
   },
   [types.REORDER_RECORDED_STREAMS]: (state, action) => {
      return {
         ...state,
         recordedStreams: {
            ...state.recordedStreams,
            data: action.payload,
         },
      }
   },
   [types.PIN_RECORDED_STREAM_COMPLETED]: (state, action) => {
      const { payload: { id, bool } } = action;

      let data  = [...state?.recordedStreams.data].sort(function(item1, item2){
         if(item1.id === id)  item1.is_pinned = !bool
         if(item2.id === id)  item2.is_pinned = !bool
         if(item1.id === id) {
            if(bool){
               return item2.is_pinned ? -1 : new Date(item1.publish_date) - new Date(item2.publish_date)
            }
            return -1
         } else if(item2.id === id) {
            if(bool){
               return item1.is_pinned ? -1 : new Date(item2.publish_date) - new Date(item1.publish_date)
            }
            return 1
         } else if(item1.id !== id && item2.id !== id){
            return  item1.is_pinned && !item2.is_pinned ? -1 ? item2.is_pinned && !item1.is_pinned  : 1 : 0
         } else {
            return 0
         }
      });
      if(bool) {
         data  = [...data].sort(function(item1, item2){
            return item1.is_pinned ? -1 : item2.is_pinned ? 1 : 0
         })

      }
      return {
         ...state,
         recordedStreams: {
            ...state?.recordedStreams,
            data: data,
         },
      }
   },
   [types.SET_WARNING_MODAL]: (state, action) => {
      return {
         ...state,
         warningModal: {
            ...state.warningModal,
            ...action.payload,
         },
      }
   },
   [types.UPDATE_LIVESTREAM_MESSAGES_BY_ID]: (state, action) => {
      const {
         username,
         member_nickname,
      } = action.payload;

      let updatedMessagesData = state?.messageData?.data

      if(!!updatedMessagesData) {
         updatedMessagesData = updatedMessagesData
            .map((msg) => {

               if(msg?.user?.username === username) {
                  return {
                     ...msg,
                     user: {
                        ...msg?.user,
                        nickname: member_nickname,
                     },
                  }
               } else {
                  return msg
               }

            })
            .map((msg) => {
               if(msg?.parent && msg?.parent?.user?.username === username) {
                  return {
                     ...msg,
                     parent: {
                        ...msg?.parent,
                        user: {
                           ...msg?.parent?.user,
                           nickname: member_nickname,
                        },
                     },
                  }
               } else {
                  return msg
               }
            })
      }


      let updatedActiveViewers = state?.activeViewers

      updatedActiveViewers = updatedActiveViewers
         ?.map(viewer => {
            if(viewer?.username === username) {
               return {
                  ...viewer,
                  nickname: member_nickname,
               }
            } else {
               return viewer
            }
         })

      let updatedTipHistory = state?.tipHistory.data

      updatedTipHistory = updatedTipHistory
         ?.map(item => {
            if(item?.member_info?.username === username) {
               return {
                  ...item,
                  member_info: {
                     ...item?.member_info,
                     nickname: member_nickname,
                  },
               }
            } else {
               return item
            }
         })

      return {
         ...state,
         messageData: {
            ...state.messageData,
            data: updatedMessagesData,
         },
         activeViewers: updatedActiveViewers,
         tipHistory: {
            ...state?.tipHistory,
            data: updatedTipHistory,
         },
      }
   },
   [types.UPDATE_LIVESTREAM_INITED_STATE]: (state, action) => {
      return {
         ...state,
         inited: action.payload,
      }
   },
   [types.UPDATE_FILTERS_INSIDE_MODAL]: (state, action) => {
      return {
         ...state,
         filtersInsideModal: action.payload,
      }
   },
}

function filterActiveViewer(filters, data, bool){
   let result = [...data];
   // eslint-disable-next-line array-callback-return
   result = [...result].map(item => {
      let { ...elm } = item;
      elm.hidden = false;
      if(bool) {
         elm.new_user = false;
      }
      const memberships = filters.memberships?.value;
      const access = filters.access?.value;
      const status = filters.status?.value;
      if(access && access !== 'all') {
         if((access === 'unlocked' && Boolean(item.purchasedAccessPrice)) || (access === 'paid' && !Boolean(item.purchasedAccessPrice))){
            elm.hidden = true;
         }
      }
      if(memberships && memberships.length > 0 && !memberships.includes(item.pricing_id)) {
         elm.hidden = true;
      }
      if(status && status.length > 0 && !status.includes(item.member_status)) {
         elm.hidden = true;
      }

      return elm
   });
   const sortValud = filters.sortBy?.value || 'date_added:desc';
   result  = [...result].sort(function(a, b){
      let sortItem1 = '';
      let sortItem2 = '';
      switch (sortValud) {
         case 'date_added:desc':  // Newest
            sortItem1 = DateFormatter.convertToUtc(a.start_watching);

            sortItem2 = DateFormatter.convertToUtc(b.start_watching);
            break;
         case 'date_added:asc': // Oldest
            sortItem1 = DateFormatter.convertToUtc(b.start_watching);
            sortItem2 = DateFormatter.convertToUtc(a.start_watching);
            break;
         case 'name:desc': // Z-A
            sortItem1 = a.username.toLowerCase();
            sortItem2 = b.username.toLowerCase();
            break;
         case 'name:asc': // A-Z
            sortItem1 = b.username.toLowerCase();
            sortItem2 = a.username.toLowerCase();
            break;

         default:
            break;
      }

      if(sortItem1 < sortItem2) { return 1; }
      if(sortItem1 > sortItem2) { return -1; }
      return 0;
   })

   return result;
}


export default createReducer(initialState)(reducersMap);
