import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import GenerateColor from 'common/utils/generateTextColor.js'
import './style.scss';

function ProgresSector({ angle, fill }) {
   const sectorElement = React.createRef();

   function createSector() {
      function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
         const angleInRadians = (angleInDegrees - 25) * Math.PI / 50;
         return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians)),
         };
      }

      // sector
      const opts = {
         cx: 10,
         cy: 10,
         radius: 10,
         start_angle: 0,
         end_angle: angle,
      };

      const start = polarToCartesian(opts.cx, opts.cy, opts.radius, opts.end_angle);

      const end = polarToCartesian(opts.cx, opts.cy, opts.radius, opts.start_angle);

      const largeArcFlag = opts.end_angle - opts.start_angle <= 50 ? '0' : '1';

      const d = [
         'M', start.x, start.y,
         'A', opts.radius, opts.radius, 0, largeArcFlag, 0, end.x, end.y,
         'L', opts.cx, opts.cy,
         'Z',
      ].join(' ');

      sectorElement.current.setAttribute('d', d);
   }

   useEffect(() => {
      createSector();
   });

   let color = fill;
   const generateWaveColor = GenerateColor.generateRGBColor(color, 0.8)
   return (
      <div
         style={ {
            borderColor: generateWaveColor,
         } }
         className={ `progres-sector ${ fill  === 'white' ? 'mr-2' : '' }` }
      >
         <svg viewBox='0 0 20 20' width='20' height='20'>
            <path ref={ sectorElement } fill={ fill } stroke='none' fillRule='evenodd' />
         </svg>
      </div>
   );
}

ProgresSector.propTypes = {
   angle: PropTypes.number,
   fill: PropTypes.string,
};
ProgresSector.defaultProps = {
   fill: 'white',
   angle: 0,
};

export default memo(ProgresSector);
