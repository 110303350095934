const initialState = {
   isFetching: true,
   isFetchingSend: false,
   isFetchingStatistics: false,
   isFetchingActiveMemberships: false,
   activeTab: 'recipients',
   receiversStatistics: {},
   activeMemberships: [],
   sendData: {

      message: {
         subject: "",
         body: "",
         bcc: true,
         media: {},
      },
   },

   // mailing list
   isFetchingMembersList: true,
   isNewFetchingMembersList: false,
   isFetchingByFilterMembersList: false,
   isEmptyMembersList: false,
   isEmptyByFilterMembersList: false,
   membersMailingList: {},

   isFetchingNonMembersList: true,
   isNewFetchingNonMembersList: false,
   isEmptyNonMembersList: false,
   isFetchingByFilterNonMembersList: false,
   isEmptyByFilterNonMembersList: false,
   nonMembersMailingList: {},
   tagsToBeSent: [],

   isEmpty: true,
   isEmptyByMessagesFilter: true,
   isFetchingSentMessages: true,
   messagesData: {
      data: [],
   },
   meta: {},

   isFetchingByFilter: false,
   messagesFilters: {},

   isFetchingMessagesNextPage: false,
   isFetchingUnsendMessage: false,

   updateData: null,
};

export default initialState;
