const initialState = {
   // shippingZone: {
   //    name: '',
   //    countries: [],
   //    error: {
   //       name: '',
   //       countries: '',
   //    },
   // },
   name: '',
   countries: [],
   zonesArray: {
      0: {
         name: '',
         rate_type: 'free',
         estimated_delivery_time: '',
         rate: 0,
      },
   },
   error: {
      name: '',
      countries: '',
   },
};

export default initialState;

// {
//    "name": "Name",
//    "countries": ["US"],
//    "shipping_options": [
//        {
//            "name": "name1",
//            "rate_type": "free",
//            "estimated_delivery_time": "sdfsdfsd"
//        },
//        {
//            "name": "sdfsdf",
//            "rate_type": "flat",
//            "estimated_delivery_time": "sdfsdfsd",
//            "rate": 50
//        }
//    ]
// }
