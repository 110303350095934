export const ADD_VIDEOS = "uploads/ADD_VIDEOS";
export const TOGGLE_VIDEO_UPLOAD_LIMIT = "uploads/TOGGLE_VIDEO_UPLOAD_LIMIT";
export const TOGGLE_INCREASE_MY_LIMIT_CLICKED = "uploads/TOGGLE_INCREASE_MY_LIMIT_CLICKED";
export const UPDATE_OR_ADD_VIDEO = "uploads/UPDATE_OR_ADD_VIDEO";
export const REMOVE_VIDEO = "uploads/REMOVE_VIDEO";
export const RESET_VIDEOS = "uploads/RESET_VIDEOS";
export const RESET_PHOTOSTES = "uploads/RESET_PHOTOSTES";
export const ADD_OR_UPDATE_PHOTOSTES = "uploads/ADD_OR_UPDATE_PHOTOSTES";
export const ADD_UPLOADERS = "uploads/ADD_UPLOADERS";

export const DESTORY_MEDIA_COMPLETED = "uploads/DESTORY_MEDIA_COMPLETED";
export const DESTORY_MEDIA_REQUEST = "uploads/DESTORY_MEDIA_REQUEST";

