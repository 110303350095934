
import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'common/components/elements/icons';
import './style.scss'
import cx from 'classnames'

const PremiumLabel = ({
   text,
   className,
   isPreRelease,
   noWrap,
   textClassName,
   labelColor,
   labelBackground,
   paddingY,
   paddingX,
   showPremiumLabel,
   showNewStyle,
   iconSize,
}) => {
   let textColor = 'major-dark'
   let bgColor = 'warning'

   if(showNewStyle) {
      bgColor = 'transparent'
   }

   if(!isPreRelease && !showPremiumLabel && !text){
      return null
   }
   return (
      <>
         {
            (isPreRelease || showPremiumLabel) && (
               <div
                  className={ `items-center ${ !!labelBackground ? `bg-warning-12` : `bg-${ bgColor }` } rounded-full py-${ paddingY } ${ !!paddingX ? `px-${ paddingX }` : 'pl-2 pr-3' }  media-premium-label flex mb-2 ${ className }` }
               >
                  <div className='h-4 w-4 flex items-center justify-center'>
                     {
                        !isPreRelease &&
                        <Icon
                           name='membership'
                           color={ !!labelColor ? labelColor : textColor }
                           darkColor={ !!labelColor ? labelColor : textColor }
                           size={ iconSize }
                        />
                     }
                     {
                        isPreRelease &&
                        <Icon
                           name='launch'
                           color={ !!labelColor ? labelColor : textColor }
                           darkColor={ !!labelColor ? labelColor : textColor }
                           size={ iconSize }
                        />
                     }
                  </div>
                  <span className={ cx({
                     [`text-${ !!labelColor ? labelColor : textColor } font-medium text-sm premium-text`]: true,
                     'text-major-dark': showNewStyle,
                  }) }> {isPreRelease ? 'Prerelease' : 'Premium'}</span>
               </div>

            )
         }
         {
            text &&
            <span className={ `leading-tight text-secondary dark:text-secondary-dark text-center ${ textClassName } ${ noWrap ? 'whitespace-nowrap' : '' }` }>{text}</span>
         }
      </>
   )
}

PremiumLabel.propTypes = {
   text: PropTypes.string,
   className: PropTypes.string,
   isPreRelease: PropTypes.bool,
   noWrap: PropTypes.bool,
   textClassName: PropTypes.string,
   labelColor: PropTypes.string,
   labelBackground: PropTypes.string,
   paddingY: PropTypes.string,
   paddingX: PropTypes.string,
   showPremiumLabel: PropTypes.bool,
   showNewStyle: PropTypes.bool,
   iconSize: PropTypes.string,
}
PremiumLabel.defaultProps = {
   whithOpacit: false,
   className: '',
   paddingY: '1',
   showPremiumLabel: false,
   showNewStyle: false,
   iconSize: 'xl',
}
export default PremiumLabel
