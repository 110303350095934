import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { screenWidthSelector, twitterAccountSelector, siteInfoSelector, authUserSelector } from 'admin/state/modules/common/selectors';
import PageLoader from 'common/components/modules/page-loading';
import OutgoingNotificationsAndAutoTweetEdit from 'admin/views/my-site/settings/outgoing-notification-and-auto-tweet/edit';
import { getSettingsInfo } from 'admin/views/my-site/settings/outgoing-notification-and-auto-tweet/data';
import Router from 'admin/routes/router';
import {
   settingBySlugSelector,
   isFetchingBySlugSelector,
   isFetchingUpdateSelector,
   updateErrorSelector,
} from 'admin/state/modules/outgoing-notifications/selectors';
import {
   getSettingsBySlugOperation,
   updateSettingOperation,
} from 'admin/state/modules/outgoing-notifications/operations';
import {
   clearUpdateErrorStateAction,
} from 'admin/state/modules/outgoing-notifications/actions';
import {
   getTwitterRedirectUrl,
} from 'admin/api/AuthApi';
import toster from 'common/utils/toast';

class OutgoingNotificationsAndAutoTweetEditContainer extends Component {
   static propTypes = {
      screenWidth: PropTypes.number,
      isFetching: PropTypes.bool,
      isFetchingUpdate: PropTypes.bool,
      match: PropTypes.object,
      data: PropTypes.object,
      fetchData: PropTypes.func,
      updateSetting: PropTypes.func,
      goTo: PropTypes.func,
      twitterAccount: PropTypes.object,
      siteInfo: PropTypes.object,
      authUser: PropTypes.object,
      errors: PropTypes.object,
      clearErrorState: PropTypes.func,
      isInsideModal: PropTypes.bool,
      insideModalSlug: PropTypes.bool,
      insideModalCallBack: PropTypes.func,
      insideModalDefaultData: PropTypes.object,
   }

   slug = null;
   eventName = null;
   variables = [];
   state = {
      fetchingConnectedTwitterAccount: false,
   }

   componentDidMount(){
      const { fetchData, isInsideModal, insideModalSlug, insideModalDefaultData } = this.props
      const layout = document.querySelector('.layout');
      let slug = ''
      let slugForCustomCategories = null

      if(layout){
         layout.classList.add('layout-mobile-background')
      }

      if(!!isInsideModal) {
         slug = insideModalSlug
      } else {
         const { match: { params: { slug: urlSlug } } } = this.props;
         slug = urlSlug
      }

      this.slug = slug


      if(slug.match('store_custom_category')) {
         slugForCustomCategories = 'custom_store_category'
      }

      const slugInfo = getSettingsInfo()?.[!!slugForCustomCategories ? slugForCustomCategories : slug];
      const title = slugInfo?.title;

      if(title){
         this.onSetPageTitile(title)
      }
      this.variables = slugInfo?.variables || [];

      fetchData(slug, (data) => {
         if(data && data.is_custom && data.title){
            this.onSetPageTitile(data.title)
         }
      }, isInsideModal, insideModalDefaultData);
   }


   onSetPageTitile = (title) => {
      Router.route('MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS_EDIT').setTitle(title);
      this.eventName = title;
      const documentTitle = `Dashboard - ${ title }`;
      if(window.document.title !== documentTitle){
         window.document.title = documentTitle;
      }
   }

   componentWillUnmount(){
      const layout = document.querySelector('.layout');
      if(layout){
         layout.classList.remove('layout-mobile-background')
      }
   }
   goBack = (bool) => {
      if(window.opener){
         const previousPage = window.opener.location.href;
         window.open(previousPage, '_self');
         window.close();
         if(bool){
            const { data } = this.props;
            const event = new CustomEvent('outgoing-notifications-and-auto-tweets-update', {
               detail: {
                  payload: data,
               },
            })

            window.opener.dispatchEvent(event);
         }
         return;
      }
      const { goTo } = this.props;
      goTo(Router.route('MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS').getCompiledPath());
   }


   onConnectToTwitterAccount = async () => {
      const referrerUrl = {
         route_name: 'MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS_EDIT',
         route_slug: this.slug,
         url: window.location.href,
      }
      this.setState({ fetchingConnectedTwitterAccount: true })
      if(referrerUrl) {
         localStorage.setItem('twitter', JSON.stringify(referrerUrl))
      }
      try {
         const { data } = await getTwitterRedirectUrl();
         const { url } = data;
         window.location.replace(url);
      } catch (error) {
         toster.error(' Something went wrong');
         this.setState({ fetchingConnectedTwitterAccount: false })
      }
   }

   nonContentNotification = () => {
      return [
         'membership_new_join',
         // 'membership_new_join_with_public_promotion',
         'membership_cancellation',
         'membership_rebill_failure',
         'membership_expiration_due_to_failed_rebill',
         'membership_expirations',
         'tip',
         // 'tip_during_livestream',
         // 'tip_on_content',
      ].includes(this.slug);
   }
   render() {
      const {
         isFetching,
         screenWidth,
         data,
         updateSetting,
         isFetchingUpdate,
         twitterAccount,
         siteInfo,
         authUser,
         errors,
         clearErrorState,
         isInsideModal,
         insideModalCallBack,
      } = this.props;
      if(isFetching){
         return <PageLoader withLoader={ isInsideModal } customStyle='w-full h-full' />
      }
      const { fetchingConnectedTwitterAccount } = this.state;
      return (
         <OutgoingNotificationsAndAutoTweetEdit
            screenWidth={ screenWidth }
            data={ data }
            onSaveData={ (data, callBack) => updateSetting(this.slug, data, () => {
               if(callBack){
                  callBack();
               }
               this.goBack(true)
            }) }
            isFetching={ isFetchingUpdate }
            goBack={ this.goBack }
            twitterAccount={ twitterAccount }
            onClickConnectTwitterAccount={ this.onConnectToTwitterAccount }
            fetchingConnectedTwitterAccount={ fetchingConnectedTwitterAccount }
            isNonContent={ this.nonContentNotification() }
            eventName={ this.eventName }
            individualVariables={ this.variables }
            siteInfo={ {
               siteName: siteInfo.site_long_name,
               screenName: authUser.screen_name,
               adminsState: siteInfo.single_or_multiple_admins,
               url: siteInfo.site_url,
            } }
            errors={ errors }
            clearErrorState={ clearErrorState }
            isInsideModal={ isInsideModal }
            insideModalCallBack={ insideModalCallBack }
            isDemoMode={ siteInfo?.site_status === 'demo_mode' }
         />
      )
   }
}
const mapStateToProps = (state) => ({
   screenWidth: screenWidthSelector(state),
   data: settingBySlugSelector(state),
   isFetching: isFetchingBySlugSelector(state),
   isFetchingUpdate: isFetchingUpdateSelector(state),
   twitterAccount: twitterAccountSelector(state),
   siteInfo: siteInfoSelector(state),
   authUser: authUserSelector(state),
   errors: updateErrorSelector(state),

})
const mapDispatchToProps = (dispatch) => {
   return {
      updateSetting: (slug, data, callBack) => dispatch(updateSettingOperation(slug, data, callBack)),
      fetchData: (slug, callback, isInsideModal, insideModalDefaultData) => dispatch(getSettingsBySlugOperation(slug, callback, isInsideModal, insideModalDefaultData)),
      clearErrorState: data => dispatch(clearUpdateErrorStateAction(data)),
      goTo: (location) => {
         dispatch(push(location))
      },
   }
}


export default connect(mapStateToProps, mapDispatchToProps)(OutgoingNotificationsAndAutoTweetEditContainer);
