import React from 'react';
import Icon from 'common/components/elements/icons';
import ProgresSector from './progress';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import { getSiteNameAbbreviatur } from 'common/utils/helper';
import './style.scss';
import cx from 'classnames';

const OnboardingProgressBar = ({
   status,
   percentage,
   isHomePage,
   openStepsModal,
   stepsList,
}) => {
   const statusData = {
      in_progress: {
         color: 'pending',
         is_percentage: true,
         text: 'In progress •',
         selector_fill: '#FFD439',
      },
      ready_to_submit_for_review: {
         icon: 'circle-checked',
         color: 'success',
         text: 'Ready to submit for review',
      },
      ready_for_approval: {
         icon: 'circle-checked',
         color: 'success',
         text: 'Ready to submit for approval',
      },
      submitted_for_review: {
         icon: 'circle-checked',
         color: 'success',
         text: 'Submitted for review',
      },
      ams_reviewing: {
         icon: 'clock',
         color: 'action',
         text: `${ getSiteNameAbbreviatur() } reviewing`,
      },
      submitted_to_payment_processors: {
         icon: 'clock',
         color: 'action',
         text: ' Submitted to payment processors for review ',
      },
      ready_to_launch: {
         icon: 'circle-checked',
         color: 'success',
         text: 'Ready to launch',
      },
      additional: {
         color: 'pending',
         is_percentage: true,
         text: 'Additional steps required •',
         selector_fill: '#FFD439',
      },
   }
   const value = statusData[status] || {}

   const completedStepsCount = stepsList.filter(item => item.status).length
   return (
      <div
         className={ cx({
            'progress-bar flex w-full items-center relative': true,
            'cursor-pointer': !isHomePage,
         }) }
         onClick={ () => {
            if(!isHomePage) {
               openStepsModal();
            }
         } }
         role='presentation'
      >
         <div className={ `absolute top-0 left-0 w-full h-full bg-${ value.color }-12` } />
         <div className='w-full flex items-center' >
            <div className='flex progress-bar-content'>
               <div className='w-5 h-5 flex justify-center items-center'>
                  {
                     !!value.is_percentage  && (
                        <ProgresSector
                           angle={ percentage }
                           fill={ value.selector_fill }  />
                     )
                  }
                  {
                     value.icon && (
                        <Icon name={ value.icon } color={ value.color } style={ { fontSize: '18px', height: '18px' } } />

                     )
                  }
               </div>
               <div className='ml-3 flex items-center progress-bar-title'>
                  <p>
                     <span className='text-major dark:text-major-dark font-medium leading-tight'> Site status: </span>
                     <span className='text-major dark:text-major-dark leading-tight'> { value.text }</span>
                     {
                        !!value.is_percentage && <span className='text-placeholder dark:text-placeholder-dark leading-tight'> {completedStepsCount}/{stepsList.length} </span>
                     }
                  </p>
               </div>
            </div>
         </div>
         {
            !isHomePage && (
               <div className='h-6 w-6'>
                  <Button
                     iconName='arrow-down'
                     backgroundColor='transparent'
                     fontIconColor='secondary'
                     darkIconcolor='secondary-dark'
                     padding='none'
                     classNames='h-full w-full'
                  />
               </div>
            )
         }
      </div>
   );
};

OnboardingProgressBar.propTypes = {
   status: PropTypes.string,
   percentage: PropTypes.any,
   isHomePage: PropTypes.bool,
   openStepsModal: PropTypes.func,
   stepsList: PropTypes.array,
};
OnboardingProgressBar.defaultProps = {
   status: 'in_progress',
   className: '',
};

export default React.memo(OnboardingProgressBar);
