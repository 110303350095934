import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Router from 'admin/routes/router';
import { currentLocationPathSelector } from 'admin/state/modules/router/selectors';
import UploadBar from 'common/components/modules/upload-bar';
import {
   videoUploadsSelector,
   uploadingVideosCountSelector,
   uploadBarStatusSelector,
   isVideoUploadLimitReachedSelector,
   complitedUploadsCountSelector,
} from 'admin/state/modules/uploads/selectors';
import {
   updateOrAddVideo,
   toggleVideoUploadLimit,
} from 'admin/state/modules/uploads/actions';
import VideoUploadLimitReachedModal from 'common/components/modules/video-limit-modal';
import intercomMoreInfo, { INCREASE_VIDEO_LIMIT } from 'admin/utils/IntercomMoreInfo';
import { screenWidthSelector } from 'admin/state/modules/common/selectors';
import QueryParams from 'admin/utils/QueryParams';


class UploadBarContainer extends Component {
   static propTypes = {
      locationPath: PropTypes.string.isRequired,
      goTo: PropTypes.func.isRequired,
      videoUploads: PropTypes.array.isRequired,
      uploadBarStatus: PropTypes.string.isRequired,
      isVideoUploadLimitReached: PropTypes.bool.isRequired,
      closeVideoUploadLimitView: PropTypes.func.isRequired,
      videoUpdate: PropTypes.func.isRequired,
      screenWidth: PropTypes.number,
      uploadingVideosCount: PropTypes.number,
      complitedUploadsCount: PropTypes.number,
   }

   componentDidMount() {
      window.addEventListener('content-update', this.bindSocketEvents);
   }

   componentWillUnmount() {
      window.removeEventListener('content-update', this.bindSocketEvents);
   }

   bindSocketEvents = e => {
      const { videoUpdate, videoUploads } = this.props;
      const { detail: { payload } } = e
      const { id, socket_emit_type, replacement_id } = payload;
      const exist = videoUploads && videoUploads.find(item => item.id === id);
      if(!socket_emit_type && !!exist && !replacement_id) {
         videoUpdate(id, payload);
      }
   }

   handleOnClickBar = () => {
      const { locationPath, goTo } = this.props;
      if(false === !!Router.route('UPLOADS').match(locationPath)) {
         goTo(Router.route('UPLOADS').getCompiledPath());
      }
   }

   handleVideoUploadLimitModalCancel = () => {
      const { closeVideoUploadLimitView } = this.props;
      closeVideoUploadLimitView();
      if('reachedlimit' === QueryParams.getParam('open-modal')){
         QueryParams.removeQueryParam('open-modal');
      }
   }

   handleIncreaseVideolimit = () => {
      intercomMoreInfo(INCREASE_VIDEO_LIMIT);
   }

   render(){
      const {
         locationPath,
         complitedUploadsCount,
         uploadBarStatus,
         isVideoUploadLimitReached,
         uploadingVideosCount,
         screenWidth,
      } = this.props;
      const disabledRoutes = !!Router.route('VIDEOS_PUBLISH').match(locationPath);
      if(0 === complitedUploadsCount || screenWidth > 700 || disabledRoutes) {
         return null;
      }
      return (
         <>
            {
               isVideoUploadLimitReached && !Router.route('UPLOADS').match(locationPath) && (
                  <VideoUploadLimitReachedModal
                     onIncreaseMylimit={ this.handleIncreaseVideolimit }
                     onCancel={ this.handleVideoUploadLimitModalCancel }
                  />
               )
            }
            <UploadBar
               onClickBar={ this.handleOnClickBar }
               status={ uploadBarStatus }
               inProgressOptions={ {
                  showArrow: false === !!Router.route('UPLOADS').match(locationPath),
                  count: uploadingVideosCount,
               } }
            />
         </>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      locationPath: currentLocationPathSelector(state),
      videoUploads: videoUploadsSelector(state),
      complitedUploadsCount: complitedUploadsCountSelector(state),
      uploadingVideosCount: uploadingVideosCountSelector(state),
      uploadBarStatus: uploadBarStatusSelector(state),
      isVideoUploadLimitReached: isVideoUploadLimitReachedSelector(state),
      screenWidth: screenWidthSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      goTo: (location) => {
         dispatch(push(location));
      },
      closeVideoUploadLimitView: () => {
         dispatch(toggleVideoUploadLimit(false));
      },
      videoUpdate: (id, video) => {
         dispatch(updateOrAddVideo(id, video));
      },
   }
}


export default connect(mapStateToProps, mapDispatchToProps)(UploadBarContainer);
