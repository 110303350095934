import {
   fetchDetailsStart,
   fetchDetailsFailed,
   fetchDetailsCompleted,

   newFetchRequest,
   newFetchRequestCompleted,
   newFetchRequestFailed,

   fetchMemberStart,
   fetchMemberCompleted,
   fetchMemberFailed,

   filterMembers,
   filterMembersCompleted,
   filterMembersFailed,

   statisticsRequest,
   statisticsRequestCompleted,
   statisticsRequestFailed,

   fetchMembershipsStart,
   fetchMembershipsCompleted,
   fetchMembershipsFailed,

   addFreeMemberRequest,
   addFreeMemberCompleted,
   addFreeMemberFailed,

   addNewPersonRequest,
   addNewPersonCompleted,
   addNewPersonFailed,
   updateMembersRequestCompleted,
   updateMemberFailed,

   fetchMemberWalletHistoryStart,
   fetchMemberWalletHistoryCompleted,
   fetchMemberWalletHistoryFailed,

   newFetchMemberWalletHistoryStart,
   newFetchMemberWalletHistoryCompleted,
   newFetchMemberWalletHistoryFailed,

   fetchMemberTagsStart,
   fetchMemberTagsCompleted,
   fetchMemberTagsFailed,
   setActiveMemberData,

   addNoteStart,
   addNoteCompleted,
   addNoteFailed,
   deleteNoteStart,
   deleteNoteCompleted,
   deleteNoteFailed,
   updateNoteStart,
   updateNoteCompleted,
   updateNoteFailed,
} from "./actions";
import {
   getMembers,
   getMember,
   getMemberStatistics as getStatistics,
   getFilterMembers,
   getMemberships,
   addFreeMember,
   addNewPerson,
   updateMember,
   getTransactionWalletActivity,
   expireFreeMembership,
   getMemberAllTags,
   addMemberNote,
   deleteMemberNote,
   updateMemberNote,
}  from 'admin/api/AuthApi';
import { setChatConversationAction, updateChatConversationList } from 'admin/state/modules/chat/actions'
import toster from 'common/utils/toast';
import PullRefresh from 'admin/utils/PullRefresh.js';
import PullToRefresh from 'pulltorefreshjs';
import Router from 'admin/routes/router';
import { push } from "connected-react-router";
import toast from "common/utils/toast";

export const membersFetchDetailsOperation = (query, ids, isEmptyFilters, callBack) => {
   return (dispatch) => {
      dispatch(fetchDetailsStart());
      getMembers(query).then(async (response) => {
         if(response.data && response.data.data && response.data.data.length === 0) {
            PullRefresh.destroyAll();
         }
         if(callBack){
            await callBack()
         }
         dispatch(fetchDetailsCompleted(response.data, ids, isEmptyFilters))
      }).catch((err) => {
         dispatch(fetchDetailsFailed());
      })
   }
};

export const memberFetchDetailsOperation = (id, callback, data = null) => {
   return (dispatch, getState) => {
      dispatch(fetchMemberStart());
      const { chat: { activeConverstaion } } = getState();
      const initMemberFunc = (data) => {
         dispatch(fetchMemberCompleted(data))
         dispatch(setActiveMemberData(data))
         !!callback && callback(data)
         if(!!activeConverstaion && activeConverstaion?.member?.id === id) {
            dispatch(setChatConversationAction({ ...activeConverstaion, member: data }))
         }
      }

      if(!!data) {
         initMemberFunc(data)
         return
      }

      getMember(id)
         .then((response) => {
            initMemberFunc(response.data)
         })
         .catch((err) => {
            dispatch(fetchMemberFailed());
            dispatch(push(Router.route('MEMBERS').getCompiledPath()))
         })
   }
}

export const membershipsFetchOperation = () => {
   return (dispatch) => {
      dispatch(fetchMembershipsStart());
      getMemberships().then((response) => {
         dispatch(fetchMembershipsCompleted(response.data))
      }).catch((err) => {
         dispatch(fetchMembershipsFailed());
      })
   }
};

export const getFilterBy = (query, onlineUsersId) => {
   return dispatch => {
      dispatch(filterMembers());
      if(query.find(el => el.includes('query[]=online_status=') && onlineUsersId)){
         query = [...query, `query[]=online_users_id=${ onlineUsersId.join(',') }`]
      }
      getFilterMembers(query).then((response) => {
         if(response.data && response.data.data && response.data.data.length === 0) {
            PullRefresh.destroyAll();
         } else {
            PullToRefresh.init({
               ...PullRefresh.init(),
               onRefresh: (done) => {
                  done();
               },
            });
         }
         dispatch(filterMembersCompleted(response.data))
      }).catch((err) => {
         if(err && err?.code === "ERR_CANCELED") {
            return
         }
         dispatch(filterMembersFailed());
      })
   }
}

export const fetchStatisticsData = (callback) => {
   return async dispatch => {
      dispatch(statisticsRequest());
      await getStatistics().then((response) => {
         !!callback && callback(response.data)
         dispatch(statisticsRequestCompleted(response.data))
      }).catch((err) => {
         dispatch(statisticsRequestFailed(err));
      })
   }
}

export const addFreeMemberOperation = (id, data, callBack) => {
   return dispatch => {
      dispatch(addFreeMemberRequest());
      dispatch(statisticsRequest());
      addFreeMember(id, data).then(response => {
         dispatch(addFreeMemberCompleted());
         toster.info('Complimentary membership has been applied');
         if(callBack) {
            callBack()
         }
      }).catch(err => {
         dispatch(addFreeMemberFailed(err));
         dispatch(statisticsRequestFailed(err));
         toster.error('This member already have active subscription');
      })
   }
}

export const addNewPersonOperation = (data, onErr, onData) => {
   return dispatch => {
      dispatch(addNewPersonRequest());
      addNewPerson(data).then(response => {
         toster.info('Invitation has been sent');
         !!onData && onData(response?.data)
         dispatch(addNewPersonCompleted(response?.data));
      }).catch(err => {
         !!onErr && onErr(err?.response?.data)
         dispatch(addNewPersonFailed(err.response.data));
      })
   }
}

export function getNewMembers(param) {
   return async dispatch => {
      dispatch(newFetchRequest());
      await getFilterMembers(param).then((response) => {
         dispatch(newFetchRequestCompleted(response.data));
      }).catch((err) => {
         dispatch(newFetchRequestFailed());
      })
   }
}
export function updateMembersOperation(data, id, callBack, hide, successCb) {
   return async dispatch => {
      dispatch(updateMemberFailed({}));
      try {
         await updateMember(id, data)
         dispatch(updateMembersRequestCompleted({ id, data }));
         if(data?.username) {
            toster.success('Username has been updated')
         } else {
            if(!hide) {
               toster.success('Your changes have been saved')
            }
         }
         if(!!successCb){
            successCb(id, data)
         }

         if(callBack){
            callBack('success')
         }
      } catch (err) {
         // console.log(err);
         if(callBack){
            callBack('error')
         }
         if(err && err.response) {
            dispatch(updateMemberFailed(err.response.data))
         }
      }
   }
}


export function expireFreeMemberShipOperation(id, callBack) {
   return async dispatch => {
      try {
         await expireFreeMembership(id)
         const { data: { membership_type, member_status } } = await getMember(id)
         const data = { membership_type, member_status }
         dispatch(updateMembersRequestCompleted({ id, data }));
         if(callBack) {
            callBack(data)
         }
         toster.success('Your changes have been saved')
      } catch (err) {
         if(err && err.response) {
            dispatch(updateMemberFailed(err.response.data))
         }
      }
   }
}

export function fetchMemberWalletHistoryOperation(param) {
   return async dispatch => {
      dispatch(fetchMemberWalletHistoryStart());
      await getTransactionWalletActivity(param)
         .then((response) => {
            dispatch(fetchMemberWalletHistoryCompleted(response.data));
         })
         .catch((err) => {
            dispatch(fetchMemberWalletHistoryFailed());
         })
   }
}

export function newFetchMemberWalletHistoryOperation(param) {
   return async dispatch => {
      dispatch(newFetchMemberWalletHistoryStart());
      await getTransactionWalletActivity(param)
         .then((response) => {
            dispatch(newFetchMemberWalletHistoryCompleted(response.data));
         })
         .catch((err) => {
            dispatch(newFetchMemberWalletHistoryFailed());
         })
   }
}

export function fetchMemberAllTagsOperations(memberId, callBack) {
   return async dispatch => {
      dispatch(fetchMemberTagsStart());
      await getMemberAllTags(memberId)
         .then((response) => {
            dispatch(fetchMemberTagsCompleted(response.data));
            if(callBack) {
               callBack()
            }
         })
         .catch((err) => {
            dispatch(fetchMemberTagsFailed());
         })
   }
}

// member notes

export const addNoteOperation = (memebrId, data, callBack) => {
   return async (dispatch) => {
      dispatch(addNoteStart())
      try {
         const response = await addMemberNote(memebrId, data);
         dispatch(addNote(memebrId, response.data));
         toast.info('Your note has been added');
         if(callBack){
            callBack();
         }
      } catch (error) {
         if(error?.response?.status === 422 && error?.response?.data?.note?.[0]){
            toast.error(error.response.data.note[0])
         }
         dispatch(addNoteFailed(error.response));
      }
   }
}
export const addNote = (memebrId, data) => {
   return async (dispatch, getState) => {
      const { chat: { activeConverstaion, conversationsList } } = getState();
      dispatch(addNoteCompleted({ memebrId, data }));
      let conversationsData = conversationsList?.data || [];
      if(conversationsData.find(conversation => conversation?.member?.id === memebrId)){
         conversationsData = [...conversationsData].map((e) => {
            let { ...conversation } = e;
            if(conversation?.member?.id === memebrId){
               let oldNotes = conversation?.member?.notes || [];
               conversation.member.notes = [data, ...oldNotes]
            }
            return conversation
         })
         updateChatConversationList(conversationsData)
      }
      if(!!activeConverstaion && activeConverstaion?.member?.id === memebrId) {
         let oldNotes = activeConverstaion.member?.notes || [];
         if(!oldNotes.find(el => el.id === data.id)){
            oldNotes = [...oldNotes].map((note) => {
               const { ...el } = note;
               if('add' === el.flag){
                  el.flag = null;
               }
               return el;
            });
            let newActiveConverstaion = {
               ...activeConverstaion,
               member: {
                  ...activeConverstaion.member,
                  notes: [{ ...data, flag: 'add' }, ...oldNotes],

               },
            }
            dispatch(setChatConversationAction(newActiveConverstaion))
         }
      }
   }
}
export const deleteNoteOperation = (memebrId, noteId, callBack) => {
   return async (dispatch, getState) => {
      dispatch(deleteNoteStart())
      try {
         await deleteMemberNote(memebrId, noteId);
         if(callBack){
            callBack();
         }
         toast.info('Your note has been deleted');
         const { chat: { activeConverstaion } } = getState();
         setTimeout(() => {
            dispatch(updateNoteData(memebrId, noteId, { flag: 'delete' }))
            setTimeout(() => {
               dispatch(deleteNoteCompleted({ memebrId, noteId }));
               if(!!activeConverstaion && activeConverstaion?.member?.id === memebrId) {
                  const oldNotes = activeConverstaion.member?.notes || [];
                  let newActiveConverstaion = {
                     ...activeConverstaion,
                     member: {
                        ...activeConverstaion.member,
                        notes: oldNotes.filter(note => note.id !== noteId),

                     },
                  }
                  dispatch(setChatConversationAction(newActiveConverstaion))
               }
            }, 500)
         }, 250)

      } catch (error) {
         dispatch(deleteNoteFailed(error.response));
      }
   }
}
export const updateNoteOperation = (memebrId, noteId, data, callBack) => {
   return async (dispatch, getState) => {
      dispatch(updateNoteStart())
      try {
         await updateMemberNote(memebrId, noteId, data);
         dispatch(updateNoteData(memebrId, noteId, { note: data.note }))
         toast.info('Your note has been updated');
         if(callBack){
            callBack();
         }
      } catch (error) {
         if(error?.response?.status === 422 && error?.response?.data?.note?.[0]){
            toast.error(error.response.data.note[0])
         }
         dispatch(updateNoteFailed(error.response));
      }
   }
}


function updateNoteData(memebrId, noteId, data){
   return async (dispatch, getState) => {
      const { chat: { activeConverstaion, conversationsList } } = getState();
      dispatch(updateNoteCompleted({ memebrId, noteId, data }));
      let conversationsData = conversationsList?.data || [];
      if(conversationsData.find(conversation => conversation?.member?.id === memebrId)){
         conversationsData = [...conversationsData].map((e) => {
            let { ...conversation } = e;
            if(conversation?.member?.id === memebrId){
               let oldNotes = conversation.member?.notes || [];
               conversation.member.notes = oldNotes.map(note => {
                  let elm = { ...note }
                  if(elm.id === noteId){
                     elm = {
                        ...elm,
                        ...data,
                     }
                  }
                  return elm
               })
            }
            return conversation
         })
         updateChatConversationList(conversationsData)
      }
      if(!!activeConverstaion && activeConverstaion?.member?.id === memebrId) {
         const oldNotes = activeConverstaion.member?.notes || [];
         let newActiveConverstaion = {
            ...activeConverstaion,
            member: {
               ...activeConverstaion.member,
               notes: oldNotes.map(note => {
                  let elm = { ...note }
                  if(elm.id === noteId){
                     elm = {
                        ...elm,
                        ...data,
                     }
                  }
                  return elm
               }),

            },
         }
         dispatch(setChatConversationAction(newActiveConverstaion))
      }
   }
}
