import queryString from 'query-string';
import { createBrowserHistory } from 'history';

const history =  createBrowserHistory({
   /* pass a configuration object here if needed */
});

const MODAL_TYPES = [
   'image',
   'video',
];

class ModalUrlHelper {
   static defineAction(payload = {}) {
      history.push(window.location.pathname)
      const parsedQueryParams = ModalUrlHelper.getParsedPayloads();
      parsedQueryParams[payload.type] = payload.id;

      if(!parsedQueryParams[payload.type]) {
         delete parsedQueryParams[payload.type];
      }
      const stringify = queryString.stringify(parsedQueryParams, { encode: false });
      history.replace({
         path: window.location.pathname,
         search: stringify,
      });
   }

   static resetAction() {
      const querySearchParams = history.location.search;
      if(!querySearchParams) return
      const parsedQueryParams = queryString.parse(querySearchParams);
      MODAL_TYPES.map(item => {
         if(item in parsedQueryParams) {
            delete parsedQueryParams[item];
         }
         return item;
      });

      history.replace({
         path: window.location.pathname,
         search: queryString.stringify(parsedQueryParams, {
            encode: false,
         }),
      });
   }

   static getParsedPayloads() {
      const querySearchParams = history.location.search;
      return queryString.parse(querySearchParams);
   }

   static getCallableMethodName(action) {
      // TODO in feature make most dynamic
      let name = '';
      switch (action) {
         default:
            name = 'openModalByUrl';
            break;
      }
      return name;
   }

   static triggerModal(context) {
      const payloads = ModalUrlHelper.getParsedPayloads();
      let params = {};
      MODAL_TYPES.map(item => {
         if(item in payloads) {
            params = { type: item, id: payloads[item] };
         }
         return item;
      });
      const functionToCall = ModalUrlHelper.getCallableMethodName();
      if(context[functionToCall] && params.type) {
         context[functionToCall]((params));
      }
   }
}

export default ModalUrlHelper;
