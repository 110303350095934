import Icon from 'common/components/elements/icons'
import toast from 'common/utils/toast'
import { copyText } from 'common/utils/utils'
import PropTypes from 'prop-types'

const CodeInfo = ({
   code,
   codeClassName,
   className,
   tooltipContent,
   showTooltip,
   anotherText,
   iconColor,
   iconSize,
}) => {

   return (
      <div
         className={ `flex items-center cursor-pointer gap-2 ${ className } ` }
         data-tooltip-id={ showTooltip ? 'ams-top-tooltip' : '' }
         data-tooltip-content={ tooltipContent ? tooltipContent : 'Copy promo code' }
         role='presentation'
         onClick={ (e) => {
            e.preventDefault()
            e.stopPropagation()
            copyText(code)
            toast.info('Promo code has been copied', { toastId: 'promotion_code_copied' })
         } }
      >
         <span className={ codeClassName }>{ anotherText ? anotherText : code }</span>
         <div
            className='flex items-center'
            data-tooltip-id={ tooltipContent ? 'ams-top-tooltip' : '' }
            data-tooltip-content='Copy promo code'
         >
            <Icon
               name='copy'
               size={ iconSize }
               color={ iconColor }
            />
         </div>
      </div>
   )
}

CodeInfo.defaultProps = {
   className: '',
   showTooltip: true,
   iconColor: 'secondary',
   iconSize: 'base',
}

CodeInfo.propTypes = {
   code: PropTypes.string.isRequired,
   codeClassName: PropTypes.string,
   className: PropTypes.string,
   tooltipContent: PropTypes.string,
   showTooltip: PropTypes.bool,
   anotherText: PropTypes.string,
   iconColor: PropTypes.string,
   iconSize: PropTypes.string,
}

export default CodeInfo
